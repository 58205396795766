import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { reportActions } from '@actions'
import { useGlobalsStore } from '@stores'
import { arrayUtils, numberUtils } from '@utils'
import { useToast } from '@hooks'

function BaselineSheet({ baselineId }) {
  const { showErrorToast } = useToast()
  const [baseline, setBaseline] = useState([])
  useEffect(() => {
    if (!baselineId) return
    fetchBaseline(baselineId)
  }, [baselineId])
  async function fetchBaseline(id) {
    const report = await reportActions.exportBaseline('json', id)
    setBaseline(report?.data?.data)
  }
  const sheet = useMemo(() => {
    if (!baseline?.length) return {}
    return arrayUtils.groupBy(baseline, (x) => `${x.minWeight}_${x.maxWeight}`)
  }, [baseline])

  const {
    getPolishedColours,
    getClarities,
    polishedColours,
    clarities
  } = useGlobalsStore(store => store)
  useEffect(() => {
    getPolishedColours()
    getClarities()
  }, [])
  const validColours = useMemo(() => {
    if (!polishedColours?.length || !baseline?.length) return []
    const polishedColoursMap = polishedColours.reduce((cols, col, idx) => ({ ...cols, [col.value]: { idx, ...col } }), {})
    const { min: minColourIdx, max: maxColourIdx, pm: pmColours } = baseline.reduce((bounds, { colour }) => ({
      min: Math.min(bounds.min ?? Infinity, polishedColoursMap[colour]?.idx ?? Infinity),
      max: Math.max(bounds.max ?? -1, polishedColoursMap[colour]?.idx ?? -1),
      pm: bounds.pm || !polishedColoursMap[colour]?.isBase
    }), { min: null, max: null, pm: false })
    if (minColourIdx === Infinity || maxColourIdx === -1) {
      showErrorToast('Invalid Baseline. Cannot be previewed.')
      return undefined
    }
    return polishedColours.slice(minColourIdx, maxColourIdx + 1).filter(col => pmColours || col.isBase)
  }, [polishedColours, baseline])
  const validClarities = useMemo(() => {
    if (!clarities?.length || !baseline?.length) return []
    const claritiesMap = clarities.reduce((clas, cla, idx) => ({ ...clas, [cla.value]: { idx, ...cla } }), {})
    const { min: minClarityIdx, max: maxClarityIdx } = baseline.reduce((bounds, { clarity }) => ({
      min: Math.min(bounds.min ?? Infinity, claritiesMap[clarity]?.idx ?? Infinity),
      max: Math.max(bounds.max ?? -1, claritiesMap[clarity]?.idx ?? -1)
    }), { min: null, max: null })
    if (minClarityIdx === Infinity || maxClarityIdx === -1) {
      showErrorToast('Invalid Baseline. Cannot be previewed.')
      return undefined
    }
    return clarities.slice(minClarityIdx, maxClarityIdx + 1)
  }, [clarities, baseline])

  if (!validColours || !validClarities) return null
  return (
    <div className='baseline-sheet'>
      {Object.values(sheet).map(wgt => {
        const wgtByColCla = arrayUtils.toMap(wgt, (x) => `${x.colour}_${x.clarity}`, x => Math.round(x.ppc * 100) / 100)
        const { minWeight, maxWeight, date } = wgt?.[0]
        return (
          <div className="baseline-sheet__weight-grid" key={`${minWeight}_${maxWeight}`}>
            <div className="baseline-sheet__weight-grid-label">({minWeight} - {maxWeight} ct.): {date}</div>
            <div className="baseline-sheet__weight-grid-columns">
              <div className="baseline-sheet__weight-grid-columns-header spacer"/>
              {validClarities?.map(cla => (
                <div className="baseline-sheet__weight-grid-columns-header" key={cla.value}>{cla.value}</div>
              ))}
            </div>
            <div className="baseline-sheet__weight-grid-rows">
              {validColours?.map(col => (
                <div className="baseline-sheet__weight-grid-row" key={col.value}>
                  <div className="baseline-sheet__weight-grid-row-header spacer" key={`header_${col.value}`}>{col.value}</div>
                  {validClarities?.map(cla => (
                    <div className="baseline-sheet__weight-grid-row-cell" key={`${col.value}_${cla.value}`}>{wgtByColCla[`${col.value}_${cla.value}`] ?? 'N/A'}</div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )
      })}
    </div>
  )
}

BaselineSheet.propTypes = {
  baselineId: PropTypes.string
}

export default BaselineSheet
