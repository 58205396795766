import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from '@molecules'
import { Icon, Button } from '@atoms'
import { useTableStore } from '@stores'

function TableFooter({
  canNextPage,
  canPreviousPage,
  gotoPage,
  initialPageSize,
  nextPage,
  pageOptions,
  previousPage,
  pageIndex,
  setAllFilters,
  rows,
  tableId,
  setPageSize,
  totalRowsCount
}) {
  const {
    getIsTableFiltered,
    removeFilterValues
  } = useTableStore(state => state)
  const isTableFiltered = getIsTableFiltered(tableId)

  const options = useMemo(() => getPageSizes(), [rows, initialPageSize])
  function getPageSizes() {
    const options = []
    for (let i = 0; i < Math.ceil(rows.length / 10); i++) {
      const pagesSize = Number(i + 1) * 10
      options.push({
        label: pagesSize,
        value: pagesSize
      })
    }

    return options
  }

  function getPageIndexes() {
    return pageOptions.map(pageIndex => (
      { label: pageIndex + 1, value: pageIndex }
    ))
  }

  return (
    <div className="table__footer">
      <div className='table__footer-results'>
        <span className="p2">
          {rows.length} results {rows.length !== totalRowsCount ? <strong>out of {totalRowsCount}</strong> : ''}
        </span>
        <Button
          typeVariant='action'
          size='sm'
          disabled={!isTableFiltered}
          noPadding={true}
          onClick={() => {
            removeFilterValues(tableId)
            setAllFilters([])
          }}
        >{isTableFiltered ? 'Clear Filters' : 'Unfiltered'}</Button>
      </div>
      <div className="table__pagination">
        <button
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          className="table__arrow"
        >
          <Icon
            name='arrowDropLeft'
          />
          <Icon
            name='arrowDropLeft'
          />
        </button>
        <button
          onClick={() => gotoPage(previousPage)}
          disabled={!canPreviousPage}
          className="table__arrow">
          <Icon
            name='arrowDropLeft'
          />
        </button>
        <span className="p2">
          Page <strong>{pageIndex + 1} of {pageOptions.length || 1}</strong>
        </span>
        <button
          onClick={() => gotoPage(nextPage)}
          disabled={!canNextPage}
          className="table__arrow">
          <Icon
            name='arrowDropRight'
          />
        </button>
        <button
          onClick={() => gotoPage(pageOptions.length - 1)}
          disabled={!canNextPage}
          className="table__arrow">
          <Icon
            name='arrowDropRight'
          />
          <Icon
            name='arrowDropRight'
          />
        </button>
      </div>
      <div className="table__pagination">
        <span className="p2">Show:</span>
        <div className="table__pagination-dropdown">
          <Dropdown
            menuPlacement='top'
            size='sm'
            onChange={e => {
              setPageSize(Number(e.currentTarget.value))
            }}
            isClearable={false}
            options={options}
            placeholder={options[options.length - 1]?.value < initialPageSize ? options[options.length - 1]?.value : initialPageSize}
            fallbackValue={null}
          />
        </div>
        <span className="p2">Jump to:</span>
        <div className="table__pagination-dropdown">
          <Dropdown
            menuPlacement='top'
            size='sm'
            onChange={e => {
              gotoPage(Number(e?.currentTarget?.value))
            }}
            isClearable={false}
            options={getPageIndexes()}
            placeholder={pageOptions[0] ? pageOptions[0] + 1 : 1}
            fallbackValue={null}
          />
        </div>
      </div>
    </div>
  )
}

TableFooter.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  totalRowsCount: PropTypes.number,
  setPageSize: PropTypes.func,
  initialPageSize: PropTypes.number,
  tableId: PropTypes.string,
  canPreviousPage: PropTypes.bool,
  canNextPage: PropTypes.bool,
  gotoPage: PropTypes.func,
  setAllFilters: PropTypes.func,
  pageOptions: PropTypes.arrayOf(PropTypes.number),
  previousPage: PropTypes.func,
  nextPage: PropTypes.func,
  pageIndex: PropTypes.number,
  TableForm: PropTypes.func
}

TableFooter.defaultProps = {
  initialPageSize: 10,
  rows: [],
  pageOptions: [],
  TableForm: null
}

export default TableFooter
