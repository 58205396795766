import React from 'react'
import { FormComponents } from '@organisms'
import { provenanceActions } from '@actions'
import { useGlobalsStore } from '@stores'
import { useToast } from '@hooks'
import { useHistory } from 'react-router-dom'

function MineForm() {
  const {
    Button,
    Checkbox,
    TextInput,
    SimpleForm
  } = FormComponents

  const { showSuccessToast } = useToast()
  const { setMinesList, resetMinesList } = useGlobalsStore(store => store)

  const history = useHistory()
  function handleOnSubmit({ ...values }) {
    for (const key in values) {
      if (values[key] === '') delete values[key]
    }
    provenanceActions.createMine(values)
      .then(async () => {
        showSuccessToast('Mine added.')
        await resetMinesList()
        await setMinesList({ condition: 'ACTIVE' })
        history.push('/seller-settings')
      })
      .catch(err => console.error(err))
  }

  return (
    <div className="create-mine">
      <div className="col-xl-4 col-lg-7 col-md-10 col-sm-12">
        <SimpleForm
          title='New Mine'
          onSubmit={handleOnSubmit}
          name='createMine'
          initialValues={{ secondaryMarket: false }}
        >
          <TextInput
            name='name'
            label='Name'
          />
          <Checkbox
            name='secondaryMarket'
            label='Secondary market'
          />
          <TextInput
            name='latitude'
            label='Latitude'
            required={false}
          />
          <TextInput
            name='longitude'
            label='Longitude'
            required={false}
          />
          <Button typeVariant="action" onClick={() => history.goBack()}>
            Cancel
          </Button>
          <Button
            size='sm'
            type='submit'
          >
            Create Mine
          </Button>
        </SimpleForm>
      </div>
    </div>
  )
}

export default MineForm
