import React, { useState, useEffect } from 'react'
import { Table } from '@organisms'
import { planningActions, reportActions, bidActions } from '@actions'
import { Checkbox } from '@atoms'
import { Dropdown, TextInput } from '@molecules'
import { ConfirmationModal } from '@templates'
import { fileUtils, objectUtils } from '@utils'
import { useToast } from '@hooks'
import { useHistory } from 'react-router-dom'

function MatchList({ saleId, canEdit }) {
  const [matches, setMatches] = useState([])
  useEffect(() => {
    fetchData()
  }, [saleId])
  function fetchData() {
    planningActions.getMatchesList({ saleId })
    .then(result => setMatches(result.data.data))
  }

  const { showSuccessToast } = useToast()

  const [columns, setColumns] = useState([])
  useEffect(() => {
    setColumns([
      {
        Header: 'ID',
        accessor: 'id',
        dataType: 'string'
      },
      {
        Header: 'Name',
        accessor: 'name',
        dataType: 'string'
      },
      {
        Header: 'Description',
        accessor: 'description',
        dataType: 'string'
      },
      {
        Header: 'Finished',
        id: 'finished',
        accessor: row => row.number_pending_calculation ? 'No' : 'Yes',
        dataType: 'string'
      },
      {
        Header: 'Sold',
        accessor: 'totalCount',
        dataType: 'number'
      },
      {
        Header: 'Profit',
        accessor: 'profit',
        dataType: 'currency',
        decimalScale: 2,
        fixedDecimalScale: true
      },
      {
        Header: 'Last Updated',
        id: 'updatedAt',
        accessor: 'updatedAt',
        dataType: 'dateTime',
        filterType: 'date'
      }
    ])
  }, [])

  const history = useHistory()
  const [newName, setNewName] = useState(null)
  const [newDescription, setNewDescription] = useState(null)
  const [newReserveType, setNewReserveType] = useState(null)
  const [newModalOpen, setNewModalOpen] = useState(false)
  function closeNewModal() {
    setNewModalOpen(false)
    setNewName(null)
    setNewDescription(null)
  }
  const { showErrorToast } = useToast()
  function createNewMatch() {
    if (!canEdit) return
    const newOptions = objectUtils.filterNullish({ saleId, name: newName, description: newDescription })
    if (!newOptions.name) {
      showErrorToast('Name field is required.')
      return Promise.reject('Name field is required.')
    }
    if (newReserveType === 'createBids') newOptions.createBids = true
    else if (newReserveType === 'finalize') newOptions.finalize = true
    return planningActions.createMatch(newOptions)
    .then(() => {
      closeNewModal()
      fetchData()
    })
  }

  const [makeCopy, setMakeCopy] = useState(false)
  const [improveModalOpen, setImproveModalOpen] = useState(false)
  function closeImproveModal() {
    setImproveModalOpen(false)
    setMakeCopy(false)
  }
  function improveMatches(matches) {
    if (!matches?.length) return Promise.reject('No matches selected.')
    return Promise.all(matches.map(match => planningActions.improveMatch({ matchId: match.id, copy: makeCopy })))
    .then(() => {
      fetchData()
    })
  }

  function exportMatches(matches) {
    if (!matches?.length) return
    for (const match of matches) {
      reportActions.getMatchDetails('xlsx', match.id)
      .then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))
    }
  }

  function postBids(matchId) {
    return bidActions.createBids(matchId)
    .then(() => showSuccessToast('Bids created.'))
  }

  const [closeSaleOpen, setCloseSaleOpen] = useState(false)
  function confirmMatch(matchId) {
    return planningActions.confirmMatch({ matchId })
    .then(() => {
      history.push('/sales')
    })
  }

  return <>
    <Table
      title='Matching Options'
      data={matches}
      columns={columns}
      getDataCallback={fetchData}
      initialSort={[{ id: 'updatedAt', desc: true }]}
      topBarActions={[
        {
          componentName: 'dropdown',
          enableOnSelect: true,
          options: [
            {
              callback: matches => setImproveModalOpen(matches),
              label: 'Process',
              value: 'improve',
              isDisabled: !canEdit
            },
            {
              callback: exportMatches,
              label: 'Export',
              value: 'export',
              isDisabled: selected => selected?.length !== 1
            },
            {
              callback: matches => postBids(matches[0]?.id),
              label: 'Use to Create Bids',
              value: 'bids'
            },
            {
              callback: matches => setCloseSaleOpen(matches[0]?.id),
              label: 'Confirm',
              value: 'confirm',
              isDisabled: selected => !canEdit || selected?.length !== 1
            }
          ]
        },
        {
          callback: () => setNewModalOpen(true),
          label: 'New',
          enableOnSelect: false,
          disabled: !canEdit
        }
      ]}
    />
    <ConfirmationModal
      open={newModalOpen}
      title='Create Match'
      message='Start a new matching process. You may create multiple matches, but only one can be confirmed per sale.'
      onClose={closeNewModal}
      onSubmit={createNewMatch}
    >
      <TextInput
        label='Name'
        value={newName ?? ''}
        onChange={e => setNewName(e.target.value)}
      />
      <TextInput
        label='Description'
        value={newDescription ?? ''}
        onChange={e => setNewDescription(e.target.value)}
      />
      <Dropdown
        label='Reserves'
        name='matchingReserveType'
        value={newReserveType ?? 'normal'}
        options={[
          { value: 'finalize', label: 'Finalizing Sale' },
          { value: 'createBids', label: 'To Create Bids' }
        ]}
        onChange={e => setNewReserveType(e.currentTarget.value)}
        infoTip={true}
      />
    </ConfirmationModal>
    <ConfirmationModal
      open={Boolean(improveModalOpen)}
      title='Process Match'
      message={<>
        Further process the {improveModalOpen.length} selected match{improveModalOpen.length > 1 ? 'es' : ''}?
        This will attempt to match stones that are still outstanding.
        <br/><br/>
        It may take several passes before all stones are matched. Check the &quot;Finished&quot; column to see when a match is ready.
      </>}
      onClose={closeImproveModal}
      onSubmit={() => improveMatches(improveModalOpen)}
    >
      <Checkbox
        label='Make a Copy'
        value={makeCopy}
        onChange={e => setMakeCopy(e.target.checked)}
      />
    </ConfirmationModal>
    <ConfirmationModal
      open={Boolean(closeSaleOpen)}
      title='Finish Sale'
      message={`Confirm the match ${closeSaleOpen} and close the sale?`}
      onClose={() => setCloseSaleOpen(false)}
      onSubmit={() => confirmMatch(closeSaleOpen)}
    />
  </>
}

export default MatchList
