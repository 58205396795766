import React from 'react'
import PropTypes from 'prop-types'
import { Route as RouterRoute } from 'react-router-dom'
import { usePageTitle } from '@hooks'

function Route({
  path,
  label,
  exact,
  component: Component
}) {
  usePageTitle(label)
  return (
    <RouterRoute
      path={path}
      exact={exact}
      render={(props) => <Component {...props} title={label} />}
    />
  )
}

Route.propTypes = {
  path: PropTypes.string,
  label: PropTypes.string,
  exact: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

Route.defaultProps = {
}

export default Route
