import { COMMON } from '@constants'
import moment from 'moment'
import React from 'react'
// Regex
const uuidRegex = new RegExp(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/)
/// Numeric percentage
const pctRegex = new RegExp(/^\d+(\.\d+)?%$/)
/// Numeric percentage with special character allowed
/// See https://exceljet.net/custom-number-formats
const pctFmtRegex = new RegExp(/^[\d#*,_]+\.?[\d#*,_]*%\??@?$/)

// Methods
// Capitalize the first letter of each word in a string (the first word character in the string or the first word character after a any number of whitespace)
function capitalize(txt, toLowerCase = false) {
  if (toLowerCase) return String(txt).toLowerCase().replace(/(?:^|\s)\w/g, x => x.toUpperCase())
  return String(txt).replace(/(?:^|\s)\w/g, x => x.toUpperCase())
}

function breakLines(txt) {
  if (!txt) return ''
  return txt.split(/\n|<br\/?>/).reduce((str, line, i, arr) => str.concat(i === arr.length - 1 ? line : [line, <br key={i}/>]), [])
}

function isUUID (uuid) {
  return uuidRegex.test(uuid)
}

function isPercent(pct) {
  return pctRegex.test(pct)
}

function isPercentFmt(pctFmt) {
  return pctFmtRegex.test(pctFmt)
}

function isEmpty(txt) {
  return txt == null || txt === ''
}

function nonEmptyString(txt) {
  return txt != null && (typeof txt === 'string') && txt !== ''
}

function compareIgnoreCase(a, b) {
  return String(a).localeCompare(String(b), 'en', { sensitivity: 'base' })
}

// dateFormat can have one of three expected inputs
// 1. A moment format string (String)
// 2. true boolean indicating time should be included in the date (Boolean)
// 3. false boolean indicating time should not included in the date (Boolean)
// Default will be false boolean and default moment format will be 'YYYY-MM-DD'
function formatDate(date, dateFormat = false) {
  if (!date) return ''

  let momentFormat = nonEmptyString(dateFormat) ? dateFormat : COMMON.DATE_FMT.DATE
  if (typeof dateFormat === 'boolean' && dateFormat) momentFormat = COMMON.DATE_FMT.DATETIME
  return moment(date).format(momentFormat)
}

function formatDescription(txt) {
  return isEmpty(txt) ? '' : capitalize(String(txt).toLocaleLowerCase())
}

function substituteConstantTemplateStr(constant, subs) {
  return constant.replace(/{{(.*?)}}/g, (_, offset) => subs[offset])
}

function dotNotationSquareBracketToDot(propertyName) {
  function squareBracketToDot(match) {
    return '.' + match.substring(1, match.length - 1)
  }
  return propertyName.replace(/\[\w+\]/g, squareBracketToDot)
}

function dotNotationToCapital(txt) {
  return txt?.split('.')?.reduce((accum, curr) => `${accum}${capitalize(curr)}`) || ''
}

function shortenUUID(text, length = 4) {
  if (!isUUID(text)) return text
  return text?.slice(0, length) || ''
}

function trim(str) {
  if (typeof str === 'string') return str.trim()
  else return str
}

export default {
  capitalize,
  breakLines,
  isUUID,
  trim,
  isPercent,
  isPercentFmt,
  isEmpty,
  nonEmptyString,
  compareIgnoreCase,
  formatDate,
  formatDescription,
  substituteConstantTemplateStr,
  dotNotationSquareBracketToDot,
  dotNotationToCapital,
  shortenUUID
}
