import React from 'react'
import NotificationForm from './notificationForm'

function CreateNotification() {
  return (
    <NotificationForm
      isNewNotification={true}
    />
  )
}

export default CreateNotification
