import { useEffect } from 'react'
import { hotjar } from 'react-hotjar'

function useAnalytics() {
  const hjid = process.env.REACT_APP_HOTJAR_HJID
  const hjsv = process.env.REACT_APP_HOTJAR_HJSV

  useEffect(() => {
    hotjar.initialize(hjid, hjsv)
  }, [])
}

export {
  useAnalytics
}
