import React from 'react'
import PropTypes from 'prop-types'
import TableRowIcon from './tableRowIcon'
import { textUtils } from '@utils'
import { useTableStore } from '@stores'
import { Button } from '@atoms'
import { ErrorIcon } from '@molecules'

function TableBody({
  page,
  tableId,
  canEdit,
  prepareRow,
  rowColourScheme,
  onEditSubmit,
  refreshTableData,
  getTableBodyProps,
  rowActions,
  validationText
}) {
  const {
    getEditRow,
    getIsEditing,
    setEditRow,
    getEditValues,
    getEditRowIndex,
    resetTableStore
  } = useTableStore(state => state)

  const editRowIndex = getEditRowIndex(tableId)
  const editRow = getEditRow(tableId)
  const isEditing = getIsEditing(tableId)
  const editValues = getEditValues(tableId)

  function getTrClassName(row) {
    if (row.isSelected) return 'table__row--body--selected'

    if (rowColourScheme?.length) {
      for (const rowColScheme of rowColourScheme) {
        if (rowColScheme.condition(row.original)) {
          return `table__row--body--${rowColScheme.scheme}`
        }
      }
    }

    return 'table__row--body'
  }

  return (
    // Apply the table body props */}
    <tbody className="table__body" {...getTableBodyProps()}>
      {// Loop over the table rows
        page.map((row, i) => {
          // Prepare the row for display
          prepareRow(row)
          return (
            // Apply the row props
            <tr key={i} className={getTrClassName(row)} {...row.getRowProps()}>
              {// Loop over the rows cells
                row.cells.map((cell, j) => {
                  // Apply the cell props
                  const cellProps = cell.getCellProps()
                  const valText = validationText?.get(cell?.row?.id)?.parseStep(cell?.column.id)
                  return (
                    <td
                      key={j}
                      className={`table__cell--body ${valText?.getValidationClass()}`} {...cellProps}>
                      <div className="table__cell-container">
                        <div className="table__cell-content">
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </div>
                        {
                          <div className="table__cell-icon">
                            {/* // Render the cell errors */}
                            <ErrorIcon {...valText?.parse()} infoTip={true} />
                          </div>
                        }
                      </div>
                    </td>
                  )
                })
              }
              {
                rowActions.length || canEdit
                  ? (
                    <td
                      className="table__cell--body"
                      role="cell"
                    >
                      {
                        <div className='table__row-icons' >
                          {rowActions
                          .filter(action => {
                            const hasProperty = Object.prototype.hasOwnProperty.call(action, 'shouldDisplay')
                            return !hasProperty || action.shouldDisplay(row.original)
                          })
                          .map((action, k) => {
                            return (
                              <TableRowIcon
                                key={k}
                                action={action}
                                row={row}
                                refreshTableData={refreshTableData}
                                iconTitle={action.title ?? textUtils.capitalize(action.actionName)}
                              />
                            )
                          })}
                          {
                            canEdit && !isEditing
                              ? (
                                <TableRowIcon
                                  action={{
                                    actionName: 'edit',
                                    callback: (rowOriginal, row) => {
                                      setEditRow(tableId, row)
                                    },
                                    shouldConfirm: false,
                                    shouldRefresh: false
                                  }}
                                  row={row}
                                  refreshTableData={refreshTableData}
                                  iconTitle='Edit'
                                />
                              ) : null
                          }
                          {
                            isEditing && editRowIndex && row.index === editRowIndex
                              ? (
                                <>
                                  <Button
                                    size='sm'
                                    typeVariant='action'
                                    onClick={() => resetTableStore(tableId)}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    size='sm'
                                    onClick={() => {
                                      Promise.resolve(onEditSubmit(editValues, editRow))
                                      .finally(() => resetTableStore(tableId))
                                    }}
                                  >
                                    Save
                                  </Button>
                                </>
                              ) : null
                          }
                        </div>
                      }
                    </td>
                  )
                  : null
              }
            </tr>
          )
        })}
    </tbody>
  )
}

TableBody.propTypes = {
  canEdit: PropTypes.bool,
  tableId: PropTypes.string,
  prepareRow: PropTypes.func,
  onEditSubmit: PropTypes.func,
  rowColourScheme: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  validationText: PropTypes.object,
  refreshTableData: PropTypes.func,
  getTableBodyProps: PropTypes.func,
  page: PropTypes.arrayOf(PropTypes.object),
  rowActions: PropTypes.arrayOf(PropTypes.object)
}

TableBody.defaultProps = {
  page: [],
  canEdit: false,
  rowActions: []
}

export default TableBody
