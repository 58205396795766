import axios from 'axios'
const BASE_URL = process.env.REACT_APP_API_BASE_URL

const getBids = function (saleId, sellerId) {
  return axios.get(`${BASE_URL}/seller/bids`, { params: { saleId, sellerId } })
}
getBids.endpoint = 'GET|/seller/bids'

const createBids = function (matchId) {
  return axios.post(`${BASE_URL}/seller/bids`, { matchId })
}
createBids.endpoint = 'POST|/seller/bids'

const setBidsStatus = function (bidIds, status) {
  return axios.put(`${BASE_URL}/seller/bids/status`, { bidIds, status })
}
setBidsStatus.endpoint = 'PUT|/seller/bids/status'

const setBidsConfirmed = function (bidIds, confirmed) {
  return axios.put(`${BASE_URL}/seller/bids/confirmed`, { bidIds, confirmed })
}
setBidsConfirmed.endpoint = 'PUT|/seller/bids/confirmed'

export const bidActions = {
  createBids,
  getBids,
  setBidsStatus,
  setBidsConfirmed
}
