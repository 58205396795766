import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, ConfirmationModal } from '@templates'
import TextArea from '../input/textArea'
import { Button } from '@atoms'
import RoughComment from './roughComment'
import { roughStoneActions } from '@actions'
import { useToast } from '@hooks'
import { useAuthStore, useRoughStoneStore } from '@stores'

function RoughCommentModal({
  open,
  roughId,
  rough,
  readOnly,
  onClose
}) {
  const [commentText, setCommentText] = useState('')
  const [roughStone, setRoughStone] = useState(null)
  const initialCommentAction = { isEdit: false, isRemove: false, commentId: null }
  const [commentAction, setCommentAction] = useState(initialCommentAction)
  const { showSuccessToast } = useToast()
  const { currentAuthenticatedUser } = useAuthStore(state => state)
  const { removeAllRoughStonesListItems } = useRoughStoneStore(store => store)

  useEffect(() => {
    if (rough) {
      setRoughStone(rough)
    }
  }, [rough])

  useEffect(() => {
    if (roughId) {
      fetchRoughStone(roughId)
    }
  }, [roughId])

  function fetchRoughStone(id) {
    if (id) {
      roughStoneActions.getRoughStoneById(id)
      .then(response => {
        setRoughStone(response?.data?.data[0])
      })
    }
  }

  function handleOnSave() {
    if (!roughStone?.id) return

    if (commentAction.isEdit) {
      roughStoneActions.editRoughComment(roughStone.id, commentAction.commentId, commentText)
      .then(() => removeAllRoughStonesListItems([roughStone.id]))
      .then(() => resetCommentModal('Comment updated.'))
    } else {
      roughStoneActions.createRoughComment([roughStone.id], commentText)
      .then(() => removeAllRoughStonesListItems([roughStone.id]))
      .then(() => resetCommentModal('Comment saved.'))
    }
  }

  function resetCommentModal(toastMessage) {
    showSuccessToast(toastMessage)
    fetchRoughStone(roughStone?.id)
    setCommentAction(initialCommentAction)
    setCommentText('')
    moveToTopList()
  }

  function moveToTopList() {
    const commentList = document.getElementById('rough-comment-list')
    commentList.scrollTop = 0
  }

  function handleOnEdit(comment) {
    setCommentAction({ isEdit: true, commentId: comment.id })
    setCommentText(comment.commentText)
  }

  function resetEditMode() {
    setCommentAction(initialCommentAction)
    setCommentText('')
  }

  function handleOnRemove() {
    roughStoneActions.removeRoughComment(roughStone.id, commentAction.commentId)
    .then(() => removeAllRoughStonesListItems([roughStone.id]))
    .then(() => resetCommentModal('Comment removed.'))
  }

  function handleOnClose() {
    setCommentAction(initialCommentAction)
    setCommentText('')
    setRoughStone(null)
    onClose()
  }

  if (!open || (!roughId && !roughStone)) return null
  return (
    <>
      <Modal
        open={open}
        onClose={handleOnClose}
        closeOnEscape={false}
        className='rough-comment__modal'
        title={`Comments on Stone ${roughStone?.id || ''}`}
      >
        <div className="rough-comment__list" id='rough-comment-list'>
          {
            !roughStone?.comments?.length
              ? <span className='subtext'>{'No comments yet'}</span>
              : roughStone?.comments
              .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
              .map((comment, index) => {
                return <RoughComment
                  key={index}
                  comment={comment}
                  onEdit={() => handleOnEdit(comment)}
                  onRemove={() => setCommentAction({ isRemove: true, commentId: comment.id })}
                  readOnly={readOnly}
                />
              })
          }
        </div>
        <div className="rough-comment__form">
          <div className={`rough-comment__form-title${readOnly ? '--disabled' : ''}`}>
            <span>{`${commentAction.isEdit ? 'Update ' : 'New '}`} comment as <strong>{currentAuthenticatedUser?.username}</strong></span>
          </div>
          <TextArea
            disabled={readOnly}
            rows={5}
            value={commentText}
            onChange={e => setCommentText(e.target.value)}
          >
          </TextArea>
          <div className="rough-comment__form-save-button">
            {
              commentAction.isEdit
                ? (
                  <Button
                    size='sm'
                    typeVariant='action'
                    onClick={resetEditMode}
                  >
                    Cancel
                  </Button>
                ) : null
            }
            <Button
              disabled={readOnly}
              size='sm'
              onClick={handleOnSave}
            >
              { commentAction.isEdit ? 'Update' : 'Post' }
            </Button>
          </div>
        </div>
      </Modal>
      <ConfirmationModal
        open={commentAction.isRemove}
        title='Remove Comment'
        message={'Remove comment?'}
        closeOnEscape={false}
        onClose={() => setCommentAction(initialCommentAction)}
        onSubmit={async () => handleOnRemove()}
      />
    </>
  )
}

RoughCommentModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool,
  roughId: PropTypes.string,
  rough: PropTypes.object
}

RoughCommentModal.defaultProps = {
  roughId: null,
  rough: null,
  open: false,
  readOnly: false
}

export default RoughCommentModal
