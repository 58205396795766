import React, { useEffect } from 'react'
import { Dropdown } from '@molecules'
import { useTableStore } from '@stores'

const DropdownFilter = function ({
  column: { filterValue, setFilter, id },
  preFilteredRows,
  tableId
}) {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  const {
    setFilterValue,
    setIsFilteredFn,
    getFilterValue,
    getTableConfig
  } = useTableStore(state => state)

  const storeFilterValue = getFilterValue(tableId, id)
  const tableConfig = getTableConfig(tableId)

  useEffect(() => {
    setFilter(storeFilterValue?.value)
  }, [storeFilterValue?.value])

  useEffect(() => {
    setIsFilteredFn(tableId, id, (filter) => !!filter?.length)
  }, [tableConfig?.activeTab?.id, tableConfig?.activeTab?.label])

  return (
    <Dropdown
      value={filterValue}
      onClick={e => e.stopPropagation()}
      onChange={e => {
        setFilterValue(tableId, id, e?.currentTarget?.value || undefined)
      }}
      isMulti={true}
      options={options.map((option, i) => ({
        value: option,
        label: option
      }
      ))}
    />
  )
}

const dropdownFilter = (rows, columnId, filters) => {
  if (!filters || !filters.length || !columnId || !columnId.length) return rows
  return rows.filter(row => filters.includes(row.values[columnId[0]]))
}

export {
  DropdownFilter,
  dropdownFilter
}
