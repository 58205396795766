import React from 'react'
import PropTypes from 'prop-types'
import PolishedStoneDetails from './polishedStoneDetails'

function PolishedStoneList({ polishedStones, orders }) {
  if (!polishedStones) return null
  return (
    <div>
      {
        polishedStones
          .slice()
          .sort((x, y) =>
            (Number(x.OrderLine?.quantityType === 'TOPS') - Number(y.OrderLine?.quantityType === 'TOPS'))
            || (Number(y.polishedWeight) - Number(x.polishedWeight))
            || (Number(y.buyerPrice) - Number(x.buyerPrice))
          )
          .map((polishedStone, index) => {
            polishedStone.label = `Polished Stone ${index + 1}`
            return (
              <React.Fragment key={index}>
                <PolishedStoneDetails
                  key={index}
                  polishedStone={polishedStone}
                  orders={orders}
                />
                <br/>
              </React.Fragment>
            )
          })
      }
    </div>
  )
}

PolishedStoneList.propTypes = {
  polishedStones: PropTypes.arrayOf(PropTypes.object),
  orders: PropTypes.object
}

PolishedStoneList.defaultProps = {
  polishedStones: [],
  orders: {}
}
export default PolishedStoneList
