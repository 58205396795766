import React, { useState, useEffect } from 'react'
import { Table } from '@organisms'
import { roleAndPermissionAction } from '@actions'
import { useToast } from '@hooks'
import PQueue from 'p-queue'

function RoleAndPermissionList() {
  const [roles, setRoles] = useState([])
  const [permissions, setPermissions] = useState([])
  const [rolesAndPermissionsList, setRolesAndPermissionsList] = useState([])
  const { showSuccessToast } = useToast()
  const tableId = 'rolesAndPermissions'

  useEffect(() => {
    refreshPermissionList()
  }, [])

  function refreshPermissionList() {
    resetLists()
    getRoles()
    getPermissions()
  }

  function resetLists() {
    setRolesAndPermissionsList([])
    setRoles([])
    setPermissions([])
  }

  useEffect(() => {
    buildRolesAndPermissionsList()
  }, [roles, permissions])

  function getRoles() {
    return roleAndPermissionAction.getRoles()
    .then(response => {
      setRoles(response.data.data
      .sort((a, b) => a.role > b.role ? 1 : -1)
      .map(role => {
        return {
          role: role.role,
          formattedName: formatRoleName(role.role)
        }
      }))
    })
  }

  function getPermissions() {
    return roleAndPermissionAction.getPermissions()
    .then(response => setPermissions(response.data.data))
  }

  function buildRolesAndPermissionsList() {
    // loop through all permission endpoints
    if (roles?.length && permissions?.length) {
      const rolesAndPermissionsList = permissions.reduce((rolesAndPermissions, permission) => {
        if (rolesAndPermissions.get(permission.endpoint)) {
          const roleAndPermission = rolesAndPermissions.get(permission.endpoint)
          // - update role permissions for this endpoint
          roleAndPermission[formatRoleName(permission.role)] = true
          // - add ignoreOrg
          updateIgnoreOrg(roleAndPermission, permission)
        } else {
          // if endpoint doesn't exist, create initial object
          const initialRoleAndPermissionObj = buildInitialRolesAndPermissionsObj(permission)
          initialRoleAndPermissionObj[formatRoleName(permission.role)] = true
          rolesAndPermissions.set(permission.endpoint, initialRoleAndPermissionObj)
        }
        return rolesAndPermissions
      }, new Map())
      setRolesAndPermissionsList(rolesAndPermissionsList)
    }
  }

  function buildInitialRolesAndPermissionsObj(permission) {
    const rolesAndPermissionsObj = {
      endpoint: permission.endpoint,
      event: String(permission.endpoint).split('|')[0],
      path: String(permission.endpoint).split('|')[1]
    }

    for (const role of roles) {
      rolesAndPermissionsObj[role.formattedName] = false
    }

    updateIgnoreOrg(rolesAndPermissionsObj, permission)
    return rolesAndPermissionsObj
  }

  function updateIgnoreOrg(roleAndPermission, permission) {
    if (roleAndPermission?.ignoreOrg && roleAndPermission?.ignoreOrg.length) {
      if (!roleAndPermission.ignoreOrg.some(role => role === permission.role)) {
        if (permission.ignoreOrg) {
          roleAndPermission.ignoreOrg.push(permission.role)
        }
      }
    } else {
      if (permission.ignoreOrg) {
        roleAndPermission.ignoreOrg = [permission.role]
      }
    }
    return roleAndPermission
  }

  function handleOnEdit(values, row) {
    if (!values) return
    // get endpoint
    const { endpoint } = row.original
    // extract edited roles
    const editRoles = extractRoles(values)
    // extract edited ignore orgs
    const ignoreOrgRoles = extractIgnoreOrg(values)
    // build request object
    const editData = buildEditData(editRoles, ignoreOrgRoles, endpoint)
    // create requests queue
    const queue = createRequestQueue(editData)
    // start requests queue
    if (queue.size) {
      queue.start()
    }
  }

  function extractRoles(values) {
    return [...roles.filter(role => values[role.formattedName]).map(role => {
      return {
        name: role.role,
        value: values[role.formattedName].value
      }
    })]
  }

  function extractIgnoreOrg(values) {
    return values?.ignoreOrg
      ? {
        value: values?.ignoreOrg.value || [],
        originalValue: values?.ignoreOrg.originalValue || []
      } : undefined
  }

  function buildEditData(editRoles, ignoreOrgRoles, endpoint) {
    const editData = []
    if (editRoles.length) {
      // loop editRoles array
      editData.push(...editRoles.map(role => {
        return {
          endpoint,
          role: role.name,
          ignoreOrg: ignoreOrgRoles?.value?.some(ignoreOrgRole => ignoreOrgRole === role.name) || false,
          operation: getRequestOperation(endpoint, role.name, role.value)
        }
      }))
    }

    if (ignoreOrgRoles) {
      const { value, originalValue } = ignoreOrgRoles

      const { addIgnore, removeIgnore } = roles.filter(role => roleAndPermissionExists(endpoint, role.role)).filter(role => !editData.some(data => data.role === role.role)).reduce(({ addIgnore, removeIgnore }, role) => {
        if (value.includes(role.role) && !originalValue.includes(role.role)) return { removeIgnore, addIgnore: [...addIgnore, createIgnoreObj(endpoint, role.role, true)] }
        else if (originalValue.includes(role.role) && !value.includes(role.role)) return { addIgnore, removeIgnore: [...removeIgnore, createIgnoreObj(endpoint, role.role, false)] }
        else return { addIgnore, removeIgnore }
      }, { addIgnore: [], removeIgnore: [] })

      editData.push(...addIgnore)
      editData.push(...removeIgnore)
    }
    // filters out cases where user changed a field back to
    // it's original value
    return editData.filter(data => data.operation)
  }

  function createIgnoreObj(endpoint, role, ignoreOrg) {
    return {
      endpoint,
      role: role,
      data: { ignoreOrg },
      operation: 'edit'
    }
  }

  function createRequestQueue(editData) {
    const queue = new PQueue({ concurrency: 1, autoStart: false })
    editData.forEach(data => {
      if (data.operation === 'create') {
        queue.add(async () => await createPermission({
          role: data.role,
          endpoint: data.endpoint,
          ignoreOrg: data.ignoreOrg
        }))
      }
      if (data.operation === 'remove') {
        queue.add(async () => await removePermission(data.endpoint, data.role))
      }
      if (data.operation === 'edit') {
        queue.add(async() => await editPermission(data.endpoint, data.role, data.data))
      }
    })
    // Adds the refresh list callback only if there's changes to
    // be made in order to avoid unnecessary page reloads
    if (queue.size) {
      queue.onIdle()
        .then(() => refreshPermissionList())
    }

    return queue
  }

  async function createPermission(permission) {
    if (permission) {
      return await roleAndPermissionAction.createPermission(permission)
      .then(() => showSuccessToast('Permission updated.'))
    }
  }

  async function removePermission(endpoint, role) {
    if (endpoint && role) {
      return await roleAndPermissionAction.removePermission(endpoint, role)
      .then(() => showSuccessToast('Permission updated.'))
    }
  }

  async function editPermission(endpoint, role, data) {
    if (endpoint && role && data) {
      return await roleAndPermissionAction.editPermission(endpoint, role, data)
      .then(() => showSuccessToast('Permission updated.'))
    }
  }

  function getRequestOperation(endpoint, role, value) {
    const permissionExists = roleAndPermissionExists(endpoint, role)
    if (permissionExists && !value) {
      return 'remove'
    }

    if (!permissionExists && value) {
      return 'create'
    }
  }

  function roleAndPermissionExists(endpoint, role) {
    return permissions.some(permission => permission.endpoint === endpoint && permission.role === role)
  }

  function getEventName(event) {
    return {
      GET: 'Get',
      POST: 'Create',
      DELETE: 'Remove',
      PATCH: 'Edit',
      PUT: 'Set'
    }[event] || ''
  }

  function formatRoleName(role) {
    // we need to remove the '.' in order
    // to React Table use it as accessor name
    return String(role).split('.').join('') || ''
  }

  const columns = [
    {
      Header: 'Event',
      id: 'event',
      accessor: row => getEventName(row.event),
      filterType: 'checkbox'
    },
    {
      Header: 'Path',
      accessor: 'path'
    },
    ...roles
    .map(role => {
      return {
        Header: role.role,
        accessor: role.formattedName,
        dataType: 'boolean',
        componentName: 'checkbox',
        filterType: 'checkbox'
      }
    }),
    {
      Header: 'Ignore Org',
      id: 'ignoreOrg',
      accessor: 'ignoreOrg',
      dataType: 'array',
      componentName: 'dropdown',
      componentProps: {
        options: roles.map(role => {
          return {
            value: role.role,
            label: role.role
          }
        }),
        isMulti: true
      }
    }
  ]
  return (
    <div className='role-and-permissions-list'>
      <Table
        id={tableId}
        title='Permissions'
        data={rolesAndPermissionsList}
        onEditSubmit={handleOnEdit}
        columns={columns}
        isMultiSelect={false}
      />
    </div>
  )
}

export default RoleAndPermissionList
