import React, { useState } from 'react'
import { Button } from '@atoms'
import { TextInput, DisplayMessage } from '@molecules'
import { useSigninStore } from '@stores'
import { Form } from '@organisms'
import { useAuth, useToast } from '@hooks'

function ForgotPasswordForm(props) {
  const [username, setUsername] = useState('')
  const { showSuccessToast } = useToast()
  const [forgotPasswordError, setForgotPasswordError] = useState(null)
  const { setDisplayMode } = useSigninStore(state => state)
  const { forgotPassword } = useAuth()

  const handleOnSubmit = () => {
    setForgotPasswordError(null)
    forgotPassword(username)
    .then(response => {
      showSuccessToast('Please check your email for the verification code.')
      setDisplayMode('confirmCode')
    })
    .catch(err => setForgotPasswordError(err))
  }

  const onCancel = () => {
    setDisplayMode('signIn')
  }

  return (
    <div className="signin__form">
      <Form
        onSubmit={handleOnSubmit}
        hasOutline={false}
      >
        <TextInput
          name="username"
          label="Username"
          labelStyle={{ color: 'white' }}
          onChange={e => setUsername(e.target.value)}
          value={username}
        />
        <div className="signin__form-buttons">
          <Button
            size='sm'
            typeVariant="action"
            onClick={onCancel}
          >
            <span className='signin__form-action-button-text'>
              Cancel
            </span>
          </Button>
          <Button
            size='sm'
            type='submit'
            typeVariant='secondary'
          >
            Submit
          </Button>
        </div>
      </Form>
      {
        forgotPasswordError
          ? <DisplayMessage
            type={'error'}
            message={
              <span>{forgotPasswordError.message}</span>
            }
          />
          : null
      }
    </div>
  )
}

export default ForgotPasswordForm
