import React from 'react'
import { Button, ToggleSwitch } from '@atoms'
import { RadioGroup, TextInput, Dropdown } from '@molecules'
import { Form, Fieldset } from '@organisms'
import { ORG } from '@constants'
import { arrayUtils } from '@utils'

function BuyerSettingsStep(props) {
  const { validateAndGoNextStep } = props.useMultiSteps(props)

  const requiredFields = []

  function goNextStep() {
    // here it validates the other fields and go next step if all required
    // fields are filled.
    validateAndGoNextStep(requiredFields)
  }

  return (
    <Form title="Buyer Settings">
      {props.getState('buyerRole') && (
        <>
          <Fieldset legend={'Limits'}>
            <div>
              <TextInput
                label="Purchase Limit"
                name="purchaseLimit"
                type="number"
                value={props.getState('purchaseLimit')}
                onChange={props.handleChange}
              />
              <TextInput
                label="Purchase Minimum"
                name="purchaseMinimum"
                type="number"
                value={props.getState('purchaseMinimum')}
                onChange={props.handleChange}
              />
              <TextInput
                label="Shipping Minimum"
                name="shippingMinimum"
                type="number"
                value={props.getState('shippingMinimum')}
                onChange={props.handleChange}
              />
            </div>
          </Fieldset>

          <Fieldset legend="Saw Plane">
            <TextInput
              label="Thickness"
              name="thickness"
              type="number"
              value={props.getState('thickness')}
              onChange={props.handleChange}
            />
            <RadioGroup
              name="units"
              label="Units"
              value={props.getState('units')}
              onChange={props.handleChange}
              options={ ORG.SAW_THICKNESS_UNITS}
            />
          </Fieldset>

          <Fieldset legend="Advanced Pricing">
            <ul className="vertical">
              <li>
                <ToggleSwitch
                  id="showCosts"
                  name="showCosts"
                  label="Show Costs"
                  checked={props.getState('showCosts')}
                  onChange={props.handleChange}
                />
              </li>
              <li>
                <ToggleSwitch
                  id="showComputedBaselines"
                  name="showComputedBaselines"
                  label="Show Color Calc Baselines"
                  checked={props.getState('showComputedBaselines')}
                  onChange={props.handleChange}
                />
              </li>
            </ul>
          </Fieldset>

          <Fieldset legend="Stone Types">
            <Dropdown
              label="High Tension"
              name="highTensionMaxParts"
              value={String(props.getState('highTensionMaxParts') ?? 1)}
              isClearable={false}
              onChange={props.handleChange}
              options={ORG.ACCEPT_HIGH_TENSION}
              infoTip={true}
            />
            <Dropdown
              label="Yellow UV"
              name="windowedStones"
              value={props.getState('rejectYellowFluor') ? 'true' : 'false'}
              isClearable={false}
              onChange={props.handleChange}
              options={ORG.ACCEPT_YELLOW_FLU}
            />
            <Dropdown
              label="Windowed Stones"
              name="acceptWindowedStones"
              value={props.getState('acceptWindowedStones')}
              isClearable={false}
              onChange={props.handleChange}
              options={arrayUtils.pickBy(ORG.ACCEPT_WINDOWED_STONES, ['value', 'label'])}
            />
            <Dropdown
              label="Blocked Stones"
              name="acceptBlockedStones"
              value={props.getState('acceptBlockedStones')}
              isClearable={false}
              onChange={props.handleChange}
              options={arrayUtils.pickBy(ORG.ACCEPT_BLOCKED_STONES, ['value', 'label'])}
            />
          </Fieldset>
        </>
      )}

      {!props.getState('buyerRole') && <>Not a Buyer</>}

      <div className="create-organization-buttons">
        <Button
          disabled={!props.hasPrev()}
          typeVariant="action"
          size="sm"
          onClick={() => props.prev()}
        >
          Previous
        </Button>
        <Button
          disabled={!props.hasNext()}
          typeVariant="primary"
          size="sm"
          onClick={() => goNextStep()}
        >
          Next
        </Button>
      </div>
    </Form>
  )
}

export default BuyerSettingsStep
