import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@atoms'
import { Table, FileUpload } from '@organisms'
import { Modal } from '@templates'
import { logisticsActions, reportActions } from '@actions'
import { useToast } from '@hooks'
import { fileUtils } from '@utils'
import { useModalStore } from '@stores'
import FileSaver from 'file-saver'

const TABS = {
  ACTIVE: 'active',
  ARCHIVED: 'archived'
}

function InvoiceFiles({
  invoiceId,
  invoice,
  refreshInvoice,
  isEditToggled
}) {
  const invoiceFiles = invoice?.Files
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [activeTab, setActiveTab] = useState({ id: TABS.ACTIVE, label: 'Active', params: 'ACTIVE' })
  const [fileDownloadLinks, setFileDownloadLinks] = useState({})
  const { showSuccessToast } = useToast()
  const { setModal } = useModalStore(state => state)
  const files = useMemo(() => {
    if (invoiceFiles) {
      return invoiceFiles
      .filter(file => file.InvoiceFiles.condition === activeTab.params)
      .map(file => (
        {
          name: file.originalName,
          downloadName: file.downloadName,
          fileId: file.id,
          condition: file.InvoiceFiles.condition,
          createdAt: file.createdAt,
          updatedAt: file.updatedAt
        }
      ))
    }
  }, [invoiceFiles, activeTab])

  async function downloadFile(url, name) {
    const file = await reportActions.getBinaryFile(url)
    FileSaver.saveAs(file.data, name)
  }

  const columns = useMemo(() => ([
    {
      Header: 'Name',
      id: 'name',
      accessor: row => {
        return (
          <a
            onClick={() => downloadFile(getFileUrl(row), row?.downloadName)}
          >
            {row?.downloadName}
          </a>
        )
      }
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      dataType: 'date',
      filterType: 'date'
    },
    {
      Header: 'Updated At',
      accessor: 'updatedAt',
      dataType: 'date',
      filterType: 'date'
    }
  ]), [files, fileDownloadLinks])

  useEffect(() => {
    if (files?.length) {
      const fileIds = files?.map(file => file.fileId)
      reportActions.getDownloadUrl(fileIds, { invoiceId })
      .then(response => {
        setFileDownloadLinks([...response.data.data.map(d => ({
          id: d.id,
          url: d.url
        }))])
      })
    }
  }, [files])

  async function handleOnFileChange(file) {
    const base64File = await fileUtils.promiseReadBase64File(file)
    setSelectedFile({ file: base64File, name: file.name })
  }

  function handleOnFileUpload() {
    if (selectedFile) {
      logisticsActions.createInvoiceFiles(invoiceId, {
        file: selectedFile.file,
        origFileName: selectedFile.name
      })
      .then(() => {
        showSuccessToast('Invoice document created.')
        setAddModalOpen(false)
        refreshInvoice()
      })
    }
  }

  async function downloadFiles(selectedRows) {
    selectedRows.reduce((links, row) => {
      links.push({ url: getFileUrl(row), name: row.downloadName })
      return links
    }, []).forEach(({ url, name }) => downloadFile(url, name))
  }

  function getFileUrl(row) {
    if (!fileDownloadLinks?.length) return ''
    return fileDownloadLinks?.find(fd => fd.id === row.fileId)?.url
  }

  const topBarActions = [
    {
      componentName: 'dropdown',
      enableOnSelect: true,
      options: [
        {
          label: 'Download Files',
          value: 'downloadFiles',
          callback: selectedRows => downloadFiles(selectedRows),
          isDisabled: isEditToggled
        },
        ...(activeTab.id === TABS.ACTIVE ? [{
          label: 'Archive',
          value: 'archive',
          callback: selectedRows => setModal({
            id: 'invoiceListArchive',
            title: 'Archive Invoice Files',
            message: `Archive ${selectedRows.length} invoice file${selectedRows.length > 1 ? 's' : ''}?`,
            onSubmit: () => handleSetCondition(selectedRows?.map(f => f.fileId), 'ARCHIVED', 'archived'),
            closeOnFail: false
          }),
          isDisabled: isEditToggled
        }] : []),
        ...(activeTab.id === TABS.ARCHIVED ? [{
          label: 'Unarchive',
          value: 'unarchive',
          callback: selectedRows => setModal({
            id: 'invoiceListUnarchive',
            title: 'Unarchive Invoice Files',
            message: `Unarchive ${selectedRows.length} invoice file${selectedRows.length > 1 ? 's' : ''}?`,
            onSubmit: () => handleSetCondition(selectedRows?.map(f => f.fileId), 'ACTIVE', 'unarchived'),
            closeOnFail: false
          }),
          isDisabled: isEditToggled
        }] : [])
      ]
    },
    ...(activeTab?.id === TABS.ACTIVE ? [{
      label: 'Upload Document',
      callback: () => setAddModalOpen(true)
    }] : [])
  ]

  const tableTabs = [
    {
      id: TABS.ACTIVE,
      label: 'Active',
      params: 'ACTIVE'
    },
    {
      id: TABS.ARCHIVED,
      label: 'Archived',
      params: 'ARCHIVED'
    }
  ]

  const rowActions = [
    {
      actionName: 'archive',
      itemType: 'invoice file',
      callback: (row) => handleSetCondition(row.fileId, 'ARCHIVED', 'archived'),
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ACTIVE'
    },
    {
      actionName: 'unarchive',
      itemType: 'invoice file',
      callback: (row) => handleSetCondition(row.fileId, 'ACTIVE', 'unarchived'),
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ARCHIVED'
    },
    {
      actionName: 'remove',
      itemType: 'invoice file',
      callback: (row) => handleSetCondition(row.fileId, 'DELETED', 'removed'),
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition !== 'DELETED'
    }
  ]

  function handleSetCondition(fileId, condition, actionText) {
    const fileIds = Array.isArray(fileId) ? fileId : [fileId]
    logisticsActions.setInvoiceFileCondition(invoiceId, { fileIds, condition })
    .then(() => showSuccessToast(`Invoice file has been ${actionText}.`))
    .then(() => refreshInvoice())
  }

  function handleOnModalClose() {
    setAddModalOpen(false)
    setSelectedFile(null)
  }

  return (
    <>
      <Table
        title='Invoice Documents'
        data={files}
        tableTabs={tableTabs}
        columns={columns}
        rowActions={rowActions}
        onTabChange={setActiveTab}
        topBarActions={topBarActions}
      />
      <Modal
        title='Upload Document'
        open={addModalOpen}
        onClose={handleOnModalClose}
      >
        <div className="invoice-files-modal">
          <FileUpload
            name='selectFile'
            label='Select File'
            onChange={handleOnFileChange}
            acceptTypes={['.xlsx', '.docx', '.pdf', '.png']}
          />
          <Button
            size='sm'
            disabled={!selectedFile}
            onClick={handleOnFileUpload}
          >
            Upload
          </Button>
        </div>
      </Modal>
    </>
  )
}

InvoiceFiles.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  invoice: PropTypes.object,
  refreshInvoice: PropTypes.func.isRequired,
  isEditToggled: PropTypes.bool
}

InvoiceFiles.defaultProps = {
  invoiceFiles: [],
  isEditToggled: false
}

export default InvoiceFiles
