import React from 'react'
import PropTypes from 'prop-types'
import { FormComponents } from '@organisms'

function CreateShapeGroup({ handleSubmit, handleCancel }) {
  const { SimpleForm, TextInput, Button } = FormComponents

  return (
    <SimpleForm onSubmit={handleSubmit}>
      <TextInput
        name='description'
        label='Description'
        required={true}
      />
      <div key='row-buttons' className='modal__buttons'>
        <Button typeVariant='secondary' size='sm' onClick={handleCancel}>Cancel</Button>
        <Button type='submit' typeVariant='primary' size='sm'>Create</Button>
      </div>
    </SimpleForm>
  )
}
CreateShapeGroup.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired
}
CreateShapeGroup.defaultProps = {
}

export default CreateShapeGroup
