import React, { useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { TextInput } from '@molecules'
import { useTableStore } from '@stores'

function NumberRangeFilter({
  column,
  tableId,
  visible,
  preFilteredRows
}) {
  const {
    filterValue = [],
    setFilter,
    id
  } = column

  const {
    setFilterValue,
    setIsFilteredFn,
    getTableConfig,
    getFilterValue
  } = useTableStore(state => state)

  const storeFilterValue = getFilterValue(tableId, id)
  const tableConfig = getTableConfig(tableId)
  const minInputRef = useRef()

  const [min, max] = useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  useEffect(() => {
    setFilter(storeFilterValue?.value)
  }, [tableConfig?.activeTab?.id, tableConfig?.activeTab?.label])

  useEffect(() => {
    setIsFilteredFn(tableId, id, (filter) => !!filter?.length && (filter[0] != null || filter[1] != null))
  }, [tableConfig?.activeTab?.id, tableConfig?.activeTab?.label])

  useEffect(() => {
    if (visible) {
      minInputRef.current.focus()
    }
  }, [visible])

  return (
    <div className='table-number-range-filter'>
      <TextInput
        inputRef={minInputRef}
        label='Min'
        size='sm'
        value={filterValue[0] ?? ''}
        name='min'
        type="number"
        onChange={e => {
          const val = e.currentTarget.value
          setFilterValue(tableId, id, [val, filterValue[1]])
          setFilter([val, filterValue[1]])
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '70px',
          marginRight: '0.5rem'
        }}
      />
      <span className='table-number-range-filter__separator'> - </span>
      <TextInput
        label='Max'
        size='sm'
        name='max'
        value={filterValue[1] ?? ''}
        type="number"
        onChange={e => {
          const val = e.currentTarget.value
          setFilterValue(tableId, id, [filterValue[0], val])
          setFilter([filterValue[0], val])
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '70px',
          marginLeft: '0.5rem'
        }}
      />
    </div>
  )
}

NumberRangeFilter.propTypes = {
  column: PropTypes.object,
  tableId: PropTypes.string,
  setIsFiltered: PropTypes.func,
  preFilteredRows: PropTypes.arrayOf(PropTypes.object),
  visible: PropTypes.bool
}

export { NumberRangeFilter }
