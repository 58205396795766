import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@atoms'

function RoughCommentIcon({ comments, onClick }) {
  return (
    <div
      onClick={onClick}
      className="rough-comment__counter"
    >
      {
        comments?.length
          ? <div className="rough-comment__counter-number">
            {comments.length}
          </div>
          : null
      }
      <Icon
        name='comment'
        className={`rough-comment__icon${!comments?.length ? '--no-comments' : ''}`}
        size='lg'
      />
    </div>
  )
}

RoughCommentIcon.propTypes = {
  onClick: PropTypes.func,
  comments: PropTypes.arrayOf(PropTypes.object)
}

RoughCommentIcon.defaultProps = {
  comments: []
}

export default RoughCommentIcon
