import React from 'react'
import { useToasts } from 'react-toast-notifications'
import { Button } from '@atoms'
import { useMultiErrorModalStore } from '@stores'

function useToast() {
  const {
    addToast,
    removeAllToasts
  } = useToasts()

  const {
    setIsOpen,
    setErrors,
    resetErrorModalStore
  } = useMultiErrorModalStore(state => state)

  function showErrorToast(message) {
    addToast(message, {
      appearance: 'error',
      autoDismiss: true,
      autoDismissTimeout: 15000
    })
  }

  function showWarningToast(message) {
    addToast(message, {
      appearance: 'warning',
      autoDismiss: true,
      autoDismissTimeout: 15000
    })
  }

  function showSuccessToast(message) {
    addToast(message, {
      appearance: 'success',
      autoDismiss: true
    })
  }

  function showInfoToast(message) {
    addToast(message, {
      appearance: 'info',
      autoDismiss: true
    })
  }

  function showMultiErrorsToast(errors, errorsLimit = 0) {
    if (!errors?.length) return
    setErrors(errors)
    const remainingErrorsCount = errors.length - errorsLimit
    addToast(
      <div>
        <span>And {remainingErrorsCount} more...</span>
        <Button
          typeVariant='action'
          size='sm'
          onClick={() => setIsOpen(true)}
        >
          Read More
        </Button>
      </div>,
      {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 15000,
        onDismiss: () => resetErrorModalStore()
      })
  }

  return {
    showErrorToast,
    showMultiErrorsToast,
    showWarningToast,
    showSuccessToast,
    showInfoToast,
    removeAllToasts
  }
}

export {
  useToast
}
