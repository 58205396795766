import React, { useEffect, useMemo } from 'react'
import { useGlobalsStore } from '@stores'
import { Button, Icon } from '@atoms'
import { ErrorIcon } from '@molecules'
import { numberUtils } from '@utils'

function OrderLinesList({ orderLines, selectedOrderLine, selectOrderLine, addOrderLine, deleteOrderLine, disabled, validationText, ...props }) {
  const {
    polishedColoursMap: coloursMap,
    claritiesMap
  } = useGlobalsStore(state => state)

  function getLineTitle(line) {
    return `${numberUtils.numFmt(line.minWeight, 2, { numbersOnly: true, fallbackValue: '' })} - ${numberUtils.numFmt(line.maxWeight, 2, { numbersOnly: true, fallbackValue: '' })} | ${coloursMap?.[line.maxColour] ?? '?'} to ${coloursMap?.[line.minColour] ?? '?'} | ${claritiesMap?.[line.maxClarity] ?? '?'} to ${claritiesMap?.[line.minClarity] ?? '?'} | ${line.minFluorescence ?? '?'} to ${line.maxFluorescence ?? '?'}`
  }

  const warning = useMemo(() => validationText?.groupWarningByKey(orderLines, 'id'), [validationText, orderLines])
  const error = useMemo(() => validationText?.groupErrorByKey(orderLines, 'id'), [validationText, orderLines])

  useEffect(() => {
    if (orderLines?.length && !selectedOrderLine) {
      selectOrderLine(orderLines[0].id)
    }
  }, [orderLines])

  return (
    <div className="order-lines__list">
      {orderLines.map((line, idx) => (
        <div key={line.id} className='order-lines__list-row'>
          <Button
            size='sm'
            typeVariant={line.id === selectedOrderLine ? 'primary' : 'secondary'}
            onClick={() => selectOrderLine(line.id)}
          >
            {getLineTitle(line)}
          </Button>
          {!disabled
            && <>
              <div
                className="cursor-pointer"
                onClick={() => deleteOrderLine(line.id)}
              >
                <Icon
                  size='md'
                  name='close'
                />
              </div>
              <ErrorIcon error={error?.[line?.id]} warning={warning?.[line?.id]} />
            </>
          }

        </div>
      ))}
      {!disabled
        && <div className="order-lines__list-row">
          <Button
            size='sm'
            onClick={addOrderLine}
          >
          Add Order Line
          </Button>
          <ErrorIcon warning={validationText?.getWarning()?.length} error={validationText?.getError()?.length}/>
        </div>
      }
    </div>
  )
}

export default OrderLinesList
