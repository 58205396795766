import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTableStore } from '@stores'
import { Checkbox } from '@atoms'
import { TextInput, Dropdown } from '@molecules'
import { numberUtils, textUtils } from '@utils'
// TODO: add support to Links
// TODO: add support to text area
function DefaultCell({
  value: initialValue,
  row,
  column,
  tableId
}) {
  const {
    getEditRowIndex,
    getIsEditing,
    setEditValue
  } = useTableStore(state => state)

  const [value, setValue] = useState(initialValue)
  const [fieldContent, setFieldContent] = useState(<span></span>)
  const editRowIndex = getEditRowIndex(tableId)
  const isEditing = getIsEditing(tableId)

  useEffect(() => {
    setFieldContent(getFieldContent())
  }, [editRowIndex, value])

  useEffect(() => {
    if (!isEditing) {
      setValue(initialValue)
    }
  }, [isEditing, initialValue])

  function onChange (e) {
    const newValue = e?.target?.value ?? e?.currentTarget?.value
    const name = e?.target?.name ?? e?.currentTarget?.name

    setValue(newValue)
    setEditValue(tableId, name, newValue, initialValue)
  }

  function onCheckBoxChange(e) {
    setValue(e.target.checked)
    setEditValue(tableId, e.target.name, e.target.checked, initialValue)
  }

  function getFieldContent() {
    if (row.index === editRowIndex) {
      const componentProps = column.componentProps && typeof column.componentProps === 'function'
        ? column.componentProps(row)
        : typeof column.componentProps === 'object'
          ? column.componentProps
          : {}

      // if row is on edit mode
      if (String(column.componentName).toLowerCase() === 'textinput') {
        return <TextInput
          name={column.id}
          value={value}
          onChange={onChange}
          {...componentProps}
        />
      }

      if (String(column.componentName).toLowerCase() === 'checkbox') {
        return <Checkbox
          name={column.id}
          checked={value}
          onChange={onCheckBoxChange}
          {...componentProps}
        />
      }

      if (String(column.componentName).toLowerCase() === 'dropdown') {
        return <Dropdown
          name={column.id}
          value={value}
          onChange={onChange}
          options={column.options}
          {...componentProps}
        />
      }
    }

    if (column.dataType === 'date') {
      return textUtils.formatDate(value)
    }

    if (column.dataType === 'dateTime') {
      return textUtils.formatDate(value, true)
    }

    if (column.dataType === 'boolean') {
      return <Checkbox
        name={column.id}
        checked={value}
        onChange={onCheckBoxChange}
        disabled={true}
        {...column.componentProps}
      />
    }

    if (column.dataType === 'currency') {
      return <span className='text-nowrap'>{numberUtils.numFmt(value, column.decimalScale ?? null, { prefix: '$', thousandSeperator: true, numbersOnly: true, fallbackValue: '' })}</span>
    }

    if (column.dataType === 'array' && value?.length) {
      return value.map(v => v).join(', ')
    }

    return value ?? ''
  }

  return fieldContent
}

DefaultCell.propTypes = {
  initialValue: PropTypes.any,
  row: PropTypes.object,
  column: PropTypes.object,
  tableId: PropTypes.string
}

export default DefaultCell
