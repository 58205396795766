import create from 'zustand'
import { planningActions } from '@actions'
import { cacheList } from './storeUtils'

const plannedStonesListPromise = {}
const qcPlannedStonesListPromise = {}

const usePlanningStore = create((set, get) => ({
  /*
  plannedStonesList structure
    plannedStonesList: {
      [paramString]: {
        data: [/plannedStone objects/],
        lastUpdated: /date-time of last request/
        cacheInvalid: /bool flag to indicate if data has been manipulated/
      }
      ...
    }
  */
  plannedStonesList: {},
  /*
  cacheList:
    getPlannedStonesList,
    setPlannedStonesList,
    removePlannedStonesListItems,
    removePlannedStonesList,
    removeAllPlannedStonesListItems,
    removeAllPlannedStonesList,
  */
  ...cacheList({
    state: 'plannedStonesList',
    zustandMethods: { get, set },
    promise: plannedStonesListPromise,
    batchAction: planningActions.getPlannedStonesBatch
  }),
  /*
  qcPlannedStonesList structure
    qcPlannedStonesList: {
      [paramString]: {
        data: [/qcPlannedStone objects/],
        lastUpdated: /date-time of last request/
        cacheInvalid: /bool flag to indicate if data has been manipulated/
      }
      ...
    }
  */
  qcPlannedStonesList: {},
  /*
  cacheList:
    getQcPlannedStonesList,
    setQcPlannedStonesList,
    removeQcPlannedStonesListItems,
    removeQcPlannedStonesList,
    removeAllQcPlannedStonesListItems,
    removeAllQcPlannedStonesList,
  */
  ...cacheList({
    state: 'qcPlannedStonesList',
    zustandMethods: { get, set },
    promise: qcPlannedStonesListPromise,
    batchAction: planningActions.getQcPlannedStonesBatch
  }),
  resetPlanningStore: () => {
    set({
      plannedStonesList: {},
      qcPlannedStonesList: {}
    })
  }
}))

export {
  usePlanningStore
}
