import clone from 'just-clone'
import deepEqual from 'fast-deep-equal/es6/react'

const pick = (o, keys, deep = true) => keys.reduce((accum, curr) => ({
  ...accum,
  [curr]: (deep && typeof o[curr] === 'object' && o[curr]) ? clone(o[curr]) : o[curr]
}), {})

const omit = (oldObject, keys, deep = true) => {
  const newObject = deep ? clone(oldObject) : { ...oldObject }
  for (const key of keys) delete newObject[key]
  return newObject
}

const isObject = obj => {
  return obj && (typeof obj === 'object') && !Array.isArray(obj)
}

// check if an object is empty
// {} -> true, '' -> false, null -> false, undefined -> false, [] -> false
const isEmpty = (o) => Boolean(o
  && Object.keys(o).length === 0
  && Object.getPrototypeOf(o) === Object.prototype
)

const filterNullish = (o = {}, deep = false) => Object.entries(o).reduce((accum, [currK, currV]) => ({
  ...accum,
  ...(
    currV != null
    && !(typeof currV === 'object' && deep && isEmpty(filterNullish(currV, deep)))
    && {
      [currK]: currV?.constructor?.name === 'Object' && deep
        ? filterNullish(currV, deep)
        : currV
    })
}), {})

// Helper to call a variable if it is a function or return its value if it is truthy
// Accept a default to return if the prop is not of a valid type
const callProp = (prop, args = [], defaultProp = null) => {
  if (typeof prop === 'function') {
    return prop(...args)
  } else if (prop && !isEmpty(prop)) {
    return prop
  }

  if (defaultProp == null) return null
  return callProp(defaultProp)
}

const deepMerge = (obj1, obj2) => {
  if (!isObject(obj1) || !isObject(obj2)) return obj2
  for (const key in obj2) {
    if (key in obj1) obj1[key] = deepMerge(obj1[key], obj2[key])
    else obj1[key] = obj2[key]
  }
  return obj1
}

const removeEmptyStrings = (obj, opts) => replaceObjectValues(obj, '', opts)
const removeNulls = (obj, opts) => replaceObjectValues(obj, null, opts)
const removeEmptyArrays = (obj, opts) => replaceObjectValues(obj, [], opts)
const replaceObjectValues = (obj, valueToReplace, opts = {}) => {
  const replacement = 'replacement' in opts ? opts.replacement : null
  const deep = opts.deep ?? false
  if (Array.isArray(obj)) {
    return obj.map(elem => deepEqual(elem, valueToReplace) ? replacement : (deep ? replaceObjectValues(elem, valueToReplace, opts) : elem))
  } else if (obj?.constructor?.name === 'Object') {
    return Object.entries(obj).reduce((newObj, [key, val]) => ({
      ...newObj,
      ...(deepEqual(val, valueToReplace) ? (replacement === undefined ? {} : { [key]: replacement }) : { [key]: deep ? replaceObjectValues(val, valueToReplace, opts) : val })
    }), {})
  } else return deepEqual(obj, valueToReplace) ? replacement : obj
}

export default { isObject, pick, omit, isEmpty, filterNullish, callProp, deepMerge, removeEmptyStrings, removeNulls, removeEmptyArrays }
