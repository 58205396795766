import { requestUtils } from '@utils'
import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_BASE_URL

const getLogs = function (params, options) {
  return axios.get(`${BASE_URL}/reports/logs`, { params, options })
}
getLogs.endpoint = 'GET|/reports/logs'

const getLogsBatch = requestUtils.batchRequest(getLogs)

export const auditActions = {
  getLogs,
  getLogsBatch
}
