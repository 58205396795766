import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ConfirmationModal } from '@templates'
import { textUtils } from '@utils'
import { useToast } from '@hooks'

function InvoicePaymentConditionModal({
  open,
  invoices,
  payments,
  operation,
  action,
  onClose
}) {
  const ids = payments?.length ? payments : invoices
  const itemsText = payments?.length ? 'payments' : 'invoices'
  const { showSuccessToast } = useToast()
  const [isOpen, setIsOpen] = useState(open)

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  function doAction(action) {
    if (action) {
      action()
      .then(async () => {
        showSuccessToast(`${ids?.length} ${itemsText} has been ${operation}d`)
        handleOnClose({ shouldFetch: true })
      })
    }
  }

  function handleOnClose({ shouldFetch }) {
    if (onClose && typeof onClose === 'function') onClose(shouldFetch)
    setIsOpen(false)
  }

  return (
    <ConfirmationModal
      title={`${textUtils.capitalize(operation)}?`}
      open={isOpen}
      onClose={() => handleOnClose({ shouldFetch: false })}
      message={`${textUtils.capitalize(operation)} ${ids?.length || 0} ${itemsText}?`}
      onSubmit={() => doAction(action)}
    />
  )
}

InvoicePaymentConditionModal.propTypes = {
  open: PropTypes.bool,
  invoices: PropTypes.arrayOf(PropTypes.string),
  payments: PropTypes.arrayOf(PropTypes.string),
  operation: PropTypes.string,
  action: PropTypes.func,
  onClose: PropTypes.func
}

InvoicePaymentConditionModal.defaultProps = {
  open: false,
  invoices: [],
  payments: [],
  operation: ''
}

export default InvoicePaymentConditionModal
