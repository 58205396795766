import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from '@atoms'
import { useHistory } from 'react-router-dom'
import NotificationModal from './notification_modal'
import { notificationButtonActions } from '@actions'
import moment from 'moment'
import parse from 'html-react-parser'
import { useAuthStore } from '@stores'

function parseProps(isRead, type, notifyTime) {
  return {
    notification: {
      className: getNotificationClassName(isRead)
    },
    icon: {
      className: 'notification__icon' + getClassModifier(isRead, type),
      name: getIconName(type)
    },
    body: {
      className: 'notification__body' + getClassModifier(null, type)
    },
    actionBtns: {
      className: 'notification__action-btns' + getClassModifier(null, type)
    },
    notifyTime: {
      formattedTime: formatNotifyTime(notifyTime)
    },
    modal: {
      titleClassName: 'notification__modal-title' + getClassModifier(null, type)
    }
  }
}

function getClassModifier(isRead, type) {
  const className = {
    INFO: '--info',
    CALENDAR: '--info',
    ERROR: '--high-attention',
    SUCCESS: '--success'
  }[type] || '--info'

  return className + (isRead ? '--is-read' : '')
}

function getIconName(type) {
  return {
    INFO: 'info',
    CALENDAR: 'info',
    ERROR: 'error',
    WARNING: 'warning',
    SUCCESS: 'check'
  }[type] || 'info'
}

function formatNotifyTime(notifyTime) {
  return moment(notifyTime).calendar(null, {
    sameDay: '[Today at] LT ',
    lastDay: '[Yesterday at] LT',
    sameElse: 'MMM Do, YYYY [at] LT'
  })
}

function getNotificationClassName(isRead) {
  return 'notification' + (isRead ? '--is-read' : '')
}

function Notification({ notification, markAsRead }) {
  const history = useHistory()
  const { hasPermission } = useAuthStore()
  const {
    notificationId,
    userId,
    notifyTime,
    isRead,
    type,
    title,
    actions
  } = notification

  // assigning body variable separately because
  // it's only possible to default a value
  // when destructuring an object if the
  // value is undefined, meaning that if
  // the body is null it would result in a crash
  // when parsing it from html string to jsx below
  const body = notification?.body || ''

  const [
    isNotificationModalOpen,
    setIsNotificationModalOpen
  ] = useState(false)

  const parsedProps = parseProps(
    isRead,
    type,
    notifyTime
  )

  function handleOnActionClick(action) {
    if (action.type in notificationButtonActions) notificationButtonActions[action.type]({ history, hasPermission })(action.args)
    else console.warn(`${action.type} handling not yet implemented.`)
  }

  return (
    <>
      <NotificationModal
        body={parse(body)}
        title={title}
        isOpen={isNotificationModalOpen}
        setOpen={setIsNotificationModalOpen}
        iconName={parsedProps.icon.name}
        notifyTime={parsedProps.notifyTime.formattedTime}
        titleClassName={parsedProps.modal.titleClassName}
      />
      <div
        className={parsedProps.notification.className}
        onClick={() => markAsRead(userId, notificationId)}
      >
        <div className="notification__content">
          <div className={parsedProps.icon.className}>
            <Icon name={parsedProps.icon.name} />
          </div>
          <div className={parsedProps.body.className}>
            <div className="notification__title">
              <span>{title}</span>
            </div>
            <p className="notification__body-text">
              {parse(body)}
            </p>
            <div className="notification__notify-time">
              <span>
                {parsedProps.notifyTime.formattedTime}
              </span>
            </div>
            <div className="notification__read-more">
              {
                String(body).length > 61
                  ? <div className="notification__read-more-btn">
                    <Button
                      onClick={() => setIsNotificationModalOpen(true)}
                      typeVariant='action'>Read more
                    </Button>
                  </div> : null
              }
            </div>
          </div>
        </div>
        <div className={parsedProps.actionBtns.className}>
          {
            actions && actions.length ? actions.map((action, index) => {
              return (
                <React.Fragment key={index}>
                  <Button
                    key={index}
                    onClick={() => handleOnActionClick(action)}
                    typeVariant="action"
                  >
                    {action.name}
                  </Button>
                  &nbsp; &nbsp;
                </React.Fragment>
              )
            }) : null
          }
        </div>
      </div>
    </>
  )
}

Notification.propTypes = {
  notification: PropTypes.object,
  markAsRead: PropTypes.func.isRequired
}

Notification.defaultProps = {
  notification: {
    type: 'INFO',
    isRead: false,
    body: ''
  },
  markAsRead: () => null
}

export default Notification
