import React, { useState, useEffect } from 'react'
import { Table } from '@organisms'
import { orderActions, shapeGroupActions } from '@actions'
import { useGlobalsStore, useModalStore } from '@stores'
import { useToast } from '@hooks'
import AssignOrders from './modals/assignOrders'
import { arrayUtils } from '@utils'

function ShapeGroupOrdersList({ shapeGroupId }) {
  const [orderList, setOrderList] = useState([])
  const {
    orderStatusesMap,
    orgsList: { all: orgsList },
    getOrderStatuses,
    getOrgsList
  } = useGlobalsStore(state => state)

  const { setModal } = useModalStore()
  const { showSuccessToast } = useToast()

  useEffect(() => {
    getOrderStatuses()
    getOrgsList()
  }, [])

  useEffect(() => {
    if (shapeGroupId) refreshList(shapeGroupId)
  }, [shapeGroupId])

  function refreshList(id) {
    orderActions.getOrderList({ condition: 'ACTIVE', shapeGroupId: id })
    .then(response => setOrderList(response.data.data))
    .catch(err => console.error(err))
  }

  async function handleAssignOrders(form) {
    await shapeGroupActions.setOrderShapeGroups({ shapeGroupId, ...form })
    showSuccessToast('Shape Group created.')
    await refreshList(shapeGroupId)
  }

  const [columns, setColumns] = useState([])
  useEffect(() => {
    setColumns([
      {
        Header: 'Order ID',
        accessor: 'id',
        dataType: 'number'
      },
      {
        Header: 'Order Name',
        accessor: 'name',
        dataType: 'string'
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: row => orderStatusesMap?.[row.status] ?? '',
        dataType: 'string',
        filterType: 'checkbox',
        enums: Object.values(orderStatusesMap)
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
        dataType: 'date',
        filterType: 'date'
      }
    ])
  }, [orderStatusesMap])

  async function handleRemove(row) {
    await shapeGroupActions.setOrderShapeGroups({ shapeGroupId, genericOrderIds: arrayUtils.pickBy(arrayUtils.differenceBy(orderList, [row], x => x.id), 'id') })
    showSuccessToast('Order has been removed.')
    await refreshList(shapeGroupId)
  }

  const rowActions = [
    {
      actionName: 'remove',
      callback: handleRemove,
      shouldConfirm: true
    }
  ]

  const topBarActions = [{
    label: 'Manage Orders',
    callback: () => setModal({
      id: 'assignShapeGroupOrder',
      title: 'Manage Orders',
      type: 'form',
      customMessageRenderer: (messageProps) => <AssignOrders {...messageProps} orderList={orderList} orgsList={orgsList} />,
      onSubmit: async (res) => await handleAssignOrders(res[0])
    })
  }]

  const initialSort = [{
    id: 'updatedAt',
    desc: true
  },
  {
    id: 'name',
    desc: false
  }]

  return <Table
    title='Orders'
    data={orderList}
    columns={columns}
    getDataCallback={() => refreshList(shapeGroupId)}
    isMultiSelect={false}
    rowActions={rowActions}
    topBarActions={topBarActions}
    initialSort={initialSort}
  />
}

export default ShapeGroupOrdersList
