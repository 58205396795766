import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { ErrorText, ErrorBanner, TextInput } from '@molecules'
import { useOrderStore } from '@stores'
import { objectUtils, validationUtils } from '@utils'

function PricingPpcGrid({
  priceTable,
  priceVar,
  colours,
  clarities,
  pmColours,
  disabled,
  validationText,
  onChange
}) {
  const [formatType, formatPrefix, formatSuffix, numberFormat] = priceVar === 'ppc' ? ['currency', '', '', false] : ['number', '', ' %', true]
  const [forceRenderValue, setForceRenderValue] = useState(0)
  const {
    painterValue,
    isPainterEnabled
  } = useOrderStore(state => state)

  function handleOnChange(e) {
    setValue(e.currentTarget.name, e.currentTarget.value)
  }

  function handleOnFocus(e) {
    if (isPainterEnabled) {
      setValue(e.target.name, painterValue)
      setForceRenderValue(forceRenderValue + 1)
    }
  }

  function getValue(colour, clarity) {
    const price = priceTable?.find(price => price.colour === colour && price.clarity === clarity)
    return price?.[priceVar] ?? ''
  }

  function setValue(fieldName, value) {
    const fieldColour = String(fieldName).split('/')[0]
    const clarity = String(fieldName).split('/')[1]
    const fieldColours = pmColours === false ? colours.filter(c => c.value === fieldColour || (c.rapColour === fieldColour && !c.isBase)).map(c => c.value) : [fieldColour]
    const newPriceTable = [...priceTable]
    for (const colour of fieldColours) {
      const priceIdx = newPriceTable?.findIndex(price => price.colour === colour && price.clarity === clarity)
      if (priceIdx >= 0) {
        newPriceTable.splice(priceIdx, 1, { ...newPriceTable[priceIdx], [priceVar]: value })
      } else {
        newPriceTable.push({ colour, clarity, [priceVar]: value })
      }
    }
    onChange(newPriceTable)
  }

  function parseValidation() {
    // Only get base level warning/error
    return validationUtils.validation({
      warning: objectUtils.isObject(validationText.getWarning()) ? null : validationText.getWarning(),
      error: objectUtils.isObject(validationText.getError()) ? null : validationText.getError()
    })
  }
  function parseCellValidation(colour, clarity) {
    return validationText?.parseStep([`${colour}|${clarity}`, priceVar === 'ppc' ? 'ppc' : 'percentage'])
  }
  const pricingValidation = useMemo(() => parseValidation(), [validationText])

  return (
    <div className='orders-pricing-grid' >
      <span className='subtext w-100'>
        <ErrorBanner classNames={[pricingValidation?.getValidationClass()]} {...pricingValidation} />
      </span>
      <br/>
      <i>
        {priceVar === 'ppc' ? 'Please enter the price per carat values in each field below'
          : 'Please enter discounts from the baseline as negative numbers (e.g. 30% below entered as -30)'}
      </i>
      <br/><br/>
      {
        colours.map((colour, colourIndex) => {
          if (pmColours === false && !colour.isBase) return null
          return (
            <div
              key={colour.description}
              className='orders-pricing-grid__row'
            >
              {
                <span className="orders-pricing-grid__colour-label">
                  <strong>{colour.description}</strong>
                </span>
              }
              {
                clarities.map(clarity => {
                  return (
                    <div
                      key={`${colour.description}${clarity.description}`}
                      className='orders-pricing-grid__cell'
                    >
                      {
                        colourIndex === 0
                          ? <span className="subtext"><strong>{clarity.description}</strong></span>
                          : null
                      }
                      <TextInput
                        type={formatType}
                        onChange={handleOnChange}
                        onFocus={handleOnFocus}
                        name={`${colour.value}/${clarity.value}`}
                        value={getValue(colour.value, clarity.value)}
                        numberFormat={numberFormat}
                        formatPrefix={formatPrefix}
                        formatSuffix={formatSuffix}
                        label={''}
                        disabled={disabled}
                        fixedDecimalScale={true}
                        classNames={[parseCellValidation(colour.value, clarity.value)?.getValidationClass()]}
                        // validationText={parseCellValidation(colour.value, clarity.value)}
                      />
                      <span className='orders-pricing-grid__bottom-label'>
                        {`${colour.description} / ${clarity.description}`}
                        <ErrorText {...parseCellValidation(colour.value, clarity.value)} />
                      </span>
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
    </div >
  )
}

PricingPpcGrid.propTypes = {
  priceTable: PropTypes.arrayOf(PropTypes.object),
  priceVar: PropTypes.string.isRequired,
  colours: PropTypes.array,
  clarities: PropTypes.array,
  pmColours: PropTypes.bool,
  disabled: PropTypes.bool,
  validationText: PropTypes.object,
  onChange: PropTypes.func.isRequired
}

PricingPpcGrid.defaultProps = {
  priceTable: [],
  onChange: () => console.error('onChange not implemented!')
}
export default PricingPpcGrid
