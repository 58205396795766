import React, { useState, useEffect, useRef } from 'react'
import { auditActions } from '@actions'
import { useGlobalsStore } from '@stores'
import { Button } from '@atoms'
import { Dropdown, TextInput } from '@molecules'
import { Table } from '@organisms'
import LogDetails from './details'
import { objectUtils } from '@utils'
import { useToast } from '@hooks'

function LogView() {
  const {
    usersList,
    getUsersList,
    orgsList: { all: orgsList },
    getOrgsList
  } = useGlobalsStore()
  useEffect(() => {
    getOrgsList()
    getUsersList()
  }, [])
  const { showErrorToast } = useToast()
  const detailsRef = useRef()

  const [selectedFilters, setSelectedFilters] = useState({ columns: '[event_id, schema_name, table_name, session_user_name, action_tstamp, user_name, row_id, action]' })
  function handleSearchChange(e) {
    const filterName = e.currentTarget.name
    const filterValue = e.currentTarget.value || undefined
    const newFilter = { [filterName]: filterValue }
    if (filterName === 'tableName') {
      if (dynamoTables.includes(filterValue)) newFilter.dynamo = true
      else newFilter.dynamo = false
    }
    setSelectedFilters({ ...selectedFilters, ...newFilter })
  }

  const [selectedEvent, setSelectedEvent] = useState(null)
  async function setSelectedEventByRow(eventId) {
    return auditActions.getLogs({ eventId })
    .then(async result => setSelectedEvent(result.data.data[0]))
    .catch(err => showErrorToast(err.message))
  }

  const [logs, setLogs] = useState([])
  async function refreshLogs() {
    setLogs([])
    setSelectedEvent(null)
    const batchIter = auditActions.getLogsBatch(selectedFilters, { progress: true })
    // Skip processing the first exit of the generator
    batchIter.next()
    const results = await batchIter
      .next()
      .catch(console.error)
    setLogs(results.value)
  }

  const [logColumns, setLogColumns] = useState([])
  useEffect(() => {
    setLogColumns([
      {
        Header: 'Change ID',
        accessor: 'event_id',
        dataType: 'integer',
        Cell: cellInfo => {
          return (
            <a
              className='link'
              id={cellInfo.value}
              onClick={async () => {
                await setSelectedEventByRow(cellInfo.value)
                detailsRef.current.scrollIntoView({ behavior: 'smooth' })
              }}
            >
              {cellInfo.value}
            </a>
          )
        }
      },
      { Header: 'User', id: 'user', accessor: row => usersList?.find(u => u.userId === row.user_name)?.name, dataType: 'string' },
      { Header: 'Table', accessor: 'table_name', dataType: 'string', filterType: 'checkbox' },
      { Header: 'Action', accessor: 'action', dataType: 'string', filterType: 'checkbox', enums: ['I', 'U', 'D'] },
      { Header: 'Date', accessor: 'action_tstamp', dataType: 'date', filterType: 'date' },
      { Header: 'ID', accessor: 'row_id', dataType: 'string' }
    ])
  }, [logs, usersList, orgsList])

  return (
    <div className='log-view'>
      <div className='log-view__search-section'>
        <Dropdown
          name='tableName'
          label='Table'
          value={selectedFilters.tableName ?? ''}
          onChange={handleSearchChange}
          options={[{ value: '', label: 'All' }].concat(tablesList.map(tableName => ({ value: tableName.toLowerCase(), label: tableName })))}
        />
        <Dropdown
          name='action'
          label='Action'
          value={selectedFilters.action ?? ''}
          onChange={handleSearchChange}
          options={[
            { value: '', label: 'All' },
            { value: 'I', label: 'Insert' },
            { value: 'U', label: 'Update' },
            { value: 'D', label: 'Delete' }
          ]}
        />
        <Dropdown
          name='userId'
          label='User'
          value={selectedFilters.userId ?? ''}
          onChange={handleSearchChange}
          options={
            [{ value: '', label: 'All' }]
            .concat(
              usersList.map(user => ({
                value: user.userId,
                label: `${user.name} | ${(orgsList?.find(x => x.orgId === user.orgId))?.commonName}`
              }))
            )
          }
        />
        <TextInput
          name='rowId'
          label='ID'
          value={selectedFilters.rowId ?? ''}
          onChange={handleSearchChange}
        />
        {/* TODO date range inputs */}
        <div>
          <Button onClick={refreshLogs} typeVariant='primary' size='sm' disabled={!Object.keys(selectedFilters).filter(key => selectedFilters[key]).length}>
            Search
          </Button>
        </div>
      </div>
      <div className='log-view__list-section'>
        <Table
          name='logList'
          title="Logs"
          columns={logColumns}
          data={logs}
          isMultiSelect={false}
          initialSort={[{ id: 'event_id', desc: true }]}
        />
      </div>
      <div className='log-view__detail-section'>
        <LogDetails logEvent={selectedEvent} eventUser={usersList?.find(u => u.userId === selectedEvent?.user_name)} ref={detailsRef} />
      </div>
    </div>
  )
}

export default LogView

const tablesList = [
  'AppSettings',
  'Assortments',
  'Batches',
  'Bids',
  'CertCostItems',
  'CertCosts',
  'CutGrades',
  'EndpointPermissions',
  'Events',
  'EventTypes',
  'Invoices',
  'InvoiceFiles',
  'MfgCostItems',
  'MfgCosts',
  'Mines',
  'NotificationTemplates',
  'OrderLines',
  'Orders',
  'Organizations',
  'Payments',
  'Pipes',
  'PriceBaselines',
  'QcPermissions',
  'QcProgress',
  'Roles',
  'Roughs',
  'Sales',
  'SellerCountries',
  'SellerMines',
  'SellerProvenanceTypes',
  'Shapes',
  'Shipments',
  'ShipmentFiles',
  'TingeMaps',
  'Transactions'
]

const dynamoTables = [
  'appsettings',
  'endpointpermissions',
  'notificationtemplates',
  'organizations',
  'roles'
]
