import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useGlobalsStore } from '@stores'
import OrganizationForm from './organizationForm/organizationForm'
import { ORG } from '@constants'
import { objectUtils } from '@utils'

function CreateOrganization() {
  const history = useHistory()
  const {
    sawPlane,
    getSawPlane,
    resetOrgsList
  } = useGlobalsStore(store => store)

  useEffect(() => {
    getSawPlane()
    // Invalidate the orgsList cache on unmount
    return () => {
      if (history?.location?.pathname === '/organizations') resetOrgsList()
    }
  }, [])

  const { stateSchema, formData } = useMemo(() => {
    const res = {}
    res.formData = objectUtils.filterNullish({
      ...sawPlane,
      acceptWindowedStones: ORG.ACCEPT_WINDOWED_STONES.find(({ default: isDefault }) => isDefault)?.value,
      acceptBlockedStones: ORG.ACCEPT_BLOCKED_STONES.find(({ default: isDefault }) => isDefault)?.value
    })
    res.stateSchema = Object.keys(res.formData)
    return res
  }, [sawPlane])

  return (
    <div className="create-organization">
      <OrganizationForm formData={formData} stateSchema={stateSchema} />
    </div>
  )
}

export default CreateOrganization
