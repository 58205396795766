import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@atoms'
function CalendarEvent({ event }) {
  if (!event) return null

  return (
    <div id={event.id} className='calendar__event'>
      <div className="calendar__event-day">{event?.day}</div>
      <div className="flex-column">
        <div className="calendar__event-name">{event?.name}</div>
        <div className="calendar__event-date">{event?.startTime}</div>
      </div>
      {
        !event.visible
          ? (
            <div className='calendar__event-invisible-icon'>
              <Icon
                name='invisible'
              />
            </div>
          )
          : null
      }
    </div>
  )
}

CalendarEvent.propTypes = {
  event: PropTypes.object
}

CalendarEvent.defaultProps = {
}
export default CalendarEvent
