import { textUtils, arrayUtils, objectUtils } from '@utils'

const cacheList = ({ state, zustandMethods: { get, set }, promise, batchAction }, methods = ['get', 'set', 'remove', 'removeAll']) => {
  const cacheListMethodState = textUtils.capitalize(state)
  const cacheListMethods = {
    // GET CACHE LIST
    [`get${cacheListMethodState}`]: getCacheList(state, get),

    // SET CACHE LIST
    [`set${cacheListMethodState}`]: setCacheList(state, get, set, promise, batchAction),

    // REMOVE CACHE LIST ITEMS
    [`remove${cacheListMethodState}Items`]: removeCacheListItems(state, get, set),
    // REMOVE CACHE LIST
    [`remove${cacheListMethodState}`]: removeCacheList(state, get, set),
    // REMOVE ALL CACHE LIST ITEMS
    [`removeAll${cacheListMethodState}Items`]: removeAllCacheListItems(state, get, set),
    // REMOVE ALL CACHE LISTS
    [`removeAll${cacheListMethodState}`]: removeAllCacheList(state, get, set)
  }
  const pickKeys = methods.reduce((pickKeys, method) => {
    if (method.startsWith('remove')) pickKeys.push(`${method}${cacheListMethodState}Items`)
    return pickKeys.concat(`${method}${cacheListMethodState}`)
  }, [])
  return objectUtils.pick(cacheListMethods, pickKeys, false)
}

// function implementations
const getCacheList = (state, get) => async (params = {}) => {
  // lastUpdated options:
  //  - lastUpdated=null will fetch without lastUpdated filter applied [REFRESH LIST]
  //  - lastUpdated=undefined OR not passing in lastUpdated at all will
  //    fetch only data that has been updated since last request [UPDATE LIST]
  //  - lastUpdated=/existing value of lastUpdated/ will return what is in store [NO REQUEST]
  const { lastUpdated: customLastUpdated, ...restParams } = params
  const paramString = restParams && Object.keys(restParams).length ? JSON.stringify(restParams) : 'all'
  const prevLastUpdated = get()[state][paramString]?.lastUpdated || null
  if (prevLastUpdated !== customLastUpdated) {
    await get()[`set${textUtils.capitalize(state)}`]({
      lastUpdated: customLastUpdated === undefined ? prevLastUpdated : customLastUpdated,
      ...restParams
    })
  }
  return get()[state][paramString]
}

const setCacheList = (state, get, set, promise, batchAction) => async (params = {}) => {
  const { lastUpdated, ...restParams } = params
  // lastUpdated time associated with this batch request will be the
  // current time before the first request in the batch
  const now = new Date()
  const paramString = restParams && Object.keys(restParams).length ? JSON.stringify(restParams) : 'all'
  if (!promise[paramString]) {
    const batchIter = batchAction({
      ...restParams,
      ...(lastUpdated && { lastUpdated })
    },
    {
    // Only show progress when we are doing a REFRESH
      progress: lastUpdated === null
    })
    // Skip processing the first exit of the generator
    batchIter.next()
    promise[paramString] = batchIter
    .next()
    .catch(console.error)
    .finally(() => {
      promise[paramString] = null
    })
    const data = await promise[paramString]
    if (!data || !data.value) return
    set({
      [state]: {
        ...get()[state],
        [paramString]: {
          ...get()[state][paramString],
          data: arrayUtils.unionBy(
            data.value,
            get()[state][paramString]?.data || [],
            x => x.id
          ),
          lastUpdated: now,
          cacheInvalid: false
        }
      }
    })
  }
}

const removeCacheListItems = (state, get, set) => async (params = {}, keys = [], accessor = ({ id }) => id) => {
  const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
  const { data, ...restList } = get()[state][paramString] || {}
  if (data) {
    set({
      [state]: {
        ...get()[state],
        [paramString]: {
          ...restList,
          data: data.filter(item => !keys.includes(accessor(item))),
          cacheInvalid: true
        }
      }
    })
  }
}

const removeCacheList = (state, get, set) => async (params = {}) => {
  const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
  const { data } = get()[state][paramString] || {}
  if (data) {
    set({
      [state]: objectUtils.omit(get().state, [paramString])
    })
  }
}

const removeAllCacheListItems = (state, get, set) => async (keys = [], accessor = ({ id }) => id) => {
  const stateLists = get()[state]
  if (stateLists) {
    await set({
      [state]: Object.entries(stateLists).reduce((lists, [key, value]) =>
        ({
          ...lists,
          [key]: {
            ...value,
            data: value.data.filter((item) => !keys.includes(accessor(item))),
            cacheInvalid: true
          }
        })
      , {})
    })
  }
}

const removeAllCacheList = (state, get, set) => async () => {
  set({
    [state]: {}
  })
}

const createMap = (arr) => arrayUtils.toMap(arr, (x) => x.value, 'description')

// Potential way of creating maps without having to touch the getters for the item in which the maps are mapping
// const subscribeMap = ({ store, states }) => states.forEach(state => store.subscribe((storeState) => storeState[state], (item) => store.setState({
//   [`${state}Map`]: item ? item.reduce((map, curr) => ({ ...map, [curr.value]: curr.description }), {}) : {}
// })))

export { cacheList, getCacheList, setCacheList, removeCacheListItems, removeCacheList, removeAllCacheListItems, removeAllCacheList, createMap }
