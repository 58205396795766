import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import InfoTip from '../infoTip/infoTip'
import { ErrorText } from '@molecules'
import { arrayUtils, objectUtils } from '@utils'
import { COMMON } from '@constants'

function TextArea({
  id,
  name,
  cols,
  rows,
  label,
  visible,
  canEdit,
  inputRef,
  required,
  children,
  onChange,
  validationText,
  componentName,
  infoTip,
  value,
  ...props
}) {
  const validHTMLElementProps = useMemo(() => {
    const propKeys = Object.keys(props)
    const validKeys = arrayUtils.intersectBy(propKeys, COMMON.HTML_ATTRIBUTES.GLOBAL.concat(COMMON.HTML_ATTRIBUTES.TEXTAREA), (x) => x.toLowerCase())
    return objectUtils.pick(props, validKeys)
  }, [props])
  return (
    visible
    && <div className="text-area__container">
      <label>
        {
          label
            ? (
              <div className="input__label">
                <span>{label}</span>
                {infoTip ? <InfoTip name={name}>{infoTip}</InfoTip> : null}
                {!required
            && <small className="text-area__optional-label"> (optional) </small>
                }
              </div>
            ) : null
        }
        <textarea
          className={`text-area__input ${validationText?.getValidationClass()}`}
          name={name}
          id={id}
          ref={inputRef}
          cols={cols}
          rows={rows}
          onChange={onChange}
          value={value}
          {...validHTMLElementProps}
        >
          {children}
        </textarea>
      </label>
      <ErrorText {...validationText?.parse()} />
    </div>
  )
}

TextArea.propTypes = {
  id: PropTypes.string,
  cols: PropTypes.number,
  rows: PropTypes.number,
  canEdit: PropTypes.bool,
  label: PropTypes.string,
  visible: PropTypes.bool,
  inputRef: PropTypes.object,
  value: PropTypes.any,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  componentName: PropTypes.string,
  validationText: PropTypes.object,
  name: PropTypes.string.isRequired,
  infoTip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
}

TextArea.defaultProps = {
  id: '',
  cols: 30,
  rows: 10,
  value: '',
  label: '',
  visible: true,
  name: '',
  required: true
}

export default TextArea
