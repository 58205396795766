import { requestUtils } from '@utils'
import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_BASE_URL

const getQcPermissions = function (params) {
  return axios.get(`${BASE_URL}/sales/qc/permissions`, { params })
}
getQcPermissions.endpoint = 'GET|/sales/qc/permissions'

const getQcPermissionsBatch = requestUtils.batchRequest(getQcPermissions)

const saveQcPermissions = function(qcPermissions) {
  return axios.patch(`${BASE_URL}/sales/qc/permissions`, { qcPermissions })
}
saveQcPermissions.endpoint = 'PATCH|/sales/qc/permissions'

const getQcProgress = function (params) {
  return axios.get(`${BASE_URL}/sales/qc/progress`, { params })
}
getQcProgress.endpoint = 'GET|/sales/qc/progress'

const getQcProgressBatch = requestUtils.batchRequest(getQcProgress)

const logQcProgress = function(qcProgress) {
  return axios.post(`${BASE_URL}/sales/qc/progress`, { qcProgress })
}
logQcProgress.endpoint = 'POST|/sales/qc/progress'

export const qcActions = {
  getQcPermissions,
  getQcPermissionsBatch,
  saveQcPermissions,
  getQcProgress,
  getQcProgressBatch,
  logQcProgress
}
