import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { organizationActions, tingeMapActions } from '@actions'
import moment from 'moment'
import { useToast, usePageTitle } from '@hooks'
import { useHistory } from 'react-router-dom'
import { useGlobalsStore, useAuthStore } from '@stores'
import BuyerSettingsDetailsPage from './buyerSettingsDetailsPage'

function TingeMapDetails({ match, canEdit: canEditProp, title }) {
  const { tingeMapId, operation } = match?.params
  const { hasAdmin, permissionsAdminCache } = useAuthStore(state => state)
  const { showSuccessToast } = useToast()
  const history = useHistory()

  const [tingeMap, setTingeMap] = useState({})
  usePageTitle(title, tingeMapId, tingeMap?.name)
  useEffect(() => {
    if (tingeMapId) {
      tingeMapActions.getTingeMapList({ id: tingeMapId, columns: '[Orders]' })
      .then(response => {
        setTingeMap(response.data.data[0])
      })
      .catch(err => console.error(err))
    } else setTingeMap({})
  }, [tingeMapId])

  const {
    orgsList: { all: orgsList },
    getOrgsList,
    polishedTinges: tinges,
    polishedColours: colours,
    getPolishedColours
  } = useGlobalsStore()
  useEffect(() => {
    getOrgsList()
    getPolishedColours()
  }, [])

  const [fields, setFields] = useState([])
  useEffect(() => {
    if (tingeMap && tinges) {
      const tingeList = tinges?.filter(t => t.value !== 'None')
      const colourOptions = colours?.map(col => ({ value: col.value, label: col.description }))
      const newFields = [
        {
          label: 'Name',
          value: tingeMap.name,
          name: 'name',
          componentName: 'textInput',
          canEdit: true
        },
        ...(hasAdmin(organizationActions.getOrganizationList)
          ? [{
            label: 'Owner',
            value: tingeMap.buyerId === null ? '-' : tingeMap.buyerId,
            name: 'buyerId',
            componentName: 'dropdown',
            options: orgsList?.map(org => ({ value: org.orgId, label: org.commonName })).concat({ value: '-', label: 'Preloaded' }),
            canEdit: false
          }] : []
        ),
        {
          label: 'Created At',
          shouldDisplay: hasAdmin(tingeMapActions.getTingeMapList) || tingeMap.buyerId !== null,
          value: moment(tingeMap.createdAt).toLocaleString()
        },
        {
          label: 'Last Updated',
          shouldDisplay: hasAdmin(tingeMapActions.getTingeMapList) || tingeMap.buyerId !== null,
          value: moment(tingeMap.updatedAt).toLocaleString()
        }
      ]
      for (const tinge of tingeList) {
        newFields.splice(-2, 0, {
          label: tinge.description,
          name: `mapping.${tinge.value}`,
          value: tingeMap.mapping?.[tinge.value],
          componentName: 'dropdown',
          options: colourOptions,
          canEdit: true
        })
      }
      setFields(newFields)
    }
  }, [tingeMap, orgsList, tinges, permissionsAdminCache])

  async function handleOnSubmit(editedValues) {
    const { condition, ...tingeMapData } = editedValues
    const conditionPromise = condition ? tingeMapActions.setTingeMapCondition([tingeMapId], condition) : Promise.resolve()
    return conditionPromise.then(() => {
      if (Object.keys(tingeMapData).length) {
        if ('mapping' in tingeMapData) {
          tingeMapData.mapping = { ...tingeMap.mapping, ...tingeMapData.mapping }
          for (const key in tingeMapData.mapping) {
            if (tingeMapData.mapping[key] === null) delete tingeMapData.mapping[key]
          }
        }
        return tingeMapActions.editTingeMap(tingeMapId, tingeMapData)
      }
    })
      .then(() => {
        showSuccessToast('Tinge Map updated.')
        history.push('/buyer-settings')
      })
  }
  function handleConfirmModal(editedValues) {
    const ignoreKeys = ['name']
    const editedKeys = Object.keys(editedValues)
    return editedKeys.some(key => !ignoreKeys.includes(key)) ? 'tingeDetailsSaveConfirmation' : null
  }

  function handleArchive() {
    tingeMapActions.setTingeMapCondition([tingeMap?.id], 'ARCHIVED')
    .then(() => {
      showSuccessToast('Tinge map has been archived.')
      history.push('/buyer-settings')
    })
  }

  function handleUnarchive() {
    tingeMapActions.setTingeMapCondition([tingeMap?.id], 'ACTIVE')
    .then(() => {
      showSuccessToast('Tinge map has been unarchived.')
      history.push('/buyer-settings')
    })
  }

  function handleRemove() {
    tingeMapActions.setTingeMapCondition([tingeMap?.id], 'DELETED')
    .then(() => {
      showSuccessToast('Tinge map has been removed.')
      history.push('/buyer-settings')
    })
  }

  const canArchive = canEditProp && tingeMap?.condition !== 'ARCHIVED' && tingeMap?.condition !== 'DELETED'
  const canUnarchive = canEditProp && tingeMap?.condition === 'ARCHIVED'
  const canRemove = canEditProp && tingeMap?.condition !== 'DELETED'
  const canEdit = canEditProp && tingeMap?.condition === 'ACTIVE'

  return (
    <BuyerSettingsDetailsPage
      canEdit={canEdit}
      isEdit={operation === 'edit'}
      onArchive={canArchive ? handleArchive : null}
      onUnarchive={canUnarchive ? handleUnarchive : null}
      onRemove={canRemove ? handleRemove : null}
      fields={fields}
      onSubmit={handleOnSubmit}
      title={{
        label: 'Tinge Map ID',
        value: tingeMap?.id || ''
      }}
      confirmModal={handleConfirmModal}
      value={tingeMap}
    />
  )
}

TingeMapDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string,
  canEdit: PropTypes.bool
}

TingeMapDetails.defaultProps = {
  canEdit: true
}

export default TingeMapDetails
