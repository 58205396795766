import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useAuthStore } from '@stores'
import { Icon } from '@atoms'
import { textUtils } from '@utils'
function RoughComment({
  comment,
  onEdit,
  onRemove,
  readOnly
}) {
  const {
    currentAuthenticatedUser
  } = useAuthStore(state => state)

  if (!comment) return null

  function getCommentTitle(comment) {
    const userId = currentAuthenticatedUser?.attributes?.sub
    return (
      <div className="rough-comment__title">
        <span><strong>{comment.commentAuthorUsername || ''}</strong> ({timeAgoText(comment.updatedAt ? moment(comment.updatedAt) : null)})</span>
        <div className="rough-comment__title-icons">
          {comment.commentAuthorId === userId && !readOnly
            ? (
              <>
                <div
                  onClick={onEdit}
                >
                  <Icon
                    size='md'
                    name='edit'
                    className='cursor-pointer'
                  />
                </div>
                <div
                  onClick={onRemove}
                >
                  <Icon
                    size='md'
                    name='closeCircle'
                    className='cursor-pointer'
                  />
                </div>
              </>
            )
            : null
          }
        </div>
      </div>
    )
  }

  function timeAgoText (date) {
    const diff = moment(new Date()).diff(date, 'seconds')
    if (diff <= 60) return `${diff} second${diff === 1 ? '' : 's'} ago`
    else if (diff <= 3600) {
      const minsDiff = Math.round(diff / 60)
      return `${minsDiff} minute${minsDiff === 1 ? '' : 's'} ago`
    } else if (diff <= 86400) {
      const hoursDiff = Math.round(diff / 3600)
      return `${hoursDiff} hour${hoursDiff === 1 ? '' : 's'} ago`
    } else return `on ${textUtils.formatDate(date, true)}`
  }

  return (
    <div className='rough-comment'>
      {getCommentTitle(comment)}
      <div className="rough-comment__text">{comment.commentText}</div>
    </div>
  )
}

RoughComment.propTypes = {
  comment: PropTypes.object,
  readOnly: PropTypes.bool,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func
}

RoughComment.defaultProps = {
  readOnly: false
}

export default RoughComment
