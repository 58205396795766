import { useEffect, useState } from 'react'

const useWindowActivity = function () {
  const [windowIsActive, setWindowIsActive] = useState(false)

  useEffect(() => {
    // when hook is called, we assume that
    // the browser tab is active
    handleActivity(true)
    // the cleanup happens when the page is not
    // active anymore
    return () => handleActivity(false)
  }, [])

  function handleActivity(forcedFlag) {
    if (typeof forcedFlag === 'boolean') {
      return forcedFlag ? setWindowIsActive(true) : setWindowIsActive(false)
    }

    return document.hidden ? setWindowIsActive(false) : setWindowIsActive(true)
  }

  useEffect(() => {
    const handleActivityFalse = () => handleActivity(false)
    const handleActivityTrue = () => handleActivity(true)
    document.addEventListener('visibilitychange', handleActivity)
    document.addEventListener('blur', handleActivityFalse)
    window.addEventListener('blur', handleActivityFalse)
    window.addEventListener('focus', handleActivityTrue)
    document.addEventListener('focus', handleActivityTrue)

    return () => {
      window.removeEventListener('blur', handleActivity)
      document.removeEventListener('blur', handleActivityFalse)
      window.removeEventListener('focus', handleActivityFalse)
      document.removeEventListener('focus', handleActivityTrue)
      document.removeEventListener('visibilitychange', handleActivityTrue)
    }
  }, [])

  return windowIsActive
}

export {
  useWindowActivity
}
