import axios from 'axios'
import { buildDataFromSchema } from './schemas/buildDataFromSchema'
import { organizationSchema } from './schemas/schemas'
import { provenanceActions } from './actions'

const BASE_URL = process.env.REACT_APP_API_BASE_URL

function getOrganizationList(params) {
  return axios.get(`${BASE_URL}/auth/organizations`, { params })
}
getOrganizationList.endpoint = 'GET|/auth/organizations'

function getLimitedOrganizationList(params) {
  return axios.get(`${BASE_URL}/auth/limited/organizations`, { params })
}
getLimitedOrganizationList.endpoint = 'GET|/auth/limited/organizations'

function getOrganizationById(orgId) {
  return axios.get(`${BASE_URL}/auth/organizations`, { params: { orgId } })
}
getOrganizationById.endpoint = 'GET|/auth/organizations'

function createOrganization(data) {
  return axios
    .post(
      `${BASE_URL}/auth/organizations`,
      buildDataFromSchema(data, organizationSchema)
    )
    .then(res => {
      const orgId = res.data.data
      const { provenanceTypes, countries, mines } = data
      let setProvPromise, setCountriesPromise, setMinesPromise
      if (provenanceTypes) setProvPromise = provenanceActions.setProvenanceTypes({ orgId, provenanceTypeIds: provenanceTypes })
      if (countries) setCountriesPromise = provenanceActions.setCountries({ orgId, countryIds: countries })
      if (mines) setMinesPromise = provenanceActions.setMines({ orgId, mineIds: mines })

      return Promise.all([
        setProvPromise,
        setCountriesPromise,
        setMinesPromise
      ]).then(() => orgId)
    })
}
createOrganization.endpoint = 'POST|/auth/organizations'

function editOrganization({ orgId, ...data }) {
  return axios.patch(
    `${BASE_URL}/auth/organizations/${orgId}`,
    data
  )
}
editOrganization.endpoint = 'PATCH|/auth/organizations/:orgId:'

const setOrgCondition = function (data) {
  const { orgId } = data
  delete (data.orgId)
  return axios.put(
    `${BASE_URL}/auth/organizations/${orgId}/condition`,
    data
  )
}
setOrgCondition.endpoint = 'PUT|/auth/organizations/:orgId:/condition'

export const organizationActions = {
  editOrganization,
  setOrgCondition,
  createOrganization,
  getOrganizationById,
  getOrganizationList,
  getLimitedOrganizationList
}
