import React, { useState, useEffect, useMemo } from 'react'
import { useGlobalsStore } from '@stores'
import { useToast } from '@hooks'
import { assortmentActions, roughStoneActions } from '@actions'
import { Modal } from '@templates'
import { Table } from '@organisms'
import { Button } from '@atoms'
import { Dropdown, ErrorIcon } from '@molecules'
import { arrayUtils, errorUtils, objectUtils } from '@utils'

function AssortmentDetailsProvenanceModal({ open, onClose, roughStonesList, refreshAssortment, refreshRoughStones, assortment }) {
  const { showSuccessToast, showInfoToast } = useToast()
  const [formValues, setFormValues] = useState()
  const sellerKey = { sellerId: assortment?.sellerId }
  const sellerActiveKey = { sellerId: assortment?.sellerId, condition: 'ACTIVE' }
  const activeOnlyParam = { condition: 'ACTIVE' }
  const {
    getProvenanceTypesList,
    getCountriesList,
    getMinesList,
    getPipesList,
    getBatchesList,
    provenanceTypesList: { [JSON.stringify(sellerKey)]: provenanceTypes = [] },
    countriesList: { [JSON.stringify(sellerKey)]: countries = [] },
    minesList: { [JSON.stringify(sellerActiveKey)]: mines = [] },
    pipesList: { [JSON.stringify(activeOnlyParam)]: pipes = [] },
    batchesList: { [JSON.stringify(activeOnlyParam)]: batches = [] }
  } = useGlobalsStore(store => store)

  useEffect(() => {
    if (assortment) {
      getCountriesList(sellerKey)
      getMinesList(sellerActiveKey)
      getPipesList(activeOnlyParam)
      getBatchesList(activeOnlyParam)
      getProvenanceTypesList(sellerKey)
    }
  }, [assortment])

  const provenanceTypesMap = useMemo(() => arrayUtils.toMap(provenanceTypes, x => x.id, 'description'), [provenanceTypes])

  const provenance = useMemo(() => formValues?.provenanceTypeId && { name: provenanceTypesMap?.[formValues.provenanceTypeId] }, [formValues, provenanceTypesMap])
  const country = useMemo(() => formValues?.countryId && (formValues.countryId === 'remove' ? null : countries?.find(({ id }) => id === formValues?.countryId)), [formValues])
  const mine = useMemo(() => formValues?.mineId && (formValues.mineId === 'remove' ? null : mines?.find(({ id }) => id === formValues?.mineId)), [formValues])
  const pipe = useMemo(() => formValues?.pipeId && (formValues.pipeId === 'remove' ? null : pipes?.find(({ id }) => id === formValues?.pipeId)), [formValues])
  const batch = useMemo(() => formValues?.batchId && (formValues.batchId === 'remove' ? null : batches?.find(({ id }) => id === formValues?.batchId)), [formValues])

  async function handleChangeProvenanceSubmit() {
    const formData = objectUtils.filterNullish({ ...formValues })
    if (!formData || Object.keys(formData).length === 0) {
      return showInfoToast('No changes were submitted for this assortment.')
    }

    if (formData.provenanceTypeId) {
      await assortmentActions.editAssortment({ assortmentId: assortment?.id, provenanceTypeId: formData.provenanceTypeId })
      delete formData.provenanceTypeId
      showSuccessToast('The assortment\'s provenance type has been updated.')
    }

    if (Object.keys(formData).length > 0) {
      if (roughStonesList?.length === 0) {
        showInfoToast('Rough stone provenance changes were not applied as there are no rough stones in this assortment.')
      } else {
        for (const k of Object.keys(formData)) {
          if (formData[k] === 'remove') formData[k] = null
        }
        await roughStoneActions.updateMultipleRoughs(
          {
            roughIds: roughStonesList?.map(row => row.id),
            ...formData
          },
          { errorSummary: errorUtils.getErrorSummary({ field: 'stones' }) }
        )

        showSuccessToast('The rough stones in the assortment have been updated.')
      }
    }

    refreshAssortment()
    refreshRoughStones()
    onClose()
  }
  function handleFormChange(e) {
    const { name, value } = e.currentTarget || e.target
    // Along with setting the current field change, clear dependent fields if
    // the dependent field value is not 'remove' (null)
    const dependencies = {
      provenanceTypeId: [],
      countryId: [],
      mineId: ['pipeId', 'batchId'],
      pipeId: ['batchId'],
      batchId: []
    }
    setFormValues(currFormValues => ({
      ...currFormValues,
      [name]: value ?? undefined,
      ...(dependencies[name].reduce((changes, dependency) => {
        if (currFormValues?.[dependency] === 'remove') return changes
        return { ...changes, [dependency]: undefined }
      }, {}))
    }))
  }

  function renderCellValue({ cell: { value }, column: { id }, row: { original } }) {
    function renderer(override, warning) {
      const val = (
        <div className="assortment-details-provenance-modal__table-cell">
          <div className="assortment-details-provenance-modal__table-cell-value">
            {override?.name ?? value ?? null}
          </div>
          <div>
            {warning && <ErrorIcon infoTip={true} warning={warning} />}
          </div>
        </div>
      )
      if (override?.name !== value && override !== undefined) {
        return (
          <div className={`assortment-details-provenance-modal__table-cell-value${override ? '--new' : '--remove'}`}>
            {val}
          </div>
        )
      }
      return val
    }
    let res
    switch (id) {
      case 'provenance':
        res = renderer(provenance)
        break
      case 'country':
        res = renderer(country)
        break
      case 'mine':
        res = renderer(mine)
        break
      case 'pipe': {
        const rendererProps = { override: undefined, warning: undefined }
        if (pipe === null) res = renderer(null)
        // Add warning to pipe if the mine is set to remove
        else if (mine === null) {
          if (!value) {} // do nothing
          else rendererProps.warning = 'Pipe is no longer valid with selected Mine.'
          res = renderer(rendererProps.override, rendererProps.warning)
        }
        // Remove pipe if the current pipe value does not belong to mine
        else if (mine && pipe === undefined) {
          if (original?.Pipe?.mineId === mine.id) {}// do nothing
          else if (!value) {} // do nothing
          else rendererProps.warning = 'Pipe is no longer valid with selected Mine.'
          res = renderer(rendererProps.override, rendererProps.warning)
        } else res = renderer(pipe)
        break
      }
      case 'batch': {
        const rendererProps = { override: undefined, warning: undefined }
        if (batch === null) res = renderer(null)
        // Remove batch if the pipe is null or mine is null
        else if ((mine === null || pipe === null)) {
          if (!value) {} // do nothing
          else rendererProps.warning = 'Batch is no longer valid with selected Pipe.'
          res = renderer(rendererProps.override, rendererProps.warning)
        }
        // Remove batch if the current batch value does not belong to pipe
        else if (pipe && batch === undefined) {
          if (original?.Batch?.pipeId === pipe.id) {}// do nothing
          else if (!value) {} // do nothing
          else rendererProps.warning = 'Batch is no longer valid with selected Pipe.'
          res = renderer(rendererProps.override, rendererProps.warning)
        } else res = renderer(batch)
        break
      }
    }
    return res
  }

  return (
    <Modal
      open={open}
      title="Change Assortment's Provenance Details"
      className="assortment-details-provenance-modal"
      onClose={() => {
        setFormValues()
        onClose()
      }}
    >
      <div>
        <div className='assortment-details-provenance-modal__table center'>
          <div className='assortment-details-provenance-modal__form'>
            <Dropdown
              name='provenanceTypeId'
              label='Provenance Type'
              options={
                  provenanceTypes?.map(type => ({
                    value: type.id,
                    label: type.description
                  }))
              }
              value={formValues?.provenanceTypeId}
              onChange={handleFormChange}
              required={false}
            />
            <Dropdown
              name='countryId'
              label='Country'
              options={[
                { label: '- Remove Country', value: 'remove' },
                ...countries?.map(c => ({
                  label: c.name,
                  value: c.id
                }))
                  .sort((a, b) => { return a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1 })
              ]}
              value={formValues?.countryId}
              onChange={handleFormChange}
              required={false}
            />
            <Dropdown
              name='mineId'
              label='Mine'
              options={[
                { label: '- Remove Mine', value: 'remove' },
                ...mines?.map(m => ({
                  label: m.name,
                  value: m.id
                }))
                  .sort((a, b) => { return a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1 })
              ]}
              value={formValues?.mineId}
              onChange={handleFormChange}
              required={false}
            />
            <Dropdown
              name='pipeId'
              label='Pipe'
              options={[
                { label: '- Remove Pipe', value: 'remove' },
                ...pipes
                  ?.filter(p => p.mineId === formValues?.mineId && (p.sellerId == null || p.sellerId === assortment?.sellerId))
                  ?.map(p => ({
                    label: p.name,
                    value: p.id
                  }))
                  .sort((a, b) => { return a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1 })
              ]}
              value={formValues?.pipeId}
              onChange={handleFormChange}
              required={false}
            />
            <Dropdown
              name='batchId'
              label='Batch'
              options={[
                { label: '- Remove Batch', value: 'remove' },
                ...batches
                  ?.filter(b => b.pipeId === formValues?.pipeId && b.sellerId === assortment?.sellerId)
                  ?.map(b => ({
                    label: b.name,
                    value: b.id
                  }))
                  .sort((a, b) => {
                    return a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1
                  })
              ]}
              value={formValues?.batchId}
              onChange={handleFormChange}
              required={false}
            />
            <Button size='sm' onClick={handleChangeProvenanceSubmit}>
                Submit
            </Button>
          </div>
          <Table
            id="provenancePreview"
            title={null}
            data={roughStonesList}
            columns={[
              {
                Header: 'Clara ID',
                accessor: 'id',
                dataType: 'number'
              },
              {
                Header: 'Seller Stone Name',
                accessor: 'sellerStoneName',
                dataType: 'string'
              },
              {
                Header: 'Provenance Type',
                id: 'provenance',
                accessor: row => provenanceTypesMap?.[row?.Assortment?.provenanceTypeId],
                dataType: 'string',
                Cell: renderCellValue
              },
              {
                Header: 'Country',
                id: 'country',
                accessor: 'Country.name',
                dataType: 'string',
                Cell: renderCellValue
              },
              {
                Header: 'Mine',
                id: 'mine',
                accessor: 'Mine.name',
                dataType: 'string',
                Cell: renderCellValue
              },
              {
                Header: 'Pipe',
                id: 'pipe',
                accessor: 'Pipe.name',
                dataType: 'string',
                Cell: renderCellValue
              },
              {
                Header: 'Batch',
                id: 'batch',
                accessor: 'Batch.name',
                dataType: 'string',
                Cell: renderCellValue
              }
            ]}
            isMultiSelect={false}
          />
        </div>
      </div>
    </Modal>)
}

export default AssortmentDetailsProvenanceModal
