import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import InfoTip from '../infoTip/infoTip'
import { ErrorText } from '@molecules'
import { arrayUtils, objectUtils } from '@utils'
import { COMMON } from '@constants'

function TextInput(
  {
    inputRef,
    name,
    type,
    size,
    value,
    label,
    disabled,
    visible,
    required,
    labelStyle,
    classNames,
    numberFormat,
    placeholder,
    allowNegative,
    fixedDecimalScale,
    formatPrefix,
    formatSuffix,
    validationText,
    infoTip,
    onBlur,
    onFocus,
    autoFocus,
    max,
    min,
    ...props
  }
) {
  const sizeSuffix = {
    sm: '--sm',
    md: '--md',
    lg: '--lg'
  }[size] || '--lg'

  const prefix = formatPrefix || (type === 'currency' ? '$ ' : '')
  const decimalScale = 'decimalScale' in props ? props.decimalScale : 2

  const validHTMLElementProps = useMemo(() => {
    const propKeys = Object.keys(props)
    const validKeys = arrayUtils.intersectBy(propKeys, COMMON.HTML_ATTRIBUTES.GLOBAL.concat(COMMON.HTML_ATTRIBUTES.INPUT), (x) => x.toLowerCase())
    return objectUtils.pick(props, validKeys)
  }, [props])

  function isAllowed(values) {
    const { floatValue } = values
    if (floatValue == null) return true
    if (min == null && max == null) return true
    return floatValue >= Number(min ?? -Infinity) && floatValue <= Number(max ?? Infinity)
  }

  return (
    visible
    && <div
      className={`text-input__container ${classNames?.map(className => className)}`}
    >
      <label>
        {
          label
            ? (
              <div className="input__label">
                <span style={labelStyle}>{label}</span>
                {infoTip ? <InfoTip name={name}>{infoTip}</InfoTip> : null}
                {!required
                  ? <small className="text-input__optional-label"> (optional) </small>
                  : null
                }
              </div>
            ) : null
        }
        {type === 'number'
          || type === 'currency'
          || numberFormat
          ? <NumberFormat
            getInputRef={inputRef}
            name={name}
            // https://github.com/s-yadav/react-number-format/issues/500#issuecomment-797481055
            value={value ?? ''}
            displayType='input'
            disabled={disabled}
            thousandSeparator={true}
            allowNegative={allowNegative}
            placeholder={placeholder}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            prefix={prefix}
            suffix={formatSuffix}
            className={`text-input__input${sizeSuffix} ${validationText?.getValidationClass()}`}
            onFocus={onFocus}
            onBlur={onBlur}
            // If a field is cleared values.floatValue is undefined. We might want to consider normalizing undefined to null for consistency.
            onValueChange={values => props.onChange({ currentTarget: { name, value: values.floatValue ?? null } })}
            autoFocus={autoFocus}
            isAllowed={isAllowed}
          />
          : <input
            ref={inputRef}
            name={name}
            type={type}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            className={`text-input__input${sizeSuffix} ${validationText?.getValidationClass()}`}
            autoFocus={autoFocus}
            {...validHTMLElementProps}
          />}
      </label>
      <ErrorText {...validationText?.parse()} />
    </div>
  )
}

TextInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
  inputRef: PropTypes.object,
  labelStyle: PropTypes.object,
  numberFormat: PropTypes.bool,
  allowNegative: PropTypes.bool,
  placeholder: PropTypes.string,
  formatPrefix: PropTypes.string,
  formatSuffix: PropTypes.string,
  decimalScale: PropTypes.number,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fixedDecimalScale: PropTypes.bool,
  validationText: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  classNames: PropTypes.arrayOf(PropTypes.string),
  infoTip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object]),
  autoFocus: PropTypes.bool
}

TextInput.defaultProps = {
  name: '',
  value: '',
  placeholder: '',
  size: '',
  min: null,
  max: null,
  type: 'text',
  visible: true,
  labelStyle: {},
  required: true,
  classNames: [],
  fixedDecimalScale: false,
  allowNegative: true,
  disabled: false,
  formatPrefix: '',
  formatSuffix: '',
  numberFormat: false,
  autoFocus: false
}

export default TextInput
