import React, { useState, useEffect } from 'react'
import { Field } from 'formik'
import { baselineActions, organizationActions } from '@actions'
import { useToast } from '@hooks'
import { useAuthStore, useGlobalsStore } from '@stores'
import { fileUtils } from '@utils'
import { FileUpload, FormComponents } from '@organisms'
import { useHistory } from 'react-router-dom'
import { ConfirmationModal } from '@templates'

function UploadBaseline(props) {
  const {
    SimpleForm,
    Dropdown,
    TextInput,
    Button
  } = FormComponents

  const { orgId: userOrgId, hasAdmin } = useAuthStore(store => store)
  const isAdmin = hasAdmin(organizationActions.getOrganizationList)

  const [baselinesList, setBaselinesList] = useState([])
  useEffect(() => {
    baselineActions.getBaselines({ condition: 'ACTIVE' }).then(response => setBaselinesList(response?.data?.data?.filter((b) => !b.parentBaselineId)))
  }, [])

  const [csvString, setCsvString] = useState(null)
  const [fileName, setFileName] = useState(undefined)
  const [selectedVersion, setSelectedVersion] = useState(null)
  const [fileShapeType, setFileShapeType] = useState(null)

  const {
    orgsList: { [JSON.stringify({ condition: 'ACTIVE' })]: orgsList },
    getOrgsList,
    baselineShapeTypes,
    getBaselineShapeTypes
  } = useGlobalsStore()
  useEffect(() => {
    getOrgsList({ condition: 'ACTIVE' })
    getBaselineShapeTypes()
  }, [])

  function handleFormChange(previousValues, e) {
    const target = e.currentTarget || e.target
    if (target?.name === 'name' || target?.name === 'buyerId') {
      const formValues = { ...previousValues, [target.name]: target.value }
      setSelectedVersion(baselinesList.find(b => b.name === formValues.name && b.buyerId === formValues.buyerId))
    }
  }

  function receiveFile(form) {
    return async function(file) {
      if (!file) {
        setCsvString(null)
        setFileName(undefined)
        form.setFieldValue('name', '')
        if (fileShapeType) {
          form.setFieldValue('shapeType', null)
          if (!selectedVersion || fileShapeType === selectedVersion.name) {
            setSelectedVersion(null)
          }
        }
        setFileShapeType(null)
      } else {
        const fileString = await fileUtils.promiseReadFile(file)
        setCsvString(fileString)
        setFileName(file.name)

        const newFileShapeType = fileString.substring(0, fileString.indexOf(','))
        const shapeTypeValue = baselineShapeTypes?.find((({ description }) => newFileShapeType === description))?.value
        form.setFieldValue('shapeType', shapeTypeValue ?? null)
        if (!selectedVersion) {
          form.setFieldValue('name', newFileShapeType)
          setSelectedVersion(baselinesList.find(b => b.name === newFileShapeType && b.buyerId === form?.values?.buyerId))
        }
        setFileShapeType(shapeTypeValue ? newFileShapeType : null)
      }
    }
  }

  const { showSuccessToast, showErrorToast } = useToast()
  const history = useHistory()

  const [confirmModalPromise, setConfirmModalPromise] = useState(null)
  async function handleSubmit({ ...formData }) {
    formData.csvString = csvString

    async function doSubmit() {
      let promise
      if (selectedVersion) {
        delete formData.name
        delete formData.buyerId
        promise = baselineActions.replaceBaseline(selectedVersion.id, formData)
      } else {
        promise = baselineActions.createBaseline(formData)
      }
      promise.then(response => {
        if (response.status === 207) {
          response.data.data.forEach(s => showSuccessToast(s))
          response.data.error.forEach(e => showErrorToast(e))
        } else {
          showSuccessToast(`Baseline ${selectedVersion ? 'Replaced' : 'Created'}`)
          history.push('/buyer-settings')
        }
      })
      .catch(console.error)
    }

    if (selectedVersion && selectedVersion.shapeType !== formData.shapeType) {
      return new Promise((resolve, reject) => {
        setConfirmModalPromise({ resolve, reject })
      })
        .finally(() => setConfirmModalPromise(null))
        .then(() => {
          doSubmit()
        })
    }

    doSubmit()
  }

  return (
    <>
      <div className="center">
        <SimpleForm
          onSubmit={handleSubmit}
          title='Upload Baseline'
          name='uploadBaseline'
          initialValues={{ buyerId: userOrgId }}
          extraOnChange={handleFormChange}
        >
          <Field name='file'>
            {({ form }) => (
              <FileUpload
                name='file'
                label='Upload Baseline File'
                fileName={fileName}
                onChange={receiveFile(form)}
                acceptTypes={['.csv']}
              />
            )
            }
          </Field>
          {isAdmin
            && <Dropdown
              name='buyerId'
              label='Owner'
              options={orgsList?.map(org => ({ value: org.orgId, label: org.commonName }))}
            />
          }
          <TextInput
            name='name'
            label='Name'
            disabled={!fileName}
          />
          <Dropdown
            name='shapeType'
            label='Shape Type'
            options={baselineShapeTypes?.map(b => ({ value: b.value, label: b.description }))}
            disabled={!!(!fileName || fileShapeType)}
          />
          <Button
            typeVariant="action"
            onClick={() => history.push('/buyer-settings')}
            size='sm'
          >
          Cancel
          </Button>
          <Button
            type='submit'
            size='sm'
          >
            {selectedVersion ? 'Replace' : 'Create'}
          </Button>
        </SimpleForm>
      </div>
      <ConfirmationModal
        open={!!confirmModalPromise}
        title={'Confirm Baseline Shape Type Update'}
        message={<>
          Replacement Baseline has a Shape Type that does not match current Baseline Shape Type.
          <br/>
          This change will affect this Baseline&apos;s child Baselines and current Orders.
          <br/>
          Do you confirm this change?
        </>}
        onClose={() => confirmModalPromise?.reject()}
        onSubmit={() => confirmModalPromise?.resolve()}
      />
    </>
  )
}

export default UploadBaseline
