import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useAuthStore, useGlobalsStore } from '@stores'
import { provenanceActions } from '@actions'
import moment from 'moment'
import { DetailsPage } from '@templates'
import { usePageTitle, useToast } from '@hooks'
import { useHistory } from 'react-router-dom'

function MineDetails({ match, title }) {
  const { mineId, operation } = match?.params

  const { hasPermission } = useAuthStore(state => state)
  const { resetMinesList } = useGlobalsStore()

  const { showSuccessToast } = useToast()
  const history = useHistory()

  const [mine, setMine] = useState({})
  usePageTitle(title, mineId, mine?.name)
  useEffect(() => {
    if (mineId) {
      provenanceActions.getMineById(mineId)
      .then(setMine)
      .catch(err => console.error(err))
    } else setMine({})
  }, [mineId])

  const [fields, setFields] = useState([])
  useEffect(() => {
    if (mine) {
      setFields([
        {
          label: 'Name',
          value: mine.name,
          name: 'name',
          componentName: 'textInput',
          canEdit: true
        },
        {
          label: 'Secondary Market',
          value: mine.secondaryMarket,
          name: 'secondaryMarket',
          componentName: 'checkbox',
          canEdit: true
        },
        {
          label: 'Latitude',
          value: mine.latitude ?? '',
          name: 'latitude',
          componentName: 'textInput',
          canEdit: true
        },
        {
          label: 'Longitude',
          value: mine.longitude ?? '',
          name: 'longitude',
          componentName: 'textInput',
          canEdit: true
        },
        {
          label: 'Condition',
          value: mine.condition,
          name: 'condition',
          componentName: 'dropdown',
          options: [
            { value: 'ACTIVE', label: 'Active' },
            { value: 'ARCHIVED', label: 'Archived' }
          ],
          canEdit: true
        },
        {
          label: 'Created At',
          value: moment(mine.createdAt).toLocaleString()
        },
        {
          label: 'Last Updated',
          value: moment(mine.updatedAt).toLocaleString()
        }
      ])
    }
  }, [mine])

  async function handleOnSubmit(editedValues) {
    const { condition, ...mineData } = editedValues

    const conditionPromise = condition ? provenanceActions.setMineCondition(mineId, condition) : Promise.resolve()
    return conditionPromise.then(() => {
      if (Object.keys(mineData).length) {
        return provenanceActions.editMine({ id: mineId, ...mineData })
      }
    })
    .then(() => {
      showSuccessToast('Mine updated.')
      resetMinesList()
      history.push('/seller-settings')
    })
  }

  function handleArchive() {
    provenanceActions.setMineCondition([mine?.id], 'ARCHIVED')
    .then(() => {
      resetMinesList()
      showSuccessToast('Mine has been archived.')
      history.push('/seller-settings')
    })
  }

  function handleUnarchive() {
    provenanceActions.setMineCondition([mine?.id], 'ACTIVE')
    .then(() => {
      resetMinesList()
      showSuccessToast('Mine has been unarchived.')
      history.push('/seller-settings')
    })
  }

  const canArchive = mine?.condition !== 'ARCHIVED' && hasPermission(provenanceActions.setMineCondition)
  const canUnarchive = mine?.condition === 'ARCHIVED' && hasPermission(provenanceActions.setMineCondition)
  const canEdit = mine?.condition === 'ACTIVE' && hasPermission(provenanceActions.editMine)

  return (
    <DetailsPage
      canEdit={canEdit}
      isEdit={operation === 'edit'}
      onArchive={canArchive ? handleArchive : null}
      onUnarchive={canUnarchive ? handleUnarchive : null}
      fields={fields}
      onSubmit={handleOnSubmit}
      title={{
        label: 'Mine ID',
        value: mine?.id || ''
      }}
    />
  )
}

MineDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string
}

export default MineDetails
