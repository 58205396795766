import React, { useEffect, useState } from 'react'
import { cutGradeActions } from '@actions'
import { Button } from '@atoms'
import { Form } from '@organisms'
import { DisplayMessage, Dropdown } from '@molecules'
import { ConfirmationModal } from '@templates'
import ProportionInput from '../../proportionInput'
import { useToast } from '@hooks'
import dottie from 'dottie'

const emptyValue = '-555555.000000'

function ProportionStep(props) {
  const [propMeta, setPropMeta] = useState([])
  useEffect(() => {
    if (props.getState('shapeId')) {
      cutGradeActions.getProportions({ shapeId: props.getState('shapeId'), fixedId: props.getState('sarineNonLinearGrade.fixedId') })
      .then(response => setPropMeta(response?.data?.data?.sort((x, y) => x.sortOrder - y.sortOrder)))
      .catch(err => console.error(err))
    }
  }, [props.getState('shapeId'), props.getState('sarineNonLinearGrade.fixedId')])

  const {
    validationError,
    validateAndSubmit,
    updateMultipleState
  } = props.useMultiSteps(props)

  const [openConfirmationModal, setOpenConfirmationModal] = useState({ name: false, data: null })

  const [filteredCutGrades, setFilteredCutGrades] = useState([])
  useEffect(() => {
    if (props.getState('shapeId')) {
      cutGradeActions.getCutGradeList({ shapeId: props.getState('shapeId'), condition: 'ACTIVE' })
      .then(response => setFilteredCutGrades(response?.data?.data?.filter(grade => grade.sarineNonLinearGrade?.fixedId === (Number(props.getState('sarineNonLinearGrade.fixedId')) || undefined))))
      .catch(console.error)
    }
  }, [props.getState('shapeId'), props.getState('sarineNonLinearGrade.fixedId')])

  function resetCutGrade(e) {
    if (submitStatus !== 'changed') setSubmitStatus('changed')
    if (e?.currentTarget?.value === '') {
      setOpenConfirmationModal({
        name: 'clear',
        data: Object.keys(props.state).reduce((propProps, key) => {
          if (key.startsWith('proportions.')) return { ...propProps, [key]: undefined }
          return propProps
        }, {})
      })
    }
    const useGrade = filteredCutGrades.find(g => g.id === e?.currentTarget?.value)
    if (!useGrade) return
    setOpenConfirmationModal({
      name: 'replace',
      data: dottie.paths(useGrade.proportions).reduce((changes, path) => {
        return { ...changes, [`proportions.${path}`]: dottie.get(useGrade.proportions, path) }
      }, {})
    })
  }

  function handleChange(e) {
    if (submitStatus !== 'changed') setSubmitStatus('changed')
    if (e?.currentTarget) {
      props.setState(e.currentTarget?.name, e.currentTarget?.value)
    } else {
      props.setState(e?.target?.name, e?.target?.value)
    }
  }

  const [submitStatus, setSubmitStatus] = useState('changed')
  const { showSuccessToast } = useToast()

  function handleSubmit() {
    const cutGrade = { proportions: propMeta.reduce((noProps, prop) => ({ ...noProps, [prop.proportion]: { min: emptyValue, max: emptyValue } }), {}) }
    for (const key in props.state) {
      const value = props.getState(key)
      if (value == null || value === '') continue
      dottie.set(cutGrade, key, value)
      if (key === 'proportions.MIN_GRDL_FLAG.min') {
        dottie.set(cutGrade, 'proportions.MIN_GRDL_FLAG.max', value)
      }
    }
    if (submitStatus === 'valid' || submitStatus === 'invalid') {
      validateAndSubmit({
        requiredFields: [],
        data: cutGrade,
        redirectPath: '/buyer-settings',
        successMessage: 'Cut Grade was successfully created.'
      })
    } else if (submitStatus === 'changed') {
      cutGradeActions.validateCutGrade({ shapeId: cutGrade.shapeId, proportions: cutGrade.proportions })
      .then(() => {
        showSuccessToast('Proportions are valid')
        setSubmitStatus('valid')
      })
      .catch(() => {
        setSubmitStatus('invalid')
      })
    }
  }

  return (
    <Form title='Create Cut Grade'>
      {filteredCutGrades && filteredCutGrades.length ? (
        <Dropdown
          name='replaceCutGrade'
          placeholder='Reset to...'resetCutGrade
          label='Copy Initial Values From'
          value=''
          onChange={resetCutGrade}
          options={filteredCutGrades.map(grade => ({ value: grade.id, label: `${grade.institute} | ${grade.name}` }))}
        />
      ) : null}
      <span style={{ float: 'right' }}>
        <Button typeVariant="action" onClick={() => resetCutGrade({ currentTarget: { value: '', label: 'Blank' } })}>
          Clear all fields
        </Button>
      </span>
      <div className='proportion-input__container'>
        <div className='proportion-input__min'>
          <label>
            <div className="input__label">
              <span>Min</span>
            </div>
          </label>
        </div>
        <div className='proportion-input__max'>
          <label>
            <div className="input__label">
              <span>Max</span>
            </div>
          </label>
        </div>
      </div>
      {propMeta.map(prop => (
        <ProportionInput
          {...prop}
          key={prop.proportion}
          minValue={props.getState(`proportions.${prop.proportion}.min`)}
          maxValue={props.getState(`proportions.${prop.proportion}.max`)}
          onChange={handleChange}
        />
      ))}
      {validationError.error && (
        <DisplayMessage
          type={'error'}
          message={
            <>
              <div>Please enter all required fields:</div>
              {validationError.blankFields.map((blankField, i) => {
                return (
                  <span key={i}>
                    <strong>- {blankField}</strong>
                  </span>
                )
              })}
            </>
          }
        />
      )}
      <div className="create-organization-buttons">
        <Button
          disabled={!props.hasPrev()}
          typeVariant="action"
          onClick={() => props.prev()}
        >
          Previous
        </Button>
        <Button
          disabled={false}
          typeVariant={submitStatus === 'invalid' ? 'error' : 'primary'}
          size="sm"
          onClick={handleSubmit}
        >
          {submitStatus === 'changed' ? 'Validate Cut Grade' : (submitStatus === 'invalid' ? 'Create Anyways' : 'Create Cut Grade')}
        </Button>
      </div>
      <ConfirmationModal
        open={['clear', 'replace'].includes(openConfirmationModal.name)}
        title='Reset Values?'
        message={`Are you sure you want to ${openConfirmationModal.name} all values? You will lose any unsaved changes to the proportions.`}
        onClose={() => setOpenConfirmationModal({ name: false, callback: null })}
        onSubmit={async () => updateMultipleState(openConfirmationModal.data)}
      />
    </Form>
  )
}

export default ProportionStep
