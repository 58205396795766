import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Dropdown, TextInput } from '@molecules'
import { Icon } from '@atoms'

function PolishedPriceBookParameters({
  onChange,
  parameters,
  index,
  shapeList,
  cutGradeList: _cutGradeList,
  colourList,
  fluorList,
  clarityList,
  tingeList,
  errors
}) {
  const cutGradeList = useMemo(() => _cutGradeList.filter(grade => grade.shapeId === parameters?.shapeId), [parameters?.shapeId, _cutGradeList])

  return (
    <div className="polished-price-book-parameters">
      <div className='polished-price-book-parameters__row'>
        <h4 className='mr-4'>{Number(index) + 1}.</h4>
        <Dropdown
          name='shapeId'
          label='Shape'
          options={shapeList}
          value={parameters?.shapeId}
          onChange={(e) => {
            onChange(e, index)
            onChange({ target: { name: 'cutGradeId', value: null } }, index)
          }}
          isClearable={false}
        />
        <Dropdown
          name='cutGradeId'
          label='Cut Grade'
          disabled={!parameters.shapeId}
          options={cutGradeList}
          value={parameters?.cutGradeId}
          onChange={(e) => onChange(e, index)}
          isClearable={false}
        />
        <Dropdown
          name='colour'
          label='Color'
          options={colourList}
          value={parameters?.colour}
          onChange={(e) => onChange(e, index)}
          isClearable={false}
        />
        <Dropdown
          name='fluorescence'
          label='Fluorescence'
          options={fluorList}
          value={parameters?.fluorescence}
          onChange={(e) => onChange(e, index)}
          isClearable={false}
        />
        <Dropdown
          name='clarity'
          label='Clarity'
          options={clarityList}
          value={parameters?.clarity}
          onChange={(e) => onChange(e, index)}
          isClearable={false}
        />
        <Dropdown
          name='tinge'
          label='Tinge'
          options={tingeList}
          value={parameters?.tinge}
          onChange={(e) => onChange(e, index)}
          isClearable={false}
        />
        <TextInput
          name='polishedWeight'
          label='Weight'
          type='number'
          value={parameters?.polishedWeight}
          onChange={(e) => onChange(e, index)}
        />
      </div>
      {
        errors
          ? (
            <div className='polished-price-book-parameters__error'>
              <Icon
                name='error'
                size='sm'
              />
              <span>{errors}</span>
            </div>
          ) : null
      }
    </div>
  )
}

PolishedPriceBookParameters.propTypes = {
  onChange: PropTypes.func,
  parameters: PropTypes.object,
  index: PropTypes.number,
  errors: PropTypes.string,
  shapeList: PropTypes.arrayOf(PropTypes.object),
  cutGradeList: PropTypes.arrayOf(PropTypes.object),
  colourList: PropTypes.arrayOf(PropTypes.object),
  fluorList: PropTypes.arrayOf(PropTypes.object),
  clarityList: PropTypes.arrayOf(PropTypes.object),
  tingeList: PropTypes.arrayOf(PropTypes.object)
}

PolishedPriceBookParameters.defaultProps = {
  errors: '',
  shapeList: [],
  cutGradeList: [],
  colourList: [],
  fluorList: [],
  clarityList: [],
  tingeList: []
}

export default PolishedPriceBookParameters
