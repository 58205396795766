import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Table } from '@organisms'
import { NavLink } from 'react-router-dom'
import { organizationActions } from '@actions'
import { useGlobalsStore, useAuthStore, useRoughStoneStore, useTransactionStore } from '@stores'
import { arrayUtils, objectUtils } from '@utils'
import dottie from 'dottie'

const TABLE_KEYS = {
  ROUGH: 'rough',
  TRANSACTION: 'transaction'
}
const FILTERS = {
  UNSOLD: {
    key: 'unsold',
    tableKey: TABLE_KEYS.ROUGH,
    condition: 'ACTIVE',
    'status.min': 'ROUGH_INCOMPLETE',
    'status.max': 'ROUGH_SUBMITTED',
    columns: [
      'id',
      'sellerStoneName',
      'assortmentId',
      'Assortment',
      'status',
      'updatedAt',
      'sellerId',
      'weight',
      'colour',
      'fluorescence',
      'tinge',
      'location',
      'countryId'
    ]
  },
  SOLD: { key: 'sold', tableKey: TABLE_KEYS.TRANSACTION, isShipped: false, isCancelled: false, columns: '[Rough,Sale]' }
}

function SelectShipmentRoughStones({
  onRoughStonesSelect
}) {
  const { hasAdmin, permissionsAdminCache } = useAuthStore(state => state)
  const [columns, setColumns] = useState([])
  const [tableRequestParams, setTableRequestParams] = useState(FILTERS.UNSOLD)
  const unsoldRequestParams = useMemo(() => objectUtils.omit(FILTERS.UNSOLD, ['key', 'tableKey']), [])
  const soldRequestParams = useMemo(() => objectUtils.omit(FILTERS.SOLD, ['key', 'tableKey']), [])

  const {
    orgsMap: { all: orgsMap },
    roughStatusesMap,
    roughTingesMap,
    roughColoursMap,
    countriesList: { all: countriesList },
    getOrgsList,
    getRoughStatuses,
    getRoughTinges,
    getRoughColours,
    getRoughQCStatuses,
    getCountriesList
  } = useGlobalsStore(store => store)

  function hasTableKey(acceptedKeys = []) {
    return acceptedKeys.includes(tableRequestParams.tableKey)
  }

  function getAccessor(row, ...k) {
    const accessor = hasTableKey([TABLE_KEYS.TRANSACTION]) ? 'Rough' : null
    return dottie.get(row, [accessor, ...k].filter(x => x), null)
  }

  const {
    roughStonesList: {
      [JSON.stringify(unsoldRequestParams)]: roughStonesList
    },
    getRoughStonesList
  } = useRoughStoneStore(store => store)
  const {
    adminTransactionsList: {
      [JSON.stringify(soldRequestParams)]: transactionsList
    },
    getAdminTransactionsList
  } = useTransactionStore(store => store)

  useEffect(() => {
    getRoughStatuses()
    getRoughTinges()
    getOrgsList()
    getRoughColours()
    getRoughQCStatuses()
    getCountriesList()
  }, [])
  const countriesMap = useMemo(() => arrayUtils.toMap(countriesList, ({ id }) => id, 'name'), [countriesList])

  useEffect(() => {
    refreshStones()
  }, [tableRequestParams])

  function refreshStones() {
    if (hasTableKey([TABLE_KEYS.ROUGH])) getRoughStonesList(unsoldRequestParams)
    else getAdminTransactionsList(soldRequestParams)
  }

  useEffect(() => {
    setColumns([
      {
        Header: 'Clara ID',
        accessor: row => getAccessor(row, 'id'),
        dataType: 'string',
        Cell: cellInfo => {
          return <NavLink
            className='link'
            target='_blank'
            to={`/roughstones/${cellInfo.value}`}
            id={cellInfo.value}>
            {cellInfo.value}
          </NavLink>
        },
        filterType: 'textarea'
      },
      {
        Header: 'Seller Stone Name',
        accessor: row => getAccessor(row, 'sellerStoneName'),
        dataType: 'string'
      },
      {
        Header: 'Assortment Name',
        accessor: row => getAccessor(row, 'Assortment', 'name'),
        dataType: 'string'
      },
      ...(hasAdmin(organizationActions.getOrganizationList)
        ? [{
          Header: 'Seller',
          id: 'sellerName',
          accessor: row => orgsMap?.[row.sellerId] ?? '',
          dataType: 'string'
        }] : []),
      ...(hasAdmin(organizationActions.getOrganizationList) && tableRequestParams.tableKey === TABLE_KEYS.TRANSACTION
        ? [{
          Header: 'Buyer',
          id: 'buyerName',
          accessor: row => orgsMap?.[row.buyerId] ?? '',
          dataType: 'string'
        }] : []),
      ...(tableRequestParams.tableKey === TABLE_KEYS.TRANSACTION
        ? [{
          Header: 'Sale',
          id: 'saleId',
          accessor: row => row.Sale?.name,
          dataType: 'string'
        }] : []),
      {
        Header: 'Weight',
        accessor: row => getAccessor(row, 'weight'),
        dataType: 'number',
        filterType: 'numberRange'
      },
      {
        Header: 'Status',
        id: 'statusDescription',
        accessor: row => roughStatusesMap?.[getAccessor(row, 'status')] ?? '',
        dataType: 'string',
        filterType: 'checkbox',
        enums: Object.values(roughStatusesMap)
      },
      {
        Header: 'Location',
        accessor: row => getAccessor(row, 'location'),
        dataType: 'string',
        filterType: 'checkbox'
      },
      {
        Header: 'Color',
        id: 'colour',
        accessor: row => roughColoursMap?.[getAccessor(row, 'colour')] ?? null,
        dataType: 'string',
        filterType: 'checkbox',
        enums: Object.values(roughColoursMap).concat([null])
      },
      {
        Header: 'Flu',
        accessor: row => getAccessor(row, 'fluorescence'),
        dataType: 'number',
        filterType: 'numberRange'
      },
      {
        Header: 'Tinge',
        id: 'tingeDescription',
        accessor: row => roughTingesMap?.[getAccessor(row, 'tinge')] ?? null,
        dataType: 'string',
        filterType: 'checkbox',
        enums: Object.values(roughTingesMap).concat([null])
      },
      {
        Header: 'Country',
        id: 'country',
        accessor: row => countriesMap?.[getAccessor(row, 'countryId')] ?? '',
        dataType: 'string'
      },
      {
        Header: 'Last Updated',
        id: 'updatedAt',
        accessor: row => getAccessor(row, 'updatedAt'),
        dataType: 'date',
        filterType: 'date'
      }
    ])
  }, [tableRequestParams, roughStonesList?.data, transactionsList?.data, orgsMap, roughStatusesMap, roughColoursMap, roughTingesMap, countriesMap, permissionsAdminCache])

  const tableTabs = [
    {
      id: 'unsold',
      label: 'Unsold',
      params: FILTERS.UNSOLD
    },
    {
      id: 'sold',
      label: 'Sold',
      params: FILTERS.SOLD
    }
  ]

  const topBarActions = [{
    label: 'Next',
    typeVariant: 'primary',
    callback: (rows) => onRoughStonesSelect(arrayUtils.pickBy(rows?.selectedRows, row => ({ ...getAccessor(row), sellerId: row.sellerId, buyerId: row.buyerId })))
  }]

  const data = useMemo(() => hasTableKey([TABLE_KEYS.ROUGH]) ? roughStonesList?.data : transactionsList?.data, [tableRequestParams, roughStonesList?.data, transactionsList?.data])

  return (
    <Table
      title="Rough Stones"
      data={data}
      columns={columns}
      tableTabs={tableTabs}
      initialSort={[{ id: 'updatedAt', desc: true }]}
      getDataCallback={(params) => setTableRequestParams({ ...params })}
      topBarActions={topBarActions}
    />
  )
}

SelectShipmentRoughStones.propTypes = {
  onRoughStonesSelect: PropTypes.func
}

export default SelectShipmentRoughStones
