import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { notificationsActions } from '@actions'
import { DetailsPage } from '@templates'
import { COMMON } from '@constants'
import NotificationActionList from './notificationActionList'
import NotificationAttachmentList from './notificationAttachmentList/notificationAttachmentList'
import { usePageTitle, useToast } from '@hooks'
import moment from 'moment'

function NotificationTemplateDetails({ match, title }) {
  const { templateId } = match?.params
  const [template, setTemplate] = useState(null)
  const [fields, setFields] = useState([])
  const [actions, setActions] = useState([0])
  const [isSendEmail, setIsSendEmail] = useState(false)
  const [notificationTimeOffset, setNotificationTimeOffset] = useState({})
  const [emailTimeOffset, setEmailTimeOffset] = useState({})
  const { showSuccessToast } = useToast()
  const hours = [...Array(24).keys()].map(i => ({ value: i, label: i }))
  const minsSeconds = [...Array(60).keys()].map(i => ({ value: i, label: i }))
  const notificationTypesObj = COMMON.NOTIFICATION.TYPE
  const notificationTypes = Object.keys(notificationTypesObj).map(type => {
    return {
      value: type,
      label: notificationTypesObj[type]
    }
  })
  usePageTitle(title, templateId, template?.templateName)

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (template?.app?.actions && template?.app?.actions?.length) {
      setActions(template.app?.actions.map((_, index) => index))
    }
  }, [template])

  useEffect(() => {
    setIsSendEmail(!!template?.email?.subject)
  }, [template])

  useEffect(() => {
    if (template?.app?.notifyTimeOffset) {
      setNotificationTimeOffset(parseDuration(template.app.notifyTimeOffset))
    }

    if (template?.email?.sendTimeOffset) {
      setEmailTimeOffset(parseDuration(template.email.sendTimeOffset))
    }
  }, [template])

  useEffect(() => {
    if (template) {
      setFields([
        {
          label: 'Template Name',
          name: 'templateName',
          value: template.templateName,
          canEdit: false
        },
        {
          label: 'Notification Type',
          name: 'app.notificationType',
          value: template.app?.notificationType,
          canEdit: true,
          componentName: 'dropdown',
          options: notificationTypes,
          isClearable: false
        },
        {
          label: 'Notification Title',
          name: 'app.title',
          value: template.app?.title,
          canEdit: true
        },
        {
          legend: 'Notification Time Offset',
          componentName: 'fieldset',
          name: 'notificationTimeOffset',
          inline: true,
          canEdit: true,
          children: [
            {
              label: 'Days',
              name: 'app.timeOffset.days',
              type: 'number',
              max: 365,
              value: notificationTimeOffset.days,
              canEdit: true
            },
            {
              label: 'Hours',
              name: 'app.timeOffset.hours',
              isClearable: false,
              componentName: 'dropdown',
              value: notificationTimeOffset.hours,
              options: hours,
              canEdit: true
            },
            {
              label: 'Minutes',
              name: 'app.timeOffset.minutes',
              isClearable: false,
              componentName: 'dropdown',
              value: notificationTimeOffset.minutes,
              options: minsSeconds,
              canEdit: true
            },
            {
              label: 'Seconds',
              name: 'app.timeOffset.seconds',
              isClearable: false,
              componentName: 'dropdown',
              value: notificationTimeOffset.seconds,
              options: minsSeconds,
              canEdit: true
            }
          ]
        },
        {
          label: 'Notification Body',
          name: 'app.body',
          value: template.app?.body,
          canEdit: true,
          componentName: 'textArea',
          span: true
        },
        {
          legend: 'Actions',
          componentName: 'fieldset',
          name: 'actions',
          span: true,
          canEdit: true,
          children: [
            {
              name: 'app.actions',
              value: template.app?.actions || [],
              actions,
              setActions,
              canEdit: true,
              customComponent: NotificationActionList,
              isDetailsPage: true,
              span: true
            }
          ]
        },
        {
          label: 'Send Email',
          name: 'sendEmail',
          onChange: e => setIsSendEmail(e?.target?.checked),
          checked: isSendEmail,
          canEdit: true,
          componentName: 'checkbox',
          span: true
        },
        {
          label: 'Subject',
          name: 'email.subject',
          value: template?.email?.subject,
          canEdit: isSendEmail,
          span: true
        },
        {
          label: 'Send From',
          name: 'email.sendFrom',
          value: template?.email?.sendFrom,
          canEdit: isSendEmail,
          span: true
        },
        {
          label: 'Reply To',
          name: 'email.replyTo',
          value: template?.email?.replyTo,
          canEdit: isSendEmail,
          span: true
        },
        {
          label: 'Email Body',
          name: 'email.body',
          value: template?.email?.body,
          componentName: 'textArea',
          canEdit: isSendEmail,
          span: true
        },
        {
          legend: 'Email Time Offset',
          componentName: 'fieldset',
          name: 'emailTimeOffset',
          canEdit: isSendEmail,
          inline: true,
          span: true,
          children: [
            {
              label: 'Days',
              name: 'email.timeOffset.days',
              type: 'number',
              value: emailTimeOffset.days,
              canEdit: isSendEmail
            },
            {
              label: 'Hours',
              name: 'email.timeOffset.hours',
              isClearable: false,
              componentName: 'dropdown',
              value: emailTimeOffset.hours,
              options: hours,
              canEdit: isSendEmail
            },
            {
              label: 'Minutes',
              name: 'email.timeOffset.minutes',
              isClearable: false,
              componentName: 'dropdown',
              value: emailTimeOffset.minutes,
              options: minsSeconds,
              canEdit: isSendEmail
            },
            {
              label: 'Seconds',
              name: 'email.timeOffset.seconds',
              isClearable: false,
              componentName: 'dropdown',
              value: emailTimeOffset.seconds,
              options: minsSeconds,
              canEdit: isSendEmail
            }
          ]
        },
        {
          name: 'email.attachments',
          value: template.email?.attachments || [],
          isDetailsPage: true,
          canEdit: isSendEmail,
          customComponent: NotificationAttachmentList,
          span: true
        },
        {
          legend: 'Recipients',
          componentName: 'fieldset',
          name: 'email.recipients',
          span: true,
          inline: true,
          canEdit: isSendEmail,
          children: [
            {
              label: 'To',
              name: 'email.recipients.tos',
              value: template.email?.recipients?.tos,
              canEdit: true,
              componentName: 'textarea'
            },
            {
              label: 'Cc',
              name: 'email.recipients.ccs',
              value: template.email?.recipients?.ccs,
              canEdit: true,
              componentName: 'textarea'
            },
            {
              label: 'Bcc',
              name: 'email.recipients.bccs',
              value: template.email?.recipients?.bccs,
              canEdit: true,
              componentName: 'textarea'
            }
          ]
        }
      ])
    }
  }, [template, actions, notificationTimeOffset, emailTimeOffset, isSendEmail])

  function getData() {
    notificationsActions.getNotificationTemplates({ templateId })
    .then(response => setTemplate(response.data.data[0]))
  }

  function handleSubmit(editValues) {
    if (editValues?.app?.timeOffset) {
      const timeOffset = sanitizeUpdatedTimeOffset(editValues?.app?.timeOffset, template?.app?.notifyTimeOffset)
      editValues.app.notifyTimeOffset = moment.duration({ ...timeOffset })
      delete editValues.app.timeOffset
    }

    if (editValues?.email?.timeOffset) {
      const timeOffset = sanitizeUpdatedTimeOffset(editValues?.email?.timeOffset, template?.email?.sendTimeOffset)
      editValues.email.sendTimeOffset = moment.duration({ ...timeOffset })
      delete editValues.email.timeOffset
    }

    if (!isSendEmail) {
      editValues.email = null
    }

    return notificationsActions.editNotificationTemplate(templateId, editValues)
    .then(() => {
      showSuccessToast('Notification template updated!')
      getData()
    })
  }

  function sanitizeUpdatedTimeOffset(updatedValues, initialDuration = { days: 0, hours: 0, minutes: 0, seconds: 0 }) {
    const initialTimeOffset = parseDuration(initialDuration)
    return parseDuration({
      days: updatedValues.days != null && !isNaN(updatedValues.days) ? updatedValues.days : initialTimeOffset.days,
      hours: updatedValues.hours != null && !isNaN(updatedValues.hours) ? updatedValues.hours : initialTimeOffset.hours,
      minutes: updatedValues.minutes != null && !isNaN(updatedValues.minutes) ? updatedValues.minutes : initialTimeOffset.minutes,
      seconds: updatedValues.seconds != null && !isNaN(updatedValues.seconds) ? updatedValues.seconds : initialTimeOffset.seconds
    })
  }

  function parseDuration(duration) {
    const timeOffset = moment.duration(duration)
    // rounding down the absolute duration length in days
    // because if the number of days is greater than 30
    // days, the duration object will
    // return number of months and remaining days.
    // for example, if user sets time offset for 35 days
    // that would result into {months: 1, days: 4, ...} duration
    // so in the UI instead of 34 it would set the value of 4.
    // I believe it's safe to assume that the length of the duration
    // in days will never be greater than the specified day because we are
    // limiting hours, minutes and seconds values.
    const days = Math.floor(timeOffset.asDays())
    const hours = timeOffset.hours()
    const minutes = timeOffset.minutes()
    const seconds = timeOffset.seconds()
    return { days, hours, minutes, seconds }
  }

  return (
    <DetailsPage
      title={{
        label: 'Template ID',
        value: template?.templateId
      }}
      canEdit={true}
      fields={fields}
      onSubmit={handleSubmit}
    />
  )
}

NotificationTemplateDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string
}

export default NotificationTemplateDetails
