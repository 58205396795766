import React, { useEffect } from 'react'
import { FormComponents } from '@organisms'
import { provenanceActions } from '@actions'
import { useGlobalsStore } from '@stores'
import { useToast } from '@hooks'
import { useHistory } from 'react-router-dom'

function CreatePipe() {
  const {
    SimpleForm,
    TextInput,
    Dropdown,
    Button
  } = FormComponents

  const { showSuccessToast } = useToast()

  const provParams = { condition: 'ACTIVE' }
  const {
    getMinesList,
    getOrgsList,
    orgsList: { [JSON.stringify({ condition: 'ACTIVE' })]: orgsList },
    resetPipesList,
    minesList: { [JSON.stringify(provParams)]: mineList = [] }
  } = useGlobalsStore(store => store)

  useEffect(() => {
    getMinesList(provParams)
  }, [])

  useEffect(() => {
    getOrgsList({ condition: 'ACTIVE' })
  }, [])

  const history = useHistory()
  function handleSubmit({ ...values }) {
    for (const key in values) {
      if (values[key] === '') delete values[key]
    }
    provenanceActions.createPipe(values)
    .then(async () => {
      showSuccessToast('Pipe added.')
      resetPipesList()
      history.push('/seller-settings')
    })
    .catch(err => console.error(err))
  }

  return (
    <div className="create-pipe">
      <div className="col-xl-4 col-lg-7 col-md-10 col-sm-12">
        <SimpleForm
          title='New Pipe'
          onSubmit={handleSubmit}
        >
          <TextInput
            name='name'
            label='Name'
          />
          <Dropdown
            name='mineId'
            label='Mine'
            options={mineList.map(mine => {
              return { label: mine.name, value: mine.id }
            })}
          />
          <Dropdown
            name='sellerId'
            label='Seller'
            required={false}
            options={orgsList?.map(org => {
              return { label: org.commonName, value: org.orgId }
            })}
          />
          <Button typeVariant="action" onClick={() => history.goBack()}>
            Cancel
          </Button>
          <Button
            type='submit'
            size='sm'
          >
            Create Pipe
          </Button>
        </SimpleForm>
      </div>
    </div>
  )
}

export default CreatePipe
