import create from 'zustand'

const useTabsStore = create((set, get) => ({
  activeTabs: {},
  setActiveTabIndex: (tabName, activeIndex) => {
    if (!tabName) return
    set({ activeTabs: { ...get().activeTabs, [tabName]: activeIndex } })
  },
  resetOrderTabs: (tabName) => {
    const activeTabs = { ...get().activeTabs }
    if (!tabName) set({ activeTabs: Object.entries(activeTabs).reduce((tabs, [tabK, tabV]) => tabK.startsWith('order') ? tabs : ({ ...tabs, [tabK]: tabV }), {}) })
    else if (tabName in activeTabs) {
      delete activeTabs[tabName]
      set({ activeTabs })
    }
  }
}))

export {
  useTabsStore
}
