import React from 'react'
import PropTypes from 'prop-types'

function HelperText({ text, type }) {
  const classNameSuffix = (() => {
    return {
      success: '--success',
      warning: '--warning',
      error: '--error'
    }[type] || ''
  })()

  return (
    <div className="input__helper-text-container">
      <span className={`input__helper-text${classNameSuffix}`}>
        {text}
      </span>
    </div>
  )
}

HelperText.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string
}

HelperText.defaultProps = {
  text: '',
  type: ''
}

export default HelperText
