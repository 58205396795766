import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { FormComponents } from '@organisms'
import { SETTINGS } from '@constants'
import clone from 'just-clone'
const { SimpleForm, TextInput, TextArea, SingleCheckbox, Dropdown, Button } = FormComponents

const ComponentMap = {
  textinput: TextInput,
  textarea: TextArea,
  singlecheckbox: SingleCheckbox,
  dropdown: Dropdown,
  autodetect(type) {
    let field
    switch (type) {
      case 'string':
        field = this.textinput
        break
      case 'number':
        field = this.textinput
        break
      case 'boolean':
        field = this.singlecheckbox
        break
      case 'textarea':
        field = this.textarea
        break
      default:
        field = this.textinput
    }
    return field
  },
  props(type) {
    const obj = {}
    switch (type) {
      case 'string':
        break
      case 'boolean':
        obj.required = false
        break
      case 'number':
        obj.type = 'number'
        obj.decimalScale = undefined
        break
      default:
    }
    return obj
  }
}
function SettingsModal({ mode, setting: initSetting, groupKeys, handleSubmit, handleCancel }) {
  const [setting, setSetting] = useState(initSetting)
  const [groupKey, setGroupKey] = useState(initSetting.groupKey)
  const [type, setType] = useState(initSetting.type)
  const schema = SETTINGS.SAVE[groupKey] || SETTINGS.SAVE.DEFAULT

  const formFields = useMemo(() => ([
    ...((mode === 'create' ? SETTINGS.CREATE.GROUP : []).map(({ component, ...restProps }) =>
      (
        React.cloneElement({ type: ComponentMap[component], ref: null }, {
          key: restProps.name,
          options: groupKeys.map(key => ({ value: key, label: key })),
          extraOnChange: (_, e) => {
            setGroupKey(e.currentTarget.value)
            setType()
            setSetting({
              groupKey: e.currentTarget.value
            })
          },
          ...restProps
        })
      ))),
    ...((mode === 'create' ? SETTINGS.CREATE.ITEM : []).map(({ component, ...restProps }) =>
      (
        React.cloneElement({ type: ComponentMap[component], ref: null }, {
          key: restProps.name,
          ...restProps
        })
      ))),
    ...((mode === 'create' ? SETTINGS.CREATE.TYPE : []).map(({ component, ...restProps }) =>
      (
        React.cloneElement({ type: ComponentMap[component], ref: null }, {
          key: restProps.name,
          extraOnChange: (_, e) => setType(e.currentTarget.value),
          ...restProps,
          ...(schema.find(({ name }) => name === 'value')?.component !== 'autodetect' ? { disabled: true, required: false } : { })
        })
      ))),
    ...schema.map(({ component, ...restProps }) =>
      (
        React.cloneElement({
          type: component === 'autodetect'
            ? ComponentMap[component](type)
            : ComponentMap[component],
          ref: null
        }, { key: restProps.name, ...restProps, ...ComponentMap.props(type) })
      )
    )
  ]), [setting, mode, groupKey, type, groupKeys])

  function onSubmit(val) {
    const valCopy = clone(val)
    if (valCopy?.type === 'boolean') valCopy.value = !!valCopy.value

    const editFields = schema
    if (editFields) {
      for (const editField of editFields) {
        if (!(editField.name in valCopy)) {
          if (editField.component === 'singlecheckbox') valCopy[editField.name] = false
          else valCopy[editField.name] = null
        }
      }
    }
    return handleSubmit(valCopy)
  }

  return (
    <SimpleForm initialValues={setting} enableReinitialize={true} onSubmit={onSubmit}>
      {formFields}
      <div key='row-buttons' className='modal__buttons'>
        <Button typeVariant='secondary' size='sm' onClick={handleCancel}>Cancel</Button>
        <Button type='submit' typeVariant='primary' size='sm'>{mode === 'edit' ? 'Save' : 'Create'}</Button>
      </div>
    </SimpleForm>
  )
}

SettingsModal.propTypes = {
  mode: PropTypes.string,
  setting: PropTypes.object.isRequired,
  groupKeys: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired
}

SettingsModal.defaultProps = {
  mode: 'edit',
  setting: {},
  groupKeys: []
}

export default SettingsModal
