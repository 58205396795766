import React, { useEffect, useState } from 'react'
import { useAuthStore, useGlobalsStore } from '@stores'
import { provenanceActions } from '@actions'
import { useToast } from '@hooks'
import { Table } from '@organisms'
import { useHistory, NavLink } from 'react-router-dom'

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    dataType: 'string',
    Cell: cellInfo => {
      return (
        <NavLink
          className="link"
          to={`/mines/${cellInfo.row.original.id}`}
          id={cellInfo.row.original.id}
        >
          {cellInfo.value}
        </NavLink>
      )
    }
  },
  {
    Header: 'Secondary Market',
    accessor: 'secondaryMarket',
    dataType: 'boolean',
    filterType: 'checkbox'
  }
]

function MineList() {
  const history = useHistory()
  const { showSuccessToast } = useToast()
  const { orgId: userOrgId, hasAdmin, hasPermission, permissionsAdminCache } = useAuthStore(state => state)
  const [provParams, setProvParams] = useState()
  const {
    getMinesList,
    resetMinesList,
    minesList: { [provParams ? JSON.stringify(provParams) : 'all']: mines }
  } = useGlobalsStore(store => store)

  useEffect(() => {
    if (!permissionsAdminCache.size) return
    getData('ACTIVE')
  }, [permissionsAdminCache])

  function getData(condition) {
    setProvParams(getParams(condition))
    getMinesList(getParams(condition))
  }

  function getParams(condition = 'ACTIVE') {
    if (hasAdmin(provenanceActions.getMinesList)) return { condition }
    return {
      sellerId: userOrgId,
      condition: 'ACTIVE'
    }
  }

  function handleArchive(row) {
    return provenanceActions.setMineCondition(row.id, 'ARCHIVED')
    .then(() => {
      showSuccessToast('Mine has been archived.')
    })
  }

  function handleUnarchive(row) {
    return provenanceActions.setMineCondition(row.id, 'ACTIVE')
    .then(() => {
      showSuccessToast('Mine has been unarchived.')
    })
  }

  const rowActions = [
    {
      actionName: 'archive',
      callback: handleArchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ACTIVE' && hasPermission(provenanceActions.setMineCondition)
    },
    {
      actionName: 'unarchive',
      callback: handleUnarchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ARCHIVED' && hasPermission(provenanceActions.setMineCondition)
    }
  ]

  const topBarActions = hasPermission(provenanceActions.createMine)
    ? [{
      label: 'Create Mine',
      callback: () => history.push('/mines/create')
    }]
    : []

  const tableTabs = !hasAdmin(provenanceActions.getMines)
    ? null
    : [{
      label: 'Active',
      params: 'ACTIVE'
    },
    {
      label: 'Archived',
      params: 'ARCHIVED'
    }]

  return (
    <Table
      id='minesTable'
      title='Mines'
      data={mines}
      columns={columns}
      tableTabs={tableTabs}
      rowActions={rowActions}
      getDataCallback={getData}
      resetDataCallback={resetMinesList}
      topBarActions={topBarActions}
      isMultiSelect={false}
    />
  )
}

export default MineList
