import React from 'react'
import { dateRangeFilter, DateRangeFilter } from './dateRangeFilter'
import { dropdownFilter, DropdownFilter } from './dropdownFilter'
import { NumberRangeFilter } from './numberRangeFilter'
import { CheckboxFilter, checkboxFilter } from './checkboxFilter'
import DefaultColumnFilter from './defaultColumnFilter'
import { TextAreaFilter, textAreaFilter } from './textAreaFilter'
import FilterPopup from './filterPopup'

function NumberRange (props) {
  return (
    <FilterPopup
      FilterComponent={NumberRangeFilter}
      {...props}
    />
  )
}

function Checkbox (props) {
  return (
    <FilterPopup
      FilterComponent={CheckboxFilter}
      {...props}
    />
  )
}

function DateRange (props) {
  return (
    <FilterPopup
      FilterComponent={DateRangeFilter}
      {...props}
    />
  )
}

function Dropdown (props) {
  return (
    <FilterPopup
      FilterComponent={DropdownFilter}
      {...props}
    />
  )
}

function TextArea (props) {
  return (
    <FilterPopup
      FilterComponent={TextAreaFilter}
      {...props}
    />
  )
}

function DefaultColumn(props) {
  return (
    <FilterPopup
      FilterComponent={DefaultColumnFilter}
      {...props}
    />
  )
}

export {
  dropdownFilter,
  checkboxFilter,
  dateRangeFilter,
  textAreaFilter,
  TextArea as TextAreaFilter,
  Dropdown as DropdownFilter,
  Checkbox as CheckboxFilter,
  DateRange as DateRangeFilter,
  NumberRange as NumberRangeFilter,
  DefaultColumn as DefaultColumnFilter
}
