import React from 'react'
import { Button } from '@atoms'
import { DisplayMessage } from '@molecules'
import clone from 'just-clone'
import { objectUtils } from '@utils'

function CreateRoughStoneBtn(props) {
  const {
    validationError,
    validateAndGoNextStep,
    validateAndSubmit
  } = props.useMultiSteps(props)

  function goNextStep() {
    validateAndGoNextStep(props.requiredFields)
  }

  function handleSubmit(data, submitStone = false) {
    if (!data) return

    const submitData = clone(data)

    if (submitData.eyeMeasurement) submitData.eyeMeasurement = objectUtils.filterNullish(submitData.eyeMeasurement, true)
    if (submitData.otherAttributes) submitData.otherAttributes = objectUtils.filterNullish(submitData.otherAttributes, true)

    if (submitData.measurements) {
      submitData.measurements = [
        ...submitData.measurements
          .filter(measurement => !!measurement.colour)
          .map(measurement => {
            delete measurement.fluorRange
            return measurement
          })
      ]
    } else submitData.measurements = []

    for (const key in submitData) {
      if (submitData[key] === null || objectUtils.isEmpty(submitData[key])) delete submitData[key]
    }

    if (submitStone) submitData.status = 'ROUGH_SUBMITTED'
    else submitData.status = 'ROUGH_INCOMPLETE'

    validateAndSubmit({
      requiredFields: [],
      data: submitData,
      redirectPath: `/assortments/${submitData.assortmentId}`,
      successMessage: `Rough stone ${submitStone ? 'submitted' : 'created'}!`
    })
  }

  return (
    <div>
      {validationError.error && (
        <DisplayMessage
          type={'error'}
          message={
            <>
              <div>Please, fill all required fields:</div>
              {validationError.blankFields.map((blankField, i) => {
                return (
                  <span key={i}>
                    <strong>- {blankField}</strong>
                  </span>
                )
              })}
            </>
          }
        />
      )}
      <div className="details-page__form-btns">
        <Button
          disabled={!props.hasPrev()}
          typeVariant="action"
          size="sm"
          onClick={() => props.prev()}
        >
          Previous
        </Button>
        {
          props.current === props.size
            ? <Button
              size="sm"
              typeVariant="secondary"
              disabled={props.filePending}
              onClick={() => handleSubmit(props.state)}
            >
              Save for later
            </Button>
            : null
        }
        <Button
          size="sm"
          typeVariant="primary"
          onClick={() =>
            props.current === props.size
              ? handleSubmit(props.state, true)
              : goNextStep()
          }
        >
          {
            props.current === props.size
              ? 'Submit rough stone'
              : 'Next'
          }
        </Button>
      </div>
    </div>
  )
}

export default CreateRoughStoneBtn
