import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { TextInput, InfoTip, Dropdown, RadioGroup, FormRow } from '@molecules'
import { Checkbox } from '@atoms'
import { Fieldset, Form } from '@organisms'
import { useGlobalsStore } from '@stores'

function OrderLine({ orderLine, onChange, allowedTinges, lineLimits, disabled, validationText, ...props }) {
  const {
    getQuantityTypes,
    quantityTypes,
    polishedTinges: tinges,
    polishedColours: colours,
    clarities,
    polishedFluorescences
  } = useGlobalsStore(state => state)
  useEffect(() => {
    getQuantityTypes()
  }, [])

  const allowedColours = useMemo(() => {
    if (!colours) return []
    return colours.reduce(({ flag, arr }, c) => {
      if (c.value === lineLimits.maxColour) flag = true
      arr.push({ value: c.value, label: c.description, isDisabled: !flag })
      if (c.value === lineLimits.minColour) flag = false
      return { flag, arr }
    }, { flag: false, arr: [] }).arr
  }, [colours, lineLimits.maxColour, lineLimits.minColour])

  const allowedClarities = useMemo(() => {
    if (!clarities) return []
    return clarities.reduce(({ flag, arr }, c) => {
      if (c.value === lineLimits.maxClarity) flag = true
      arr.push({ value: c.value, label: c.description, isDisabled: !flag })
      if (c.value === lineLimits.minClarity) flag = false
      return { flag, arr }
    }, { flag: false, arr: [] }).arr
  }, [clarities, lineLimits.maxClarity, lineLimits.minClarity])

  const allowedFluors = useMemo(() => {
    if (!polishedFluorescences) return []
    return polishedFluorescences.reduce(({ flag, arr }, f) => {
      arr.push({ value: f.value, label: f.description + (f.min !== undefined && f.max !== undefined ? ` (${f.min}-${f.max})` : ''), isDisabled: !flag })
      if (f.value === lineLimits.maxFluorescence) flag = false
      return { flag, arr }
    }, { flag: true, arr: [] }).arr
  }, [polishedFluorescences, lineLimits.maxFluorescence])

  function handleCheckbox(e) {
    onChange({ currentTarget: { name: e.currentTarget.name, value: e.currentTarget.checked } })
  }

  if (!orderLine) {
    return (
      <Form>
        <div className="order-lines__item" />
      </Form>
    )
  }

  return (
    <Form>
      <div className="order-lines__item">
        <div className="order-lines__item-columns">
          <div className="order-lines__item-column">
            <div className="order-lines__item-quantity">
              <div>
                <TextInput
                  label={
                    <div className="order-lines__item-quantity-label">
                      Quantity
                      <div className="subtext">
                        ({orderLine.quantityFilled ?? 0} Filled)
                      </div>
                      <InfoTip name='orderLineQty' />
                    </div>
                  }
                  name={`${orderLine.id}/quantity`}
                  value={orderLine.quantityType === 'TOPS' ? '' : orderLine.quantity}
                  onChange={onChange}
                  allowNegative={false}
                  type='number'
                  decimalScale={0}
                  disabled={disabled || orderLine.quantityType === 'TOPS'}
                  validationText={validationText?.parseStep('quantity')}
                />
              </div>
              <div>
                <RadioGroup
                  name={`${orderLine.id}/quantityType`}
                  value={orderLine.quantityType}
                  onChange={onChange}
                  disabled={disabled}
                  options={quantityTypes?.map(quantityType => {
                    return {
                      label: quantityType.description,
                      value: quantityType.value,
                      name: quantityType.value
                    }
                  })}
                />
              </div>
            </div>
            <div className='order-lines__item-strict-limits'>
              <Checkbox
                label='Strict upper weight'
                name={`${orderLine.id}/strictLimits.weight`}
                onChange={handleCheckbox}
                value={orderLine.strictLimits?.weight}
                disabled={disabled}
                infoTip={InfoTip.messages.strictLimitsWeight}
              />
              <Checkbox
                label='Strict upper clarity'
                name={`${orderLine.id}/strictLimits.clarity`}
                onChange={handleCheckbox}
                value={orderLine.strictLimits?.clarity}
                disabled={disabled}
                infoTip={InfoTip.messages.strictLimitsClarity}
              />
              <Checkbox
                label='Strict upper color'
                name={`${orderLine.id}/strictLimits.colour`}
                onChange={handleCheckbox}
                value={orderLine.strictLimits?.colour}
                disabled={disabled}
                infoTip={InfoTip.messages.strictLimitsColour}
              />
            </div>
          </div>
          <div className="order-lines__item-column">
            <Fieldset
              legend='Tinge Filters'
            >
              {
                // allowedTinges.map(tinge => {
                tinges?.map(tinge => {
                  if (!allowedTinges.includes(tinge.value)) return null
                  return (
                    <Checkbox
                      key={tinge.value}
                      label={tinge.description}
                      name={`${orderLine.id}/tingesIncluded.${tinge.value}`}
                      onChange={handleCheckbox}
                      value={orderLine.tingesIncluded?.[tinge.value]}
                      disabled={disabled || (allowedTinges.length === 1 && orderLine.tingesIncluded?.None)}
                    />
                  )
                })
              }
            </Fieldset>
          </div>
        </div>
        <FormRow validationText={validationText?.parseStep('weight')}>
          <TextInput
            name={`${orderLine.id}/minWeight`}
            label='Min Weight'
            value={orderLine.minWeight}
            onChange={onChange}
            type='number'
            disabled={disabled}
            allowNegative={false}
            validationText={validationText?.parseStep('minWeight')}
          />
          <TextInput
            name={`${orderLine.id}/maxWeight`}
            label='Max Weight'
            value={orderLine.maxWeight}
            onChange={onChange}
            type='number'
            disabled={disabled}
            allowNegative={false}
            validationText={validationText?.parseStep('maxWeight')}
          />
        </FormRow>
        <div className="order-lines__item-range">
          <Dropdown
            name={`${orderLine.id}/maxColour`}
            label='Max Color'
            isClearable={false}
            value={orderLine.maxColour}
            onChange={onChange}
            options={allowedColours.map((col, idx) => {
              return Object.assign({}, col, {
                isDisabled: col.isDisabled || idx > allowedColours.findIndex(x => x.value === orderLine.minColour)
              })
            })}
            disabled={disabled}
            validationText={validationText?.parseStep('maxColour')}
          />
          <Dropdown
            name={`${orderLine.id}/minColour`}
            label='Min Color'
            isClearable={false}
            value={orderLine.minColour}
            onChange={onChange}
            // options={allowedColours}
            options={allowedColours.map((col, idx) => {
              return Object.assign({}, col, {
                isDisabled: col.isDisabled || idx < allowedColours.findIndex(x => x.value === orderLine.maxColour)
              })
            })}
            disabled={disabled}
            validationText={validationText?.parseStep('minColour')}
          />
        </div>
        <div className="order-lines__item-range">
          <Dropdown
            name={`${orderLine.id}/maxClarity`}
            label='Max Clarity'
            isClearable={false}
            value={orderLine.maxClarity}
            onChange={onChange}
            options={allowedClarities.map((cl, idx) => {
              return Object.assign({}, cl, {
                isDisabled: cl.isDisabled || idx > allowedClarities.findIndex(x => x.value === orderLine.minClarity)
              })
            })}
            disabled={disabled}
            validationText={validationText?.parseStep('maxClarity')}
          />
          <Dropdown
            name={`${orderLine.id}/minClarity`}
            label='Min Clarity'
            isClearable={false}
            value={orderLine.minClarity}
            onChange={onChange}
            options={allowedClarities.map((cl, idx) => {
              return Object.assign({}, cl, {
                isDisabled: cl.isDisabled || idx < allowedClarities.findIndex(x => x.value === orderLine.maxClarity)
              })
            })}
            disabled={disabled}
            validationText={validationText?.parseStep('minClarity')}
          />
        </div>
        <div className="order-lines__item-range">
          <Dropdown
            name={`${orderLine.id}/minFluorescence`}
            label='Min Fluor'
            isClearable={false}
            value={orderLine.minFluorescence}
            onChange={onChange}
            options={allowedFluors.map((flu, idx) => {
              return Object.assign({}, flu, {
                isDisabled: flu.isDisabled || idx > allowedFluors.findIndex(x => x.value === orderLine.maxFluorescence)
              })
            })}
            disabled={disabled}
            validationText={validationText?.parseStep('minFluorescence')}
          />
          <Dropdown
            name={`${orderLine.id}/maxFluorescence`}
            label='Max Fluor'
            isClearable={false}
            value={orderLine.maxFluorescence}
            onChange={onChange}
            options={allowedFluors.map((flu, idx) => {
              return Object.assign({}, flu, {
                isDisabled: flu.isDisabled || idx < allowedFluors.findIndex(x => x.value === orderLine.minFluorescence)
              })
            })}
            disabled={disabled}
            validationText={validationText?.parseStep('maxFluorescence')}
          />
        </div>
      </div>
    </Form>
  )
}

OrderLine.propTypes = {
  orderLine: PropTypes.object,
  onChange: PropTypes.func,
  allowedTinges: PropTypes.array,
  lineLimits: PropTypes.object,
  disabled: PropTypes.bool,
  validationText: PropTypes.object
}

OrderLine.defaultProps = {
}

export default OrderLine
