import React, { useState, useEffect } from 'react'
import { Table } from '@organisms'
import { notificationsActions } from '@actions'
import { NavLink, useHistory } from 'react-router-dom'
import { COMMON } from '@constants'

function NotificationTemplateList() {
  const [notificationTemplateList, setNotificationTemplateList] = useState([])
  const notificationTypes = COMMON.NOTIFICATION.TYPE
  const history = useHistory()

  useEffect(() => {
    notificationsActions.getNotificationTemplates()
    .then((response) => setNotificationTemplateList(response.data.data))
  }, [])

  const columns = [
    {
      accessor: 'templateName',
      Header: 'Template Name',
      Cell: cellInfo => {
        return (
          <NavLink
            className="link"
            to={`/notifications/templates/${cellInfo.row.original.templateId}`}
            id={cellInfo.row.original.id}
          >
            {cellInfo.value}
          </NavLink>
        )
      }
    },
    {
      accessor: 'app.title',
      Header: 'Notification Title'
    },
    {
      id: 'app.notificationType',
      accessor: row => notificationTypes[row?.app?.notificationType],
      Header: 'Notification Type',
      filterType: 'checkbox',
      enums: Object.values(notificationTypes)
    },
    {
      Header: 'Send Email',
      accessor: row => !!row?.email?.subject,
      dataType: 'boolean',
      filterType: 'checkbox'
    },
    {
      Header: 'Email Reply To',
      accessor: 'email.replyTo',
      filterType: 'checkbox'
    },
    {
      Header: 'Email Subject',
      accessor: 'email.subject'
    },
    {
      Header: 'Created At',
      dataType: 'date',
      filterType: 'date',
      accessor: 'createdAt'
    },
    {
      Header: 'Updated At',
      dataType: 'date',
      filterType: 'date',
      accessor: 'updatedAt'
    }
  ]
  const topBarActions = [{
    label: 'Create Notification Template',
    callback: () => history.push('/notifications/templates/create')
  }]

  return (
    <Table
      title='Notification Templates'
      data={notificationTemplateList}
      topBarActions={topBarActions}
      columns={columns}
      isMultiSelect={false}
    />
  )
}

export default NotificationTemplateList
