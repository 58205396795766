import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

function NavigationLink({
  name,
  label,
  text,
  url
}) {
  return (
    <div className='link-component'>
      <span className="label">{label}</span>
      <NavLink id={name} to={url}>{text || url}</NavLink>
    </div>
  )
}

NavigationLink.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  label: PropTypes.string
}

NavigationLink.defaultProps = {
  url: '',
  name: '',
  text: '',
  label: ''
}
export default NavigationLink
