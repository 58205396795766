import React from 'react'
import PropTypes from 'prop-types'
import { ErrorText } from '@molecules'

function FormRow({
  className,
  visible,
  validationText,
  children,
  multiLevelValidation,
  ...props
}) {
  return (
    visible
    && <div
      className={`form-row ${className}`}
      {...props}
    >
      <div className="form-row__fields">
        {FormRow.childRenderer(children, validationText, multiLevelValidation)}
      </div>
      <ErrorText {...validationText?.parse()} />
    </div>

  )
}

FormRow.childRenderer = function(children, validationText, multiLevelValidation) {
  if (!children || multiLevelValidation) {
    return children
  }
  return children.map((child, idx) => React.cloneElement(child,
    {
      ...child.props,
      ...('key' in child.props ? {} : { key: idx }),
      validationText: (validationText?.getError() || validationText?.getWarning()) ? undefined : child.props?.validationText, // hide child errors if a parent has an error
      children: FormRow.childRenderer(child.props?.children, validationText, multiLevelValidation)
    }
  ))
}

FormRow.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  validationText: PropTypes.object,
  multiLevelValidation: PropTypes.bool
}

FormRow.defaultProps = {
  visible: true,
  className: '',
  multiLevelValidation: false
}

export default FormRow
