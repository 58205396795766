import React from 'react'
import PropTypes from 'prop-types'
import prettyBytes from 'pretty-bytes'
import { InfoTip } from '@molecules'
import { Icon } from '@atoms'
import ProgressListBar from './progressListBar'

function ProgressListTask({
  task,
  itemConfig,
  index,
  retryTask,
  removeTask
}) {
  return (
    <div className="progress-list__item">
      <div className="progress-list__item-info">
        <span className="progress-list__task-name"
          title={task.name}>{task.name}</span>
        <span
          className="progress-list__task-size">{Number(task.size) ? prettyBytes(Number(task.size) || 0) : ''}</span>
        <div className="progress-list__item-status">
          <span className={`progress-list__item-status-icon${itemConfig.variant}`}>
            {task.error
              ? (
                <>
                  <Icon
                    name='refresh'
                    size='md'
                    title='Retry'
                    onClick={retryTask}
                  />
                  <InfoTip name={`progress-list-taskId-info-${index}`}
                    icon={itemConfig.iconName}
                    size="md">{task.error}</InfoTip>
                </>
              )
              : <Icon name={itemConfig.iconName} size="md"/>
            }
          </span>
        </div>
      </div>
      {task.description && (
        <div className="progress-list__task-description" title={task.description}>
          {task.description}
        </div>
      )}
      <ProgressListBar
        variant={itemConfig?.variant}
        inProgress={task?.status?.isInProgress}
        isSucceeded={task?.status?.isSucceeded}
        isFailed={task?.status?.isFailed}
        progressPercentage={task?.progressPercentage}
      />
      {
        !task.status.isInProgress
          ? (
            <div className="progress-list__item-close-button">
              <Icon
                name="close"
                size="md"
                onClick={removeTask}
              />
            </div>
          ) : null
      }
    </div>
  )
}

ProgressListTask.propTypes = {
  task: PropTypes.object,
  itemConfig: PropTypes.object,
  index: PropTypes.number,
  removeTask: PropTypes.func,
  retryTask: PropTypes.func
}

export default ProgressListTask
