import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Icon, Button } from '@atoms'
import { Modal, ProgressListBar } from '@templates'
import { ErrorText, InfoTip } from '@molecules'
import { arrayUtils, fileUtils, objectUtils } from '@utils'
import { COMMON } from '@constants'

function FileUpload({
  name,
  label,
  required,
  onChange,
  disabled,
  visible,
  acceptTypes,
  fileNameCharLimit,
  expectedFileName,
  fileName: propFileName,
  infoTip,
  validationText,
  ...props
}) {
  const validHTMLElementProps = useMemo(() => {
    const propKeys = Object.keys(props)
    const validKeys = arrayUtils.intersectBy(propKeys, COMMON.HTML_ATTRIBUTES.GLOBAL.concat(COMMON.HTML_ATTRIBUTES.INPUT), (x) => x.toLowerCase())
    return objectUtils.pick(props, validKeys)
  }, [props])
  const [initialFileName] = useState(propFileName)
  const [fileName, setFileName] = useState('')
  const [uploadProgress, setUploadProgress] = useState(null)
  const [confirmFile, setConfirmFile] = useState(false)
  const [waiting, setWaiting] = useState(false)
  const [error, setError] = useState('')
  const [hasFailed, setHasFailed] = useState(false)
  const [hasSucceeded, setHasSucceeded] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [hasExpectedFileName, setHasExpectedFileName] = useState(false)
  useEffect(() => {
    setFileName(propFileName ? String(propFileName).toLowerCase() : '')
  }, [propFileName])

  const expectedFileNames = useMemo(() => {
    if (!expectedFileName) return []
    if (!Array.isArray(expectedFileName)) {
      return [String(expectedFileName).toLocaleLowerCase()]
    }

    return expectedFileName.map(fileName => String(fileName).toLocaleLowerCase())
  }, [expectedFileName])

  function handleOnChange(e) {
    const fileInput = e?.target
    const file = fileInput?.files?.[0]
    setSelectedFile(file)
    if (!expectedFileNames.length || !file || expectedFileNames.some(expFileName => expFileName === String(file?.name).toLowerCase())) {
      selectFile(file)
    } else {
      setConfirmFile(file)
    }
    if (fileInput) fileInput.value = null
    setHasExpectedFileName(false)
  }

  async function selectFile(file) {
    setFileName(file?.name ? String(file.name).toLowerCase() : '')
    setWaiting(true)
    handleOnReset()
    try {
      await onChange(file, handleUpdateProgress)
      setWaiting(false)
      setHasSucceeded(true)
    } catch (err) {
      handleOnError(err.message)
    }
  }

  function handleOnReset() {
    setHasFailed(false)
    setHasSucceeded(false)
    setConfirmFile(false)
    setHasExpectedFileName(false)
    setUploadProgress(null)
  }

  function handleOnError(err) {
    console.error(err)
    setError(err)
    setWaiting(false)
    setHasFailed(true)
  }

  function handleUpdateProgress(_, progress) {
    setUploadProgress(progress)
  }

  function renderFileIcon(fileName, expFileName) {
    if (!fileName || (hasExpectedFileName && fileName !== expFileName)) {
      return null
    }

    if (!hasExpectedFileName && fileName === expFileName) {
      setHasExpectedFileName(true)
    }

    return (
      <div
        className={`file-upload__expected-file-name-icon${fileName === expFileName ? '--success' : '--warning'}`}
      >
        <Icon
          name={fileName === expFileName ? 'check' : 'warning'}
          size='sm'
        />
      </div>
    )
  }

  const fileTypes = acceptTypes.map(fileType => fileType).join(', ')
  const isDisabled = disabled || waiting

  return (
    visible
    && <>
      <div className='file-upload'>
        <div className="file-upload__title">
          <span className='file-upload__label'>{label}</span>
          {infoTip ? <InfoTip name={name}>{infoTip}</InfoTip> : null}
          {!required
            && <small className="text-input__optional-label"> (optional) </small>
          }
        </div>
        <span className="file-upload__file-types">
          {`Accepted file types: ${fileTypes.length ? fileTypes : 'All'}`}
        </span>
        {
          (
            expectedFileNames.length
            // We only want to show the section below if the user currently does not
            // have an initial file set or if their file has been updated
            && (!initialFileName || initialFileName !== propFileName)
          )
            ? (
              <div className="file-upload__expected-file-name">
                <div className='left'>
                  Expected file names:
                </div>
                <div className="file-upload__expected-file-list">
                  {expectedFileNames.map((expFileName, i) => (
                    <div className='flex-row' key={i}>
                      <span>{expFileName}</span>
                      {renderFileIcon(fileName, expFileName)}
                    </div>
                  ))}
                </div>
              </div>
            ) : null
        }
        <div className={`file-upload__input-container${isDisabled ? '--disabled' : ''} ${validationText?.getValidationClass()}`}>
          <div className="file-upload__input-container-row">
            <div className="file-upload__input-upload-button">
              <label className={`file-upload__input-label${isDisabled ? '--disabled' : ''}`}>
                <Icon
                  name='upload'
                  size='lg'
                />
                <span>Choose file</span>
                <input
                  type='file'
                  accept={fileTypes}
                  disabled={isDisabled}
                  onChange={handleOnChange}
                  {...validHTMLElementProps}
                />
              </label>
            </div>
            <div className="file-upload__input-info">
              <div className="file-upload__input-info-description">
                <span title={fileName} >{ fileUtils.shortenFileName(fileName, fileNameCharLimit) || 'No file selected.'}</span>
                {
                  fileName && !waiting
                    ? <div
                      className={`file-upload__remove-icon${isDisabled ? '--disabled' : ''}`}
                      onClick={() => !isDisabled && handleOnChange(null)}
                    >
                      <Icon
                        name='closeCircle'
                        size='md'
                      />
                    </div>
                    : null
                }
              </div>
              {
                waiting || uploadProgress != null ? (
                  <div className="file-upload__input-info-progress-bar-container">
                    <div className="file-upload__input-info-progress-bar">
                      <ProgressListBar
                        progressPercentage={uploadProgress}
                        inProgress={waiting}
                        isSucceeded={hasSucceeded}
                        isFailed={hasFailed}
                      />
                    </div>
                    {
                      hasFailed && error
                        ? (
                          <>
                            <Icon
                              name='refresh'
                              size='md'
                              title='Retry'
                              onClick={() => selectFile(selectedFile)}
                            />
                            <InfoTip
                              icon='exclamation'
                              name='fileUploadError'>
                              {error}
                            </InfoTip>
                          </>
                        ) : null
                    }
                  </div>
                ) : null
              }
            </div>
          </div>
          <div className="file-upload__input-container-row"/>
        </div>
        <ErrorText {...validationText?.parse()} />
      </div>
      <Modal
        open={confirmFile !== false}
        title='Confirm ADV File?'
        onClose={() => setConfirmFile(false)}
        className="dialog_archive"
        position="right center"
      >
        {expectedFileNames?.length && (
          <div className='confirm-dialog__line'>
          Expected file name {expectedFileNames.length > 1 ? 'is one of' : 'is'}: {expectedFileNames.reduce((n, fn, idx, eFn) => {
              if (idx < eFn.length - 1) return n.concat([<strong key={idx}>{fn}</strong>, idx !== eFn.length - 2 ? ', ' : ' or '])
              else return n.concat(<strong key={idx}>{fn}</strong>)
            }, [])}. You have selected {confirmFile?.name}.
          </div>
        )}
        <div className='confirm-dialog__line h4 center'>Are you sure you want to use this file?</div>
        <div className='confirm-dialog__modal-buttons'>
          <Button typeVariant='primary' size='sm' onClick={() => setConfirmFile(false)}>No</Button>
          <Button typeVariant='secondary' size='sm' onClick={() => selectFile(confirmFile)}>Yes</Button>
        </div>
      </Modal>
    </>
  )
}

FileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
  expectedFileName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fileName: PropTypes.string,
  acceptTypes: PropTypes.arrayOf(PropTypes.string),
  infoTip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  fileNameCharLimit: PropTypes.number,
  validationText: PropTypes.object
}

FileUpload.defaultProps = {
  label: '',
  required: true,
  acceptTypes: [],
  disabled: false,
  visible: true,
  expectedFileName: null,
  fileName: undefined,
  onChange: () => console.error('onChange not implemented!')
}

export default FileUpload
