import React, { useEffect, useState } from 'react'
import { useGlobalsStore, useAuthStore } from '@stores'
import { cutGradeActions, organizationActions } from '@actions'
import { Button } from '@atoms'
import { Form } from '@organisms'
import { TextInput, Dropdown, DisplayMessage } from '@molecules'
import { useHistory } from 'react-router-dom'

function MainStep(props) {
  function handleChange(e) {
    if (e?.currentTarget) {
      props.setState(e.currentTarget?.name, e.currentTarget?.value)
    } else {
      props.setState(e?.target?.name, e?.target?.value)
    }
    if (e?.currentTarget?.name === 'shapeId') {
      updateMultipleState(Object.keys(props.state).reduce((propProps, key) => {
        if (key.startsWith('proportions.')) return { ...propProps, [key]: undefined }
        return propProps
      }, {}))
    }
  }

  useEffect(() => {
    props.setState('sarineNonLinearGrade.fixedId', '')
  }, [props.getState('shapeId')])
  useEffect(() => {
    props.setState('sarineNonLinearGrade.cutGradeId', '')
  }, [props.getState('sarineNonLinearGrade.fixedId')])

  const {
    getOrgsList,
    orgsList: { [JSON.stringify({ condition: 'ACTIVE' })]: orgsList },
    fixedIds,
    getFixedIds
  } = useGlobalsStore(store => store)
  const { hasAdmin } = useAuthStore(state => state)
  const isAdmin = hasAdmin(organizationActions.getOrganizationList)

  useEffect(() => {
    getOrgsList({ condition: 'ACTIVE' })
    getFixedIds()
  }, [])

  const [shapesList, setShapesList] = useState([])
  useEffect(() => {
    cutGradeActions.getShapeList({ condition: 'ACTIVE' })
    .then(response => setShapesList(response.data.data))
    .catch(err => console.error(err))
  }, [])
  const selectedShape = shapesList?.find(shape => shape.id === props.getState('shapeId'))

  const fixedIdOptions = fixedIds?.filter(id => id.sarineShapeReplacements[selectedShape?.sarineGenericShapeId])
  const selectedFixedId = fixedIds?.find(id => String(id.value) === props.getState('sarineNonLinearGrade.fixedId'))

  const {
    validationError,
    validateAndGoNextStep,
    updateMultipleState
  } = props.useMultiSteps(props)

  const requiredFields = [
    { name: 'name', label: 'Cut Grade Name' },
    { name: 'institute', label: 'Institute' },
    { name: 'buyerId', label: 'Buyer' },
    { name: 'shapeId', label: 'Shape' }
  ]
  if (props.getState('sarineNonLinearGrade.fixedId')) { requiredFields.push({ name: 'sarineNonLinearGrade.cutGradeId', label: 'Cut Grade for Rules' }) }

  const history = useHistory()

  return (
    <Form title='Create Cut Grade'>
      <TextInput
        name='name'
        label='Cut Grade Name'
        value={props.getState('name')}
        onChange={handleChange}
      />
      <TextInput
        name='institute'
        label='Institute'
        value={props.getState('institute')}
        onChange={handleChange}
      />
      {isAdmin
      && <Dropdown
        name='buyerId'
        label='Owner'
        size='lg'
        value={props.getState('buyerId') || ''}
        onChange={handleChange}
        options={orgsList?.map(org => ({ value: org.orgId, label: org.commonName }))}
      />
      }
      <Dropdown
        name='shapeId'
        label='Shape'
        size='lg'
        value={props.getState('shapeId') || ''}
        onChange={handleChange}
        options={
          shapesList.map(shape => ({
            value: shape.id,
            label: (shape.shapeType === 'CUSTOM' ? 'Custom | ' : 'Preloaded | ') + shape.name
          }))
          .sort((a, b) => { return a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1 })
        }
      />
      <Dropdown
        name='sarineNonLinearGrade.fixedId'
        label='Special Rules'
        size='lg'
        value={String(props.getState('sarineNonLinearGrade.fixedId') || '')}
        onChange={handleChange}
        options={[{ value: '', label: 'None' }].concat(fixedIdOptions?.map(id => ({ value: String(id.value), label: id.description })) || [])}
      />
      <Dropdown
        name='sarineNonLinearGrade.cutGradeId'
        label='Cut Grade for Rules'
        size='lg'
        value={String(props.getState('sarineNonLinearGrade.cutGradeId') || '')}
        onChange={handleChange}
        options={[{ value: '', label: 'None' }].concat(selectedFixedId?.cutGrades?.map(g => ({ value: String(g.value), label: g.description })) || [])}
      />
      {validationError.error && (
        <DisplayMessage
          type={'error'}
          message={
            <>
              <div>Please enter all required fields:</div>
              {validationError.blankFields.map((blankField, i) => {
                return (
                  <span key={i}>
                    <strong>- {blankField}</strong>
                  </span>
                )
              })}
            </>
          }
        />
      )}
      <div className="create-organization-buttons">
        <Button typeVariant="action" onClick={() => history.push('/buyer-settings')}>
          Cancel
        </Button>
        <Button
          disabled={!props.hasNext()}
          typeVariant="primary"
          size="sm"
          onClick={() => validateAndGoNextStep(requiredFields)}
        >
          Next
        </Button>
      </div>
    </Form>
  )
}

export default MainStep
