import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@templates'
import { FileUpload } from '@organisms'
import { fileUtils } from '@utils'
import Excel from 'exceljs/dist/es5/exceljs.browser'
import { useToast } from '@hooks'
import { bidActions } from '@actions'

function UploadBidsResponsesModal({
  open,
  onClose,
  onBidsUploaded,
  localRoughList
}) {
  const { showSuccessToast, showErrorToast } = useToast()

  async function onBidsFileSelect(file) {
    if (file == null) return

    try {
      const buffer = await fileUtils.promiseReadBinaryFile(file)
      const book = new Excel.Workbook()
      await book.xlsx.load(buffer)
      const sheet = book.getWorksheet('Active Bids') || book.worksheets.find(sheet => fileUtils.findHeaderRowIdx(sheet, ['Clara ID', 'Sell? (Y/N)'], 1) > -1)
      if (!sheet) throw new Error('"Active Bids" sheet could not be found in workbook.')
      const headerRow = getHeaderRow(sheet)
      const excelBidsData = buildExcelBidsData(sheet, headerRow)
      const formattedBidsData = formatBidsData(excelBidsData)
      updateBidsData(formattedBidsData)
      .then(onBidsUploaded)
      .then(() => showSuccessToast('Bids successfully uploaded.'))
      .catch(err => showErrorToast(err?.message))
    } catch (err) {
      console.error(err)
      showErrorToast('There was an error reading the excel.')
    }
  }

  function getHeaderRow(sheet) {
    let headerRow = null
    const requiredColumns = ['Clara ID', 'Sell? (Y/N)']

    sheet.eachRow((row, num) => {
      if (headerRow === null && (row.getCell('A').text.toLowerCase() === 'clara id')) headerRow = { num, row }
    })

    if (headerRow == null) throw new Error('No header row could be identified.')
    if (!requiredColumns.every(column => Object.values(headerRow.row.values).map(v => String(v).toLowerCase()).includes(String(column).toLowerCase()))) {
      throw new Error('Missing required columns.')
    }

    return headerRow
  }

  function buildExcelBidsData(sheet, headerRow) {
    const data = []
    sheet.eachRow((row, num) => {
      if (Number(num) > Number(headerRow.num)) {
        let _row = {}
        row.eachCell((cell, colNum) => {
          _row = { ..._row, [String(headerRow.row.values[colNum]).toLowerCase()]: cell.text.trim() }
        })

        data.push(_row)
      }
    })

    return data
  }

  function formatBidsData(data) {
    return data.map(_data => ({
      id: _data['clara id'],
      sellerStoneName: _data.name,
      Assortment: { name: _data.assortment },
      sellerName: _data.seller,
      weight: _data.weight,
      colour: _data.color,
      fluorescence: _data.fluorescence,
      tinge: _data.tinge,
      bid: {
        status: formatBidStatus(_data['sell? (y/n)']),
        confirmed: formatBidConfirmed(_data['bid confirmed (y/n)']),
        offerPrice: _data['offer price']
      },
      reservePpc: _data['reserve price']
    }))
  }

  function formatBidConfirmed(bidConfirmed) {
    const _bidConfirmed = String(bidConfirmed).toLowerCase()
    return _bidConfirmed === 'y' || _bidConfirmed === 't' || _bidConfirmed === '1' || _bidConfirmed === 'yes' || _bidConfirmed === 'true'
  }

  async function updateBidsData(fileBids) {
    const updateBids = []
    for (const fileBid of fileBids) {
      const localBid = localRoughList.find(rough => rough?.bid?.roughId === fileBid.id)?.bid
      if (localBid) {
        if (Math.abs(Number(localBid.offerPrice) - Number(fileBid.bid.offerPrice)) > 0.01) {
          throw new Error(`The bid's offer price doesn't match for Clara ID: ${fileBid.id}.`)
        }

        if (localBid.status !== fileBid.bid.status) {
          fileBid.id = localBid.id
          fileBid.localBidConfirmed = localBid.confirmed
          updateBids.push(fileBid)
        }
      }
    }

    const confirmedBids = updateBids.filter(updateBid => updateBid.localBidConfirmed)
    if (confirmedBids?.length) await bidActions.setBidsConfirmed(confirmedBids.map(bid => bid.id), false)

    return Promise.all(['BID_ACCEPTED', 'BID_REJECTED', 'BID_OFFERED'].reduce((promises, status) => {
      const bidIds = updateBids.filter(bid => bid.bid.status === status).map(bid => bid.id)
      if (bidIds.length) {
        promises.push(bidActions.setBidsStatus(bidIds, status))
      }

      return promises
    }, []))
  }

  function formatBidStatus(status) {
    const _status = String(status).toLowerCase().trim()
    return _status === 'y' || _status === 't' || _status === '1' || _status === 'yes' || _status === 'true'
      ? 'BID_ACCEPTED'
      : _status === 'n' || _status === 'f' || _status === '0' || _status === 'no' || _status === 'false'
        ? 'BID_REJECTED'
        : 'BID_OFFERED'
  }

  return (
    <Modal
      title='Upload Bids Responses'
      open={open}
      onClose={onClose}
    >
      <span className="subtext">{'The upload file must include a sheet: "Active Bids", with two required columns: "Clara ID" and "Sell? (Y/N)".'}</span>
      <FileUpload
        name='bidsFile'
        acceptTypes={['.xlsx']}
        onChange={onBidsFileSelect}
      />

    </Modal>
  )
}

UploadBidsResponsesModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onBidsUploaded: PropTypes.func,
  localRoughList: PropTypes.arrayOf(PropTypes.object)
}

UploadBidsResponsesModal.defaultProps = {
  open: false,
  localRoughList: []
}

export default UploadBidsResponsesModal
