import create from 'zustand'

const useMultiErrorModalStore = create((set, get) => ({
  isOpen: false,
  setIsOpen: (isOpen) => {
    set({ isOpen })
  },
  errors: [],
  setErrors: (errors) => {
    if (errors?.length) {
      set({ errors })
    }
  },
  resetErrorModalStore: () => {
    if (!get().isOpen) {
      set({ errors: [] })
    }
  }
}))

export {
  useMultiErrorModalStore
}
