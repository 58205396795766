import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox } from '@atoms'

function AcceptStonesModal({ keys, handleSubmit, handleCancel }) {
  const [overrideOrders, setOverrideOrders] = useState(false)

  const buildKey = (ks) => ks.length === 1 ? [<b key={ks[0]}>{ks[0]}</b>] : [...buildKey(ks.slice(0, 1)), ' and ', ...buildKey(ks.slice(1))]
  const key = useMemo(() => {
    if (!keys.length) return null
    return buildKey(keys)
  }, [keys])

  return (
    <>
      This may lead to changes in the layout of Order sections.
      <br/>
      <b>Your saved and submitted orders will also be affected, please review their settings accordingly.</b>
      <br/><br/>
      <Checkbox
        name="overrideOrders"
        label={<>Override all existing {key} stone settings for your orders.</>}
        className="organization-details__stones-checkbox-input"
        value={overrideOrders}
        onChange={(e) => setOverrideOrders(e.target.checked)}
      />
      <div key='row-buttons' className='modal__buttons'>
        <Button typeVariant='secondary' size='sm' onClick={handleCancel}>Cancel</Button>
        <Button onClick={() => handleSubmit({ overrideOrders })}>Accept</Button>
      </div>
    </>
  )
}
AcceptStonesModal.propTypes = {
  keys: PropTypes.arrayOf(PropTypes.string),
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired
}
AcceptStonesModal.defaultProps = {
  keys: []
}

export default AcceptStonesModal
