import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Collapsible } from '@molecules'
import AssignRoughStoneList from './assignRoughStoneList'

function AssignRoughStoneRow({ transaction, orgsList }) {
  const [isAssigned, setIsAssigned] = useState({ assigned: false, roughId: null, orgId: null })
  const [isOpened, setIsOpened] = useState(isAssigned?.assigned)

  function onRoughStoneAssigned({ roughId, buyerId }) {
    setIsAssigned({
      assigned: true,
      roughId,
      orgId: buyerId
    })
    setIsOpened(false)
  }

  function getTitle() {
    return isAssigned.assigned
      ? `Rough Stone ${isAssigned.roughId} reassigned to ${getOrg(isAssigned.orgId)}`
      : `Rough Stone ${transaction?.Rough?.id}`
  }

  function getOrg(orgId) {
    return orgsList.find(org => org.orgId === orgId).commonName || ''
  }

  return (
    <Collapsible
      title={getTitle()}
      type={isAssigned.assigned ? 'success' : ''}
      isOpened={isOpened}
      onClick={() => setIsOpened(!isOpened)}
    >
      <div className='assign-rough-stone-row__header'>
        <span><b>Seller:</b> {getOrg(transaction?.sellerId)}</span>
        <span><b>Seller Price:</b> ${transaction?.sellerPrice}</span>
        <span><b>Last Purchase Price:</b> ${transaction?.buyerPrice}</span>
      </div>
      <AssignRoughStoneList
        roughStone={transaction?.Rough}
        buyerId={transaction?.buyerId}
        sellerId={transaction?.sellerId}
        saleId={transaction?.saleId}
        sellerPrice={transaction?.sellerPrice}
        onRoughStoneAssigned={onRoughStoneAssigned}
        isAssigned={isAssigned.assigned}
      />
    </Collapsible>
  )
}

AssignRoughStoneRow.propTypes = {
  transaction: PropTypes.func,
  orgsList: PropTypes.arrayOf(PropTypes.object)
}

AssignRoughStoneRow.defaultProps = {
  orgsList: []
}
export default AssignRoughStoneRow
