import React, { useEffect, useState } from 'react'
import { useMultiStepsStore } from '@stores'
import { useHistory } from 'react-router-dom'
import { useToast } from '@hooks'
import { DisplayMessage } from '@molecules'
import clone from 'just-clone'
import { objectUtils, validationUtils } from '@utils'

// TODO: use useRef to create a mutable but
// render persistent validationError (or displayMessage)
// object instead of state variable
function useMultiSteps(props = {}) {
  const {
    isEdit,
    onSubmit,
    hasChanged,
    setHasChanged,
    setConfirmPageExit,
    formLocalState,
    setCurrentStep,
    validationError,
    validationSchemaError,
    setFormLocalState,
    setValidationError,
    setValidationSchemaError,
    resetValidation,
    removeValidationSchemaError
  } = useMultiStepsStore(state => state)

  const {
    showSuccessToast,
    showErrorToast,
    showWarningToast
  } = useToast()

  useEffect(() => {
    setCurrentStep(props.order)
  }, [props.order])

  // resets values to default when
  // component consuming this hooks
  // umount
  useEffect(() => {
    return () => {
      resetValidation()
    }
  }, [])

  useEffect(() => {
    setHasChanged(true)
  }, [props.state])

  useEffect(() => {
    // if formData was passed in (edit)
    // it populates the form state with the
    // data passed in, based on the stateSchema
    if (props.formData && props.stateSchema) updateMultipleState({ ...objectUtils.pick(props.formData, props.stateSchema), ...props.state })
  }, [props.formData, props.stateSchema])

  function validateAndGoNextStep(requiredFields = [], customValidationFunction) {
    const { validationError: valErr, validationSchemaError: valSchemaError } = validateForm(requiredFields, customValidationFunction, props?.validationSchema)
    const changed = hasChanged
    setHasChanged(false)
    if (valErr?.error) return
    else if (valSchemaError?.getError()) return showErrorToast('Please clear the highlighted errors before saving.')
    else if (valSchemaError?.getWarning() && changed) return showWarningToast('Please clear the highlighted warnings or proceed to saving.')
    props.next()
  }

  function validateAndSubmit({
    requiredFields = [],
    data,
    redirectPath = '',
    successMessage = '',
    customValidationFunction
  }) {
    const { validationError: valErr, validationSchemaError: valSchemaError } = validateForm(requiredFields, customValidationFunction, props?.validationSchema)
    const changed = hasChanged
    setHasChanged(false)
    if (valErr?.error) return
    else if (valSchemaError?.getError()) return showErrorToast(props?.validationToast?.error || valSchemaError?.defaultErrorToastMessage)
    else if (valSchemaError?.getWarning() && changed) return showWarningToast(props?.validationToast?.warning || valSchemaError?.defaultWarningToastMessage)

    if (onSubmit) {
      submitForm(data, onSubmit, redirectPath, successMessage)
    }
  }

  function validateForm(requiredFields = [], customValidation = null, schema) {
    const res = {}
    const newValidationError = clone(validationError)
    newValidationError.error = false
    newValidationError.blankFields = []

    requiredFields.forEach(field => {
      if (!props.getState(field.name)) {
        newValidationError.error = true
        newValidationError.blankFields.push(field.label)
      }
    })

    if (customValidation != null && typeof customValidation === 'function') {
      let validationResult = customValidation(props.state)
      if (validationResult && !Array.isArray(validationResult)) validationResult = [validationResult]
      if (validationResult && validationResult.length) {
        newValidationError.error = true
        newValidationError.customErrors = validationResult
      }
    }
    res.validationError = newValidationError
    setValidationError(newValidationError)

    // schema validation
    if (schema) {
      const newValidationSchemaError = validationUtils.validateSchemaSync(schema, props.state, { form: props.state, ...props?.schemaContext })
      setValidationSchemaError(newValidationSchemaError)
      res.validationSchemaError = newValidationSchemaError
    }
    return res
  }

  const history = useHistory()
  function submitForm(data, fn, redirectPath, successMessage) {
    if (!data) return
    if (!fn) return
    // TODO: handle success and error messages
    fn(data)
      .then(() => {
        clearState()
        setHasChanged(false)
        setConfirmPageExit(false)
        if (successMessage) { showSuccessToast(successMessage) }

        if (redirectPath) {
          history.push(redirectPath)
        } else {
          props.jump(1)
        }
      })
      .catch(console.error)
  }

  function clearState() {
    Object.keys(props.state).forEach(item => {
      props.state[item] = ''
    })
  }

  function getFormLocalState(stateName) {
    if (!stateName) return formLocalState

    return formLocalState[stateName]
  }

  function renderValidationError() {
    return (
      validationError.error && (
        <DisplayMessage
          type={'error'}
          message={
            <>
              {validationError.blankFields && validationError.blankFields.length
                ? <>
                  <div>Please enter all required fields:</div>
                  {validationError.blankFields.map((blankField, i) => {
                    return (
                      <span key={i}>
                        <strong>- {blankField}</strong>
                      </span>
                    )
                  })}
                </>
                : null
              }
              {validationError.customErrors && validationError.customErrors.length
                ? validationError.customErrors.map((msg, i) => (
                  <span key={i}>
                    <strong>- {msg}</strong>
                  </span>
                ))
                : null
              }
            </>
          }
        />
      )
    )
  }

  const [pendingChanges, setPendingChanges] = useState([])
  function updateMultipleState(changes) {
    if (typeof props.setState !== 'function') throw new Error('updateMultipleState requires react-step-builder props. Try running it from a step.')
    setPendingChanges(currPendingChanges => currPendingChanges.concat(Object.entries(changes)))
  }
  useEffect(() => {
    if (pendingChanges && pendingChanges.length) {
      const change = pendingChanges[0]
      props.setState(change[0], change[1])
      setPendingChanges(pendingChanges.slice(1))
    }
  }, [pendingChanges])

  return {
    isEdit,
    validateForm,
    validationError,
    validationSchemaError,
    validateAndSubmit,
    setFormLocalState,
    getFormLocalState,
    setValidationError,
    setValidationSchemaError,
    removeValidationSchemaError,
    validateAndGoNextStep,
    renderValidationError,
    updateMultipleState
  }
}

export {
  useMultiSteps
}
