import React, { useState, useEffect, useMemo } from 'react'
import { Table } from '@organisms'
import { NavLink, useHistory } from 'react-router-dom'
import { logisticsActions } from '@actions'
import { useGlobalsStore, useModalStore } from '@stores'
import { useProgressList, useToast } from '@hooks'
import EditStatus from './modal/editStatus'

function Shipments() {
  const [shipmentList, setShipmentList] = useState([])
  const [activeTab, setActiveTab] = useState({ label: 'Active', params: 'ACTIVE' })
  const history = useHistory()
  const { showSuccessToast, showInfoToast } = useToast()
  const {
    orgsMap: { all: orgsMap },
    getOrgsList,
    getShipmentTypes,
    shipmentTypesMap,
    getShipmentStatuses,
    shipmentStatusesMap
  } = useGlobalsStore(store => store)
  const { setModal } = useModalStore(state => state)
  const {
    getSucceededTasks,
    getProgressList,
    createProgressList
  } = useProgressList()

  useEffect(() => {
    refreshShipmentList()
  }, [])

  useEffect(() => {
    getShipmentTypes()
    getShipmentStatuses()
  }, [])

  useEffect(() => {
    getOrgsList()
  }, [])

  const columns = useMemo(() => (
    [
      {
        Header: 'Shipment ID',
        accessor: 'id',
        dataType: 'string',
        Cell: cellInfo => {
          return <NavLink
            className='link'
            to={`/shipments/${cellInfo.row.original.id}`}
            id={cellInfo.row.original.id}>
            {cellInfo.row.values.id}
          </NavLink>
        }
      },
      {
        Header: 'Rough Stones',
        accessor: row => row?.roughIds?.length || 0,
        dataType: 'number',
        filterType: 'numberRange'
      },
      {
        Header: 'Type',
        id: 'type',
        accessor: row => shipmentTypesMap?.[row.type],
        dataType: 'string',
        filterType: 'checkbox',
        enums: Object.values(shipmentTypesMap)
      },
      {
        Header: 'From Org',
        id: 'fromOrgId',
        accessor: row => orgsMap?.[row.fromOrgId],
        dataType: 'string'
      },
      {
        Header: 'To Org',
        id: 'toOrgId',
        accessor: row => orgsMap?.[row.toOrgId],
        dataType: 'string'
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: row => shipmentStatusesMap?.[row.status],
        dataType: 'string',
        filterType: 'checkbox',
        enums: Object.values(shipmentStatusesMap)
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        dataType: 'date',
        filterType: 'date'
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
        dataType: 'date',
        filterType: 'date'
      }
    ]), [shipmentList, orgsMap, shipmentTypesMap, shipmentStatusesMap])

  const topBarActions = [
    {
      componentName: 'dropdown',
      enableOnSelect: true,
      options: [
        {
          label: 'Archive',
          isDisabled: rows => rows.some(row => row.original.condition !== 'ACTIVE'),
          callback: (selectedRows) => setModal({
            id: 'shipmentListArchive',
            title: 'Archive Shipments',
            message: `Archive ${selectedRows.length} shipment${selectedRows.length > 1 ? 's' : ''}?`,
            onSubmit: () => handleSetCondition(selectedRows.map(row => row.id), 'ARCHIVED', 'archived'),
            closeOnFail: false
          }),
          value: 'archive'
        },
        {
          label: 'Unarchive',
          isDisabled: rows => rows.some(row => row.original.condition !== 'ARCHIVED'),
          callback: (selectedRows) => setModal({
            id: 'shipmentListUnarchive',
            title: 'Archive Shipments',
            message: `Unarchive ${selectedRows.length} shipment${selectedRows.length > 1 ? 's' : ''}?`,
            onSubmit: () => handleSetCondition(selectedRows.map(row => row.id), 'ACTIVE', 'unarchived'),
            closeOnFail: false
          }),
          value: 'unarchive'
        },
        {
          label: 'Remove',
          callback: (selectedRows) => setModal({
            id: 'shipmentListRemove',
            title: 'Remove Shipments',
            message: `Remove ${selectedRows.length} shipment${selectedRows.length > 1 ? 's' : ''}?`,
            onSubmit: () => handleSetCondition(selectedRows.map(row => row.id), 'DELETED', 'removed'),
            closeOnFail: false
          }),
          value: 'remove'
        },
        {
          label: 'Change Status',
          isDisabled: rows => rows.some(row => row.original.condition !== 'ACTIVE'),
          callback: (selectedRows) => {
            const progressList = createProgressList({
              name: 'shipmentListStatus',
              title: 'Change Shipment Status',
              concurrency: 1,
              onAllTasksComplete: async (list) => {
                const successIds = getSucceededTasks('shipmentListStatus').filter(taskId => !(list.tasks[taskId]?.noChange))
                if (successIds.length) {
                  await Promise.all([
                    refreshShipmentList()
                  ])
                }
                if (!list.count().failed) {
                  if (!successIds.length) showInfoToast('No changes were recorded.')
                  else showSuccessToast(`${successIds.length} shipment${successIds.length > 1 ? 's were' : ' was'} successfully updated.`)
                }
              }
            })
            setModal({
              id: 'shipmentListStatus',
              title: 'Change Status',
              type: 'form',
              customMessageRenderer: (messageProps) => <EditStatus selectedRows={selectedRows} {...messageProps} />,
              customButtonsRenderer: () => null,
              onSubmit: (res) => {
                selectedRows
                .forEach(row => {
                  const task = progressList.newTask()
                  task.noChange = true
                  task.id = row.id
                  task.name = `Shipment #${row.id}`
                  task.callback = async () => {
                    if (res[0]?.status !== row.status) {
                      const list = getProgressList('shipmentListStatus')
                      const currTask = list.tasks[row.id]
                      currTask.noChange = false
                      await logisticsActions.editShipment(row.id, res[0], { loadingSpinner: false })
                    }
                  }
                  progressList.addTask(task)
                })
                progressList.start()
              },
              closeOnFail: false
            })
          },
          value: 'changeStatus'
        }
      ]
    },
    {
      label: 'Create Shipment',
      callback: () => history.push('/shipments/create')
    }
  ]

  const tableTabs = [
    {
      label: 'Active',
      params: 'ACTIVE'
    },
    {
      label: 'Archived',
      params: 'ARCHIVED'
    }
  ]

  const rowActions = [
    {
      actionName: 'archive',
      itemType: 'shipment',
      callback: (row) => handleSetCondition([row.id], 'ARCHIVED', 'archived'),
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ACTIVE'
    },
    {
      actionName: 'unarchive',
      itemType: 'shipment',
      callback: (row) => handleSetCondition([row.id], 'ACTIVE', 'unarchived'),
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ARCHIVED'
    },
    {
      actionName: 'remove',
      itemType: 'shipment',
      callback: (row) => handleSetCondition([row.id], 'DELETED', 'removed'),
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition !== 'DELETED'
    }
  ]

  function handleSetCondition(ids, condition, actionText) {
    logisticsActions.setShipmentCondition({ shipmentIds: ids, condition })
    .then(() => showSuccessToast(`Shipment${ids.length > 1 ? 's have' : ' has'} been ${actionText}.`))
    .finally(() => refreshShipmentList(activeTab.params))
  }

  function refreshShipmentList(condition = 'ACTIVE') {
    logisticsActions.getShipments({ condition })
    .then(response => setShipmentList(response.data.data.map(s => ({ ...s, roughCount: s.roughIds?.length ?? 0 }))))
  }

  return (
    <>
      <Table
        title="Shipments"
        data={shipmentList}
        columns={columns}
        tableTabs={tableTabs}
        onTabChange={setActiveTab}
        rowActions={rowActions}
        getDataCallback={refreshShipmentList}
        topBarActions={topBarActions}
      />
    </>
  )
}

export default Shipments
