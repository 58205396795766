import React, { useMemo } from 'react'
import { Icon, Button } from '@atoms'
import { InfoTip } from '@molecules'
import PropTypes from 'prop-types'
import ProgressListTask from './progressListTask'

function ProgressList({ list }) {
  const {
    tasks,
    isOpen,
    removeTask,
    title,
    retryTask,
    setIsOpen,
    isMinimized,
    setIsMinimized,
    reset,
    removeAllTasks,
    isAllTasksComplete
  } = list

  const { succeeded, total } = useMemo(() => list?.count(), [list])
  const _tasks = useMemo(() => {
    return Object.keys(tasks)
    .filter(task => !tasks[task].status.isRemoved)
    .sort(task => tasks[task].status.isFailed ? -1 : 1)
    .sort(task => tasks[task].status.isInProgress ? -1 : 1)
  }, [list])
  if (!isOpen || !total) return null

  function getItemConfig(item) {
    if (item.status.isFailed) {
      return {
        status: 'Failed!',
        variant: '--failed',
        iconName: 'error'
      }
    }
    if (item.status.isSucceeded) {
      return {
        status: 'Succeeded.',
        variant: '--succeeded',
        iconName: 'check'
      }
    }
    if (item.status.isInProgress) {
      return {
        status: 'In Progress...',
        variant: '--in-progress',
        iconName: 'progressClock'
      }
    }
    return {
      status: 'Waiting...',
      variant: '',
      iconName: 'clock'
    }
  }

  function handleOnClose(e) {
    e.stopPropagation()
    setIsOpen(false)
    reset()
  }

  return (
    <div className='progress-list'>
      <div onClick={(e) => {
        e.stopPropagation()
        setIsMinimized(!isMinimized)
      }}
      className="progress-list__header"
      >
        <div className='flex-row'>
          <div className='progress-list__title' >
            {title}
          </div>
          <div className='progress-list__counter'>({succeeded}/{total})</div>
        </div>
        <div className='progress-list__header-icons'>
          <Icon
            name={isMinimized ? 'chevronUp' : 'chevronDown'}
            size='lg'
            onClick={(e) => {
              e.stopPropagation()
              setIsMinimized(!isMinimized)
            }}
          />
          {
            isAllTasksComplete()
              ? (
                <Icon
                  name='close'
                  size='lg'
                  onClick={handleOnClose}
                />
              ) : null
          }
        </div>
      </div>
      {
        !isMinimized
          ? (
            <div className="progress-list__container">
              <div className="right flex-row">
                <Button
                  typeVariant='action'
                  onClick={() => removeAllTasks()}
                  size='sm'
                >
                  Clear All
                </Button>
                <InfoTip
                  name={'clearAll'}
                >
                  Clear all tasks that are not in progress.
                </InfoTip>
              </div>
              {
                _tasks.map((taskId, index) => {
                  return (
                    <ProgressListTask
                      key={index}
                      task={tasks[taskId]}
                      tasks={tasks}
                      itemConfig={getItemConfig(tasks[taskId])}
                      index={index}
                      removeTask={() => removeTask(tasks[taskId])}
                      retryTask={() => retryTask(tasks[taskId])}
                    />
                  )
                })
              }
            </div>
          ) : null
      }
    </div>
  )
}

ProgressList.propTypes = {
  list: PropTypes.object
}

export default ProgressList
