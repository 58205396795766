import React, { useEffect, useState } from 'react'
import { cutGradeActions, organizationActions } from '@actions'
import { useToast } from '@hooks'
import { useAuthStore, useGlobalsStore } from '@stores'
import { fileUtils } from '@utils'
import { Button } from '@atoms'
import { Dropdown } from '@molecules'
import { FileUpload, Form } from '@organisms'
import { useHistory } from 'react-router-dom'

function UploadCutGrades(props) {
  const { orgId: userOrgId, hasAdmin } = useAuthStore(store => store)
  const isAdmin = hasAdmin(organizationActions.getOrganizationList)

  const [xmlFile, setXmlFile] = useState(null)
  const [fileName, setFileName] = useState(undefined)
  const [buyerId, setBuyerId] = useState(userOrgId)

  const {
    orgsList: { [JSON.stringify({ condition: 'ACTIVE' })]: orgsList },
    getOrgsList
  } = useGlobalsStore()
  useEffect(() => {
    getOrgsList({ condition: 'ACTIVE' })
  }, [])

  async function receiveFile(file) {
    if (!file) {
      setXmlFile(null)
      setFileName(undefined)
    } else {
      const fileString = await fileUtils.promiseReadFile(file)
      setXmlFile(fileString)
      setFileName(file.name)
    }
  }

  const { showSuccessToast, showErrorToast } = useToast()
  const history = useHistory()

  function handleSubmit() {
    cutGradeActions.uploadCutGrades({
      file: xmlFile,
      buyerId
    })
    .then(response => {
      if (response.status === 207) {
        response.data.data.forEach(s => showSuccessToast(s))
        response.data.error.forEach(e => showErrorToast(e))
      } else if (response.status === 200) {
        showSuccessToast(response.data.data)
      }
      history.push('/buyer-settings')
    })
  }

  return (
    <div className="center">
      <Form>
        {isAdmin
        && <Dropdown
          name='buyerId'
          label='Owner'
          value={buyerId}
          options={orgsList?.map(org => ({ value: org.orgId, label: org.commonName }))}
          onChange={e => setBuyerId(e?.currentTarget?.value)}
        />
        }
        <FileUpload
          name='file'
          label='Upload AllInstitutes.xml'
          fileName={fileName}
          onChange={receiveFile}
          acceptTypes={['.xml']}
        />
        <Button
          typeVariant="action"
          onClick={() => history.push('/buyer-settings')}
          size='sm'
        >
        Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!xmlFile || !buyerId}
          size='sm'
        >
        Create
        </Button>
      </Form>
    </div>
  )
}

export default UploadCutGrades
