import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useOrderStore, useGlobalsStore } from '@stores'
import { planningActions, transactionActions } from '@actions'
import { numberUtils } from '@utils'
import { Table } from '@organisms'
import { useToast } from '@hooks'
import { NavLink } from 'react-router-dom'

function AssignTransactionStone({
  roughStone,
  buyerId,
  sellerId,
  sellerPrice,
  saleId,
  onRoughStoneAssigned,
  isAssigned
}) {
  const [spsRequests, setSpsRequests] = useState(null)
  const [columns, setColumns] = useState([])

  const ordersParams = {
    columns: '[id, name, buyerId, OrderLines]'
  }

  const { showSuccessToast } = useToast()
  const {
    ordersList: { [ordersParams ? JSON.stringify(ordersParams) : 'all']: ordersList },
    getOrdersList
  } = useOrderStore(store => store)

  const {
    orgsList
  } = useGlobalsStore(state => state)

  const orders = useMemo(() => ordersList?.data, [ordersList])

  const groupedOptions = useMemo(() => {
    if (!spsRequests || !orders) return []
    const groups = {}
    for (const req of spsRequests) {
      for (const part of req.PolishedStones) {
        const key = `${req.id}.${part.optionNumber}`
        if (!groups[key]) {
          groups[key] = {
            key,
            spsRequestId: req.id,
            optionNumber: part.optionNumber,
            buyerId: req.buyerId,
            buyer: orgsList?.all?.find(o => o.orgId === req.buyerId)?.commonName,
            buyerPrice: 0,
            parts: []
          }
        }
        const group = groups[key]
        const order = orders?.find(o => o.buyerId === req.buyerId && o.OrderLines?.some(l => l.id === part.orderLineId))
        const orderLine = order.OrderLines?.find(l => l.id === part.orderLineId)
        group.parts.push({
          id: part.id,
          partNumber: part.partNumber,
          polishedWeight: part.polishedWeight,
          buyerPrice: part.buyerPrice,
          orderId: order?.id,
          order: order?.name,
          orderLineId: part.orderLineId,
          quantity: orderLine?.quantity,
          quantityFilled: orderLine?.quantityFilled,
          quantityType: orderLine?.quantityType
        })
        group.buyerPrice += Number(part.buyerPrice)
      }
    }
    return Object.values(groups).sort((a, b) => b.buyerPrice - a.buyerPrice)
  }, [spsRequests, orders])

  const maxOptsPerBuyer = 3
  const filteredOptions = useMemo(() => {
    const buyerOpts = {}
    const filtered = []
    for (const opt of groupedOptions) {
      if (!buyerOpts[opt.buyerId]) buyerOpts[opt.buyerId] = 0
      if (buyerOpts[opt.buyerId] < maxOptsPerBuyer) {
        filtered.push(opt)
        buyerOpts[opt.buyerId]++
      }
    }
    for (const opt of filtered) {
      opt.parts.sort((a, b) => b.buyerPrice - a.buyerPrice)
    }
    return filtered
  }, [groupedOptions])

  useEffect(() => {
    getOrdersList(ordersParams)
  }, [])

  useEffect(() => {
    if (roughStone?.SpsRequest?.buyerId) {
      getOrdersList(ordersParams)
    }
  }, [roughStone?.SpsRequest])

  useEffect(() => {
    planningActions.getPlannedStones({ roughId: roughStone?.id, saleId, status: 'COMPLETE', condition: 'ACTIVE', columns: '[PolishedStones]' })
    .then(res => setSpsRequests(res.data.data.filter(request => request.buyerId !== buyerId)))
  }, [])

  useEffect(() => {
    setColumns([
      {
        Header: 'Buyer',
        accessor: 'buyer'
      },
      {
        Header: 'Total Price',
        accessor: 'buyerPrice',
        dataType: 'currency',
        id: 'buyerPrice',
        decimalScale: 2,
        fixedDecimalScale: true
      },
      {
        Header: 'Orders',
        accessor: row => row.parts?.map((part, index) => (
          <div key={part.id} className='flex-row order-column'>
            {part.orderId
            && <NavLink
              className="link"
              to={`/orders/${part.orderId}`}
              id={part.id}
            >
              <div className='order-column__info'>
                {part.orderId} (<div className="order-column__info-name">{part.order}</div>)
              </div>
            </NavLink>
            }
            <span className={part.quantityFilled === part.quantity ? 'assign-rough-stone-list__quantity-filled' : ''}>{`(${part.quantityFilled}/${part.quantityType === 'TOPS' ? '\u221e' : part.quantity})`}</span>
          </div>
        )),
        dataType: 'string',
        id: 'orderNames'
      },
      {
        Header: 'Price',
        accessor: row => row.parts?.map(part => <p key={part.id}>{numberUtils.numFmt(part.buyerPrice, 2, { prefix: '$', thousandSeperator: true })}</p>),
        sortType: (row1, row2) => row1?.original?.reduce((accum, { buyerPrice }) => accum + Number(buyerPrice), 0) - row2?.original?.reduce((accum, { buyerPrice }) => accum + Number(buyerPrice), 0),
        id: 'partPrice'
      },
      {
        Header: 'Weight',
        accessor: row => row.parts?.map(part => <p key={part.id}>{part.polishedWeight}</p>),
        sortType: (row1, row2) => row1?.original?.reduce((accum, { polishedWeight }) => accum + Number(polishedWeight), 0) - row2?.original?.reduce((accum, { polishedWeight }) => accum + Number(polishedWeight), 0),
        id: 'weight'
      }
    ])
  }, [filteredOptions, orders])

  function assignRoughStone(option) {
    if (option) {
      const polishedStoneIds = option.parts.reduce((arr, part) => ([...arr, part.id]), [])
      transactionActions.createTransaction({
        roughId: roughStone.id,
        buyerId: option.buyerId,
        sellerId,
        saleId,
        sellerPrice,
        polishedStoneIds
      })
      .then(() => {
        showSuccessToast('Rough stone reassigned.')
        onRoughStoneAssigned({
          roughId: roughStone.id,
          buyerId: option.buyerId
        })
      })
    }
  }

  const rowActions = [
    {
      actionName: 'assign',
      callback: assignRoughStone,
      shouldConfirm: ({ row }) => `Reassign to ${row?.buyer} for $${row?.buyerPrice}?`,
      shouldDisplay: () => !isAssigned
    }
  ]

  return (
    <Table
      title={`Reassign Stone ${roughStone?.id}`}
      columns={columns}
      data={filteredOptions}
      rowActions={rowActions}
      isMultiSelect={false}
      initialSort={[{ id: 'buyerPrice', desc: true }]}
    />
  )
}

AssignTransactionStone.propTypes = {
  roughStone: PropTypes.object,
  buyerId: PropTypes.string,
  sellerId: PropTypes.string,
  sellerPrice: PropTypes.string,
  saleId: PropTypes.string,
  buyerPrice: PropTypes.string,
  isAssigned: PropTypes.bool,
  onRoughStoneAssigned: PropTypes.func
}

AssignTransactionStone.defaultProps = {
  isAssigned: false
}

export default AssignTransactionStone
