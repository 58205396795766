import React, { useState } from 'react'
import { Button } from '@atoms'
import { TextInput, DisplayMessage } from '@molecules'
import { useSigninStore } from '@stores'
import { useAuth, useToast } from '@hooks'
import { Form } from '@organisms'

// TODO: add error message
function ChangePasswordForm() {
  const [newPassword, setNewPassword] = useState('')
  const [repeatNewPassword, setRepeatNewPassword] = useState('')
  const [changePasswordError, setChangePasswordError] = useState(null)
  const { changePassword, signIn } = useAuth()
  const { showSuccessToast } = useToast()
  const { setDisplayMode } = useSigninStore(state => state)

  const handleOnSubmit = function () {
    setChangePasswordError(null)
    changePassword(newPassword, repeatNewPassword)
    .then(user => {
      setDisplayMode('signin')
      showSuccessToast('Password was successfully updated.')
      signIn(user.username, newPassword)
    })
    .catch(err => setChangePasswordError(err))
  }

  return (
    <div className="signin__form">
      <Form
        hasOutline={false}
        onSubmit={handleOnSubmit}
      >
        <TextInput
          type="password"
          name="newPassword"
          label="New password"
          labelStyle={{ color: 'white' }}
          onChange={(e) => setNewPassword(e.target.value)}
          value={newPassword}
        />
        <TextInput
          type="password"
          name="repeatNewPassword"
          label="Repeat new password"
          labelStyle={{ color: 'white' }}
          onChange={(e) => setRepeatNewPassword(e.target.value)}
          value={repeatNewPassword}
        />
        <div className="signin__form-buttons">
          <Button
            size='sm'
            typeVariant='action'
            onClick={() => setDisplayMode('signin')}
          >
            <span className='signin__form-action-button-text'>
              Cancel
            </span>
          </Button>
          <Button
            size='sm'
            type='submit'
            typeVariant='secondary'
          >
            Save
          </Button>
        </div>
        {
          changePasswordError
            ? <DisplayMessage
              type={'error'}
              message={
                <span>{changePasswordError.message}</span>
              }
            />
            : null
        }
      </Form>
    </div>
  )
}

export default ChangePasswordForm
