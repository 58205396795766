import axios from 'axios'
const BASE_URL = process.env.REACT_APP_API_BASE_URL

const getAssortmentList = function (params) {
  return axios.get(`${BASE_URL}/seller/assortments`, { params })
}
getAssortmentList.endpoint = 'GET|/seller/assortments'

const getAssortmentById = function (id, otherParams) {
  return axios.get(`${BASE_URL}/seller/assortments`, { params: { id, ...otherParams } })
}
getAssortmentById.endpoint = 'GET|/seller/assortments'

const createAssortment = function (data) {
  return axios.post(`${BASE_URL}/seller/assortments`, data)
}
createAssortment.endpoint = 'POST|/seller/assortments'

const getAssortmentCategories = function (sellerId) {
  return axios.get(`${BASE_URL}/seller/assortmentcategories`, { params: (sellerId ? { sellerId } : {}) })
}
getAssortmentCategories.endpoint = 'GET|/seller/assortmentcategories'

const setAssortmentCondition = function (assortmentIds, condition) {
  return axios.put(`${BASE_URL}/seller/assortments/condition`, { assortmentIds, condition })
}
setAssortmentCondition.endpoint = 'PUT|/seller/assortments/condition'

const editAssortment = function({ assortmentId, ...data }) {
  return axios.patch(`${BASE_URL}/seller/assortments/${assortmentId}`,
    data
  )
}
editAssortment.endpoint = 'PATCH|/seller/assortments/:assortmentId:'

export const assortmentActions = {
  createAssortment,
  getAssortmentById,
  getAssortmentList,
  setAssortmentCondition,
  getAssortmentCategories,
  editAssortment
}
