import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Fieldset } from '@organisms'
import { Collapsible, TextInput } from '@molecules'
import { Icon, Button } from '@atoms'
import { useGlobalsStore } from '@stores'
import ReportAttachment from './reportAttachment'

function NotificationAttachmentList({
  attachments,
  disabled,
  onAttachmentChange,
  isDetailsPage,
  ...props
}) {
  const {
    reportTypes,
    getReportTypes
  } = useGlobalsStore(store => store)

  const localAttachments = useMemo(() => {
    if (props.value) {
      return normalizeDetailsPageProps(props.value)
    }
    return attachments.map(att => ({ ...att }))
  }, [props.value, attachments])

  const newAttachmentObj = {
    reportType: '',
    parameters: [],
    required: false,
    fileName: ''
  }

  useEffect(() => {
    getReportTypes()
  }, [])

  function normalizeDetailsPageProps(attachments) {
    return attachments?.map(attachment => normalizeAttachment(attachment)) || []
  }

  function normalizeAttachment(attachment) {
    // Report Attachments
    if (attachment?.report) {
      return {
        type: 'report',
        fileName: attachment.filename,
        required: attachment.required,
        reportType: attachment.report.reportType,
        parameters: normalizeParams(attachment.report)
      }
    }

    // base64 Files Attachments
    if (attachment?.encoding) {
      return {
        ...attachment,
        type: 'base64File',
        fileName: attachment.filename
      }
    }

    // s3 Files Attachments
    if (attachment?.s3File) {
      return {
        type: 's3File',
        fileName: attachment.filename,
        bucket: attachment.s3File.bucket,
        key: attachment.s3File.key
      }
    }
  }

  function normalizeParams(report) {
    const params = []
    if (report.orgId) {
      params.push('orgId|[ORG_ID]')
    }

    if (report.saleId) {
      params.push('saleId|[SALE_ID]')
    }
    return params
  }

  function handleOnChange(index, e) {
    const target = e.currentTarget ?? e.target
    const targetValue = target.type === 'checkbox' ? target.checked : target.value
    if (isDetailsPage) {
      onDetailsPageChange(index, target.name, targetValue)
    } else {
      localAttachments[index][target.name] = targetValue
      onAttachmentChange([...localAttachments])
    }
  }

  function onDetailsPageChange(index, name, value) {
    const updatedAttachments = [...localAttachments]
    if (name === 'parameters') {
      updatedAttachments[index].parameters = value ? [...value] : []
    } else {
      updatedAttachments[index][name] = value
    }

    updateDetailsPage(updatedAttachments)
  }

  function updateDetailsPage(attachments) {
    props.onChange({
      target: {
        name: props.name,
        value: transformToDetailsPageProps(attachments)
      }
    })
  }

  function transformToDetailsPageProps(values) {
    return values.map(v => ({
      filename: v.fileName,
      required: !!v.required,
      report: {
        reportType: v.reportType,
        ...v.parameters.reduce((obj, param) => ({ ...obj, [param.split('|')[0]]: param.split('|')[1] }), {})
      }
    }))
  }

  function handleOnAddNew() {
    localAttachments.push(newAttachmentObj)
    if (isDetailsPage) {
      updateDetailsPage(localAttachments)
    } else {
      onAttachmentChange([...localAttachments])
    }
  }

  function handleOnRemove(index) {
    localAttachments.splice(index, 1)
    if (isDetailsPage) {
      updateDetailsPage(localAttachments)
    } else {
      onAttachmentChange([...localAttachments])
    }
  }

  return (
    <Fieldset
      legend='Attachments'
      required={false}
    >
      {
        !localAttachments.length
          ? (
            <div className='flex-row'>
              <Icon
                name='plus'
                size='sm'
                disabled={disabled}
                onClick={() => handleOnAddNew()}
              />
              <Button
                size='sm'
                typeVariant='action'
                disabled={disabled}
                onClick={() => handleOnAddNew()}
              >
                Add Attachment
              </Button>
            </div>
          ) : null
      }
      {
        localAttachments.map((attachment, index) => {
          return (
            <div
              key={index}
              className='notification-attachment-list'
            >
              <Collapsible
                title={`Attachment #${index + 1}`}
                isOpened={index === localAttachments.length - 1}
              >
                {
                  attachment.type === 'report'
                    ? (
                      <ReportAttachment
                        disabled={disabled}
                        attachment={attachment}
                        handleOnChange={handleOnChange}
                        index={index}
                        key={`report-att-${index}`}
                        reportTypes={reportTypes}
                      />
                    )
                    : (
                      <TextInput
                        value={attachment?.fileName}
                        label='File Name'
                        name="fileName"
                        disabled={true}
                      />
                    )
                }
              </Collapsible>
              <div className="notification-attachment-list__icons">
                <Icon
                  name='plus'
                  disabled={disabled}
                  onClick={() => handleOnAddNew()}
                />
                <Icon
                  name='remove'
                  disabled={disabled}
                  onClick={() => handleOnRemove(index)}
                />
              </div>
            </div>
          )
        })
      }
    </Fieldset>
  )
}

NotificationAttachmentList.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  isDetailsPage: PropTypes.bool,
  onAttachmentChange: PropTypes.func
}

NotificationAttachmentList.defaultProps = {
  attachments: [],
  disabled: false,
  isDetailsPage: false
}

export default NotificationAttachmentList
