import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import CalendarEvent from './calendarEvent'
import moment from 'moment'
import { textUtils } from '@utils'

function CalendarEventList({
  eventList
}) {
  const sortedEventList = useMemo(() => (eventList || []).slice().sort((x, y) => moment(x.startTime) - moment(y.startTime)), [eventList])
  const nearestEvent = useMemo(() => findNearestEvent(sortedEventList), [sortedEventList])

  useEffect(() => {
    if (nearestEvent) {
      // gets the Event element that has the nearest start time
      // from the current date
      const element = document.getElementById(nearestEvent.id)
      if (element) {
        // scrolls list to the event element
        element.parentNode.scrollTop = element.offsetTop - element.parentNode.offsetTop
      }
    }
  }, [nearestEvent])

  function findNearestEvent(list) {
    const currentDate = moment(new Date())
    if (!list.length) return null
    // Too avoid looping through entire list check initial failcase
    if (moment(list[0].startTime).isSameOrAfter(currentDate, 'day')) return list[0]
    if (moment(list[list.length - 1].startTime).isSameOrBefore(currentDate, 'day')) return list[list.length - 1]

    // Since list is sorted we can traverse in reverse order until we find our nearest event
    for (const idx of list.keys()) {
      if (moment(list[list.length - 1 - idx].startTime).isBefore(currentDate, 'day')) return list[list.length - idx]
    }
  }

  function sanitizeEvent(event) {
    if (event) {
      return {
        id: event?.id,
        day: textUtils.formatDate(event.startTime, 'D'),
        name: event?.name,
        visible: event?.visible,
        startTime: textUtils.formatDate(event?.startTime, true),
        badge: getBadge(event?.startTime)
      }
    }
  }

  function getBadge(date) {
    if (textUtils.formatDate(date) < textUtils.formatDate(new Date())) {
      return 'completed'
    }
  }

  return (
    <div className='calendar__event-list'>
      {
        sortedEventList.map((event, index) => {
          return (
            <CalendarEvent
              key={index}
              event={sanitizeEvent(event)}
            />
          )
        })
      }
    </div>
  )
}

CalendarEventList.propTypes = {
  eventList: PropTypes.arrayOf(PropTypes.object)
}

CalendarEventList.defaultProps = {
  eventList: []
}
export default CalendarEventList
