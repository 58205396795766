import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@atoms'
import { TextArea, RoughCommentIcon, RoughCommentModal } from '@molecules'
import { Table } from '@organisms'
import { Modal } from '@templates'
import { useAuthStore } from '@stores'
import { useToast } from '@hooks'
import { NavLink } from 'react-router-dom'
import { roughStoneActions } from '@actions'

function MultiRoughsCommentsModal({
  open,
  onClose,
  dataSet,
  refreshStoneList
}) {
  const [roughComment, setRoughComment] = useState('')
  const [isOpen, setIsOpen] = useState(open)
  const [openRoughCommentsModal, setOpenRoughCommentsModal] = useState({ open: false, rough: null })
  const { currentAuthenticatedUser } = useAuthStore(state => state)
  const { showErrorToast, showSuccessToast } = useToast()
  const columns = useMemo(() => [
    {
      Header: 'Clara ID',
      accessor: 'id',
      dataType: 'string',
      Cell: cellInfo => {
        return <NavLink
          className='link'
          to={`/roughstones/${cellInfo.row.original.id}`}
          target={'_blank'}
          id={cellInfo.row.original.id}
        >
          {cellInfo.row.values.id}
        </NavLink>
      }
    },
    {
      Header: 'Seller Stone Name',
      accessor: 'sellerStoneName',
      dataType: 'string'
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      dataType: 'date',
      filterType: 'date'
    },
    {
      Header: 'Last Updated',
      accessor: 'updatedAt',
      dataType: 'date',
      filterType: 'date'
    },
    {
      Header: '',
      accessor: 'comments',
      dataType: '',
      Cell: cellInfo => {
        return (
          <RoughCommentIcon
            comments={cellInfo?.row?.original?.comments}
            onClick={() => setOpenRoughCommentsModal({ open: true, rough: cellInfo?.row?.original })}
          />
        )
      }
    }
  ], [])

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  function postComment(commentText = '', roughIds = []) {
    if (!commentText) {
      showErrorToast('Comment is blank.')
      return
    }

    roughStoneActions.createRoughComment(roughIds, commentText)
    .then(() => {
      showSuccessToast('Comment created.')
      setIsOpen(false)
      refreshStoneList()
    })
  }

  function handleOnClose() {
    setRoughComment('')
    onClose()
  }

  return (
    <>
      <Modal
        title='Add New Comment to Rough Stones'
        open={isOpen}
        onClose={handleOnClose}
      >
        <Table
          title='Selected Rough Stones'
          isMultiSelect={false}
          columns={columns}
          data={dataSet}
        />
        <div className="rough-comment-multi__new-comment-container">
          <div className="rough-comment__form-title">
            <span>New comment as <strong>{currentAuthenticatedUser?.username}</strong></span>
          </div>
          <TextArea
            rows={5}
            value={roughComment}
            onChange={e => setRoughComment(e.target.value)}
          />
          <div className="rough-comment-multi__new-comment-post-button">
            <Button
              size='sm'
              onClick={() => postComment(roughComment, dataSet.map(d => d.id))}
            >
              Post
            </Button>
          </div>
        </div>
      </Modal>
      <RoughCommentModal
        open={openRoughCommentsModal.open}
        onClose={() => {
          setOpenRoughCommentsModal({ open: false, rough: null })
        }}
        rough={openRoughCommentsModal?.rough}
        readOnly={true}
      />
    </>
  )
}

MultiRoughsCommentsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  refreshStoneList: PropTypes.func.isRequired,
  dataSet: PropTypes.arrayOf(PropTypes.object)
}

MultiRoughsCommentsModal.defaultProps = {
  open: false,
  dataSet: []
}

export default MultiRoughsCommentsModal
