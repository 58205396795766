const BUYER = {
  COST: {
    MFG: {
      NAME: 'Manufacturing',
      FIELD: 'costPerRoughCarat',
      LABEL: 'Cost Per Rough Carat',
      KEY: 'MFG',
      CONFIRM_MODAL_KEY: 'mfgCostDetailsSaveConfirmation',
      ACTION: {
        POST: 'createMfgCost',
        GET: 'getMfgCostList',
        PATCH: 'editMfgCost',
        PUT: 'setMfgCostCondition'
      },
      ITEMS_KEY: 'MfgCostItems'
    },
    CERT: {
      NAME: 'Certificate',
      FIELD: 'costPerStone',
      LABEL: 'Cost Per Stone',
      KEY: 'CERT',
      CONFIRM_MODAL_KEY: 'certCostDetailsSaveConfirmation',
      ACTION: {
        POST: 'createCertCost',
        GET: 'getCertCostList',
        PATCH: 'editCertCost',
        PUT: 'setCertCostCondition'
      },
      ITEMS_KEY: 'CertCostItems'
    },
    ERROR: {
      REQUIRED: 'At least one cost row is required.',
      WEIGHT_COVERAGE: '{{name}} weights must have coverage up to at least {{maxWeight}}ct.'
    }
  }
}

export default BUYER
