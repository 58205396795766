const { default: PQueue } = require('p-queue')

const batchRequest = function (fn) {
  const groupId = Math.random()
  return async function * ({ offset = 0, limit = 500, ...params } = {}, { requestGroupId = groupId, progress = false, ...options } = { requestGroupId: groupId, progress: false }, ...args) {
    const res = await fn.apply(this, [
      { ...params, offset, limit },
      {
        ...options,
        requestGroupId,
        requestGroupOptions: {
          progress,
          weight: limit
        }
      },
      ...args
    ])
    const recordCount = res?.data?.recordCount
    const queue = new PQueue({ concurrency: 3 })
    const res2 = await queue.addAll(
      Array.from(
        { length: Math.ceil((recordCount ?? 0) / limit) - 1 },
        (_, i) => i + 1
      ).map(page => (() => {
        return fn.apply(this, [
          {
            ...params,
            offset: limit * page,
            limit
          },
          {
            ...options,
            requestGroupId,
            requestGroupOptions: {
              progress,
              weight: (
                recordCount >= (limit * page)
                  ? limit
                  : (recordCount % limit)
              )
            }
          },
          ...args
        ])
      }))
    ).then(values => values.reduce((accum, value) => accum.concat(value?.data?.data), res?.data?.data))
    yield res
    yield res2
  }
}

export default { batchRequest }
