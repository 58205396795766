import create from 'zustand'

const useSigninStore = create(set => ({
  displayMode: 'signIn',
  setDisplayMode: (displayMode) => {
    set({ displayMode })
  }
}))

export {
  useSigninStore
}
