import axios from 'axios'
const BASE_URL = process.env.REACT_APP_API_BASE_URL

const getMfgCostList = function (params) {
  return axios.get(`${BASE_URL}/buyer/costs/manufacturing`, { params })
}
const getCertCostList = function (params) {
  return axios.get(`${BASE_URL}/buyer/costs/certificate`, { params })
}
getMfgCostList.endpoint = 'GET|/buyer/costs/manufacturing'
getCertCostList.endpoint = 'GET|/buyer/costs/certificate'

const createMfgCost = function(params) {
  return axios.post(`${BASE_URL}/buyer/costs/manufacturing`, params)
}
const createCertCost = function(params) {
  return axios.post(`${BASE_URL}/buyer/costs/certificate`, params)
}
createMfgCost.endpoint = 'POST|/buyer/costs/manufacturing'
createCertCost.endpoint = 'POST|/buyer/costs/certificate'

const editMfgCost = function(costId, params) {
  return axios.patch(`${BASE_URL}/buyer/costs/manufacturing/${costId}`, params)
}
const editCertCost = function(costId, params) {
  return axios.patch(`${BASE_URL}/buyer/costs/certificate/${costId}`, params)
}
editMfgCost.endpoint = 'PATCH|/buyer/costs/manufacturing/:mfgCostId:'
editCertCost.endpoint = 'PATCH|/buyer/costs/certificate/:certCostId:'

const setMfgCostCondition = function(mfgCostIds, condition) {
  return axios.put(`${BASE_URL}/buyer/costs/manufacturing/condition`, { mfgCostIds, condition })
}
const setCertCostCondition = function(certCostIds, condition) {
  return axios.put(`${BASE_URL}/buyer/costs/certificate/condition`, { certCostIds, condition })
}
setMfgCostCondition.endpoint = 'PUT|/buyer/costs/manufacturing/condition'
setCertCostCondition.endpoint = 'PUT|/buyer/costs/certificate/condition'

export const costActions = {
  getMfgCostList,
  getCertCostList,
  createMfgCost,
  createCertCost,
  editMfgCost,
  editCertCost,
  setMfgCostCondition,
  setCertCostCondition
}
