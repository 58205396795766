import React from 'react'
import PropTypes from 'prop-types'
import 'react-calendar/dist/Calendar.css'
import { Calendar as ReactCalendar } from 'react-calendar'
import CalendarEventList from './calendarEventList'
import { textUtils } from '@utils'

function Calendar({
  value,
  eventList,
  displayEvents
}) {
  function isToday(date) {
    return textUtils.formatDate(date) === textUtils.formatDate(new Date())
  }

  function hasEvent(date) {
    if (!eventList.length) return false
    return eventList.some(event => textUtils.formatDate(event.startTime) === textUtils.formatDate(date))
  }

  function tileContent({ date }) {
    return (
      <>
        {
          hasEvent(date)
            ? <span className="calendar-component__tile-event"></span>
            : null
        }

        <span>{textUtils.formatDate(date, 'D')}</span>
      </>
    )
  }

  function tileClassName ({ date }) {
    if (isToday(date)) {
      return 'calendar-component__tile-today'
    }

    return 'calendar-component__tile'
  }

  return (
    <div className="calendar__container">
      <ReactCalendar
        value={value}
        className='calendar-component'
        tileContent={tileContent}
        tileClassName={tileClassName}
      />
      {
        displayEvents
          ? (
            <CalendarEventList
              eventList={eventList}
            />
          )
          : null
      }
    </div>
  )
}

Calendar.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
  displayEvents: PropTypes.bool,
  eventsListPosition: PropTypes.string,
  eventList: PropTypes.arrayOf(PropTypes.object)
}

Calendar.defaultProps = {
  eventList: [],
  value: new Date(),
  displayEvents: true,
  eventsListPosition: 'right'
}

export default Calendar
