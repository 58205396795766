import React from 'react'
import { TextInput, Dropdown } from '@molecules'

const emptyValue = '-555555.000000'
// Check for emptyValue in its string or numeric format for robustness
const isEmptyValue = (x) => x == emptyValue
const isEmpty = (x) => x == null || isNaN(x) || isEmptyValue(x) || x === ''
const toSarineFmt = (x) => {
  if (typeof x === 'string') return x
  if (typeof x === 'number') return x.toFixed(6)
}
const displayTypes = {
  PLAIN: {
    toDisplay: x => isEmptyValue(x) ? '' : x,
    toValue: x => isEmpty(x) ? emptyValue : x,
    units: ''
  },
  PERCENT: {
    toDisplay: x => isEmptyValue(x) ? '' : x * 100,
    toValue: x => isEmpty(x) ? emptyValue : x / 100,
    units: '%'
  },
  DEGREES: {
    toDisplay: x => isEmptyValue(x) ? '' : x * 180 / Math.PI,
    toValue: x => isEmpty(x) ? emptyValue : (x * Math.PI / 180),
    units: 'degrees'
  },
  PERCENT_DIFFERENCE: {
    toDisplay: x => isEmptyValue(x) ? '' : (x - 1) * 100,
    toValue: x => isEmpty(x) ? emptyValue : ((x / 100) + 1),
    units: '% diff'
  },
  HIDDEN: {
    toDisplay: x => isEmptyValue(x) ? '' : x,
    toValue: x => isEmpty(x) ? emptyValue : x
  },
  GIRDLE_BOOLEAN: {
    toDisplay: x => isEmptyValue(x) ? '' : toSarineFmt(x),
    toValue: x => isEmpty(x) ? emptyValue : x
  }
}

function ProportionInput({ proportion, description, displayType, minValue, maxValue, onChange, ...props }) {
  const dispTp = displayTypes[displayType]

  function handleChange(e) {
    const target = 'currentTarget' in e ? 'currentTarget' : 'target'
    onChange({ [target]: { ...e?.[target], value: dispTp.toValue(e?.[target]?.value) } })
  }

  if (displayType === 'HIDDEN') {
    return null
  } else if (displayType === 'GIRDLE_BOOLEAN') {
    return <div className='proportion-input__container'>
      <div className='proportion-input__label'>
        <label>
          <div className="input__label">
            <span>{description}</span>
          </div>
        </label>
      </div>
      <div className='proportion-input__minmax'>
        <Dropdown
          {...props}
          name={`proportions.${proportion}.min`}
          value={(minValue == null || minValue === '') ? '' : dispTp.toDisplay(minValue)}
          onChange={handleChange}
          options={[
            { value: '', label: 'Unspecified' },
            { value: '0.000000', label: 'Mountains' },
            { value: '1.000000', label: 'Valleys' }
          ]}
        />
      </div>
    </div>
  } else {
    return <div className='proportion-input__container'>
      <div className='proportion-input__label'>
        <label>
          <div className="input__label">
            <span>{description}</span>
          </div>
        </label>
      </div>
      <div className='proportion-input__min'>
        <TextInput
          {...props}
          name={`proportions.${proportion}.min`}
          value={(minValue == null || minValue === '') ? '' : dispTp.toDisplay(minValue)}
          onChange={handleChange}
          type='number'
          decimalScale={5}
        />
      </div>
      <div className='proportion-input__max'>
        <TextInput
          {...props}
          name={`proportions.${proportion}.max`}
          value={(maxValue == null || maxValue === '') ? '' : dispTp.toDisplay(maxValue)}
          onChange={handleChange}
          type='number'
          decimalScale={5}
        />
      </div>
      <div className='proportion-input__units'>
        <label>
          <div className="input__label">
            <span>{dispTp.units}</span>
          </div>
        </label>
      </div>
    </div>
  }
}

export default ProportionInput
