import React, { useEffect, useState, useMemo } from 'react'
import { useModalStore, useRoughStoneStore } from '@stores'
import { useToast } from '@hooks'
import { Checkbox } from '@atoms'
import ProgressiveProfitMatrix from './inc/progressiveProfitMatrix'
import PQueue from 'p-queue'
import { assortmentActions } from '@actions'
import { Dropdown } from '@molecules'

function ProgressiveProfitModal({ open, onClose, dataSet }) {
  const {
    setModal
  } = useModalStore(state => state)
  const { showSuccessToast } = useToast()

  const [useProgressiveProfit, setUseProgressiveProfit] = useState(true)
  const [progressiveProfitMatrix, setProgressiveProfitMatrix] = useState(null)

  const closeModal = () => {
    setUseProgressiveProfit(true)
    setProgressiveProfitMatrix(null)
    onClose()
  }

  const saveAssortments = async () => {
    const queue = new PQueue({ concurrency: 4 })
    return queue.addAll(dataSet.map(asmt => (() => {
      return assortmentActions.editAssortment({ assortmentId: asmt.id, useProgressiveProfit, progressiveProfitMatrix: progressiveProfitMatrix ?? undefined })
    })))
    .then(() => showSuccessToast('Assortments Updated'))
  }

  useEffect(() => {
    if (open) {
      setModal({
        id: 'progressiveProfit',
        type: 'form',
        title: 'Set Progressive Profit Matrix',
        customMessageRenderer,
        // customButtonsRenderer: () => null,
        onClose: closeModal,
        className: 'assortment-list__progressive-profit-modal',
        submitText: 'Save',
        onSubmit: saveAssortments,
        onCancel: closeModal,
        addDefaultButtons: true
      })
    }
  }, [open, useProgressiveProfit, progressiveProfitMatrix])

  const { assortmentsList: { all: assortmentsList = [] }, getAssortmentsList } = useRoughStoneStore()
  useEffect(() => {
    getAssortmentsList()
  }, [])
  const filteredAssortments = useMemo(() => {
    return assortmentsList.filter(asmt => asmt.progressiveProfitMatrix?.length)
  }, [assortmentsList])

  const customMessageRenderer = (messageProps, modalDefaults) => {
    return <div>
      <Checkbox
        name='useProgressiveProfit'
        value={useProgressiveProfit}
        onChange={(e) => {
          const target = e.currentTarget || e.target
          setUseProgressiveProfit(target.checked)
        }}
        label='Use Progressive Profit'
      />
      <br/>
      <Dropdown
        value=''
        placeholder='Fill With...'
        label='From Existing Matrix'
        disabled={!useProgressiveProfit}
        options={filteredAssortments.map(asmt => ({ value: asmt.id, label: `${asmt.name} | ${asmt.useProgressiveProfit ? 'Using PP' : 'Inactive PP'}` }))}
        required={false}
        onChange={(e) => {
          const target = e.currentTarget || e.target
          if (!target.value) return
          const asmt = filteredAssortments.find(a => a.id === target.value)
          setProgressiveProfitMatrix(asmt.progressiveProfitMatrix)
        }}
      />
      <br/>
      <ProgressiveProfitMatrix
        name='progressiveProfitMatrix'
        value={progressiveProfitMatrix}
        onChange={(e) => {
          const target = e.currentTarget || e.target
          setProgressiveProfitMatrix(target.value)
        }}
        label='Matrix'
        disabled={!useProgressiveProfit}
      />
    </div>
  }

  return null
}

export default ProgressiveProfitModal
