import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Table } from '@organisms'
import { orderActions } from '@actions'
import { arrayUtils } from '@utils'
import { useGlobalsStore } from '@stores'

function AssignOrders({ orderList: existingOrderList, orgsList, handleSubmit, handleCancel }) {
  const { orderStatusesMap, getOrderStatuses } = useGlobalsStore(store => store)

  useEffect(() => {
    getOrderStatuses()
  }, [])

  const [orderList, setOrderList] = useState(existingOrderList)
  const genericOrg = useMemo(() => orgsList?.find(({ orgType }) => orgType === 'GENERIC')?.orgId, [orgsList])

  function refreshList() {
    if (!genericOrg) return
    orderActions.getOrderList({ condition: 'ACTIVE', buyerId: genericOrg })
    .then(response => setOrderList((existingOrderList) => arrayUtils.unionBy(existingOrderList, response.data.data, (x) => x.id)))
    .catch(err => console.error(err))
  }
  useEffect(() => {
    refreshList()
  }, [])

  const initialSelected = useMemo(() => existingOrderList.reduce((selected, _, idx) => ({ ...selected, [idx]: true }), {}), [existingOrderList])
  const columns = useMemo(() => ([
    {
      Header: 'Order ID',
      accessor: 'id',
      dataType: 'number'
    },
    {
      Header: 'Order Name',
      accessor: 'name',
      dataType: 'string'
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: row => orderStatusesMap?.[row.status] ?? '',
      dataType: 'string',
      filterType: 'checkbox',
      enums: Object.values(orderStatusesMap)
    },
    {
      Header: 'Last Updated',
      accessor: 'updatedAt',
      dataType: 'date',
      filterType: 'date'
    }
  ]), [orderStatusesMap])

  const topBarActions = [{
    label: 'Update',
    callback: ({ selectedRows }) => handleSubmit({ genericOrderIds: arrayUtils.pickBy(selectedRows, 'id') }),
    enableOnSelect: true
  }]

  return (
    <Table
      title="Orders"
      data={orderList}
      columns={columns}
      initialSelected={initialSelected}
      initialSort={[]}
      topBarActions={topBarActions}
    />
  )
}

AssignOrders.propTypes = {
  orderList: PropTypes.array,
  orgsList: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired
}
AssignOrders.defaultProps = {
  orderList: [],
  orgsList: []
}

export default AssignOrders
