import React from 'react'
import { Icon } from '@atoms'

function NotificationEmptyState() {
  return (
    <div className="notification__empty-state">
      <div className="notification__empty-state-icon">
        <Icon name='bell' />

      </div>
      <span className='notification__empty-state-heading'>
        You currently have no notifications
      </span>
    </div>
  )
}

export default NotificationEmptyState
