import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Table } from '@organisms'

function QcProgressModal({
  qcProgress,
  usersMap,
  qcProgressStatusesMap
}) {
  const columns = useMemo(() => ([
    {
      Header: 'User',
      id: 'userId',
      accessor: row => usersMap?.[row.userId] || 'Other',
      dataType: 'string',
      filterType: 'checkbox',
      enums: Object.values(usersMap).concat(['Other'])
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: row => qcProgressStatusesMap?.[row.status],
      dataType: 'string',
      filterType: 'checkbox',
      enums: Object.values(qcProgressStatusesMap).concat()
    },
    {
      Header: 'Date',
      id: 'createdAt',
      accessor: 'createdAt',
      dataType: 'dateTime',
      filterType: 'date'
    }
  ]), [qcProgress, usersMap])

  const initialSort = [{
    id: 'createdAt',
    desc: true
  }]

  return (
    <Table
      title='Users'
      data={qcProgress}
      columns={columns}
      isMultiSelect={false}
      initialSort={initialSort}
    />
  )
}

QcProgressModal.propTypes = {
  qcProgress: PropTypes.array,
  usersMap: PropTypes.object,
  qcProgressStatusesMap: PropTypes.object
}

QcProgressModal.defaultProps = {
  qcProgress: [],
  usersMap: {},
  qcProgressStatusesMap: {}
}

export default QcProgressModal
