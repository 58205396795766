import React, { useEffect, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Table } from '@organisms'
import { useToast } from '@hooks'
import { ConfirmationModal } from '@templates'
import { useAuthStore, useGlobalsStore } from '@stores'
import { transactionActions, reportActions, organizationActions } from '@actions'
import moment from 'moment'
import FileSaver from 'file-saver'
import UpdateTransactionModal from './modals/updateTransactionsModal'
import AssignRoughStonesModal from './modals/assignRoughStones/assignRoughStonesmodal'

function DashboardTable({ refreshList, orgsList, transactions, selectedSale, organizationId }) {
  const {
    hasAdmin,
    hasPermission,
    permissionsAdminCache
  } = useAuthStore(state => state)
  const {
    roughColoursMap,
    roughColours,
    getRoughColours
  } = useGlobalsStore(state => state)
  useEffect(() => {
    getRoughColours()
  }, [])

  const { buyer: buyerTransactions, seller: sellerTransactions, admin: adminTransactions } = transactions

  const transactionsTypes = [
    { value: 'buyer', label: 'Buyer' },
    { value: 'seller', label: 'Seller' },
    { value: 'admin', label: 'Admin' }]
  const [transactionsType, setTransactionsType] = useState()
  const [assignStonesModal, setAssignStonesModal] = useState({ open: false, selectedRows: [] })
  const [openModal, setOpenModal] = useState({ updateKeys: [], selectedRows: [], newLocation: '' })
  const { showSuccessToast } = useToast()
  function updateSucceeded(message = 'Stones updated') {
    return function () {
      showSuccessToast(message)
      refreshList()
    }
  }

  useEffect(() => {
    // Reset transactions type when changing sale or org
    if (selectedSale && organizationId) setTransactionsType()
  }, [selectedSale, organizationId])
  useEffect(() => {
    // Don't set transactionsType until both variables are ready
    // TODO: Check if we already have a transactionType set and try and preserve that choice
    if (transactionsType) return
    if (buyerTransactions && sellerTransactions) {
      if (buyerTransactions.length) setTransactionsType('buyer')
      else if (sellerTransactions.length) setTransactionsType('seller')
    } else if (adminTransactions) {
      if (adminTransactions.length) setTransactionsType('admin')
    }
  }, [buyerTransactions, sellerTransactions, adminTransactions])

  const filteredTransactions = useMemo(() => {
    if (!transactionsType) return []
    return transactions?.[transactionsType]?.filter((t) => (!selectedSale || t.saleId === selectedSale) && (!organizationId || t.buyerId === organizationId || t.sellerId === organizationId))
  }, [transactions, transactionsType, selectedSale, organizationId])

  const [downloadFilesModalOpen, setDownloadFilesModalOpen] = useState({ name: false, selectedRows: [] })

  async function downloadMultipleFiles(rows, format) {
    for (const row of rows) {
      const response = await reportActions.getDownloadUrl(format === 'AXP' ? [row.SPSRequest.plannedAxpId] : [row.SPSRequest.plannedAdvId], { spsRequestId: row.SPSRequest.id })
      const file = response?.data?.data?.[0]
      FileSaver.saveAs(file.url)
    }
  }

  function getDataCallback(params) {
    if (params) setTransactionsType(params)
  }

  function resetDataCallback() {
    refreshList()
  }

  const [columns, setColumns] = useState([])
  useEffect(() => {
    setColumns([
      {
        Header: 'Clara ID',
        accessor: 'Rough.id',
        dataType: 'string',
        filterType: 'textarea',
        Cell: cellInfo => {
          return (
            <NavLink
              className="link"
              to={`/transactions/${cellInfo.row.original.id}`}
              id={cellInfo.row.original.id}
            >
              {cellInfo.value}
            </NavLink>
          )
        }
      },
      {
        Header: 'Weight',
        accessor: 'Rough.weight',
        dataType: 'string',
        filterType: 'numberRange'
      },
      // CD-1782: Consider showing rough colour for seller transactions, polished colour for buyer transactions and both for admin
      {
        Header: 'Color',
        accessor: (row) => roughColoursMap?.[row?.Rough?.colour] ?? '',
        dataType: 'string',
        enums: roughColours?.map(c => c.description),
        filterType: 'checkbox'
      },
      {
        Header: 'Fluor',
        accessor: 'Rough.fluorescence',
        dataType: 'string',
        filterType: 'numberRange'
      },
      ...(hasPermission(transactionActions.getAdminTransactions) || transactionsType === 'seller' ? [
        {
          Header: hasPermission(transactionActions.getAdminTransactions) ? "Seller's Price" : 'Sold Price',
          accessor: 'sellerPrice',
          dataType: 'currency',
          filterType: 'numberRange',
          decimalScale: 2,
          fixedDecimalScale: true
        }
      ] : []),
      ...(hasPermission(transactionActions.getAdminTransactions) || transactionsType === 'buyer' ? [
        {
          Header: hasPermission(transactionActions.getAdminTransactions) ? "Buyer's Price" : 'Purchase Price',
          accessor: 'buyerPrice',
          dataType: 'currency',
          filterType: 'numberRange',
          decimalScale: 2,
          fixedDecimalScale: true
        }
      ] : []),
      ...(hasPermission(transactionActions.getAdminTransactions) ? [
        {
          Header: 'Total Profit',
          accessor: 'claraMargin',
          dataType: 'currency',
          filterType: 'numberRange',
          decimalScale: 2,
          fixedDecimalScale: true
        }
      ] : []),
      ...(hasAdmin(organizationActions.getOrganizationList) ? [
        {
          Header: 'Seller',
          id: 'sellerName',
          accessor: txn => orgsList?.find(org => org.orgId === txn.sellerId)?.commonName,
          dataType: 'string'
        },
        {
          Header: 'Buyer',
          id: 'buyerName',
          accessor: txn => orgsList?.find(org => org.orgId === txn.buyerId)?.commonName,
          dataType: 'string'
        }
      ] : []),
      {
        Header: 'Status',
        id: 'status',
        accessor: txn => txn.Rough.status === 'ROUGH_ACCEPTED' ? 'Accepted' : (txn.cancelledDate ? 'Cancelled' : (txn.shippedDate ? 'Shipped' : (txn.paidDate ? 'Paid' : 'Sold'))),
        dataType: 'string',
        filterType: 'checkbox',
        enums: ['Sold', 'Paid', 'Shipped', 'Accepted', ...(hasPermission(transactionActions.getAdminTransactions) ? ['Cancelled'] : [])]
      },
      {
        Header: 'Date Transacted',
        accessor: 'transactionDate',
        dataType: 'date',
        filterType: 'date'
      }
    ])
  }, [permissionsAdminCache, selectedSale, organizationId, orgsList, roughColoursMap, transactionsType])

  const tableTabs = useMemo(() => {
    let tabs = []
    if (buyerTransactions) tabs.push({ label: transactionsTypes.find(({ value }) => value === 'buyer').label, params: 'buyer', disabled: !buyerTransactions.length })
    if (sellerTransactions) tabs.push({ label: transactionsTypes.find(({ value }) => value === 'seller').label, params: 'seller', disabled: !sellerTransactions.length })
    if (adminTransactions) tabs = [{ label: transactionsTypes.find(({ value }) => value === 'admin').label, params: 'admin' }]
    return tabs.length > 1 ? tabs : []
  }, [buyerTransactions, sellerTransactions, adminTransactions])

  // Wait for the tabs to be ready before setting table id
  const tableId = useMemo(() => selectedSale, [tableTabs])

  // Wait for transactions to be fully fetched before displaying this table
  if (!(buyerTransactions && sellerTransactions) && !adminTransactions) return null
  return (
    <>
      <Table
        id={tableId}
        title='Transactions'
        data={filteredTransactions}
        columns={columns}
        tableTabs={tableTabs}
        resetDataCallback={resetDataCallback}
        getDataCallback={getDataCallback}
        isMultiSelect={true}
        topBarActions={[
          {
            componentName: 'dropdown',
            enableOnSelect: true,
            options: (hasPermission(transactionActions.editTransactions)
              ? [
                {
                  label: 'Mark as Paid',
                  isDisabled: rows => rows.some(({ values }) => values.status !== 'Sold'),
                  callback: selectedRows => setOpenModal({ updateKeys: ['paidDate'], selectedRows, paidDate: moment() }),
                  value: 'paid'
                },
                {
                  label: 'Mark as Shipped',
                  isDisabled: rows => rows.some(({ values }) => values.status !== 'Paid'),
                  callback: selectedRows => setOpenModal({ updateKeys: ['shippedDate'], selectedRows, shippedDate: moment() }),
                  value: 'shipped'
                },
                {
                  label: 'Mark as Accepted',
                  isDisabled: rows => rows.some(({ values }) => values.status !== 'Shipped'),
                  callback: selectedRows => setOpenModal({ updateKeys: ['accepted'], selectedRows, accepted: true }),
                  value: 'accepted'
                },
                {
                  label: 'Mark as Not Shipped',
                  isDisabled: rows => rows.some(({ values }) => values.status !== 'Shipped'),
                  callback: selectedRows => setOpenModal({ updateKeys: ['shippedDate'], selectedRows, shippedDate: null }),
                  value: 'unshipped'
                },
                {
                  label: 'Mark as Unpaid',
                  isDisabled: rows => rows.some(({ values }) => values.status !== 'Paid'),
                  callback: selectedRows => setOpenModal({ updateKeys: ['paidDate'], selectedRows, paidDate: null }),
                  value: 'unpaid'
                },
                {
                  label: 'Cancel Transactions',
                  isDisabled: rows => rows.some(({ values }) => ['Accepted', 'Cancelled'].includes(values.status)),
                  callback: selectedRows => setOpenModal({ updateKeys: ['cancelledDate', 'cancelledReason'], selectedRows, cancelledDate: moment() }),
                  value: 'cancelled'
                },
                {
                  label: 'Reassign Rough Stones',
                  isDisabled: rows => !rows.every(({ values }) => ['Cancelled'].includes(values.status)),
                  callback: selectedRows => setAssignStonesModal({ open: true, selectedRows }),
                  value: 'reassignRoughStone'
                },
                {
                  label: 'Download ADVs',
                  callback: selectedRows => {
                    setDownloadFilesModalOpen({ name: 'ADV', selectedRows })
                  },
                  value: 'downloadAdv'
                },
                {
                  label: 'Download AXPs',
                  callback: selectedRows => {
                    setDownloadFilesModalOpen({ name: 'AXP', selectedRows })
                  },
                  value: 'downloadAxp'
                }
              ]
              : [
                {
                  label: 'Download ADVs',
                  isDisabled: rows => rows.some(row => !row.original?.paidDate || !row.original?.SPSRequest?.plannedAdvId),
                  callback: selectedRows => {
                    setDownloadFilesModalOpen({ name: 'ADV', selectedRows })
                  },
                  value: 'downloadAdv'
                },
                {
                  label: 'Download AXPs',
                  isDisabled: rows => rows.some(row => !row.original?.paidDate || !row.original?.SPSRequest?.plannedAxpId),
                  callback: selectedRows => {
                    setDownloadFilesModalOpen({ name: 'AXP', selectedRows })
                  },
                  value: 'downloadAxp'
                }
              ]
            )
          }
        ]}
      />
      <UpdateTransactionModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        transactions={filteredTransactions}
        updateSucceeded={updateSucceeded}
      />
      <AssignRoughStonesModal
        open={assignStonesModal.open}
        setIsOpen={setAssignStonesModal}
        selectedRows={assignStonesModal.selectedRows}
        orgsList={orgsList}
      />
      <ConfirmationModal
        open={downloadFilesModalOpen.name === 'ADV' || downloadFilesModalOpen.name === 'AXP'}
        title={`Download ${downloadFilesModalOpen.name} Files`}
        message={
          <>
            Are you sure you want to download {downloadFilesModalOpen.selectedRows.length} {downloadFilesModalOpen.name} files?
            <br/><br/>
            (Please note, your browser may block downloading multiple files at once and you will have to allow this action.)
          </>
        }
        onClose={() => setDownloadFilesModalOpen({ name: false, selectedRows: [] })}
        onSubmit={() => {
          downloadMultipleFiles(downloadFilesModalOpen.selectedRows, downloadFilesModalOpen.name)
        }}
      >
      </ConfirmationModal>
    </>
  )
}

export default DashboardTable
