import React from 'react'
import { useMultiErrorModalStore } from '@stores'
import { Modal } from '@templates'
function MultiErrorModal() {
  const {
    isOpen,
    errors,
    setIsOpen,
    resetErrorModalStore
  } = useMultiErrorModalStore(state => state)

  if (!isOpen) return null

  return (
    <Modal
      title='Errors List'
      open={isOpen}
      onClose={() => {
        setIsOpen(false)
        resetErrorModalStore()
      }}
    >
      <div className="multi-error-modal">
        <div className='multi-error-modal__error-counter'>
        Errors: {errors.length}
        </div>
        {errors.map((error, index) => (
          <div
            className='multi-error-modal__error'
            key={index}
          >
            <span
              className='multi-error-modal__error-text'
            >
              {error}
            </span>
          </div>
        ))}
      </div>
    </Modal>
  )
}

export default MultiErrorModal
