import React, { useState, useEffect, useMemo } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import FileSaver from 'file-saver'
import dottie from 'dottie'
import moment from 'moment'
import { Table } from '@organisms'
import { reportActions, roughStoneActions, planningActions, organizationActions, qcActions, provenanceActions, orderActions, userActions } from '@actions'
import { arrayUtils, errorUtils, fileUtils, objectUtils, textUtils } from '@utils'
import { ConfirmationModal } from '@templates'
import { useGlobalsStore, usePlanningStore, useRoughStoneStore, useOrderStore, useModalStore, useAuthStore, useQcStore } from '@stores'
import { useToast } from '@hooks'
import SendForPlanningForm from './send_for_planning_form'
import UpdateInclusionDetails from './updateInclusionDetails'
import AssignStones from './assignStones'
import UpdateAdvDetails from './updateAdvDetails'
import QcProgressModal from './qcProgress'
import { Badge } from '@molecules'
import { COMMON } from '@constants'

const STONE_KEYS = {
  ROUGH: 'rough',
  PLANNED: 'planned'
}
const QC_USER_TAB_KEYS = ['new', 'wip', 'done', 'reject']
const FILTERS = {
  PENDING: { key: 'pending', stoneKey: STONE_KEYS.PLANNED, submittedOnly: true, qcApproved: false, columns: ['Rough.QcPermissions', 'Rough.QcProgress'], adminColumns: ['Rough.Assortment'] },
  SALE_READY: { key: 'saleReady', stoneKey: STONE_KEYS.PLANNED, submittedOnly: true, qcApproved: true, columns: ['Rough.QcPermissions', 'Rough.QcProgress'], adminColumns: ['Rough.Assortment'] },
  UNPLANNED: { key: 'unplanned', stoneKey: STONE_KEYS.ROUGH, status: 'ROUGH_SUBMITTED', columns: ['genericPrice'], adminColumns: ['Assortment'] },
  SUBMITTED: { key: 'submitted', stoneKey: STONE_KEYS.PLANNED, submittedOnly: true, columns: ['Rough.QcPermissions', 'Rough.QcProgress'], adminColumns: ['Rough.Assortment'] },
  SOLD: { key: 'sold', stoneKey: STONE_KEYS.PLANNED, status: 'COMPLETE', earliestSpsEndTime: moment().subtract(3, 'months').toISOString(), columns: [], adminColumns: ['Rough.Assortment'] },
  NEW: { key: 'new', stoneKey: STONE_KEYS.PLANNED, submittedOnly: true, columns: ['Rough.QcPermissions', 'Rough.QcProgress'] },
  WIP: { key: 'wip', stoneKey: STONE_KEYS.PLANNED, submittedOnly: true, columns: ['Rough.QcPermissions', 'Rough.QcProgress'] },
  DONE: { key: 'done', stoneKey: STONE_KEYS.PLANNED, submittedOnly: true, columns: ['Rough.QcPermissions', 'Rough.QcProgress'] },
  REJECT: { key: 'reject', stoneKey: STONE_KEYS.PLANNED, submittedOnly: true, columns: ['Rough.QcPermissions', 'Rough.QcProgress'] }
}

const qcPermissionsListParams = { 'condition.min': 'ARCHIVED', 'condition.max': 'ACTIVE' }
const qcProgressListParams = { }

function QCStonesList() {
  const {
    orgsList: { limited: { all: orgsList } }, orgsMap: { limited: { all: orgsMap } }, getLimitedOrgsList,
    inclusionReductionsMap, getInclusionReductions,
    inclusionTypesMap, getInclusionTypes,
    minesList, getMinesList,
    roughColoursMap, getRoughColours,
    roughQCStatuses, roughQCApprovedMap, getRoughQCStatuses,
    qcProgressStatusesMap, getQCProgressStatuses,
    polishedTingesMap, getPolishedTinges,
    spsRequestStatusesMap, getSpsRequestStatuses
  } = useGlobalsStore(store => store)
  const { getQcPermissionsList, getQcProgressList } = useQcStore(store => store)
  const { currentAuthenticatedUser, hasAdmin, permissionsAdminCache } = useAuthStore(store => store)
  const userId = currentAuthenticatedUser?.userId
  const customRoles = currentAuthenticatedUser?.attributes?.['custom:roles']
  const userRoles = customRoles
    ? JSON.parse(customRoles)
    : []
  const { setModal } = useModalStore()
  const { hasPermission } = useAuthStore()
  const { showSuccessToast, showErrorToast, showWarningToast } = useToast()
  const history = useHistory()
  const [tabRequestParams, setTabRequestParams] = useState()
  const [plannedStonesParams, setPlannedStonesParams] = useState()
  const [roughStoneParams, setRoughStonesParams] = useState()
  const [usersList, setUsersList] = useState([])
  const stoneKey = tabRequestParams?.stoneKey
  const genericOrg = useMemo(() => orgsList?.find(o => o.orgType === 'GENERIC')?.orgId, [orgsList])
  const roughQCSellableMap = useMemo(() => arrayUtils.toMap(roughQCStatuses, ({ value }) => value, 'sellable'), [roughQCStatuses])

  useEffect(() => {
    if (orgsList && !genericOrg) showErrorToast('No Generic organization was found')
  }, [orgsList, genericOrg])

  useEffect(() => {
    if (!permissionsAdminCache?.size) return
    userActions.getUsersList().then(async response => setUsersList(response.data.data))
  }, [permissionsAdminCache])
  const qcUsersList = useMemo(() => (usersList || []).filter(({ roles }) => roles.includes('qc.user')), [usersList, userId])
  const usersMap = useMemo(() => arrayUtils.toMap(usersList || [], x => x.userId, 'name'), [usersList])

  useEffect(() => {
    if (!permissionsAdminCache.size) return
    getLimitedOrgsList()
    getInclusionReductions()
    getInclusionTypes()
    getRoughColours()
    getRoughQCStatuses()
    getQCProgressStatuses()
    getPolishedTinges()
    getSpsRequestStatuses()
    hasPermission(provenanceActions.getMines) && hasAdmin(roughStoneActions.getQcRoughStoneList) && getMinesList()
  }, [permissionsAdminCache])

  function hasFilterKey(acceptedKeys = []) {
    return acceptedKeys.map(({ key }) => key).includes(tabRequestParams?.key)
  }
  function hasStoneKey(acceptedKeys = []) {
    return acceptedKeys.includes(tabRequestParams?.stoneKey)
  }
  function hasRoleKey(acceptedKeys = []) {
    return arrayUtils.intersectBy(acceptedKeys, userRoles, x => x)?.length
  }
  function filterBy({ tabs = true, roles = true }) {
    const display = { tabs: true, roles: true }
    if (Array.isArray(tabs)) display.tabs = hasFilterKey(tabs)
    else display.tabs = tabs

    if (Array.isArray(roles)) display.roles = hasRoleKey(roles)
    else display.roles = roles

    return display.tabs && display.roles
  }

  function getQcApprovedByTab(tab) {
    if (!tab || !roughQCApprovedMap) return []
    if (!('qcApproved' in tab)) return Object.values(roughQCApprovedMap ?? [])
    return roughQCStatuses.filter(r => tab.qcApproved ? r.sellable : !r.sellable).map(r => r.qcApproved)
  }

  useEffect(() => {
    if (!permissionsAdminCache?.size || !tabRequestParams) return
    refreshStones()
  }, [tabRequestParams, genericOrg, permissionsAdminCache])
  useEffect(() => {
    if (!permissionsAdminCache?.size) return
    if (!tabRequestParams) setTabRequestParams(hasAdmin(planningActions.getQcPlannedStones) ? FILTERS.PENDING : FILTERS.NEW)
  }, [tabRequestParams, permissionsAdminCache])

  const {
    qcRoughStonesList: { [JSON.stringify(roughStoneParams)]: roughStonesList },
    getQcRoughStonesList,
    removeQcRoughStonesListItems,
    removeAllQcRoughStonesListItems,
    removeQcRoughStonesList
  } = useRoughStoneStore(store => store)
  const roughsList = useMemo(() => roughStonesList?.data.filter(r => !r.genericPrice), [roughStonesList?.lastUpdated])
  useEffect(() => {
    if (roughStonesList?.cacheInvalid) refreshStones()
  }, [roughStonesList?.cacheInvalid])

  async function refreshStones(hardRefresh = false) {
    const reqParams = { ...tabRequestParams, hardRefresh }
    if (hasStoneKey([STONE_KEYS.ROUGH])) {
      const stoneParams = getRoughStonesParams(reqParams)
      setRoughStonesParams(stoneParams)
      getQcRoughStonesList(stoneParams)
    } else {
      const stoneParams = getPlannedStonesParams(reqParams)
      const paramsKey = objectUtils.omit(stoneParams, ['lastUpdated'])
      setPlannedStonesParams(paramsKey)
      await getQcPlannedStonesList(stoneParams)
    }
  }

  async function resetStones() {
    const reqParams = { ...tabRequestParams }
    if (hasStoneKey([STONE_KEYS.ROUGH])) {
      const stoneParams = getRoughStonesParams(reqParams)
      removeQcRoughStonesList(stoneParams)
    } else {
      const stoneParams = getPlannedStonesParams(reqParams)
      const paramsKey = objectUtils.omit(stoneParams, ['lastUpdated'])
      await removeQcPlannedStonesList(paramsKey)
    }
  }

  function getRoughStonesParams(reqParams) {
    const params = objectUtils.omit(reqParams, ['key', 'stoneKey', 'hardRefresh', 'adminColumns'])
    params.columns = params.columns.concat(hasAdmin(roughStoneActions.getQcRoughStoneList) ? reqParams.adminColumns : [])
    return params
  }
  function getPlannedStonesParams(reqParams) {
    const { status, submittedOnly, qcApproved, earliestSpsEndTime, columns = [], adminColumns = [], hardRefresh } = reqParams
    const params = {
      status,
      qcApproved,
      earliestSpsEndTime,
      columns: columns.concat(hasAdmin(planningActions.getQcPlannedStones) ? adminColumns : []),
      limit: 500,
      condition: 'ACTIVE',
      ...(hardRefresh ? { lastUpdated: null } : {})
    }
    // backend expects submittedOnly params
    // only if it's true, otherwise it won't
    // delete from the opts.where object in spsRequest.js file
    if (submittedOnly) params.submittedOnly = submittedOnly
    else params.columns = (params.columns || []).concat('Rough')
    params.columns = params.columns && `[${params.columns}]`
    return params
  }

  const {
    qcPlannedStonesList: { [plannedStonesParams ? JSON.stringify(plannedStonesParams) : 'all']: plannedStonesList } = {},
    getQcPlannedStonesList,
    removeAllQcPlannedStonesListItems,
    removeQcPlannedStonesList
  } = usePlanningStore(store => store)

  const plannedList = useMemo(() => plannedStonesList?.data?.map((pS) => ({
    ...pS,
    qcProgress: pS?.Rough?.QcProgresses?.[0]?.status,
    qcProgressUser: usersMap?.[pS?.Rough?.QcProgresses?.[0]?.userId] || 'Other',
    permissions: pS?.Rough?.QcPermissions?.filter(qcP => qcP.condition === 'ACTIVE')?.map(qcP => usersMap?.[qcP.userId]) || []
  })), [plannedStonesList?.lastUpdated, usersMap])
  const plannedListByProgressStatus = useMemo(() => arrayUtils.groupBy(plannedList || [], (x) => x.qcProgress), [plannedList])
  useEffect(() => {
    if (plannedStonesList?.cacheInvalid) refreshStones()
  }, [plannedStonesList?.cacheInvalid])

  function getAccessor(row, ...k) {
    const accessor = hasStoneKey([STONE_KEYS.PLANNED]) ? 'Rough' : null
    return dottie.get(row, [accessor, ...k].filter(x => x), null)
  }

  function getCacheLastUpdated() {
    return (hasStoneKey([STONE_KEYS.PLANNED]) ? plannedStonesList : roughStonesList)?.lastUpdated
  }

  async function resetCaches() {
    const [expiredPermissions, expiredProgress] = await Promise.all([
      checkStonePermissions(),
      checkStoneProgress()
    ])
    if (expiredPermissions || expiredProgress) await resetStones()
  }

  async function checkStonePermissions() {
    const res = await getQcPermissionsList(qcPermissionsListParams)
    const lastUpdated = getCacheLastUpdated()
    return res.data.some(({ updatedAt }) => moment(lastUpdated).isBefore(updatedAt))
  }

  async function checkStoneProgress() {
    const res = await getQcProgressList(qcProgressListParams)
    const lastUpdated = getCacheLastUpdated()
    return res.data.some(({ updatedAt }) => moment(lastUpdated).isBefore(updatedAt))
  }

  function downloadPlanningDetails() {
    reportActions.getQcPlanningDetails('xlsx', { splitVersions: true })
    .then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))
  }
  function downloadReplanningDetails() {
    reportActions.getReplanningDetails('xlsx')
    .then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))
  }

  async function downloadAdvForId(reqId) {
    const request = {}
    if (hasStoneKey([STONE_KEYS.ROUGH])) {
      request.stone = roughsList?.find(x => x.id === reqId)
      request.fileId = request.stone?.galaxyFileId
      request.roughId = reqId
      request.params = { roughId: reqId }
    } else {
      request.stone = plannedStonesList?.data?.find(x => x.id === reqId)
      request.fileId = request.stone?.plannedAdvId
      request.roughId = request.stone?.roughId
      request.params = { spsRequestId: reqId }
    }
    if (request.stone) {
      const res = (await reportActions.getQcDownloadUrl([request.fileId], request.params)).data.data[0]
      FileSaver.saveAs(res.url, request.roughId)
    }
  }

  const [downloadFilesModalOpen, setDownloadFilesModalOpen] = useState({ selectedRows: [] })

  async function downloadMultipleFiles(rows) {
    for (const row of rows) {
      const request = {
        fileId: hasStoneKey([STONE_KEYS.ROUGH]) ? row.galaxyFileId : row.plannedAdvId,
        params: { [hasStoneKey([STONE_KEYS.ROUGH]) ? 'roughId' : 'spsRequestId']: row.id }
      }
      const response = await reportActions.getQcDownloadUrl([request.fileId], request.params)
      const file = response?.data?.data?.[0]
      FileSaver.saveAs(file.url)
    }
    setDownloadFilesModalOpen({ selectedRows: [] })
  }

  function getQcProgressLabel({ qcProgress, qcProgressUser }) {
    if (qcProgress === 'NEW') return qcProgressStatusesMap?.[qcProgress] ?? ''
    return qcProgressStatusesMap?.[qcProgress] ? `${qcProgressStatusesMap?.[qcProgress]} - ${qcProgressUser}` : ''
  }

  function getQcProgressHistory(qcProgress) {
    return setModal({
      id: 'stoneQcProgressHistory',
      title: 'QC Progress',
      customMessageRenderer: () => <QcProgressModal qcProgress={qcProgress} usersMap={usersMap} qcProgressStatusesMap={qcProgressStatusesMap} />,
      customButtonsRenderer: () => null
    })
  }

  async function handleUpdateQCStatuses(roughIds, qcStatus = 'APPROVED') {
    await roughStoneActions.updateMultipleQcRoughs(
      {
        roughIds,
        qcStatus
      },
      { errorSummary: errorUtils.getErrorSummary({ field: 'stones' }) }
    )
    .then(() => removeAllQcPlannedStonesListItems(
      roughIds,
      // Custom accessor for roughIds
      (plannedStone) => plannedStone?.Rough?.id
    ))
    .then(() => {
      showSuccessToast('QC Status(es) Updated')
    })
  }

  async function handleUpdatePlanning(roughIds, form) {
    await roughStoneActions.updateMultipleQcRoughs(
      {
        roughIds,
        ...form
      },
      { errorSummary: errorUtils.getErrorSummary({ field: 'stones' }) }
    )
    .then(() => {
      showSuccessToast('Rough Planning Updated')
    })
  }

  const genericOrdersParams = { buyerId: genericOrg, status: 'ORDER_SUBMITTED', columns: '[id]' }
  const {
    ordersList: { [JSON.stringify(genericOrdersParams)]: ordersList },
    getOrdersList
  } = useOrderStore(store => store)
  useEffect(() => {
    if (!genericOrg || !hasPermission(orderActions.getOrderList)) return
    getOrdersList(genericOrdersParams)
  }, [genericOrg, permissionsAdminCache])
  async function handleSendForGenericPlanning(roughIds, formData) {
    return planningActions.sendSpsRequests({ stoneIds: roughIds, orderIds: ordersList.data.map(x => x.id), planAllShapes: true, ...formData })
    .then(async res => {
      await removeQcRoughStonesListItems(getRoughStonesParams(FILTERS.UNPLANNED), roughIds)
      showSuccessToast(`${roughIds.length} stones have been submitted for planning.`)
    })
    .catch(err => console.log(err))
  }

  const columns = useMemo(() => {
    const cols = {
      planned: ['id', 'sellerName', 'sellerStoneName', 'assortment', 'mine', 'weight', 'colour', 'fluorescence', 'tinge', 'inclusionType', 'inclusionReductions', 'spsStatus', 'qcStatus', 'qcProgress', 'permissions', 'assignedTime', 'galaxyFile', 'updatedAt'],
      rough: ['id', 'sellerName', 'sellerStoneName', 'assortment', 'mine', 'weight', 'colour', 'fluorescence', 'tinge', 'inclusionType', 'inclusionReductions', 'galaxyFile', 'updatedAt']
    }
    const allColumns = {
      id: {
        Header: 'Clara ID',
        accessor: row => getAccessor(row, 'id'),
        dataType: 'string',
        filterType: 'textarea',
        ...(stoneKey === STONE_KEYS.PLANNED ? {
          Cell: cellInfo => {
            return (
              <NavLink
                className="link"
                to={`/qc/planning/${cellInfo.row.original.id}`}
                id={cellInfo.row.original.id}
              >
                {cellInfo.value}
              </NavLink>
            )
          }
        } : {})
      },
      sellerName: {
        Header: 'Seller',
        id: 'sellerName',
        accessor: row => orgsMap?.[getAccessor(row, 'sellerId')],
        dataType: 'string',
        hidden: !hasAdmin(organizationActions.getLimitedOrganizationList)
      },
      sellerStoneName: {
        Header: 'Seller Stone Name',
        id: 'sellerStoneName',
        accessor: row => getAccessor(row, 'sellerStoneName'),
        dataType: 'string'
      },
      assortment: {
        Header: 'Assortment',
        id: 'assortment',
        accessor: row => getAccessor(row, 'Assortment', 'name'),
        dataType: 'string',
        hidden: !hasAdmin(roughStoneActions.getQcRoughStoneList)
      },
      mine: {
        Header: 'Mine',
        id: 'mine',
        accessor: row => minesList?.all?.find(m => m.id === getAccessor(row, 'mineId'))?.name,
        dataType: 'string',
        filterType: 'checkbox',
        hidden: !hasAdmin(roughStoneActions.getQcRoughStoneList)
      },
      weight: {
        Header: 'Weight',
        accessor: row => getAccessor(row, 'weight'),
        dataType: 'number',
        filterType: 'numberRange'
      },
      colour: {
        Header: 'Colour',
        accessor: row => roughColoursMap?.[getAccessor(row, 'colour')] ?? null,
        dataType: 'string',
        filterType: 'checkbox',
        enums: Object.values(roughColoursMap).concat([null])
      },
      fluorescence: {
        Header: 'Flu',
        accessor: row => getAccessor(row, 'fluorescence'),
        dataType: 'number',
        filterType: 'numberRange'
      },
      tinge: {
        Header: 'Tinge',
        accessor: row => polishedTingesMap?.[getAccessor(row, 'tinge')] ?? null,
        dataType: 'string',
        filterType: 'checkbox',
        enums: Object.values(polishedTingesMap).concat([null])
      },
      inclusionType: {
        Header: 'Inclusion Type',
        accessor: row => inclusionTypesMap?.[getAccessor(row, 'inclusionsTypeId')] ?? '',
        id: 'InclusionType',
        dataType: 'string',
        filterType: 'checkbox',
        enums: Object.values(inclusionTypesMap)
      },
      inclusionReductions: {
        Header: 'Inclusion Reductions',
        accessor: row => inclusionReductionsMap?.[getAccessor(row, 'inclusionReductionsId')] ?? '',
        id: 'inclusionReductions',
        dataType: 'string',
        filterType: 'checkbox',
        enums: Object.values(inclusionReductionsMap)
      },
      spsStatus: {
        Header: 'SPS Status',
        id: 'status',
        accessor: (row) => spsRequestStatusesMap?.[row.status] ?? '',
        dataType: 'string',
        filterType: 'checkbox',
        enums: Object.values(spsRequestStatusesMap)
      },
      qcStatus: {
        Header: 'QC Approved',
        id: 'qcStatus',
        accessor: row => roughQCApprovedMap?.[getAccessor(row, 'qcStatus')] ?? '',
        dataType: 'string',
        filterType: 'checkbox',
        enums: getQcApprovedByTab(tabRequestParams)
      },
      qcProgress: {
        Header: 'QC Progress',
        id: 'qcProgress',
        accessor: row => qcProgressStatusesMap?.[row.qcProgress],
        dataType: 'string',
        filterType: 'checkbox',
        enums: Object.values(qcProgressStatusesMap),
        // hidden: !hasAdmin(qcActions.getQcProgress),
        Cell: cellInfo => (
          <a
            className="link"
            onClick={() => getQcProgressHistory(getAccessor(cellInfo.row.original, ['QcProgresses']))}
          >
            {getQcProgressLabel(cellInfo.row.original)}
          </a>
        )
      },
      permissions: {
        Header: 'Assignees',
        id: 'permissions',
        accessor: row => row.permissions.join(', '),
        dataType: 'string',
        hidden: !hasAdmin(qcActions.getQcPermissions)
      },
      assignedTime: {
        Header: 'Assigned Time',
        id: 'assignedTime',
        accessor: row => textUtils.formatDate(row.Rough?.QcPermissions?.[0]?.updatedAt, true) || 'N/A',
        filterType: 'date'
      },
      galaxyFile: {
        Header: 'ADV File',
        accessor: 'advLink',
        dataType: 'string',
        Cell: cellInfo => {
          if (dottie.get(cellInfo.row.original, [stoneKey === STONE_KEYS.ROUGH ? 'galaxyFileId' : 'plannedAdvId'])) {
            return (
              <a
                className="link"
                onClick={() => downloadAdvForId(cellInfo.row.original.id)}
              >
              ADV
              </a>
            )
          }
          return null
        }
      },
      updatedAt: {
        Header: 'Last Updated',
        id: 'updatedAt',
        accessor: 'updatedAt',
        dataType: 'dateTime',
        filterType: 'date'
      }
    }
    return cols?.[stoneKey]?.reduce((arr, col) => {
      const { hidden, ...restCol } = allColumns[col]
      if (!hidden) arr.push(restCol)
      return arr
    }, [])
  }, [orgsList, orgsMap, minesList, inclusionTypesMap, inclusionReductionsMap, plannedList, roughsList, roughColoursMap, roughQCApprovedMap, polishedTingesMap, qcProgressStatusesMap, spsRequestStatusesMap, usersMap, stoneKey, permissionsAdminCache])

  const tableTabs = useMemo(() => {
    if (!userRoles) return []
    return [
      {
        label: 'Pending QC',
        params: FILTERS.PENDING,
        roles: hasAdmin(planningActions.getQcPlannedStones)
      },
      {
        label: 'Sale Ready QC',
        params: FILTERS.SALE_READY,
        roles: hasAdmin(planningActions.getQcPlannedStones)

      },
      {
        label: 'All Planned Stones',
        params: FILTERS.SUBMITTED,
        roles: hasAdmin(planningActions.getQcPlannedStones)

      },
      {
        label: 'Pending Planning',
        params: FILTERS.UNPLANNED,
        roles: hasAdmin(roughStoneActions.getQcRoughStoneList)

      },
      {
        label: 'Recently Sold',
        params: FILTERS.SOLD,
        roles: hasAdmin(planningActions.getQcPlannedStones)

      },
      {
        id: 'New',
        label: <Badge label="New" count={plannedListByProgressStatus?.[FILTERS.NEW.key.toUpperCase()]?.length} classNames={['qc-badge']}/>,
        params: FILTERS.NEW,
        roles: !hasAdmin(planningActions.getQcPlannedStones) && hasPermission(planningActions.getQcPlannedStones)

      },
      {
        id: 'In Progress',
        label: 'In Progress',
        params: FILTERS.WIP,
        roles: !hasAdmin(planningActions.getQcPlannedStones) && hasPermission(planningActions.getQcPlannedStones)

      },
      {
        id: 'Done',
        label: 'Done',
        params: FILTERS.DONE,
        roles: !hasAdmin(planningActions.getQcPlannedStones) && hasPermission(planningActions.getQcPlannedStones)

      },
      {
        id: 'Reject',
        label: <Badge label="Recheck" count={plannedListByProgressStatus?.[FILTERS.REJECT.key.toUpperCase()]?.length} classNames={['qc-badge']}/>,
        params: FILTERS.REJECT,
        roles: !hasAdmin(planningActions.getQcPlannedStones) && hasPermission(planningActions.getQcPlannedStones)

      }
    ].filter(filterBy)
  }, [userRoles, permissionsAdminCache, plannedListByProgressStatus])

  const updatePlanningOption = useMemo(() => ({
    label: 'Update Inclusion Settings',
    callback: (selectedRows) => {
      return setModal({
        id: 'stonesListUpdatePlanning',
        title: 'Update Inclusion Settings',
        customMessageRenderer: ({
          handleCancel,
          handleSubmit
        }) => (
          <UpdateInclusionDetails
            handleCancel={handleCancel}
            handleSubmit={(res) => {
              if (res) {
                const inclusionDetails = objectUtils.removeNulls(res, { replacement: undefined, deep: true })
                const changedRows = selectedRows.filter(r =>
                  (inclusionDetails?.inclusionsTypeId != null && getAccessor(r, 'inclusionsTypeId') !== inclusionDetails.inclusionsTypeId)
                  || (inclusionDetails?.inclusionReductionsId != null && getAccessor(r, 'inclusionReductionsId') !== inclusionDetails.inclusionReductionsId)
                )
                if (!changedRows.length) showWarningToast('No stones have been updated.')
                else {
                  const roughIds = selectedRows.map(row => getAccessor(row, 'id'))
                  handleUpdatePlanning(roughIds, inclusionDetails).then(() => handleSubmit(roughIds))
                }
              }
            }}
          />
        ),
        onSubmit: (submitProps) => {
          removeAllQcRoughStonesListItems(submitProps?.[0])
          removeAllQcPlannedStonesListItems(
            submitProps?.[0],
            (plannedStone) => plannedStone?.Rough?.id
          )
        },
        closeOnFail: false,
        customButtonsRenderer: () => null
      })
    },
    value: 'roughPlanning'
  }), [stoneKey])
  const topBarActionsOptions = useMemo(() => {
    return [
      {
        label: 'Download ADVs',
        isDisabled: rows => rows.some(row => !(hasStoneKey([STONE_KEYS.ROUGH]) ? row.original?.galaxyFileId : row.original?.plannedAdvId)),
        callback: selectedRows => {
          setDownloadFilesModalOpen({ selectedRows })
        },
        value: 'downloadAdv'
      },
      {
        label: 'Set QC Approved',
        isDisabled: rows => rows.some(row => roughQCSellableMap?.[row.original?.Rough?.qcStatus]),
        callback: (selectedRows, { refreshTableData }) => handleUpdateQCStatuses(selectedRows.map(row => (row.Rough.id)), 'APPROVED').then(() => refreshTableData()),
        value: 'qcStatusApproved',
        roles: hasAdmin(roughStoneActions.updateMultipleQcRoughs),
        tabs: [FILTERS.PENDING, FILTERS.SALE_READY, FILTERS.SUBMITTED]
      },
      {
        label: 'Set QC Not Approved',
        isDisabled: rows => rows.some(row => !roughQCSellableMap?.[row.original?.Rough?.qcStatus]),
        callback: (selectedRows, { refreshTableData }) => handleUpdateQCStatuses(selectedRows.map(row => (row.Rough.id)), 'NOT_APPROVED').then(() => refreshTableData()),
        value: 'qcStatusNotApproved',
        roles: hasAdmin(roughStoneActions.updateMultipleQcRoughs),
        tabs: [FILTERS.PENDING, FILTERS.SALE_READY, FILTERS.SUBMITTED]
      },
      {
        label: 'Set QC Progress to New',
        callback: (selectedRows, { refreshTableData }) => qcActions.logQcProgress(selectedRows.map(row => ({ userId, roughId: row.Rough.id, status: 'NEW' }))).then(() => refreshTableData()),
        isDisabled: rows => rows.some(row => row.original?.qcProgressUser !== usersMap?.[userId]),
        value: 'qcProgressSetNew',
        roles: hasPermission(qcActions.logQcProgress) && !hasAdmin(qcActions.logQcProgress),
        tabs: [FILTERS.WIP, FILTERS.REJECT, FILTERS.DONE]
      },
      {
        label: 'Set QC Progress to In Progress',
        callback: (selectedRows, { refreshTableData }) => qcActions.logQcProgress(selectedRows.map(row => ({ userId, roughId: row.Rough.id, status: 'WIP' }))).then(() => refreshTableData()),
        value: 'qcProgressSetWIP',
        roles: hasPermission(qcActions.logQcProgress) && !hasAdmin(qcActions.logQcProgress),
        tabs: [FILTERS.NEW, FILTERS.REJECT, FILTERS.DONE]
      },
      {
        label: 'Set QC Progress to Recheck',
        callback: (selectedRows, { refreshTableData }) => qcActions.logQcProgress(selectedRows.map(row => ({ userId, roughId: row.Rough.id, status: 'REJECT' }))).then(() => refreshTableData()),
        value: 'qcProgressSetReject',
        roles: hasAdmin(qcActions.logQcProgress),
        tabs: [FILTERS.PENDING, FILTERS.SALE_READY, FILTERS.SUBMITTED]
      },
      {
        label: 'Set QC Progress to Done',
        callback: (selectedRows, { refreshTableData }) => qcActions.logQcProgress(selectedRows.map(row => ({ userId, roughId: row.Rough.id, status: 'DONE' }))).then(() => refreshTableData()),
        isDisabled: rows => rows.some(row => row.original?.qcProgressUser !== usersMap?.[userId]),
        value: 'qcProgressSetDone',
        roles: hasPermission(qcActions.logQcProgress) && !hasAdmin(qcActions.logQcProgress),
        tabs: [FILTERS.NEW, FILTERS.WIP, FILTERS.REJECT]
      },
      {
        ...updatePlanningOption,
        tabs: [FILTERS.PENDING, FILTERS.SALE_READY, FILTERS.UNPLANNED, FILTERS.SUBMITTED, FILTERS.NEW, FILTERS.WIP, FILTERS.REJECT]
      },
      {
        label: 'Send For Planning',
        callback: selectedRows => setModal({
          id: 'stonesListGenericPlanning',
          title: 'Send Rough Stones for Generic Planning',
          type: 'form',
          customMessageRenderer: ({ handleSubmit }) => <SendForPlanningForm ids={selectedRows} handleSubmit={handleSubmit}/>,
          customButtonsRenderer: () => null,
          onSubmit: (res) => handleSendForGenericPlanning(selectedRows?.map(row => (row.id)), res[0]),
          closeOnFail: false
        }),
        value: 'genericPlanning',
        roles: hasPermission(planningActions.sendSpsRequests),
        tabs: [FILTERS.UNPLANNED]
      },
      {
        label: 'Assign Stones',
        callback: async (selectedRows) => {
          await new Promise((resolve, reject) => setModal({
            id: 'assignUsersToStones',
            title: 'Assign Stones',
            type: 'form',
            className: 'assign-stones-modal',
            customMessageRenderer: (messageRendererProps) => (
              <AssignStones usersList={qcUsersList} selectedRows={selectedRows.map(row => getAccessor(row))} {...messageRendererProps} />
            ),
            onSubmit: (submitProps) => {
              showSuccessToast(`${submitProps?.[0].length} stone${submitProps?.[0].length > 1 ? 's were' : ' was'} successfully updated.`)
              resolve()
            },
            onCancel: reject,
            customButtonsRenderer: () => null,
            closeOnFail: false
          }))
          await refreshStones(true)
        },
        value: 'assignStones',
        roles: hasPermission(qcActions.saveQcPermissions),
        tabs: [FILTERS.PENDING, FILTERS.SALE_READY]
      },
      {
        label: 'Excel Update',
        callback: (selectedRows) => history.push('/qc/roughstones/upload', { roughId: selectedRows.map(row => getAccessor(row, 'id')) }),
        isDisabled: rows => rows.some(row => row.original.condition !== 'ACTIVE'),
        value: 'multiRoughUpload',
        roles: hasPermission(roughStoneActions.updateMultipleQcRoughs),
        tabs: [FILTERS.PENDING, FILTERS.SALE_READY, FILTERS.SUBMITTED, FILTERS.UNPLANNED, FILTERS.NEW, FILTERS.WIP, FILTERS.REJECT]
      }
    ]?.filter(filterBy)
  }, [tabRequestParams?.key, updatePlanningOption, permissionsAdminCache, qcUsersList, genericOrg])
  const data = useMemo(() => {
    if (hasStoneKey([STONE_KEYS.ROUGH])) return roughsList
    else if (QC_USER_TAB_KEYS.includes(tabRequestParams?.key)) return plannedListByProgressStatus?.[tabRequestParams?.key?.toUpperCase()]
    else return plannedList
  }, [tabRequestParams?.key, roughsList, plannedList])

  const topBarButtons = useMemo(() => ([
    {
      callback: () => downloadPlanningDetails(),
      label: 'Planning Details Report',
      roles: hasPermission(reportActions.getQcPlanningDetails)
    },
    {
      callback: () => downloadReplanningDetails(),
      label: 'Replanning Changes Report',
      roles: hasPermission(reportActions.getReplanningDetails)
    },
    {
      label: 'Upload Multiple ADV',
      callback: () => setModal({
        id: 'assortmentRoughStoneListAdvFileUpload',
        title: 'Upload Multiple ADV',
        customMessageRenderer: ({
          handleClose,
          handleSubmit,
          handleCancel
        }) => (
          <UpdateAdvDetails
            progressListName='roughStoneListAdvFileUpload'
            handleClose={handleClose}
            handleAdvUploadSubmit={({ id } = {}) => handleSubmit([id])}
            handleCancel={handleCancel}
            stonesList={data?.map((x) => getAccessor(x))}
          />
        ),
        onSubmit: (submitProps) => {
          removeAllQcRoughStonesListItems(submitProps?.[0])
          removeAllQcPlannedStonesListItems(
            submitProps?.[0],
            (plannedStone) => plannedStone?.Rough?.id
          )
        },
        customButtonsRenderer: () => <></>
      }),
      tabs: [FILTERS.PENDING, FILTERS.SALE_READY, FILTERS.SUBMITTED, FILTERS.UNPLANNED, FILTERS.NEW, FILTERS.WIP, FILTERS.REJECT]
    },
    {
      callback: () => history.push('/qc/roughstones/upload'),
      label: 'Excel Update',
      // For now this is only available to admin and clara.qc
      roles: hasPermission(roughStoneActions.updateMultipleQcRoughs),
      tabs: [FILTERS.PENDING, FILTERS.SALE_READY, FILTERS.SUBMITTED, FILTERS.UNPLANNED, FILTERS.NEW, FILTERS.WIP, FILTERS.REJECT]
    }
  ]?.filter(filterBy)), [tabRequestParams?.key, data])

  return (
    <>
      <Table
        title='SPS Requests'
        data={data}
        columns={columns}
        resetDataCallback={resetCaches}
        getDataCallback={(params) => setTabRequestParams({ ...params })}
        tableTabs={tableTabs}
        initialPageSize={100}
        isMultiSelect={true}
        topBarActions={[
          {
            componentName: 'dropdown',
            enableOnSelect: true,
            options: topBarActionsOptions
          },
          ...topBarButtons
        ]}
      />
      <ConfirmationModal
        open={downloadFilesModalOpen.selectedRows.length > 0}
        title={'Download ADV Files'}
        message={
          <>
            Are you sure you want to download {downloadFilesModalOpen.selectedRows.length} ADV files?
            <br/><br/>
            (Please note, your browser may block downloading multiple files at once and you will have to allow this action.)
          </>
        }
        onClose={() => setDownloadFilesModalOpen({ selectedRows: [] })}
        onSubmit={() => {
          downloadMultipleFiles(downloadFilesModalOpen.selectedRows)
        }}
      >
      </ConfirmationModal>
    </>
  )
}

export default QCStonesList
