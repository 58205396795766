import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { pricePointActions, roughStoneActions, weightCategoryActions } from '@actions'
import { useAuthStore, useGlobalsStore } from '@stores'
import { SELLER } from '@constants'
import { arrayUtils, errorUtils, textUtils } from '@utils'
import dottie from 'dottie'
import { Dropdown } from '@molecules'
import { Button } from '@atoms'

function MultiRoughsUpdateModal({
  handleSubmit,
  handleCancel,
  roughStonesList
}) {
  const [fieldName, setFieldName] = useState()
  const [fieldValue, setFieldValue] = useState()
  const [weightCategories, setWeightCategories] = useState()
  const [pricePoints, setPricePoints] = useState()

  const sellerIds = useMemo(() => new Set(arrayUtils.pickBy(roughStonesList, 'sellerId')), [roughStonesList])

  const { permissionsAdminCache, hasPermission, hasAdmin } = useAuthStore()
  const {
    getRoughScanTypes,
    getRoughTensions,
    getInclusionTypes,
    getInclusionReductions,
    getRoughTypes,
    roughScanTypes: scanTypes,
    roughTensions,
    inclusionTypes,
    inclusionReductions,
    roughTypes
  } = useGlobalsStore(store => store)

  useEffect(() => {
    getRoughScanTypes()
    getRoughTensions()
    getInclusionTypes()
    getInclusionReductions()
    getRoughTypes()
  }, [])
  useEffect(() => {
    if (!permissionsAdminCache.size) return
    if (sellerIds.size === 1) {
      const sellerId = sellerIds.values().next().value
      weightCategoryActions.getWeightCategories(sellerId)
      .then(response => setWeightCategories(response.data.data))
      .catch(err => console.error(err))

      pricePointActions.getPricePoints(sellerId)
      .then(response => setPricePoints(response.data.data))
      .catch(err => console.error(err))
    }
  }, [roughStonesList, permissionsAdminCache])

  const fieldValues = useMemo(() => ({
    weightCategory: weightCategories?.map(w => ({ description: w, value: w })),
    pricePointName: pricePoints?.map(p => ({ description: p, value: p })),
    scanType: scanTypes,
    inclusionType: inclusionTypes?.filter(it => hasAdmin(roughStoneActions.getInclusionTypes) || it.autoInclusions)?.map(i => ({ description: i.description, value: i.id })),
    inclusionReductions: inclusionReductions?.map(i => ({ description: i.description, value: i.id })),
    location: SELLER.ROUGH_LOCATIONS?.map(l => ({ description: l, value: l })),
    type: roughTypes,
    tension: roughTensions,
    yellowUv: [{ description: 'Yes', value: true }, { description: 'No', value: false }]
  }), [permissionsAdminCache, weightCategories, pricePoints, scanTypes, inclusionTypes, inclusionReductions, roughTypes, roughTensions])
  const fieldNameOptions = useMemo(() => SELLER.MULTI_ROUGHS_UPDATE_FIELDS
    .sort((a, b) => textUtils.compareIgnoreCase(a?.header, b?.header))
    .reduce((_opts, _opt) => {
      if (_opt.permissions?.some(p => p.admin ? hasAdmin(roughStoneActions[p.action]) : hasPermission(roughStoneActions[p.action]))) {
        _opts.push({
          value: _opt.key,
          label: _opt.header,
          isDisabled: ['weightCategory', 'pricePointName'].includes(_opt.key) && sellerIds.size > 1
        })
      }
      return _opts
    }, []), [permissionsAdminCache, sellerIds])
  const fieldValueOptions = useMemo(() => (fieldValues[fieldName] || []).map(v => ({ label: v.description, value: v.value })), [fieldName])

  function onChange(e) {
    const target = e.currentTarget || e.target
    if (target?.name === 'fieldName') {
      setFieldName(target.value)
      setFieldValue()
    }
    if (target?.name === 'fieldValue') setFieldValue(target.value)
  }
  function onSubmit() {
    let editFieldName = fieldName
    if (fieldName === 'pricePointName') editFieldName = 'pricePoint'
    if (fieldName === 'inclusionType') editFieldName = 'inclusionsTypeId'
    if (fieldName === 'inclusionReductions') editFieldName = 'inclusionReductionsId'
    if (fieldName === 'tension') editFieldName = 'otherAttributes.tension'
    if (fieldName === 'yellowUv') editFieldName = 'otherAttributes.yellowFluorescence'
    const edit = { [editFieldName]: fieldValue, roughIds: roughStonesList.filter(r => fieldValue !== dottie.get(r, editFieldName)).map(({ id }) => id) }
    return handleSubmit(dottie.transform(edit), { errorSummary: errorUtils.getErrorSummary({ field: 'stones' }) })
  }

  return (
    <div className="form__container">
      <Dropdown
        name='fieldName'
        label='Parameter'
        value={fieldName}
        required={true}
        options={fieldNameOptions}
        isClearable={false}
        onChange={onChange}
      />
      <Dropdown
        name='fieldValue'
        label='Value'
        value={fieldValue}
        required={true}
        options={fieldValueOptions}
        disabled={!fieldName}
        creatable={fieldName === 'weightCategory' || fieldName === 'pricePointName'}
        onChange={onChange}
      />
      <div key='row-buttons' className='modal__buttons'>
        <Button typeVariant='secondary' size='sm' onClick={handleCancel}>Cancel</Button>
        <Button onClick={onSubmit} typeVariant='primary' size='sm'>Save</Button>
      </div>
    </div>
  )
}

MultiRoughsUpdateModal.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  roughStonesList: PropTypes.arrayOf(PropTypes.object)
}

MultiRoughsUpdateModal.defaultProps = {
  roughStonesList: []
}

export default MultiRoughsUpdateModal
