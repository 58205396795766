import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@templates'
import { useModalStore } from '@stores'
import { Button, Checkbox } from '@atoms'

function SubmitOrderConfirmationModal({
  open,
  onClose,
  onSubmit,
  customTitle,
  customMessage
}) {
  const { setTermsAndConditionsModalIsOpen } = useModalStore(state => state)
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false)

  useEffect(() => {
    if (!open && termsAndConditionsAccepted) {
      setTermsAndConditionsAccepted(false)
    }
  }, [open])

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={customTitle || 'Confirm Terms and Conditions'}
      className="submit-order-confirmation-modal"
    >
      {customMessage
        && <div className="submit-order-confirmation-modal__message">{customMessage}</div>
      }
      <div className="submit-order-confirmation-modal__input-container">
        <Checkbox
          name='termsAndConditions'
          value={termsAndConditionsAccepted}
          onChange={() => setTermsAndConditionsAccepted(!termsAndConditionsAccepted)}
        />
        <div className="submit-order-confirmation-modal__input-label">
          By checking this box and submitting the order you hereby confirm your agreement to Clara’s terms and conditions, the full text of which can be found
          <a onClick={() => setTermsAndConditionsModalIsOpen(true)}> here</a>.
        </div>
      </div>
      <div className='modal__buttons'>
        <Button typeVariant='primary' size='sm' onClick={onSubmit} disabled={!termsAndConditionsAccepted}>OK</Button>
      </div>
    </Modal>
  )
}

SubmitOrderConfirmationModal.propTypes = {
  open: PropTypes.bool,
  customTitle: PropTypes.string,
  customMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

SubmitOrderConfirmationModal.defaultProps = {
  open: false
}
export default SubmitOrderConfirmationModal
