import React, { useState, useEffect, useMemo } from 'react'
import AddressForm from './addressForm'
import { randomBytes } from 'crypto'
import { Button } from '@atoms'
import { Form } from '@organisms'
import { useToast } from '@hooks'
import { Dropdown, TextArea } from '@molecules'
import { ORG } from '@constants'
import { objectUtils } from '@utils'
import { useGlobalsStore } from '@stores'

function AddressStep(props) {
  const {
    setFormLocalState,
    getFormLocalState
  } = props.useMultiSteps(props)
  const address = props.getState('address')
  const nextButtonDisabled = getFormLocalState('nextButtonDisabled')
  const [openAddress, setOpenAddress] = useState()

  const appSettingsParams = { groupKey: 'INVOICE_PAYMENT_DETAILS' }

  const {
    appSettings: { [JSON.stringify(appSettingsParams)]: invoicePaymentDetails },
    getAppSettings
  } = useGlobalsStore(store => store)

  useEffect(() => {
    getAppSettings(appSettingsParams)
  }, [])

  const [defaultClaraInvoicePayment, defaultLucaraInvoicePayment] = useMemo(() => {
    const invoiceDefaults = []
    for (const paymentDetail of (invoicePaymentDetails || [])) {
      if (paymentDetail.claraDefault) invoiceDefaults[0] = paymentDetail.key
      if (paymentDetail.lucaraDefault) invoiceDefaults[1] = paymentDetail.key
    }
    return invoiceDefaults
  }, [invoicePaymentDetails])

  useEffect(() => {
    // Initialize address
    if (address?.length || !defaultClaraInvoicePayment || !defaultLucaraInvoicePayment) return
    addNewAddress()
  }, [address, defaultClaraInvoicePayment, defaultLucaraInvoicePayment])

  const {
    showErrorToast
  } = useToast()

  const isNextButtonDisabled = useMemo(() => Object.values(getFormLocalState('nextButtonDisabled') ?? {}).some(x => x), [props?.state?.address])

  function addNewAddress() {
    const newAddress = {
      _id: randomBytes(16).toString('hex'),
      invoicePayment: {
        clara: defaultClaraInvoicePayment,
        lucara: defaultLucaraInvoicePayment
      }
    }
    props.handleChange({
      currentTarget: {
        name: 'address',
        value: (address || []).concat(newAddress)
      }
    })
    setOpenAddress(newAddress._id)
  }

  function handleGoNext() {
    // validate address types
    const addressTypeValidation = validateAddressType(address)
    if (addressTypeValidation.error) {
      // if not valid display an error
      showErrorToast(addressTypeValidation.blankFields[0])
    } else {
      // if valid, go next page
      props.next()
    }
  }

  function validateAddressType(addresses = []) {
    // Ensure there's one and only one address of each type (Billing & Shipping)
    let billingCount = 0
    let shippingCount = 0
    for (const addr of addresses) {
      if (addr.shipping) shippingCount++
      if (addr.billing) billingCount++
    }
    const validation = {
      error: false,
      blankFields: []
    }
    if (billingCount !== 1 || shippingCount !== 1) {
      validation.error = true
      validation.blankFields.push('Your Organization profile should have only one billing address and only one shipping address selected at any point in time')
    }

    const entityNames = addresses.map(addr => addr.name)
    if (entityNames.length !== new Set(entityNames).size) {
      validation.error = true
      validation.blankFields.push('Entity name must be unique.')
    }

    return validation
  }

  return (
    <Form title={'Addresses'}>
      {address?.length
        && address.map((addr) => {
          return (
            <AddressForm
              key={addr._id}
              formData={addr}
              onAdd={addNewAddress}
              onRemove={() => {
                setFormLocalState({ nextButtonDisabled: objectUtils.omit(nextButtonDisabled, [addr._id], true) })
                props.handleChange({ currentTarget: { name: 'address', value: address.filter(({ _id }) => _id !== addr._id) } })
              }}
              onChange={(value, isValid) => {
                setFormLocalState({ nextButtonDisabled: objectUtils.deepMerge(nextButtonDisabled, { [addr._id]: !isValid }) })
                const idx = address.findIndex(({ _id }) => _id === addr._id)
                props.setState('address', address.slice(0, idx).concat(value).concat(address.slice(idx + 1)))
              }}
              isOpen={openAddress === addr._id}
              canRemove={address.length > 1}
            />
          )
        })}
      <hr />
      <Dropdown
        label="Preferred Shipping"
        name="preferredShipping"
        required={false}
        value={props.getState('preferredShipping')}
        onChange={props.handleChange}
        options={ORG.PREFERRED_SHIPPING}
      />
      <TextArea
        label="Invoice Notes"
        name="invoiceNotes"
        value={props.getState('invoiceNotes')}
        onChange={props.handleChange}
        required={false}
      />
      <TextArea
        label="Compliance Comments"
        name="comments"
        value={props.getState('comments')}
        onChange={props.handleChange}
        required={false}
      />
      <Dropdown
        label="Risk Level"
        name="riskLevel"
        value={props.getState('riskLevel', '')}
        onChange={props.handleChange}
        required={false}
        options={ORG.RISK_LEVEL}
      />
      <Button
        disabled={!props.hasPrev()}
        typeVariant="action"
        size="sm"
        onClick={() => props.prev()}
      >
        Previous
      </Button>
      <Button
        disabled={!props.hasNext() || isNextButtonDisabled}
        typeVariant="primary"
        size="sm"
        onClick={() => handleGoNext()}
      >
        Next
      </Button>
    </Form>
  )
}

export default AddressStep
