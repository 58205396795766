import React, { useEffect } from 'react'
import { Dropdown } from '@molecules'
import { useGlobalsStore, useOrderStore } from '@stores'
import { ToggleSwitch } from '@atoms'

function OrderPricingRangesSettings({ maxColour, minColour, maxClarity, minClarity, maxFluorescence, minFluorescence, onChange, disabled }) {
  const {
    polishedColours: colours,
    clarities,
    polishedFluorescences,
    getPolishedColours,
    getClarities,
    getPolishedFluorescences
  } = useGlobalsStore(state => state)
  useEffect(() => {
    getPolishedColours()
    getClarities()
    getPolishedFluorescences()
  }, [])
  const { pmColours, setPmColours } = useOrderStore(state => state)

  return (
    <div className='order-pricing-ranges-settings'>
      <div className="order-pricing-ranges-settings__row">
        <Dropdown
          label='Max Color'
          name='maxColour'
          isClearable={false}
          options={colours?.map((colour, index) => ({
            value: colour.value,
            label: colour.description,
            isDisabled: index > colours.findIndex(x => x.value === minColour)
          }))}
          value={maxColour}
          onChange={onChange}
          disabled={disabled}
        />
        <Dropdown
          label='Min Color'
          name='minColour'
          isClearable={false}
          options={colours?.map((colour, index) => ({
            value: colour.value,
            label: colour.description,
            isDisabled: index < colours.findIndex(x => x.value === maxColour)
          }))}
          value={minColour}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
      <div className="order-pricing-ranges-settings__row">
        <Dropdown
          label='Max Clarity'
          name='maxClarity'
          isClearable={false}
          options={clarities?.map((clarity, index) => ({
            value: clarity.value,
            label: clarity.description,
            isDisabled: index > clarities.findIndex(x => x.value === minClarity)
          }))}
          value={maxClarity}
          onChange={onChange}
          disabled={disabled}
        />
        <Dropdown
          label='Min Clarity'
          name='minClarity'
          isClearable={false}
          options={clarities?.map((clarity, index) => ({
            value: clarity.value,
            label: clarity.description,
            isDisabled: index < clarities.findIndex(x => x.value === maxClarity)
          }))}
          value={minClarity}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
      <div className="order-pricing-ranges-settings__row">
        <Dropdown
          label='Min Fluor'
          name='minFluorescence'
          isClearable={false}
          options={polishedFluorescences?.filter(flu => flu.value === 'N').map(flu => Object.assign({
            value: flu.value,
            label: flu.description + (flu.min !== undefined && flu.max !== undefined ? ` (${flu.min}-${flu.max})` : '')
          }))}
          value={minFluorescence}
          onChange={onChange}
          disabled={true}
        />
        <Dropdown
          label='Max Fluor'
          name='maxFluorescence'
          isClearable={false}
          options={polishedFluorescences?.map(flu => ({
            value: flu.value,
            label: flu.description + (flu.min !== undefined && flu.max !== undefined ? ` (${flu.min}-${flu.max})` : '')
          }))}
          value={maxFluorescence}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
      <div className="order-pricing-ranges-settings__row">
        <ToggleSwitch
          id='showPlusMinusColours'
          name='showPlusMinusColours'
          label='Show +/- color'
          value={pmColours}
          disabled={disabled}
          onChange={e => setPmColours(e.target.checked)}
        />
      </div>
    </div>
  )
}

export default OrderPricingRangesSettings
