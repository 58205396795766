import React from 'react'
import PropTypes from 'prop-types'
import { FormComponents } from '@organisms'
import { eventActions } from '@actions'
import { useToast } from '@hooks'

function CreateEventType({
  onCancel,
  onSubmitSuccess
}) {
  const {
    SimpleForm,
    TextInput,
    TextArea,
    Button
  } = FormComponents

  const { showSuccessToast } = useToast()

  function onSubmit(event) {
    eventActions.createEventType(event)
    .then(() => {
      showSuccessToast('Event Type Created.')
      onSubmitSuccess()
    })
  }

  return (
    <SimpleForm
      title='New Event Type'
      onSubmit={(e) => onSubmit(e)}
    >
      <TextInput
        name='name'
        label='Name'
      />
      <TextArea
        name='description'
        label='Description'
      />
      <Button
        typeVariant='action'
        size='sm'
        onClick={() => onCancel()}
      >
        Cancel
      </Button>
      <Button
        type='submit'
        size='sm'
      >
        Create Event Type
      </Button>
    </SimpleForm>
  )
}

CreateEventType.propTypes = {
  onCancel: PropTypes.func,
  onSubmitSuccess: PropTypes.func
}

export default CreateEventType
