import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@atoms'
import InfoTip from '../infoTip/infoTip'

function ErrorIcon({ infoTip, ...restProps }) {
  const { error, warning } = restProps
  function renderIcon(type, message) {
    return infoTip ? (
      <InfoTip
        name={`errorIcon-${JSON.stringify(message)}`}
        icon={type}
        size={type}
        iconClass={type}>
        {message}
      </InfoTip>
    )
      : (<Icon
        size={type}
        name={type}
        className={type}
      />)
  }
  if (error) {
    return renderIcon('error', error)
  }
  if (warning) {
    return renderIcon('warning', warning)
  }
  return null
}

ErrorIcon.propTypes = {
  infoTip: PropTypes.bool,
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

ErrorIcon.defaultProps = {
  infoTip: false
}

export default ErrorIcon
