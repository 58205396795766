import { reportActions } from './reports_actions'
import FileSaver from 'file-saver'
import { fileUtils } from '@utils'

import { COMMON } from '@constants'

const { GET_REPORT, REDIRECT } = COMMON.NOTIFICATION.ACTION.TYPE

function redirect(props) {
  return function (args) {
    if (!args) return console.error('Missing redirect args.')
    if (typeof args !== 'string' && !(typeof args === 'object' && 'pathname' in args)) return console.error('Invalid redirect args.')
    props.history.push(args)
  }
}

function getReport(props) {
  return async function (args) {
    let [reportId, fileId] = args
    try {
      if (!fileId) {
        const report = (await (props.hasPermission(reportActions.listReports) ? reportActions.listReports : reportActions.listUserReports)({ id: reportId })).data.data[0]
        fileId = report.fileId
      }
      const response = await reportActions.getDownloadUrl([fileId], { savedReportId: reportId })
      const file = response.data.data[0]
      const downloaded = await reportActions.getBinaryFile(file.url)
      FileSaver.saveAs(downloaded.data, fileUtils.getFileName(file))
    } catch (err) {
      console.error(err)
    }
  }
}

export const notificationButtonActions = {
  [GET_REPORT.key]: getReport,
  [REDIRECT.key]: redirect
}
