import React from 'react'
import PropTypes from 'prop-types'
import HelperText from '../input/helperText'

function ErrorText({ warning, error }) {
  function toArray(val) {
    if (typeof val === 'string') return [error]
    if (Array.isArray(val)) return Array.from(new Set(val))
    if (typeof val === 'object') return toArray(Object.values(val).flat())
    return []
  }

  if (error) {
    return (toArray(error).map((valText, idx) =>
      <HelperText
        key={idx}
        text={valText}
        type='error'
      />
    ))
  }
  if (warning) {
    return (toArray(warning).map((valText, idx) =>
      <HelperText
        key={idx}
        text={valText}
        type='warning'
      />
    ))
  }
  return null
}

ErrorText.propTypes = {
  warning: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string])
}

ErrorText.defaultProps = {

}

export default ErrorText
