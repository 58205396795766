import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Collapse from 'react-collapse'
import { Icon } from '@atoms'
function Collapsible({
  children,
  title,
  type,
  onClick,
  disabled,
  isOpenedOnRender,
  ...props
}) {
  const [isOpened, setIsOpened] = useState(false)

  useEffect(() => {
    setIsOpened(props.isOpened)
  }, [props.isOpened])

  useEffect(() => {
    setIsOpened(isOpenedOnRender)
  }, [isOpenedOnRender])

  useEffect(() => {
    if (disabled) {
      setIsOpened(false)
    }
  }, [disabled])

  return (
    <div className='collapsible'>
      <div
        className={`collapsible__header${
          disabled ? '--disabled'
            : type ? `--${type}` : ''}`}
        onClick={(e) => {
          if (onClick) onClick(e)
          setIsOpened(!isOpened)
        }}
      >
        <span className="collapsible__title">{title}</span>
        {/* Icon will be replaced with Icon component */}
        {/* Icon component is being introduced on CD-1230 */}
        {!isOpened && <div className="collapsible__arrow">
          <Icon
            name='chevronRight'
            size='sm'
          />
        </div>}
        {isOpened && <div className="collapsible__arrow">
          <Icon
            name='chevronDown'
            size='sm'
          />
        </div>}
      </div>
      <Collapse
        theme={{ content: 'collapsible__content' }}
        isOpened={isOpened}
      >
        {children}
      </Collapse>
    </div>
  )
}

Collapsible.propTypes = {
  isOpened: PropTypes.bool,
  disabled: PropTypes.bool,
  isOpenedOnRender: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  type: PropTypes.string
}

Collapsible.defaultProps = {
  isOpened: false,
  disabled: false,
  isOpenedOnRender: false,
  title: 'Title',
  type: ''
}
export default Collapsible
