import React from 'react'
import { Tabs } from '@templates'
import AppNotificationList from './appNotificationList'
import EmailNotificationList from './emailNotificationList'

function NotificationList() {
  const tabItems = [
    {
      title: 'App Notifications',
      Component: AppNotificationList
    },
    {
      title: 'Email Notifications',
      Component: EmailNotificationList
    }
  ]

  return (
    <Tabs
      name='notifications'
      items={tabItems}
    />
  )
}

export default NotificationList
