import React from 'react'
import { Redirect } from 'react-router-dom'
import SigninForm from './signin_form'
import images from '../../../assets/images'
import ForgotPasswordForm from './forgot_password_form'
import ChangePasswordForm from './change_password_form'
import ConfirmCodeForm from './confirm_code_form'
import { useSigninStore, useAuthStore } from '@stores'

const SignIn = () => {
  const {
    isAuthenticated
  } = useAuthStore(state => state)

  const {
    displayMode
  } = useSigninStore(state => state)

  const SigninFormComponent = {
    signin: SigninForm,
    confirmCode: ConfirmCodeForm,
    changePassword: ChangePasswordForm,
    forgotPassword: ForgotPasswordForm
  }[displayMode] || SigninForm

  return (
    isAuthenticated ? <Redirect to='dashboard' />
      : (
        <div className="signin">
          <div className="signin__hero-container">
            <div className="signin__logo">
              <img src={images.claraLogo} alt="Clara logo" />
              <div className="signin__subtext">
                <span>The future of the rough diamond marketplace</span>
              </div>
            </div>
            <div className="signin__hero-container-img">
              <img src={images.heroBg} alt="hero background" />
            </div>
          </div>
          <div className="signin__container">
            <div className="signin__form-container">
              <div className="signin__form-logo">
                <img src={images.claraLogo} alt="Clara logo" />
                <div className="signin__form-subtext">
                  <span>The future of the rough diamond marketplace</span>
                </div>
              </div>
              <SigninFormComponent />
            </div>
          </div>
        </div>
      )
  )
}

export default SignIn
