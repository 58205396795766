import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

function DragAndDrop({
  title,
  onDrop,
  isMulti,
  onChange,
  acceptTypes
}) {
  const [isDragOver, setIsDragOver] = useState(false)
  const fileInputRef = useRef()
  const fileTypes = acceptTypes.map(fileType => fileType).join(', ')

  function handleOnDragOver(e) {
    e.preventDefault()
    if (!isDragOver) {
      setIsDragOver(true)
    }
  }

  function handleOnDragLeave(e) {
    e.preventDefault()
    if (isDragOver) {
      setIsDragOver(false)
    }
  }

  function handleOnDrop(e) {
    const files = filterNotSupportedFiles([...e.dataTransfer.files])
    setIsDragOver(false)
    e.preventDefault()
    if (onDrop) onDrop([...files])
  }

  function handleOnChange(e) {
    const files = e.currentTarget.files
    setIsDragOver(false)
    e.preventDefault()
    if (onChange && files?.length) onChange([...files])
  }

  function filterNotSupportedFiles(files) {
    if (!acceptTypes.length) return files
    return files.filter(file => acceptTypes.some(type => String(file.name).slice(String(file.name).lastIndexOf('.'), String(file.name).length) === type))
  }

  return (
    <div
      onDragOver={handleOnDragOver}
      onDragLeave={handleOnDragLeave}
      onDrop={handleOnDrop}
      className={`drag-and-drop${isDragOver ? '--is-drag-over' : ''}`}
    >
      <span
        className='drag-and-drop__title'
      >
        {title}
      </span>
      <span
        className='drag-and-drop__file-types'
      >
        {`Accepted file types: ${fileTypes.length ? fileTypes : 'All'}`}
      </span>
      <input
        ref={fileInputRef}
        className='drag-and-drop__file-input'
        type="file"
        name="dragAndDropInput"
        id="dragAndDropInput"
        multiple={isMulti}
        accept={fileTypes}
        onChange={handleOnChange}
      />
      <span className='p2'>Drag and drop files here</span>
      <span className='p2'>or</span>
      <label
        htmlFor="dragAndDropInput"
        className="link cursor-pointer"
      >
        Browse files
      </label>
    </div>
  )
}

DragAndDrop.propTypes = {
  isMulti: PropTypes.bool,
  onDrop: PropTypes.func,
  title: PropTypes.string,
  onChange: PropTypes.func,
  acceptTypes: PropTypes.arrayOf(PropTypes.string)
}

DragAndDrop.defaultProps = {
  title: '',
  isMulti: true,
  acceptTypes: []
}

export default DragAndDrop
