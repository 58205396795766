import axios from 'axios'
const BASE_URL = process.env.REACT_APP_API_BASE_URL

// Provenance actions
const getProvenance = function (params) {
  return axios.get(`${BASE_URL}/seller/provenance`, {
    params
  })
}
getProvenance.endpoint = 'GET|/seller/provenance'

function setProvenanceTypes(data) {
  const { orgId, ...editUserData } = data
  return axios.put(`${BASE_URL}/seller/organizations/${orgId}/provenance`, editUserData)
}
setProvenanceTypes.endpoint = 'PUT|/seller/organizations/:orgId:/provenance'

// Countries actions
const getCountries = function (params) {
  return axios.get(`${BASE_URL}/seller/countries`, {
    params
  })
}
getCountries.endpoint = 'GET|/seller/countries'

const getCountryById = function(countryId) {
  return getCountries()
  .then(response => {
    const country = response?.data?.data?.find(country => country.id === countryId)
    if (!country) return Promise.reject(new Error('Country not found.'))
    return country
  })
}
getCountryById.endpoint = 'GET|/seller/countries'

const getMines = function (params) {
  return axios.get(`${BASE_URL}/seller/mines`, {
    params
  })
}
getMines.endpoint = 'GET|/seller/mines'

const getMineById = function(mineId) {
  return getMines()
  .then(response => {
    const mine = response?.data?.data?.find(mine => mine.id === mineId)
    if (!mine) return Promise.reject(new Error('Mine not found.'))
    return mine
  })
}
getMineById.endpoint = 'GET|/seller/mines'

const getPipes = function (params) {
  return axios.get(`${BASE_URL}/seller/pipes`, {
    params
  })
}
getPipes.endpoint = 'GET|/seller/pipes'

const getPipeById = function (pipeId) {
  return getPipes()
  .then(response => {
    const pipe = response?.data?.data?.find(pipe => pipe.id === pipeId)
    if (!pipe) return Promise.reject(new Error('Pipe not found.'))
    return pipe
  })
}
getPipeById.endpoint = 'GET|/seller/pipes'

const getBatches = function (params) {
  return axios.get(`${BASE_URL}/seller/batches`, { params })
}
getBatches.endpoint = 'GET|/seller/batches'

const getBatchById = function (batchId) {
  return getBatches()
  .then(response => {
    const batch = response?.data?.data?.find(batch => batch.id === batchId)
    if (!batch) return Promise.reject(new Error('Batch not found.'))
    return batch
  })
}
getBatchById.endpoint = 'GET|/seller/batches'

const createCountry = function (data) {
  return axios.post(`${BASE_URL}/seller/countries`, data)
}
createCountry.endpoint = 'POST|/seller/countries'

function setCountries(data) {
  const { orgId, ...editUserData } = data
  return axios.put(`${BASE_URL}/seller/organizations/${orgId}/countries`, editUserData)
}
setCountries.endpoint = 'PUT|/seller/organizations/:orgId:/countries'

const createPipe = function (data) {
  return axios.post(`${BASE_URL}/seller/pipes`, data)
}
createPipe.endpoint = 'POST|/seller/pipes'

const createBatch = function (data) {
  return axios.post(`${BASE_URL}/seller/batches`, data)
}
createBatch.endpoint = 'POST|/seller/batches'

const editCountry = function({ id, ...changes }) {
  return axios.patch(`${BASE_URL}/seller/countries/${id}`, changes)
}
editCountry.endpoint = 'PATCH|/seller/countries/:countryId:'

const editMine = function({ id, ...changes }) {
  return axios.patch(`${BASE_URL}/seller/mines/${id}`, changes)
}
editMine.endpoint = 'PATCH|/seller/mines/:mineId:'

const editPipe = function({ id, ...changes }) {
  return axios.patch(`${BASE_URL}/seller/pipes/${id}`, changes)
}
editPipe.endpoint = 'PATCH|/seller/pipes/:pipeId:'

const editBatch = function({ id, ...changes }) {
  return axios.patch(`${BASE_URL}/seller/batches/${id}`, changes)
}
editBatch.endpoint = 'PATCH|/seller/batches/:batchId:'

const setCountryCondition = function(countryId, condition) {
  return axios.put(`${BASE_URL}/seller/countries/${countryId}/condition`, { condition })
}
setCountryCondition.endpoint = 'PUT|/seller/countries/:countryId:/condition'

const setMineCondition = function(mineId, condition) {
  return axios.put(`${BASE_URL}/seller/mines/${mineId}/condition`, { condition })
}
setMineCondition.endpoint = 'PUT|/seller/mines/:mineId:/condition'

const setPipeCondition = function(pipeId, condition) {
  return axios.put(`${BASE_URL}/seller/pipes/${pipeId}/condition`, { condition })
}
setPipeCondition.endpoint = 'PUT|/seller/pipes/:pipeId:/condition'

const setBatchCondition = function(batchId, condition) {
  return axios.put(`${BASE_URL}/seller/batches/${batchId}/condition`, { condition })
}
setBatchCondition.endpoint = 'PUT|/seller/batches/:batchId:/condition'

const createMine = function (data) {
  return axios.post(`${BASE_URL}/seller/mines`, data)
}
createMine.endpoint = 'POST|/seller/mines'

function setMines(data) {
  const { orgId, ...editUserData } = data
  return axios.put(`${BASE_URL}/seller/organizations/${orgId}/mines`, editUserData)
}
setMines.endpoint = 'PUT|/seller/organizations/:orgId:/mines'

function getLicenceTypes(params) {
  return axios.get(`${BASE_URL}/seller/countries/licencetypes`, { params })
}
getLicenceTypes.endpoint = 'GET|/seller/countries/licencetypes'

export const provenanceActions = {
  getProvenance,
  setProvenanceTypes,
  setCountries,
  setMines,
  getCountries,
  getCountryById,
  getLicenceTypes,
  getMines,
  getMineById,
  getPipes,
  getPipeById,
  getBatches,
  getBatchById,
  createCountry,
  createMine,
  createPipe,
  createBatch,
  editCountry,
  editMine,
  editPipe,
  editBatch,
  setCountryCondition,
  setMineCondition,
  setPipeCondition,
  setBatchCondition
}
