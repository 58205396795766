import React from 'react'
import ProgressList from './progressList'
import { useProgressList } from '@hooks'

function ProgressLists() {
  const {
    getLists
  } = useProgressList()
  const lists = getLists()

  return (
    <div className='progress-lists'>
      {
        Object.keys(lists).map((list, index) => {
          return (
            <ProgressList
              key={index}
              list={lists[list]}
            />
          )
        })
      }
    </div>
  )
}

export default ProgressLists
