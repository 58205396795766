import axios from 'axios'
import { requestUtils } from '@utils'

const BASE_URL = process.env.REACT_APP_API_BASE_URL

function getRoughStoneList(params, options) {
  const { filters, columns, ...restParams } = params
  return axios.get(`${BASE_URL}/seller/roughs`, {
    params: {
      ...filters,
      ...(columns ? { columns: `[${columns.join(',')}]` } : {}),
      ...restParams
    },
    options
  })
}
getRoughStoneList.endpoint = 'GET|/seller/roughs'

const getRoughStoneListBatch = requestUtils.batchRequest(getRoughStoneList)

function getQcRoughStoneList(params, options) {
  const { filters, columns, ...restParams } = params
  return axios.get(`${BASE_URL}/seller/qc/roughs`, {
    params: {
      ...filters,
      ...(columns ? { columns: `[${columns.join(',')}]` } : {}),
      ...restParams
    },
    options
  })
}
getQcRoughStoneList.endpoint = 'GET|/seller/qc/roughs'

const getQcRoughStoneListBatch = requestUtils.batchRequest(getQcRoughStoneList)

function getRoughStoneForBlockchain(roughId) {
  return axios.get(`${BASE_URL}/seller/bcrough/${roughId}`)
}
getRoughStoneForBlockchain.endpoint = 'GET|/seller/bcrough/:roughId:'

function createRoughStone(rough) {
  return axios.post(`${BASE_URL}/seller/roughs`, { roughs: [rough] })
}
createRoughStone.endpoint = 'POST|/seller/roughs'

function createRoughStones(roughList) {
  return axios.post(`${BASE_URL}/seller/roughs`, { roughs: roughList })
}
createRoughStones.endpoint = 'POST|/seller/roughs'

function editRoughStone(id, rough, options = {}) {
  return axios.patch(`${BASE_URL}/seller/roughs/${id}`, rough, { options })
}
editRoughStone.endpoint = 'PATCH|/seller/roughs/:roughId:'

function editQcRoughStone(id, rough, options = {}) {
  return axios.patch(`${BASE_URL}/seller/qc/roughs/${id}`, rough, { options })
}
editQcRoughStone.endpoint = 'PATCH|/seller/qc/roughs/:roughId:'

function getRoughStoneById(roughId, columns, options = {}) {
  return axios.get(`${BASE_URL}/seller/roughs`, {
    params: { id: roughId, columns },
    options
  })
}
getRoughStoneById.endpoint = 'GET|/seller/roughs'

function getQcRoughStoneById(roughId, columns, options = {}) {
  return axios.get(`${BASE_URL}/seller/qc/roughs`, {
    params: { id: roughId, columns },
    options
  })
}
getQcRoughStoneById.endpoint = 'GET|/seller/qc/roughs'

function getUploadUrl({ orgId }, options = {}) {
  return axios.get(`${BASE_URL}/seller/upload`, { params: { sellerId: orgId }, options })
}
getUploadUrl.endpoint = 'GET|/seller/upload'

function getQcUploadUrl({ roughId }, options = {}) {
  return axios.get(`${BASE_URL}/seller/qc/upload`, { params: { roughId }, options })
}
getQcUploadUrl.endpoint = 'GET|/seller/qc/upload'

function uploadFile(signedUrl, file, options = {}) {
  if (!signedUrl) return
  const { taskId, updateProgress } = options

  const client = axios.create()
  client.interceptors.request.use(config => {
    delete config.headers['Content-Type']
    delete config.headers.put['Content-Type']
    return config
  }, null, { synchronous: true })
  return client.put(signedUrl, file, {
    onUploadProgress: (progressEvent) => {
      const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      if (updateProgress) {
        updateProgress(taskId, percentage)
      }
    }
  })
}

function updateMultipleRoughs(params, options = {}) {
  return axios.patch(`${BASE_URL}/seller/roughs`, params, { options })
}
updateMultipleRoughs.endpoint = 'PATCH|/seller/roughs'

function updateMultipleQcRoughs(params, options = {}) {
  return axios.patch(`${BASE_URL}/seller/qc/roughs`, params, { options })
}
updateMultipleQcRoughs.endpoint = 'PATCH|/seller/qc/roughs'

function setRoughCondition({ roughIds, sellerId, condition }) {
  return axios.put(`${BASE_URL}/seller/roughs/condition`, { roughIds, sellerId, condition })
}
setRoughCondition.endpoint = 'PUT|/seller/roughs/condition'

function createRoughComment(roughIds, commentText) {
  return axios.post(`${BASE_URL}/seller/roughs/comments`, { roughIds, commentText })
}
createRoughComment.endpoint = 'POST|/seller/roughs.comment'

function editRoughComment(roughId, commentId, commentText) {
  return axios.put(`${BASE_URL}/seller/roughs/${roughId}/comments/${commentId}`, { commentText })
}
editRoughComment.endpoint = 'PUT|/seller/roughs/:roughId:/comments/:commentId:'

function removeRoughComment(roughId, commentId) {
  return axios.delete(`${BASE_URL}/seller/roughs/${roughId}/comments/${commentId}`)
}
removeRoughComment.endpoint = 'DELETE|/seller/roughs/:roughId:/comments/:commentId:'

function getInclusionReductions() {
  return axios.get(`${BASE_URL}/seller/inclusionreductions`)
}
getInclusionReductions.endpoint = 'GET|/seller/inclusionreductions'

function getInclusionTypes() {
  return axios.get(`${BASE_URL}/seller/inclusionstypes`)
}
getInclusionTypes.endpoint = 'GET|/seller/inclusionstypes'

function getAvailability() {
  return axios.get(`${BASE_URL}/seller/availability`)
}
getAvailability.endpoint = 'GET|/seller/availability'

function getRoughSellers(params) {
  return axios.get(`${BASE_URL}/seller/roughs/orgs`, { params })
}
getRoughSellers.endpoint = 'GET|/seller/roughs/orgs'

function galaxyFileExists(hash, sellerId) {
  const hashes = Array.isArray(hash) ? hash : [hash]
  return axios.post(`${BASE_URL}/seller/roughs/galaxyfiles`,
    { hashes, sellerId },
    { options: { loadingSpinner: false } }
  )
}
galaxyFileExists.endpoint = 'POST|/seller/roughs/galaxyfiles'

function suspendSellerRoughs(params) {
  return axios.patch(`${BASE_URL}/seller/roughs/suspend`, params)
}
suspendSellerRoughs.endpoint = 'PATCH|/seller/roughs/suspend'

function getGalaxyFees() {
  return axios.get(`${BASE_URL}/seller/galaxyfees`)
}
getGalaxyFees.endpoint = 'GET|/seller/galaxyfees'

export const roughStoneActions = {
  uploadFile,
  getUploadUrl,
  getQcUploadUrl,
  editRoughStone,
  editQcRoughStone,
  getAvailability,
  createRoughStone,
  galaxyFileExists,
  createRoughStones,
  getRoughStoneList,
  getQcRoughStoneList,
  getRoughStoneForBlockchain,
  suspendSellerRoughs,
  getRoughStoneListBatch,
  getQcRoughStoneListBatch,
  getRoughStoneById,
  getQcRoughStoneById,
  setRoughCondition,
  getInclusionTypes,
  editRoughComment,
  removeRoughComment,
  createRoughComment,
  updateMultipleRoughs,
  updateMultipleQcRoughs,
  getInclusionReductions,
  getRoughSellers,
  getGalaxyFees
}
