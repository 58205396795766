import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@atoms'
import { ConfirmationModal } from '@templates'
import { objectUtils } from '@utils'

function TableRowIcon({
  action,
  row,
  iconTitle,
  refreshTableData
}) {
  const [openModal, setOpenModal] = useState(false)
  if (!action) return null

  const {
    actionName,
    itemType,
    callback,
    iconName,
    shouldConfirm,
    shouldRefresh = true
  } = action
  const defaultConfirmationModalMessage = `Are you sure you want to ${actionName} ${itemType && row.original.name ? itemType + ' "' + row.original.name + '"' : 'this item'}?`
  const confirmationMessage = (typeof shouldConfirm === 'function' || typeof shouldConfirm === 'string')
    ? objectUtils.callProp(shouldConfirm, [{ defaultMessage: defaultConfirmationModalMessage, row: row.original, values: row.values }], defaultConfirmationModalMessage)
    : defaultConfirmationModalMessage

  function onSubmit() {
    Promise.resolve(callback(row.original, row))
   .then(() => {
     if (shouldRefresh) {
       refreshTableData()
     }
   })
  }

  return (
    <>
      <div className='table__row-icon cursor-pointer'>
        <Icon
          size='lg'
          name={iconName ?? actionName}
          title={iconTitle}
          className='table__row-icon'
          onClick={() => {
            if (shouldConfirm) {
              setOpenModal(true)
            } else {
              onSubmit()
            }
          }}
        />
      </div>
      <ConfirmationModal
        open={openModal}
        title={`${actionName}${itemType ? ` ${itemType}` : ''}?`}
        message={confirmationMessage}
        onSubmit={() => onSubmit()}
        onClose={() => setOpenModal(false)}
      />
    </>
  )
}

TableRowIcon.propTypes = {
  iconTitle: PropTypes.string,
  row: PropTypes.object.isRequired,
  refreshTableData: PropTypes.func,
  action: PropTypes.object.isRequired
}

TableRowIcon.defaultProps = {
  action: null,
  iconTitle: ''
}

export default TableRowIcon
