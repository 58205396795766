import React, { useEffect, useState } from 'react'
import { Button, ToggleSwitch } from '@atoms'
import { TextInput, Dropdown } from '@molecules'
import { Form, Fieldset } from '@organisms'
import {
  provenanceActions
} from '@actions'
import { numberUtils, objectUtils, textUtils } from '@utils'
import clone from 'just-clone'

function removeIdFromAddresses(addresses) {
  // backend reject any property that doesn't
  // belong to the table schema, so we need to
  // remove _id from addresses before sending it
  return addresses.map(({ _id, ...rest }) => {
    rest.userDocuments = rest.userDocuments?.map(({ _id, ...restDoc }) => restDoc) || []
    return rest
  })
}

function SellerSettingsStep(props) {
  const { validateAndSubmit } = props.useMultiSteps(props)

  function handleSubmit(formData) {
    if (!formData) return
    const data = clone(formData)
    data.address = removeIdFromAddresses(data.address)
    data.emailCcs = data.emailCcs ? (data.emailCcs).trim().split('\n') : null
    data.emailBccs = data.emailBccs ? (data.emailBccs).trim().split('\n') : null
    data.minMatchingMargin = numberUtils.isNumber(data.minMatchingMargin) ? data.minMatchingMargin / 100 : null
    data.minClaraMargin = numberUtils.isNumber(data.minClaraMargin) ? data.minClaraMargin / 100 : null
    data.profitSplit = numberUtils.isNumber(data.profitSplit) ? data.profitSplit / 100 : null
    data.acceptWindowedStones = textUtils.isEmpty(data.acceptWindowedStones) ? null : data.acceptWindowedStones === 'true'
    data.acceptBlockedStones = textUtils.isEmpty(data.acceptBlockedStones) ? null : data.acceptBlockedStones === 'true'
    validateAndSubmit({
      requiredFields: [],
      data: objectUtils.removeNulls(objectUtils.removeEmptyStrings(data, { replacement: undefined, deep: true }), { replacement: undefined, deep: true }),
      redirectPath: '/organizations',
      successMessage: `Organization ${data.commonName} was successfully created.`
    })
    data.emailCcs = data.emailCcs ? data.emailCcs.join('\n') : null
    data.emailBccs = data.emailBccs ? data.emailBccs.join('\n') : null
    data.minMatchingMargin = numberUtils.isNumber(data.minMatchingMargin) ? data.minMatchingMargin * 100 : null
    data.minClaraMargin = numberUtils.isNumber(data.minClaraMargin) ? data.minClaraMargin * 100 : null
    data.profitSplit = numberUtils.isNumber(data.profitSplit) ? data.profitSplit * 100 : null
    data.acceptWindowedStones = textUtils.isEmpty(data.acceptWindowedStones) ? null : String(data.acceptWindowedStones)
    data.acceptBlockedStones = textUtils.isEmpty(data.acceptBlockedStones) ? null : String(data.acceptBlockedStones)
  }

  const [provTypesList, setProvTypesList] = useState([])
  const [countriesList, setCountriesList] = useState([])
  const [minesList, setMinesList] = useState([])

  useEffect(() => {
    if (props.getState('sellerRole')) {
      provenanceActions.getProvenance().then(result => {
        setProvTypesList(
          result.data.data.map(r => {
            return {
              label: r.description,
              value: r.id
            }
          })
        )
      })

      provenanceActions.getCountries().then(result => {
        setCountriesList(
          result.data.data.map(r => {
            return {
              label: r.name,
              value: r.id
            }
          })
        )
      })

      provenanceActions.getMines({ condition: 'ACTIVE' }).then(result => {
        setMinesList(
          result.data.data.map(r => {
            return {
              label: r.name,
              value: r.id
            }
          })
        )
      })
    }
  }, [])

  return (
    <Form title="Seller Settings">
      {props.getState('sellerRole') && (
        <>
          <ToggleSwitch
            id="bcAnonymous"
            name="bcAnonymous"
            label="Anonymous On Blockchain"
            checked={props.getState('bcAnonymous')}
            onChange={props.handleChange}
          />
          <Dropdown
            name="provenanceTypes"
            label="Allowed Provenance Types"
            options={provTypesList}
            value={props.getState('provenanceTypes')}
            onChange={e =>
              props.setState('provenanceTypes', e.currentTarget.value)
            }
            isMulti
          />
          <Dropdown
            name="countries"
            label="Allowed Countries"
            options={countriesList}
            value={props.getState('countries')}
            onChange={e => props.setState('countries', e.currentTarget.value)}
            isMulti
          />
          <Dropdown
            name="mines"
            label="Allowed Mines"
            options={minesList}
            value={props.getState('mines')}
            onChange={e => props.setState('mines', e.currentTarget.value)}
            isMulti
          />
          <div>
            <Fieldset legend="Margins">
              <ul className="vertical">
                <li>
                  <TextInput
                    label="Minimum Match Margin %"
                    name="minMatchingMargin"
                    type="number"
                    value={props.getState('minMatchingMargin')}
                    onChange={props.handleChange}
                  />
                  <TextInput
                    label="Minimum Clara Margin %"
                    name="minClaraMargin"
                    type="number"
                    value={props.getState('minClaraMargin')}
                    onChange={props.handleChange}
                  />
                  <TextInput
                    label="Profit Split %"
                    name="profitSplit"
                    type="number"
                    value={props.getState('profitSplit')}
                    onChange={props.handleChange}
                  />
                </li>
              </ul>
            </Fieldset>
          </div>
        </>
      )}

      {!props.getState('sellerRole') && <>Not a Seller</>}

      <div className="create-organization-buttons">
        <Button
          disabled={!props.hasPrev()}
          typeVariant="action"
          size="sm"
          onClick={() => props.prev()}
        >
          Previous
        </Button>
        <Button
          typeVariant="primary"
          size="sm"
          onClick={() => handleSubmit(props.state)}
        >
          Create Organization
        </Button>
      </div>
    </Form>
  )
}

export default SellerSettingsStep
