import React, { useState } from 'react'
import { Form, FileUpload } from '@organisms'
import { roughStoneActions } from '@actions'
import CreateRoughStoneBtn from '../inc/createRoughStoneBtn'
import { useMultiSteps } from '@hooks'
import { useModalStore } from '@stores'
import { fileUtils } from '@utils'

function UploadStep({ assortment, ...props }) {
  const { validationSchemaError } = useMultiSteps(props)
  const [filePending, setFilePending] = useState(false)
  const {
    setModal
  } = useModalStore(state => state)

  async function handleSarineFileChange(file, handleFileUploadProgress) {
    if (!file) {
      props.setState('galaxyFile', undefined)
    } else {
      const md5Hash = await fileUtils.getFileHash(file)
      const galaxyFileExists = await roughStoneActions.galaxyFileExists(md5Hash, assortment.sellerId)
      if (galaxyFileExists.data.data.exists) {
        const roughId = galaxyFileExists.data.data.files[0].rough_id
        await new Promise((resolve, reject) => {
          setModal({
            id: 'advFileExists',
            title: 'ADV File Exists',
            message: <>
              This ADV file has been uploaded to another rough stone ({roughId}).
              <br/>
              <div className="center">
                <strong>Are you sure you want to use this file?</strong>
              </div>
            </>,
            buttonOptions: {
              submitText: 'Yes',
              submitTypeVariant: 'secondary',
              cancelText: 'No',
              cancelTypeVariant: 'primary'
            },
            onSubmit: resolve,
            onCancel: (err) => {
              props.setState('galaxyFile', undefined)
              reject(err)
            }
          })
        })
      }
      return uploadAdvFile(file, handleFileUploadProgress)
    }
  }

  function uploadAdvFile(file, handleFileUploadProgress) {
    setFilePending(true)
    return roughStoneActions.getUploadUrl({ orgId: assortment.sellerId })
    .then(response => {
      return {
        url: response.data.data.url.url,
        uploadId: response.data.data.uploadId
      }
    })
    .then(tempUpload => {
      return roughStoneActions.uploadFile(tempUpload.url, file, { updateProgress: handleFileUploadProgress })
      .then(response => {
        const galaxyFile = {
          tempFile: tempUpload.uploadId,
          fileName: response.config.data.name
        }
        props.setState('galaxyFile', galaxyFile)
      })
    })
    .catch(err => {
      console.error(err)
      throw err
    })
    .finally(() => {
      setFilePending(false)
    })
  }

  return (
    <Form title={`New rough stone for assortment ${assortment.name}`}>
      <div className="create-rough-stone__upload-file">
        <FileUpload
          name='advFile'
          label="Sarine galaxy file"
          onChange={(file, handleFileUploadProgress) => handleSarineFileChange(file, handleFileUploadProgress)}
          acceptTypes={['.adv']}
          expectedFileName={`${props.state.sellerStoneName?.toLowerCase()}.adv`}
          fileName={props.state?.galaxyFile?.fileName || undefined}
          validationText={validationSchemaError?.parseStep('galaxyFile')}
        />
      </div>
      <CreateRoughStoneBtn filePending={filePending} {...props} />
    </Form>
  )
}

export default UploadStep
