import axios from 'axios'
const BASE_URL = process.env.REACT_APP_API_BASE_URL

const getBaselines = function(params) {
  return axios.get(`${BASE_URL}/buyer/baselines`, { params })
}
getBaselines.endpoint = 'GET|/buyer/baselines'

const createBaseline = function(params) {
  return axios.post(`${BASE_URL}/buyer/baselines`, params)
}
createBaseline.endpoint = 'POST|/buyer/baselines'

const replaceBaseline = function(baselineId, params) {
  return axios.put(`${BASE_URL}/buyer/baselines/${baselineId}`, params)
}
replaceBaseline.endpoint = 'PUT|/buyer/baselines/:priceBaselineId:'

const editBaseline = function(baselineId, params) {
  return axios.patch(`${BASE_URL}/buyer/baselines/${baselineId}`, params)
}
editBaseline.endpoint = 'PATCH|/buyer/baselines/:priceBaselineId:'

const setBaselineCondition = function(priceBaselineIds, condition) {
  return axios.put(`${BASE_URL}/buyer/baselines/condition`, { priceBaselineIds, condition })
}
setBaselineCondition.endpoint = 'PUT|/buyer/baselines/condition'

export const baselineActions = {
  getBaselines,
  editBaseline,
  createBaseline,
  replaceBaseline,
  setBaselineCondition
}
