import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useAuthStore, useGlobalsStore } from '@stores'
import { provenanceActions } from '@actions'
import { DetailsPage } from '@templates'
import { usePageTitle, useToast } from '@hooks'
import { objectUtils } from '@utils'

function CountryDetails({ match, title }) {
  const { countryId, operation } = match?.params

  const { hasAdmin } = useAuthStore(state => state)
  const { setCountriesList, resetLicenceTypesList } = useGlobalsStore()
  const { showSuccessToast } = useToast()
  const history = useHistory()

  const [country, setCountry] = useState({})
  usePageTitle(title, countryId, country?.name)
  useEffect(() => {
    if (countryId) {
      provenanceActions.getCountryById(countryId)
      .then(setCountry)
      .catch(err => console.error(err))
    } else setCountry({})
  }, [countryId])

  const [fields, setFields] = useState([])
  useEffect(() => {
    if (country) {
      setFields([
        {
          label: 'Name',
          value: country.name,
          name: 'name',
          componentName: 'textInput',
          canEdit: true
        },
        {
          label: 'Required Licence',
          value: country.requiredLicence ?? '',
          name: 'requiredLicence',
          componentName: 'textInput',
          canEdit: true
        },
        {
          label: 'Secondary Market',
          value: country.secondaryMarket,
          name: 'secondaryMarket',
          componentName: 'checkbox',
          canEdit: true,
          span: true
        },
        {
          label: 'Created At',
          value: moment(country.createdAt).toLocaleString()
        },
        {
          label: 'Last Updated',
          value: moment(country.updatedAt).toLocaleString()
        }
      ])
    }
  }, [country])

  async function handleOnSubmit(editedValues) {
    const { ...countryData } = objectUtils.removeEmptyStrings(editedValues)

    if (Object.keys(countryData).length) {
      return provenanceActions.editCountry({ id: countryId, ...countryData })
      .then(() => {
        showSuccessToast('Country updated.')
        resetLicenceTypesList()
        setCountriesList()
        .then(() => {
          history.push('/seller-settings')
        })
      })
    }
  }

  return (
    <DetailsPage
      canEdit={hasAdmin(provenanceActions.editCountry)}
      isEdit={operation === 'edit'}
      fields={fields}
      onSubmit={handleOnSubmit}
      title={{
        label: 'Country ID',
        value: country?.id || ''
      }}
    />
  )
}

CountryDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string
}

export default CountryDetails
