import axios from 'axios'
const BASE_URL = process.env.REACT_APP_API_BASE_URL

const wakeUpDb = function (params) {
  return axios.get(`${BASE_URL}/events/wakeup`, { options: { loadingSpinner: false } })
}
wakeUpDb.endpoint = 'GET|/events/wakeup'

const getEventTypes = function () {
  return axios.get(`${BASE_URL}/events/types`)
}
getEventTypes.endpoint = 'GET|/events/types'

const createEventType = function (event) {
  return axios.post(`${BASE_URL}/events/types`, event)
}
createEventType.endpoint = 'POST|/events/types'

const getEvents = function (params) {
  return axios.get(`${BASE_URL}/events`, { params })
}
getEvents.endpoint = 'GET|/events'

const createEvent = function (event) {
  return axios.post(`${BASE_URL}/events`, event)
}
createEvent.endpoint = 'POST|/events'

const editEvent = function (id, data) {
  return axios.patch(`${BASE_URL}/events/${id}`, data)
}
editEvent.endpoint = 'PATCH|/events/:eventId:'

const setEventCondition = function (id, condition) {
  return axios.put(`${BASE_URL}/events/${id}/condition`, { condition })
}
setEventCondition.endpoint = 'PUT|/events/:eventId:/condition'

export const eventActions = {
  wakeUpDb,
  getEvents,
  editEvent,
  createEvent,
  getEventTypes,
  createEventType,
  setEventCondition
}
