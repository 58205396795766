import React from 'react'

const reserveMethod = <>
  How reserve prices will be determined for stones in this assortment:
  <br/><br/>
  <i>Individually Priced</i> uses the reserve prices on the stones, ignoring the assortment reserve.
  <br/>
  <i>Sum of Stones</i> will add up the reserve prices of the stones and use that as the assortment&apos;s reserve.
  <br/>
  <i>Assortment Ppc</i> uses the assortment&apos;s reserve price per carat times the weights of all the stones. Stone reserves are ignored.
</>

const inclusionsTypeId = <>
  Category A:<br/>
  -Light white Feathers<br/>
  -Nice white inclusions<br/>
  -Nice spreaded Black inclusions<br/>
  -Very light Black Pique<br/><br/>
  Category B:<br/>
  -SI1-I2 Black piques<br/>
  -Bubble inclusions<br/>
  -Spotted inclusions<br/>
  -Colored inclusions<br/>
  -Heavy and solid white Glets /crack<br/>
</>

const roughExcel = <>
  Please upload an xlsx file with the following headers (in this order):<br/><br/>
  seller stone name, weight, color 1, fluorescence 1, tinge 1, color 2, fluorescence 2, tinge 2, ..., <br/>
  eye color, eye fluorescence, eye tinge, country, mine, pipe, batch, weight category, price point name,<br/>
  price per carat, scan type, inclusion type, tension, yellow uv, type<br/><br/>
  Allowed yehuda color values: D+ to K- and NFY (upload DE as E+)<br/>
  Allowed yehuda fluorescence values: 0 to 200<br/>
  Allowed yehuda tinge values: &quot;None&quot;, &quot;IIa Brown D&quot;, &quot;IIa Brown E&quot;, &quot;IIa Brown F&quot;, &quot;TLB&quot;, &quot;LB&quot;, &quot;IIa Mixed&quot;<br/>
  Allowed eye color values: D+ to M-, N, O-P, CAPE<br/>
  Allowed eye fluorescence values: None, Faint, Medium, Strong, Very Strong<br/>
  Allowed eye tinge values: &quot;None&quot;, &quot;IIa Brown D&quot;, &quot;IIa Brown E&quot;, &quot;IIa Brown F&quot;, &quot;TTLB D-F&quot;, &quot;TTLB G-H&quot;, &quot;TLB&quot;, &quot;LB&quot;<br/>
  Allowed scan type values: &quot;galaxy_1000&quot;, &quot;galaxy_ultra&quot;<br/>
  Allowed inclusion type values: &quot;Category A&quot;, &quot;Category B&quot;<br/>
  Allowed tension values: &quot;None&quot;, &quot;T3&quot;, &quot;T4&quot;<br/>
  Allowed yellow uv value: &quot;Yes&quot;<br/>
  Allowed type value: &quot;Rough&quot;, &quot;Windowed&quot;, &quot;Blocked&quot;
</>
const roughQCExcel = <>
  Please upload an xlsx file with the following headers (in this order):<br/><br/>
  clara id, inclusion type, inclusion reductions, qc status<br/><br/>
  Allowed inclusion type: &quot;Category A&quot;, &quot;Category B&quot;, &quot;Manual&quot;<br/>
  Allowed inclusion reductions: &quot;Clara Default&quot;, &quot;Clara Default - Culet 0&quot;, &quot;Sarine Default&quot;<br/>
  Allowed qc status: &quot;QC Approved&quot;, &quot;QC Not Approved&quot;<br/><br/>
  *Note* qc status must be updated independently of other fields
</>

const roughListTipsSmooth = <>Redistribute reserve prices according to<br/>relative estimated value, keeping the total<br/>of all selected stones the same.</>
const roughListTipsSmoothA = <>Redistribute reserve prices according to<br/>relative estimated value, keeping the total of all<br/>selected stones within each assortment the same.</>
const roughListTipsGeneric = <>Set reserve prices using the estimated value by Clara.</>
const roughListTipsAdjust = <>Increase or decrease all prices by a specified percentage.</>
const roughListTipsUnset = <>Remove all override prices and use original reserves instead.</>

const strictLimitsWeight = <>
Clara may occasionally give you a stone with higher weight than the order you have placed.<br/>
The price per carat that you are charged will be from this order line.<br/><br/>
Check this box if you wish to reject higher weight stones (at this order line&apos;s price per carat).
</>
const strictLimitsClarity = <>
Clara may occasionally give you a stone with better clarity than the order you have placed.<br/>
The price per carat that you are charged will be from this order line.<br/><br/>
Check this box if you wish to reject higher clarity stones (at this order line&apos;s price per carat).
</>
const strictLimitsColour = <>
Clara may occasionally give you a stone with a color one minor grade better than the order you placed.<br/>
The price per carat that you are charged will be from this order line.<br/><br/>
Check this box if you wish to reject higher colour stones (at this order line&apos;s price per carat).
</>

const orderLineQty = <>
&quot;Regular&quot; means the quantity will be reduced as stones are allocated. Once filled the order line is complete.<br/>
&quot;Recurring&quot; means the quantity will reset at the start of each sale (ex. 15 stones requested per sale).<br/>
Select &quot;Tops&quot; to make this a tops order line. We will not match rough diamonds where both planned parts are tops.
</>

const sampleCosts = <>
Example:<br/><br/>
0.5ct Round G VS1<br/>
RAP: $3700/ct, Discount: -20%, Margin: 5%<br/>
Manufacturing cost: $75/ct, Cert cost: $64, After-sawn weight: 0.93ct<br/><br/>
Discount with Margin: 1 - 0.80 x 0.95 = 24%<br/>
Total: ($3700/ct x (1-24%) x 0.5ct) - ($75/ct x 0.93ct) - $64 = $1272.25
</>

const mfgCost = <>
  You can enter your manufacturing costs here. When this cost is selected on an<br/>
  order, the cost will be subtracted from your pricing when Clara determines<br/>
  the price you offer for each stone. Manufacturing costs are in US dollars<br/>
  per rough carat after sawing (so the main stone and top each has its own<br/>
  manufacturing cost). The weight entered is rough weight after sawing.<br/>
  Weight ranges not covered in the Manufacturing costs are going to be taken<br/>
  into account with a value of $0.<br/><br/>
  {sampleCosts}
</>

const certCost = <>
  You can enter your certificate costs here. When this cost is selected on an<br/>
  order, the cost will be subtracted from your pricing when Clara determines<br/>
  the price you offer for each stone. Certificate costs are in US dollars per<br/>
  polished stone. The weight entered is polished weight. Enter $0 for sizes<br/>
  that are not going to be certified. Weight ranges not covered in the <br/>
  Certificate costs are going to be taken into account with a value of $0.<br/><br/>
  {sampleCosts}
</>

const orderTinges = <>
If any tinges can be priced with this order indicate that here.<br/>
Select the Tinge Map you want to use for pricing stones.
</>

const createBaseline = <>
Each rough diamond color is a row. To use a mixture of baseline prices for any given color, enter percentages in that row.<br/>
For example: if rough stones with a Clara color grade of E+ receive a polished grade of E 50% of the time and D the other 50%,<br/>
you could enter 50 in both the D and E columns for the E+ row. Each row should sum to 100%.
</>

const pricingInstructions = <div className="order-pricing-infotip__instructions">
  <p>1 - Pricing for Tops</p><br />
Generally people price tops only as rounds with a PPC split according to sensible polished<br/>
groups.<br/>
For example:<br />
0.04-0.07 DEF VVS/VS/SI will have three PPC (per clarity)<br />
0.04-0.07 GHI VVS/VS/SI will have three PPC (per clarity)…<br />
0.08-0.14 DEF VVS/VS/SI will have three PPC (per clarity)<br />
0.08-0.14 GHI VVS/VS/SI will have three PPC (per clarity)…<br />
Between 0.30-0.69 most people price the tops with a discount below RAP list.<br/>
More competitive tops leads to competitive overall bidding per stone.<br />
  <br /><br />
  <p>2 - Pricing for main stones</p><br />
Some manufacturers estimate the cost of labor for specific size categories by assuming an<br/>
average aftersawn yield. For example for a 0.50 RB polished, assume an aftersawn yield of 50%.<br/>
Then apply your average labour to the estimate, and translate into an average PPC on the polished.<br/>
For example:<br />
Polished diamond: 0.50, After-sawn yield: 50%, Manufacturing cost: $65/CT on aftersawn weight.<br />
This will translate to 1.00 rough weight, at $65/ct is $65 dollars total labor cost, divided <br/>
by 0.50 is approx. $130/ct on the polished. This can now be applied as a discount to polished<br/>
buying prices to account for labor cost. Then further reduce your price by certificate costs of<br />
the polished stone and required margin.
  <br /><br />
  <p>3 - Pricing for CAPE stones</p><br />
Pricing for CAPE stones (colors of N, O-P and CAPE in pricing section) is using M RAP price,<br/>
when discount method is selected and there are no specific values for these colors in the<br/>
baseline file attached to the order. Should you intend to have designated values for these<br/>
colors, please update your baseline to include N, OP and CAPE colors.
</div>

const showComputedBaselines = <>
This feature allows you to use your manufacturing data of previous stones to create new baselines with updated prices<br/>
for all colors including +/- measurements. It will create a weighted average of your existing baseline prices based<br/>
on how you observe each rough color measurement translate to a polished color grade.<br/><br/>
If enabled, this will appear when you click the &quot;Baselines&quot; button above the pricing templates list. Instead of using a round or pear<br/>
baseline, you can use this custom baseline that combines colors based on your own results.
</>
const showCosts = <>
This feature allows you to enter additional inputs such as margin, certificate costs and manufacturing costs<br/>
and have Clara automatically calculate your purchase prices.<br/>
</>

const purchaseLimit = <>
This is your overall purchase limit per sale.
</>
const purchaseMinimum = <>
 If the total value of all assigned stones in a sale is below this threshold, you will be omitted from the sale.
</>
const shippingMinimum = <>
All stones matched to you will be held at a Clara facility until the total value reaches this amount.<br/>This helps reduce your shipping costs.
</>

const provenanceType = <>
  <b>Primary Source:</b><br/>
  - Primary producer&apos;s account<br/>
  - Goods sold and scanned by producer directly<br/>
  <b>Clara Direct:</b><br/>
  - Clara has direct control over the acquisition<br/>
  <b>Secondary Market - Verified Origin:</b><br/>
  - 3rd party verifiable chain of custody of single source or aggregate mix<br/>
  <b>Secondary Market:</b><br/>
  - Only industry standard documentation is available (invoice, and KP, if avaialable)<br/>
  - Partial boxes will always be in this category.<br/>
  <b>Seller Represented Provenance:</b><br/>
  - Stones provided by a Clara trusted seller<br/>
</>

const buyerSettingsArchiveDisabled = <>
  This item cannot be archived because it is associated with an active order.
</>

const buyerSettingsRemoveDisabled = <>
  This item cannot be removed because it is associated with an active or archived order.
</>

const dashboardChartsOrder = <>
The bar chart shows the current filled amount of each order line.<br/>
The line chart shows an estimate of how many stones each order line could have received if its price was raised. This can also be generated by the Feedback Report.
</>

const highTensionMaxParts = <>
  <b>Do Not Accept High Tension:</b><br/>
  - Stones with T4 tension will not be accepted<br/>
  <b>Main Stone Only (Default):</b><br/>
  - For stones with T4 tension, you will only pay for the main stone<br/>
  <b>Include Tops:</b><br/>
  - For stones with T4 tension, you will pay for the main stone and the top<br/>
</>

const acceptWindowedStones = <>
  <b>Disable Windowed Stones:</b><br/>
  - Hide &apos;Accept Windowed Stones&apos; checkbox in the Orders section<br/>
  - Do not accept rough stones marked as Windowed Stones<br/>
  <b>Do Not Accept Windowed Stones (Default):</b><br/>
  - Do not accept rough stones marked as Windowed Stones<br/>
  <b>Accept Windowed Stones:</b><br/>
  - Accept rough stones marked as Windowed Stones<br/>
  <br/>
  *Note* Existing &apos;Accept Windowed Stone&apos; checkbox in the Orders section will not be changed, unless overriden
</>

const acceptBlockedStones = <>
  <b>Disable Blocked Stones:</b><br/>
  - Hide &apos;Accept Blocked Stones&apos; checkbox in the Orders section<br/>
  - Do not accept rough stones marked as Blocked Stones<br/>
  <b>Do Not Accept Blocked Stones (Default):</b><br/>
  - Do not accept rough stones marked as Blocked Stones<br/>
  <b>Accept Blocked Stones:</b><br/>
  - Accept rough stones marked as Blocked Stones<br/>
  <br/>
  *Note* Existing &apos;Accept Blocked Stone&apos; checkbox in the Orders section will not be changed, unless overriden
</>

const allColoursWarning = <>Missing some required measurements (including eye measurements in special cases)</>
const allFlusWarning = allColoursWarning
const allTingesWarning = allColoursWarning

const minMatchingMargin = <>
The % difference between buyer price and seller stone reserve price required to consider an order line for matching.<br/><br/>
Minimum Matching Margin % For Matching:<br/>
  <ul className='bulleted-list'>
    <li key="minMatch0">-5 implies buyer price could be up to 5% below seller&apos;s reserve price and it will still sell</li>
    <li key="minMatch1">0 implies buyer price must be at least the same price as seller&apos;s reserve price</li>
    <li key="minMatch2">5 implies buyer price must be at least 5% above the seller&apos;s reserve price</li>
  </ul>
  <br/>
* Accepted values <b>-100</b> to <b>100</b><br/>
* For empty reserve stones, this field is negligible<br/>
* Default <b>0</b>
</>
const minClaraMargin = <>
The % of the buyer price that Clara will receive, at minimum.<br/><br/>
Minimum Clara Margin % For Transactions:<br/>
  <ul className='bulleted-list'>
    <li key="minClara0">-5 implies Clara can pay the seller 5% more than the buyer price</li>
    <li key="minClara1">0 implies that Clara will not lose money on the transaction</li>
    <li key="minClara2">5 implies that Clara must get at least 5% of the buyer price, and the seller will get the rest</li>
  </ul>
  <br/>
* Accepted values <b>-100</b> to <b>100</b><br/>
* Default <b>0</b>
</>
const profitSplit = <>
After giving Clara the minimum profit margin, if there is any value left over between the<br/>
seller reserve and the buyer price this determines how much of that goes to Clara.<br/><br/>
Profit Split % For Transactions:
  <ul className='bulleted-list'>
    <li key="profitSplit0">0 implies Clara will receive Minimum Clara Margin % of the buyer price, and everything else goes to the seller</li>
    <li key="profitSplit1">100 implies the seller gets their reserve price, and everything else goes to Clara</li>
    <li key="profitSplit2">30 implies that after Minimum Clara Margin % has been taken out, the difference between the remainder of buyer price and the seller reserve will be split 30/70 between Clara and the seller</li>
  </ul>
  <br/>
* Accepted values <b>0</b> to <b>100</b><br/>
* For empty reserve stones, value must be <b>0</b><br/>
* Default <b>100</b>
</>

const matchingReserveType = <>
  <b>To Create Bids:</b><br/>
  - All stones are forced to sell regardless of reserve prices, but the profit is unhanged. <br/>
  - This is useful for generating bids to give to sellers, but should not be used to close a sale.<br/>
  <b>Finalizing Sale:</b><br/>
  - This is for finalizing a sale without bids or after bids have been decided on.<br/>
  - Stones with accepted bids are forced to sell regardless of reserve prices.<br/>
  - Stones with bids that were not accepted will not be sold.<br/>
  - Stones without any bids are sold according to the &quot;Normal&quot; rules.
</>

const requiredNotSelected = <>
  It is required to upload new ADV files when changing inclusion type to manual.
</>

const assortmentProgressiveProfitRatio = <>
  The &quot;ratio&quot; here is the ratio of buyer&apos;s price over generic price for the same stone.<br/>
  (Allowed range: -100 to &infin;)<br/>
  &emsp;-20 means the buyer&apos;s price is 20% below generic.<br/>
  &emsp;0 means the buyer&apos;s price is the same as generic.<br/>
  &emsp;20 means the buyer&apos;s price is 20% higher than generic.
</>

export {
  reserveMethod,
  requiredNotSelected,
  inclusionsTypeId,
  roughExcel,
  roughQCExcel,
  roughListTipsSmooth,
  roughListTipsSmoothA,
  roughListTipsGeneric,
  roughListTipsAdjust,
  roughListTipsUnset,
  strictLimitsWeight,
  strictLimitsClarity,
  strictLimitsColour,
  orderLineQty,
  mfgCost,
  certCost,
  orderTinges,
  createBaseline,
  pricingInstructions,
  showComputedBaselines,
  showCosts,
  purchaseLimit,
  purchaseMinimum,
  shippingMinimum,
  provenanceType,
  buyerSettingsArchiveDisabled,
  buyerSettingsRemoveDisabled,
  dashboardChartsOrder,
  highTensionMaxParts,
  allColoursWarning,
  allFlusWarning,
  allTingesWarning,
  minClaraMargin,
  minMatchingMargin,
  profitSplit,
  matchingReserveType,
  assortmentProgressiveProfitRatio,
  acceptWindowedStones,
  acceptBlockedStones
}
