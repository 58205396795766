import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { MultiStepsForm } from '@organisms'
import { useGlobalsStore, useAuthStore } from '@stores'
import countryMineStep from './create_rough_stone/countryMineStep'
import priceWeightStep from './create_rough_stone/priceWeightStep'
import measurementStep from './create_rough_stone/measurementStep'
import uploadStep from './create_rough_stone/uploadStep'
import {
  roughStoneActions,
  assortmentActions,
  weightCategoryActions,
  pricePointActions
} from '@actions'
import { roughSchema } from '@schemas'
import { arrayUtils, objectUtils } from '@utils'

function CreateRoughStone(props) {
  const assortmentId = props.match.params.assortmentId
  const [assortment, setAssortment] = useState({})
  const [weightCategories, setWeightCategories] = useState([])
  const [pricePoints, setPricePoints] = useState([])
  const [inclusionTypes, setInclusionTypes] = useState([])
  const [inclusionReductions, setInclusionReductions] = useState([])

  const measurementValidationCache = new Map()

  const orgParam = { orgId: assortment?.sellerId }
  const appSettingsParams = { groupKey: 'ROUGH_CONSTANTS' }
  const sellerParam = { sellerId: assortment?.sellerId }
  const sellerActiveParam = { sellerId: assortment?.sellerId, condition: 'ACTIVE' }
  const activeOnlyParam = { condition: 'ACTIVE' }

  const { hasAdmin, permissionsAdminCache } = useAuthStore(store => store)
  const {
    getOrgsList,
    getAppSettings,
    getCountriesList,
    getMinesList,
    getPipesList,
    getBatchesList,
    orgsList: { [JSON.stringify(orgParam)]: orgList = [] },
    appSettings: { [JSON.stringify(appSettingsParams)]: appSettings },
    countriesList: { [JSON.stringify(sellerParam)]: countryList = [] },
    minesList: { [JSON.stringify(sellerActiveParam)]: mineList = [] },
    pipesList: { [JSON.stringify(activeOnlyParam)]: pipeList = [] },
    batchesList: { [JSON.stringify(sellerActiveParam)]: batchList = [] },
    getRoughColours,
    getRoughTinges,
    getRoughFluorescences,
    getPolishedFluorescences,
    getRoughScanTypes,
    getRoughTensions,
    getRoughTypes,
    roughColours,
    roughTinges,
    roughFluorescences,
    polishedFluorescences,
    roughScanTypes,
    roughTensions,
    roughTypes
  } = useGlobalsStore(store => store)

  useEffect(() => {
    assortmentActions.getAssortmentById(assortmentId, { columns: '[ProvenanceType]' })
    .then(response => {
      setAssortment(response?.data?.data[0])
    })
    .catch(err => console.error(err))

    roughStoneActions.getInclusionTypes()
    .then(response => setInclusionTypes(response.data.data))
    .catch(err => console.error(err))

    roughStoneActions.getInclusionReductions()
    .then(response => setInclusionReductions(response.data.data))
    .catch(err => console.error(err))
  }, [assortmentId])

  useEffect(() => {
    if (!assortment?.sellerId) return
    getOrgsList(orgParam)
    .catch(console.error)

    getAppSettings(appSettingsParams)
    .catch(console.error)

    getCountriesList(sellerParam)
    .catch(console.error)

    getMinesList(sellerActiveParam)
    .catch(console.error)

    getPipesList(activeOnlyParam)
    .catch(console.error)

    getBatchesList(sellerActiveParam)
    .catch(console.error)

    weightCategoryActions.getWeightCategories(assortment.sellerId)
    .then(response => setWeightCategories(response.data.data))
    .catch(console.error)

    pricePointActions.getPricePoints(assortment.sellerId)
    .then(response => setPricePoints(response.data.data))
    .catch(console.error)

    getRoughColours().catch(console.error)
    getRoughTinges().catch(console.error)
    getRoughFluorescences().catch(console.error)
    getPolishedFluorescences().catch(console.error)
    getRoughScanTypes().catch(console.error)
    getRoughTensions().catch(console.error)
    getRoughTypes().catch(console.error)
  }, [assortment?.sellerId])

  const eyeMeasurementColours = useMemo(() => (roughColours ?? []).filter(c => !c.eyeNotAllowed), [roughColours])
  const eyeMeasurementFluorescences = useMemo(() => (polishedFluorescences ?? []).filter(f => !f.eyeNotAllowed), [polishedFluorescences])
  const eyeMeasurementTinges = useMemo(() => (roughTinges ?? []).filter(t => !t.eyeNotAllowed), [roughTinges])
  const filteredInclusionTypes = useMemo(() => (inclusionTypes ?? []).filter(inc => hasAdmin(roughStoneActions.getInclusionTypes) ? true : inc.autoInclusions === true), [inclusionTypes, permissionsAdminCache])
  const sellerReserveRequired = useMemo(() => (orgList ?? []).some(org => org.profitSplit !== 0), [orgList])
  const wgtWarningSchemaFields = useMemo(() => arrayUtils.differenceBy(roughSchema.createStepWgtWarningSchemaFields, [...sellerReserveRequired ? [] : ['reservePpcOriginal']], (x) => x), [sellerReserveRequired])
  const { MEASUREMENTS_TO_SUBMIT, TENSION_REQUIRED_ABOVE_WEIGHT, EYE_COLOUR_ABOVE_PRIMARY_SOURCE_WEIGHT } = useMemo(() => arrayUtils.toMap(appSettings || [], (x) => x.key, 'value'), [appSettings])

  const steps = useMemo(() => ([
    {
      title: 'Country & Mine',
      component: countryMineStep,
      validationProps: {
        validationSchema: {
          warning: roughSchema.createStepProvWarningSchema({
            provenanceType: assortment?.ProvenanceType,
            minesList: arrayUtils.pickBy(mineList, 'id')
          }),
          error: roughSchema.createStepProvErrorSchema
        }
      }
    },
    {
      title: 'Weight & Price',
      component: priceWeightStep,
      validationProps: {
        validationSchema: {
          warning: roughSchema.createStepWgtWarningSchema(wgtWarningSchemaFields)
        }
      }
    },
    {
      title: 'Measurements',
      component: measurementStep,
      validationProps: {
        validationSchema: {
          warning: roughSchema.createStepMeasWarningSchema({
            provenanceType: assortment?.ProvenanceType,
            eyeMeasurementColours: arrayUtils.pickBy(eyeMeasurementColours ?? [], 'value'),
            eyeMeasurementFluorescences: arrayUtils.pickBy(eyeMeasurementFluorescences ?? [], 'value'),
            eyeMeasurementTinges: arrayUtils.pickBy(eyeMeasurementTinges ?? [], 'value'),
            inclusionTypes: arrayUtils.pickBy(filteredInclusionTypes ?? [], 'id'),
            inclusionReductions: arrayUtils.pickBy(inclusionReductions ?? [], 'id'),
            roughScanTypes: arrayUtils.pickBy(roughScanTypes ?? [], 'value'),
            roughTensions: arrayUtils.pickBy(roughTensions ?? [], 'value'),
            roughTypes: arrayUtils.pickBy(roughTypes ?? [], 'value'),
            minMeasurements: MEASUREMENTS_TO_SUBMIT,
            tensionRequiredAboveWeight: TENSION_REQUIRED_ABOVE_WEIGHT,
            eyeColourAbovePrimarySourceWeight: EYE_COLOUR_ABOVE_PRIMARY_SOURCE_WEIGHT,
            // For calculateRoughMeasurements
            roughColours: roughColours ?? [],
            roughFluorescences: roughFluorescences ?? [],
            polishedFluorescences: polishedFluorescences ?? [],
            roughTinges: roughTinges ?? []
          })
        },
        schemaContext: {
          cache: measurementValidationCache
        }
      }
    },
    {
      title: 'Upload',
      component: uploadStep,
      validationProps: {
        validationSchema: {
          warning: roughSchema.createStepAdvWarningSchema
        }
      }
    }
  ]), [assortment, mineList, pricePoints, weightCategories, eyeMeasurementColours, eyeMeasurementFluorescences, eyeMeasurementTinges, roughScanTypes, roughTensions, filteredInclusionTypes, inclusionReductions, roughColours, roughFluorescences, polishedFluorescences, roughTinges, MEASUREMENTS_TO_SUBMIT, TENSION_REQUIRED_ABOVE_WEIGHT, EYE_COLOUR_ABOVE_PRIMARY_SOURCE_WEIGHT, wgtWarningSchemaFields])

  const { stateSchema, formData } = useMemo(() => {
    const res = {}
    res.formData = objectUtils.filterNullish({
      assortmentId,
      sellerId: assortment?.sellerId,
      inclusionReductionsId: inclusionReductions.find(i => i.default === 'DEFAULT')?.id,
      location: 'Seller',
      type: roughTypes?.find(t => t.default)?.value
    })
    res.stateSchema = Object.keys(res.formData)
    return res
  }, [assortmentId, assortment, inclusionReductions, roughTypes])

  return (
    <div className="create-rough-stone__container">
      <div className="col-xl-5 col-lg-9 col-md-10 col-sm-12 create-rough-stone__box">
        <MultiStepsForm
          steps={steps}
          formData={formData}
          onSubmit={roughStoneActions.createRoughStone}
          stateSchema={stateSchema}
          sharedProps={{
            assortment,
            countryList,
            mineList,
            pipeList,
            batchList,
            weightCategories,
            pricePoints,
            inclusionTypes: filteredInclusionTypes,
            inclusionReductions,
            eyeMeasurementColours,
            eyeMeasurementFluorescences,
            eyeMeasurementTinges,
            roughScanTypes,
            roughTensions,
            roughTypes
          }}
        />
      </div>
    </div>
  )
}

CreateRoughStone.propTypes = {
  formData: PropTypes.object,
  match: PropTypes.object
}

CreateRoughStone.defaultProps = {
  formData: null
}

export default CreateRoughStone
