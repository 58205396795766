import React, { useState, useEffect } from 'react'
import { Calendar } from '@organisms'
import { roughStoneActions, eventActions } from '@actions'
import { useAuthStore } from '@stores'

// TODO: extract general and detailed ranges to components
function Inventory() {
  const [roughAvailabilityList, setRoughAvailabilityList] = useState([])
  const [eventList, setEventList] = useState([])
  const coloursEnum = ['DEF', 'GHI', 'JK']
  const tingesEnum = ['Type IIa Brown', 'TLB/LB']
  const { hasAdmin } = useAuthStore()
  const isAdmin = hasAdmin(eventActions.getEvents)

  useEffect(() => {
    roughStoneActions.getAvailability()
    .then(response => setRoughAvailabilityList(response.data.data))
  }, [])

  useEffect(() => {
    eventActions.getEvents()
    .then(response => setEventList(response.data.data))
  }, [])

  function renderColoursTinges(coloursTinges, coloursTingesEnum) {
    if (!coloursTinges || !coloursTingesEnum) return null
    const totalQty = Object.keys(coloursTinges).reduce((qty, colour) => {
      qty += coloursTinges[colour].count
      return qty
    }, 0)

    const coloursTingesQty = coloursTingesEnum.map(colour => {
      return {
        name: colour,
        qty: coloursTinges[colour]?.count || 0,
        percentage: Number(coloursTinges[colour]?.count) / Number(totalQty) * 100 || 0
      }
    })

    return (
      <>
        {
          coloursTingesQty.map((colourTinge, index) => {
            return (
              <span
                key={index}
                className='inventory__detailed-ranges-table-column--no-border'
              >
                {formatColourTingeRange(Math.round(colourTinge.percentage))}
              </span>
            )
          })
        }
      </>
    )
  }

  function formatColourTingeQuantity(quantity) {
    if (quantity <= 50) { return '< 50' }
    if (quantity <= 100) { return '50 - 100' }
    if (quantity <= 150) { return '100 - 150' }
    if (quantity <= 200) { return '150 - 200' }
    if (quantity > 200) { return '> 200' }
  }

  function formatColourTingeRange(percentage) {
    if (percentage <= 10) { return '0 - 10%' }
    if (percentage <= 20) { return '10 - 20%' }
    if (percentage <= 30) { return '20 - 30%' }
    if (percentage <= 40) { return '30 - 40%' }
    if (percentage <= 50) { return '40 - 50%' }
    if (percentage <= 60) { return '50 - 60%' }
    if (percentage <= 70) { return '60 - 70%' }
    if (percentage <= 80) { return '70 - 80%' }
    if (percentage <= 90) { return '80 - 90%' }
    if (percentage <= 100) { return '90 - 100%' }
  }

  function filterNotVisible(eventList) {
    return eventList.filter(event => event.visible)
  }

  return (
    <div className='inventory'>
      <div className="inventory__calendar-container">
        <div className="inventory__calendar-title">Events Calendar</div>
        <div className="inventory__calendar">
          <Calendar
            eventList={isAdmin ? eventList : filterNotVisible(eventList)}
          />
        </div>
      </div>
      <div className="inventory__ranges-container">
        <div className="inventory__ranges-container-title">Available Rough Diamonds</div>
        <div className="inventory__ranges">
          <div className="inventory__general-ranges">
            <div className="inventory__ranges-title">General Ranges</div>
            <div className="inventory__ranges-row">
              <span className="label">Color</span> <span className="p2">D to M</span>
            </div>
            <div className="inventory__ranges-row">
              <span className="label">Tinge</span> <span className="p2">Type IIa Brown (D E F), TLB, LB</span>
            </div>
            <div className="inventory__ranges-row">
              <span className="label">Fluorescence</span> <span className="p2">None to Strong Blue</span>
            </div>
            <div className="inventory__ranges-row">
              <span className="label">Clarity</span> <span className="p2">VVS1 to I2</span>
            </div>
          </div>
          <div className="inventory__detailed-ranges">
            <div className="inventory__ranges-title">Detailed Ranges</div>
            <div className={`inventory__detailed-ranges-table ${isAdmin ? 'quad-col' : 'tri-col'}`}>
              <div className="inventory__detailed-ranges-table-title">
                Weight
              </div>
              {isAdmin && (
                <div className="inventory__detailed-ranges-table-title">
                  Quantity
                </div>
              )}
              <div className="inventory__detailed-ranges-table-title">
                Color
              </div>
              <div className="inventory__detailed-ranges-table-title">
                Tinge
              </div>
              <div className="inventory__detailed-ranges-table-title">
              </div>
              {isAdmin && (
                <div className="inventory__detailed-ranges-table-title">
                </div>
              )}
              <div className='inventory__detailed-ranges-table-colour-header'>
                {
                  coloursEnum.map((colour, index) => {
                    return <span
                      key={index}
                      className='inventory__detailed-ranges-table-title'>
                      {colour}
                    </span>
                  })
                }
              </div>
              <div className='inventory__detailed-ranges-table-tinge-header'>
                {
                  tingesEnum.map((tinge, index) => {
                    return <span
                      key={index}
                      className='inventory__detailed-ranges-table-title'
                    >
                      {tinge}
                    </span>
                  })
                }
              </div>
              {
                roughAvailabilityList.map((roughAvailability, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className='inventory__detailed-ranges-table-column' >{roughAvailability.name}</div>
                      {isAdmin && (
                        <div className='inventory__detailed-ranges-table-column'>
                          {formatColourTingeQuantity(roughAvailability.count)}</div>
                      )}
                      <div className='inventory__detailed-ranges-table-colour'>
                        {renderColoursTinges(roughAvailability?.colors, coloursEnum)}
                      </div>
                      <div className='inventory__detailed-ranges-table-tinge'>
                        {renderColoursTinges(roughAvailability?.colors, tingesEnum)}
                      </div>
                    </React.Fragment>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Inventory
