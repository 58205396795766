import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from '@atoms'
import { Dropdown } from '@molecules'
import { useTableStore } from '@stores'
import { objectUtils } from '@utils'

function TableTopBar({
  title,
  rows,
  canExport,
  tableId,
  topBarActions,
  isMultiSelect,
  refreshTableData,
  selectedFlatRows,
  topBarCustomComponents,
  topBarSelectedInfo,
  onTabChange,
  exportToExcel
}) {
  const {
    getTableConfig,
    setTableConfig
  } = useTableStore(state => state)

  const tableConfig = getTableConfig(tableId) || {}
  const {
    activeTab,
    tableTabs,
    getDataCallback
  } = tableConfig
  const isExportDisabled = useMemo(() => !rows?.length, [rows])

  function handleOnTabChange(tab) {
    if (activeTab.label !== tab.label) {
      activeTab.id = tab.id
      activeTab.label = tab.label
      activeTab.params = tab.params
      if (getDataCallback) {
        getDataCallback(tab.params)
      }
      setTableConfig(tableId, tableConfig)
    }
    if (onTabChange) {
      return onTabChange(tab)
    }
  }

  function handleOptionSelect(e, action) {
    const value = e.currentTarget.value
    const option = action.options.find(option => option.value === value)
    if (option) {
      option.callback(getSelectedRows(), getCallbackProps())
    }
  }

  function getSelectedRows() {
    return selectedFlatRows.map(row => row.original)
  }

  function getCallbackProps() {
    return {
      activeTab,
      refreshTableData,
      selectedRows: getSelectedRows()
    }
  }

  const selectedText = useMemo(() => {
    if (!isMultiSelect) return null
    return (
      <>
        <div>{selectedFlatRows.length} selected</div>
        {topBarSelectedInfo && <div className="ml-1">{objectUtils.callProp(topBarSelectedInfo, [selectedFlatRows])}</div>}
      </>
    )
  }, [isMultiSelect, topBarSelectedInfo, selectedFlatRows])

  return (
    <>
      <div className="table__top-bar-row">
        <div className={'table__top-bar'}>
          <div className="table__top-bar-left-wing">
            <div className="table__top-bar-title">{title}</div>
            {
              tableTabs?.length
                ? (
                  <div className="table__top-bar-tabs">
                    {
                      tableTabs
                      .filter(tab => !tab.hide)
                      .map((tab, index) => {
                        return <div
                          key={index}
                          onClick={() => {
                            if (!tab.disabled) handleOnTabChange(tab)
                          }}
                          className={`table__top-bar-tabs-item${!tab.disabled && activeTab?.label === tab.label ? '--active' : ''}${tab.disabled ? '--disabled' : ''} `}>
                          {tab.label}
                        </div>
                      })
                    }
                  </div>
                )
                : null
            }
            {
              refreshTableData
                ? <Icon
                  name='refresh'
                  onClick={async () => await refreshTableData()}
                />
                : null
            }
          </div>
          {
            canExport
              ? (<div className="table__top-bar-right-wing">
                <div className="table__top-bar-export-icon">
                  <Icon
                    name='excel'
                    title={isExportDisabled ? 'No rows to be exported' : 'Export'}
                    disabled={isExportDisabled}
                    onClick={exportToExcel}
                    size='lg'
                  />
                </div>
              </div>) : null}
        </div>
      </div>
      <div className="table__top-bar">
        <div className="table__top-bar-right-wing">
          <div>
            <span className="table__top-bar-selected-count">
              {selectedText}
            </span>
          </div>
          <div className='table__top-bar-action-buttons'>
            {topBarActions?.map((action, index) => {
              if (String(action?.componentName).toLowerCase() === 'dropdown') {
                return (
                  <div key={index} className='table__top-bar-dropdown'>
                    <Dropdown
                      isClearable={false}
                      placeholder={action?.label ?? 'Actions...'}
                      creatable={false}
                      size='sm'
                      options={action.options.map(opt => {
                        if (typeof opt.isDisabled === 'function') return { ...opt, isDisabled: opt.isDisabled(selectedFlatRows) }
                        return opt
                      })}
                      onChange={(e) => handleOptionSelect(e, action)}
                      disabled={action?.enableOnSelect && !selectedFlatRows.length}
                    />
                  </div>
                )
              } else {
                return (
                  <div key={index}>
                    <Button
                      size='sm'
                      onClick={() => action?.callback(getCallbackProps())}
                      typeVariant={action.typeVariant ?? 'secondary'}
                      disabled={action.disabled || (action?.enableOnSelect && !selectedFlatRows.length)}
                    >
                      {action?.label}
                    </Button>
                  </div>
                )
              }
            })}
          </div>
          {topBarCustomComponents.map((customComponent, index) => {
            return (
              <customComponent.component
                key={index}
                selectedRowsData={selectedFlatRows.map(row => row.values)}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

TableTopBar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tableId: PropTypes.string,
  onTabChange: PropTypes.func,
  isMultiSelect: PropTypes.bool,
  canExport: PropTypes.bool,
  refreshTableData: PropTypes.func,
  exportToExcel: PropTypes.func,
  rows: PropTypes.arrayOf(PropTypes.object),
  topBarActions: PropTypes.arrayOf(PropTypes.object),
  selectedFlatRows: PropTypes.arrayOf(PropTypes.object),
  topBarCustomComponents: PropTypes.arrayOf(PropTypes.object),
  topBarSelectedInfo: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.object])
}

TableTopBar.defaultProps = {
  rows: [],
  title: '',
  tableId: '',
  topBarActions: [],
  selectedFlatRows: [],
  canExport: true,
  isMultiSelect: false,
  topBarCustomComponents: []
}

export default TableTopBar
