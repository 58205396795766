import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { notificationsActions } from '@actions'
import { DetailsPage } from '@templates'
import { usePageTitle, useToast } from '@hooks'
import moment from 'moment'
import { textUtils } from '@utils'
import { NotificationAttachmentList } from '@pages'

function EmailNotificationDetails({ match, title }) {
  const { emailId } = match?.params
  const [notification, setNotification] = useState(null)
  const { showSuccessToast } = useToast()
  usePageTitle(title, emailId, notification?.subject)

  useEffect(() => {
    refreshEmailNotifications()
  }, [])

  const fields = useMemo(() => {
    return [
      {
        label: 'Subject',
        name: 'subject',
        value: notification?.subject,
        canEdit: true
      },
      {
        label: 'From',
        name: 'sender.from',
        value: notification?.from,
        canEdit: true
      },
      {
        label: 'Send Time',
        name: 'sendTime',
        type: 'datetime-local',
        value: textUtils.formatDate(notification?.sendTime, moment.HTML5_FMT.DATETIME_LOCAL),
        canEdit: true
      },
      {
        label: 'Reply To',
        name: 'sender.replyTo',
        value: notification?.replyTo,
        canEdit: true
      },
      {
        label: 'Body',
        name: 'body',
        value: notification?.body,
        componentName: 'textArea',
        canEdit: true,
        span: true
      },
      {
        legend: 'Recipients',
        componentName: 'fieldset',
        name: 'recipient',
        span: true,
        canEdit: true,
        children: [
          {
            label: 'To',
            name: 'recipient.tos',
            value: notification?.tos,
            canEdit: true
          },
          {
            label: 'Cc',
            name: 'recipient.ccs',
            value: notification?.ccs,
            canEdit: true
          },
          {
            label: 'Bcc',
            name: 'recipient.bccs',
            value: notification?.bccs,
            canEdit: true
          }
        ]
      },
      {
        name: 'attachments',
        value: notification?.attachments || [],
        isDetailsPage: true,
        canEdit: true,
        customComponent: NotificationAttachmentList,
        span: true
      },
      {
        label: 'Created At',
        name: 'createdAt',
        value: textUtils.formatDate(notification?.createdAt, true),
        canEdit: false
      },
      {
        label: 'Updated At',
        name: 'updateAt',
        value: textUtils.formatDate(notification?.updatedAt, true),
        canEdit: false
      }
    ]
  }, [notification])

  function handleOnSubmit(editedValues) {
    return notificationsActions.editEmailNotification(emailId, editedValues)
    .then(() => {
      showSuccessToast('Notification updated.')
      refreshEmailNotifications()
    })
  }

  function refreshEmailNotifications() {
    notificationsActions.getEmailNotifications({ emailId })
    .then(response => setNotification(response.data.data[0]))
  }

  return (
    <DetailsPage
      title={{
        label: 'Notification ID',
        value: notification?.emailId || ''
      }}
      onSubmit={handleOnSubmit}
      canEdit={!notification?.sentMessageId}
      fields={fields}
    />
  )
}

EmailNotificationDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string
}

export default EmailNotificationDetails
