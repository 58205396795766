import React, { useState, useEffect } from 'react'
import { Table } from '@organisms'
import { cutGradeActions, shapeGroupActions } from '@actions'
import { useGlobalsStore, useModalStore } from '@stores'
import { NavLink } from 'react-router-dom'
import AssignShapes from './modals/assignShapes'
import { useToast } from '@hooks'
import { arrayUtils } from '@utils'

function ShapeGroupShapesList({ shapeGroupId }) {
  const [shapeList, setShapeList] = useState([])
  const {
    orgsList: { all: orgsList },
    getOrgsList
  } = useGlobalsStore(state => state)
  const { setModal } = useModalStore()
  const { showSuccessToast } = useToast()

  useEffect(() => {
    getOrgsList()
  }, [])

  useEffect(() => {
    if (shapeGroupId) refreshList(shapeGroupId)
  }, [shapeGroupId])

  function refreshList(id) {
    cutGradeActions.getShapeList({ condition: 'ACTIVE', shapeGroupId: id })
    .then(response => setShapeList(response.data.data))
    .catch(err => console.error(err))
  }

  async function handleAssignShapes(form) {
    await shapeGroupActions.setShapeGroupShapes({ shapeGroupId, ...form })
    showSuccessToast('Shape Group created.')
    refreshList(shapeGroupId)
  }

  const [columns, setColumns] = useState([])
  useEffect(() => {
    setColumns([
      {
        Header: 'ID',
        accessor: 'id',
        dataType: 'string',
        Cell: cellInfo => {
          return (
            <NavLink
              className="link"
              to={`/shapes/${cellInfo.row.original.id}`}
              id={cellInfo.row.original.id}
            >
              {cellInfo.value.slice(0, 4)}
            </NavLink>
          )
        }
      },
      {
        Header: 'Name',
        accessor: 'name',
        dataType: 'string'
      },
      {
        Header: 'Owner',
        accessor: (row) => row.buyerId == null ? '* Preloaded *' : (orgsList?.find(org => org.orgId === row.buyerId)?.commonName || ''),
        dataType: 'string',
        id: 'owner'
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
        dataType: 'date',
        filterType: 'date'
      }
    ])
  }, [shapeList])

  async function handleRemove(row) {
    await shapeGroupActions.setShapeGroupShapes({ shapeGroupId, shapeIds: arrayUtils.pickBy(arrayUtils.differenceBy(shapeList, [row], x => x.id), 'id') })
    showSuccessToast('Shape group has been removed.')
    refreshList(shapeGroupId)
  }

  const rowActions = [
    {
      actionName: 'remove',
      callback: handleRemove,
      shouldConfirm: true
    }
  ]

  const topBarActions = [{
    label: 'Manage Shapes',
    callback: () => setModal({
      id: 'assignShapeGroupShapes',
      title: 'Manage Shapes',
      type: 'form',
      customMessageRenderer: (messageProps) => <AssignShapes {...messageProps} shapeList={shapeList} orgsList={orgsList} />,
      onSubmit: async (res) => await handleAssignShapes(res[0])
    })
  }]

  const initialSort = [{
    id: 'updatedAt',
    desc: true
  },
  {
    id: 'owner',
    desc: true
  },
  {
    id: 'name',
    desc: false
  }]

  return <Table
    title='Shapes'
    data={shapeList}
    columns={columns}
    getDataCallback={() => refreshList(shapeGroupId)}
    isMultiSelect={false}
    rowActions={rowActions}
    topBarActions={topBarActions}
    initialSort={initialSort}
  />
}

export default ShapeGroupShapesList
