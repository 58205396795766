import React from 'react'
import PropTypes from 'prop-types'

function DownloadLink({
  name,
  onClick,
  label,
  text,
  url
}) {
  return (
    <div className='link-component'>
      <span className="label">{label}</span>
      <a onClick={onClick} name={name} href={url}>{text || url}</a>
    </div>
  )
}

DownloadLink.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func
}

DownloadLink.defaultProps = {
  url: '',
  name: '',
  text: '',
  label: ''
}

export default DownloadLink
