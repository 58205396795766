import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import OrderPricingRanges from './orderPricingRanges'
import { Button } from '@atoms'
import ImportOrderModal from '../importOrderModal'
import CopyOrderModal from '../copyOrderModal'
import ExportPriceTemplate from '../exportPriceTemplate'
import { orderActions } from '@actions'
import { useModalStore, useOrderStore, useTabsStore } from '@stores'
import { InfoTip } from '@molecules'

function OrderPricing({ value, onChange, name, showCosts, disabled, fluorGridRef, validationText, removeValidationText }) {
  const [importModalOpen, setImportModalOpen] = useState(false)
  const [copyModalOpen, setCopyModalOpen] = useState(false)
  const [recalcId, setRecalcId] = useState(null)
  const weightPriceScheme = useMemo(() => value.map(({ minWeight, maxWeight }) => ({ minWeight, maxWeight })), [value])
  const { setModal } = useModalStore()
  const ordersParams = { condition: 'ACTIVE', columns: '[id, name]' }
  const { setActiveTabIndex } = useTabsStore(state => state)
  const {
    ordersList: { [ordersParams ? JSON.stringify(ordersParams) : 'all']: ordersList },
    getOrdersList,
    gridSize,
    pmColours,
    setActivePricingTabIndex
  } = useOrderStore(store => store)

  useEffect(() => {
    getOrdersList(ordersParams)
  }, [])

  useEffect(() => {
    setActiveTabIndex('orderPricingRange', 0)
    setActivePricingTabIndex(0)
  }, [])

  function handleChange(value) {
    onChange({ currentTarget: { name, value } })
  }

  async function copyPricing(orderId) {
    const order = (await orderActions.getOrderList({ id: orderId, columns: '[id, priceSchemeType, priceScheme]' })).data.data[0]
    onChange({ currentTarget: { name: 'priceScheme', value: order.priceScheme } })
    removeValidationText('priceScheme')
    setRecalcId(order.id)
  }

  return (
    <>
      <div className='order-pricing'>
        <h5>Order Pricing <InfoTip name="pricingInstructions"/></h5>
        {
          !disabled
            ? (
              <div className='order-pricing-step-top-buttons'>
                <Button size='sm' onClick={() => setImportModalOpen(true)}>Upload Excel Pricing</Button>
                <Button size='sm' typeVariant='secondary' onClick={() => {
                  setModal({
                    id: 'orderPricingExportOrder',
                    title: 'Generate Excel Pricing Template',
                    customMessageRenderer: ({ handleSubmit, handleCancel }) => (
                      <ExportPriceTemplate
                        initialPriceScheme={weightPriceScheme}
                        initialGridSize={gridSize}
                        initialPmColours={pmColours}
                        showCosts={showCosts}
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                      />
                    ),
                    customButtonsRenderer: () => null,
                    closeable: false
                  })
                }}>Generate Pricing Template</Button>
                <Button size='sm' typeVariant='secondary' onClick={() => setCopyModalOpen(true)}>Copy From Existing Order</Button>
              </div>
            )
            : null
        }
        <OrderPricingRanges
          priceScheme={value}
          onChange={handleChange}
          recalcId={recalcId}
          showCosts={showCosts}
          disabled={disabled}
          fluorGridRef={fluorGridRef}
          validationText={validationText}
        />
      </div>
      <CopyOrderModal
        orders={ordersList?.data}
        open={copyModalOpen}
        onSubmit={orderId => copyPricing(orderId)}
        onClose={() => setCopyModalOpen(false)}
        setRecalcId={setRecalcId}
      />
      <ImportOrderModal
        open={importModalOpen}
        onClose={() => setImportModalOpen(false)}
        onSubmit={priceScheme => {
          setRecalcId(Math.random())
          handleChange(priceScheme)
        }}
      />
    </>
  )
}

OrderPricing.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  recalcId: PropTypes.any,
  showCosts: PropTypes.bool,
  disabled: PropTypes.bool,
  validationText: PropTypes.object,
  removeValidationText: PropTypes.func
}

OrderPricing.defaultProps = {
  value: []
}
export default OrderPricing
