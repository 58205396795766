import React from 'react'
import PropTypes from 'prop-types'

function ErrorBanner({ classNames, warning, error }) {
  function toArray(val) {
    if (typeof val === 'string') return [error]
    if (Array.isArray(val)) return Array.from(new Set(val))
    if (typeof val === 'object') return toArray(Object.values(val).flat())
    return []
  }

  if (error) {
    return <div className='error-banner-container'>
      {(toArray(error).map((valText, idx) =>
        <div key={idx} className={`error-banner ${classNames?.join(' ')}`}>
          {valText}
        </div>
      ))}
    </div>
  }
  if (warning) {
    return <div className='error-banner-container'>
      {(toArray(warning).map((valText, idx) =>
        <div key={idx} className={`error-banner ${classNames?.join(' ')}`}>
          {valText}
        </div>
      ))}
    </div>
  }
  return null
}

ErrorBanner.propTypes = {
  classNames: PropTypes.arrayOf(PropTypes.string),
  warning: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string])
}

ErrorBanner.defaultProps = {

}

export default ErrorBanner
