import create from 'zustand'
import { roughStoneActions, assortmentActions } from '@actions'
import { cacheList } from './storeUtils'
import { objectUtils } from 'utils/utils'

const roughStonesListPromise = {}
const qcRoughStonesListPromise = {}
const assortmentsListPromise = {}

const initialValues = {
  /*
  roughStonesList structure
    roughStonesList: {
      [paramString]: {
        data: [/roughStones objects/],
        lastUpdated: /date-time of last request/,
        cacheInvalid: /bool flag to indicate if data has been manipulated/
      }
      ...
    }
  */
  roughStonesList: {},
  /*
  qcRoughStonesList structure
    qcRoughStonesList: {
      [paramString]: {
        data: [/roughStones objects/],
        lastUpdated: /date-time of last request/,
        cacheInvalid: /bool flag to indicate if data has been manipulated/
      }
      ...
    }
  */
  qcRoughStonesList: {},
  assortmentsList: {}
}

const useRoughStoneStore = create((set, get) => ({
  ...initialValues,
  /*
  cacheList:
    getRoughStonesList,
    setRoughStonesList,
    removeRoughStonesListItems,
    removeRoughStonesList,
    removeAllRoughStonesListItems,
    removeAllRoughStonesList,
  */
  ...cacheList({
    state: 'roughStonesList',
    zustandMethods: { get, set },
    promise: roughStonesListPromise,
    batchAction: roughStoneActions.getRoughStoneListBatch
  }),

  /*
  cacheList:
  getQcRoughStonesList,
  setQcRoughStonesList,
  removeQcRoughStonesListItems,
  removeQcRoughStonesList,
  removeAllQcRoughStonesListItems,
  removeAllQcRoughStonesList,
  */
  ...cacheList({
    state: 'qcRoughStonesList',
    zustandMethods: { get, set },
    promise: qcRoughStonesListPromise,
    batchAction: roughStoneActions.getQcRoughStoneListBatch
  }),

  setAssortmentsList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!assortmentsListPromise[paramString]) {
      assortmentsListPromise[paramString] = assortmentActions.getAssortmentList(params).finally(() => {
        assortmentsListPromise[paramString] = null
      })
    }
    const queryResults = await assortmentsListPromise[paramString]
    if (!queryResults) return
    set({ assortmentsList: { ...get().assortmentsList, [paramString]: queryResults.data.data } })
  },
  getAssortmentsList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!get().assortmentsList[paramString]) await get().setAssortmentsList(params)
    return get().assortmentsList[paramString]
  },
  resetAssortmentsList: (params) => {
    let assortmentsList = {}
    if (params === 'all') {
      assortmentsList = objectUtils.omit(get().assortmentsList, ['all'], false)
    } else if (params && Object.keys(params).length) {
      assortmentsList = objectUtils.omit(get().assortmentsList, [JSON.stringify(params)], false)
    }
    set({ assortmentsList })
  },
  resetRoughStoneStore: () => {
    set({ ...initialValues })
  }
}))

export {
  useRoughStoneStore
}
