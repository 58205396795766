import create from 'zustand'
import { orderActions } from '@actions'
import { cacheList } from './storeUtils'

const ordersListPromise = {}

const useOrderStore = create((set, get) => ({
  painterValue: null,
  setPainterValue: (painterValue) => {
    set({ painterValue })
  },
  activePricingTabIndex: 0,
  setActivePricingTabIndex: (activePricingTabIndex) => {
    set({ activePricingTabIndex })
  },
  isPainterEnabled: false,
  setIsPainterEnabled: (isPainterEnabled) => {
    set({ isPainterEnabled })
  },
  painterFluorValue: undefined,
  setPainterFluorValue: (painterFluorValue) => {
    set({ painterFluorValue })
  },
  isFluorMultiPainterEnabled: false,
  setIsFluorMultiPainterEnabled: (isFluorMultiPainterEnabled) => {
    set({ isFluorMultiPainterEnabled })
  },
  resetPainter: () => {
    set({
      isPainterEnabled: false,
      painterFluorValue: undefined,
      isFluorMultiPainterEnabled: false
    })
  },
  disablePainter: () => {
    set({
      isPainterEnabled: false,
      isFluorMultiPainterEnabled: false
    })
  },
  activeRange: { priceRange: null, colours: [], clarities: [] },
  setActiveRange: (activeRange) => {
    set({ activeRange })
  },
  gridSize: undefined,
  setGridSize: (gridSize) => {
    set({ gridSize })
  },
  pmColours: true,
  setPmColours: (pmColours) => {
    set({ pmColours })
  },
  resetPricingGrid: () => {
    set({ gridSize: undefined, pmColours: true })
  },
  fluorGridValues: null,
  setFluorGridValues: (fluorGridValues) => {
    set({ fluorGridValues })
  },
  /*
  ordersList structure
    ordersList: {
      [paramString]: {
        data: [/order objects/],
        lastUpdated: /date-time of last request/
        cacheInvalid: /bool flag to indicate if data has been manipulated/
      }
      ...
    }
  */
  ordersList: {},
  /*
    cacheList:
      getOrdersList,
      setOrdersList,
      removeOrdersListItems,
      removeOrdersList,
      removeAllOrdersListItems,
      removeAllOrdersList,
    */
  ...cacheList({
    state: 'ordersList',
    zustandMethods: { get, set },
    promise: ordersListPromise,
    batchAction: orderActions.getOrderListBatch
  }),
  resetOrderStore: () => {
    set({
      painterValue: '',
      activePricingTabIndex: 0,
      isPainterEnabled: false,
      painterFluorValue: undefined,
      isFluorMultiPainterEnabled: false,
      activeRange: { priceRange: null, colours: [], clarities: [] },
      gridSize: undefined,
      pmColours: true,
      ordersList: {}
    })
  }
}))

export {
  useOrderStore
}
