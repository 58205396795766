import React, { useState, useEffect, useMemo } from 'react'
import { Table } from '@organisms'
import { notificationsActions } from '@actions'
import { NavLink, useHistory } from 'react-router-dom'
import moment from 'moment'
import { useGlobalsStore } from '@stores'
import { COMMON } from '@constants'
import { textUtils } from '@utils'

function AppNotificationList() {
  const [appNotificationList, setAppNotificationList] = useState([])
  const [columns, setColumns] = useState([])
  const history = useHistory()
  const notificationTypes = COMMON.NOTIFICATION.TYPE

  const {
    getOrgsList,
    orgsList: { all: organizationList },
    getUsersList,
    usersList
  } = useGlobalsStore(store => store)

  const recipients = useMemo(() => {
    if (usersList?.length && organizationList?.length) {
      // loop userList
      return usersList.reduce((recipientsObj, user) => {
        // if userId doesn't exist, add to obj
        if (!recipientsObj[user?.userId]) {
          const org = organizationList.find(o => o.orgId === user?.orgId)
          recipientsObj[user?.userId] = `${user?.username} | ${org?.commonName}`
        }
        return recipientsObj
      }, {})
    }
  }, [usersList, organizationList])

  useEffect(() => {
    getOrgsList()
  }, [])

  useEffect(() => {
    getUsersList()
  }, [])

  useEffect(() => {
    fetchData()
  }, [])
  function fetchData() {
    notificationsActions.getAppNotifications()
    .then(response => setAppNotificationList(response.data.data))
  }

  useEffect(() => {
    setColumns([
      {
        accessor: 'title',
        Header: 'Title',
        Cell: cellInfo => {
          return (
            <NavLink
              className="link"
              to={`/notifications/app/${cellInfo.row.original.notificationId}`}
              id={cellInfo.row.original.notificationId}
            >
              {cellInfo.value}
            </NavLink>
          )
        }
      },
      {
        id: 'type',
        accessor: (row) => notificationTypes[row.type],
        Header: 'Type',
        filterType: 'checkbox',
        enums: Object.values(notificationTypes)
      },
      {
        accessor: row => recipients ? recipients[row.userId] : '',
        Header: 'Recipient'
      },
      {
        accessor: row => isSent(row),
        Header: 'Sent',
        dataType: 'boolean',
        filterType: 'checkbox'
      },
      {
        accessor: 'isRead',
        Header: 'Read',
        dataType: 'boolean',
        filterType: 'checkbox'
      },
      {
        key: 'condition',
        accessor: (row) => textUtils.formatDescription(row.condition),
        Header: 'Condition',
        filterType: 'checkbox'
      },
      {
        accessor: 'notifyTime',
        Header: 'Notify Time',
        dataType: 'dateTime',
        filterType: 'date'
      },
      {
        accessor: 'createdAt',
        Header: 'Created At',
        dataType: 'date',
        filterType: 'date'
      },
      {
        accessor: 'updatedAt',
        Header: 'Updated At',
        dataType: 'date',
        filterType: 'date'
      }
    ])
  }, [appNotificationList, usersList, organizationList, recipients])

  function isSent(notification) {
    return moment(notification?.notifyTime).diff(new Date()) <= 0
  }

  const topBarActions = [
    {
      label: 'Create Notification',
      callback: () => history.push('/notifications/create')
    },
    {
      label: 'Notification Templates',
      callback: () => history.push('/notifications/templates')
    }
  ]

  return (
    <Table
      title='Notifications'
      data={appNotificationList}
      columns={columns}
      getDataCallback={fetchData}
      topBarActions={topBarActions}
      isMultiSelect={false}
    />
  )
}

export default AppNotificationList
