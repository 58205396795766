import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { usePopper } from 'react-popper'
import { useOuterClick } from '@hooks'
import { Icon } from '@atoms'
import { useTableStore } from '@stores'
function FilterPopup({
  FilterComponent,
  ...props
}) {
  const [displayElement, setDisplayElement] = useState(null)
  const [popupElement, setPopupElement] = useState(null)
  const [visible, setVisible] = useState(false)
  const outerClickRef = useOuterClick(() => {
    setVisible(false)
  })
  const { styles, attributes } = usePopper(
    displayElement,
    popupElement,
    {
      strategy: 'fixed',
      modifiers: [
        { name: 'offset', options: { offset: [0, visible ? 0 : -135] } }]
    }
  )

  const {
    getIsFiltered,
    setFilterValue
  } = useTableStore(state => state)
  const isFiltered = getIsFiltered(props?.tableId, props?.column?.id)

  return (
    <div ref={outerClickRef} className="table-filter-popup" onClick={e => e.stopPropagation()}>
      <div className="table-filter-popup__header" onClick={() => setVisible(!visible)}>
        <button
          className="table-filter-popup__button"
          type="button"
          ref={setDisplayElement}
          onClick={() => setVisible(!visible)}
        >
          <Icon
            name={visible ? 'arrowDropDown' : 'arrowDropRight'}
            size='sm'
          />
          <span className={`table-filter-popup__text${isFiltered ? '--filtered' : ''}`}>
            Filter...
          </span>
        </button>
      </div>
      <div
        className={`table-filter-popup__content${visible ? '' : '--hidden'}`}
        ref={setPopupElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <FilterComponent
          visible={visible}
          {...props}
        />
      </div>
      {
        isFiltered ? (
          <Icon
            name='closeCircle'
            size='sm'
            className='table__filter-popup-close-icon'
            title='Clear Filter'
            onClick={() => {
              props.column.setFilter(undefined)
              setFilterValue(props?.tableId, props?.column?.id, undefined)
            }}
          />
        ) : null
      }
    </div>
  )
}

FilterPopup.propTypes = {
  FilterComponent: PropTypes.func
}

FilterPopup.defaultProps = {
}

export default FilterPopup
