import React from 'react'
import PropTypes from 'prop-types'

function FormChildren({ children, ...restProps }) {
  const wrappedChildren = React.Children.map(
    children,
    (child, idx) => {
      const { key, props: childProps = {} } = child
      const childKey = String(key ?? idx)
      // once we're confident this works as expected without side-effects, we can probably loosen or completely remove this validKey check
      const validKey = childKey.includes('row-')

      if (childProps && childProps.children && validKey) {
        return React.cloneElement(child,
          {
            key: childKey,
            ...childProps,
            // Wrap grandchildren too
            children: (
              <FormChildren {...restProps}>
                {child.props.children}
              </FormChildren>
            )
          }
        )
      }
      // dont pass props to dom elements that are standard html elements
      return typeof child.type === 'function' ? React.cloneElement(child, { key: childKey, ...restProps, ...childProps }) : child
    }
  )
  return wrappedChildren
}

FormChildren.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default FormChildren
