import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import InfoTip from '../infoTip/infoTip'
import { Button } from '@atoms'
import { ErrorIcon, ErrorText } from '@molecules'
import { arrayUtils, validationUtils } from '@utils'

function sanitizeEvent(event, name, isMulti) {
  const e = { currentTarget: {} }
  e.currentTarget.name = name || ''
  if (isMulti) {
    e.currentTarget.value = event?.map(e => e.value)
  } else {
    // checking only for null/undefined because we want 0 to be a valid value
    e.currentTarget.value = event?.value == null ? null : event.value
  }
  return e
}

function Dropdown({
  name,
  size,
  value,
  label,
  extraLabel,
  canAddAll,
  isMulti,
  options,
  required,
  onChange,
  disabled,
  visible,
  creatable,
  topActions,
  isClearable,
  menuPlacement,
  fallbackValue,
  validationText,
  infoTip,
  ...props
}) {
  const [validationTextByValue, setValidationTextByValue] = useState()
  const SelectComponent = creatable ? CreatableSelect : Select
  const sizeSuffix = {
    sm: '--sm',
    md: '--md',
    lg: '--lg'
  }[size] || '--lg'

  useEffect(() => {
    if (value !== undefined || isMulti) return
    const index = options.findIndex(opt => opt.default === 'DEFAULT')
    if (index >= 0) onChange(sanitizeEvent(options[index], name, isMulti))
  }, [options])

  const fieldValue = useMemo(() => value != null
    ? Array.isArray(value)
      ? value.map(x => ({
        value: x,
        label: getValueLabel(x)
      }))
      : {
        value,
        label: getValueLabel(value)
      }
    : (fallbackValue ?? undefined)
  , [value, fallbackValue, creatable, options, validationText])

  useEffect(() => {
    const warningIndices = Object.keys(validationText?.getWarning() || {})
    const errorIndices = Object.keys(validationText?.getError() || {})
    if ((warningIndices.length || errorIndices.length) && Array.isArray(fieldValue)) {
      setValidationTextByValue(validationUtils.validation({
        warning: warningIndices.reduce((warn, warnIdx) => ({ ...warn, [fieldValue[warnIdx].value]: validationText.getWarning([warnIdx]) }), {}),
        error: errorIndices.reduce((err, errIdx) => ({ ...err, [fieldValue[errIdx].value]: validationText.getError([errIdx]) }), {})
      }))
    }
  }, [validationText?.error, validationText?.warning])

  function getValueLabel(val = '') {
    const flatOptions = options.reduce((opts, opt) => opt.options ? opts.concat(opt.options) : opts.concat([opt]), [])
    const label = creatable
      ? (flatOptions.find(option => option.value === val)?.label || val)
      : flatOptions.find(option => option.value === val)?.label
    return <div className="d-flex align-items-center">
      {label}
      <ErrorIcon {...validationTextByValue?.parse?.([val])} />
    </div>
  }

  function topActionChangeHandler(valOptions, append = false) {
    return onChange(sanitizeEvent(append
      ? arrayUtils.unionBy(fieldValue || [], valOptions, (x) => x.value)
      : valOptions
    , name, true))
  }

  return (
    visible
    && <div className="dropdown">
      <div className="dropdown__container" onClick={e => e.stopPropagation()}>
        {
          label
            ? (
              <div className="dropdown__header">
                <div className="dropdown__label">
                  <span>{label}</span>
                  <small className="text-input__optional-label"> {extraLabel} </small>
                  {infoTip ? <InfoTip name={name}>{infoTip}</InfoTip> : null}
                  {!required && (
                    <small className="text-input__optional-label"> (optional) </small>
                  )}
                </div>
                <div className="dropdown__top-actions">
                  {
                  topActions?.length
                    ? (
                      topActions.map((action, index) => {
                        return (
                          <Button
                            key={index}
                            typeVariant='action'
                            onClick={(e) => action.onClick(topActionChangeHandler, e)}
                            size='sm'
                            disabled={action.disabled}
                          >
                            {action.label}
                          </Button>
                        )
                      })
                    ) : null
                  }
                  {
                    isMulti && canAddAll
                      ? (
                        <Button
                          typeVariant='action'
                          onClick={() => onChange(sanitizeEvent(options, name, isMulti))}
                          size='sm'
                          disabled={disabled}
                        >
                          Add all
                        </Button>
                      )
                      : null
                  }
                </div>
              </div>
            )
            : null
        }
        {
          <SelectComponent
            value={fieldValue}
            isMulti={isMulti}
            options={options}
            isDisabled={disabled}
            isClearable={isClearable}
            classNamePrefix="dropdown"
            menuPlacement={menuPlacement}
            menuPortalTarget={document.getElementById('react-select-portal')}
            className={`dropdown-container${sizeSuffix} ${validationText?.getValidationClass()}`}
            onChange={e => onChange(sanitizeEvent(e, name, isMulti))}
            {...props}
          />
        }
        <ErrorText {...validationText?.parse()} />
      </div>
    </div>
  )
}

Dropdown.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  isMulti: PropTypes.bool,
  canAddAll: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  extraLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
  required: PropTypes.bool,
  creatable: PropTypes.bool,
  isClearable: PropTypes.bool,
  menuPlacement: PropTypes.string,
  fallbackValue: PropTypes.string,
  topActions: PropTypes.arrayOf(PropTypes.object),
  validationText: PropTypes.object,
  infoTip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object, PropTypes.array]),
  value: PropTypes.oneOfType(
    [
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number)
    ]
  )
}

Dropdown.defaultProps = {
  name: '',
  type: 'md',
  value: null,
  options: [],
  topActions: [],
  extraLabel: '',
  isMulti: false,
  canAddAll: false,
  required: true,
  disabled: false,
  visible: true,
  creatable: false,
  fallbackValue: '',
  isClearable: true,
  menuPlacement: 'bottom'
}

export default Dropdown
