import React, { useMemo, useEffect, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@atoms'
import TableTopBar from './tableTopBar'
import TableHeader from './tableHeader'
import {
  dateRangeFilter,
  DateRangeFilter,
  dropdownFilter,
  DropdownFilter,
  NumberRangeFilter,
  TextAreaFilter,
  textAreaFilter,
  DefaultColumnFilter,
  CheckboxFilter,
  checkboxFilter
} from './filters/filters'
import {
  useTable,
  useSortBy,
  useFilters,
  useRowSelect,
  usePagination
} from 'react-table'
import TableBody from './tableBody'
import TableFooter from './tableFooter'
import { objectUtils, textUtils, validationUtils, fileUtils } from '@utils'
import { useTableStore } from '@stores'
import DefaultCell from './cells/defaultCell'
import clone from 'just-clone'
import Excel from 'exceljs/dist/es5/exceljs.browser'

const Table = React.forwardRef(function({
  id,
  data,
  title,
  canExport,
  columns,
  rowActions,
  topBarActions,
  onEditSubmit,
  isMultiSelect,
  initialPageSize,
  initialSort, // { id, desc }
  initialSelected,
  onTabChange,
  rowColourScheme,
  getDataCallback,
  resetDataCallback,
  topBarCustomComponents,
  topBarSelectedInfo,
  tableTabs,
  showFooter,
  validationProps: {
    validationSchema,
    schemaContext,
    onValidationChange
  }
}, ref) {
  const tableId = id || title
  const canEdit = !!onEditSubmit

  const {
    setTableConfig,
    getTableConfig,
    getFilterValue,
    resetTableStore
  } = useTableStore(state => state)

  const tableConfig = getTableConfig(tableId)
  const tableFilters = getFilterValue(tableId)

  useEffect(() => {
    const activeTab = tableConfig?.activeTab
    const finalTab = (tableTabs ?? []).reduce((obj, tab, idx) => {
      if (obj.def == null && !tab?.disabled) obj.def = idx
      if (obj.active == null && activeTab?.id != null ? activeTab?.id === tab?.id : activeTab?.label === tab.label) obj.active = idx
      return obj
    }, { def: undefined, active: undefined })
    const finalTabIdx = finalTab.active ?? finalTab.def ?? 0
    setTableConfig(tableId, {
      tableTabs,
      getDataCallback,
      activeTab: objectUtils.pick(tableTabs?.[finalTabIdx] || {}, ['id', 'label', 'params'], false)
    })
    // removing the current table config obj
    // from table store because we want
    // to access it's info when the current table
    // is mounted only. We might want to remove this
    // when implementing saved filter that must be persisted
    // Resetting edit values and editRowIndex as well
    return () => resetTableStore(tableId)
  }, [tableId, tableTabs, getDataCallback])

  async function refreshTableData() {
    const {
      activeTab,
      getDataCallback,
      tableTabs
    } = tableConfig || {}
    // In case table has tabs
    if (
      activeTab?.label
      && getDataCallback
      && tableTabs?.length
    ) {
      if (resetDataCallback) await resetDataCallback()
      await tableConfig.getDataCallback(activeTab.params)
      return
    }

    if (getDataCallback) {
      if (resetDataCallback) await resetDataCallback()
      await tableConfig.getDataCallback()
    }
  }

  const defaultColumn = useMemo(() => ({
    // set up our default Filter UI
    Filter: DefaultColumnFilter,
    Cell: DefaultCell
  }), [])

  // loop trough each column
  // and add the column filter
  // and add the column sortBy
  // based on the column data type
  function loadColumns(cols) {
    return clone(cols).map(column => {
      // sorting
      if (column.dataType === 'string') {
        column.sortType = (row1, row2, columnName) => textUtils.compareIgnoreCase(row1.values[columnName], row2.values[columnName])
      }
      // filters
      switch (column.filterType) {
        case 'date':
          column.Filter = DateRangeFilter
          column.filter = dateRangeFilter
          break
        case 'dateTime':
          column.Filter = DateRangeFilter
          column.filter = dateRangeFilter
          break
        case 'dropdown':
          column.Filter = DropdownFilter
          column.filter = dropdownFilter
          break
        case 'numberRange':
          column.Filter = NumberRangeFilter
          column.filter = 'between'
          break
        case 'checkbox':
          column.Filter = CheckboxFilter
          column.filter = checkboxFilter
          break
        case 'textarea':
          column.Filter = TextAreaFilter
          column.filter = textAreaFilter
          break
        default:
          column.Filter = DefaultColumnFilter
      }
      return column
    })
  }

  const initialFilters = useMemo(() => {
    if (!Object.keys(tableFilters).length) return []
    return Object.keys(tableFilters).map(filter => ({
      id: filter,
      value: tableFilters[filter]?.value ?? ''
    }))
  }, [tableFilters, tableConfig?.activeTab, tableTabs])

  const tableInstance = useTable(
    {
      tableId,
      data: useMemo(() => data, [data]),
      columns: useMemo(() => loadColumns(columns), [columns]),
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: initialPageSize,
        sortBy: initialSort || (
          // sort by last updated if possible
          columns.some(c => c.accessor === 'updatedAt') ? [
            { id: 'updatedAt', desc: true }
          ] : [{
            // otherwise first column is sorted by default
            id: columns[0]?.accessor,
            desc: false
          }]
        ),
        selectedRowIds: initialSelected,
        filters: initialFilters
      },
      autoResetFilters: false
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      if (!isMultiSelect) return null
      hooks.visibleColumns.push(columns => [
        {
          // Creates column for selection in header and body rows
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <Checkbox {...row.getToggleRowSelectedProps()} />
            </div>
          )
        },
        ...columns
      ])
    }
  )

  useImperativeHandle(ref, () => ({
    tableInstance
  }))

  const {
    rows,
    page,
    gotoPage,
    nextPage,
    prepareRow,
    canNextPage,
    pageOptions,
    setPageSize,
    headerGroups,
    previousPage,
    getTableProps,
    getTableBodyProps,
    selectedFlatRows,
    canPreviousPage,
    setAllFilters,
    state: { pageIndex }
  } = tableInstance

  const validationText = useMemo(() => {
    if (!validationSchema) return
    return (rows ?? []).reduce((map, { id, original }) =>
      map.set(id,
        validationUtils.validateSchemaSync(
          validationSchema,
          original,
          {
            form: original,
            ...schemaContext
          }
        )
      )
    , new Map())
    // TODO: This may cause problems to have a dependency on data
    // and to use rows within the function but the issue I am seeing is
    // that rows updates anytime we filter on the table without touching data
  }, [data, validationSchema, schemaContext])

  useEffect(() => {
    onValidationChange && onValidationChange({ validationText })
  }, [validationText])

  function exportToExcel(rows) {
    if (!rows?.length) return
    const columns = rows[0].cells
    .filter(c => c.column.id !== 'selection')
    .filter(c => !!c.column.Header)
    .map(c => ({
      header: c.column.Header,
      key: c.column.id,
      dataType: c.column.dataType,
      formatExcel: c.column.formatExcel,
      excelStyle: c.column.excelStyle
    }))
    if (!columns?.length) return

    function formatValue(value, dataType, formatFn) {
      if (value == null) return ''
      if (formatFn) return formatFn(value)
      if (dataType === 'boolean') return !value ? 'No' : 'Yes'
      if (dataType === 'date') return textUtils.formatDate(value)
      if (dataType === 'dateTime') return textUtils.formatDate(value, true)
      if (dataType === 'number' || dataType === 'currency') return isNaN(Number(value)) ? value : Number(value)
      return value
    }

    function getCellStyle(dataType, excelStyle) {
      if (excelStyle) return excelStyle
      if (dataType === 'currency') return { numFmt: '$#,##0.00', alignment: { horizontal: 'right' } }
      // if (dataType === 'number') return { numFmt: '#,##0.00#', alignment: { horizontal: 'right' } }
      return null
    }

    try {
      // We would like to use streaming for large tables, but it appears to be an open issue https://github.com/exceljs/exceljs/issues/1228
      // const bookStream = new TransformStream()
      // const workbook = new Excel.stream.xlsx.WorkbookWriter({ stream: bookStream, useStyles: true })
      const workbook = new Excel.Workbook()
      workbook.creator = 'Clara'
      workbook.created = new Date()
      const sheet = workbook.addWorksheet(title || 'Sheet1')
      sheet.columns = columns

      // Regular version
      const data = rows.reduce((data, row) => {
        const values = {}
        for (const key in row.values) {
          const column = columns?.find(c => c.key === key)
          if (column) {
            const { dataType, formatExcel } = column
            values[key] = formatValue(row.values[key], dataType, formatExcel)
          }
        }
        data.push(values)
        return data
      }, [])
      sheet.addRows(fileUtils.getExcelRows(data, columns))
      const colStyles = columns.map(col => getCellStyle(col.dataType, col.excelStyle))
      sheet.eachRow((row, rowNum) => {
        if (!rowNum) return
        row.eachCell((cell, colNum) => {
          if (colStyles[colNum - 1]) {
            for (const key in colStyles[colNum - 1]) cell[key] = colStyles[colNum - 1][key]
          }
        })
      })

      // Streaming version
      // for (const row of rows) {
      //   const values = {}
      //   for (const key in row.values) {
      //     const column = columns?.find(c => c.key === key)
      //     if (column) {
      //       const { dataType, formatExcel } = column
      //       values[key] = formatValue(row.values[key], dataType, formatExcel)
      //     }
      //   }
      //   const excelRow = sheet.addRow(row)
      //   excelRow.eachCell((cell, colNum) => {
      //     if (colStyles[colNum - 1]) {
      //       for (const key in colStyles[colNum - 1]) cell[key] = colStyles[colNum - 1][key]
      //     }
      //   })
      //   excelRow.commit()
      // }

      sheet.views = [{ state: 'frozen', ySplit: 1 }]
      sheet.autoFilter = {
        from: 'A1',
        to: { row: rows.length + 1, column: sheet.columns.length }
      }
      const fileName = typeof title === 'string' ? title : 'List Export'
      // sheet.commit()
      // workbook.commit()
      // fileUtils.saveBufferExcel(bookStream, `${fileName}.xlsx`)
      return workbook.xlsx.writeBuffer().then(buffer => fileUtils.saveBufferExcel(buffer, `${fileName}.xlsx`))
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="table__container">
      <TableTopBar
        title={title}
        rows={rows}
        canExport={canExport}
        tableId={tableId}
        topBarActions={topBarActions}
        isMultiSelect={isMultiSelect}
        onEditSubmit={onEditSubmit}
        onTabChange={onTabChange}
        setAllFilters={setAllFilters}
        selectedFlatRows={selectedFlatRows}
        topBarCustomComponents={topBarCustomComponents}
        topBarSelectedInfo={topBarSelectedInfo}
        refreshTableData={tableConfig?.getDataCallback ? refreshTableData : null}
        exportToExcel={() => exportToExcel(rows)}
      />
      <div className="table__inner-container">
        <table className="table" {...getTableProps()}>
          <TableHeader
            headerGroups={headerGroups}
            rowActions={rowActions}
          />
          <TableBody
            page={page}
            tableId={tableId}
            canEdit={canEdit}
            rowColourScheme={rowColourScheme}
            prepareRow={prepareRow}
            rowActions={rowActions}
            onEditSubmit={onEditSubmit}
            refreshTableData={refreshTableData}
            getTableBodyProps={getTableBodyProps}
            validationText={validationText}
          />
        </table>
      </div>
      {
        showFooter
          ? <TableFooter
            rows={rows}
            tableId={tableId}
            gotoPage={gotoPage}
            nextPage={nextPage}
            pageIndex={pageIndex}
            setPageSize={setPageSize}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            setAllFilters={setAllFilters}
            initialPageSize={initialPageSize}
            totalRowsCount={data?.length || data?.size}
          />
          : null
      }
    </div>
  )
})

Table.propTypes = {
  id: PropTypes.string,
  showFooter: PropTypes.bool,
  canExport: PropTypes.bool,
  onTabChange: PropTypes.func,
  onEditSubmit: PropTypes.func,
  initialSort: PropTypes.array,
  isMultiSelect: PropTypes.bool,
  getDataCallback: PropTypes.func,
  rowColourScheme: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  initialSelected: PropTypes.object,
  initialPageSize: PropTypes.number,
  resetDataCallback: PropTypes.func,
  validationProps: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.instanceOf(Map)]),
  columns: PropTypes.arrayOf(PropTypes.object),
  tableTabs: PropTypes.arrayOf(PropTypes.object),
  rowActions: PropTypes.arrayOf(PropTypes.object),
  topBarActions: PropTypes.arrayOf(PropTypes.object),
  topBarCustomComponents: PropTypes.arrayOf(PropTypes.object),
  topBarSelectedInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

Table.defaultProps = {
  id: '',
  data: [],
  columns: [],
  rowActions: [],
  showFooter: true,
  canExport: true,
  topBarActions: [],
  onEditSubmit: null,
  initialPageSize: 30,
  initialSelected: {},
  isMultiSelect: true,
  tableTabs: undefined,
  title: 'Table Title',
  getDataCallback: null,
  topBarCustomComponents: [],
  validationProps: {
    validationSchema: undefined,
    schemaContext: undefined,
    onValidationChange: undefined
  }
}

export default Table
