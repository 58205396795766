import React, { useEffect, useMemo } from 'react'
import { Checkbox } from '@atoms'
import { useTableStore } from '@stores'

function CheckboxFilter({
  column: { filterValue = {}, setFilter, id, enums, dataType },
  preFilteredRows,
  tableId
}) {
  const options = useMemo(() => {
    if (Array.isArray(enums) && enums?.length) return enums
    if (dataType === 'boolean') return ['True', 'False']

    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(String(row.values[id]))
    })
    return [...options.values()].sort((a, b) => {
      if (a == null || a === 'null' || a === 'undefined') return 1
      if (b == null || b === 'null' || b === 'undefined') return -1
      if (String(a).toUpperCase() > String(b).toUpperCase()) return 1
      if (String(a).toUpperCase() < String(b).toUpperCase()) return -1
      return 0
    })
  }, [id, preFilteredRows, enums])

  const {
    setFilterValue,
    setIsFilteredFn,
    getFilterValue,
    getTableConfig
  } = useTableStore(state => state)

  const storeFilterValue = getFilterValue(tableId, id)
  const tableConfig = getTableConfig(tableId)

  useEffect(() => {
    setFilter(storeFilterValue?.value)
  }, [storeFilterValue?.value])

  function setIsFiltered(filter) {
    if (!filter) return false
    return Object.keys(filter).some(f => filter[f])
  }

  useEffect(() => {
    setIsFilteredFn(tableId, id, setIsFiltered)
  }, [tableConfig?.activeTab?.id, tableConfig?.activeTab?.label])

  function handleOnFilter(e, option) {
    const filter = { ...storeFilterValue?.value, [option]: e?.currentTarget?.checked }
    setFilterValue(tableId, id, filter)
  }

  return (
    <div className='table-checkbox-filter'>
      {
        options.map((option, key) => (
          <Checkbox
            key={key}
            label={option != null && option !== 'null' && option !== 'undefined' ? option : '(blank)'}
            name={option}
            value={filterValue[option]}
            onChange={e => handleOnFilter(e, option)}
          />
        ))
      }
    </div>
  )
}

const checkboxFilter = (rows, columnId, filters) => {
  if (!filters || !Object.keys(filters).length || !columnId || !columnId.length) return rows
  if (Object.keys(filters).every(f => !filters[f])) return rows
  const _filters = Object.entries(filters).reduce((obj, [key, val]) => {
    return { ...obj, [key.toLowerCase()]: val }
  }, {})
  return rows.filter(row => row.values[columnId[0]] in filters ? filters[row.values[columnId[0]]] : _filters[String(row.values[columnId[0]]).toLowerCase()])
}

export {
  CheckboxFilter,
  checkboxFilter
}
