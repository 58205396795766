import axios from 'axios'
import { requestUtils } from '@utils'

const BASE_URL = process.env.REACT_APP_API_BASE_URL

const getOrderList = function (params, options) {
  return axios.get(`${BASE_URL}/buyer/orders`, { params, options })
}
getOrderList.endpoint = 'GET|/buyer/orders'

const getOrderListBatch = requestUtils.batchRequest(getOrderList)

const createOrder = function(order) {
  return axios.post(`${BASE_URL}/buyer/orders`, order)
}
createOrder.endpoint = 'POST|/buyer/orders'

const editOrder = function({ id, ...order }) {
  return axios.patch(`${BASE_URL}/buyer/orders/${id}`, order)
}
editOrder.endpoint = 'PATCH|/buyer/orders/:orderId:'

// params must include orderIds: []
const editMultipleOrders = function(params, options = {}) {
  return axios.patch(`${BASE_URL}/buyer/orders`, params, { options })
}
editMultipleOrders.endpoint = 'PATCH|/buyer/orders'

const setOrderCondition = function({ orderIds, buyerId, condition }) {
  return axios.put(`${BASE_URL}/buyer/orders/condition`, { orderIds, buyerId, condition })
}
setOrderCondition.endpoint = 'PUT|/buyer/orders/condition'

const getOrderBuyers = function (params) {
  return axios.get(`${BASE_URL}/buyer/orders/orgs`, { params })
}
getOrderBuyers.endpoint = 'GET|/buyer/orders/orgs'

const suspendBuyersOrders = function (params) {
  return axios.patch(`${BASE_URL}/buyer/orders/suspend`, params)
}
suspendBuyersOrders.endpoint = 'PATCH|/buyer/orders/suspend'

const getPriceBook = function (params) {
  return axios.post(`${BASE_URL}/buyer/orders/pricecheck`, params)
}
getPriceBook.endpoint = 'POST|/buyer/orders/pricecheck'

export const orderActions = {
  getOrderList,
  getPriceBook,
  getOrderListBatch,
  createOrder,
  editOrder,
  suspendBuyersOrders,
  editMultipleOrders,
  setOrderCondition,
  getOrderBuyers
}
