import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { logisticsActions } from '@actions'
import { FormComponents } from '@organisms'
import { numberUtils, objectUtils } from '@utils'
import SelectShipmentStonesModal from './selectShipmentStonesModal'
import { useHistory } from 'react-router-dom'
import { useToast } from '@hooks'
import { useGlobalsStore } from '@stores'

function CreateShipmentForm({
  selectedRoughStones,
  fixedFromOrgId,
  fixedToOrgId,
  fixedType,
  onCancel,
  onCreated
}) {
  const {
    SimpleForm,
    TextInput,
    Dropdown,
    TextArea,
    Button
  } = FormComponents

  const [fromAddresses, setFromAddresses] = useState([])
  const [fromShippingMinimum, setFromShippingMinimum] = useState(null)
  const [toShippingMinimum, setToShippingMinimum] = useState(null)
  const [toAddresses, setToAddresses] = useState([])
  const [isSelectedStonesModalOpen, setIsSelectedStonesModalOpen] = useState(false)
  const { showSuccessToast } = useToast()
  const history = useHistory()
  const {
    getOrgsList,
    orgsList: { all: orgsList },
    shipmentTypes,
    getShipmentTypes
  } = useGlobalsStore(state => state)

  const totalWeight = useMemo(() => {
    return selectedRoughStones.reduce((total, rough) => {
      return total + Number(rough.weight)
    }, 0)
  }, [selectedRoughStones])

  useEffect(() => {
    getOrgsList()
  }, [])

  useEffect(() => {
    getShipmentTypes()
  }, [])

  useEffect(() => {
    if (fixedFromOrgId) handleOrgOnChange(fixedFromOrgId, 'from')
  }, [fixedFromOrgId])

  useEffect(() => {
    if (fixedToOrgId) handleOrgOnChange(fixedToOrgId, 'to')
  }, [fixedToOrgId])

  function handleOrgOnChange(orgId, type) {
    const org = orgsList?.find(o => o.orgId === orgId)

    if (type === 'from') {
      setFromAddresses(org.address.filter(address => !address.condition || address.condition === 'ACTIVE').map(address => ({
        value: address.name,
        label: `${address.name} | ${address.line1} | ${address.country}`
      })))

      setFromShippingMinimum(org.shippingMinimum)
    }

    if (type === 'to') {
      setToAddresses(org.address.filter(address => !address.condition || address.condition === 'ACTIVE').map(address => ({
        value: address.name,
        label: `${address.name} | ${address.line1} | ${address.country}`
      })))

      setToShippingMinimum(org.shippingMinimum)
    }
  }

  function handleSubmit(values) {
    if (!values) return

    logisticsActions.createShipment({
      ...values,
      fromAddress: { ...getEntityAddress(values.fromOrgId, values.fromAddress) },
      toAddress: { ...getEntityAddress(values.toOrgId, values.toAddress) },
      roughIds: selectedRoughStones?.length ? selectedRoughStones.map(rough => rough.id) : undefined
    })
    .then((response) => {
      showSuccessToast('Shipment created.')
      if (onCreated) return onCreated(response.data.data)
      else history.push(`/shipments/${response.data.data}`)
    })
  }

  function getEntityAddress(orgId, entityName) {
    return removeInvalidEntityFields(orgsList
    ?.find(o => o.orgId === orgId).address
    ?.find(address => address.name === entityName))
  }

  function removeInvalidEntityFields(entity) {
    const allowedFields = [
      'name',
      'line1',
      'line2',
      'line3',
      'city',
      'province',
      'country',
      'postalCode',
      'corporateNumber',
      'taxNumber',
      'email',
      'phoneNumber',
      'invoiceNotes',
      'invoicePayment',
      'preferredShipping'
    ]

    return objectUtils.pick(entity, allowedFields)
  }

  function validateAddress(value, type, label) {
    if (
      // Empty address
      (!value || !Object.keys(value).length)
      // From address is not a valid option
      || (type === 'from' && !fromAddresses.find(({ value: addrValue }) => value === addrValue))
      // To address is not a valid option
      || (type === 'to' && !toAddresses.find(({ value: addrValue }) => value === addrValue))
    ) return [`${label} is required.`]
  }

  return (
    <div className={fixedFromOrgId || fixedToOrgId || fixedType ? 'center' : 'col-xl-6 col-lg-8 col-md-10 col-sm-12 center'}>
      <SimpleForm
        title='Create Shipment'
        onSubmit={handleSubmit}
        initialValues={{
          type: fixedType,
          fromOrgId: fixedFromOrgId,
          toOrgId: fixedToOrgId
        }}
      >
        <div className='create-shipment-form__info'>
          <span>
            <strong>{selectedRoughStones.length}</strong> stones selected. (<strong>{numberUtils.numFmt(totalWeight, 2, { suffix: 'ct', thousandSeperator: true })}</strong> in total)
          </span>
          <Button
            size='sm'
            typeVariant='action'
            onClick={() => setIsSelectedStonesModalOpen(true)}
            disabled={!selectedRoughStones?.length}
          >
              View
          </Button>
        </div>
        <Dropdown
          name='type'
          label='Type'
          options={shipmentTypes?.map(type => ({
            value: type.value,
            label: type.description
          })) || []}
          disabled={!!fixedType}
        />
        <Dropdown
          name='fromOrgId'
          label='From Organization'
          extraLabel={`(Shipping minimum ${numberUtils.numFmt(fromShippingMinimum, 2, { prefix: '$', thousandSeperator: true })})`}
          options={orgsList?.map(org => ({
            value: org.orgId,
            label: org.commonName
          }))}
          isClearable={false}
          disabled={!!fixedFromOrgId}
          extraOnChange={(_, e) => handleOrgOnChange(e.currentTarget.value, 'from')}
        />
        <Dropdown
          name='fromAddress'
          label='From Address'
          options={fromAddresses}
          disabled={!fromAddresses.length}
          isClearable={false}
          customValidation={(val) => validateAddress(val, 'from', 'From Address')}
        />
        <Dropdown
          name='toOrgId'
          label='To Organization'
          extraLabel={`(Shipping minimum ${numberUtils.numFmt(toShippingMinimum, 2, { prefix: '$', thousandSeperator: true })})`}
          options={orgsList?.map(org => ({
            value: org.orgId,
            label: org.commonName
          }))}
          isClearable={false}
          disabled={!!fixedToOrgId}
          extraOnChange={(_, e) => handleOrgOnChange(e.currentTarget.value, 'to')}
        />
        <Dropdown
          name='toAddress'
          label='To Address'
          options={toAddresses}
          disabled={!toAddresses.length}
          isClearable={false}
          customValidation={(val) => validateAddress(val, 'to', 'To Address')}
        />
        <TextInput
          name='trackingNumber'
          label='Tracking Number'
          required={false}
        />
        <TextArea
          name='notes'
          label='Notes'
          rows={5}
          required={false}
        />
        <Button
          typeVariant="action"
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          size='sm'
        >
          Create Shipment
        </Button>
      </SimpleForm>
      <SelectShipmentStonesModal
        open={isSelectedStonesModalOpen}
        roughStones={selectedRoughStones}
        onClose={() => setIsSelectedStonesModalOpen(false)}
        readonly={true}
      />
    </div>
  )
}

CreateShipmentForm.propTypes = {
  selectedRoughStones: PropTypes.arrayOf(PropTypes.object),
  fixedFromOrgId: PropTypes.string,
  fixedToOrgId: PropTypes.string,
  fixedType: PropTypes.string,
  onCancel: PropTypes.func,
  onCreated: PropTypes.func
}

CreateShipmentForm.defaultProps = {
  selectedRoughStones: []
}

export default CreateShipmentForm
