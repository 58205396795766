import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useModalStore } from '@stores'
import { DetailsPage } from '@templates'
import { Icon } from '@atoms'
import { InfoTip } from '@molecules'

function BuyerSettingsDetailsPage({ value, onSubmit, onValidate, confirmModal, ...restProps }) {
  const {
    setModal
  } = useModalStore(state => state)

  const hasActiveOrders = useMemo(() => {
    if (!value || !('Orders' in value)) return false
    return value.Orders.filter(o => o.condition === 'ACTIVE').length
  }, [value])

  const hasArchivedOrders = useMemo(() => {
    if (!value || !('Orders' in value)) return false
    return value.Orders.filter(o => o.condition === 'ARCHIVED').length
  }, [value])

  async function handleSubmit(values) {
    if (onValidate) {
      await Promise.resolve(onValidate(values))
    }
    const confirmModalId = confirmModal(values)
    if (confirmModalId && hasActiveOrders) {
      await new Promise((resolve, reject) => {
        setModal({
          id: confirmModalId,
          title: 'Confirm Update',
          message: <>
          You have {hasActiveOrders} active order{hasActiveOrders > 1 ? 's' : ''} that use{hasActiveOrders === 1 ? 's' : ''} this item.<br/>
          These changes will automatically be reflected on {hasActiveOrders > 1 ? 'those' : 'that'} order{hasActiveOrders > 1 ? 's' : ''} and will likely affect your prices.<br/>
          Are you sure you want to continue?
          </>,
          buttonOptions: {
            submitText: 'Yes'
          },
          onSubmit: resolve,
          onCancel: reject
        })
      })
    }
    return onSubmit(values)
  }

  return <DetailsPage
    onSubmit={handleSubmit}
    customIconRenderer={{
      archive: (archiveProps) => (!value || hasActiveOrders ? <InfoTip name="buyerSettingsArchiveDisabled" icon={archiveProps?.name} size={archiveProps?.size} iconClass="icon--disabled" /> : <Icon {...archiveProps}/>),
      remove: (removeProps) => (!value || hasActiveOrders || hasArchivedOrders ? <InfoTip name="buyerSettingsRemoveDisabled" icon={removeProps?.name} size={removeProps?.size} iconClass="icon--disabled"/> : <Icon {...removeProps} />)
    }}
    {...restProps}/>
}

BuyerSettingsDetailsPage.propTypes = {
  value: PropTypes.object,
  onSubmit: PropTypes.func,
  onValidate: PropTypes.func,
  confirmModal: PropTypes.func
}
BuyerSettingsDetailsPage.defaultProps = {
  confirmModal: () => undefined
}

export default BuyerSettingsDetailsPage
