import React, { useEffect, useState } from 'react'
import { TextInput } from '@molecules'

function getRowTotal(row) {
  return Math.round(Object.values(row || {}).reduce((rowTot, rcValue) => rowTot + Number(rcValue), 0) * 10000) / 100
}

function ColourMixGrid({ name, value, onChange, colours, form, validationObject, ...props }) {
  const [rapColours, setRapColours] = useState([])
  useEffect(() => {
    setRapColours(Object.keys((colours || []).reduce((obj, c) => ({ ...obj, [c.rapColour]: true }), {})))
    if (colours && form) form.resetForm()
  }, [colours])

  function getHandleChange(c, rc) {
    return function(e) {
      if (!c || !rc) return
      return onChange({
        currentTarget: {
          name,
          value: {
            ...value,
            [c]: {
              ...value[c],
              [rc]: e.currentTarget.value ? e.currentTarget.value / 100 : 0
            }
          }
        }
      })
    }
  }

  function renderRowSummary(row) {
    const total = getRowTotal(row)
    return <div className={'colour-mix-grid__label-total' + (total === 100 ? '' : ' error')}>{total}</div>
  }

  return (
    <>
      <div className='colour-mix-grid__grid-container'>
        <div />
        {rapColours?.map(rc => <div key={rc} className='colour-mix-grid__label-rap'>{rc}</div>)}
        <div />
        {colours?.map(({ value: c, description: cDesc }) => <React.Fragment key={c}>
          <div className='colour-mix-grid__label-colour'>{cDesc}</div>
          {rapColours?.map(rc => {
            return (
              <div
                className='colour-mix-grid__input'
                key={rc}>
                <TextInput
                  type='number'
                  name={`${name}|${c}|${rc}`}
                  value={value?.[c]?.[rc] * 100 || ''}
                  onChange={getHandleChange(c, rc)}
                  size='sm'
                  {...props}
                />
              </div>
            )
          })}
          {renderRowSummary(value?.[c])}
          {validationObject?.[c] ? <div className='colour-mix-grid__validation-error'>{validationObject?.[c]}</div> : null}
        </React.Fragment>)}
      </div>
    </>
  )
}

ColourMixGrid.validate = function(value) {
  if (!value || !Object.keys(value).length) {
    return ['Color grid is required']
  } else {
    /* const errors = []
    for (const [key, row] of Object.entries(value)) {
      if (getRowTotal(row) !== 1) errors.push(`The row ${key} does not total 100%`)
    }
    if (errors.length) return errors */
    const errors = {}
    for (const [key, row] of Object.entries(value)) {
      if (getRowTotal(row) !== 100) errors[key] = 'Rows must total 100%'
    }
    if (Object.keys(errors).length) return errors
  }
}

export default ColourMixGrid
