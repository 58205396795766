function buildDataFromSchema(data, schema) {
  const transformedData = {}

  function parseValue(type, value) {
    if (type === 'string') { return String(value) }

    if (type === 'number') { return Number(value) }

    if (type === 'boolean') { return !!value }

    if (type === 'array') { return value }
  }

  // go through each schema property
  for (const [key, value] of Object.entries(schema)) {
    if (value.type === 'object') {
      // if it's an object, we call this same function
      // passing the property the schema, and the same data object
      // this way it search recursively until it finds all nested objects
      const nestedObject = buildDataFromSchema(data, value.properties)
      // we want to omit empty fields from the data sent to the back end
      // so if the nested is empty we don't add it to properties of
      // the current level's object.
      if (Object.keys(nestedObject).length) { transformedData[key] = nestedObject }
    } else {
      // if it's not an object, we parse the value via parseValue
      // and add to transformedData object
      if (data[key] != null) { transformedData[key] = parseValue(value.type, data[key]) }
    }
  }

  return transformedData
}

export {
  buildDataFromSchema
}
