import React from 'react'
import { FormComponents } from '@organisms'
import { provenanceActions } from '@actions'
import { useToast } from '@hooks'
import { useHistory } from 'react-router-dom'
import { useGlobalsStore } from '@stores'

function CreateCountry() {
  const {
    Button,
    Checkbox,
    TextInput,
    SimpleForm
  } = FormComponents

  const { showSuccessToast } = useToast()
  const { setCountriesList, resetLicenceTypesList } = useGlobalsStore(store => store)

  const history = useHistory()
  function handleSubmit({ ...values }) {
    for (const key in values) {
      if (values[key] === '') delete values[key]
    }
    provenanceActions.createCountry(values)
    .then(async () => {
      showSuccessToast('Country added.')
      await setCountriesList()
      resetLicenceTypesList()
      history.push('/seller-settings')
    })
    .catch(err => console.error(err))
  }

  return (
    <div className='create-country'>
      <div className="col-xl-4 col-lg-7 col-md-10 col-sm-12">
        <SimpleForm
          title='New Country'
          onSubmit={handleSubmit}
          name='createCountry'
          initialValues={{ secondaryMarket: false }}
        >
          <TextInput
            name='name'
            label='Name'
          />
          <TextInput
            name='requiredLicence'
            label='Required Licence'
            required={false}
          />
          <Checkbox
            name='secondaryMarket'
            label='Secondary market'
          />
          <Button typeVariant="action" onClick={() => history.goBack()}>
            Cancel
          </Button>
          <Button
            size='sm'
            type='submit'
          >
            Create Country
          </Button>
        </SimpleForm>
      </div>
    </div>
  )
}

export default CreateCountry
