import React from 'react'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import { Icon } from '@atoms'

function Modal({
  open,
  title,
  nested,
  position,
  closeable,
  children,
  onClose,
  closeOnDocumentClick,
  ...props
}) {
  return (
    <Popup
      open={open}
      position={position}
      closeOnEscape={closeable}
      closeOnDocumentClick={closeOnDocumentClick}
      nested={nested}
      onClose={onClose}
      {...props}
    >
      {close => (
        <div className='modal'>
          <h3 className="modal__header">
            <div className="modal__title">
              {title}
            </div>
            {
              closeable
                ? <div
                  className='modal__close-icon'
                  onClick={close}
                >
                  <Icon
                    size='lg'
                    name='close'
                  />
                </div>
                : null
            }
          </h3>
          <div className="modal__content">
            {children}
          </div>
        </div>
      )}
    </Popup>
  )
}

Modal.propTypes = {
  open: PropTypes.bool,
  nested: PropTypes.bool,
  closeOnDocumentClick: PropTypes.bool,
  title: PropTypes.string,
  position: PropTypes.string,
  closeable: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.objectOf([PropTypes.object, PropTypes.array])
}

Modal.defaultProps = {
  open: false,
  nested: true,
  title: 'Title',
  position: 'center',
  closeable: true,
  closeOnDocumentClick: false,
  onClose: () => console.warn('Modal.js => onClose callback not implemented')
}

export default Modal
