import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Table } from '@organisms'
import { numberUtils } from '@utils'
import { useAuthStore } from '@stores'
import { orderActions, planningActions } from '@actions'

function PolishedStonesList({ polishedStones, claritiesMap, orders }) {
  const { hasAdmin, hasPermission, permissionsAdminCache } = useAuthStore()
  const [groupedResults, setGroupedResults] = useState([])
  useEffect(() => {
    if (polishedStones) {
      const grouped = {}
      for (const p of polishedStones) {
        grouped[p.optionNumber] = [...(grouped[p.optionNumber] || []), p]
      }
      setGroupedResults(Object.values(grouped))
    }
  }, [polishedStones])

  const [columns, setColumns] = useState([])
  useEffect(() => {
    setColumns([
      {
        Header: 'Option #',
        accessor: row => row[0]?.optionNumber,
        dataType: 'number',
        id: 'optionNumber'
      },
      ...((hasAdmin(planningActions.getPlannedStones) || hasAdmin(planningActions.getQcPlannedStones)) ? [{
        Header: 'Total Price',
        accessor: row => row?.reduce((tot, part) => tot + Number(part.buyerPrice), 0),
        dataType: 'currency',
        id: 'totalPrice',
        decimalScale: 2,
        fixedDecimalScale: true
      }] : []),
      ...(hasPermission(orderActions.getOrderList) ? [{
        Header: 'Orders',
        accessor: row => row.map(part => <p key={part.id}>{orders?.find(x => x.OrderLines.map(x => x.id).includes(part.orderLineId))?.name}</p>),
        dataType: 'string',
        id: 'orderNames'
      }] : []),
      ...((hasAdmin(planningActions.getPlannedStones) || hasAdmin(planningActions.getQcPlannedStones)) ? [{
        Header: 'Price',
        accessor: row => row.map(part => <p key={part.id}>{numberUtils.numFmt(part.buyerPrice, 2, { prefix: '$', thousandSeperator: true })}</p>),
        sortType: (row1, row2) => row1?.original?.reduce((accum, { buyerPrice }) => accum + Number(buyerPrice), 0) - row2?.original?.reduce((accum, { buyerPrice }) => accum + Number(buyerPrice), 0),
        id: 'price'
      }] : []),
      {
        Header: 'Weight',
        accessor: row => row.map(part => <p key={part.id}>{part.polishedWeight}</p>),
        sortType: (row1, row2) => row1?.original?.reduce((accum, { polishedWeight }) => accum + Number(polishedWeight), 0) - row2?.original?.reduce((accum, { polishedWeight }) => accum + Number(polishedWeight), 0),
        id: 'weight'
      },
      {
        Header: 'Clarity',
        accessor: row => row.map(part => <p key={part.id}>{claritiesMap?.[part.clarity] ?? ''}</p>),
        dataType: 'string',
        id: 'clarity'
      }
    ])
  }, [groupedResults, orders, claritiesMap, permissionsAdminCache])

  const initialSort = [{ id: 'totalPrice', desc: true }, { id: 'weight', desc: true }]

  return (
    <div className='PlannedStones'>
      <Table
        title='SPS Results'
        data={groupedResults}
        columns={columns}
        isMultiSelect={false}
        initialSort={initialSort}
      />
    </div>
  )
}

PolishedStonesList.propTypes = {
  polishedStones: PropTypes.arrayOf(PropTypes.object),
  orders: PropTypes.arrayOf(PropTypes.object),
  clarities: PropTypes.object
}

PolishedStonesList.defaultProps = {
  polishedStones: [],
  orders: [],
  claritiesMap: {}
}

export default PolishedStonesList
