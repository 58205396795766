import axios from 'axios'
const BASE_URL = process.env.REACT_APP_API_BASE_URL

const getShipments = function (params) {
  return axios.get(`${BASE_URL}/logistics/shipments`, { params })
}
getShipments.endpoint = 'GET|/logistics/shipments'

const createShipment = function (params) {
  return axios.post(`${BASE_URL}/logistics/shipments`, params)
}
createShipment.endpoint = 'POST|/logistics/shipments'

const editShipment = function (id, params, options = {}) {
  return axios.patch(`${BASE_URL}/logistics/shipments/${id}`, params, { options })
}
editShipment.endpoint = 'PATCH|/logistics/shipments'

const setShipmentCondition = function (params) {
  return axios.put(`${BASE_URL}/logistics/shipments/condition`, params)
}
setShipmentCondition.endpoint = 'PUT|/logistics/shipments/condition'

const getShipmentConstants = function () {
  return axios.get(`${BASE_URL}/logistics/shipments/constants`)
}
getShipmentConstants.endpoint = 'GET|/logistics/shipments/constants'

const createShipmentFiles = function (shipmentId, params) {
  return axios.post(`${BASE_URL}/logistics/shipments/${shipmentId}/files`, params)
}
createShipmentFiles.endpoint = 'POST|/logistics/shipments/:shipmentId:/files'

const setShipmentFileCondition = function (shipmentId, fileId, params) {
  return axios.put(`${BASE_URL}/logistics/shipments/${shipmentId}/files/${fileId}/condition`, params)
}
setShipmentFileCondition.endpoint = 'PUT|/logistics/shipments/:shipmentId:/files/:fileId:/condition'

const createInvoice = function(invoice) {
  return axios.post(`${BASE_URL}/logistics/invoices`, invoice)
}
createInvoice.endpoint = 'POST|/logistics/invoices'

const editInvoice = function(id, params, options = {}) {
  return axios.patch(`${BASE_URL}/logistics/invoices/${id}`, params, { options })
}
editInvoice.endpoint = 'PATCH|/logistics/invoices/:invoiceId:'

const getInvoices = function(params) {
  return axios.get(`${BASE_URL}/logistics/invoices`, { params })
}
getInvoices.endpoint = 'GET|/logistics/invoices'

const setInvoiceCondition = function(params) {
  return axios.put(`${BASE_URL}/logistics/invoices/condition`, params)
}
setInvoiceCondition.endpoint = 'PUT|/logistics/invoices/condition'

const createInvoiceFiles = function(invoiceId, file) {
  return axios.post(`${BASE_URL}/logistics/invoices/${invoiceId}/files`, file)
}
createInvoiceFiles.endpoint = 'POST|/logistics/invoices/:invoiceId:/files'

const setInvoiceFileCondition = function(invoiceId, params) {
  return axios.put(`${BASE_URL}/logistics/invoices/${invoiceId}/files/condition`, params)
}
setInvoiceFileCondition.endpoint = 'PUT|/logistics/invoices/:invoiceId:/files/condition'

const createPayment = function(payment) {
  return axios.post(`${BASE_URL}/logistics/payments`, payment)
}
createPayment.endpoint = 'POST|/logistics/payments'

const editPayment = function(id, params, options = {}) {
  return axios.patch(`${BASE_URL}/logistics/payments/${id}`, params, { options })
}
editPayment.endpoint = 'PATCH|/logistics/payments/:paymentId:'

const editMultiplePayments = function(params) {
  return axios.patch(`${BASE_URL}/logistics/payments`, params)
}
editMultiplePayments.endpoint = 'PATCH|/logistics/payments'

const getPayments = function(params) {
  return axios.get(`${BASE_URL}/logistics/payments`, { params })
}
getPayments.endpoint = 'GET|/logistics/payments'

const setPaymentCondition = function(params) {
  return axios.put(`${BASE_URL}/logistics/payments/condition`, params)
}
setPaymentCondition.endpoint = 'PUT|/logistics/payments/condition'

const generateFullInvoice = function(params) {
  return axios.post(`${BASE_URL}/logistics/invoices/full`, params)
}
generateFullInvoice.endpoint = 'POST|/logistics/invoices/full'

export const logisticsActions = {
  getShipments,
  editShipment,
  setShipmentCondition,
  getShipmentConstants,
  createShipment,
  createShipmentFiles,
  setShipmentFileCondition,
  createInvoice,
  editInvoice,
  getInvoices,
  setInvoiceCondition,
  createInvoiceFiles,
  setInvoiceFileCondition,
  createPayment,
  editPayment,
  editMultiplePayments,
  getPayments,
  setPaymentCondition,
  generateFullInvoice
}
