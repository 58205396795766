import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import clone from 'just-clone'
import { saleActions } from '@actions'
import { Dropdown, RadioGroup, TextInput } from '@molecules'
import { Button, Checkbox } from '@atoms'
import { objectUtils, textUtils, validationUtils } from '@utils'

function PlanningDetailsModal({
  handleSubmit,
  handleCancel,
  initialValues
}) {
  const [values, setValues] = useState(initialValues)
  const [sales, setSales] = useState()
  const [validationText, setValidationText] = useState({})

  // true if valid
  // false if invalid
  const requiredValidationSchema = {
    'dateRange.startDate': (x, form) => !textUtils.isEmpty(form['dateRange.endDate']) ? !textUtils.isEmpty(x) : true,
    'dateRange.endDate': (x, form) => !textUtils.isEmpty(form['dateRange.startDate']) ? !textUtils.isEmpty(x) : true
  }

  useEffect(() => {
    saleActions.getSales({ columns: '[id, name, status, updatedAt]' })
    .then(result => setSales(
      result.data.data
      .sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt))
    ))
  }, [])

  const saleOptions = useMemo(() => (sales || []).map(sale => ({ value: sale.id, label: sale.name })), [sales])

  function onChange(e) {
    let newValues
    const target = e.currentTarget || e.target
    if (target.type === 'checkbox') newValues = { ...values, [target.name]: target.checked }
    else {
      const value = !textUtils.isEmpty(target.value) ? target.value : null
      newValues = { ...values, [target.name]: value }
    }
    onValidate(newValues)
    setValues(newValues)
  }
  function onValidate(vals) {
    setValidationText(Object.entries(requiredValidationSchema).reduce((valObj, [key, schema]) => ({
      ...(!schema(vals[key], vals) ? {
        [key]: validationUtils.validation({
          error: true
        })
      } : {}),
      ...valObj
    }), {}))
  }
  function onSubmit() {
    const valuesCopy = clone(values)
    valuesCopy.submitted = valuesCopy.status === 'submitted'
    delete valuesCopy.status
    return handleSubmit(objectUtils.filterNullish(valuesCopy))
  }

  return (
    <div className="form__container">
      <RadioGroup
        name='status'
        label='Status'
        value={values.status}
        options={[{ name: 'submitted', label: 'Submitted', value: 'submitted' }, { name: 'all', label: 'All', value: 'all' }]}
        onChange={onChange}
        validationText={validationText?.status}
      />
      <Dropdown
        name='saleId'
        label='Sale'
        value={values.saleId}
        options={saleOptions}
        isClearable={true}
        disabled={values?.['dateRange.startDate'] || values?.['dateRange.endDate']}
        onChange={onChange}
        validationText={validationText?.saleId}
      />
      <TextInput
        name='dateRange.startDate'
        label='Start Date'
        type='date'
        value={values?.['dateRange.startDate']}
        disabled={values?.saleId}
        onChange={onChange}
        validationText={validationText?.['dateRange.startDate']}
      />
      <TextInput
        name='dateRange.endDate'
        label='End Date'
        type='date'
        value={values?.['dateRange.endDate']}
        disabled={values?.saleId}
        onChange={onChange}
        validationText={validationText?.['dateRange.endDate']}
      />
      <Checkbox
        name='includeTops'
        label='Include Tops'
        value={values.includeTops}
        options={[{ name: 'Yes', value: true }, { name: 'No', value: false }]}
        onChange={onChange}
        validationText={validationText?.includeTops}
      />
      <Dropdown
        name='maxOptsPerBuyer'
        label='Max Options Per Buyer'
        value={values.maxOptsPerBuyer}
        options={Array.from({ length: 5 }, (_, i) => ({ label: i + 1, value: i + 1 }))}
        isClearable={false}
        onChange={onChange}
        validationText={validationText?.maxOptsPerBuyer}
      />
      <Dropdown
        name='maxOpts'
        label='Max Options'
        value={values.maxOpts}
        options={Array.from({ length: 10 }, (_, i) => ({ label: i + 1, value: i + 1 }))}
        isClearable={false}
        onChange={onChange}
        validationText={validationText?.maxOpts}
      />
      <div key='row-buttons' className='modal__buttons'>
        <Button typeVariant='secondary' size='sm' onClick={handleCancel}>Cancel</Button>
        <Button onClick={onSubmit} typeVariant='primary' size='sm' disabled={!objectUtils.isEmpty(validationText)}>Generate</Button>
      </div>
    </div>
  )
}

PlanningDetailsModal.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  initialValues: PropTypes.object
}

PlanningDetailsModal.defaultProps = {
  initialValues: { status: 'submitted', includeTops: true, maxOptsPerBuyer: 2, maxOpts: 5 }
}

export default PlanningDetailsModal
