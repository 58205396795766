import React, { useState, useEffect, useRef, useImperativeHandle, useMemo } from 'react'
import PropTypes from 'prop-types'
import { ErrorText, TextInput } from '@molecules'
import { Icon } from '@atoms'
import { useGlobalsStore, useOrderStore } from '@stores'
import clone from 'just-clone'

const PricingFluorGrid = React.forwardRef(function PricingFluorGrid({ priceTable, colours, clarities, fluorescences, pricingType, pmColours, onChange, disabled, validationText, ...props }, ref) {
  const { polishedColours: allColours, clarities: allClarities, polishedFluorescences, getPolishedFluorescences } = useGlobalsStore()
  useEffect(() => {
    getPolishedFluorescences()
  }, [])

  const {
    painterValue,
    painterFluorValue,
    isPainterEnabled,
    fluorGridValues,
    setFluorGridValues,
    isFluorMultiPainterEnabled
  } = useOrderStore(state => state)

  const [colourMap, setColourMap] = useState()
  const [clarityMap, setClarityMap] = useState()
  useEffect(() => {
    if (!allColours || !allClarities) return
    setColourMap(allColours.reduce((map, c, i) => ({ ...map, [c.value]: i + 1 }), {}))
    setClarityMap(allClarities.reduce((map, c, i) => ({ ...map, [c.value]: i + 1 }), {}))
  }, [allColours, allClarities])

  useEffect(() => {
    // Initialize values
    if (fluorGridValues) return
    if (priceTable?.length && colours && clarities && colourMap && clarityMap) refreshValues()
  }, [colours, clarities, polishedFluorescences, colourMap, clarityMap, priceTable])

  useEffect(() => {
    // Update values
    if (fluorGridValues) refreshValues()
  }, [priceTable, colourMap, clarityMap, clarities, colours])

  function refreshValues() {
    const vals = {}
    for (const col of colours) {
      for (const clr of clarities) {
        const region = getFluRegion(col.value, clr.value)
        for (const flu of polishedFluorescences) {
          vals[`${col.value}/${clr.value}/${flu.value}`] = region?.discounts?.[flu.value]
        }
      }
    }
    setFluorGridValues(vals)
  }

  const valuesRef = useRef(fluorGridValues)
  useEffect(() => {
    valuesRef.current = { fluorGridValues, colourMap, clarityMap }
  }, [fluorGridValues])
  useEffect(() => {
    return () => {
      getCompressedDiscounts()
    }
  }, [])

  function equalPrices(x, y) {
    return polishedFluorescences.every(f => x.discounts[f.value] === y.discounts[f.value])
  }
  function getCompressedDiscounts() {
    if (valuesRef.current && valuesRef.current.fluorGridValues && Object.keys(valuesRef.current.fluorGridValues).length) {
      const newTable = []
      const queue = Object.entries(valuesRef.current.fluorGridValues).reduce((list, [key, value]) => {
        if (value == null) return list
        const [col, clr, flu] = key.split('/')
        const prev = list.find(r => r.maxColour === col && r.maxClarity === clr)
        if (prev) {
          prev.discounts[flu] = value
          return list
        } else {
          return [...list, {
            maxColour: col,
            minColour: col,
            maxClarity: clr,
            minClarity: clr,
            discounts: { [flu]: value }
          }]
        }
      }, [])

      while (queue.length) {
        const region = queue.shift()
        let modify = false
        for (const neighbour of newTable) {
          if (!equalPrices(region, neighbour)) continue
          if (region.maxColour === neighbour.maxColour && region.minColour === neighbour.minColour) {
            if (valuesRef.current.clarityMap?.[neighbour.minClarity] + 1 === valuesRef.current.clarityMap?.[region.maxClarity]) {
              newTable.splice(newTable.indexOf(neighbour), 1)
              modify = { ...region, maxClarity: neighbour.maxClarity }
            } else if (valuesRef.current.clarityMap?.[region.minClarity] + 1 === valuesRef.current.clarityMap?.[neighbour.maxClarity]) {
              newTable.splice(newTable.indexOf(neighbour), 1)
              modify = { ...region, minClarity: neighbour.minClarity }
            }
          } else if (region.maxClarity === neighbour.maxClarity && region.minClarity === neighbour.minClarity) {
            if (valuesRef.current.colourMap?.[neighbour.minColour] + 1 === valuesRef.current.colourMap?.[region.maxColour]) {
              newTable.splice(newTable.indexOf(neighbour), 1)
              modify = { ...region, maxColour: neighbour.maxColour }
            } else if (valuesRef.current.colourMap?.[region.minClarity] + 1 === valuesRef.current.colourMap?.[neighbour.maxClarity]) {
              newTable.splice(newTable.indexOf(neighbour), 1)
              modify = { ...region, minColour: neighbour.minColour }
            }
          }
          if (modify) break
        }
        if (modify) {
          queue.push(modify)
        } else {
          newTable.push(region)
        }
      }
      onChange(newTable)
    }
  }

  useImperativeHandle(ref, () => ({
    getCompressedDiscounts
  }))

  const [expandedRows, setExpandedRows] = useState([0])
  function expandRow(rowIndex) {
    if (expandedRows.some(index => index === rowIndex)) {
      expandedRows.splice(expandedRows.indexOf(rowIndex), 1)
    } else {
      expandedRows.push(rowIndex)
    }
    setExpandedRows(clone(expandedRows))
  }

  function toggleExpanded(e) {
    e.preventDefault()
    if (expandedRows.length === colours.length) {
      setExpandedRows([])
    } else {
      setExpandedRows(Array.from({ length: colours.length }, (_, i) => i))
    }
  }

  function getFluRegion(colour, clarity) {
    const colIdx = colourMap?.[colour]
    const clrIdx = clarityMap?.[clarity]
    const regions = priceTable.filter(r => {
      return colourMap?.[r.maxColour] <= colIdx && colourMap?.[r.minColour] >= colIdx
        && clarityMap?.[r.maxClarity] <= clrIdx && clarityMap?.[r.minClarity] >= clrIdx
    })
    if (regions.length > 1) {
      console.warn('flu regions overlap, which is BAD')
      return regions[0]
    } else if (!regions.length) {
      return {
        maxColour: colour,
        minColour: colour,
        maxClarity: clarity,
        minClarity: clarity,
        discounts: polishedFluorescences.reduce((obj, f) => ({ ...obj, [f.value]: undefined }), {})
      }
    } else {
      return regions[0]
    }
  }

  // Optimize validation lookup by storing validation results in map keyed by colour/clarity/flu
  const validationMap = useMemo(buildValidationMap, [validationText, colours, clarities, fluorescences, colourMap, clarityMap])
  // Group validation by rows in the grid
  const validationRowMap = useMemo(() => {
    const rowMap = new Map()
    validationMap.forEach((v, k) => {
      const [col, _, flu] = k.split('/')
      rowMap.set(`${col}/${flu}`, [...(rowMap.get(`${col}/${flu}`) || []), v])
    })
    return rowMap
  }, [validationMap])
  function buildValidationMap() {
    const map = new Map()
    for (const col of colours) {
      for (const cla of clarities) {
        for (const flu of fluorescences) {
          const valRes = parseValidation(col.value, cla.value, flu.value)
          map.set(`${col.value}/${cla.value}/${flu.value}`, valRes)
        }
      }
    }
    return map
  }
  function parseValidation(colour, clarity, flu) {
    // Since min-max is a range we will have to use this information to find the correct idx
    const priceIdx = priceTable?.findIndex(price =>
      clarityMap?.[price.maxClarity] <= clarityMap?.[clarity]
      && clarityMap?.[price.minClarity] >= clarityMap?.[clarity]
      && colourMap?.[price.maxColour] <= colourMap?.[colour]
      && colourMap?.[price.minColour] >= colourMap?.[colour]
    )
    return validationText?.parseStep([priceIdx, 'discounts', flu])
  }

  // Event target name should be formatted using one of the options below
  // name => {colour}/{clarity}/{fluor} => Update the fluor at the colour and clarity cross section
  // name => {colour}/{clarity}/ => Update all fluor at the colour and clarity cross section
  function handleFluChange(e) {
    const target = e.currentTarget || e.target

    const [fieldColour, fieldClarity, fieldFluor] = target?.name?.split('/')
    const getAllFluor = (col, clar) => fluorescences.reduce((accum, { value: flu }) => ({ ...accum, [`${col}/${clar}/${flu}`]: target?.value?.[flu] }), {})

    if (pmColours === false) {
      const fieldColours = colours.filter(c => c.value === fieldColour || (c.rapColour === fieldColour && !c.isBase)).map(c => c.value)
      setFluorGridValues({
        ...fluorGridValues,
        ...fieldColours.reduce((vs, c) => ({
          ...vs,
          ...(fieldFluor
            ? { [`${c}/${fieldClarity}/${fieldFluor}`]: target?.value }
            : getAllFluor(c, fieldClarity))
        }), {})
      })
    } else {
      setFluorGridValues({
        ...fluorGridValues,
        ...(fieldFluor
          ? { [target?.name]: target.value }
          : getAllFluor(fieldColour, fieldClarity))
      })
    }
  }

  function handleOnFocus(e) {
    if (isPainterEnabled) {
      handleFluChange({ target: { name: e.target.name, value: painterValue } })
    } else if (isFluorMultiPainterEnabled) {
      handleFluChange({ target: { name: e?.target?.name?.split('/').slice(0, 2).concat('').join('/'), value: painterFluorValue } })
    }
  }

  function validateRow({ colour, flu, clarity }) {
    const inputName = `${colour}/${clarity}/${flu}`
    const valRow = validationRowMap?.get(`${colour}/${flu}`)
    const errors = valRow.filter(valRes => !!(valRes?.getError() || valRes?.getWarning()))
    const hasErrors = !!errors.length
    const validationPlaceholder = getValidationPlaceholder(errors)
    const { error, warning } = { ...validationMap?.get(inputName) }
    const validationClass = validationMap?.get(inputName)?.getValidationClass()
    return { error, warning, hasErrors, validationPlaceholder, inputName, validationClass }
  }

  function getValidationPlaceholder(messages) {
    if (!messages?.length) return ''
    let longerErrorMessage = ''
    messages.forEach(message => {
      const str = message?.error || message?.warning || ''
      if (String(str).length > String(longerErrorMessage).length) {
        longerErrorMessage = str
      }
    })

    return longerErrorMessage
  }

  return (
    <div className='order-pricing-fluor-grid'>
      <br/>
      <i>{pricingType === 'ppc' ? 'Please key in fluorescence discounts from the regular prices as negative numbers (e.g. 5% below entered as -5)'
        : 'Please enter additional discounts for fluorescence as negative numbers (e.g. an additional 5% discount from the baseline price should be entered as -5)'}
      </i>
      <br/><br/><br/>
      <a onClick={toggleExpanded}>{expandedRows.length === colours.length ? 'Collapse All' : 'Expand All'}</a>
      <br/><br/><br/>
      {
        colours.map((colour, index) => {
          if (pmColours === false && !colour.isBase) return null
          return (
            <div key={colour.value}
              className="order-pricing-fluor-grid__row">
              <div className="order-pricing-fluor-grid__collapsible">
                <span className="order-pricing-fluor-grid__label">
                  {colour.description}
                </span>
                <div
                  className={`order-pricing-fluor-grid__collapsible-icon${expandedRows.some(rowIndex => rowIndex === index) ? '--expanded' : ''}`}
                  onClick={() => expandRow(index)}
                >
                  <Icon
                    name='chevronRight'
                  />
                </div>
              </div>
              {
                clarities.map(clarity => {
                  return (
                    <div
                      key={`${colour.value}/${clarity.value}`}
                      className='order-pricing-fluor-grid__clarities'
                    >
                      <div className='order-pricing-fluor-grid__column'>
                        <span className="order-pricing-fluor-grid__label">
                          {clarity.description}
                        </span>
                        <div className={`order-pricing-fluor-grid__fluor-values${expandedRows.some(rowIndex => rowIndex === index) ? '--expanded' : ''}`}>
                          {fluorescences.map(flu => {
                            if (flu.value === 'N') return null
                            const {
                              error,
                              warning,
                              validationPlaceholder,
                              hasErrors,
                              inputName,
                              validationClass
                            } = validateRow({ colour: colour.value, flu: flu.value, clarity: clarity.value })
                            return (
                              <div key={flu.value} className={`order-pricing-fluor-grid__fluor-value${hasErrors ? '-grow' : ''}`}>
                                <div className='d-flex align-items-center'>
                                  <TextInput
                                    name={inputName}
                                    type='number'
                                    label=''
                                    onFocus={handleOnFocus}
                                    value={fluorGridValues?.[inputName]}
                                    onChange={handleFluChange}
                                    disabled={disabled}
                                    fixedDecimalScale={true}
                                    classNames={[validationClass]}
                                  />
                                </div>
                                <div className='order-pricing-fluor-grid__fluor-subtext'>
                                  <span className='subtext'>
                                    {flu.description}
                                  </span>
                                  {
                                    hasErrors && (!error && !warning) ? (
                                      <div className='order-pricing-fluor-grid__fluor-subtext-placeholder'>
                                        <span className='subtext'>
                                          {
                                            validationPlaceholder
                                          }
                                        </span>
                                      </div>
                                    ) : null
                                  }
                                  <ErrorText error={error} warning={warning} />
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
    </div >
  )
})

PricingFluorGrid.propTypes = {
  priceTable: PropTypes.arrayOf(PropTypes.object),
  colours: PropTypes.array,
  clarities: PropTypes.array,
  fluorescences: PropTypes.array,
  pmColours: PropTypes.bool,
  pricingType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  validationText: PropTypes.object
}

PricingFluorGrid.defaultProps = {
  priceTable: []
}

export default PricingFluorGrid
