import { useEffect } from 'react'
import axios from 'axios'
import { useToast, useAuth } from './hooks'
import { useLoadingSpinner } from '@stores'
import Cookies from 'js-cookie'
import dottie from 'dottie'

const awsDisconnectErrors = ['Connection terminated unexpectedly']
function useAxiosInterceptors() {
  const {
    showErrorToast,
    showInfoToast,
    showSuccessToast,
    showMultiErrorsToast
  } = useToast()

  const {
    signOut,
    getMightRefreshSession
  } = useAuth()

  const {
    addIsLoading,
    removeIsLoading
  } = useLoadingSpinner(state => state)

  useEffect(() => {
    // intercepts every request and adds the authorization token
    axios.interceptors.request.use(config => {
      const { options } = config

      config.requestId = options?.requestId || Math.random()
      config.requestGroupId = options?.requestGroupId || config.requestId
      config.requestGroupOptions = options?.requestGroupOptions
      config.loadingSpinner = options?.loadingSpinner === undefined || options?.loadingSpinner === null
        ? true // loading spinner is displayed by default
        : options.loadingSpinner

      if (config?.loadingSpinner) {
        const { requestId, requestGroupId, requestGroupOptions, loadingSpinner } = config
        addIsLoading(requestId, requestGroupId, requestGroupOptions, loadingSpinner)
      }

      const jwtToken = Cookies.get('jwt')
      if (jwtToken) config.headers.Authorization = jwtToken

      config.retry = !config.retry

      if (config.params) {
        config.params = dottie.flatten(config.params)
      }

      return config
    })

    // intercepts every response error and handles them accordantly
    // based on it's HTTP status
    axios.interceptors.response.use(response => {
      const { requestId, requestGroupId } = response.config
      removeIsLoading(requestId, requestGroupId)
      if (response.config.report) {
        showSuccessToast('Your report has been downloaded and a copy is available in your reports page.')
      }
      return response
    },
    errorResponse => {
      try {
        const config = errorResponse.config
        const { method, requestId, requestGroupId } = config
        removeIsLoading(requestId, requestGroupId)
        const errStatus = errorResponse?.response?.status
        // 401 - no permission => validate user
        if (errStatus === 401) {
          if (getMightRefreshSession()) showErrorToast('Please try your request again. If the issue persists, contact your Clara rep.')
          else signOut()
        } else if (
          errStatus === 504
          // Handling for unpredictable AWS disconnect errors
          || (errStatus === 500 && awsDisconnectErrors.includes(errorResponse?.response?.data?.message))
        ) {
          if (config.retry && method === 'get' && !config.report) {
            config.transformRequest = [data => data]
            return Promise.resolve(axios(config))
          } else if (config.report && errStatus === 504) {
            showInfoToast("Your report is taking a while. You'll be notified when it's ready to download. Check your reports page for details.")
          } else {
            showErrorToast('Please try your request again. If the issue persists, contact your Clara rep.')
          }
        } else if (errStatus === 413 && errorResponse?.response?.data?.message) {
          showInfoToast(errorResponse.response.data.message)
        } else if (errorResponse?.response?.data?.errors) {
          if (config.options?.errorSummary) errorResponse.response.data.errors.unshift(config.options.errorSummary)
          const errorsLimit = 5
          if (errorResponse?.response?.data?.errors.length > errorsLimit) {
            errorResponse.response.data.errors.slice(0, 4).forEach(err => showErrorToast(err))
            showMultiErrorsToast(errorResponse?.response?.data?.errors, errorsLimit)
          } else {
            errorResponse.response.data.errors.forEach(err => showErrorToast(err))
          }
        } else if (errorResponse?.response?.data?.message) {
          showErrorToast(errorResponse.response.data.message)
        } else if (errorResponse?.response?.statusText) {
          showErrorToast(errorResponse.response.statusText)
        } else {
        // other errors (eg. network failure)
          throw new Error()
        }
        return Promise.reject(errorResponse)
      } catch (err) {
        showErrorToast('An unexpected error occured. If the issue persists, contact your Clara rep.')
        return Promise.reject(errorResponse)
      }
    })
  }, [])
}

export {
  useAxiosInterceptors
}
