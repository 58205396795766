import React from 'react'
import PropTypes from 'prop-types'
import { FormComponents } from '@organisms'

function DuplicateOrder({ handleSubmit, handleCancel }) {
  const { SimpleForm, TextInput, Button } = FormComponents

  return (
    <SimpleForm onSubmit={handleSubmit}>
      <div> Do you wish to create a new order that duplicates this order?</div>
      <TextInput
        name='name'
        label='Name'
        required={true}
      />
      <div key='row-buttons' className='modal__buttons'>
        <Button typeVariant='secondary' size='sm' onClick={handleCancel}>Cancel</Button>
        <Button type='submit' typeVariant='primary' size='sm'>Create</Button>
      </div>
    </SimpleForm>
  )
}
DuplicateOrder.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired
}
DuplicateOrder.defaultProps = {
}

export default DuplicateOrder
