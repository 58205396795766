import React, { useEffect, useMemo } from 'react'
import { useHistory, NavLink } from 'react-router-dom'
import { Table } from '@organisms'
import OrganizationForm from './organizationForm/organizationForm'
import { useGlobalsStore, useAuthStore } from '@stores'
import { organizationActions, reportActions } from '@actions'
import { fileUtils, textUtils } from '@utils'
import { COMMON } from '@constants'

function OrganizationsList() {
  const { hasPermission } = useAuthStore(state => state)
  const {
    getOrgsList,
    orgsList: { all: orgsList },
    resetOrgsList,
    complianceStatusesMap,
    licenceStatusesMap,
    licenceTypesList,
    getComplianceStatuses,
    getLicenceStatuses,
    getLicenceTypesList
  } = useGlobalsStore(store => store)
  const history = useHistory()
  useEffect(() => {
    getOrgsList()
    getComplianceStatuses()
    getLicenceStatuses()
    getLicenceTypesList({ condition: 'ACTIVE' })
  }, [])

  const columns = useMemo(() => [
    {
      Header: 'Common Name',
      accessor: 'commonName',
      dataType: 'string',
      Cell: cellInfo => {
        return (
          <NavLink
            className="link"
            to={`/organizations/${cellInfo.row.original.orgId}`}
            id={cellInfo.row.original.orgId}
          >
            {cellInfo.row.values.commonName}
          </NavLink>
        )
      }
    },
    {
      Header: 'Legal Name',
      accessor: 'legalName',
      dataType: 'string'
    },
    {
      Header: 'Type',
      id: 'orgType',
      accessor: (row) => textUtils.formatDescription(row.orgType),
      dataType: 'string',
      filterType: 'checkbox'
    },
    {
      Header: 'Compliance',
      id: 'compliance.status',
      accessor: row => complianceStatusesMap[row.compliance?.status],
      dataType: 'string',
      filterType: 'checkbox'
    },
    ...(
      licenceTypesList
        ? licenceTypesList?.map(type => ({
          Header: type,
          id: `licences.${type}.status`,
          accessor: row => licenceStatusesMap[row.licences?.[type]?.status],
          dataType: 'string',
          filterType: 'checkbox'
        }))
        : []
    ),
    {
      Header: 'Purchase Limit',
      id: 'purchaseLimit',
      accessor: (row) => row.purchaseLimit ?? null,
      dataType: 'currency',
      filterType: 'numberRange'
    },
    {
      Header: 'Condition',
      id: 'condition',
      accessor: (row) => textUtils.formatDescription(row.condition),
      dataType: 'string',
      filterType: 'checkbox'
    },
    {
      Header: 'Last Updated',
      accessor: 'updatedAt',
      dataType: 'date',
      filterType: 'date'
    }
  ], [complianceStatusesMap, licenceStatusesMap, licenceTypesList])

  function navigateTo(path) {
    history.push(path)
  }

  function refreshOrgsList() {
    resetOrgsList()
    getOrgsList()
  }

  const initialSort = [{
    id: 'commonName',
    desc: false
  }]

  return (
    <Table
      title="Organizations"
      data={orgsList}
      columns={columns}
      isMultiSelect={false}// {isAdmin}
      TableForm={OrganizationForm}
      getDataCallback={refreshOrgsList}
      topBarActions={[
        ...(hasPermission(organizationActions.createOrganization) ? [{
          label: 'Create Organization',
          callback: () => navigateTo('/organizations/create')
        }] : []),
        ...(hasPermission(reportActions.exportOrganizations) ? [{
          label: 'Export Organizations',
          callback: () => reportActions.exportOrganizations().then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))

        }] : [])
      ]}
      initialSort={initialSort}
    />
  )
}

export default OrganizationsList
