import React, { useEffect, useState } from 'react'
import { cutGradeActions, organizationActions } from '@actions'
import { FormComponents, FileUpload } from '@organisms'

import { useAuthStore, useGlobalsStore } from '@stores'
import { useToast } from '@hooks'
import { fileUtils } from '@utils'
import { useHistory } from 'react-router-dom'

function CreateShape() {
  const {
    SimpleForm,
    TextInput,
    Dropdown,
    Button
  } = FormComponents

  const { showSuccessToast } = useToast()

  const { orgId: userOrgId, hasAdmin } = useAuthStore(state => state)
  const isAdmin = hasAdmin(organizationActions.getOrganizationList)

  const { getOrgsList, orgsList: { [JSON.stringify({ condition: 'ACTIVE' })]: orgsList } } = useGlobalsStore(store => store)
  useEffect(() => {
    getOrgsList({ condition: 'ACTIVE' })
  }, [])

  const [file, setFile] = useState(null)
  function handleFileChange(file) {
    setFile(file)
  }

  const history = useHistory()
  async function handleSubmit(values) {
    const { ...shape } = values

    if (file) {
      shape.file = await fileUtils.promiseReadBase64File(file)
      shape.origFileName = file.name
    }

    for (const key in shape) {
      if (shape[key] === '') delete shape[key]
    }

    cutGradeActions.createShape(shape)
    .then(() => {
      showSuccessToast('Shape added.')
      history.push('/buyer-settings')
    })
    .catch(err => console.error(err))
  }

  return (
    <div className='create-shape center'>
      <SimpleForm
        title='New Shape'
        onSubmit={handleSubmit}
        name='createShape'
        initialValues={{ buyerId: userOrgId }}
      >
        <TextInput
          name='name'
          label='Name'
        />
        {isAdmin
        && <Dropdown
          name='buyerId'
          label='Buyer'
          options={orgsList?.map(org => ({
            label: org.commonName,
            value: org.orgId
          }))}
        />
        }
        <FileUpload
          name='file'
          label="Shape File"
          required={true}
          onChange={handleFileChange}
          acceptTypes={['.asc']}
          fileName={file?.name || undefined}
        />
        <Button typeVariant="action" onClick={() => history.push('/buyer-settings')}>
            Cancel
        </Button>
        <Button
          type='submit'
          size='sm'
        >
            Create Shape
        </Button>
      </SimpleForm>
    </div>
  )
}

export default CreateShape
