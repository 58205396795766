import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, ToggleSwitch } from '@atoms'
import { useGlobalsStore } from '@stores'
import { TextInput, Dropdown } from '@molecules'
import { useToast } from '@hooks'
import Excel from 'exceljs/dist/es5/exceljs.browser'
import { arrayUtils, fileUtils, textUtils } from '@utils'
import { COMMON } from '@constants'

function ExportPriceTemplate({
  initialPriceScheme,
  initialGridSize,
  initialPmColours,
  onSubmit,
  onCancel,
  showCosts
}) {
  const { showErrorToast } = useToast()
  const [priceScheme, setPriceScheme] = useState(sortPriceScheme(initialPriceScheme))
  const [gridSize, setGridSize] = useState(initialGridSize)
  const [pmColours, setPmColours] = useState(initialPmColours)

  const {
    polishedColours: colours,
    clarities,
    polishedFluorescences,
    getPolishedColours,
    getClarities,
    getPolishedFluorescences,
    getPricingRangeWeightIncrement
  } = useGlobalsStore(state => state)
  useEffect(() => {
    getPolishedColours()
    getClarities()
    getPolishedFluorescences()
  }, [])

  function sortPriceScheme(pS) {
    return pS.slice().sort((a, b) => a.minWeight - b.minWeight)
  }

  function handleOrderGridSizeChange(e) {
    const target = e.currentTarget || e.target
    setGridSize({ ...gridSize, [target.name]: target.value })
  }

  function handleRangeChange(idx) {
    return function(e) {
      const target = e.currentTarget || e.target
      if ('name' in target && 'value' in target) {
        const newRange = { ...priceScheme[idx], ...{ [target.name]: target.value } }
        const newScheme = [...priceScheme]
        newScheme.splice(idx, 1, newRange)
        setPriceScheme(newScheme)
      }
    }
  }

  function handleAddWeight(e) {
    e.preventDefault()
    const maxWeight = priceScheme.reduce((max, range) => range && !isNaN(range.maxWeight) ? Math.max(max, range.maxWeight) : max, getPricingRangeWeightIncrement(0, -1))
    setPriceScheme([...priceScheme, { minWeight: getPricingRangeWeightIncrement(maxWeight), maxWeight: null, priceTable: [], discountTable: [] }])
  }

  function handleDeleteWeight(idx) {
    return function(e) {
      setPriceScheme(priceScheme.filter((_, i) => idx !== i))
    }
  }

  function handleRangeBlur() {
    setPriceScheme(currPriceScheme => sortPriceScheme(currPriceScheme))
  }

  async function handleSubmit() {
    const errors = []
    if (!pmColours) {
      const maxColour = colours.find(colour => colour.value === gridSize?.maxColour)
      const minColour = colours.find(colour => colour.value === gridSize?.minColour)
      if (!maxColour?.isBase) errors.push('Max Color should not include +/-. Please select a non +/- colour.')
      if (!minColour?.isBase) errors.push('Min Color should not include +/-. Please select a non +/- colour.')
    }
    if (priceScheme.some(({ minWeight, maxWeight }) => minWeight === null || maxWeight === null)) errors.push('Weight ranges should not be empty.')
    if (arrayUtils.hasOverlap(priceScheme, { start: 'minWeight', end: 'maxWeight' })) errors.push('Weight ranges should not overlap.')
    if (errors.length) return errors.forEach(error => showErrorToast(error))
    await generateXlsxTemplate()
    onSubmit()
  }

  async function generateXlsxTemplate() {
    try {
      const workbook = new Excel.Workbook()
      workbook.creator = 'Clara'
      workbook.created = new Date()
      const sheet = workbook.addWorksheet('Pricing')
      const bold = { font: { bold: true } }
      const columns = [
        { key: 'minWeight', header: 'Min Weight', width: 12, style: { alignment: { horizontal: 'left' }, ...bold } },
        { key: 'maxWeight', header: 'Max Weight', width: 12, style: { alignment: { horizontal: 'left' }, ...bold } },
        { key: 'colour', header: 'Color', width: 10, style: bold }
      ]

      const minClarityIdx = clarities.findIndex(clarity => clarity.value === gridSize.minClarity)
      const maxClarityIdx = clarities.findIndex(clarity => clarity.value === gridSize.maxClarity)
      const claritiesRange = clarities.slice(maxClarityIdx, minClarityIdx + 1)

      const minFlourIdx = 1
      const maxFlourIdx = polishedFluorescences.findIndex(flour => flour.value === gridSize.maxFluorescence)
      const fluorRange = polishedFluorescences.slice(minFlourIdx, maxFlourIdx + 1)

      const filteredColours = !pmColours ? colours.filter((colour) => colour.isBase) : colours

      const minColourIdx = filteredColours.findIndex(colour => colour.value === gridSize.minColour)
      const maxColourIdx = filteredColours.findIndex(colour => colour.value === gridSize.maxColour)
      const colourRange = filteredColours.slice(maxColourIdx, minColourIdx + 1)

      columns.push(
        ...claritiesRange.map((clarity) => Object.assign({ header: clarity.description, key: clarity.value, width: 10, style: { alignment: { horizontal: 'right' } } }), [])
      )

      columns.push(
        ...fluorRange.reduce((accum, flour, idx) => {
          accum.push({ key: `blank${idx}`, header: ' ', width: 5 })
          accum.push({ header: flour.description, key: flour.value, width: 10, style: bold })
          accum.push(...claritiesRange.map((clarity) => Object.assign({ header: clarity.description, key: `${flour.value}|${clarity.value}`, width: 10, style: { alignment: { horizontal: 'right' } } })))
          return accum
        }, [])
      )
      sheet.columns = columns

      const records = []
      const sortedPriceScheme = sortPriceScheme(priceScheme)
      sortedPriceScheme.forEach((ord, i) => {
        if (i) {
          const rec = {}
          for (const cla of claritiesRange) {
            rec[cla.value] = cla.description
            for (const flr of fluorRange) rec[`${flr.value}|${cla.value}`] = cla.description
          }
          for (const flr of fluorRange) rec[flr.value] = flr.description
          records.push(rec)
        }
        for (const col of colourRange) {
          const rec = {
            minWeight: Math.min(ord.minWeight, ord.maxWeight),
            maxWeight: Math.max(ord.minWeight, ord.maxWeight),
            colour: col.description
          }
          for (const flr of fluorRange) {
            rec[flr.value] = col.description
          }
          records.push(rec)
        }
      })
      sheet.addRows(records)
      sheet.eachRow(row => {
        const checkCell = row.getCell('minWeight')
        if (!checkCell || !checkCell.text || checkCell.text === 'Min Weight') { row.font = bold.font }
      })

      sheet.spliceRows(0, 0, [], [], [], [], [])
      sheet.getCell('A1').value = 'Instructions:'
      sheet.getCell('A2').value = `1) In columns D-${String.fromCharCode('C'.charCodeAt(0) + claritiesRange.length)} enter either discounts from the baseline or PPC prices (e.g. -40 or 2500).`
      sheet.getCell('A3').value = '2) In each fluorescence section enter a percentage to reduce the price for stones with that fluorescence (e.g. an additional 5% discount entered as -5).'
      sheet.getCell('A4').value = '3) If you don\'t plan to use a fluorescence cell, please set the value to -100 so that its price will be 0.'
      for (let i = 1; i <= 4; i++) {
        sheet.getRow(i).font = { bold: false }
      }
      sheet.views = [{ state: 'frozen', xSplit: 2, ySplit: 4 }]

      if (showCosts) {
        const marginSheet = workbook.addWorksheet('Margin')
        marginSheet.columns = [
          { key: 'weight', header: 'Weight', width: 12, style: { alignment: { horizontal: 'left' }, ...bold } },
          { header: 'Margin (%)', key: 'margin', width: 12, style: { alignment: { horizontal: 'right' } } }
        ]
        marginSheet.addRows(sortedPriceScheme.map(wgt => ({ weight: `${wgt.minWeight} - ${wgt.maxWeight}` })))
        marginSheet.getRow(1).font = bold.font
        marginSheet.spliceRows(0, 0, [], [], [])
        marginSheet.getCell('A1').value = 'Desired margins to subtract from the main pricing template. Weight ranges must match the main Pricing sheet.'
        marginSheet.getCell('A2').value = 'If margins are already included in your main pricing, you can leave this section blank.'
        for (let i = 1; i <= 2; i++) {
          marginSheet.getRow(i).font = { bold: false }
        }
      }

      return workbook.xlsx.writeBuffer().then(buffer => fileUtils.saveBufferExcel(buffer, `Pricing Template - ${textUtils.formatDate(new Date(), COMMON.DATE_FMT.REPORT)}.xlsx`))
    } catch (err) {
      console.error(err)
      showErrorToast(err?.message || 'There was an error writing the excel.')
    }
  }

  const disableSubmit = useMemo(() => {
    if (!priceScheme || !priceScheme.length) {
      return true
    }
    return false
  }, [priceScheme])

  return (
    <div className="export-price-template">
      <div className="export-price-template__form">
        <div className="export-price-template__dropdown-container">
          <div className="export-price-template__row">
            <div className="export-price-template__dropdown">
              <label>
                <div className="dropdown__label">
                  <span>Max Color</span>
                </div>
                <Dropdown
                  className='dropdown dropdown-container--lg'
                  name='maxColour'
                  onChange={handleOrderGridSizeChange}
                  value={gridSize?.maxColour}
                  options={colours?.reduce((accum, colour, index) =>
                    pmColours
                    || colour.isBase
                    || colour.value === gridSize?.maxColour ? accum.concat({
                        value: colour.value,
                        label: colour.description,
                        isDisabled: index > colours.findIndex(x => x.value === gridSize?.minColour)
                      }) : accum
                  , [])}
                  isClearable={false}
                />
              </label>
            </div>
            <div className="export-price-template__dropdown">
              <label>
                <div className="dropdown__label">
                  <span>Min Color</span>
                </div>
                <Dropdown
                  className='dropdown dropdown-container--lg'
                  name='minColour'
                  onChange={handleOrderGridSizeChange}
                  value={gridSize?.minColour}
                  options={colours?.reduce((accum, colour, index) =>
                    pmColours
                    || colour.isBase
                    || colour.value === gridSize?.minColour ? accum.concat({
                        value: colour.value,
                        label: colour.description,
                        isDisabled: index < colours.findIndex(x => x.value === gridSize?.maxColour)
                      }) : accum
                  , [])}
                  isClearable={false}
                />
              </label>
            </div>
          </div>
          <div className="export-price-template__row">
            <div className="export-price-template__dropdown">
              <label>
                <div className="dropdown__label">
                  <span>Max Clarity</span>
                </div>
                <Dropdown
                  className='dropdown dropdown-container--lg'
                  name='maxClarity'
                  onChange={handleOrderGridSizeChange}
                  value={gridSize?.maxClarity}
                  options={clarities?.map((clarity, index) => ({
                    value: clarity.value,
                    label: clarity.description,
                    isDisabled: index > clarities.findIndex(x => x.value === gridSize?.minClarity)
                  }))}
                  isClearable={false}
                />
              </label>
            </div>
            <div className="export-price-template__dropdown">
              <label>
                <div className="dropdown__label">
                  <span>Min Clarity</span>
                </div>
                <Dropdown
                  className='dropdown dropdown-container--lg'
                  name='minClarity'
                  onChange={handleOrderGridSizeChange}
                  value={gridSize?.minClarity}
                  options={clarities?.map((clarity, index) => ({
                    value: clarity.value,
                    label: clarity.description,
                    isDisabled: index < clarities.findIndex(x => x.value === gridSize?.maxClarity)
                  }))}
                  isClearable={false}
                />
              </label>
            </div>
          </div>
          <div className="export-price-template__row">
            <div className="export-price-template__dropdown">
              <label>
                <div className="dropdown__label">
                  <span>Min Fluor</span>
                </div>
                <Dropdown
                  className='dropdown dropdown-container--lg'
                  name='minFluorescence'
                  onChange={handleOrderGridSizeChange}
                  value={gridSize?.minFluorescence}
                  options={polishedFluorescences?.filter(fluor => fluor.value === 'N').map(((fluor) => ({
                    value: fluor.value,
                    label: fluor.description + (fluor.min !== undefined && fluor.max !== undefined ? ` (${fluor.min}-${fluor.max})` : '')
                  })))}
                  isClearable={false}
                />
              </label>
            </div>
            <div className="export-price-template__dropdown">
              <label>
                <div className="dropdown__label">
                  <span>Max Fluor</span>
                </div>
                <Dropdown
                  className='dropdown dropdown-container--lg'
                  name='maxFluorescence'
                  onChange={handleOrderGridSizeChange}
                  value={gridSize?.maxFluorescence}
                  options={polishedFluorescences?.map((fluor) => ({
                    value: fluor.value,
                    label: fluor.description + (fluor.min !== undefined && fluor.max !== undefined ? ` (${fluor.min}-${fluor.max})` : '')
                  }))}
                  isClearable={false}
                />
              </label>
            </div>
          </div>
        </div>
        <ToggleSwitch
          id='showPlusMinusColours'
          name='showPlusMinusColours'
          label='Show +/- color'
          value={pmColours}
          onChange={e => setPmColours(e.target.checked)}
        />
        {priceScheme.map((priceRange, idx) =>
          <div key={idx} className="export-price-template__row">
            <TextInput
              name='minWeight'
              label={!idx ? 'Min Weight' : ''}
              value={priceRange.minWeight}
              onChange={handleRangeChange(idx)}
              onBlur={handleRangeBlur}
              type='number'
            />
            <TextInput
              name='maxWeight'
              label={!idx ? 'Max Weight' : ''}
              value={priceRange.maxWeight}
              onChange={handleRangeChange(idx)}
              type='number'
            />
            <div className={`file-upload__remove-icon ${!idx ? 'mt-3' : ''}`} onClick={handleDeleteWeight(idx)}>
              <Icon
                name='closeCircle'
                size='md'
              />
            </div>
          </div>
        )}
        <div className='w-100'>
          <Button size='sm' onClick={handleAddWeight}>Add Weight Range</Button>
        </div>
      </div>

      <div className="export-price-template__buttons">
        <Button typeVariant='secondary' size='sm' onClick={onCancel}>Cancel</Button>
        <Button typeVariant='primary' size='sm' onClick={handleSubmit} disabled={disableSubmit}>Generate</Button>
      </div>
    </div>
  )
}
ExportPriceTemplate.propTypes = {
  initialPriceScheme: PropTypes.arrayOf(PropTypes.object),
  initialGridSize: PropTypes.object,
  initialPmColours: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  showCosts: PropTypes.bool
}

ExportPriceTemplate.defaultProps = {
  initialPriceScheme: [],
  initialPmColours: true,
  onSubmit: () => console.warn('ExportPriceTemplate.js => onSubmit callback not implemented'),
  onCancel: () => console.warn('ExportPriceTemplate.js => onCancel callback not implemented'),
  showCosts: true
}

export default ExportPriceTemplate
