import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { usePopper } from 'react-popper'
import { Button, Icon } from '@atoms'
import { useOuterClick } from '@hooks'

const NO_OPTIONS = [{
  label: 'No Options',
  value: '',
  classNames: ['no-options'],
  buttonProps: { disabled: true }
}]

function DropdownButton({
  label,
  onClick,
  options,
  ...props
}) {
  const [displayElement, setDisplayElement] = useState(null)
  const [popupElement, setPopupElement] = useState(null)
  const [visible, setVisible] = useState(false)
  const outerClickRef = useOuterClick(() => {
    setVisible(false)
  })
  const { styles, attributes } = usePopper(
    displayElement,
    popupElement,
    {
      strategy: 'fixed',
      modifiers: [
        { name: 'offset', options: { offset: [0, visible ? 0 : -135] } }]
    }
  )

  function renderOptions(opts) {
    return opts.concat(opts.length ? [] : NO_OPTIONS).map((opt, key) => {
      if (opt.options) {
        return (
          <div key={`${opt.value}_${key}`} className={['dropdown-button__group'].concat(opt.classNames || []).join(' ')}>
            <div className="dropdown-button__group-heading">{opt.label}</div>
            {renderOptions(opt.options)}
          </div>)
      }
      return (
        <div key={`${opt.value}_${key}`} className={['dropdown-button__option'].concat(opt.classNames || []).join(' ')}>
          <Button size="sm" typeVariant="action" {...opt?.buttonProps} onClick={() => opt?.onClick({ setVisible })}>{opt.label}</Button>
        </div>
      )
    })
  }

  return (
    <div ref={outerClickRef} className="dropdown-button" onClick={e => e.stopPropagation()}>
      <Button size="sm" {...props} buttonRef={setDisplayElement} onClick={() => setVisible(!visible)}>
        <div className='d-flex align-items-center'>
          {label}
          <div className='ml-1'>
            <Icon size="sm" name="chevronDown"/>
          </div>
        </div>
      </Button>
      <div
        className={`dropdown-button__content${visible ? '' : '--hidden'}`}
        ref={setPopupElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {renderOptions(options)}
      </div>
    </div>
  )
}

DropdownButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  options: PropTypes.array
}

DropdownButton.defaultProps = {
  label: 'Title',
  options: []
}
export default DropdownButton
