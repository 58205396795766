import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@templates'
import AssignRoughStoneRow from './assignRoughStoneRow'

function TransactionRoughsList({
  open,
  setIsOpen,
  selectedRows,
  orgsList
}) {
  return (
    <Modal
      open={open}
      onClose={() => setIsOpen({ open: false, selectedRows: [] })}
      title='Reassign Rough Stones'
    >
      <div className='transaction-roughs-list'>
        {
          selectedRows.filter(transaction => transaction.cancelledDate).map((transaction, index) => {
            return (
              <AssignRoughStoneRow
                key={index}
                transaction={transaction}
                orgsList={orgsList}
              />
            )
          })
        }
      </div>
    </Modal>
  )
}

TransactionRoughsList.propTypes = {
  open: PropTypes.bool,
  setIsOpen: PropTypes.func,
  selectedRows: PropTypes.arrayOf(PropTypes.object),
  orgsList: PropTypes.array
}

TransactionRoughsList.defaultProps = {
  open: false,
  selectedRows: []
}

export default TransactionRoughsList
