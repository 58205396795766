import { useEffect } from 'react'
import { textUtils } from '@utils'

/** Hook for changing page title */
export const usePageTitle = (pageTitle = '', pageItem = null, tertiaryItem = null) => {
  useEffect(() => {
    const title = buildTitle(pageTitle, pageItem, tertiaryItem)
    if (title) document.title = title
  }, [pageTitle, pageItem, tertiaryItem])

  function buildTitle(main, secondary, tertiary) {
    const shortSecondary = secondary && textUtils.shortenUUID(secondary)
    const shortTertiary = tertiary && textUtils.shortenUUID(tertiary)
    return `${main}${shortSecondary ? ' - ' + shortSecondary : ''}${shortTertiary ? ` (${shortTertiary})` : ''}`
  }
}
