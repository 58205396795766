import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useAuthStore, useGlobalsStore } from '@stores'
import { provenanceActions } from '@actions'
import moment from 'moment'
import { DetailsPage } from '@templates'
import { usePageTitle, useToast } from '@hooks'
import { useHistory } from 'react-router-dom'

function PipeDetails({ match, title }) {
  const { pipeId, operation } = match?.params

  const { orgId: userOrgId, hasAdmin, hasPermission, permissionsAdminCache } = useAuthStore(state => state)
  const provParams = hasAdmin(provenanceActions.getMines) ? null : { sellerId: userOrgId }

  const {
    orgsList: { all: orgsList },
    getOrgsList,
    getMinesList,
    resetPipesList,
    minesList: { [provParams ? JSON.stringify(provParams) : 'all']: mines = [] }
  } = useGlobalsStore()

  const { showSuccessToast } = useToast()
  const history = useHistory()

  const [pipe, setPipe] = useState({})
  usePageTitle(title, pipeId, pipe?.name)
  useEffect(() => {
    if (pipeId) {
      provenanceActions.getPipeById(pipeId)
      .then(setPipe)
      .catch(err => console.error(err))
    } else setPipe({})
  }, [pipeId])

  useEffect(() => {
    if (!permissionsAdminCache.size) return
    getMinesList(provParams)
    .catch(err => console.error(err))

    getOrgsList()
  }, [permissionsAdminCache])

  const [fields, setFields] = useState([])
  useEffect(() => {
    if (pipe) {
      setFields([
        {
          label: 'Name',
          value: pipe.name,
          name: 'name',
          componentName: 'textInput',
          canEdit: true
        },
        {
          label: 'Mine',
          value: pipe.mineId,
          name: 'mineId',
          componentName: 'dropdown',
          options: mines.map(mine => ({ value: mine.id, label: mine.name })),
          canEdit: false
        },
        {
          label: 'Seller',
          value: pipe.sellerId === null ? '-' : pipe.sellerId,
          name: 'sellerId',
          componentName: 'dropdown',
          options: orgsList?.map(org => ({ value: org.orgId, label: org.commonName })).concat({ value: '-', label: 'Preloaded' }),
          canEdit: false
        },
        {
          label: 'Condition',
          value: pipe.condition,
          name: 'condition',
          componentName: 'dropdown',
          options: [
            { value: 'ACTIVE', label: 'Active' },
            { value: 'ARCHIVED', label: 'Archived' }
          ],
          canEdit: true
        },
        {
          label: 'Created At',
          value: moment(pipe.createdAt).toLocaleString()
        },
        {
          label: 'Last Updated',
          value: moment(pipe.updatedAt).toLocaleString()
        }
      ])
    }
  }, [pipe, mines, orgsList])

  async function handleOnSubmit(editedValues) {
    const { condition, ...pipeData } = editedValues

    const conditionPromise = condition ? provenanceActions.setPipeCondition(pipeId, condition) : Promise.resolve()
    return conditionPromise.then(() => {
      if (Object.keys(pipeData).length) {
        return provenanceActions.editPipe({ id: pipeId, ...pipeData })
      }
    })
    .then(() => {
      showSuccessToast('Pipe updated.')
      resetPipesList()
      history.push('/seller-settings')
    })
  }

  function handleArchive() {
    provenanceActions.setPipeCondition(pipe?.id, 'ARCHIVED')
    .then(() => {
      resetPipesList()
      showSuccessToast('Pipe has been archived.')
      history.push('/seller-settings')
    })
  }

  function handleUnarchive() {
    provenanceActions.setPipeCondition(pipe?.id, 'ACTIVE')
    .then(() => {
      resetPipesList()
      showSuccessToast('Pipe has been unarchived.')
      history.push('/seller-settings')
    })
  }

  const canArchive = pipe?.condition !== 'ARCHIVED' && hasPermission(provenanceActions.setPipeCondition)
  const canUnrchive = pipe?.condition === 'ARCHIVED' && hasPermission(provenanceActions.setPipeCondition)
  const canEdit = pipe?.condition === 'ACTIVE' && hasPermission(provenanceActions.editPipe)

  return (
    <DetailsPage
      canEdit={canEdit}
      isEdit={operation === 'edit'}
      onArchive={canArchive ? handleArchive : null}
      onUnarchive={canUnrchive ? handleUnarchive : null}
      fields={fields}
      onSubmit={handleOnSubmit}
      title={{
        label: 'Pipe ID',
        value: pipe?.id || ''
      }}
    />
  )
}

PipeDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string
}

export default PipeDetails
