import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@atoms'
import { useGlobalsStore, useOrderStore } from '@stores'
import clone from 'just-clone'
import { arrayUtils, numberUtils } from '@utils'

function PreviewGrid({ priceTable, ppcTable, colours, clarities, fluorescences, pricingType, priceVar, pmColours, disabled, ...props }) {
  const { polishedColours: allColours, clarities: allClarities, polishedFluorescences, getPolishedFluorescences } = useGlobalsStore()
  useEffect(() => {
    getPolishedFluorescences()
  }, [])

  const ppcTableMap = useMemo(() => arrayUtils.toMap(ppcTable, (x) => `${x.colour}/${x.clarity}`, priceVar), [ppcTable])
  const {
    fluorGridValues,
    setFluorGridValues
  } = useOrderStore(state => state)

  const [colourMap, setColourMap] = useState()
  const [clarityMap, setClarityMap] = useState()
  useEffect(() => {
    if (!allColours || !allClarities) return
    setColourMap(allColours.reduce((map, c, i) => ({ ...map, [c.value]: i + 1 }), {}))
    setClarityMap(allClarities.reduce((map, c, i) => ({ ...map, [c.value]: i + 1 }), {}))
  }, [allColours, allClarities])

  useEffect(() => {
    // Initialize values
    if (fluorGridValues) return
    if (priceTable?.length && colours && clarities && colourMap && clarityMap) refreshValues()
  }, [colours, clarities, polishedFluorescences, colourMap, clarityMap, priceTable])

  useEffect(() => {
    // Update values
    if (fluorGridValues) refreshValues()
  }, [priceTable, colourMap, clarityMap, clarities, colours])

  function refreshValues() {
    const vals = {}
    for (const col of colours) {
      for (const clr of clarities) {
        const region = getFluRegion(col.value, clr.value)
        for (const flu of polishedFluorescences) {
          vals[`${col.value}/${clr.value}/${flu.value}`] = region?.discounts?.[flu.value]
        }
      }
    }
    setFluorGridValues(vals)
  }

  const [expandedRows, setExpandedRows] = useState([0])
  function expandRow(rowIndex) {
    if (expandedRows.some(index => index === rowIndex)) {
      expandedRows.splice(expandedRows.indexOf(rowIndex), 1)
    } else {
      expandedRows.push(rowIndex)
    }
    setExpandedRows(clone(expandedRows))
  }

  function toggleExpanded(e) {
    e.preventDefault()
    if (expandedRows.length === colours.length) {
      setExpandedRows([])
    } else {
      setExpandedRows(Array.from({ length: colours.length }, (_, i) => i))
    }
  }

  function getFluRegion(colour, clarity) {
    const colIdx = colourMap?.[colour]
    const clrIdx = clarityMap?.[clarity]
    const regions = priceTable.filter(r => {
      return colourMap?.[r.maxColour] <= colIdx && colourMap?.[r.minColour] >= colIdx
        && clarityMap?.[r.maxClarity] <= clrIdx && clarityMap?.[r.minClarity] >= clrIdx
    })
    if (regions.length > 1) {
      console.warn('flu regions overlap, which is BAD')
      return regions[0]
    } else if (!regions.length) {
      return {
        maxColour: colour,
        minColour: colour,
        maxClarity: clarity,
        minClarity: clarity,
        discounts: polishedFluorescences.reduce((obj, f) => ({ ...obj, [f.value]: undefined }), {})
      }
    } else {
      return regions[0]
    }
  }

  function getValue(fluorValue, colour, clarity, flu) {
    if (!fluorGridValues) return ''
    if (
      (fluorValue != null || flu === 'N')
      && ppcTableMap[`${colour}/${clarity}`] != null
    ) {
      if (priceVar === 'percentage') {
        return numberUtils.numFmt(
          Number(fluorValue ?? 0) + Number(ppcTableMap[`${colour}/${clarity}`]) + Number(fluorValue ?? 0) * Number(ppcTableMap[`${colour}/${clarity}`]) / 100,
          2,
          { suffix: '%' }
        )
      } else {
        return numberUtils.numFmt(
          (Number(fluorValue ?? 0) / 100 + 1) * Number(ppcTableMap[`${colour}/${clarity}`]),
          2,
          { prefix: '$', thousandSeperator: true }
        )
      }
    } else return 'N/A'
  }

  return (
    <div className='order-pricing-fluor-grid'>
      <br/>
      <i>{
        pricingType === 'ppc'
          ? 'Preview of ppc values with fluor discounts applied'
          : 'Preview of pricing discounts with fluorescence discounts applied'
      }</i>
      <br/><br/><br/>
      <a onClick={toggleExpanded}>{expandedRows.length === colours.length ? 'Collapse All' : 'Expand All'}</a>
      <br/><br/><br/>
      {
        colours.map((colour, index) => {
          if (pmColours === false && !colour.isBase) return null
          return (
            <div key={colour.value}
              className="order-pricing-fluor-grid__row">
              <div className="order-pricing-fluor-grid__collapsible">
                <span className="order-pricing-fluor-grid__label">
                  {colour.description}
                </span>
                <div
                  className={`order-pricing-fluor-grid__collapsible-icon${expandedRows.some(rowIndex => rowIndex === index) ? '--expanded' : ''}`}
                  onClick={() => expandRow(index)}
                >
                  <Icon
                    name='chevronRight'
                  />
                </div>
              </div>
              {
                clarities.map(clarity => {
                  return (
                    <div
                      key={`${colour.value}/${clarity.value}`}
                      className='order-pricing-fluor-grid__clarities'
                    >
                      <div
                        className='order-pricing-fluor-grid__column--wide'
                      >
                        <span className="order-pricing-fluor-grid__label">
                          {clarity.description}
                        </span>
                        <div className={`order-pricing-fluor-grid__fluor-values${expandedRows.some(rowIndex => rowIndex === index) ? '--expanded-checkered' : ''}`}>
                          {fluorescences.map(flu => {
                            const inputName = `${colour.value}/${clarity.value}/${flu.value}`
                            const fluorValue = fluorGridValues?.[inputName]
                            const value = getValue(fluorValue, colour.value, clarity.value, flu.value)
                            return (
                              <div key={flu.value} className='order-pricing-fluor-grid__fluor-value pt-4'>
                                {value}
                                <div className='order-pricing-fluor-grid__fluor-subtext'>
                                  <span className='subtext'>
                                    {flu.description}
                                  </span>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
    </div >
  )
}

PreviewGrid.propTypes = {
  priceTable: PropTypes.arrayOf(PropTypes.object),
  ppcTable: PropTypes.arrayOf(PropTypes.object),
  colours: PropTypes.array,
  clarities: PropTypes.array,
  fluorescences: PropTypes.array,
  pmColours: PropTypes.bool,
  pricingType: PropTypes.string,
  priceVar: PropTypes.string,
  disabled: PropTypes.bool
}

PreviewGrid.defaultProps = {
  priceTable: []
}

export default PreviewGrid
