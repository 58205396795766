import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTableStore } from '@stores'

function DefaultColumnFilter({
  column: { filterValue, setFilter, id },
  tableId,
  visible
}) {
  const {
    setFilterValue,
    setIsFilteredFn,
    getFilterValue,
    getTableConfig
  } = useTableStore(state => state)

  const storeFilterValue = getFilterValue(tableId, id)
  const tableConfig = getTableConfig(tableId)
  const inputRef = useRef()

  useEffect(() => {
    setFilter(storeFilterValue?.value)
  }, [storeFilterValue?.value])

  useEffect(() => {
    if (visible) {
      inputRef.current.focus()
    }
  }, [visible])

  useEffect(() => {
    setIsFilteredFn(tableId, id, (filter) => !!filter)
  }, [tableConfig?.activeTab?.id, tableConfig?.activeTab?.label])

  return (
    <input
      ref={inputRef}
      value={filterValue || ''}
      className="table__filter"
      placeholder={'Search...'}
      // stopPropagation in onClick callback to
      // avoid sorting when filter input
      // receives a click
      onClick={e => e.stopPropagation()}
      onChange={e => setFilterValue(tableId, id, e.target.value || undefined)}
    />
  )
}

DefaultColumnFilter.propTypes = {
  column: PropTypes.object,
  tableId: PropTypes.string,
  visible: PropTypes.bool
}

export default DefaultColumnFilter
