import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTabsStore } from '@stores'

function Tabs({
  items,
  initialTabIndex,
  name,
  onTabChange
}) {
  const { activeTabs: { [name]: activeTabIndex }, setActiveTabIndex } = useTabsStore(state => state)

  useEffect(() => {
    if (activeTabIndex == null && initialTabIndex != null && initialTabIndex >= 0 && initialTabIndex < items.length) {
      setActiveTabIndex(name, initialTabIndex)
    } else if (activeTabIndex == null && (initialTabIndex == null || initialTabIndex < 0)) {
      setActiveTabIndex(name, 0)
    } else if (activeTabIndex != null && items?.[activeTabIndex]?.disabled) {
      // Find the first non-disabled tab with priority given to initial tab. If all disabled just set to initial tab or 0.
      let newActiveTabIndex = activeTabIndex
      if (initialTabIndex >= 0 && initialTabIndex < items.length && !items[initialTabIndex].disabled) {
        newActiveTabIndex = initialTabIndex
      } else {
        const firstNonDisabled = items.findIndex(item => !item.disabled)
        newActiveTabIndex = firstNonDisabled > -1 ? firstNonDisabled : (initialTabIndex ?? 0)
      }
      if (activeTabIndex !== newActiveTabIndex) setActiveTabIndex(name, newActiveTabIndex)
    }
  }, [initialTabIndex, activeTabIndex, items])

  function handleOnClick(index) {
    if (name) {
      setActiveTabIndex(name, index)
      if (typeof onTabChange === 'function') {
        onTabChange(index)
      }
    }
  }

  return (
    <div className="tabs">
      <div className="tabs__header">
        {items.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => handleOnClick(index)}
              className={
                `tabs__header-item${index === activeTabIndex || items.length === 1
                  ? '--active'
                  : ''}${item.disabled ? ' tabs__header-item--disabled' : ''}`
              }
            >
              <span>{item.title}</span>
            </div>
          )
        })}
      </div>
      <div className="tabs__container">
        {items.map((item, index) => {
          const { Component, props } = item
          return index === activeTabIndex || items.length === 1
            ? <Component key={index} {...props} />
            : null
        })}
      </div>
    </div>
  )
}

Tabs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  initialTabIndex: PropTypes.number,
  onTabChange: PropTypes.func,
  name: PropTypes.string.isRequired
}

Tabs.defaultTabs = {
  name: '',
  items: []
}

export default Tabs
