import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { DragAndDrop } from '@organisms'
import { Button, Icon } from '@atoms'
import { roughStoneActions } from '@actions'
import { useProgressList } from '@hooks'
import UploadAdvFileRow from './uploadAdvFileRow'
import { useGlobalsStore } from '@stores'
import { fileUtils } from '@utils'
import { InfoTip } from '@molecules'

function UploadAdvFilesModal({
  progressListName,
  progressListTitle,
  roughStonesList,
  allowedStatuses,
  onAllTasksComplete,
  handleCancel,
  handleClose,
  onUpload,
  onUploadCallback,
  onFileSelect,
  submitText,
  onValidation,
  required,
  autoUpload,
  editRoughFunc,
  getUploadUrlFunc
}) {
  const [uploadList, setUploadList] = useState([])
  const [validation, setValidation] = useState({
    selected: 0,
    total: 0,
    valid: !required
  })

  const [selectedFilesExists, setSelectedFilesExists] = useState([])
  const [selectedRoughStones, setSelectedRoughStones] = useState([])
  const [progressList, setProgressList] = useState()
  const [isValidatingFiles, setIsValidatingFiles] = useState(false)
  const {
    createProgressList
  } = useProgressList()
  const {
    roughStatuses = [],
    getRoughStatuses
  } = useGlobalsStore(store => store)

  useEffect(() => {
    setProgressList(createProgressList({
      name: progressListName,
      title: progressListTitle,
      autoStart: false,
      onAllTasksComplete
    }))
  }, [])

  useEffect(() => {
    getRoughStatuses()
  }, [])

  useEffect(() => {
    if (onFileSelect) {
      onFileSelect(!!uploadList.length)
    }
  }, [uploadList])

  useEffect(() => {
    handleValidation()
  }, [uploadList, selectedRoughStones])

  function handleValidation() {
    if (onValidation) {
      setValidation({
        ...validation,
        ...onValidation(uploadList, selectedRoughStones)
      })
    } else {
      setValidation({
        ...validation,
        selected: uploadList?.filter(u => u.rough).length || 0,
        total: uploadList?.length || 0,
        valid: required ? uploadList?.every(uploadObj => uploadObj.rough) : uploadList?.some(uploadObj => uploadObj.rough)
      })
    }
  }

  const filteredRoughList = useMemo(() => {
    if (!roughStonesList?.length || !roughStatuses?.length) return []
    return filterRoughList(roughStonesList)
  }, [roughStatuses, roughStonesList])

  const isRequiredNotSelected = useMemo(() => {
    return (required && !uploadList.length) || (required && !validation.valid)
  }, [required, uploadList, validation.valid])

  const isSubmitDisabled = useMemo(() => {
    return isValidatingFiles || isRequiredNotSelected || !validation.valid
  }, [isValidatingFiles, uploadList, required, validation.valid])

  function handleOnChange(updatedUploadObj) {
    updateUploadList(updatedUploadObj)
    updateSelectedRoughList(updatedUploadObj)
  }

  function updateUploadList(updatedUploadObj) {
    // replace old value with new one based on it's index
    const uploadObj = findUploadObj(updatedUploadObj)
    uploadObj.rough = updatedUploadObj.rough
    // update uploadList state variable
    setUploadList([...uploadList])
  }

  function updateSelectedRoughList(updatedUploadObj) {
    const filteredSelectedRoughList = selectedRoughStones.filter(r => r?.id !== updatedUploadObj?.lastSelected?.id)
    if (updatedUploadObj.rough) {
      setSelectedRoughStones([...filteredSelectedRoughList, updatedUploadObj.rough])
    } else {
      setSelectedRoughStones(filteredSelectedRoughList)
    }
  }

  function handleOnRemove(uploadObj) {
    // updates upload list
    const filteredUploadList = uploadList.filter(upload => upload.index !== uploadObj.index)
    setSelectedFilesExists([...selectedFilesExists.filter(f => f.fileName !== uploadObj.file.name)])
    setUploadList(filteredUploadList)

    // updates selected roughs list
    const updatedUploadObj = { ...uploadObj, lastSelected: uploadObj.rough, rough: null }
    updateSelectedRoughList(updatedUploadObj)
  }

  function findUploadObj(updatedUploadObj) {
    return uploadList.find(uploadObj => uploadObj.index === updatedUploadObj.index)
  }

  async function handleOnFileSelect(files) {
    const filesAndRoughs = []
    // look for existing adv files in the db from selected files
    // const galaxyFilesExists = await validateGalaxyFilesExists(files)
    // loop through files
    setIsValidatingFiles(true)
    validateGalaxyFilesExists(files)
    files.forEach((file, index) => {
      // match filename with rough name
      const rough = findRoughMatch(file)
      // build upload object
      // add to filesAndRoughs array
      filesAndRoughs.push({
        index,
        file,
        rough
      })
      if (rough) {
        // add to the selected rough list
        setSelectedRoughStones([...selectedRoughStones, rough])
      }
    })

    setUploadList(filesAndRoughs)
  }

  async function validateGalaxyFilesExists(files) {
    const hashs = []
    for (const file of files) {
      const hash = await fileUtils.getFileHash(file)
      hashs.push(hash)
      file.hash = hash
    }
    if (!hashs.length) return

    roughStoneActions.galaxyFileExists(hashs)
    .then(galaxyFilesExists => {
      setSelectedFilesExists(galaxyFilesExists.data.data.files.map(file => ({
        hash: file.File.hash,
        roughId: file.rough_id,
        fileName: file.File.originalName,
        sellerStoneName: file.sellerStoneName
      })))
    })
    .finally(() => setIsValidatingFiles(false))
  }

  function handleOnUpload() {
    if (uploadList.length) {
      if (onUpload && typeof onUpload === 'function') {
        onUpload(progressList, uploadList)
      }

      uploadList
      .filter(uploadObj => !!uploadObj.rough)
      .forEach(uploadObj => {
        const task = progressList.newTask()
        if (uploadObj?.rough?.id) {
          task.name = `Stone #${uploadObj.rough.id}`
          task.description = uploadObj?.file?.name
        } else {
          task.name = uploadObj?.file?.name
        }
        task.size = uploadObj?.file?.size
        task.uploadObj = uploadObj
        task.callback = (_task) => uploadAdv(uploadObj, progressList, _task).then(() => {
          if (onUploadCallback) {
            onUploadCallback(uploadObj.rough)
          }
        })
        progressList.addTask(task)
      })

      if (autoUpload) progressList.start()
    }
  }

  function resetOnModalClose() {
    setUploadList([])
    setSelectedRoughStones([])
  }

  async function uploadAdv(uploadObj, list, task) {
    const uploadUrl = await getUploadUrlFunc({ orgId: uploadObj.rough.sellerId, roughId: uploadObj.rough.id }, { loadingSpinner: false })
    const tempUpload = UploadAdvFilesModal.buildTempUpload(uploadUrl, uploadObj)
    const uploadedFile = await roughStoneActions.uploadFile(tempUpload.url, uploadObj.file, {
      updateProgress: list?.updateTaskProgressPercentage,
      taskId: task.id
    })
    return await editRoughFunc(tempUpload.roughId, {
      galaxyFile: UploadAdvFilesModal.buildGalaxyFile(tempUpload, uploadedFile)
    }, { loadingSpinner: false })
  }

  function findRoughMatch(file) {
    const fileName = getFileName(file)

    return filteredRoughList.find(rough =>
      String(rough.sellerStoneName).toLowerCase() === String(fileName).toLocaleLowerCase()
      || String(rough.id).toLowerCase() === String(fileName).toLowerCase()
    ) || null
  }

  function getFileName(file) {
    return String(file?.name).slice(0, String(file?.name)?.lastIndexOf('.'))
  }

  function filterRoughList(roughList) {
    if (!allowedStatuses.length) return roughList
    return roughList.filter(rough => allowedStatuses.includes(rough.status))
  }

  return (
    <div className="upload-adv-files-modal">
      {
        uploadList.length
          ? (
            <>
              {isValidatingFiles || selectedFilesExists?.length
                ? (
                  <div className='upload-adv-files-modal__verifying-files'>
                    {isValidatingFiles ? <span>Verifying ADV Files</span> : null}
                    {isValidatingFiles ? <Icon name='spinner' spin size='sm' /> : null}
                    {!isValidatingFiles && selectedFilesExists?.length ? <>
                      <Icon name='warning' size='md' />
                      <span>The highlighted ADV file(s) have been uploaded to other rough stones.
                        <br/>
                        <span className="upload-adv-files-modal__verify-files-subtext">
                          Please proceed with the upload if you agree with the duplicated ADV files.<br/>If you want to review your ADV files click Cancel button.
                        </span>
                      </span>
                    </>
                      : null}
                  </div>
                ) : null
              }
              <div className="upload-adv-files-modal__results-list-container">
                <div className="upload-adv-files-modal__results-list">
                  <div className="upload-adv-file-row__header">
                    <h4>ADV File Name</h4>
                    <h4>Size</h4>
                    <h4>Rough Status</h4>
                    <div className='upload-adv-file-row__rough-stone'>
                      <h4>Rough Stone </h4>
                      <span className='subtext'>{validation?.selected} out of {validation?.total}</span>
                    </div>
                    <div className='upload-adv-file-row__icons'></div>
                  </div>
                  {
                    uploadList.map((uploadObj, index) => {
                      return (
                        <UploadAdvFileRow
                          key={index}
                          uploadObj={uploadObj}
                          onChange={handleOnChange}
                          onRemove={handleOnRemove}
                          roughStonesList={filteredRoughList}
                          selectedFilesExists={selectedFilesExists}
                          selectedRoughStones={selectedRoughStones}
                        />
                      )
                    })
                  }
                </div>
                <div className="upload-adv-files-modal__footer">
                  <Button
                    typeVariant='action'
                    size='sm'
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </Button>
                  <Button
                    size='sm'
                    disabled={isSubmitDisabled}
                    onClick={() => {
                      handleOnUpload()
                      handleClose()
                      resetOnModalClose()
                    }}
                  >
                    {submitText}
                  </Button>
                  {
                    isRequiredNotSelected
                      ? <InfoTip name='requiredNotSelected'/>
                      : null
                  }
                </div>
              </div>
            </>
          ) : (
            <div className="upload-adv-files-modal__description">
              <h4>Select ADV files to be uploaded.</h4>
              <span>After files are selected, available rough stones with the same name as the ADV files will be automatically selected.</span>
              <span>A rough stone can also be manually selected using the dropdown.</span>
              <span>ADV files with no rough stone will not be uploaded.</span>
            </div>
          )
      }
      <DragAndDrop
        title='ADV Files'
        acceptTypes={['.adv']}
        onChange={handleOnFileSelect}
        onDrop={handleOnFileSelect}
      />
    </div>
  )
}

UploadAdvFilesModal.propTypes = {
  required: PropTypes.bool,
  onClose: PropTypes.func,
  onAllTasksComplete: PropTypes.func,
  onValidation: PropTypes.func,
  handleCancel: PropTypes.func,
  handleClose: PropTypes.func,
  onUpload: PropTypes.func,
  onUploadCallback: PropTypes.func,
  onFileSelect: PropTypes.func,
  progressListTitle: PropTypes.string,
  submitText: PropTypes.string,
  progressListName: PropTypes.string.isRequired,
  roughStonesList: PropTypes.arrayOf(PropTypes.object),
  allowedStatuses: PropTypes.arrayOf(PropTypes.string),
  autoUpload: PropTypes.bool,
  editRoughFunc: PropTypes.func,
  getUploadUrlFunc: PropTypes.func
}

UploadAdvFilesModal.defaultProps = {
  required: false,
  roughStonesList: [],
  allowedStatuses: [],
  onUpload: null,
  submitText: 'Upload Files',
  progressListTitle: 'Upload ADV Files',
  autoUpload: true,
  handleCancel: () => undefined,
  handleClose: () => undefined,
  editRoughFunc: roughStoneActions.editRoughStone,
  getUploadUrlFunc: roughStoneActions.getUploadUrl
}

UploadAdvFilesModal.buildTempUpload = function(uploadUrl, uploadObj) {
  return {
    url: uploadUrl.data.data.url.url,
    uploadId: uploadUrl.data.data.uploadId,
    roughId: uploadObj.rough.id
  }
}

UploadAdvFilesModal.buildGalaxyFile = function(tempUpload, uploadedFile) {
  return {
    tempFile: tempUpload.uploadId,
    fileName: uploadedFile.config.data.name
  }
}

export default UploadAdvFilesModal
