import React from 'react'
import PropTypes from 'prop-types'

function Step({ step, currentStepNumber }) {
  const lineClassName = (() => {
    if (currentStepNumber === step.order) { return '--current' }
    if (currentStepNumber > step.order) { return '--completed' }

    return ''
  })()

  return (
    <div className="progress-tracker__step-container" key={step.order}>
      <div className="progress-tracker__step">
        <span className="progress-tracker__step-title">{step.title}</span>
        <div
          className={`progress-tracker__tracker-line-container${lineClassName}`}
        ></div>
      </div>
    </div>
  )
}
function ProgressTracker({ steps, currentStepNumber }) {
  return (
    <div className="progress-tracker">
      {steps.map((step, index) => (
        <Step key={index} step={step} currentStepNumber={currentStepNumber} />
      ))}
    </div>
  )
}

ProgressTracker.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object),
  currentStepNumber: PropTypes.number
}

ProgressTracker.defaultProps = {
  steps: [],
  currentStepNumber: 1
}

export default ProgressTracker
