import create from 'zustand'
import { qcActions } from '@actions'
import { cacheList } from './storeUtils'

const qcPermissionsListPromise = {}
const qcProgressListPromise = {}

const useQcStore = create((set, get) => ({
  /*
  qcPermissionsList structure
    qcPermissionsList: {
      [paramString]: {
        data: [/qcPermission objects/],
        lastUpdated: /date-time of last request/
        cacheInvalid: /bool flag to indicate if data has been manipulated/
      }
      ...
    }
  */
  qcPermissionsList: {},
  /*
  cacheList:
    getQcPermissionsList,
    setQcPermissionsList,
    removeQcPermissionsListItems,
    removeQcPermissionsList,
    removeAllQcPermissionsListItems,
    removeAllQcPermissionsList,
  */
  ...cacheList({
    state: 'qcPermissionsList',
    zustandMethods: { get, set },
    promise: qcPermissionsListPromise,
    batchAction: qcActions.getQcPermissionsBatch
  }),
  /*
  qcProgress structure
    qcProgressList: {
      [paramString]: {
        data: [/qcProgress objects/],
        lastUpdated: /date-time of last request/
        cacheInvalid: /bool flag to indicate if data has been manipulated/
      }
      ...
    }
  */
  qcProgressList: {},
  /*
  cacheList:
    getQcProgressList,
    setQcProgressList,
    removeQcProgressListItems,
    removeQcProgressList,
    removeAllQcProgressListItems,
    removeAllQcProgressList,
  */
  ...cacheList({
    state: 'qcProgressList',
    zustandMethods: { get, set },
    promise: qcProgressListPromise,
    batchAction: qcActions.getQcProgressBatch
  }),
  resetQCStore: () => {
    set({
      qcPermissionsList: {},
      qcProgressList: {}
    })
  }
}))

export {
  useQcStore
}
