import React from 'react'
import PropTypes from 'prop-types'
import Modal from './modal'
import { Button } from '@atoms'
import { useToast } from '@hooks'

function ConfirmationModal({
  open,
  title,
  position,
  message,
  onClose,
  onSubmit,
  isSubmitDisabled,
  closeOnFail,
  children,
  ...props
}) {
  const {
    showErrorToast,
    showWarningToast,
    showInfoToast
  } = useToast()

  function handleSubmit() {
    Promise.resolve(onSubmit())
    .then(() => onClose())
    .catch((err) => {
      if (err.name === 'ModalError') {
        if (err.type === 'error') showErrorToast(err.message)
        if (err.type === 'warning') showWarningToast(err.message)
        if (err.type === 'info') showInfoToast(err.message)
      }
      if (closeOnFail) onClose()
    })
  }

  return (
    <Modal
      open={open}
      title={title}
      position={position}
      onClose={onClose}
      {...props}
    >
      {message ? <div className='modal__message'>{message}</div> : null}
      {children}
      <div className='modal__buttons'>
        <Button typeVariant='secondary' size='sm' onClick={onClose}>Cancel</Button>
        <Button typeVariant='primary' size='sm' onClick={handleSubmit} disabled={isSubmitDisabled}>OK</Button>
      </div>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  position: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
  closeOnFail: PropTypes.bool
}

ConfirmationModal.defaultProps = {
  open: false,
  title: 'Title',
  position: 'center',
  message: '',
  onClose: () => console.warn('ConfirmationModal.js => onClose callback not implemented'),
  onSubmit: () => console.warn('ConfirmationModal.js => onSubmit callback not implemented'),
  isSubmitDisabled: false,
  closeOnFail: false
}

export default ConfirmationModal
