import React, { useEffect, useMemo } from 'react'
import { useGlobalsStore, useModalStore } from '@stores'
import { useToast } from '@hooks'
import { settingsActions } from '@actions'
import { Table } from '@organisms'
import SettingsModal from './settingsModal'
import { objectUtils } from '@utils'
import { SETTINGS } from '@constants'
import { settingSchema } from '@schemas'

function SettingsList() {
  const { showSuccessToast, showErrorToast } = useToast()

  const {
    appSettings: { all: appSettings },
    appSettingsByGroup: { all: appSettingsByGroup },
    getAppSettings,
    setAppSettings,
    resetAppSettings
  } = useGlobalsStore(store => store)
  const { setModal } = useModalStore(store => store)

  useEffect(() => {
    getAppSettings()
  }, [])

  const getJSONCell = function({ row: { original } }) {
    const schema = SETTINGS.SAVE[original.groupKey] || SETTINGS.SAVE.DEFAULT
    let values = objectUtils.pick(original, schema.map(({ name }) => name))
    if (!values) return values
    if (typeof value === 'string' && values[0] === '{') values = JSON.parse(values)
    else if (typeof values === 'string') return values
    if (!Object.keys(values)?.length) return null
    return Object.entries(values).map(([key, val]) => (
      <div key={key} className='table__cell-json'><b>{key}</b>: {typeof val === 'string' ? val : JSON.stringify(val)}</div>
    ))
  }

  const columns = useMemo(() => ([
    {
      Header: 'Group Key',
      accessor: 'groupKey',
      id: 'groupKey',
      dataType: 'string',
      filterType: 'checkbox'
    },
    {
      Header: 'Key',
      accessor: 'key',
      id: 'key',
      dataType: 'string'
    },
    {
      Header: 'Value',
      accessor: 'value',
      id: 'value',
      Cell: getJSONCell
    },
    {
      Header: 'Description',
      accessor: 'description',
      id: 'description',
      dataType: 'string'
    },
    {
      Header: 'Last Updated',
      accessor: 'updatedAt',
      id: 'updatedAt',
      dataType: 'dateTime',
      filterType: 'date'
    }

  ]), [appSettings])

  async function handleValidate(setting, mode) {
    const errors = []
    // Validate by default
    const defaultValidateObj = settingSchema.DEFAULT([setting], { group: appSettingsByGroup[setting.groupKey], mode })
    const defaultValidateRes = (await Promise.all(Object.values(defaultValidateObj).map(func => func.call()))).filter(res => res)
    errors.push(...(defaultValidateRes || []).flat())
    // Validate by groupKey
    if (settingSchema[setting.groupKey]) {
      const groupValidateObj = settingSchema[setting.groupKey]([setting], { group: appSettingsByGroup[setting.groupKey], mode })
      const groupValidateRes = (await Promise.all(Object.values(groupValidateObj).map(func => func.call()))).filter(res => res)
      errors.push(...(groupValidateRes || []).flat())
    }
    if (errors.length) {
      errors.forEach(err => showErrorToast(err))
      throw new Error()
    }
  }

  function handleEdit(setting, row) {
    const editSetting = objectUtils.omit(setting, ['createdAt', 'updatedAt', 'description'])
    return setModal({
      id: 'editSettings',
      title: `${setting.key} - ${setting.groupKey}`,
      type: 'form',
      customMessageRenderer: (messageProps) => <SettingsModal {...messageProps} mode='edit' setting={editSetting} groupKeys={Object.keys(appSettingsByGroup)}/>,
      customButtonsRenderer: () => null,
      closeable: false,
      closeOnFail: false,
      onSubmit: (res) => handleValidate(res[0], 'edit').then(() => settingsActions.saveSettings({ settings: [res[0]] })).then(() => showSuccessToast('Settings saved')).then(resetAppSettings).then(setAppSettings),
      className: 'settings'
    })
  }
  function handleCreate() {
    return setModal({
      id: 'createSetting',
      title: 'Create Setting',
      type: 'form',
      customMessageRenderer: (messageProps) => <SettingsModal {...messageProps} mode='create' groupKeys={Object.keys(appSettingsByGroup)}/>,
      customButtonsRenderer: () => null,
      closeOnFail: false,
      onSubmit: (res) => handleValidate(res[0], 'create').then(() => settingsActions.saveSettings({ settings: [res[0]] })).then(() => showSuccessToast('Settings saved')).then(resetAppSettings).then(setAppSettings),
      className: 'settings'
    })
  }

  const rowActions = [
    {
      actionName: 'edit',
      callback: handleEdit,
      shouldRefresh: false
    }
  ]

  const topBarActions = [{
    label: 'Create Setting',
    callback: handleCreate
  }]

  const initialSort = [{
    id: 'groupKey',
    desc: false
  }, {
    id: 'key',
    desc: false
  }]

  return (
    <Table
      title='Platform Settings'
      data={appSettings}
      columns={columns}
      getDataCallback={setAppSettings}
      rowActions={rowActions}
      topBarActions={topBarActions}
      isMultiSelect={false}
      initialSort={initialSort}
    />
  )
}

export default SettingsList
