import React, { useEffect, useMemo } from 'react'
import { MultiStepsForm } from '@organisms'
import NameShapeCutGradeStep from './nameShapeCutGradeStep'
import CostsBaselineStep from './costsBaselineStep'
import PricingStep from './pricingStep'
import OrderLinesStep from './orderLinesStep'
import { orderActions } from '@actions'
import { useAuthStore, useOrderStore, useTabsStore, useGlobalsStore } from '@stores'
import OrderFieldModal from '../orderFieldModal'
import { orderSchema } from '@schemas'
import { objectUtils } from '@utils'

function CreateOrder() {
  const {
    getOrderStatuses,
    getOrgsList,
    getPolishedColours,
    getClarities,
    getPolishedFluorescences,
    getQuantityTypes,
    getPricingRangeWeightGap,
    orderStatuses,
    orgsList: { [JSON.stringify({ condition: 'ACTIVE' })]: orgsList },
    polishedColours: colours,
    clarities,
    polishedFluorescences,
    quantityTypes
  } = useGlobalsStore()
  useEffect(() => {
    getOrderStatuses()
    getOrgsList({ condition: 'ACTIVE' })
    getPolishedColours()
    getClarities()
    getPolishedFluorescences()
    getQuantityTypes()
    getPricingRangeWeightGap()
  }, [])
  function getFormConstants(formData) {
    return {
      ORDER_STATUSES: (orderStatuses || [])?.map(status => status?.value),
      COLOURS: (colours || [])?.map(colour => colour?.value),
      CLARITIES: (clarities || [])?.map(clarity => clarity?.value),
      POLISHED_FLUORESCENCES: (polishedFluorescences || [])?.map(fluor => fluor?.value),
      QUANTITY_TYPES: (quantityTypes || []).map(quantityType => quantityType?.value),
      WEIGHT_GAP_FN: getPricingRangeWeightGap,
      SHOW_COSTS: orgsList?.find(org => org.orgId === formData?.buyerId)?.showCosts ?? false,
      PM_COLOURS: pmColours
    }
  }

  const steps = [
    {
      title: 'Name, Shape and Cut Grade',
      component: NameShapeCutGradeStep,
      validationProps: {
        validationSchema: {
          warning: orderSchema.createOrderNameShapeCutGradeWarningValidationSchema,
          error: orderSchema.createOrderNameShapeCutGradeErrorValidationSchema
        }
      }
    },
    {
      title: 'Pricing',
      component: PricingStep,
      validationProps: {
        validationSchema: {
          warning: (formData) => {
            const constants = getFormConstants(formData)
            return orderSchema.createOrderPricingStepWarningValidationSchema(constants)
          }
        }
      }
    },
    {
      title: 'Costs and Baselines',
      component: CostsBaselineStep,
      validationProps: {
        validationSchema: {
          warning: orderSchema.createOrderCostsAndBaselinesWarningValidationSchema
        }
      }
    },
    {
      title: 'Order Lines',
      component: OrderLinesStep,
      validationProps: {
        validationSchema: {
          warning: orderSchema.createOrderOrderLinesWarningValidationSchema
        }
      }
    }
  ]

  const { orgId: userOrgId } = useAuthStore(state => state)
  const { resetPricingGrid, pmColours } = useOrderStore(state => state)
  const { resetOrderTabs } = useTabsStore(state => state)
  useEffect(() => {
    return () => {
      resetPricingGrid()
      resetOrderTabs()
    }
  }, [])

  const { stateSchema, formData } = useMemo(() => {
    const res = {}
    res.formData = objectUtils.filterNullish({
      buyerId: userOrgId,
      priceSchemeType: 'clara2020',
      priceScheme: []
    })
    res.stateSchema = Object.keys(res.formData)
    return res
  }, [userOrgId])

  return (
    <div className='create-order'>
      <MultiStepsForm
        steps={steps}
        formData={formData}
        stateSchema={stateSchema}
        state
        onSubmit={orderActions.createOrder}
      />
      <OrderFieldModal />
    </div>
  )
}

export default CreateOrder
