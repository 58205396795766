import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Table } from '@organisms'
import { cutGradeActions } from '@actions'
import { arrayUtils } from '@utils'

function AssignShapes({ shapeList: existingShapeList, orgsList, handleSubmit, handleCancel }) {
  const [shapeList, setShapeList] = useState(existingShapeList)

  function refreshList() {
    cutGradeActions.getShapeList({ condition: 'ACTIVE', shapeGroupId: 'none' })
    .then(response => setShapeList((existingShapeList) => arrayUtils.unionBy(existingShapeList, response.data.data, (x) => x.id)))
    .catch(err => console.error(err))
  }
  useEffect(() => {
    refreshList()
  }, [])

  const initialSelected = useMemo(() => existingShapeList.reduce((selected, _, idx) => ({ ...selected, [idx]: true }), {}), [existingShapeList])
  const columns = useMemo(() => ([
    {
      Header: 'ID',
      accessor: 'id',
      dataType: 'string',
      Cell: cellInfo => cellInfo.value.slice(0, 4)
    },
    {
      Header: 'Name',
      accessor: 'name',
      dataType: 'string'
    },
    {
      Header: 'Owner',
      accessor: (row) => row.buyerId == null ? '* Preloaded *' : (orgsList?.find(org => org.orgId === row.buyerId)?.commonName || ''),
      dataType: 'string',
      id: 'owner'
    },
    {
      Header: 'Last Updated',
      accessor: 'updatedAt',
      dataType: 'date',
      filterType: 'date'
    }
  ]), [orgsList])

  const topBarActions = [{
    label: 'Update',
    callback: ({ selectedRows }) => handleSubmit({ shapeIds: arrayUtils.pickBy(selectedRows, 'id') }),
    enableOnSelect: true
  }]

  return (
    <Table
      title="Shapes"
      data={shapeList}
      columns={columns}
      initialSelected={initialSelected}
      initialSort={[]}
      topBarActions={topBarActions}
    />
  )
}

AssignShapes.propTypes = {
  shapeList: PropTypes.array,
  orgsList: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired
}
AssignShapes.defaultProps = {
  shapeList: [],
  orgsList: []
}

export default AssignShapes
