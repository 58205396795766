import React from 'react'
import { Button } from '@atoms'
import { useModalStore } from '@stores'
import Modal from '../modal/modal'

function TermsAndConditionsModal() {
  const {
    termsAndConditionsModalIsOpen,
    setTermsAndConditionsModalIsOpen
  } = useModalStore(state => state)

  return (
    <Modal title="Clara Terms and Conditions" open={termsAndConditionsModalIsOpen} onClose={() => setTermsAndConditionsModalIsOpen(false)} className="terms-and-conditions-modal">
      <div className="terms-and-conditions-modal__container" >
        <div className="terms-and-conditions-modal__header">
          <div><span>TERMS AND CONDITIONS APPLICABLE TO BUYERS OF</span></div>
          <div><span>ROUGH DIAMONDS THROUGH THE DIAMOND SALES PLATFORM OF</span></div>
          <div><span>CLARA DIAMOND SOLUTIONS LIMITED PARTNERSHIP</span></div>
          <div><span>As of August 1, 2019</span></div>
        </div>
        <br/>
        <div className="terms-and-conditions-modal__body">
          <div className="terms-and-conditions-modal__body-header"><span>1. INTRODUCTION</span></div>
          <div><span>1.1</span></div>
          <div><span>These terms and conditions (the “</span><span className="terms-and-conditions-modal__body-highlight">Terms and Conditions</span><span >”) set out the terms on which a buyer agrees</span></div>
          <div><span>to purchase rough diamonds through the diamond sales platform (the “</span><span className="terms-and-conditions-modal__body-highlight">Clara Platform</span><span >”) of Clara</span></div>
          <div><span>Diamond Solutions Limited Partnership (“</span><span className="terms-and-conditions-modal__body-highlight">Clara</span><span >”).  Any party which uploads one or more rough diamonds for</span></div>
          <div><span>sale on the Clara Platform is referred to herein as a “seller” and any party which submits an order for</span></div>
          <div><span>the purchase of one or more rough diamonds on the Clara Platform is referred to herein as a “buyer”.</span></div>
          <div><span>1.2</span></div>
          <div><span>Each buyer and any of its representatives wishing to purchase rough diamonds through the Clara</span></div>
          <div><span>Platform is bound by these Terms and Conditions.</span></div>
          <div><span>1.3</span></div>
          <div><span>The Terms and Conditions are available on the Clara Platform, and each buyer confirms that it has</span></div>
          <div><span>read them and agrees to be bound by them upon its registration as a buyer. Notwithstanding anything</span></div>
          <div><span>to the contrary contained herein, if a buyer’s registration has been completed and after such time the</span></div>
          <div><span>Terms and Conditions are amended by Clara, the buyer agrees that it shall be bound by the amended</span></div>
          <div><span>Terms and Conditions available on the Clara Platform at the time it places an order on the Clara</span></div>
          <div><span>Platform.</span></div>
          <div><span>1.4</span></div>
          <div><span>The buyer agrees that when its representative selects the “I Accept” button on the Clara Platform or</span></div>
          <div><span>submits an order to purchase rough diamonds, it, the buyer, is confirming its acceptance of the Terms</span></div>
          <div><span>and Conditions in effect at that time electronically as further proof that it agrees to be legally bound by</span></div>
          <div><span>them. Each buyer further agrees that an electronic signature is the legal equivalent to a manual</span></div>
          <div><span>signature, which has the full force and effect of a signature affixed by hand to a paper document, and</span></div>
          <div><span>which does not require the validation from any certification authority and/or other third party</span></div>
          <div><span>verification.</span></div>
          <div><span>1.5</span></div>
          <div><span>The Terms and Conditions apply to the use of the Clara Platform and/or to any other services offered</span></div>
          <div><span>by Clara in the context of current and future Clara sales.</span></div>
          <br/>
          <div className="terms-and-conditions-modal__body-header"><span>2. BUYER REGISTRATION</span></div>
          <div><span>2.1</span></div>
          <div><span>Any company wishing to purchase rough diamonds on the Clara Platform must apply to become a</span></div>
          <div><span>buyer by completing a registration form supplied by Clara for this purpose and providing all such</span></div>
          <div><span>documentation as may be required, including: proof of the identity of the buyer, its directors, officers,</span></div>
          <div><span>representatives and shareholders; a copy of the buyer’s constitutional documents and business</span></div>
          <div><span>licence; and company bank account(s) details.</span></div>
          <div><span>2.2</span></div>
          <div><span>When submitting its buyer registration form, the applicant buyer shall inform Clara if any of its partners,</span></div>
          <div><span>directors, senior managers or representatives is a shareholder or ultimate beneficiary in a company</span></div>
          <div><span>that a buyer registration with Clara or with any other company selling diamonds has been previously</span></div>
          <div><span>cancelled or been withdrawn. Failure to do so will be considered a material breach of the Terms and</span></div>
          <div><span>Conditions.</span></div>
          <div><span>2.3</span></div>
          <div><span>All information and documentation supplied shall be up to date and complete in all respects, and</span></div>
          <div><span>buyers who are already registered are required to keep such information and documentation updated</span></div>
          <div><span>at all times, including, but not limited to buyer’s representatives’ passport copies or renewed trade</span></div>
          <div><span>licences.</span></div>
          <div><span>2.4</span></div>
          <div><span>In order to be approved as a buyer, every applicant must have met all of Clara’s security, compliance</span></div>
          <div><span>and other due diligence requirements as determined by Clara in its sole discretion.</span></div>
          <div><span>2.5</span></div>
          <div><span>The buyer warrants that it has the legal capacity to perform its obligations under these Terms and</span></div>
          <div><span>Conditions.</span></div>
          <div><span>2.6</span></div>
          <div><span>Clara may, at its absolute discretion, cancel a buyer’s registration, and/or refuse to grant access to</span></div>
          <div><span>any of its representatives to the Clara Platform, without notice and with immediate effect.</span></div>
          <br/>
          <div className="terms-and-conditions-modal__body-header"><span>3. BUYER COMPLIANCE</span></div>
          <div><span>3.1</span></div>
          <div><span>The buyer hereby warrants and confirms that its company, its holding company, any/all subsidiaries,</span></div>
          <div><span>and each of such companies’ shareholders, partners, directors, senior managers or representatives:</span></div>
          <div><span>3.1.1</span></div>
          <div><span>comply with applicable laws;</span></div>
          <div><span>3.1.2</span></div>
          <div><span>are not and have not been involved in or associated with conflict diamonds;</span></div>
          <div><span>3.1.3</span></div>
          <div><span>have not purchased, sold, exported, imported or otherwise traded in rough diamonds without</span></div>
          <div><span>the full authority of, and in compliance with, the Kimberley Process certification scheme since</span></div>
          <div><span>its inception in January 2003;</span></div>
          <div><span>3.1.4</span></div>
          <div><span>comply and have not failed to comply with all trading obligations to third parties;</span></div>
          <div><span>3.1.5</span></div>
          <div><span>have not been convicted of, in any jurisdiction, any illegal activity, including, but not limited to:</span></div>
          <div><span>any offence of fraud; money laundering; theft; dishonesty; or for causing any damage to the</span></div>
          <div><span>health or welfare of any individual;</span></div>
          <div><span>3.1.6</span></div>
          <div><span>have not been expelled from a diamond bourse, or a similar industry organization, whether</span></div>
          <div><span>pursuant to a judgement from a litigation committee or otherwise;</span></div>
          <div><span>3.1.7</span></div>
          <div><span>have not been turned away or have had its status as a member of any diamond industry body</span></div>
          <div><span>or trade association terminated or suspended on the basis of a failure to comply with any code</span></div>
          <div><span>of conduct or ethical code operated by such diamond industry body or trade association;</span></div>
          <div><span>3.1.8</span></div>
          <div><span>have not been involved in or associated with the refusal by any grading laboratory to grade</span></div>
          <div><span>any diamond(s) or the contravention of any code of ethics or conduct operated by a grading</span></div>
          <div><span>laboratory;</span></div>
          <div><span>3.1.9</span></div>
          <div><span>are not subject to any insolvency or bankruptcy proceedings;</span></div>
          <div><span>3.1.10</span></div>
          <div><span>have not been included in a list of organizations and individuals with respect to which there is</span></div>
          <div><span>information on their engagement in extremist activities or terrorism that is maintained by a</span></div>
          <div><span>country who is a participant in the Kimberley Process; and</span></div>
          <div><span>3.1.11</span></div>
          <div><span>do not employ and/or are not associated with (a) “Politically Exposed Person(s)”, as that</span></div>
          <div><span>expression is defined in EU Commission Directive 2006/70/EC of 1st August 2006.</span></div>
          <div><span>3.2</span></div>
          <div><span>To the extent a “Purchase Limit” is specified in any order submitted on the Clara Platform, the buyer</span></div>
          <div><span>hereby warrants and confirms that it has the financial capability to satisfy payment with respect to</span></div>
          <div><span>such order up to such limit.  Clara may require (but is not obligated to require) a buyer to pay a deposit</span></div>
          <div><span>at the time an order is placed on the Clara Platform, as a bank guarantee, to a bank account nominated</span></div>
          <div><span>by Clara. Any such deposit shall either be refunded after a specified period of time if no portion of a</span></div>
          <div><span>buyer’s order has been filled after such period, or be deducted from the total payable amount in the</span></div>
          <div><span>event that all or a part of buyer’s order has been filled.</span></div>
          <div><span>3.3</span></div>
          <div><span>The buyer shall immediately advise Clara in the event that its registered company, its holding</span></div>
          <div><span>company, any of its subsidiaries, and/or any of such companies’ shareholders, partners, directors,</span></div>
          <div><span>senior managers or representatives fail or cease to satisfy the aforesaid compliance requirements.</span></div>
          <div><span>3.4</span></div>
          <div><span>If the buyer is in breach of the aforesaid compliance requirements:</span></div>
          <div><span>3.4.1    the buyer’s registration may be immediately revoked;</span></div>
          <div><span>3.4.2    Clara shall have the right to refuse to permit the buyer to submit any orders for rough</span></div>
          <div><span>diamonds, to terminate any existing orders for rough diamonds, and refuse to permit the</span></div>
          <div><span>purchase of rough diamonds by the buyer regardless of whether any order has been</span></div>
          <div><span>submitted and remains outstanding; and</span></div>
          <div><span>3.4.3    the buyer shall be fully liable to Clara for any damages resulting from such failure by the buyer</span></div>
          <div><span>to comply with this Section 3, including payment of a 10% default fee for any terminated</span></div>
          <div><span>contracts of sale.</span></div>
          <br/>
          <div className="terms-and-conditions-modal__body-header"><span>4. USE OF THE CLARA PLATFORM; ORDER SUBMISSION</span></div>
          <div><span>4.1</span></div>
          <div><span>Clara shall be under no obligation to permit a buyer to submit orders, whether or not such buyer has</span></div>
          <div><span>successfully completed the Clara Platform registration process or previously purchased rough</span></div>
          <div><span>diamonds on the Clara Platform.</span></div>
          <div><span>4.2</span></div>
          <div><span>Orders to purchase rough diamonds from a seller through Clara must be submitted via the Clara</span></div>
          <div><span>Platform.  At the time of registration, each buyer will be given a unique username and password to</span></div>
          <div><span>access  the  Clara  Platform. The  buyer  shall  be  responsible  for  maintaining  the  security  and</span></div>
          <div><span>confidentiality of its login details and shall immediately notify Clara in case of their loss or theft. Clara</span></div>
          <div><span>is not responsible at any point for the misplacement, loss or misuse of the username or password.</span></div>
          <div><span>4.3</span></div>
          <div><span>Order deadlines may be posted on the Clara website and available for viewing upon logging into the</span></div>
          <div><span>Clara Platform and/or provided by email.  Any correspondence from Clara to a buyer will be</span></div>
          <div><span>communicated to the buyer via email using the email address(es) provided by the buyer when it</span></div>
          <div><span>registers on the Clara Platform.</span></div>
          <div><span>4.4</span></div>
          <div><span>In order for an order to be eligible to be filled, the buyer must fully and accurately complete all order</span></div>
          <div><span>fields on the Clara Platform, including tolerance range, size range, color range, fluorescence range,</span></div>
          <div><span>quantity, expiry date etc. and upload the requisite files, including shape .asc file, price list etc., in the</span></div>
          <div><span>required format.  The buyer shall be solely responsible for the accuracy and sufficiency of the</span></div>
          <div><span>information contained in any order.  A buyer may not change or withdraw an order after any applicable</span></div>
          <div><span>order deadline.</span></div>
          <div><span>4.5</span></div>
          <div><span>The buyer agrees not to use the Clara Platform for any purpose other than to submit an order to</span></div>
          <div><span>purchase diamonds with the proposed attributes set out in an order. Clara has the right to review all</span></div>
          <div><span>orders and exclude an order based on the requested diamond attributes and other order parameters,</span></div>
          <div><span>including pricing.</span></div>
          <div><span>4.6</span></div>
          <div><span>A buyer may request training materials relating to the Clara Platform. For technical guidance when</span></div>
          <div><span>submitting an order, the buyer may also contact Clara by email or telephone using the contact</span></div>
          <div><span>information provided on the Clara Platform.</span></div>
          <div><span>4.7</span></div>
          <div><span>The buyer shall implement and enforce procedures to ensure, and represents and warrants to Clara,</span></div>
          <div><span>that all persons who have access to the Clara Platform on its behalf are fully conversant with the</span></div>
          <div><span>operation of the Clara Platform and will comply with these Terms and Conditions.</span></div>
          <div><span>4.8</span></div>
          <div><span>The buyer represents and warrants that an order submitted on the Clara Platform shall be submitted</span></div>
          <div><span>by an individual, whether an employee or representative, who is legally authorized to take a financial</span></div>
          <div><span>commitment on the buyer’s behalf. Clara shall have no obligation to verify the financial authorization</span></div>
          <div><span>of any employee or representative of the buyer.</span></div>
          <div><span>4.9</span></div>
          <div><span>Access to the Clara Platform is on an “as is” basis. Clara does not guarantee uninterrupted access to</span></div>
          <div><span>the Clara Platform and shall not be liable for any technical problems, which render the Clara Platform</span></div>
          <div><span>inoperable.</span></div>
          <div><span>4.10</span></div>
          <div><span>For the avoidance of any doubt Clara shall have no liability whatsoever to any buyer for any losses</span></div>
          <div><span>whatsoever and howsoever arising out of or in connection with the partial and/or total inability of the</span></div>
          <div><span>buyer to submit or withdraw an order submitted on the Clara Platform prior to or during a Clara sales</span></div>
          <div><span>cycle or any exercise by Clara of its discretion under the Terms and Conditions. </span><span className="terms-and-conditions-modal__body-highlight">The buyer accepts</span></div>
          <div><span className="terms-and-conditions-modal__body-highlight">this exclusion of liability and waives its possible rights in this respect.</span></div>
          <div><span>4.11</span></div>
          <div><span>In the event of any occurrence which, in the absolute discretion of Clara, impairs the proper functioning</span></div>
          <div><span>of the Clara Platform, Clara may, in its absolute discretion, cancel, stop or suspend sales via the Clara</span></div>
          <div><span>Platform and, where applicable, restart the sales via the Clara Platform at any time or in any manner.</span></div>
          <div><span>4.12</span></div>
          <div><span>Clara grants the buyer a non-exclusive, non-transferrable, non-sublicensable license to use the Clara</span></div>
          <div><span>Platform solely for the purpose of purchasing rough diamonds in accordance with these Terms and</span></div>
          <div><span>Conditions.</span></div>
          <div><span>4.13</span></div>
          <div><span>The Clara Platform contains intellectual property and confidential information which are the exclusive</span></div>
          <div><span>property of Clara. The buyer acknowledges that other than the license set out in Section 4.12, it</span></div>
          <div><span>acquires no right, title or interest in or to such intellectual property and confidential information.  The</span></div>
          <div><span>buyer agrees not use the Clara Platform for any purpose other than to acquire rough diamonds in</span></div>
          <div><span>accordance with these Terms and Conditions and shall not reverse-engineering/plagiarize and/or</span></div>
          <div><span>adapt the logic, the general aesthetics or any part of its content of the Clara Platform. The buyer shall</span></div>
          <div><span>notify Clara if it becomes aware of any unauthorized use of the Clara Platform content by a third party.</span></div>
          <div><span>4.14</span></div>
          <div><span>Clara reserves the right from time to time, in its sole discretion, to modify, change, discontinue or limit</span></div>
          <div><span>the availability of the Clara Platform.</span></div>
          <br/>
          <div className="terms-and-conditions-modal__body-header"><span>5. SALES VIA THE CLARA PLATFORM</span></div>
          <div><span>5.1</span></div>
          <div><span>In order to conduct sales via the Clara Platform, Clara shall commence an automated process to</span></div>
          <div><span>match individual rough diamonds which have been uploaded to the Clara Platform by a seller to all</span></div>
          <div><span>orders which have been submitted by participating buyers prior to the applicable order deadline.</span></div>
          <div><span>5.2</span></div>
          <div><span>Clara shall have absolute discretion as it sees fit to conduct sales via the Clara Platform including:</span></div>
          <div><span>5.2.1    whether and when any automated matching process is conducted via the Clara Platform;</span></div>
          <div><span>provided that to the extent an order deadline has been established, Clara shall only conduct</span></div>
          <div><span>the automated matching process;</span></div>
          <div><span>5.2.2    the length of time it takes to complete the automated matching process and a sale via the</span></div>
          <div><span>Clara Platform;</span></div>
          <div><span>5.2.3    the timing of the posting of the results of the automated matching process and a sale via the</span></div>
          <div><span>Clara Platform; and</span></div>
          <div><span>5.2.4    the frequency with which Clara conducts an automated matching process on the Clara</span></div>
          <div><span>Platform.</span></div>
          <div><span>5.3</span></div>
          <div><span>Following the order deadline, any order submitted on the Clara Platform shall stand as an offer to buy</span></div>
          <div><span>a diamond with the characteristics set out in the order.  All orders shall remain active for future Clara</span></div>
          <div><span>sales unless the buyer cancels the order on the Clara Platform prior to any applicable order deadline.</span></div>
          <div><span>5.4</span></div>
          <div><span>Submission of an order via the Clara Platform does not guarantee any or all of the buyer’s order will</span></div>
          <div><span>be successfully fulfilled.  Clara makes no representations or warranties as to the quantity or</span></div>
          <div><span>characteristics of rough diamonds to be sold via the Clara Platform or its ability to fulfil a buyer’s order</span></div>
          <div><span>completely, partially or at all.  The availability for sale of one or more rough diamonds with the</span></div>
          <div><span>characteristics specified in a buyer’s order does not give the buyer the right or opportunity to purchase</span></div>
          <div><span>such rough diamond unless such diamond is matched to the buyer’s order via the Clara Platform.</span></div>
          <div><span>5.5</span></div>
          <div><span>Buyers may be requested to predefine their overall ‘Purchase Limit’, i.e. the maximum amount a buyer</span></div>
          <div><span>would be prepared to purchase in a specified period.  In the case that a buyer has submitted several</span></div>
          <div><span>successful orders and the total amount of the rough diamonds matched to fill an order exceeds its</span></div>
          <div><span>spending limit, Clara may reduce the buyer’s total purchase to an amount that is below its pre-defined</span></div>
          <div><span>Purchase Limit at its sole discretion.</span></div>
          <br/>
          <div className="terms-and-conditions-modal__body-header"><span>6. RESULTS OF A CLARA SALES</span></div>
          <div><span>6.1</span></div>
          <div><span>Following the completion of the automated matching process, Clara shall post on each buyer’s home</span></div>
          <div><span>page on the Clara Platform, and/or send via email, the details as to how each of its orders has been</span></div>
          <div><span>filled, if at all.</span></div>
          <div><span>6.2</span></div>
          <div><span>Neither Clara nor any seller is obligated to publish the results of the automated matching process or</span></div>
          <div><span>the details of a sale via the Clara Platform to any party other than the results applicable a successful</span></div>
          <div><span>buyer.</span></div>
          <div><span>6.3</span></div>
          <div><span>Any seller or Clara may, in its absolute discretion, set a reserve price at which it is willing to sell rough</span></div>
          <div><span>diamonds.  Clara shall not disclose the reserve price of any particular rough diamond sold to any</span></div>
          <div><span>buyer.</span></div>
          <div><span>6.4</span></div>
          <div><span>Notwithstanding anything to the contrary herein, prior to the existence of a binding contract both Clara</span></div>
          <div><span>and a seller reserves the right, at its sole and absolute discretion, to refuse to sell any particular</span></div>
          <div><span>diamond for any reason.  A binding contract of sale is concluded between Clara and a buyer in respect</span></div>
          <div><span>of any rough diamond only when the buyer has been given notice by Clara that its order has been</span></div>
          <div><span>filled as contemplated in Section 6.1 and Clara has submitted by email to that buyer its invoice in</span></div>
          <div><span>respect of that rough diamond.</span></div>
          <br/>
          <div className="terms-and-conditions-modal__body-header"><span>7. INVOICING AND PAYMENT</span></div>
          <div><span>7.1</span></div>
          <div><span>Pursuant to Section 6.1 Clara shall issue an invoice in United States dollars for the rough diamonds(s)</span></div>
          <div><span>which fill a buyers order. Such invoice shall be posted on the buyer’s home page on the Clara Platform</span></div>
          <div><span>and/or sent via email.</span></div>
          <div><span>7.2</span></div>
          <div><span>Each buyer shall within five working days after Clara has posted the buyer’s home page on the Clara</span></div>
          <div><span>Platform and/or sent the invoice by email pay in United States dollars the price for each rough diamond</span></div>
          <div><span>and any applicable fees (including but not limited to Sarine’s Galaxy® inclusion mapping technology</span></div>
          <div><span>fee and shipping fees) set out on the relevant invoice by electronic bank transfer to the bank account</span></div>
          <div><span>referred to in Clara’s invoice.</span></div>
          <div><span>7.3</span></div>
          <div><span>The invoice shall only be issued to the buyer and payment shall only be accepted from the bank</span></div>
          <div><span>account(s) specified in the buyer’s registration form.</span></div>
          <div><span>7.4</span></div>
          <div><span>In the event that a buyer is unable to pay the invoice from the bank account(s) specified in the</span></div>
          <div><span>registration form, the buyer shall inform Clara in writing of the details of any different bank account</span></div>
          <div><span>that it intends to use to pay the invoice. Clara is under no obligation to accept payment from an</span></div>
          <div><span>alternate bank account to that specified in the buyer’s registration form and may carry out whatever</span></div>
          <div><span>‘know your customer’ checks it considers necessary in respect of such bank account.</span></div>
          <div><span>7.5</span></div>
          <div><span>The details of the bank accounts to which the payment must be made shall only be specified on the</span></div>
          <div><span>invoice issued by Clara. In the event a buyer receives a notice regarding bank account details in any</span></div>
          <div><span>other manner, Clara hereby notifies the buyer that such notice is fraudulent and the buyer shall be</span></div>
          <div><span>solely liable for any losses incurred due to relying on such notice. The buyer shall refer any questions</span></div>
          <div><span>or concerns relating to payment processing to the Chief Financial Officer of Clara via telephone.</span></div>
          <div><span>7.6</span></div>
          <div><span>In the event that any payment by the buyer results in Clara being charged bank charges Clara shall</span></div>
          <div><span>be entitled to invoice to the buyer the same amount as it is charged by the bank.</span></div>
          <div><span>7.7</span></div>
          <div><span>If a buyer fails to pay the price and, if applicable, the charges in respect of a rough diamond within</span></div>
          <div><span>five working days after having received the invoice, without prejudice to any and all of Clara’s rights</span></div>
          <div><span>against the buyer, including, but not limited to damages associated with such default, Clara may at</span></div>
          <div><span>any time by merely sending a written notice automatically and without any prior notice effect the</span></div>
          <div><span>termination of its obligations to sell and deliver the relevant rough diamond. The buyer may be</span></div>
          <div><span>excluded from participation in any future sales via the Clara Platform. The buyer shall be subject to a</span></div>
          <div><span>default fee of 10% of the total of its winning price for all of the rough diamonds that are covered by the</span></div>
          <div><span>termination notice. The buyer expressly agrees that payment of the default fee shall not prejudice</span></div>
          <div><span>Clara’s right to recover further losses, damages, or expenses. The buyer expressly agrees that the</span></div>
          <div><span>default fee is not a penalty and that Clara may retain the buyer’s deposit to pay for the default fee.</span></div>
          <div><span>7.8</span></div>
          <div><span>Working days shall mean any day in Vancouver, British Columbia, Canada that is not a weekend or a</span></div>
          <div><span>public holiday.</span></div>
          <br/>
          <div className="terms-and-conditions-modal__body-header"><span>8. COLLECTION AND DELIVERY</span></div>
          <div><span>8.1</span></div>
          <div><span>Each buyer shall, following receipt of full payment (including charges, if applicable) by Clara, collect</span></div>
          <div><span>its winning lot(s) at Clara’s premises or as otherwise notified by Clara.</span></div>
          <div><span>8.2</span></div>
          <div><span>Swift copies cannot be accepted as a way to release the rough diamonds.</span></div>
          <div><span>8.3</span></div>
          <div><span>A buyer shall not be permitted to collect any rough diamond until Clara has received full payment for</span></div>
          <div><span>all rough diamonds invoiced in a the related invoice. In the event that one or more buyers are related</span></div>
          <div><span>to each other (i.e. affiliated companies), such buyers shall not be permitted to collect any rough</span></div>
          <div><span>diamond until Clara has received full payment for all rough diamonds invoiced to such affiliated</span></div>
          <div><span>companies with respect to a Clara sale.</span></div>
          <div><span>8.4</span></div>
          <div><span>Title of ownership in each rough diamond sold shall pass to the buyer upon receipt of full payment by</span></div>
          <div><span>Clara for all diamonds allocated to such buyer in the relevant Clara sales cycle.</span></div>
          <div><span>The buyer may be charged additional insurance and storage costs in case its rough diamonds have</span></div>
          <div><span>not been collected two working days after receipt of the full payment.</span></div>
          <div><span>8.6</span></div>
          <div><span>If the buyer requests its rough diamonds to be shipped, Clara may arrange for such a shipment with</span></div>
          <div><span>any reputable carrier used in the industry.  Clara shall not be obligated to arrange for shipment to any</span></div>
          <div><span>location other than the location specified in a buyer’s registration details.</span></div>
          <div><span>8.7</span></div>
          <div><span>The buyer acknowledges and agrees that neither Clara nor any seller is liable for the rough diamonds</span></div>
          <div><span>and all risk of loss shall pass once they have crossed the threshold of Clara’s premises; insurance for</span></div>
          <div><span>the rough diamond(s) shall be the responsibility of the buyer once the rough diamonds leave Clara’s</span></div>
          <div><span>premises even if Clara has agreed to arrange for shipment.</span></div>
          <div><span>8.8</span></div>
          <div><span>When applicable, all transport, export, shipping insurance, diamond office tax fees and associated</span></div>
          <div><span>costs shall be for the buyer’s account to be paid for prior to movement of rough diamond(s) sold to it.</span></div>
          <div><span>8.9</span></div>
          <div><span>The buyer undertakes, represents and warrants to comply with all laws, including, but not limited to</span></div>
          <div><span>export/import control and trade sanction laws and regulations, which may apply to the rough</span></div>
          <div><span>diamond(s) sold to it. This compliance is the buyer’s sole responsibility, and Clara shall not be held</span></div>
          <div><span>responsible for any breach of the export/import and sanctions rules by the buyer.</span></div>
          <div><span>8.10</span></div>
          <div><span>Before collecting any rough diamond(s) sold to it, the buyer, its authorized representative and/or its</span></div>
          <div><span>nominated courier shall sign Clara’s form of receipt.</span></div>
          <div><span>8.11</span></div>
          <div><span>No return of goods after collection or delivery shall be accepted.</span></div>
          <br/>
          <div className="terms-and-conditions-modal__body-header"><span>9. BUYER ACKNOWLEDGEMENT: NO WARRANTY</span></div>
          <div><span>9.1</span></div>
          <div><span>The buyer acknowledges and agrees that neither Clara nor a seller makes, and the buyer is not relying</span></div>
          <div><span>on, any information, representation or warranty from Clara or the seller with regard to the rough</span></div>
          <div><span>diamonds purchased on the Clara Platform.</span></div>
          <br/>
          <div className="terms-and-conditions-modal__body-header"><span>10. LIMITED LIABILITY</span></div>
          <div><span>10.1</span></div>
          <div><span>IN NO EVENT SHALL CLARA BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL OR</span></div>
          <div><span>CONSEQUENAL DAMAGES IN CONNECTION WITH OR ARISING OUT OF THESE TERMS AND</span></div>
          <div><span>CONDITIONS, THE USE OF THE CLARA PLATFORM OR OTHERWISE IN RESPECT OF ANY</span></div>
          <div><span>ROUGH DIAMOND SOLD TO THE BUYER ON THE CLARA PLATFORM, INCLUDING WITHOUT</span></div>
          <div><span>LIMITATION LOSS OF PROFITS OR REVENUES, LOSS DATA, FAILURE TO REALIZE EXPECTED</span></div>
          <div><span>SAVINGS, OR ANY OTHER COMMERCIAL OR ECONOMIC LOSS OF ANY KIND, WHETHER</span></div>
          <div><span>BASED ON CONTRACT, TORT OR ANY OTHER LEGAL THEORY EVEN IF CLARA WAS ADVISED</span></div>
          <div><span>OF THE POSSIBILITY OF SUCH DAMAGE.</span></div>
          <br/>
          <div className="terms-and-conditions-modal__body-header"><span>11. DATA</span></div>
          <div><span>11.1</span></div>
          <div><span>Buyer retains ownership of all data it submits on the Clara Platform and hereby grants Clara a</span></div>
          <div><span>perpetual, non-transferrable, non-exclusive, royalty-free, worldwide licence to access, use, copy,</span></div>
          <div><span>support, maintain and sublicenses the data arising or derived from the provision of services to the</span></div>
          <div><span>buyer for any purpose, including but not limited aggregating and analyzing statistical data.</span></div>
          <div><span>11.2</span></div>
          <div><span>The buyer acknowledges that Clara participates in programs from time to time pursuant to which it is</span></div>
          <div><span>asked to provide confirmation of mine origin and the original physical attributes of a rough diamond</span></div>
          <div><span>(the “</span><span className="terms-and-conditions-modal__body-highlight">Provenance Information</span><span>”).  To the extent a buyer participates in any program which involves</span></div>
          <div><span>the verification of Provenance Information, the buyer irrevocably grants to Clara a non-exclusive, non-</span></div>
          <div><span>transferable, royalty-free, worldwide licence to access, use and distribute and sublicense the</span></div>
          <div><span>Provenance Information with respect to each rough diamond sold to it on Clara Platform solely for the</span></div>
          <div><span>purpose of facilitating the delivery of provenance services to diamond retailers.</span></div>
          <br/>
          <div className="terms-and-conditions-modal__body-header"><span>12. COLLUSION AND CONFIDENTIALITY</span></div>
          <div><span>12.1</span></div>
          <div><span>No buyer shall disclose to any other buyer, or to any other person whatsoever, any information relating</span></div>
          <div><span>to the Clara Platform or a sale via the Clara Platform. Without prejudice to the generality of the</span></div>
          <div><span>meaning of the phrase, “information relating to the Clara sales platform or a Clara transaction” shall</span></div>
          <div><span>include:</span></div>
          <div><span>12.1.1  the details of a buyer’s order and the results of the Clara automated matching process;</span></div>
          <div><span>12.1.2  any and all information concerning the Clara Platform, including but not limited to, the web</span></div>
          <div><span>address that is used for accessing the Clara Platform and the username and password</span></div>
          <div><span>associated with any buyer.</span></div>
          <div><span>12.2</span></div>
          <div><span>If a buyer engages in collusive acts, or does not maintain the required confidentiality, without prejudice</span></div>
          <div><span>to all of Clara’s and/or a seller’s rights (including but not limited to terminating or recovering damages</span></div>
          <div><span>for breach of contract), Clara and/or a seller may:</span></div>
          <div><span>12.2.1  recover from the buyer any losses or damages arising out of the collusive act or breach of</span></div>
          <div><span>confidentiality;</span></div>
          <div><span>12.2.2  recover from the buyer any benefit accruing to the buyer by reason of the buyer’s   collusive</span></div>
          <div><span>act or breach of confidentiality. The buyer shall hold such benefit on trust for Clara and/or a</span></div>
          <div><span>seller;</span></div>
          <div><span>12.2.3  terminate any of Clara’s obligations to sell or deliver any rough diamonds (whether pursuant</span></div>
          <div><span>to the Terms and Conditions or otherwise) to the buyer.</span></div>
          <div><span>12.3</span></div>
          <div><span>Clara shall not communicate the name of a buyer registered on Clara without such buyer’s consent,</span></div>
          <div><span>unless and to the extent, required by applicable law.</span></div>
          <div><span>12.4</span></div>
          <div><span>Public disclosure of the sale price of a rough diamond sold on the Clara Platform is solely at a seller’s</span></div>
          <div><span>discretion.</span></div>
          <br/>
          <div className="terms-and-conditions-modal__body-header"><span>13. NOTICES</span></div>
          <div><span>13.1</span></div>
          <div><span>Except as otherwise expressly provided in the Terms and Conditions, Clara may give any notice under</span></div>
          <div><span>the Terms and Conditions to any buyer by such mean as, in its absolute discretion, it sees fit.</span></div>
          <div><span>13.2</span></div>
          <div><span>Any notice, including an invoice, shall be effective upon receipt and shall be deemed to have been</span></div>
          <div><span>received by the buyer at 9.00 am on the third working day after posting, if sent by registered post; or</span></div>
          <div><span>at the time of transmission in legible form, if delivered by electronic means.</span></div>
          <br/>
          <div className="terms-and-conditions-modal__body-header"><span>14. AMENDMENTS TO THE TERMS &amp; CONDITIONS AND TRANSFER OF RIGHTS</span></div>
          <div><span>14.1</span></div>
          <div><span>These Terms &amp; Conditions supersede and replace any/all previous Terms &amp; Conditions of a seller or</span></div>
          <div><span>Clara in relation to the conduct of a rough diamond sale via the Clara Platform.</span></div>
          <div><span>14.2</span></div>
          <div><span>Clara shall be entitled to amend the Terms and Conditions by making such amended terms available</span></div>
          <div><span>on the buyer’s home page on the Clara Platform and/or by email, prior to the time the buyer submits</span></div>
          <div><span>an order on the Clara Platform to which the amended terms shall apply.</span></div>
          <div><span>14.3</span></div>
          <div><span>For referential purposes, the current version of these Terms &amp; Conditions shall be available on the</span></div>
          <div><span>buyer’s home page on the Clara Platform.</span></div>
          <div><span>14.4</span></div>
          <div><span>The buyer is not entitled to transfer any of its rights or obligations under these Terms and Conditions</span></div>
          <div><span>and the agreements with Clara, either whole or in part, to any third party without the prior written</span></div>
          <div><span>consent of Clara. Any such assignment or transfer without the prior written consent shall be deemed</span></div>
          <div><span>null and void.</span></div>
          <div><span>14.5</span></div>
          <div><span>The provisions of these Terms and Conditions shall be interpreted so as to be valid and enforceable</span></div>
          <div><span>under the applicable law. If one or more provisions of these Terms and Conditions is found to be</span></div>
          <div><span>invalid, illegal or enforceable, in whole or in part, the remaining provisions shall not be affected.</span></div>
          <br/>
          <div className="terms-and-conditions-modal__body-header"><span>15. FORCE MAJEURE</span></div>
          <div><span>15.1</span></div>
          <div><span>Any delay or failure of Clara to perform its obligations under these Terms and Conditions will be</span></div>
          <div><span>excused if, and to the extent, that the delay or failure is caused by an event or occurrence beyond the</span></div>
          <div><span>reasonable control of Clara, including but without limitation, acts of God, fires, floods, wars, sabotage,</span></div>
          <div><span>civil unrest, accidents or government laws, rules and regulations.</span></div>
          <br/>
          <div className="terms-and-conditions-modal__body-header"><span>16. GOVERNING LAW AND JURISDICTION</span></div>
          <div><span>16.1</span></div>
          <div><span>The Terms and Conditions and any contract pursuant to them shall be governed by and constructed</span></div>
          <div><span>in accordance with the laws of British Columbia (Canada), and the buyer irrevocably attorns to the</span></div>
          <div><span>exclusive jurisdiction of the Courts of British Columbia (Canada).</span></div>
          <br/>
          <div className="terms-and-conditions-modal__body-header"><span>17. COUNTERPARTS</span></div>
          <div><span>17.1</span></div>
          <div><span>These Terms and Conditions may be executed in any number of counterparts, each of which will be</span></div>
          <div><span>deemed to be an original, and all of which taken together will be deemed to constitute one and the</span></div>
          <div><span>same instrument.</span></div>
          <br/><br/>
        </div>
      </div>
      <div className='modal__buttons'>
        <Button typeVariant='primary' size='sm' onClick={() => setTermsAndConditionsModalIsOpen(false)}>OK</Button>
      </div>
    </Modal>
  )
}

TermsAndConditionsModal.defaultProps = {
}
export default TermsAndConditionsModal
