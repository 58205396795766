import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { ProgressTracker } from '@molecules'
import { Steps, Step } from 'react-step-builder'
import { useMultiStepsStore } from '@stores'
import { useMultiSteps } from '@hooks'
import { RouterPrompt } from '@templates'
function MultiStepsForm({ steps, onSubmit, formData, stateSchema, sharedProps = {} }) {
  const {
    confirmPageExit,
    setOnSubmit,
    setConfirmPageExit,
    setCurrentStep,
    currentStepNumber,
    resetFormLocalState
  } = useMultiStepsStore(state => state)

  useEffect(() => {
    if (onSubmit) setOnSubmit(onSubmit)
    // when component unmount
    // resets current step value
    return () => setCurrentStep(0)
  }, [])

  useEffect(() => {
    return () => resetFormLocalState()
  }, [])

  useEffect(() => {
    const schemaIsDuplicated = isSchemaDuplicated(stateSchema)
    if (schemaIsDuplicated) {
      throw new Error('State schema can\'t have duplicated names.')
    }
  }, [stateSchema])

  useEffect(() => {
    setConfirmPageExit(true)
  }, [])

  function isSchemaDuplicated(stateSchema) {
    // validates if stateSchema contains any duplicated value
    const isDuplicated
      = stateSchema.filter((item, index) => stateSchema.indexOf(item) !== index)
        .length > 0
    return isDuplicated
  }

  const progressTrackerSteps = steps.map((step, index) => {
    return {
      title: step.title,
      order: index + 1
    }
  })

  return (
    <div className='multi-step-form'>
      <ProgressTracker
        steps={progressTrackerSteps}
        currentStepNumber={currentStepNumber}
      />
      <Steps>
        {steps.map((step, index) => (
          <Step
            key={index}
            component={step.component}
            // formData is the object containing
            // the values to populate the form
            // and fill the form's fields
            formData={formData}
            stateSchema={stateSchema}
            {...step.validationProps}
            // pass useMultiStep hook as a form
            // so each child has access to hook directly
            // in props, avoiding importing useMultiSteps hook
            // outside multiStepForm component
            useMultiSteps={useMultiSteps}
            {...sharedProps}
          />
        ))}
      </Steps>
      <RouterPrompt
        when={confirmPageExit}
      />
    </div>
  )
}

MultiStepsForm.propTypes = {
  onSubmit: PropTypes.func,
  formData: PropTypes.object,
  steps: PropTypes.arrayOf(PropTypes.object),
  stateSchema: PropTypes.arrayOf(PropTypes.string)
}

MultiStepsForm.defaultProps = {
  steps: [],
  formData: null,
  onSubmit: null,
  stateSchema: []
}

export default MultiStepsForm
