import { arrayUtils } from '@utils'

// Validate a given Setting groupKey
// Pass in new data for settings arguement
// Pass in existing data for groupKey for group arguement
// Pass in 'create' or 'edit' for mode
// Return false if validation passes
// Return array of errors if validation fails
export const settingSchema = {
  INVOICE_PAYMENT_DETAILS: (settings = [], { mode, group = [] }) => ({
    // Validate that we have at most 1 claraDefault and 1 lucaraDefault
    multipleDefaults: () => {
      const getDefaults = (arr) => arr.reduce((defs, setting) => {
        if (setting.claraDefault) defs.claraDefault.push(setting)
        if (setting.lucaraDefault) defs.lucaraDefault.push(setting)
        return defs
      }, { claraDefault: [], lucaraDefault: [] })

      // Filter out any rows from group that are present in settings
      const filteredGroup = group.filter(({ key }) => !settings.some(({ key: settingKey }) => key === settingKey))

      const existingDefaults = getDefaults(filteredGroup)
      if (existingDefaults.claraDefault.length > 1 || existingDefaults.lucaraDefault.length > 1) console.error('Too many defaults in the db. Please fix.')
      const newDefaults = getDefaults(settings)

      const errors = []
      const claraDefault = arrayUtils.unionBy(existingDefaults.claraDefault, newDefaults.claraDefault, (x) => x.key)
      if (claraDefault.length > 1) errors.push('Invoice Payment Details should have at most 1 claraDefault.')
      const lucaraDefault = arrayUtils.unionBy(existingDefaults.lucaraDefault, newDefaults.lucaraDefault, (x) => x.key)
      if (lucaraDefault.length > 1) errors.push('Invoice Payment Details should have at most 1 lucaraDefault.')

      if (errors.length) return errors
      return false
    }
  }),
  DEFAULT: (settings = [], { mode, group = [] }) => ({
    // Validate that we are not duplicating keys
    duplicateKeys: () => {
      if (mode !== 'edit' && arrayUtils.intersectBy(settings, group, (x) => x.key).length) return ['Duplicate Key(s) are not permitted for a given Group Key.']
      return false
    }
  })
}
