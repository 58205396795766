import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { FileUpload, Table } from '@organisms'
import { orderActions, cutGradeActions } from '@actions'
import { useAuthStore, useGlobalsStore } from '@stores'
import { useToast } from '@hooks'
import { Icon, Button } from '@atoms'
import { arrayUtils, fileUtils, numberUtils, objectUtils, textUtils } from '@utils'
import PolishedPriceBookParameters from './polishedPriceBookParameters'
import ExcelJS from 'exceljs/dist/es5/exceljs.browser'
import { Collapsible } from '@molecules'

function PolishedPriceBookModal({ orders }) {
  const orderIds = orders.map(o => o.id)
  const buyersIds = new Set(orders.map(o => o.buyerId))
  const [fullShapeList, setFullShapeList] = useState([])
  const [fullCutGradeList, setFullCutGradeList] = useState([])
  const [priceBook, setPriceBook] = useState([])
  const [errors, setErrors] = useState(null)
  const paramFields = [
    'shapeId',
    'cutGradeId',
    'colour',
    'clarity',
    'fluorescence',
    'tinge',
    'polishedWeight'
  ]
  const [parameters, setParameters] = useState([populateParamsObject(paramFields)])
  const { showErrorToast } = useToast()
  const {
    getPolishedColours,
    getClarities,
    polishedColours,
    clarities,
    roughTinges,
    getRoughTinges,
    polishedFluorescences,
    getPolishedFluorescences,
    orgsMap: { all: orgsMap },
    getOrgsList
  } = useGlobalsStore(store => store)
  const { hasAdmin, permissionsAdminCache } = useAuthStore()
  const shapeList = useMemo(() => {
    const orderShapes = orders.map(({ shapeId }) => ({ id: shapeId }))
    return arrayUtils.intersectBy(fullShapeList || [], orderShapes, (x) => x.id)
  }, [orders, fullShapeList])
  const cutGradeList = useMemo(() => {
    const orderCutGrades = orders.map(({ cutGradeId }) => ({ id: cutGradeId }))
    return arrayUtils.intersectBy(fullCutGradeList || [], orderCutGrades, (x) => x.id)
  }, [orders, fullCutGradeList])
  const colourList = useMemo(() => (polishedColours || []).map(c => ({ value: c.value, label: c.description })), [polishedColours])
  const clarityList = useMemo(() => (clarities || []).map(c => ({ value: c.value, label: c.description })), [clarities])
  const fluorList = useMemo(() => (polishedFluorescences || []).map(f => ({ value: f.value, label: f.description })), [polishedFluorescences])
  const tingeList = useMemo(() => (roughTinges || []).map(t => ({ value: t.value, label: t.description })), [roughTinges])
  const cutGradeMap = useMemo(() => arrayUtils.toMap(cutGradeList, x => x.id), [cutGradeList])
  const shapeMap = useMemo(() => arrayUtils.toMap(shapeList, x => x.value), [shapeList])
  const colourMap = useMemo(() => arrayUtils.toMap(colourList, x => x.value), [colourList])
  const clarityMap = useMemo(() => arrayUtils.toMap(clarityList, x => x.value), [clarityList])
  const fluorMap = useMemo(() => arrayUtils.toMap(fluorList, x => x.value), [fluorList])
  const tingeMap = useMemo(() => arrayUtils.toMap(tingeList, x => x.value), [tingeList])
  const dataValidationArrs = useMemo(() => ({
    shape: arrayUtils.pickBy(shapeList, 'label'),
    color: arrayUtils.pickBy(colourList, 'label'),
    clarity: arrayUtils.pickBy(clarityList, 'label'),
    fluorescence: arrayUtils.pickBy(fluorList, 'label'),
    tinge: arrayUtils.pickBy(tingeList, 'label')
  }), [shapeList, colourList, clarityList, fluorList, tingeList])

  useEffect(() => {
    getPolishedColours()
    getClarities()
    getPolishedFluorescences()
    getRoughTinges()
    getOrgsList()
  }, [])

  useEffect(() => {
    cutGradeActions.getShapeList({ condition: 'ACTIVE' })
    .then(response => {
      setFullShapeList(response?.data?.data?.filter(s => s.buyerId ? buyersIds.has(s.buyerId) : true).map(item => ({ id: item.id, value: item.id, label: item.name })))
    })
  }, [])

  useEffect(() => {
    cutGradeActions.getCutGradeList({ condition: 'ACTIVE' })
    .then(response => setFullCutGradeList(response?.data?.data
      .map(grade => ({ id: grade.id, shapeId: grade.shapeId, value: grade.id, name: grade.name, label: (grade.buyerId !== null ? 'Custom | ' : 'Preloaded | ') + grade.institute + ' | ' + grade.name }))
      .sort((a, b) => { return a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1 })
    ))
  }, [])

  function handleOnChange(event, key) {
    const name = event?.target?.name || event?.currentTarget?.name
    const value = event?.target?.value || event?.currentTarget?.value
    setParameters(prev => prev.toSpliced(key, 1, { ...prev.at(key), [name]: value }))
  }

  function handleOnAddParameters() {
    setParameters(prev => prev.concat(populateParamsObject(paramFields)))
  }

  function populateParamsObject(fields) {
    const params = {}
    fields.forEach(function(key) {
      params[key] = null
    })
    return params
  }

  function handleGeneratePriceBook(parameters) {
    const hasErrors = validateRequiredParams(parameters)
    if (hasErrors) return
    orderActions.getPriceBook({
      orderIds,
      polishedStones: [
        ...parameters.map(obj => ({
          ...objectUtils.filterNullish(obj)
        }))
      ]
    }).then(response => setPriceBook(parsePriceBookData(response.data.data)))
  }

  function validateRequiredParams(params) {
    setErrors(null)
    let hasErrors = false
    params.forEach((param, idx) => {
      if (Object.values(param).some(p => !p)) {
        setParamsError(idx, 'Missing required fields.')
        hasErrors = true
      }
    })
    return hasErrors
  }

  function setParamsError(key, errorMessage) {
    setErrors(prev => ({ ...prev, [key]: errorMessage }))
  }

  function parsePriceBookData(data) {
    if (!data?.length) return []
    return data.map((d, i) => ({
      idx: i + 1,
      ...parameters[i],
      buyerName: !d.success ? d.message : orgsMap?.[d?.order?.buyerId],
      basePpc: d?.priceBreakdown?.basePpc,
      totalDiscount: d?.priceBreakdown?.totalDiscount,
      totalPpc: d?.priceBreakdown?.totalPpc,
      orderName: d?.order?.name,
      totalStone: d?.priceBreakdown?.totalStone
    }))
  }

  const columns = useMemo(() => ([
    {
      Header: 'No.',
      dataType: 'number',
      accessor: 'idx'
    },
    {
      Header: 'Shape',
      accessor: row => shapeMap[row.shapeId]?.label
    },
    {
      Header: 'Cut Grade',
      accessor: row => cutGradeMap[row.cutGradeId]?.label,
      formatExcel: (value) => value.split(' | ').at(-1)
    },
    {
      Header: 'Color',
      accessor: row => colourMap[row.colour]?.label,
      style: { width: '50px' }
    },
    {
      Header: 'Fluorescence',
      accessor: row => fluorMap[row.fluorescence]?.label
    },
    {
      Header: 'Clarity',
      accessor: row => clarityMap[row.clarity]?.label
    },
    {
      Header: 'Tinge',
      accessor: row => tingeMap[row.tinge]?.label
    },
    {
      Header: 'Weight',
      dataType: 'number',
      accessor: 'polishedWeight'
    },
    ...(hasAdmin(orderActions.getPriceBook) ? [{
      Header: 'Buyer Name',
      accessor: 'buyerName'
    }] : []),
    {
      Header: 'Order Name',
      accessor: 'orderName'
    },
    {
      Header: 'Base PPC',
      dataType: 'currency',
      accessor: 'basePpc'
    },
    {
      Header: 'Discount',
      dataType: 'number',
      accessor: row => numberUtils.numFmt(row?.totalDiscount, 2) + ' %'
    },
    {
      Header: 'PPC',
      dataType: 'currency',
      accessor: 'totalPpc'
    },
    {
      Header: 'Total',
      dataType: 'currency',
      accessor: 'totalStone'
    }
  ]), [shapeMap, cutGradeMap, colourMap, fluorMap, clarityMap, tingeMap, permissionsAdminCache])

  async function handleOnFileSelect(file) {
    if (!file) return
    try {
      const buffer = await fileUtils.promiseReadBinaryFile(file)
      const book = new ExcelJS.Workbook()
      await book.xlsx.load(buffer)
      const sheet = book.getWorksheet('Parameters') || book.worksheets.find(sheet => fileUtils.findHeaderRowIdx(sheet, ['shape', 'cut grade', 'color', 'fluorescence', 'clarity', 'tinge', 'weight'], 1) > -1)
      if (!sheet) throw new Error('"Parameters" sheet could not be found in workbook.')
      const headerRow = getHeaderRow(sheet)
      sheet.getRow(headerRow.num).eachCell((cell, colNum) => {
        const cellVal = cell.text.trim().toLowerCase()
        const column = sheet.getColumn(colNum)
        column.key = cellVal
      })

      const params = []
      sheet.eachRow((row, num) => {
        if (num <= headerRow.num) return
        params.push(parseRow(row))
      })
      setParameters(params)
    } catch (error) {
      console.error(error)
      showErrorToast(error.message)
    }
  }

  function getHeaderRow(sheet) {
    let headerRow = null
    const requiredColumns = ['shape', 'cut grade', 'color', 'fluorescence', 'clarity', 'tinge', 'weight']

    sheet.eachRow((row, num) => {
      if (headerRow === null && (row.values.some(val => val?.toLowerCase?.() === 'shape'))) headerRow = { num, row }
    })

    if (headerRow == null) throw new Error('No header row could be identified.')
    if (!requiredColumns.every(column => Object.values(headerRow.row.values).map(v => String(v).toLowerCase()).includes(String(column).toLowerCase()))) {
      throw new Error('Missing required columns.')
    }

    return headerRow
  }

  function parseRow(row) {
    const parsed = {}
    parsed.shapeId = setShapeParameter(row.getCell('shape').text)
    parsed.cutGradeId = setCutGradeParameter(parsed.shapeId, row.getCell('cut grade').text)
    parsed.colour = colourList.find(c => String(c.value).toLowerCase() === String(row.getCell('color').text).toLowerCase() || String(c.label).toLowerCase() === String(row.getCell('color').text).toLowerCase())?.value || null
    parsed.clarity = clarityList.find(c => String(c.value).toLowerCase() === String(row.getCell('clarity').text).toLowerCase() || String(c.label).toLowerCase() === String(row.getCell('clarity').text).toLowerCase())?.value || null
    parsed.fluorescence = fluorList.find(f => String(f.value).toLowerCase() === String(row.getCell('fluorescence').text).toLowerCase() || String(f.label).toLowerCase() === String(row.getCell('fluorescence').text).toLowerCase())?.value || null
    parsed.tinge = tingeList.find(t => String(t.value).toLowerCase() === String(row.getCell('tinge').text).toLowerCase() || String(t.label).toLowerCase() === String(row.getCell('tinge').text).toLowerCase())?.value || null
    parsed.polishedWeight = row.getCell('weight').text
    return parsed
  }

  function setShapeParameter(selectedShape) {
    if (!selectedShape) return null
    if (!shapeList?.length) return null
    return shapeList.find(s => s.value === selectedShape || String(s.label).toLowerCase() === String(selectedShape).toLowerCase())?.value || null
  }

  function setCutGradeParameter(shapeId, selectedCutGrade) {
    if (!selectedCutGrade) return null
    if (!cutGradeList?.length) return null
    if (!shapeList?.length) return null
    return cutGradeList.find(c => c.shapeId === shapeId && (c.id === selectedCutGrade || String(c.name).toLowerCase() === String(selectedCutGrade).toLowerCase()))?.id || null
  }

  function handleOnRemoveParameters(key) {
    setParameters(prev => prev.toSpliced(key, 1))
  }

  function generateTemplate() {
    const columns = ['shape', 'cut grade', 'weight', 'color', 'clarity', 'tinge', 'fluorescence']
    const dataValidationColumns = ['shape', 'color', 'clarity', 'tinge', 'fluorescence']

    try {
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'Clara'
      workbook.created = new Date()
      const sheet = workbook.addWorksheet('Parameters')
      sheet.columns = columns.map(column => ({
        header: textUtils.capitalize(column, true),
        key: column
      }))

      for (const column of dataValidationColumns) {
        const sheetCol = sheet.getColumn(column)
        sheet.dataValidations.model[`${sheetCol.letter}2:${sheetCol.letter}9999`] = {
          allowBlank: true,
          error: 'Please use the drop down to select a valid value.',
          errorTitle: 'Invalid Selection',
          showErrorMessage: true,
          type: 'list',
          ...(dataValidationArrs[column]?.length <= 80 ? { formulae: [`"${dataValidationArrs?.[column]}"`] } : {})
        }
      }

      return workbook.xlsx.writeBuffer().then(buffer => fileUtils.saveBufferExcel(buffer, 'price_book_parameters.xlsx'))
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className='polished-price-book-modal'>
      <Collapsible
        title={'Upload Parameters File'}
        isOpenedOnRender={true}
      >
        <div>
          <h2>Upload Parameters File</h2>
          <FileUpload
            label='Upload .xlsx file'
            name='uploadXlsFile'
            acceptTypes={['.xlsx']}
            onChange={handleOnFileSelect}
          />
          <div className='polished-price-book-modal__download-template'>
            <Icon
              name='download'
              onClick={generateTemplate}
            />
            <Button
              typeVariant='action'
              size='sm'
              onClick={generateTemplate}
            >Download Template</Button>
          </div>
        </div>
      </Collapsible>
      <Collapsible
        title='Polished Details'
        isOpenedOnRender={true}
      >
        <div className='polished-price-book-modal__parameters'>
          <h2>Polished Details</h2>
          {
          parameters?.map((param, i) => (
            <div key={i} className="polished-price-book-modal__row">
              <PolishedPriceBookParameters
                onChange={(e, key) => handleOnChange(e, key)}
                parameters={param}
                index={i}
                shapeList={shapeList}
                cutGradeList={cutGradeList}
                colourList={colourList}
                fluorList={fluorList}
                clarityList={clarityList}
                tingeList={tingeList}
                errors={errors?.[i]}
              />
              <div className='polished-price-book-modal__row-icons'>
                <Icon
                  name='plus'
                  size='lg'
                  onClick={handleOnAddParameters}
                />
                <Icon
                  name='remove'
                  size='lg'
                  disabled={parameters.length <= 1}
                  onClick={() => handleOnRemoveParameters(i)}
                />
              </div>
            </div>
          ))
          }
        </div>
        <div className="polished-price-book-modal__parameters-button">
          <Button
            onClick={() => handleGeneratePriceBook(parameters)}
          >
            Generate Price Book
          </Button>
        </div>

      </Collapsible>
      <Collapsible
        title='Results'
        isOpened={priceBook?.length}
      >
        <div>
          <h2>Results</h2>
          <Table
            title='Generic Price Book'
            columns={columns}
            data={priceBook}
          />
        </div>
      </Collapsible>
    </div>
  )
}

PolishedPriceBookModal.defaultProps = {
  orderIds: []
}

export default PolishedPriceBookModal
