const ORG = {
  PREFERRED_SHIPPING: [
    { label: 'Brinks', value: 'Brinks' },
    { label: 'Malca Amit', value: 'Malca' },
    { label: 'Ferrari', value: 'Ferrari' }
  ],
  SAW_THICKNESS_UNITS: [
    {
      label: 'Microns',
      value: 'microns',
      name: 'microns'
    },
    {
      label: 'Percent',
      value: 'percent',
      name: 'percent'
    }
  ],
  ACCEPT_HIGH_TENSION: [
    { value: '0', label: 'Do Not Accept High Tension' },
    { value: '1', label: 'Main Stone Only' },
    { value: '2', label: 'Include Tops' }
  ],
  ACCEPT_YELLOW_FLU: [
    { value: 'false', label: 'Accept Yellow UV' },
    { value: 'true', label: 'Do Not Accept Yellow UV' }
  ],
  RISK_LEVEL: [
    { value: '', label: 'N/A', default: 'DEFAULT' },
    { value: 'Low', label: 'Low' },
    { value: 'Medium', label: 'Medium' },
    { value: 'High', label: 'High' }
  ],
  ACCEPT_WINDOWED_STONES: [
    { value: '', label: 'Disable Windowed Stones' },
    { value: 'false', label: 'Do Not Accept Windowed Stones', default: true },
    { value: 'true', label: 'Accept Windowed Stones' }
  ],
  ACCEPT_BLOCKED_STONES: [
    { value: '', label: 'Disable Blocked Stones' },
    { value: 'false', label: 'Do Not Accept Blocked Stones', default: true },
    { value: 'true', label: 'Accept Blocked Stones' }
  ]
}

export default ORG
