import axios from 'axios'
const BASE_URL = process.env.REACT_APP_API_BASE_URL

const getShapeGroupsList = function(params) {
  return axios.get(`${BASE_URL}/sales/shapegroup`, { params })
}
getShapeGroupsList.endpoint = 'GET|/sales/shapegroup'

const createShapeGroup = function(shapeGroup) {
  return axios.post(`${BASE_URL}/sales/shapegroup`, shapeGroup)
}
createShapeGroup.endpoint = 'POST|/sales/shapegroup'

const setShapeGroupShapes = function({ shapeGroupId, ...body }) {
  return axios.put(`${BASE_URL}/sales/shapegroup/${shapeGroupId}`, body)
}
setShapeGroupShapes.endpoint = 'PUT|/sales/shapegroup/:shapeGroupId:'

const setOrderShapeGroups = function({ shapeGroupId, genericOrderIds }) {
  return axios.put(`${BASE_URL}/sales/shapegroup/mapping`, { shapeGroupId, genericOrderIds })
}
setOrderShapeGroups.endpoint = 'PUT|/sales/shapegroup/mapping/:genericOrderId:'

export const shapeGroupActions = {
  getShapeGroupsList,
  createShapeGroup,
  setShapeGroupShapes,
  setOrderShapeGroups
}
