import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Dropdown, TextInput } from '@molecules'
import { Checkbox } from '@atoms'
import { arrayUtils } from '@utils'

function ReportAttachment({
  disabled,
  attachment,
  handleOnChange,
  index,
  reportTypes
}) {
  const parameters = {
    'orgId|[ORG_ID]': 'Org Id',
    'saleId|[SALE_ID]': 'Sale Id'
  }

  const reportTypesMap = useMemo(() => {
    return arrayUtils.toMap(reportTypes || [], (x) => x.value)
  }, [reportTypes])

  function handleOnReportTypeChange(index, e) {
    handleOnChange(index, e)
    const reportTypeValue = e.currentTarget.value
    const defaultFileName = reportTypesMap[reportTypeValue]?.defaultFileName
    if (defaultFileName) {
      handleOnChange(index, { target: { name: 'fileName', value: defaultFileName } })
    }
  }

  return (
    <>
      <div className="notification-attachment-list__row right">
        <Checkbox
          label='Required'
          name='required'
          disabled={disabled}
          value={attachment?.required}
          onChange={e => handleOnChange(index, e)}
        />
      </div>
      <div className="notification-attachment-list__row">
        <Dropdown
          label='Report Type'
          name='reportType'
          options={reportTypes?.map(type => ({
            label: type.description,
            value: type.value
          }))}
          value={attachment?.reportType}
          onChange={e => handleOnReportTypeChange(index, e)}
          disabled={disabled}
          isClearable={false}
          required={!disabled}
        />
        <TextInput
          label='File Name'
          name='fileName'
          value={attachment?.fileName}
          onChange={e => handleOnChange(index, e)}
          disabled={disabled}
        />
      </div>
      <div className="notification-attachment-list__row">
        <Dropdown
          label='Parameters'
          name='parameters'
          value={attachment?.parameters}
          options = {Object.keys(parameters).map(p => ({
            value: p,
            label: parameters[p]
          }))}
          disabled={disabled}
          isClearable={true}
          onChange={e => handleOnChange(index, e)}
          isMulti={true}
          required={false}
        />
      </div>
    </>
  )
}

ReportAttachment.propTypes = {
  index: PropTypes.number,
  disabled: PropTypes.bool,
  attachment: PropTypes.object,
  handleOnChange: PropTypes.func.isRequired,
  reportTypes: PropTypes.arrayOf(PropTypes.object)
}

ReportAttachment.defaultProps = {
  disabled: false,
  reportTypes: []
}

export default ReportAttachment
