import create from 'zustand'
import { shapeGroupActions } from '@actions'

const shapeGroupsListPromise = {}

const SHAPE_GROUP_STORE_INITIAL_STATE = {
  shapeGroupsList: {}
}

const useShapeGroupStore = create((set, get) => ({
  ...SHAPE_GROUP_STORE_INITIAL_STATE,
  getShapeGroupsList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!get().shapeGroupsList[paramString]) await get().setShapeGroupsList(params)
    return get().shapeGroupsList[paramString] || []
  },
  setShapeGroupsList: async (params) => {
    const paramString = params && Object.keys(params).length ? JSON.stringify(params) : 'all'
    if (!shapeGroupsListPromise[paramString]) {
      shapeGroupsListPromise[paramString] = shapeGroupActions.getShapeGroupsList(params).finally(() => {
        shapeGroupsListPromise[paramString] = null
      })
    }
    const queryResults = await shapeGroupsListPromise[paramString]
    if (!queryResults) return
    set({ shapeGroupsList: { ...get().shapeGroupsList, [paramString]: queryResults.data.data } })
  },
  getShapeGroupById: async shapeGroupId => {
    if (!get().shapeGroupsList?.all) await get().setShapeGroupsList()
    return get().shapeGroupsList?.all.find(shapeGroup => shapeGroup.id === shapeGroupId)
  },
  resetShapeGroupStore: () => {
    set({
      ...SHAPE_GROUP_STORE_INITIAL_STATE
    })
  }
}))

export {
  useShapeGroupStore
}
