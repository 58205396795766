import React, { useEffect, useState } from 'react'
import { useAuthStore, useGlobalsStore } from '@stores'
import { Table } from '@organisms'
import { useHistory, NavLink } from 'react-router-dom'
import { provenanceActions } from '@actions'
import { useToast } from '@hooks'

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    dataType: 'string',
    Cell: cellInfo => {
      return (
        <NavLink
          className="link"
          to={`/countries/${cellInfo.row.original.id}`}
          id={cellInfo.row.original.id}
        >
          {cellInfo.value}
        </NavLink>
      )
    }
  },
  {
    Header: 'Secondary Market',
    accessor: 'secondaryMarket',
    dataType: 'boolean',
    filterType: 'checkbox'
  },
  {
    Header: 'Licence',
    accessor: 'requiredLicence',
    dataType: 'string',
    filterType: 'checkbox'
  }
]

function CountryList() {
  const { orgId: userOrgId, hasAdmin, permissionsAdminCache, hasPermission } = useAuthStore(state => state)
  const [countryParams, setCountryParams] = useState()

  const {
    getCountriesList,
    resetCountriesList,
    countriesList: { [countryParams ? JSON.stringify(countryParams) : 'all']: countries }
  } = useGlobalsStore(store => store)
  useEffect(() => {
    if (!permissionsAdminCache.size) return
    const params = getParams()
    setCountryParams(params)
  }, [permissionsAdminCache])
  useEffect(() => {
    if (countryParams) getData(countryParams)
  }, [countryParams])

  function getParams(condition = 'ACTIVE') {
    return {
      ...hasAdmin(provenanceActions.getCountries) ? null : { sellerId: userOrgId },
      condition
    }
  }
  function getData(params) {
    getCountriesList(params)
  }

  const history = useHistory()
  const { showSuccessToast } = useToast()

  function handleArchive(row) {
    return provenanceActions.setCountryCondition(row.id, 'ARCHIVED')
    .then(() => {
      showSuccessToast('Country has been archived.')
    })
  }

  function handleUnarchive(row) {
    return provenanceActions.setCountryCondition(row.id, 'ACTIVE')
    .then(() => {
      showSuccessToast('Country has been unarchived.')
    })
  }

  function handleRemove(row) {
    return provenanceActions.setCountryCondition(row.id, 'DELETED')
    .then(() => {
      showSuccessToast('Country has been removed.')
    })
  }

  const rowActions = [
    {
      actionName: 'archive',
      callback: handleArchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ACTIVE'
    },
    {
      actionName: 'unarchive',
      callback: handleUnarchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ARCHIVED'
    },
    {
      actionName: 'remove',
      callback: handleRemove,
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition !== 'DELETED'
    }
  ]

  const tableTabs = [{
    label: 'Active',
    params: 'ACTIVE'
  },
  {
    label: 'Archived',
    params: 'ARCHIVED'
  }]

  const topBarActions = hasPermission(provenanceActions.createCountry)
    ? [{
      label: 'Create Country',
      callback: () => history.push('/countries/create')
    }]
    : []

  return (
    <Table
      title='Countries'
      data={countries}
      topBarActions={topBarActions}
      rowActions={rowActions}
      tableTabs={tableTabs}
      columns={columns}
      getDataCallback={(condition) => setCountryParams(getParams(condition))}
      resetDataCallback={resetCountriesList}
      isMultiSelect={false}
    />
  )
}

export default CountryList
