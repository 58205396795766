import React from 'react'
import PropTypes from 'prop-types'

function Badge({
  label,
  count,
  classNames
}) {
  return (
    <div className={['badge'].concat(classNames).join(' ')}>
      {label}
      {count
        ? (
          <span className="badge-counter">
            {count}
          </span>
        ) : null }
    </div>
  )
}

Badge.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  count: PropTypes.number,
  classNames: PropTypes.array
}

Badge.defaultProps = {
  label: 'Title',
  count: 0,
  classNames: []
}
export default Badge
