import React, { useState, useEffect } from 'react'
import { FormComponents } from '@organisms'
import { useHistory } from 'react-router-dom'
import { Modal } from '@templates'
import CreateEventType from './createEventType'
import { eventActions } from '@actions'
import { useToast } from '@hooks'
import moment from 'moment'

// TODO: navigate to event list after created
function CreateEvent() {
  const [isEventTypeModalOpen, setIsEventTypeModalOpen] = useState(false)
  const [eventTypes, setEventTypes] = useState([])
  const [groupIds, setGroupIds] = useState([])
  const { showSuccessToast } = useToast()
  const history = useHistory()
  const {
    SimpleForm,
    TextInput,
    TextArea,
    SingleCheckbox,
    Dropdown,
    Button
  } = FormComponents

  useEffect(() => {
    getGroupIds()
    getEventTypes()
  }, [])

  function getEventTypes() {
    eventActions.getEventTypes()
    .then(response => setEventTypes(response.data.data))
  }

  function getGroupIds() {
    eventActions.getEvents()
    .then(response => {
      setGroupIds([...new Set(response.data.data.filter(event => event.groupId)
      .map(event => event.groupId))])
    })
  }

  function handleOnSubmit(event) {
    if (event.startTime) {
      event.startTime = moment(event.startTime).toISOString()
    }

    if (event.endTime) {
      event.endTime = moment(event.endTime).toISOString()
    }

    if (!event.visible) {
      event.visible = false
    }

    eventActions.createEvent(event)
    .then(() => {
      showSuccessToast('Event created.')
      history.push('/events')
    })
  }

  const topActions = [
    {
      label: 'Create Event Type',
      onClick: () => setIsEventTypeModalOpen(true)
    }
  ]

  return (
    <div className='create-event'>
      <div className="col-xl-4 col-lg-7 col-md-10 col-sm-12">
        <SimpleForm
          title='New Event'
          onSubmit={e => handleOnSubmit(e)}
        >
          <TextInput
            name='name'
            label='Name'
          />
          <TextArea
            name='description'
            label='Description'
            required={false}
          />
          <SingleCheckbox
            name='visible'
            label='Is Visible'
            required={false}
          />
          <Dropdown
            name='typeId'
            label='Event Type'
            topActions={topActions}
            options={eventTypes.map(eventType => {
              return {
                label: eventType.name,
                value: eventType.id
              }
            })}
          />
          <Dropdown
            name='groupId'
            label='Group ID'
            required={false}
            creatable
            options ={ groupIds.map(groupId => {
              return {
                label: groupId,
                value: groupId
              }
            })}
          />
          <TextInput
            name='startTime'
            label='Start Date'
            type='datetime-local'
          />
          <TextInput
            name='endTime'
            label='End Date'
            required={false}
            type='datetime-local'
          />
          <Button
            size='sm'
            typeVariant='action'
            onClick={() => history.goBack()}
          >
           Cancel
          </Button>
          <Button
            size='sm'
            type='submit'
          >
           Create Event
          </Button>
        </SimpleForm>
      </div>
      <Modal
        open={isEventTypeModalOpen}
        title='New Event Type'
        onClose={() => setIsEventTypeModalOpen(false)}
      >
        <CreateEventType
          onSubmitSuccess={() => {
            getEventTypes()
            setIsEventTypeModalOpen(false)
          }}
          onCancel={() => setIsEventTypeModalOpen(false)}
        />
      </Modal>
    </div>
  )
}

export default CreateEvent
