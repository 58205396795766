import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Icon from '../icon/icon'
import { arrayUtils, objectUtils } from '@utils'
import { COMMON } from '@constants'

function Button({
  size,
  loading,
  iconName,
  noOutline,
  disabled,
  typeVariant,
  noPadding,
  buttonRef,
  ...props
}) {
  const sizeSuffix = {
    sm: '--sm',
    md: '--md',
    lg: '--lg'
  }[size] || '--md'

  const typeClassName = {
    icon: 'icon',
    action: 'action',
    primary: 'primary',
    secondary: 'secondary',
    warning: 'warning',
    error: 'error'
  }[typeVariant] || 'primary'

  const validHTMLElementProps = useMemo(() => {
    const propKeys = Object.keys(props)
    const validKeys = arrayUtils.intersectBy(propKeys, COMMON.HTML_ATTRIBUTES.GLOBAL.concat(COMMON.HTML_ATTRIBUTES.BUTTON), (x) => x.toLowerCase())
    return objectUtils.pick(props, validKeys)
  }, [props])

  return (
    <button
      onClick={props.onClick}
      className={`btn-${typeClassName}${noOutline ? '--no-outline' : ''} btn${sizeSuffix} ${noPadding ? 'btn--no-padding' : ''}`}
      disabled={disabled || loading}
      ref={buttonRef}
      {...validHTMLElementProps}
    >
      <span className={`button-text${sizeSuffix}`}>
        {
          typeVariant === 'icon'
            ? <Icon name={iconName} size={size} />
            : props.children
        }
        {
          loading
            ? (
              <span className="btn__spinner">
                <Icon name='spinner' spin={true} />
              </span>
            )
            : null
        }
      </span>
    </button>
  )
}

Button.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  noPadding: PropTypes.bool,
  disabled: PropTypes.bool,
  noOutline: PropTypes.bool,
  iconName: PropTypes.string,
  typeVariant: PropTypes.string,
  buttonRef: PropTypes.func
}

Button.defaultProps = {
  size: 'md',
  type: 'button',
  loading: false,
  disabled: false,
  noPadding: false,
  noOutline: false,
  iconName: 'info',
  typeVariant: 'primary'
}

export default Button
