import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@templates'
import { Button } from '@atoms'
import { TextArea } from '@molecules'
import { Table } from '@organisms'
import { useGlobalsStore, useAuthStore } from '@stores'
import { useToast } from '@hooks'
import { organizationActions, roughStoneActions, saleActions } from '@actions'
import { arrayUtils, numberUtils } from '@utils'
import PQueue from 'p-queue'
import clone from 'just-clone'

function SelectShipmentStonesModal({
  open,
  disabled,
  roughStones,
  onClose,
  onChange,
  readonly,
  isEditToggled
}) {
  const {
    orgsMap: { all: orgsMap },
    getOrgsList,
    roughStatusesMap,
    getRoughStatuses,
    countriesList: { all: countriesList },
    getCountriesList
  } = useGlobalsStore(store => store)
  const { hasAdmin } = useAuthStore(state => state)
  const displayOrgColumn = hasAdmin(organizationActions.getOrganizationList)
  const displayTransactionColumn = hasAdmin(roughStoneActions.getRoughStoneList)
  const [isAddStonesModalOpen, setIsAddStonesModalOpen] = useState(false)
  const [hasSaved, setHasSaved] = useState(false)
  const [addRoughStones, setAddRoughStones] = useState('')
  const [topBarActions, setTopBarActions] = useState([])
  const [roughIds, setRoughIds] = useState([])
  const [localRoughStones, setLocalRoughStones] = useState([])
  const [initialLocalRoughStones, setInitialLocalRoughStones] = useState([])
  const [allSales, setAllSales] = useState({})
  const { showErrorToast } = useToast()
  const [openRoughStonesModal, setOpenRoughStonesModal] = useState(open)

  useEffect(() => {
    setOpenRoughStonesModal(open)
  }, [open])

  useEffect(() => {
    if (openRoughStonesModal) {
      setRoughIds(localRoughStones.map(rough => rough.id))
      setHasSaved(false)
    }
  }, [openRoughStonesModal, localRoughStones])

  useEffect(() => {
    if (roughStones && disabled) {
      setLocalRoughStones(sanitizeLocalRoughStones(clone(roughStones)))
    }
  }, [roughStones, orgsMap, allSales])

  useEffect(() => {
    getOrgsList()
    saleActions.getSales({ columns: '[id, name, status, updatedAt]' })
    .then(result => {
      setAllSales(arrayUtils.toMap(result.data.data, (x) => x.id, 'name'))
    })
  }, [])

  useEffect(() => {
    getRoughStatuses()
    getCountriesList()
  }, [])
  const countriesMap = useMemo(() => arrayUtils.toMap(countriesList, ({ id }) => id, 'name'), [countriesList])

  useEffect(() => {
    if (localRoughStones && disabled) {
      setInitialLocalRoughStones(localRoughStones)
    }
  }, [localRoughStones])

  useEffect(() => {
    setTopBarActions([
      {
        label: 'Add Stones',
        callback: () => setIsAddStonesModalOpen(true),
        disabled
      },
      {
        label: 'Ok',
        typeVariant: 'primary',
        disabled: localRoughStones?.length === initialLocalRoughStones?.length && localRoughStones.every((lR, i) => initialLocalRoughStones[i].id === lR.id),
        callback: () => {
          addStones(localRoughStones?.map(rough => rough.id))
          setRoughIds(localRoughStones?.map(rough => rough.id))
          setInitialLocalRoughStones(localRoughStones)
          setOpenRoughStonesModal(false)
          setHasSaved(true)
        }
      }
    ])
  }, [disabled, localRoughStones, initialLocalRoughStones])

  function sanitizeLocalRoughStones(localRoughStones) {
    if (!localRoughStones?.length) return []
    return localRoughStones.map(rough => {
      if (!rough.sellerName) {
        rough.sellerName = orgsMap?.[rough.sellerId]
      }

      if (rough.Transactions?.length) {
        rough.saleName = allSales?.[rough?.Transactions[0]?.saleId]
      }

      if (rough.Assortment) {
        rough.assortmentName = rough?.Assortment?.name || ''
      }

      return {
        id: rough.id,
        sellerStoneName: rough.sellerStoneName,
        assortmentName: rough.assortmentName,
        sellerName: rough.sellerName,
        weight: rough.weight,
        status: rough.status,
        location: rough.location,
        saleName: rough.saleName,
        countryId: rough.countryId
      }
    })
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'Clara ID',
        accessor: 'id',
        dataType: 'string',
        filterType: 'textarea'
      },
      {
        Header: 'Seller Stone Name',
        accessor: 'sellerStoneName',
        dataType: 'string'
      },
      {
        Header: 'Assortment Name',
        accessor: 'assortmentName',
        dataType: 'string'
      },
      ...(displayOrgColumn
        ? [{
          Header: 'Seller',
          accessor: 'sellerName',
          dataType: 'string'
        }] : []),
      ...(displayTransactionColumn ? [{
        Header: 'Sale',
        accessor: 'saleName',
        dataType: 'string'
      }] : []),
      {
        id: 'weight',
        Header: 'Weight',
        accessor: 'weight',
        Cell: ({ row }) => numberUtils.numFmt(row.values.weight, 2, { suffix: 'ct', thousandSeperator: true }),
        dataType: 'number',
        filterType: 'numberRange'
      },
      {
        Header: 'Country',
        id: 'country',
        accessor: row => countriesMap?.[row?.countryId] ?? '',
        dataType: 'string'
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: row => roughStatusesMap?.[row.status],
        dataType: 'string',
        filterType: 'checkbox',
        enums: Object.values(roughStatusesMap)
      },
      {
        Header: 'Location',
        accessor: 'location',
        dataType: 'string',
        filterType: 'checkbox'
      }
    ]
  }, [roughStones, orgsMap, allSales, displayOrgColumn, displayOrgColumn, displayTransactionColumn, roughStatusesMap, countriesMap])

  const rowActions = useMemo(() => (
    [
      {
        actionName: 'remove',
        callback: handleOnRemove,
        shouldConfirm: false,
        shouldDisplay: () => !!handleOnRemove && !disabled
      }
    ]
  ), [handleOnRemove, disabled])

  function handleOnRemove(row) {
    setLocalRoughStones(localRoughStones.filter(rough => rough?.id !== row?.id))
  }

  async function handleOnAddStones(stones) {
    const ids = String(stones)
    .split(/\r?\n/)
    // filters out duplicates from user input
    ?.filter((id, index, arr) => arr.indexOf(id) === index)
    // filters out rough ids that already exist in the shipment rough list
    ?.filter(id => id && !localRoughStones.some(rough => rough.id === id))

    getRoughStones(ids)
    .then(roughStones => {
      const _roughStones = roughStones.reduce((_roughs, _rough) => {
        if (_rough?.data?.data?.length) _roughs.valid.push(_rough.data.data[0])
        else _roughs.notFound.push(_rough)
        return _roughs
      }, { notFound: [], valid: [] })
      if (_roughStones.notFound.length) showErrorToast(`${_roughStones.notFound.length} rough stones not found.`)

      setLocalRoughStones(sanitizeLocalRoughStones(joinRoughStones(_roughStones.valid)))
    })
    .finally(() => {
      setIsAddStonesModalOpen(false)
    })
  }

  function addStones(roughIds) {
    onChange({ target: { name: 'roughIds', value: roughIds } })
  }

  function joinRoughStones(addedRoughStones) {
    return [...localRoughStones, ...addedRoughStones]
  }

  function getRoughStones(ids) {
    const queue = new PQueue({ concurrency: 4 })

    return queue.addAll(ids
      .filter(id => !localRoughStones.some(rough => rough.id === id))
      .map(id => {
        return () => roughStoneActions.getRoughStoneById(id, '[id, sellerStoneName, Transaction, Assortment, sellerId, weight, location, status, countryId]')
      }))
  }

  function handleOnClose() {
    if (onClose) onClose()
    if (!hasSaved) {
      setLocalRoughStones(initialLocalRoughStones)
    }
    setOpenRoughStonesModal(false)
  }

  return (
    <>
      {!readonly
        ? (
          <div className="select-stones-modal">
            <div className="select-stones-modal__title">
              <span className='label'>Rough Stones</span>
              <a
                onClick={() => setOpenRoughStonesModal(true)}
              >
                {isEditToggled ? 'Manage' : 'View'}
              </a>
            </div>
            <span>{localRoughStones?.length > roughIds?.length ? localRoughStones?.length : roughIds?.length || '0'}</span>
          </div>
        ) : null
      }
      <Modal
        title='Rough Stones'
        open={openRoughStonesModal}
        onClose={handleOnClose}
      >
        <Table
          title=''
          data={localRoughStones}
          columns={columns}
          isMultiSelect={false}
          rowActions={rowActions}
          topBarActions={readonly ? [] : topBarActions}
        />
      </Modal>
      <Modal
        title='Add Rough Stones'
        open={isAddStonesModalOpen}
        onClose={() => {
          setAddRoughStones('')
          setIsAddStonesModalOpen(false)
        }}
      >
        <div className='select-stones-modal__text-area-container'>
          <TextArea
            label='Rough Stones IDs'
            onChange={e => setAddRoughStones(e.target.value)}
            value={addRoughStones}
          />
          <div className="right">
            <Button
              size='sm'
              onClick={() => handleOnAddStones(addRoughStones)}
            >
              Add
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

SelectShipmentStonesModal.propTypes = {
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  setRoughIds: PropTypes.func,
  onSaveStones: PropTypes.func,
  isEditToggled: PropTypes.bool,
  roughStones: PropTypes.arrayOf(PropTypes.object),
  roughIds: PropTypes.arrayOf(PropTypes.string)
}

SelectShipmentStonesModal.defaultProps = {
  open: false,
  disabled: true,
  readonly: false,
  isEditToggled: true,
  roughStones: [],
  roughIds: []
}

export default SelectShipmentStonesModal
