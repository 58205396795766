import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Button } from '@atoms'
import { Modal } from '@templates'
import { RadioGroup } from '@molecules'
import { useAuthStore } from '@stores'
import { SELLER } from '@constants'
import Excel from 'exceljs/dist/es5/exceljs.browser'
import { fileUtils, arrayUtils, textUtils, objectUtils } from '@utils'
import { useToast } from '@hooks'
import { roughStoneActions } from '@actions'
import clone from 'just-clone'

function RoughTemplateDownloadModal({
  open,
  onClose,
  initialValues,
  roughColours,
  roughFluorescences,
  eyeMeasurementColours,
  eyeMeasurementTinges,
  roughTinges,
  roughTensions,
  roughTypes,
  inclusionTypes,
  countriesList,
  minesList,
  pipesList,
  batchesList,
  roughScanTypes,
  weightCategories,
  pricePoints,
  locations,
  inclusionReductions,
  minMeasurements,
  tensionRequiredAboveWeight,
  eyeColourAbovePrimarySourceWeight
}) {
  const [options, setOptions] = useState()
  const { hasAdmin, hasPermission, permissionsAdminCache } = useAuthStore(state => state)
  const { showErrorToast } = useToast()
  const [operation, setOperation] = useState(null)
  const roughIdColumn = { key: 'roughId', header: 'Clara ID', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }, { action: 'editQcRoughStone', admin: true }, { action: 'editQcRoughStone', admin: false }], width: 12 }
  const dataValidation = useMemo(() => ({
    color: arrayUtils.pickBy(roughColours, 'description'),
    eyeColor: arrayUtils.pickBy(eyeMeasurementColours, 'description'),
    fluorescence: [0, 200],
    weight: [0],
    eyeFluorescence: Array.from(new Set(arrayUtils.pickBy(roughFluorescences, 'description'))),
    tinge: arrayUtils.pickBy(roughTinges, 'description'),
    eyeTinge: arrayUtils.pickBy(eyeMeasurementTinges, 'description'),
    tension: arrayUtils.pickBy(roughTensions, 'description'),
    type: arrayUtils.pickBy(roughTypes, 'description'),
    inclusionType: arrayUtils.pickBy(inclusionTypes, 'description'),
    scanType: arrayUtils.pickBy(roughScanTypes, 'description'),
    inclusionReduction: arrayUtils.pickBy(inclusionReductions, 'description'),
    country: arrayUtils.pickBy(countriesList || [], 'name'),
    mine: arrayUtils.pickBy(minesList || [], 'name'),
    pipe: arrayUtils.pickBy(pipesList || [], 'name'),
    batch: arrayUtils.pickBy(batchesList || [], 'name'),
    weightCategory: weightCategories,
    pricePoint: pricePoints,
    pricePerCarat: [0],
    location: locations,
    qcStatus: ['Yes', 'No', 'N/A'],
    yellowUV: ['Yes'],
    minMeasurements,
    tensionRequiredAboveWeight,
    eyeColourAbovePrimarySourceWeight
  }), [
    roughColours,
    roughFluorescences,
    roughTinges,
    roughTensions,
    roughTypes,
    inclusionTypes,
    countriesList,
    minesList,
    pipesList,
    batchesList,
    roughScanTypes,
    weightCategories,
    pricePoints,
    minMeasurements,
    tensionRequiredAboveWeight,
    eyeColourAbovePrimarySourceWeight
  ])

  const operationOptions = useMemo(() => {
    if (!open || !permissionsAdminCache.size) return []
    const _actions = [
      { action: 'createRoughStones', operation: 'create', hidden: () => 'roughId' in initialValues },
      { action: 'editQcRoughStone', operation: 'edit' },
      { action: 'editRoughStone', operation: 'edit' }
    ]

    return _actions.reduce((operations, a) => {
      if (
        (hasAdmin(roughStoneActions[a.action]) || hasPermission(roughStoneActions[a.action]))
        && !operations.some(o => o.value === a.operation)
        && !a?.hidden?.()
      ) operations.push({ value: a.operation, label: textUtils.capitalize(a.operation), name: a.action })

      return operations
    }, [])
  }, [open, initialValues, permissionsAdminCache])

  useEffect(() => {
    if (!open || !operation || !permissionsAdminCache.size) return
    setOptions((options || getInitialOptions()).map(o => ({ ...o, selected: isOptionRequired(o) ? true : o.selected })))
  }, [open, operation, permissionsAdminCache])

  useEffect(() => {
    setOperation(operationOptions[0]?.value)
  }, [operationOptions])

  function getInitialOptions() {
    return SELLER.ROUGH_TEMPLATE_OPTIONS.reduce((_opts, _opt) => {
      if (_opt.permissions?.some(p => p.admin ? hasAdmin(roughStoneActions[p.action]) : hasPermission(roughStoneActions[p.action]))) _opts.push({ ..._opt, selected: _opt.key !== 'qcStatus' })
      return _opts
    }, [])
  }

  function handleOnChange(e) {
    const { name, checked } = e.target
    let _options = clone(options)
    if (name === 'addAll') {
      let selected = true
      if (options?.filter(o => o.key !== 'qcStatus').every(o => o.selected)) selected = false
      _options = [..._options?.map(o => ({ ...o, selected: o.key === 'qcStatus' ? false : isOptionRequired(o) ? true : selected }))]
    } else if (name === 'qcStatus') {
      _options = [..._options?.map(o => ({ ...o, selected: o.key === 'qcStatus' ? checked : checked ? false : o.selected }))]
    } else {
      _options = [..._options?.map(o => ({ ...o, selected: o.key === 'qcStatus' && checked ? false : o.key === name ? checked : o.selected }))]
    }

    setOptions(_options)
  }

  function generateTemplate(columns) {
    try {
      const workbook = new Excel.Workbook()
      workbook.creator = 'Clara'
      workbook.created = new Date()
      const sheet = workbook.addWorksheet('Sheet1')
      if (operation === 'edit') columns.unshift(roughIdColumn)
      sheet.columns = columns.map(column => ({ header: column.header, key: column.key, width: column.width, dataValidation: column.dataValidation }))
      const headerRows = 5
      sheet.spliceRows(0, 0, ...new Array(headerRows - 1).map(() => []))
      sheet.getCell('A1').value = `Use this template to ${operation} rough stones.`
      sheet.getCell('A2').value = 'Instructions:'
      sheet.getCell('A3').value = `- ${operation === 'create' ? 'Add new rough stones by entering in stone data immediately beneath the column header row below' : 'Enter in the clara id\'s of existing rough stones you want to edit'}`
      sheet.views = [{ state: 'frozen', ySplit: headerRows }]

      for (const column of columns) {
        // excel has a limit of 255 characters for validation strings
        const hasSupportedValidation = column.dataValidation && String(dataValidation[column.dataValidation.key])?.length < 255
        if (hasSupportedValidation) {
          const sheetCol = sheet.getColumn(column.key)
          if (column.dataValidation.type === 'whole' || column.dataValidation.type === 'decimal') {
            sheet.dataValidations.model[`${sheetCol.letter}6:${sheetCol.letter}9999`] = {
              allowBlank: column.dataValidation.allowBlank ?? true,
              prompt: column.dataValidation.prompt,
              promptTitle: column.dataValidation.promptTitle,
              showInputMessage: column.dataValidation.showInputMessage,
              formulae: dataValidation[column.dataValidation.key],
              showErrorMessage: column.dataValidation.showErrorMessage ?? true,
              operator: column.dataValidation.operator,
              type: column.dataValidation.type
            }
          } else {
            sheet.dataValidations.model[`${sheetCol.letter}6:${sheetCol.letter}9999`] = {
              allowBlank: column.dataValidation.allowBlank ?? true,
              error: 'Please use the drop down to select a valid value.',
              errorTitle: 'Invalid Selection',
              showErrorMessage: column.dataValidation.showErrorMessage ?? true,
              type: column.dataValidation.type || 'list'
            }
            // there is a limit on the number of options a validation dropdown can have. if goes over the limit it crashes the file on open
            if (dataValidation[column.dataValidation.key]?.length <= 80) sheet.dataValidations.model[`${sheetCol.letter}6:${sheetCol.letter}9999`].formulae = [`"${dataValidation[column.dataValidation.key]}"`]
          }
        }
      }

      if (!objectUtils.isEmpty(initialValues)) {
        const initialRows = Object.entries(initialValues).reduce((rows, [col, vals]) => Array.from({ length: Math.max(vals.length, rows.length) }).map((_, idx) => ({
          [col]: vals[idx],
          ...rows[idx]
        })), [])
        sheet.addRows(initialRows)
      }
      return workbook.xlsx.writeBuffer().then(buffer => fileUtils.saveBufferExcel(buffer, 'multi_rough_stone_upload_v2.xlsx'))
    } catch (err) {
      console.error(err)
      showErrorToast(err?.message || 'There was an error writing the excel.')
    }
  }

  function isOptionRequired(option) {
    const requiredFields = [
      { key: 'sellerStoneName', operations: ['create'] }
    ]

    return requiredFields.some(field => field.key === option.key && field.operations.includes(operation))
  }

  function handleOnGenerateTemplate() {
    generateTemplate(options?.filter(o => o.selected))
    onClose()
  }

  return (
    <Modal
      title='Download Template'
      open={open}
      onClose={onClose}
    >
      <div className="rough-template-download__container">
        <span className='rough-template-download__description-text'>
          Select the columns to be included in the template.
        </span>
        <div className="rough-template-download__header">
          <div className="rough-template-download__option">
            <Checkbox
              label={'Add all'}
              name={'addAll'}
              id={'addAll'}
              value={options?.every(o => o.selected)}
              onChange={handleOnChange}
            />
          </div>
          <div className="rough-template-download__option">
            <RadioGroup
              label='Operation'
              disabled={operationOptions?.length === 1}
              options={operationOptions}
              name='operation'
              value={operation}
              onChange={(e) => setOperation(e.target.value)}
            />
          </div>
        </div>
        <div className="rough-template-download__list">
          {
            options?.map((option, index) => {
              const optionRequired = isOptionRequired(option)
              return (
                <React.Fragment
                  key={index}
                >
                  <div className="rough-template-download__option">
                    <Checkbox
                      disabled={optionRequired}
                      label={option.header}
                      name={option.key}
                      id={option.key}
                      value={option.selected}
                      onChange={handleOnChange}
                    />
                  </div>
                </React.Fragment>
              )
            })
          }
        </div>
        <div className="rough-template-download__buttons">
          <Button
            typeVariant='action'
            size='sm'
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size='sm'
            onClick={handleOnGenerateTemplate}
            disabled={options?.every(o => !o.selected)}
          >
            Download Template
          </Button>
        </div>
      </div>
    </Modal>
  )
}

RoughTemplateDownloadModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  initialValues: PropTypes.object,
  roughColours: PropTypes.arrayOf(PropTypes.object),
  roughFluorescences: PropTypes.arrayOf(PropTypes.object),
  roughTinges: PropTypes.arrayOf(PropTypes.object),
  eyeMeasurementTinges: PropTypes.arrayOf(PropTypes.object),
  eyeMeasurementColours: PropTypes.arrayOf(PropTypes.object),
  roughTensions: PropTypes.arrayOf(PropTypes.object),
  roughTypes: PropTypes.arrayOf(PropTypes.object),
  inclusionTypes: PropTypes.arrayOf(PropTypes.object),
  countriesList: PropTypes.arrayOf(PropTypes.object),
  minesList: PropTypes.arrayOf(PropTypes.object),
  pipesList: PropTypes.arrayOf(PropTypes.object),
  batchesList: PropTypes.arrayOf(PropTypes.object),
  roughScanTypes: PropTypes.arrayOf(PropTypes.object),
  inclusionReductions: PropTypes.arrayOf(PropTypes.object),
  weightCategories: PropTypes.arrayOf(PropTypes.string),
  pricePoints: PropTypes.arrayOf(PropTypes.string),
  locations: PropTypes.arrayOf(PropTypes.string),
  minMeasurements: PropTypes.number,
  tensionRequiredAboveWeight: PropTypes.number,
  eyeColourAbovePrimarySourceWeight: PropTypes.number
}

RoughTemplateDownloadModal.defaultProps = {
  open: false,
  initialValues: {},
  roughColours: [],
  roughFluorescences: [],
  roughTinges: [],
  eyeMeasurementTinges: [],
  eyeMeasurementColours: [],
  roughTensions: [],
  roughTypes: [],
  inclusionTypes: [],
  countriesList: [],
  minesList: [],
  pipesList: [],
  batchesList: [],
  roughScanTypes: [],
  inclusionReductions: [],
  weightCategories: [],
  pricePoints: [],
  locations: []
}

export default RoughTemplateDownloadModal
