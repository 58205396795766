import React, { useEffect, useMemo, useState } from 'react'
import clone from 'just-clone'
import deepEqual from 'fast-deep-equal'
import { useHistory } from 'react-router-dom'
import { roughStoneActions } from '@actions'
import { arrayUtils, objectUtils } from '@utils'
import { useToast, useProgressList } from '@hooks'
import { roughSchema } from '@schemas'
import { Table } from '@organisms'
import { useRoughStoneStore, usePlanningStore } from '@stores'
import SaveMultipleRoughs from './saveMultipleRoughs'

function useEditMultipleRoughs({ name, roughList, fileId, columns, columnKeys, validationSchema, editFunc }) {
  const { showSuccessToast, showErrorToast, showWarningToast } = useToast()
  const [validationText, setValidationText] = useState()
  const [disableSubmit, setDisableSubmit] = useState(true)
  const history = useHistory()
  const schemaContext = useMemo(() => ({ cache: new Map() }), [])
  const {
    createProgressList,
    getSucceededTasks,
    getProgressList
  } = useProgressList()

  const [progressList, setProgressList] = useState()
  useEffect(() => {
    setProgressList(createProgressList({
      name,
      title: 'Uploading Rough Stones',
      autoStart: false,
      concurrency: 5,
      onAllTasksComplete: async (list) => {
        const successIds = getSucceededTasks(name).filter(taskId => !(list.tasks[taskId]?.noChange))
        if (successIds.length) {
          // If there was a success
          // Remove from the store
          await removeAllRoughStonesListItems(successIds)
          await removeAllPlannedStonesListItems(successIds, (plannedStone) => plannedStone?.Rough?.id)
        }
        if (!list.count().failed && history?.location?.pathname?.includes('/roughstones/upload')) {
          showSuccessToast(`${successIds.length} rough stone${successIds.length > 1 ? 's were' : ' was'} successfully updated.`)
          history.goBack()
        }
      }
    }))
  }, [])

  useEffect(() => {
    // Reset disable submit any time we change the file
    // If we are ever able to directly edit the preview table,
    // this will have to change
    setDisableSubmit(false)
  }, [fileId])

  const {
    removeAllRoughStonesListItems
  } = useRoughStoneStore(store => store)
  const { removeAllPlannedStonesListItems } = usePlanningStore(store => store)

  const completeRoughList = useMemo(() => {
    if (!roughList?.length) return []
    return roughList.reduce((rL, { existing, ...r }) => {
      if (!existing) return rL
      // Try and replace empty input with existing database data
      // Since the parser in SaveMultipleRoughs will not include some
      // fields if their value is null or invalid, we need to ensure
      // we are also considering the parsed keys that are being set from input
      // keys
      const res = Object.entries(r).reduce((nK, [k, v]) => {
        const parsedName = SaveMultipleRoughs.parseNamedKey[k]
        if (!(k in nK)) nK[k] = v
        else if (v !== '' && v != null) {
          nK[k] = v
          if (parsedName) nK[parsedName] = r[parsedName] ?? null
        }
        return nK
      }, clone(existing))
      const completeRough = { ...res, existing, updates: r }
      Object.entries(r).forEach(([attrK, attrV]) => {
        if (objectUtils.isObject(attrV)) completeRough[attrK] = objectUtils.deepMerge({ ...existing[attrK] }, attrV)
        else if (Array.isArray(attrV)) {
          existing[attrK].reduce((accum, curr, idx) => {
            accum[idx] = { ...curr, ...attrV[idx] }
            return accum
          }, attrV)
        }
      })
      return rL.concat(completeRough)
    }, [])
  }, [roughList])

  const tableColumns = useMemo(() => {
    if (!columns) return []
    return columns(columnKeys)
  }, [columns])

  const { hasErrors, hasWarnings } = useMemo(() => {
    const res = { hasErrors: false, hasWarnings: false }
    if (validationText?.[fileId]) {
      // validationErrors is a Map so we will have to loop through the entries
      for (const valErrors of validationText?.[fileId]?.values()) {
        if (valErrors.getError()) {
          res.hasErrors = true
          break
        } else if (valErrors.getWarning()) {
          res.hasWarnings = true
        }
      }
    }
    return res
  }, [validationText?.[fileId]])

  useEffect(() => {
    if (hasErrors) {
      showErrorToast('Please clear the highlighted errors before saving.')
    } else if (hasWarnings) {
      showWarningToast('Please clear the highlighted warnings or proceed to saving. Please note that the values of some fields with warning may be removed before saving.')
    }
  }, [hasErrors, hasWarnings])

  function handleSubmit(roughList) {
    setDisableSubmit(true)
    if (!roughList.length) {
      showErrorToast('No rough stones were submitted')
      return
    }
    // TODO here, separate out the eyeRequired stuff, which can be added to the rough object for display in the grid
    roughList.forEach(({ updates: { id, sellerId, countryName, mineName, pipeName, batchName, inclusionsTypeName, inclusionReductionsName, scanTypeName, tensionName, qcStatusName, locationName, status, typeName, ...rough }, existing }) => {
      const task = progressList.newTask()
      task.id = id
      task.name = `${id}${(rough?.sellerStoneName || existing?.sellerStoneName) ? ` (${rough?.sellerStoneName || existing?.sellerStoneName})` : ''}`
      task.noChange = true
      task.callback = async () => {
        const roughUpdates = Object.entries(rough).reduce((updated, [k, v]) => {
          if (typeof v === 'object') {
            if (!deepEqual(existing[k], v)) updated[k] = objectUtils.deepMerge({ ...existing[k] }, v)
          } else if (!isNaN(v) && !isNaN(existing[k])) {
            if (Number(v) !== Number(existing[k])) updated[k] = v
          } else if (v !== existing[k]) updated[k] = v
          return updated
        }, {})
        if (!objectUtils.isEmpty(roughUpdates)) {
          // Edit rough stone
          const list = getProgressList(name)
          const currTask = list.tasks[id]
          currTask.noChange = false
          await editFunc(id, objectUtils.filterNullish(roughUpdates, true), { loadingSpinner: false })
        }
      }
      progressList.addTask(task)
    })

    progressList.start()
  }

  const topBarActions = [{
    label: 'Save Changes',
    callback: () => handleSubmit(completeRoughList),
    disabled: !completeRoughList?.length
    || hasErrors
    || disableSubmit
  }]

  return <Table
    title= 'Upload Rough Stones Preview'
    columns= {tableColumns}
    data={completeRoughList}
    topBarActions={topBarActions}
    isMultiSelect={false}
    initialPageSize={25}
    initialSort={[]}
    validationProps={{
      validationSchema,
      schemaContext,
      onValidationChange: ({ validationText: valText }) => setValidationText(currValText => ({ ...currValText, [fileId]: valText }))
    }}
  />
}

function EditMultipleRoughs(props) {
  const columnKeys = ['id', 'sellerStoneName', 'assortmentName', 'statusName', 'weight', 'reservePpcOriginal', 'reservePpcOverride', 'measurements', 'eyeMeasurement', 'overrideMeasurement', 'countryName', 'mineName', 'pipeName', 'batchName', 'scanTypeName', 'weightCategory', 'pricePoint', 'inclusionsTypeName', 'inclusionReductionsName', 'tensionName', 'otherAttributes.yellowFluorescence', 'locationName', 'qcStatusName', 'typeName']
  const validationSchema = useMemo(() => {
    if (!props.constants) return
    const { assortment } = props
    const {
      mines,
      pricePoints,
      weightCategories,
      eyeMeasurementColours,
      eyeMeasurementFluorescences,
      eyeMeasurementTinges,
      inclusionTypes,
      inclusionReductions,
      roughScanTypes,
      roughTensions,
      roughTypes,
      roughColours,
      roughFluorescences,
      polishedFluorescences,
      tinges,
      locations,
      minMeasurements,
      tensionRequiredAboveWeight,
      eyeColourAbovePrimarySourceWeight
    } = props.constants
    return {
      warning: roughSchema.editMultipleRoughsWarningSchema({
        provenanceType: assortment?.ProvenanceType,
        minesList: arrayUtils.pickBy(mines, 'id'),
        pricePoints,
        weightCategories,
        eyeMeasurementColours: arrayUtils.pickBy(eyeMeasurementColours ?? [], 'value'),
        eyeMeasurementFluorescences: arrayUtils.pickBy(eyeMeasurementFluorescences ?? [], 'value'),
        eyeMeasurementTinges: arrayUtils.pickBy(eyeMeasurementTinges ?? [], 'value'),
        inclusionTypes: arrayUtils.pickBy(inclusionTypes ?? [], 'id'),
        inclusionReductions: arrayUtils.pickBy(inclusionReductions ?? [], 'id'),
        roughScanTypes: arrayUtils.pickBy(roughScanTypes ?? [], 'value'),
        roughTensions: arrayUtils.pickBy(roughTensions ?? [], 'value'),
        roughTypes: arrayUtils.pickBy(roughTypes ?? [], 'value'),
        minMeasurements,
        tensionRequiredAboveWeight,
        eyeColourAbovePrimarySourceWeight,
        // For calculateRoughMeasurements
        roughColours: roughColours ?? [],
        roughFluorescences: roughFluorescences ?? [],
        polishedFluorescences: polishedFluorescences ?? [],
        roughTinges: tinges ?? [],
        locations: locations ?? []
      }),
      error: roughSchema.editMultipleRoughsErrorSchema({
        roughColours: roughColours ?? [],
        roughFluorescences: roughFluorescences ?? [],
        roughTinges: tinges ?? []
      })
    }
  }, [props.constants])

  return useEditMultipleRoughs({ name: 'editMultipleRoughs', ...props, columnKeys, validationSchema, editFunc: roughStoneActions.editRoughStone })
}

function QCEditMultipleRoughs(props) {
  const columnKeys = ['id', 'sellerStoneName', 'assortmentName', 'weight', 'colourName', 'fluorescenceName', 'tingeName', 'inclusionsTypeName', 'inclusionReductionsName', 'qcStatusName']
  const validationSchema = useMemo(() => {
    if (!props.constants) return
    const {
      roughQCStatuses,
      inclusionTypes,
      inclusionReductions
    } = props.constants
    return {
      warning: roughSchema.qcEditMultipleRoughsWarningSchema({
        roughQCStatuses: arrayUtils.pickBy(roughQCStatuses ?? [], 'value'),
        inclusionTypes: arrayUtils.pickBy(inclusionTypes ?? [], 'id'),
        inclusionReductions: arrayUtils.pickBy(inclusionReductions ?? [], 'id')
      }),
      error: roughSchema.qcEditMultipleRoughsErrorSchema({
        roughQCStatuses: arrayUtils.pickBy(roughQCStatuses ?? [], 'value'),
        inclusionTypes: arrayUtils.pickBy(inclusionTypes ?? [], 'id'),
        inclusionReductions: arrayUtils.pickBy(inclusionReductions ?? [], 'id')
      })
    }
  }, [props.constants])
  return useEditMultipleRoughs({ name: 'qcEditMultipleRoughs', ...props, columnKeys, validationSchema, editFunc: roughStoneActions.editQcRoughStone })
}

export { EditMultipleRoughs, QCEditMultipleRoughs }
