import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormComponents } from '@organisms'
import { useGlobalsStore } from '@stores'

const { SimpleForm, Dropdown, Button } = FormComponents
function EditStatus({ selectedRows, handleSubmit, handleCancel }) {
  const { shipmentStatuses, getShipmentStatuses } = useGlobalsStore()
  useEffect(() => {
    getShipmentStatuses()
  }, [])

  return (
    <SimpleForm onSubmit={handleSubmit}>
      <div>Are you sure you want to update {selectedRows.length} shipment{selectedRows.length > 1 ? 's' : ''}?</div>
      <Dropdown
        name='status'
        label='Status'
        required={true}
        options={shipmentStatuses?.map(c => ({ value: c.value, label: c.description }))}
        clearable={false}
      />
      <div key='row-buttons' className='modal__buttons'>
        <Button typeVariant='secondary' size='sm' onClick={handleCancel}>Cancel</Button>
        <Button type='submit' typeVariant='primary' size='sm'>Update</Button>
      </div>
    </SimpleForm>
  )
}
EditStatus.propTypes = {
  selectedRows: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired
}
EditStatus.defaultProps = {
  selectedRows: []
}

export default EditStatus
