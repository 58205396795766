import React from 'react'
import NotificationForm from './notificationForm'
// TODO: Find a better name for this prop
function CreateNotificationTemplate() {
  return (
    <NotificationForm />
  )
}

export default CreateNotificationTemplate
