import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { cutGradeActions } from '@actions'
import moment from 'moment'
import { usePageTitle, useToast } from '@hooks'
import { useHistory } from 'react-router-dom'
import { useGlobalsStore, useAuthStore } from '@stores'
import { FileUpload } from '@organisms'
import { fileUtils, textUtils } from '@utils'
import BuyerSettingsDetailsPage from './buyerSettingsDetailsPage'

function ShapeDetails({ match, title }) {
  const { shapeId, operation } = match?.params
  const { hasAdmin, permissionsAdminCache } = useAuthStore(state => state)
  const { showSuccessToast } = useToast()
  const history = useHistory()

  const [shape, setShape] = useState({})
  usePageTitle(title, shapeId, shape?.name)
  useEffect(() => {
    if (shapeId) {
      cutGradeActions.getShapeList({ id: shapeId, columns: '[ShapeFile,Orders]' })
      .then(response => {
        const shape = response.data.data[0]
        setShape({
          ...shape,
          customFile: shape.ShapeFile
        })
      })
      .catch(err => console.error(err))
    } else setShape({})
  }, [shapeId])
  const canEdit = shape?.shapeType === 'CUSTOM' && shape?.condition === 'ACTIVE'

  const {
    orgsList: { all: orgsList }, getOrgsList,
    shapeTypesMap, getShapeTypes
  } = useGlobalsStore()
  useEffect(() => {
    getOrgsList()
    getShapeTypes()
  }, [])

  const [fields, setFields] = useState([])
  useEffect(() => {
    if (shape) {
      setFields([
        {
          label: 'Name',
          value: shape.name,
          name: 'name',
          componentName: 'textInput',
          canEdit
        },
        {
          label: 'Owner',
          value: shape.buyerId === null ? '-' : shape.buyerId,
          name: 'buyerId',
          componentName: 'dropdown',
          options: orgsList?.map(org => ({ value: org.orgId, label: org.commonName })).concat({ value: '-', label: 'Preloaded' }),
          canEdit: false
        },
        {
          label: 'Type',
          value: shapeTypesMap?.[shape.shapeType] ?? '',
          name: 'shapeType',
          componentName: 'textInput',
          canEdit: false
        },
        {
          legend: 'ASC File',
          componentName: 'fieldset',
          canEdit: true,
          span: true,
          name: 'asc',
          children: [
            {
              label: '',
              name: 'noAdvFilePlaceHolder',
              customComponent: () => <span>No ASC file uploaded.</span>,
              canEdit: false,
              renderOverrides: (values) => ({
                shouldDisplay: !values.find(({ name }) => name === 'customFileId').value && !shape.ShapeFile
              })
            },
            {
              label: 'Original Name',
              name: 'ascOriginalName',
              componentName: 'textInput',
              canEdit: false,
              renderOverrides: (values) => {
                const customFileId = values.find(({ name }) => name === 'customFileId')
                return {
                  value: customFileId?.value?.fileName || customFileId?.value?.originalName,
                  shouldDisplay: !!(customFileId?.value?.fileName || customFileId?.value?.originalName)
                }
              }
            },
            {
              label: 'Last Updated',
              name: 'ascLastUpdated',
              componentName: 'textInput',
              canEdit: false,
              renderOverrides: (values) => {
                const customFileId = values.find(({ name }) => name === 'customFileId')
                return {
                  value: customFileId?.value?.lastUpdated || textUtils.formatDate(customFileId?.value?.updatedAt, true),
                  shouldDisplay: !!(customFileId?.value?.lastUpdated || customFileId?.value?.updatedAt)
                }
              }
            },
            {
              label: 'Download',
              text: fileUtils.getFileName(shape.ShapeFile, { shorten: true }),
              componentName: 'downloadLink',
              name: 'fileUrl',
              url: shape.ShapeFile?.url || shape.ShapeFile?.downloadName,
              shouldDisplay: shape.ShapeFile,
              canEdit: false
            },
            {
              label: 'File',
              value: shape.customFile,
              name: 'customFileId',
              shouldDisplay: 'editOnly',
              customComponent: ({ value, onChange, disabled, expectedFileName = undefined, ...props }) => {
                return <FileUpload
                  name='file'
                  label='File'
                  acceptTypes={['.asc']}
                  expectedFileName={expectedFileName}
                  fileName={value?.name || value?.originalName || value?.downloadName || value?.key || undefined}
                  onChange={async (file) => {
                    if (!file) return onChange({ currentTarget: { name: 'customFileId', value: file } })
                    onChange({
                      currentTarget: {
                        name: 'customFileId',
                        value: {
                          name: file.name,
                          file: await fileUtils.promiseReadBase64File(file)
                        }
                      }
                    })
                  }}
                  disabled={disabled}
                />
              },
              renderOverride: values => ({
                expectedFileName: `${values.name}.asc`
              }),
              canEdit
            }
          ]
        },
        {
          label: 'Created At',
          shouldDisplay: hasAdmin(cutGradeActions.getShapeList) || shape.buyerId !== null,
          value: moment(shape.createdAt).toLocaleString()
        },
        {
          label: 'Last Updated',
          shouldDisplay: hasAdmin(cutGradeActions.getShapeList) || shape.buyerId !== null,
          value: moment(shape.updatedAt).toLocaleString()
        }
      ])
    }
  }, [shape, orgsList, shapeTypesMap, permissionsAdminCache])

  async function handleOnSubmit(editedValues) {
    const { condition, customFileId, ...shapeData } = editedValues

    if ('customFileId' in editedValues) {
      if (customFileId) {
        shapeData.file = customFileId.file
        shapeData.origFileName = customFileId.name
      } else {
        shapeData.file = null
      }
    }

    const conditionPromise = condition ? cutGradeActions.setShapeCondition(shapeId, condition) : Promise.resolve()
    conditionPromise.then(() => {
      if (condition) showSuccessToast('Condition updated')
      if (Object.keys(shapeData).length) {
        return cutGradeActions.editShape({ id: shapeId, ...shapeData })
      }
    })
    .then(() => {
      showSuccessToast('Shape updated.')
      history.push('/buyer-settings')
    })
  }

  function handleArchive() {
    cutGradeActions.setShapeCondition(shape?.id, 'ARCHIVED')
    .then(() => {
      showSuccessToast('Shape has been archived.')
      history.push('/buyer-settings')
    })
  }

  function handleUnarchive() {
    cutGradeActions.setShapeCondition(shape?.id, 'ACTIVE')
    .then(() => {
      showSuccessToast('Shape has been unarchived.')
      history.push('/buyer-settings')
    })
  }

  function handleRemove() {
    cutGradeActions.setShapeCondition(shape?.id, 'DELETED')
    .then(() => {
      showSuccessToast('Shape has been removed.')
      history.push('/buyer-settings')
    })
  }

  const canArchive = shape?.condition !== 'ARCHIVED' && shape?.condition !== 'DELETED'
  const canUnarchive = shape?.condition === 'ARCHIVED'
  const canRemove = shape?.condition !== 'DELETED'

  return (
    <BuyerSettingsDetailsPage
      canEdit={canEdit}
      isEdit={operation === 'edit'}
      fields={fields}
      onArchive={canArchive ? handleArchive : null}
      onUnarchive={canUnarchive ? handleUnarchive : null}
      onRemove={canRemove ? handleRemove : null}
      onSubmit={handleOnSubmit}
      title={{
        label: 'Shape ID',
        value: shape?.id || ''
      }}
      value={shape}
    />
  )
}

ShapeDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string
}

export default ShapeDetails
