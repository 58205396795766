import create from 'zustand'

const useMultiStepsStore = create((set, get) => ({
  currentStepNumber: 1,
  setCurrentStep: (stepNumber) => {
    set({ currentStepNumber: stepNumber })
  },
  validationError: {
    error: false,
    errorMessage: '',
    blankFields: []
  },
  setValidationError: (validationError) => {
    if (validationError) {
      set({ validationError })
    }
  },
  validationSchemaError: undefined,
  setValidationSchemaError: (validationSchemaError) => {
    if (validationSchemaError) {
      set({ validationSchemaError })
    }
  },
  removeValidationSchemaError: (key) => {
    const validationSchemaError = get().validationSchemaError
    if (validationSchemaError) {
      const newValidationSchemaError = validationSchemaError.removeStep(key)
      set({ validationSchemaError: newValidationSchemaError })
    }
  },
  onSubmit: null,
  setOnSubmit: (onSubmit) => {
    set({ onSubmit })
  },
  formLocalState: {},
  setFormLocalState: (props) => {
    set({ formLocalState: { ...get().formLocalState, ...props } })
  },
  resetFormLocalState: () => {
    set({ formLocalState: {} })
  },
  hasChanged: false,
  setHasChanged: (hasChanged) => {
    set({ hasChanged })
  },
  confirmPageExit: false,
  setConfirmPageExit: (confirmPageExit) => {
    set({ confirmPageExit })
  },
  resetValidation: () => {
    set({
      validationError: {
        error: false,
        errorMessage: '',
        blankFields: []
      },
      validationSchemaError: undefined
    })
  }
}))

export {
  useMultiStepsStore
}
