import React, { useMemo } from 'react'
import { useModalStore } from '@stores'
import { Modal } from '@templates'
import BaselineSheet from '../buyerSettings/baselineSheet'
import CostDetails from '../buyerSettings/details/costDetails'
import CutGradeDetails from '../buyerSettings/details/cutGradeDetails'
import TingeMapDetails from '../buyerSettings/details/tingeMapDetails'

function OrderFieldModal() {
  const {
    activeOrderFieldModal,
    buyerSettingsModalOptions,
    activeOrderFieldModalField,
    resetActiveOrderFieldModal
  } = useModalStore()

  const orderFieldModalRenderer = (activeModal) => {
    const orderFieldModalProps = {
      content: null,
      open: true,
      title: activeOrderFieldModalField?.label
    }

    switch (activeModal) {
      case buyerSettingsModalOptions.CUT_GRADE:
        orderFieldModalProps.content = <CutGradeDetails match={{ params: { cutGradeId: activeOrderFieldModalField?.id } }} canEdit={false} />
        break
      case buyerSettingsModalOptions.BASELINE:
        orderFieldModalProps.content = <BaselineSheet baselineId={activeOrderFieldModalField?.id} />
        break
      case buyerSettingsModalOptions.MFG_COST:
        orderFieldModalProps.content = <CostDetails match={{ params: { costId: activeOrderFieldModalField?.id } }} costType='mfg' canEdit={false}/>
        break
      case buyerSettingsModalOptions.CERT_COST:
        orderFieldModalProps.content = <CostDetails match={{ params: { costId: activeOrderFieldModalField?.id } }} costType='cert' canEdit={false}/>
        break
      case buyerSettingsModalOptions.TINGE:
        orderFieldModalProps.content = <TingeMapDetails match={{ params: { tingeMapId: activeOrderFieldModalField?.id } }} canEdit={false}/>
        break
      default:
        orderFieldModalProps.open = false
    }
    return <Modal title={orderFieldModalProps.title} open={orderFieldModalProps.open} onClose={resetActiveOrderFieldModal} className='order-field-modal x-scroll sticky'>{orderFieldModalProps.content}</Modal>
  }

  return useMemo(() => orderFieldModalRenderer(activeOrderFieldModal), [activeOrderFieldModal])
}

OrderFieldModal.defaultProps = {
}

export default OrderFieldModal
