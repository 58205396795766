import React, { useMemo, useState } from 'react'
import { roughStoneActions } from '@actions'
import { errorUtils } from '@utils'
import { TextInput, Dropdown, InfoTip } from '@molecules'
import { ConfirmationModal } from '@templates'
function PricingAdjustmentModal({ openModal, setOpenModal, updateSucceeded, priceableStatuses }) {
  const [priceAdjustmentKey, setPriceAdjustmentKey] = useState('default')
  const [genericPremium, setGenericPremium] = useState(0)
  const [priceAdjustment, setPriceAdjustment] = useState(0)

  const pricingAdjustmentProps = {
    default: {
      message: '',
      onSubmit: null,
      onClose: () => setOpenModal({ name: false, selectedRows: [] }),
      content: null
    },
    smooth: {
      title: <>Smooth Prices <InfoTip name='roughListTipsSmooth' /></>,
      message: `Smooth the price${openModal.selectedRows.length > 1 ? 's' : ''} for ${openModal.selectedRows.length} stone${openModal.selectedRows.length > 1 ? 's' : ''}?`,
      onSubmit: async () => {
        return roughStoneActions.updateMultipleRoughs(
          {
            roughIds: openModal.selectedRows.map(row => row.id),
            reservePpcOverride: { smoothingMethod: 'SELECTED' }
          },
          { errorSummary: errorUtils.getErrorSummary({ field: 'stones' }) }
        )
          .then(updateSucceeded('Prices updated', openModal.selectedRows.map(row => row.id)))
      },
      onClose: () => setOpenModal({ name: false, selectedRows: [] }),
      isDisabled: () => openModal.selectedRows.some(row => row.genericPrice === null || !priceableStatuses.includes(row.status)),
      content: null
    },
    smooth2: {
      title: <>Smooth with Assortments <InfoTip name='roughListTipsSmoothA' /></>,
      message: `Smooth the price${openModal.selectedRows.length > 1 ? 's' : ''} for ${openModal.selectedRows.length} stone${openModal.selectedRows.length > 1 ? 's' : ''} within their assortments?`,
      onSubmit: async () => {
        return roughStoneActions.updateMultipleRoughs(
          {
            roughIds: openModal.selectedRows.map(row => row.id),
            reservePpcOverride: { smoothingMethod: 'ASSORTMENT' }
          },
          { errorSummary: errorUtils.getErrorSummary({ field: 'stones' }) }
        )
          .then(updateSucceeded('Prices updated', openModal.selectedRows.map(row => row.id)))
      },
      onClose: () => setOpenModal({ name: false, selectedRows: [] }),
      isDisabled: () => openModal.selectedRows.some(row => row.genericPrice === null || !priceableStatuses.includes(row.status)),
      content: null
    },
    generic: {
      title: <>Set to Estimated Value <InfoTip name="roughListTipsGeneric"/></>,
      message: `Set the price${openModal.selectedRows.length > 1 ? 's' : ''} for ${openModal.selectedRows.length} stone${openModal.selectedRows.length > 1 ? 's' : ''} to estimated value?`,
      onSubmit: async () => {
        return roughStoneActions.updateMultipleRoughs(
          {
            roughIds: openModal.selectedRows.map(row => row.id),
            reservePpcOverride: { percentAboveGeneric: genericPremium }
          },
          { errorSummary: errorUtils.getErrorSummary({ field: 'stones' }) }
        )
          .then(updateSucceeded('Prices updated', openModal.selectedRows.map(row => row.id)))
      },
      onClose: () => {
        setOpenModal({ name: false, selectedRows: [] })
        setGenericPremium(0)
      },
      isDisabled: () => openModal.selectedRows.some(row => row.genericPrice === null || !priceableStatuses.includes(row.status)),
      content: (<TextInput
        type='number'
        label='With % Premium'
        name='genericPremium'
        onChange={e => setGenericPremium(e?.currentTarget?.value)}
        value={genericPremium}
      />)
    },
    adjust: {
      title: <>Adjust Prices <InfoTip name="roughListTipsAdjust"></InfoTip></>,
      message: `Adjust the price${openModal.selectedRows.length > 1 ? 's' : ''} for ${openModal.selectedRows.length} stone${openModal.selectedRows.length > 1 ? 's' : ''}?`,
      onSubmit: async () => {
        return roughStoneActions.updateMultipleRoughs(
          {
            roughIds: openModal.selectedRows.map(row => row.id),
            reservePpcOverride: { adjust: priceAdjustment }
          },
          { errorSummary: errorUtils.getErrorSummary({ field: 'stones' }) }
        )
          .then(updateSucceeded('Prices updated', openModal.selectedRows.map(row => row.id)))
      },
      onClose: () => {
        setOpenModal({ name: false, selectedRows: [] })
        setPriceAdjustment(0)
      },
      isDisabled: () => openModal.selectedRows.some(row => !priceableStatuses.includes(row.status)),
      content: (<TextInput
        type='number'
        label='Adjust by %'
        name='priceAdjustment'
        onChange={e => setPriceAdjustment(e?.currentTarget?.value)}
        value={priceAdjustment}
      />)
    },
    reset: {
      title: <>Remove Price Overrides <InfoTip name="roughListTipsUnset"></InfoTip></>,
      message: `Reset the price${openModal.selectedRows.length > 1 ? 's' : ''} for ${openModal.selectedRows.length} stone${openModal.selectedRows.length > 1 ? 's' : ''} to original value${openModal.selectedRows.length > 1 ? 's' : ''}?`,
      onSubmit: async () => {
        const roughIds = openModal.selectedRows.filter(row => row.reservePpcOverride != null).map(row => row.id)
        if (!roughIds.length) throw new errorUtils.ModalError(`No change required for selected stone${openModal.selectedRows.length > 1 ? 's' : ''}.`, 'error')
        return roughStoneActions.updateMultipleRoughs(
          {
            roughIds,
            reservePpcOverride: { reset: true }
          },
          { errorSummary: errorUtils.getErrorSummary({ field: 'stones' }) }
        )
          .then(updateSucceeded('Prices reset', roughIds))
      },
      onClose: () => setOpenModal({ name: false, selectedRows: [] }),
      isDisabled: () => openModal.selectedRows.some(row => !priceableStatuses.includes(row.status)),
      content: null
    }
  }

  const pricingAdjustDropdown = useMemo(() => ({
    value: priceAdjustmentKey === 'default' ? null : priceAdjustmentKey,
    options: Object.entries(pricingAdjustmentProps).filter(([type]) => openModal?.acceptedKeys?.includes(type))?.map(([type, val]) => ({
      label: val.title,
      value: type,
      isDisabled: val.isDisabled()
    }))
  }), [priceAdjustmentKey, openModal.selectedRows])

  return (
    <ConfirmationModal
      open={openModal.name === 'pricingAdjustment'}
      title='Pricing Adjustments'
      message={'Please select pricing adjustment below'}
      onClose={pricingAdjustmentProps?.[priceAdjustmentKey]?.onClose}
      onSubmit={pricingAdjustmentProps?.[priceAdjustmentKey]?.onSubmit}
      isSubmitDisabled={!pricingAdjustmentProps?.[priceAdjustmentKey]?.onSubmit}
    >
      <Dropdown
        label='Type'
        value={pricingAdjustDropdown?.value}
        onChange={e => setPriceAdjustmentKey(e.currentTarget.value)}
        options={pricingAdjustDropdown?.options}
      />
      {pricingAdjustDropdown?.value && <hr/>}
      {pricingAdjustmentProps?.[priceAdjustmentKey]?.message ? <div className='modal__message'>{pricingAdjustmentProps?.[priceAdjustmentKey]?.message}</div> : null}
      {pricingAdjustmentProps?.[priceAdjustmentKey]?.content || null}
    </ConfirmationModal>
  )
}

export default PricingAdjustmentModal
