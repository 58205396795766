import { useRef, useEffect } from 'react'

const useOuterClick = function (callback) {
  const innerRef = useRef()

  useEffect(() => {
    // We run this useEffect only once to avoid adding
    // and removing event listeners on each render.
    document.addEventListener('mousedown', handleClick)
    // When component is unmounted the event listener is removed
    return () => document.removeEventListener('mousedown', handleClick)
    function handleClick(e) {
      // if the clicked element (e.target) is not
      // inside the element tracked by this hook,
      // the passed callback is executed.
      if (innerRef.current
        && callback
        && !innerRef.current.contains(e.target)) {
        callback(e)
      }
    }
  }, [callback])

  // return the ref to be set in
  // the container element.
  return innerRef
}

export {
  useOuterClick
}
