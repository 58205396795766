import object from './object'
import dottie from 'dottie'

// local helpers
const _getPropsFn = (props) => {
  if (Array.isArray(props)) return (x) => object.pick(x, props)
  else if (typeof props === 'string') return (x) => dottie.get(x, props)
  else if (typeof props === 'function') return props
  else if (props === null) return (x) => x
  else throw new Error('key paramter must be of type array or string or null')
}

// Returns every element that exists in any of the two arrays at least
// once, after applying the provided function to each array element of both.
// Note: If a and b are arrays of objects, potential issues may arise with
// duplicate elements in the result array if a and/or b contain any duplicate
// elements within their own array.
// [a,b,c] & [c,d,e] => [a,b,c,d,e]
const unionBy = (a, b, fn) => {
  const s = new Set(a.map(fn))
  return Array.from(new Set([...a, ...b.filter(x => !s.has(fn(x)))]))
}

// [a,b,c] & [b,c,d] = [a,d]
const symmetricDifferenceBy = (a, b, fn) => {
  const aSet = new Set(a.map(fn))
  const bSet = new Set(b.map(fn))
  return Array.from(new Set([
    ...a.filter(x => !bSet.has(fn(x))),
    ...b.filter(x => !aSet.has(fn(x)))
  ]))
}

// [a,b,c] & [b,c,d] = [a]
const differenceBy = (a, b, fn) => {
  const bSet = new Set(b.map(fn))
  return Array.from(new Set([
    ...a.filter(x => !bSet.has(fn(x)))
  ]))
}

// Returns every element that exists in both of the two arrays
// after applying the provided function to each array element of both.
// Note: If a and b are arrays of objects, fn must be used to convert
// the object to a JS primitive so that it can be compared
// [a,b,c] & [c,d,e] => [c]
const intersectBy = (a, b, fn) => {
  const bSet = new Set(b.map(fn))
  return Array.from(new Set([
    ...a.filter(x => bSet.has(fn(x)))
  ]))
}

// Group array items by fn
const groupBy = (a, fn) => a.reduce((accum, curr) => {
  const key = fn(curr)
  if (!(key in accum)) {
    accum[key] = []
  }
  accum[key].push(curr)
  return accum
}, {})

// Convert an array to a map
// fn is the function to get the map key
// props is for selecting the properties that are mapped to the map key
//  props can either be a string which represents the value to be mapped to the
//  key or it can be a array of properties that will be mapped as an object to the key
//  or null if the entire object is mapped
// dataStruct may be either 'Object' or 'Map'
const toMap = (a, fn, props = null, dataStruct = 'Object') => {
  if (!a) return {}
  const propsFn = _getPropsFn(props)
  if (dataStruct === 'Object') {
    return a.reduce((accum, curr) => ({ ...accum, [fn(curr)]: propsFn(curr) }), {})
  } else if (dataStruct === 'Map') {
    return a.reduce((accum, curr) => accum.set(fn(curr), propsFn(curr)), new Map())
  }
}

// Similar to toMap
// Pick a set of props from each object in an array of objects
// props is for selecting the properties that returned from each array item
//  props can either be a string which represents the value to be mapped to the
//  array item or it can be a array of properties that will be mapped as an object
//  or null if the entire object is mapped
const pickBy = (a = [], props) => a?.map(_getPropsFn(props))

// Partition an array into two arrays based on a partitioning function
// Items in the first array are truthy and in second array are falsey
// a = [1,2,3,4,5,6,7,8], fn = (x) => x > 4 => [[1,2,3,4], [5,6,7,8]]
const partitionBy = (a = [], fn, props = null) => {
  const propsFn = _getPropsFn(props)
  return a.reduce((partitions, x) => {
    if (fn(x)) partitions[0].push(propsFn(x))
    else partitions[1].push(propsFn(x))
    return partitions
  }, [[], []])
}

// Return a range of values from a min and max value
const captureRange = (a, fn, min, max) => {
  const minIdx = a.findIndex(x => fn(x) === min)
  const maxIdx = a.findIndex(x => fn(x) === max)
  return a.slice(minIdx, maxIdx + 1)
}

const hasOverlap = (arr, keys = { start: 'start', end: 'end' }) => {
  const { start, end } = keys
  const sortedArr = arr.slice().sort((i1, i2) => Number(i1[start]) - Number(i2[start]))
  return sortedArr.slice(1).some((curr, idx) => sortedArr[idx][end] >= curr[start])
}

// Insert an item into an array and preserve sort order (in-place algorithm)
// 'a' is the item to be inserted
// arr is the array of already sorted elements
// fn is the compare function between 'a' and a given arr item (if match return 0, if gt return 1, if lt return -1)
const sortedInsert = (a, arr, fn) => {
  const _find = (item, start, end) => {
    start = start ?? 0
    end = end ?? arr.length

    const pivot = parseInt(start + (end - start) / 2, 10)
    const match = fn(item, arr[pivot])
    if (end - start <= 1 || match === 0) return pivot
    if (match === 1) {
      return _find(item, pivot, end)
    } else {
      return _find(item, start, pivot)
    }
  }
  arr.splice(_find(a) + 1, 0, a)
  return arr
}

export default { unionBy, symmetricDifferenceBy, differenceBy, intersectBy, groupBy, toMap, pickBy, partitionBy, captureRange, hasOverlap, sortedInsert }
