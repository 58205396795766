import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@templates'
import { reportActions, saleActions } from '@actions'
import { fileUtils } from '@utils'
import { Button } from '@atoms'
import { TextInput, Dropdown } from '@molecules'
import dottie from 'dottie'
import moment from 'moment'
import clone from 'just-clone'

function PaymentsReportModal({ open, onClose }) {
  const [sales, setSales] = useState([])
  const [formData, setFormData] = useState(null)

  const yearsOptions = useMemo(() => {
    if (!open) return []
    const years = []
    const startYear = 2018
    const endYear = new Date().getFullYear()
    for (let i = startYear; i <= endYear; i++) {
      years.push(i)
    }
    return years.reverse()
  }, [open])

  const monthOptions = useMemo(() => {
    if (!open) return []
    const months = []
    for (let i = 0; i < 12; i++) {
      const date = new Date()
      date.setMonth(i)
      months.push({ value: i + 1, label: date.toLocaleString('default', { month: 'long' }) })
    }
    return months
  }, [open])

  useEffect(() => {
    saleActions.getSales({ status: 'COMPLETED' })
    .then(response => setSales(response.data.data.sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt))))
  }, [])

  function handleOnSubmit(values) {
    reportActions.getPaymentsReport('xlsx', dottie.transform(sanitizeValues(clone(values))))
    .then(response => fileUtils.saveBase64Excel(response.data.data.report, fileUtils.getFileName(response.data.data)))
  }

  function sanitizeValues(values) {
    if (!values) return {}
    Object.keys(values).forEach(v => {
      if (!values[v]) delete values[v]
    })

    if (values['dateRange.startDate']) values['dateRange.startDate'] = moment((values['dateRange.startDate'])).toISOString()
    if (values['dateRange.endDate']) values['dateRange.endDate'] = moment((values['dateRange.endDate'])).toISOString()
    return values
  }

  function handleOnChange(event) {
    const name = event?.currentTarget?.name ?? event?.target?.name
    const value = event?.currentTarget?.value ?? event?.target?.value
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  function handleOnClose() {
    setFormData(null)
    if (onClose && typeof onClose) onClose()
  }

  return (
    <Modal
      title='Payments Report'
      open={open}
      onClose={handleOnClose}
    >
      <div className="payments-report-modal__container">
        <div className="payments-report-modal__form-buttons">
          <Button
            typeVariant='action'
            size='sm'
            onClick={() => setFormData(null)}
          >
            Clear All
          </Button>
        </div>
        <Dropdown
          name='saleId'
          label='Sale'
          options={sales?.map(s => ({ value: s.id, label: s.name }))}
          required={false}
          value={formData?.saleId}
          onChange={handleOnChange}
          disabled={formData?.['dateRange.startDate'] || formData?.['dateRange.endDate'] || formData?.['period.month'] || formData?.['period.year']}
        />
        <TextInput
          name='dateRange.startDate'
          label='Start Date'
          type='date'
          required={false}
          value={formData?.['dateRange.startDate']}
          disabled={formData?.saleId || formData?.['period.month'] || formData?.['period.year']}
          onChange={handleOnChange}
        />
        <TextInput
          name='dateRange.endDate'
          label='End Date'
          type='date'
          required={false}
          value={formData?.['dateRange.endDate']}
          disabled={formData?.saleId || formData?.['period.month'] || formData?.['period.year']}
          onChange={handleOnChange}
        />
        <Dropdown
          name='period.month'
          label='Month'
          type='date'
          options={monthOptions.map(m => ({ ...m }))}
          required={false}
          value={formData?.['period.month']}
          disabled={formData?.saleId || formData?.['dateRange.startDate'] || formData?.['dateRange.endDate'] }
          onChange={handleOnChange}
        />
        <Dropdown
          name='period.year'
          label='Year'
          type='date'
          options={yearsOptions.map(y => ({ value: y, label: y }))}
          required={false}
          value={formData?.['period.year']}
          onChange={handleOnChange}
          disabled={formData?.saleId || formData?.['dateRange.startDate'] || formData?.['dateRange.endDate'] }
        />
        <div className="payments-report-modal__form-buttons">
          <Button
            typeVariant='action'
            size='sm'
            onClick={handleOnClose}
          >
            Cancel
          </Button>
          <Button
            size='sm'
            onClick={() => handleOnSubmit(formData)}
          >
            Generate Report
          </Button>
        </div>
      </div>
    </Modal>
  )
}

PaymentsReportModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
}

PaymentsReportModal.defaultProps = {
  open: true
}

export default PaymentsReportModal
