import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { DetailsPage } from '@templates'
import { eventActions } from '@actions'
import { usePageTitle, useToast } from '@hooks'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { textUtils } from '@utils'

function EventDetails({ match, title }) {
  const { eventId } = match?.params
  const [events, setEvents] = useState([])
  const [fields, setFields] = useState([])
  const [eventTypes, setEventTypes] = useState([])
  const { showSuccessToast } = useToast()
  const history = useHistory()

  useEffect(() => {
    getEvents()
    getEventTypes()
  }, [])

  const event = useMemo(() => getEvent(), [events])
  const groupIds = useMemo(() => getGroupIds(), [events])
  usePageTitle(title, eventId, event?.name)

  useEffect(() => {
    if (event) {
      setFields([
        {
          label: 'Name',
          value: event?.name,
          name: 'name',
          componentName: 'textInput',
          canEdit: true
        },
        {
          label: 'Event Type',
          value: event?.typeId,
          name: 'typeId',
          componentName: 'dropdown',
          canEdit: true,
          options: eventTypes?.map(eventType => {
            return {
              label: eventType?.name,
              value: eventType?.id
            }
          })
        },
        {
          label: 'Description',
          value: event?.description || '',
          name: 'description',
          componentName: 'textArea',
          canEdit: true,
          span: true
        },
        {
          label: 'Start Time',
          value: textUtils.formatDate(event.startTime, true),
          name: 'startTime',
          componentName: 'textInput',
          type: 'datetime-local',
          canEdit: true
        },
        {
          label: 'End Time',
          value: textUtils.formatDate(event.endTime, true),
          name: 'endTime',
          componentName: 'textInput',
          type: 'datetime-local',
          canEdit: true
        },
        {
          label: 'Group ID',
          value: event?.groupId,
          name: 'groupId',
          componentName: 'dropdown',
          options: groupIds?.map(groupId => {
            return {
              value: groupId,
              label: groupId
            }
          }),
          creatable: true,
          canEdit: true
        },
        {
          label: 'Visible',
          name: 'visible',
          componentName: 'fieldset',
          canEdit: true,
          children: [
            {
              label: 'Visible',
              value: event?.visible,
              componentName: 'checkbox',
              name: 'visible',
              canEdit: true
            }
          ]
        },
        {
          label: 'Created At',
          value: moment(event.createdAt).toLocaleString(),
          name: 'createdAt',
          componentName: 'textInput',
          canEdit: false
        },
        {
          label: 'Last Updated',
          value: moment(event.lastUpdated).toLocaleString(),
          name: 'lastUpdated',
          componentName: 'textInput',
          canEdit: false
        }
      ])
    }
  }, [event, eventTypes, groupIds])

  function getEvents() {
    if (eventId) {
      eventActions.getEvents()
      .then(response => setEvents(response?.data?.data))
    }
  }

  function getEvent() {
    if (events?.length) {
      return events.find(event => event.id === eventId)
    }
  }

  function getEventTypes() {
    eventActions.getEventTypes()
    .then(response => setEventTypes(response?.data?.data))
  }

  function getGroupIds() {
    if (events?.length) {
      return [...new Set(events.filter(event => event.groupId)
      .map(event => event.groupId))]
    }
  }

  function handleSubmit(editValues) {
    if (editValues.startTime) {
      editValues.startTime = moment(editValues.startTime).toISOString()
    }

    if (editValues.endTime) {
      editValues.endTime = moment(editValues.endTime).toISOString()
    }

    return eventActions.editEvent(event.id, editValues)
    .then(() => {
      showSuccessToast('Event updated.')
      history.push('/events')
    })
  }

  function handleRemove() {
    return eventActions.setEventCondition(event.id, 'DELETED')
    .then(() => {
      showSuccessToast('Event removed.')
      history.push('/events')
    })
  }

  return (
    <DetailsPage
      fields={fields}
      canEdit={true}
      onSubmit={handleSubmit}
      onRemove={handleRemove}
      title={{
        label: 'Event ID',
        value: event?.id || ''
      }}
    />
  )
}

EventDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string
}

export default EventDetails
