import React, { useEffect, useState, useRef } from 'react'
import OrderPricing from '../pricing/orderPricing'
import { Button } from '@atoms'
import { Form } from '@organisms'
import { useGlobalsStore } from '@stores'

function PricingStep(props) {
  const { validateAndGoNextStep, renderValidationError, validationSchemaError, removeValidationSchemaError } = props.useMultiSteps(props)
  const { getOrgById } = useGlobalsStore()
  const fluorGridRef = useRef(null)
  const [showCosts, setShowCosts] = useState(false)
  useEffect(() => {
    getOrgById(props.getState('buyerId'))
    .then(buyer => {
      setShowCosts(buyer?.showCosts)
    })
  }, [props.state.buyerId])

  return <>
    <Form>
      <OrderPricing
        name='priceScheme'
        onChange={props.handleChange}
        value={props.getState('priceScheme')}
        showCosts={showCosts}
        fluorGridRef={fluorGridRef}
        validationText={validationSchemaError?.parseStep('priceScheme')}
        removeValidationText={removeValidationSchemaError}
      />
      {renderValidationError()}
      <div className="right">
        <Button
          size='sm'
          typeVariant='action'
          onClick={() => props.prev()}
        >
          Previous
        </Button>
        <Button
          size='sm'
          onClick={async () => {
            if (fluorGridRef.current) fluorGridRef.current.getCompressedDiscounts()
            await validateAndGoNextStep()
          }}
        >
          Next
        </Button>
      </div>
    </Form>
  </>
}

export default PricingStep
