import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@templates'
import { textUtils } from '@utils'
import { logisticsActions } from '@actions'
import { useToast } from '@hooks'
import { useGlobalsStore } from '@stores'
import { Table, FormComponents } from '@organisms'
import moment from 'moment'

function EditPaymentsModal({
  open,
  payments,
  orgsMap,
  salesMap,
  onClose
}) {
  const {
    SimpleForm,
    TextInput,
    Button,
    TextArea,
    Dropdown
  } = FormComponents

  const { showSuccessToast } = useToast()
  const [isOpen, setIsOpen] = useState(false)
  const appSettingsParams = { groupKey: 'INVOICE_PAYMENT_DETAILS' }
  const {
    appSettings: { [JSON.stringify(appSettingsParams)]: invoicePaymentDetails },
    getAppSettings,
    orgsList: { [JSON.stringify({ condition: 'ACTIVE' })]: orgsList },
    getOrgsList
  } = useGlobalsStore()

  const buyers = useMemo(() => new Set(payments?.map(p => p.buyerId)), [payments])
  const sendingBank = useMemo(() => {
    if (buyers?.size && orgsList?.length) {
      const orgs = orgsList.filter(o => buyers.has(o.orgId))
      if (!orgs?.length) return []
      return orgs.map(org => org.address.find(a => a.billing && (!a.condition || a.condition === 'ACTIVE')))
      .filter(addr => addr)
      .map(addr => ([
        ...(addr.invoicePayment?.primaryBank ? [addr.invoicePayment.primaryBank] : []),
        ...(addr.invoicePayment?.secondaryBank ? [addr.invoicePayment.secondaryBank] : [])
      ]))
      .reduce((allowedBanks, orgBanks) => allowedBanks.filter(aBank => orgBanks.includes(aBank)))
    }
    return []
  }, [orgsList, buyers])

  const hasDifferentBuyers = useMemo(() => buyers?.size > 1, [buyers])
  const hasPaidPayments = useMemo(() => payments?.some(p => p.paymentDate), [payments])

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  useEffect(() => {
    if (isOpen) {
      getOrgsList({ condition: 'ACTIVE' })
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      getAppSettings(appSettingsParams)
    }
  }, [isOpen])

  const columns = useMemo(() => (
    [
      {
        id: 'id',
        Header: 'Payment ID',
        accessor: row => textUtils.shortenUUID(row?.id),
        dataType: 'string'
      },
      {
        Header: 'Invoice Number',
        id: 'invoiceNumber',
        accessor: row => row?.Invoice?.invoiceNumber || '',
        dataType: 'string'
      },
      {
        Header: 'Buyer',
        id: 'buyerId',
        accessor: row => orgsMap?.[row.buyerId],
        dataType: 'string'
      },
      {
        Header: 'Seller',
        id: 'sellerId',
        accessor: row => orgsMap?.[row.sellerId],
        dataType: 'string'
      },
      {
        Header: 'Payment Date',
        accessor: 'paymentDate',
        dataType: 'date'
      },
      {
        Header: 'Sending Bank',
        accessor: 'sendingBank',
        dataType: 'string'
      },
      {
        Header: 'Receiving Bank',
        accessor: 'receivingBank',
        dataType: 'string'
      },
      {
        Header: 'FX (CAD)',
        accessor: 'cadExchange',
        dataType: 'number'
      },
      {
        Header: 'FX (BWP)',
        accessor: 'bwpExchange',
        dataType: 'number'
      }
    ]
  ), [orgsMap, salesMap, payments])

  function handleOnSubmit(values) {
    const paymentIds = payments?.map(pay => pay?.id)
    if (paymentIds && values) {
      if (values?.paymentDate == null || values.paymentDate === '') delete values.paymentDate
      if (values.paymentDate) values.paymentDate = moment(values.paymentDate).toISOString()
      logisticsActions.editMultiplePayments({ paymentIds, ...values })
      .then(() => {
        showSuccessToast('Payments has been updated')
        handleOnClose({ shouldFetch: true })
      })
    }
  }

  function handleOnClose({ shouldFetch }) {
    setIsOpen(false)
    if (onClose && typeof onClose === 'function') onClose(shouldFetch)
  }

  return (
    <Modal
      title='Edit Payments'
      open={isOpen}
      onClose={() => handleOnClose({ shouldFetch: false })}
    >
      <div className="mark-invoice-as-paid-modal__container">
        <div className="mark-invoice-as-paid-modal__form">
          <SimpleForm
            onSubmit={handleOnSubmit}
            initialValues={{ paymentDate: hasPaidPayments ? '' : textUtils.formatDate(new Date()) }}
          >
            <TextInput
              label='Payment Date'
              type='date'
              name='paymentDate'
              required={false}
            />
            <Dropdown
              label='Sending Bank'
              name='sendingBank'
              disabled={hasDifferentBuyers}
              options={sendingBank?.map(bank => ({ label: bank, value: bank }))}
              required={false}
              creatable={true}
            />
            <Dropdown
              label='Receiving Bank'
              name='receivingBank'
              options={invoicePaymentDetails?.map(({ key }) => ({ label: key, value: key }))}
              required={false}
            />
            <TextInput
              label='FX (CAD)'
              name='cadExchange'
              type='number'
              required={false}
              decimalScale={6}
            />
            <TextInput
              label='FX (BWP)'
              name='bwpExchange'
              type='number'
              required={false}
              decimalScale={6}
            />
            <TextArea
              label='Comments'
              name='notes'
              required={false}
            />
            <Button
              typeVariant='action'
              onClick={() => handleOnClose({ shouldFetch: false })}
            >
              Cancel
            </Button>
            <Button
              type='submit'
            >
              Save
            </Button>
          </SimpleForm>
        </div>
        <Table
          id='editPayment'
          title='Payments'
          data={payments}
          columns={columns}
          isMultiSelect={false}
        />
      </div>
    </Modal>
  )
}

EditPaymentsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  payments: PropTypes.arrayOf(PropTypes.object),
  orgsMap: PropTypes.arrayOf(PropTypes.object),
  salesMap: PropTypes.arrayOf(PropTypes.object)
}

EditPaymentsModal.defaultProps = {
  open: false,
  payments: [],
  orgsMap: [],
  salesMap: []
}

export default EditPaymentsModal
