import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormComponents, Fieldset } from '@organisms'
import { Checkbox } from '@atoms'
import { notificationsActions } from '@actions'
import { useToast } from '@hooks'
import { COMMON } from '@constants'
import { useHistory } from 'react-router-dom'
import { Dropdown as ExternalDropdown } from '@molecules'
import { useGlobalsStore } from '@stores'
import moment from 'moment'
import NotificationActionList from './notificationActionList'
import NotificationAttachmentList from './notificationAttachmentList/notificationAttachmentList'
import { textUtils } from '@utils'
function NotificationForm({ isNewNotification }) {
  const {
    SimpleForm,
    TextInput,
    TextArea,
    Dropdown,
    Button
  } = FormComponents
  const hours = useMemo(() => [...Array(24).keys()].map(i => ({ value: i, label: i })), [])
  const minsSeconds = useMemo(() => [...Array(60).keys()].map(i => ({ value: i, label: i })), [])
  const formTitle = `New Notification ${!isNewNotification ? 'Template' : ''}`
  const submitButtonText = `Create Notification ${!isNewNotification ? 'Template' : ''}`
  const notificationTypes = COMMON.NOTIFICATION.TYPE
  const [sendEmail, setSendEmail] = useState(false)
  const [sendAppNotification, setSendAppNotification] = useState(false)
  const [isAppNotificationSubmitted, setIsAppNotificationSubmitted] = useState(false)
  const [isEmailNotificationSubmitted, setIsEmailNotificationSubmitted] = useState(false)
  const [recipientsList, setRecipientList] = useState([])
  const [selectedRecipients, setSelectedRecipients] = useState([])
  const [notificationTemplateList, setNotificationTemplateList] = useState([])
  const [templateValues, setTemplateValues] = useState({})
  const [selectedTemplateId, setSelectedTemplateId] = useState(null)
  const [attachments, setAttachments] = useState([])
  const { showSuccessToast, showErrorToast } = useToast()
  const {
    getOrgsList,
    orgsList: { all: orgsList },
    getUsersList,
    usersList
  } = useGlobalsStore(store => store)

  const history = useHistory()
  const types = useMemo(() => Object.keys(notificationTypes).map(type => ({
    value: type,
    label: notificationTypes[type]
  })), [])

  useEffect(() => {
    getOrgsList()
  }, [])

  useEffect(() => {
    getUsersList()
  }, [])

  useEffect(() => {
    if (usersList?.length && orgsList?.length) {
      setRecipientList(usersList.map(user => {
        const org = orgsList.find(org => org.orgId === user.orgId)
        return {
          label: `${user.name}${org?.commonName ? ` | ${org.commonName}` : ''}`,
          value: user.userId
        }
      }))
    }
  }, [usersList, orgsList])

  useEffect(() => {
    if (isNewNotification) {
      notificationsActions.getNotificationTemplates()
      .then(response => {
        setNotificationTemplateList(response.data.data)
      })
    }
  }, [isNewNotification])

  function handleOnTemplateSelect(templateId) {
    const notificationTemplate = notificationTemplateList.find(template => template.templateId === templateId)
    const notifyTime = notificationTemplate?.app?.notifyTimeOffset
      ? textUtils.formatDate(moment(new Date()).add(notificationTemplate?.app?.notifyTimeOffset).utc().local(), true)
      : null
    const sendTime = notificationTemplate?.email?.sendTimeOffset
      ? textUtils.formatDate(moment(new Date()).add(notificationTemplate?.email?.sendTimeOffset).utc().local(), true)
      : null
    if (notificationTemplate) {
      setTemplateValues({
        app: {
          title: notificationTemplate?.app?.title || '',
          body: notificationTemplate?.app?.body || '',
          actions: notificationTemplate?.app?.actions || [],
          notificationType: notificationTemplate?.app?.notificationType || '',
          notifyTime
        },
        email: {
          sender: {
            replyTo: notificationTemplate?.email?.replyTo || '',
            from: notificationTemplate?.email?.sendFrom || ''
          },
          recipients: notificationTemplate?.email?.recipients || {},
          sendTimeOffset: notificationTemplate?.email?.sendTimeOffset || '',
          body: notificationTemplate?.email?.body || '',
          subject: notificationTemplate?.email?.subject || '',
          sendTime
        }
      })

      setSendAppNotification(!!notificationTemplate?.app?.title)
      setSendEmail(!!notificationTemplate?.email?.subject)
    }

    setSelectedTemplateId(templateId)
  }

  function handleOnSubmit(values) {
    if (isNewNotification) {
      handleNotificationSubmit(values)
    } else {
      handleNotificationTemplateSubmit(values)
    }
  }

  function handleNotificationSubmit(values) {
    const requests = []
    if (sendAppNotification) {
      if (!selectedRecipients?.length) {
        showErrorToast('Recipients is required.')
        return
      }

      const notificationData = {
        notifications: [{
          actions: values?.app?.actions,
          type: values?.app?.notificationType,
          title: values?.app?.title,
          body: values?.app?.body || '',
          recipients: selectedRecipients
        }]
      }

      if (values?.app?.notifyTime) {
        notificationData.notifications[0].notifyTime = moment(values?.app?.notifyTime).toISOString()
      }

      requests.push(notificationsActions.createAppNotification(notificationData)
      .then(() => {
        showSuccessToast('App notification created.')
        setSendAppNotification(false)
        setIsAppNotificationSubmitted(true)
      }))
    }

    if (sendEmail) {
      const emailData = {
        emails: [{
          sender: values?.email?.sender,
          subject: values?.email.subject,
          body: values?.email.body || '',
          recipients: [{ ...values?.email?.recipients }]
        }]
      }

      if (values?.email?.sendTime) {
        emailData.emails[0].sendTime = moment(values?.email?.sendTime).toISOString()
      }

      requests.push(notificationsActions.createEmailNotification(emailData)
      .then(() => {
        showSuccessToast('Email notification created.')
        setSendEmail(false)
        setIsEmailNotificationSubmitted(true)
      }))
    }

    if (requests.length) {
      const req = requests.reduce((requests, request) => {
        return requests.then(() => request)
      }, Promise.resolve())

      req.then(() => history.push('/notifications'))
    }
  }

  function handleNotificationTemplateSubmit(values) {
    const data = {
      templateName: values.templateName,
      app: { ...values.app }
    }

    if (sendEmail) {
      // Filter out empty replyTo field
      const { replyTo, ...restEmail } = values.email
      data.email = { ...restEmail, ...(replyTo && { replyTo }) }

      if (values?.timeOffset?.email) {
        // parse time offset into duration
        data.email.sendTimeOffset = moment.duration({ ...values.timeOffset.email })
      }
    }

    if (values?.timeOffset?.notification) {
      // parse time offset into duration
      data.app.notifyTimeOffset = moment.duration({ ...values.timeOffset.notification })
    }

    if (attachments.length) {
      data.email.attachments = attachments.map(att => ({
        filename: att.fileName,
        required: !!att.required,
        report: {
          reportType: att.reportType,
          ...att.parameters.reduce((obj, param) => ({ ...obj, [param.split('|')[0]]: param.split('|')[1] }), {})
        }
      }))
    }

    notificationsActions.createNotificationTemplate(data)
    .then(() => {
      showSuccessToast('Notification Template created.')
      history.push('/notifications/templates')
    })
  }

  function addRecipients(role) {
    let recipients = []
    if (role === 'buyers') {
      recipients = usersList.filter(user => user.roles.some(role => role.split('.')[0] === 'buyer')).map(user => user.userId)
    }

    if (role === 'sellers') {
      recipients = usersList.filter(user => user.roles.some(role => role.split('.')[0] === 'seller')).map(user => user.userId)
    }

    if (role === 'all') {
      recipients = usersList.map(user => user.userId)
    }

    setSelectedRecipients(recipients)
  }

  const recipientsTopActions = [
    {
      label: 'All buyers',
      onClick: () => addRecipients('buyers')
    },
    {
      label: 'All sellers',
      onClick: () => addRecipients('sellers')
    },
    {
      label: 'Add all',
      onClick: () => addRecipients('all')
    }
  ]

  return (
    <div className='col-xl-4 col-lg-7 col-md-10 col-sm-12 center'>
      <SimpleForm
        title={formTitle}
        onSubmit={handleOnSubmit}
        enableReinitialize={true}
        initialValues={templateValues}
      >
        {
          !isNewNotification
            ? (
              <TextInput
                label='Template Name'
                name='templateName'
              />
            ) : null
        }
        {
          isNewNotification
            ? (
              <ExternalDropdown
                label='Template'
                name='notificationTemplate'
                value={selectedTemplateId}
                required={false}
                topActions={[{
                  label: 'Templates',
                  onClick: () => history.push('/notifications/templates')
                }]}
                onChange={e => handleOnTemplateSelect(e.currentTarget.value)}
                options={notificationTemplateList.map(notificationTemplate => ({
                  value: notificationTemplate.templateId,
                  label: notificationTemplate.templateName
                }))}
              />
            ) : null
        }
        {
          isNewNotification
            ? (
              <Checkbox
                name='sendAppNotification'
                value={sendAppNotification}
                disabled={isAppNotificationSubmitted}
                onChange={e => setSendAppNotification(e.target.checked)}
                label='Send App Notification'
              />
            ) : null
        }
        <Dropdown
          label='Notification Type'
          name='app.notificationType'
          options={types}
          required={sendAppNotification || !isNewNotification}
          disabled={!sendAppNotification && isNewNotification}
          isClearable={false}
        />
        <TextInput
          label='Notification Title'
          name='app.title'
          required={sendAppNotification || !isNewNotification}
          disabled={!sendAppNotification && isNewNotification}
        />
        <TextArea
          label='Notification Body'
          name='app.body'
          required={false}
          disabled={!sendAppNotification && isNewNotification}
        />
        {
          isNewNotification
            ? (
              <ExternalDropdown
                label='Recipients'
                name='app.recipients'
                value={selectedRecipients}
                topActions={recipientsTopActions}
                options={recipientsList}
                onChange={e => setSelectedRecipients(e.currentTarget.value)}
                isMulti={true}
                creatable={true}
                disabled={!sendAppNotification}
              />
            ) : null
        }
        {
          isNewNotification
            ? (
              <TextInput
                label='Notify Time'
                type='datetime-local'
                name='app.notifyTime'
                required={false}
                disabled={!sendAppNotification}
              />
            ) : null
        }
        <Fieldset
          legend='Actions'
          name='actions'
          key='row-actions'
          required={false}
          disabled={!sendAppNotification && isNewNotification}
        >
          <NotificationActionList
            name='app.actions'
            key='row-notification-actions'
            disabled={!sendAppNotification && isNewNotification}
          />
        </Fieldset>
        {
          !isNewNotification
            ? (
              <Fieldset
                legend='Notification Time Offset'
                key='row-time-offset-fieldset'
                inline={true}
                required={false}
              >
                <TextInput
                  label='Days'
                  name='timeOffset.notification.days'
                  type='number'
                  required={false}
                />
                <Dropdown
                  label='Hours'
                  name='timeOffset.notification.hours'
                  options={hours}
                  isClearable={false}
                  required={false}
                />
                <Dropdown
                  label='Minutes'
                  name='timeOffset.notification.minutes'
                  options={minsSeconds}
                  isClearable={false}
                  required={false}
                />
                <Dropdown
                  label='Seconds'
                  name='timeOffset.notification.seconds'
                  options={minsSeconds}
                  isClearable={false}
                  required={false}
                />
              </Fieldset>
            ) : null
        }
        <Checkbox
          name='sendEmail'
          value={sendEmail}
          disabled={isEmailNotificationSubmitted}
          onChange={e => setSendEmail(e.target.checked)}
          label='Send Email'
        />
        <TextInput
          label='Subject'
          name='email.subject'
          disabled={!sendEmail}
          required={sendEmail}
        />
        {isNewNotification
          ? (
            <TextInput
              label='From'
              name='email.sender.from'
              type='email'
              disabled={!sendEmail}
              required={sendEmail}
            />
          ) : null
        }
        {!isNewNotification
          ? (
            <TextInput
              label='Send From'
              name='email.sendFrom'
              type='email'
              disabled={!sendEmail}
              required={sendEmail}
            />
          ) : null
        }
        <TextInput
          label='Reply To'
          name={`${isNewNotification ? 'email.sender.replyTo' : 'email.replyTo'}`}
          required={false}
          type='email'
          disabled={!sendEmail}
        />
        <TextArea
          label='Email Body'
          name='email.body'
          disabled={!sendEmail}
          required={false}
        />
        {
          !isNewNotification
            ? (
              <Fieldset
                legend='Email Time Offset'
                key='row-time-offset'
                required={false}
              >
                <div
                  key='row-time-offset'
                  className="create-notification-template__time-offset"
                  required={false}
                >
                  <TextInput
                    label='Days'
                    name='timeOffset.email.days'
                    type='number'
                    disabled={!sendEmail}
                    required={false}
                  />
                  <Dropdown
                    label='Hours'
                    name='timeOffset.email.hours'
                    options={hours}
                    disabled={!sendEmail}
                    isClearable={false}
                    required={false}
                  />
                  <Dropdown
                    label='Minutes'
                    name='timeOffset.email.minutes'
                    options={minsSeconds}
                    disabled={!sendEmail}
                    isClearable={false}
                    required={false}
                  />
                  <Dropdown
                    label='Seconds'
                    name='timeOffset.email.seconds'
                    options={minsSeconds}
                    disabled={!sendEmail}
                    isClearable={false}
                    required={false}
                  />
                </div>
              </Fieldset>
            ) : null
        }
        {
          !isNewNotification
            ? (
              <NotificationAttachmentList
                attachments={attachments}
                disabled={!sendEmail}
                onAttachmentChange={setAttachments}
              />
            ) : null
        }
        <Fieldset
          legend='Recipients'
          key='row-recipients'
        >
          <TextArea
            label='To'
            name='email.recipients.tos'
            disabled={!sendEmail}
            required={false}
          />
          <TextArea
            label='Cc'
            name='email.recipients.ccs'
            disabled={!sendEmail}
            required={false}
          />
          <TextArea
            label='Bcc'
            name='email.recipients.bccs'
            disabled={!sendEmail}
            required={false}
          />
        </Fieldset>
        {
          isNewNotification
            ? (
              <TextInput
                label='Send Time'
                name='email.sendTime'
                type='datetime-local'
                required={false}
                disabled={!sendEmail}
              />
            ) : null
        }
        <div key='row-submit-button' className="right">
          <Button type='submit'>
            {submitButtonText}
          </Button>
        </div>
      </SimpleForm>
    </div>
  )
}

NotificationForm.propTypes = {
  isNewNotification: PropTypes.bool
}

NotificationForm.defaultProps = {
  isNewNotification: false
}

export default NotificationForm
