import { textUtils } from '@utils'

const getFormFieldLabel = (key, field) => {
  switch (key) {
    case 'shapeId':
      return (field?.shapeType === 'CUSTOM' ? 'Custom | ' : 'Preloaded | ') + field?.name
    case 'cutGradeId':
      return (field?.buyerId !== null ? 'Custom | ' : 'Preloaded | ') + field?.institute + ' | ' + field?.name
    case 'priceBaselineId': {
      const date = field?.date || field?.ParentBaseline?.date
      return field?.name + ' | ' + field?.shapeType + (date ? ' | ' + textUtils.formatDate(date) : '')
    }
    case 'mfgCostId':
    case 'certCostId':
    case 'tingeMapId':
    case 'additionalFilters.countryIds':
    case 'additionalFilters.mineIds':
      return field?.name
    case 'additionalFilters.provenanceTypeIds':
      return field?.description
    default:
      return ''
  }
}

export { getFormFieldLabel }
