import React, { useState, useEffect, useMemo } from 'react'
import FileSaver from 'file-saver'
import { Table } from '@organisms'
import { useAuthStore, useGlobalsStore } from '@stores'
import { reportActions, saleActions } from '@actions'
import { fileUtils, textUtils } from '@utils'

function ReportsList() {
  const { hasAdmin, hasPermission, permissionsAdminCache } = useAuthStore(state => state)
  const isAdmin = hasAdmin(reportActions.listReports)
  const isAllowed = hasPermission(reportActions.listReports)
  const { orgsList: { all: orgsList }, getOrgsList, reportTypesMap, getReportTypes } = useGlobalsStore()
  const [reports, setReports] = useState([])

  const availableReports = useMemo(() => (reports || []).filter(report => isAdmin || report.reportType !== 'INVOICE'), [reports])

  useEffect(() => {
    getOrgsList()
    getReportTypes()
  }, [])
  useEffect(() => {
    if (!permissionsAdminCache.size) return
    refreshReports()
  }, [permissionsAdminCache])

  function refreshReports () {
    (isAllowed ? reportActions.listReports : reportActions.listUserReports)({ condition: 'ACTIVE' })
    .then(result => setReports(result.data.data))
  }

  const [sales, setSales] = useState([])
  useEffect(() => {
    saleActions.getSales({ columns: '[id, name]' })
    .then(result => {
      setSales(result.data.data)
    })
  }, [])

  const getJSONCell = function({ value }) {
    if (!value) return value
    if (typeof value === 'string' && value[0] === '{') value = JSON.parse(value)
    else if (typeof value === 'string') return value
    if (!Object.keys(value)?.length) return null
    return Object.entries(value).map(([key, val]) => (
      <div key={key} className='table__cell-json'>{key}: {typeof val === 'string' ? val : JSON.stringify(val)}</div>
    ))
  }

  const [columns, setColumns] = useState([])
  useEffect(() => {
    setColumns([
      {
        Header: 'Report',
        id: 'reportType',
        accessor: row => reportTypesMap?.[row.reportType] ?? 'Unknown',
        filterType: 'checkbox'
      },
      {
        Header: 'Details',
        accessor: 'options',
        Cell: getJSONCell
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: row => textUtils.formatDescription(row.status),
        filterType: 'checkbox'
      },
      {
        Header: 'Sale',
        id: 'saleId',
        accessor: row => sales?.find(s => s.id === row.saleId)?.name || 'N/A'
      },
      {
        Header: 'Report Time',
        accessor: 'createdAt',
        dataType: 'dateTime',
        filterType: 'date'
      },
      ...(isAdmin ? [{
        Header: 'Owner',
        id: 'orgId',
        accessor: row => orgsList?.find(org => org.orgId === row.orgId)?.commonName
      }] : [])
    ])
  }, [isAdmin, orgsList, sales, reportTypesMap])

  const rowActions = [
    {
      actionName: 'download',
      callback: handleDownload,
      shouldConfirm: false,
      shouldDisplay: (row) => row.status === 'AVAILABLE'
    }
  ]

  async function handleDownload(savedReport) {
    const response = await reportActions.getDownloadUrl([savedReport.fileId], { savedReportId: savedReport.id })
    const file = response?.data?.data?.[0]
    // FileSaver.saveAs(file.url, fileUtils.getFileName(file))
    const downloaded = await reportActions.getBinaryFile(file.url)
    FileSaver.saveAs(downloaded.data, fileUtils.getFileName(file))
  }

  return (
    <Table
      title='Saved Reports'
      data={availableReports}
      columns={columns}
      rowActions={rowActions}
      initialSort={[{ id: 'createdAt', desc: true }]}
      getDataCallback={refreshReports}
      isMultiSelect={false}
    />
  )
}

export default ReportsList
