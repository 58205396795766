import React, { useMemo } from 'react'
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Line, Legend } from 'recharts'
import { textUtils } from '@utils'
import { InfoTip } from '@molecules'

function OrderFeedbackChart({ lines, activeLine, getLineDescription, getLineQuantityDescription, chartColours, onClick, onHover }) {
  const data = useMemo(() => {
    // Group the lines by percentage
    return lines.reduce((percentages, line) => percentages.map(({ pct, ...restPercentages }) => ({
      ...restPercentages,
      pct,
      [line.id]: pct
        ? line.feedback0filled + line[`feedback${pct}filled`]
        : line[`feedback${pct}filled`]
    }))
    , [
      { id: '0', pct: 0, name: 'Current' },
      { id: '1', pct: 5, name: '+5%' },
      { id: '2', pct: 10, name: '+10%' },
      { id: '3', pct: 15, name: '+15%' },
      { id: '4', pct: 20, name: '+20%' }
    ])
  }, [lines])
  function getLineLabel(order, line) {
    return `${order.id}: ${order.name}\n${getLineDescription(line)}, ${getLineQuantityDescription(line)}`
  }
  function compactLegend(props) {
    return <div>
      {props.payload.map(line => (
        <div
          key={line.dataKey}
          style={{ color: line.color }}
          onMouseEnter={() => onHover(line.dataKey)}
          onMouseLeave={() => onHover(null)}
        >
          <InfoTip
            name={`line_${line.dataKey}_legend`}
            place='right' delayHide={100}
            icon="circle"
            onClick={() => onClick(line.dataKey)}
          >
            {textUtils.breakLines(line.value)}
          </InfoTip>
        </div>
      ))}
    </div>
  }

  if (!lines?.length) return null
  return (
    <ResponsiveContainer width='100%' height={400}>
      <LineChart data={data}>
        {lines.map((line, i) => {
          const order = { id: line.orderId, name: line.orderName }
          return <Line key={line.id}
            type="monotone"
            dataKey={line.id}
            orderId={order.id}
            stroke={chartColours[i % chartColours.length]}
            strokeWidth={line.id === activeLine ? 4 : 2}
            name={getLineLabel(order, line)}
          />
        })}
        <CartesianGrid />
        <XAxis dataKey='name' label={{ value: 'Price', position: 'insideBottom', dy: 10 }}/>
        <YAxis
          label={{ value: 'Estimated Quantity', position: 'insideLeft', angle: -90, dy: 70 }}
          domain={[0, 'dataMax+1']}
          allowDecimals={false}
        />
        <Legend wrapperStyle={{ width: 40, height: '90%', right: 0, top: 5, overflowY: 'auto', overflowX: 'clip' }} layout='vertical' verticalAlign='middle' align='right' content={compactLegend} />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default OrderFeedbackChart
