import React, { useState, useEffect } from 'react'
import { Table } from '@organisms'
import { saleActions } from '@actions'
import { useHistory, NavLink } from 'react-router-dom'
import { useGlobalsStore } from '@stores'

function SalesList() {
  const [sales, setSales] = useState([])
  const {
    saleStatusesMap,
    getSaleStatuses
  } = useGlobalsStore(store => store)

  useEffect(() => {
    getSaleStatuses()
  }, [])

  useEffect(() => {
    saleActions.getSales({ columns: '[id, name, status, updatedAt]' })
    .then(result => {
      setSales(result.data.data)
    })
    .catch(console.error)
  }, [])

  const [columns, setColumns] = useState([])
  useEffect(() => {
    setColumns([
      {
        Header: 'Sale ID',
        accessor: 'id',
        dataType: 'string',
        Cell: cellInfo => {
          return (
            <NavLink
              className="link"
              to={`/sales/${cellInfo.value}`}
              id={cellInfo.row.original.id}
            >
              {cellInfo.value}
            </NavLink>
          )
        }
      },
      {
        Header: 'Name',
        accessor: 'name',
        dataType: 'string'
      },
      {
        Header: 'Status',
        accessor: (row) => saleStatusesMap?.[row.status] ?? '',
        id: 'status',
        dataType: 'string',
        filterType: 'checkbox',
        enums: Object.values(saleStatusesMap)

      },
      {
        Header: 'Last Updated',
        id: 'updatedAt',
        accessor: 'updatedAt',
        dataType: 'dateTime',
        filterType: 'date'
      }
    ])
  }, [sales, saleStatusesMap])

  const history = useHistory()
  function navigateTo(path) {
    history.push(path)
  }

  return (
    <div className='PlannedStones'>
      <Table
        title='Sales'
        data={sales}
        columns={columns}
        initialPageSize={100}
        initialSort={[{ id: 'updatedAt', desc: true }]}
        topBarActions={[
          {
            callback: () => navigateTo('/sales/create'),
            label: 'Create A Sale',
            enableOnSelect: false
          }
        ]}
      />
    </div>
  )
}

export default SalesList
