import React from 'react'
import ReactTooltip from 'react-tooltip'
import PropTypes from 'prop-types'
import { Icon } from '@atoms'
import * as messages from './messages'
import { textUtils } from '@utils'

function InfoTip({
  name,
  children,
  place,
  icon,
  iconClass,
  type,
  size,
  delayHide,
  ...rest
}) {
  function renderIcon() {
    return <Icon
      name={icon}
      size={size}
      className={iconClass}
      tooltipId={name}
      {...rest}
    />
  }

  function renderTooltip() {
    return <ReactTooltip
      id={name}
      effect='solid'
      type={type}
      place={place}
      multiline={true}
      delayHide={delayHide}
      className="infotip__tooltip"
      {...rest}
    >
      {children && children !== true ? children : messages[textUtils.dotNotationToCapital(name)] || ''}
    </ReactTooltip>
  }

  return <>
    {renderIcon()}
    {renderTooltip()}
  </>
}

InfoTip.messages = messages

InfoTip.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.any,
  type: PropTypes.string,
  place: PropTypes.string,
  icon: PropTypes.string,
  iconClass: PropTypes.string,
  size: PropTypes.string,
  delayHide: PropTypes.number
}

InfoTip.defaultProps = {
  type: 'dark',
  place: 'top',
  icon: 'info',
  iconClass: 'info-tip',
  size: 'info',
  delayHide: 500
}

export default InfoTip
