import React, { useMemo } from 'react'
import { cutGradeActions } from '@actions'
import { MultiStepsForm } from '@organisms'
import { useAuthStore } from '@stores'
import MainStep from './cutGrade/mainStep'
import ProportionStep from './cutGrade/proportionStep'
import { objectUtils } from '@utils'

function CreateCutGrade(props) {
  const { orgId: userOrgId } = useAuthStore(state => state)

  const steps = [
    { title: 'Header', component: MainStep },
    { title: 'Proportions', component: ProportionStep }
  ]

  const { stateSchema, formData } = useMemo(() => {
    const res = {}
    res.formData = objectUtils.filterNullish({
      buyerId: userOrgId
    })
    res.stateSchema = Object.keys(res.formData)
    return res
  }, [userOrgId])

  return (
    <div className="center">
      <MultiStepsForm
        steps={steps}
        formData={formData}
        stateSchema={stateSchema}
        onSubmit={(cutGrade) => {
          return cutGradeActions.createCutGrade(cutGrade)
        }}
      />
    </div>
  )
}

export default CreateCutGrade
