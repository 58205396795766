import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@atoms'
import { useLoadingSpinner } from '@stores'
function LoadingSpinnerModal(props) {
  const {
    isLoading
  } = useLoadingSpinner(state => state)
  const activeSpinners = Object.keys(isLoading).length
  const spinnerData = {
    props: {
      className: 'loading-spinner-modal__icon',
      name: 'spinner',
      spin: true
    },
    Component: null
  }

  if (activeSpinners === 1 && Object.values(isLoading)[0].progress) {
    spinnerData.Component = ProgressSpinner
    const { completedKeys, activeKeys, weight = {} } = Object.values(isLoading)[0]
    const completedWeight = Array.from(completedKeys).reduce((accum, curr) => accum + Number(weight[curr]), 0)
    const totalWeight = completedWeight + Array.from(activeKeys).reduce((accum, curr) => accum + Number(weight[curr]), 0)
    spinnerData.props.progress = completedWeight / totalWeight || 0
  } else if (activeSpinners >= 1) {
    spinnerData.Component = ClassicSpinner
  }

  const { Component: SpinnerComponent, props: spinnerProps } = spinnerData
  return SpinnerComponent && (
    <div className='loading-spinner-modal'>
      <SpinnerComponent {...spinnerProps}/>
    </div>
  )
}

function ClassicSpinner({ className, name, spin }) {
  return <Icon className={className} name={name} spin={spin}/>
}

function ProgressSpinner({ className, name, spin, progress }) {
  return <>
    <Icon className={className} name={name} spin={spin} />
    <div className="loading-spinner-modal__progress-percentage">{Math.round(progress * 100)}%</div>
  </>
}

LoadingSpinnerModal.defaultProps = {
}
export default LoadingSpinnerModal
