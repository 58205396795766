import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@atoms'
import { Dropdown, InfoTip } from '@molecules'
import prettyBytes from 'pretty-bytes'
import { useGlobalsStore } from '@stores'

function UploadAdvFileRow({
  uploadObj,
  onChange,
  onRemove,
  selectedFilesExists,
  roughStonesList,
  selectedRoughStones
}) {
  const {
    rough,
    file
  } = uploadObj
  const [localRoughList, setLocalRoughList] = useState([])
  const { roughStatusesMap, getRoughStatuses } = useGlobalsStore(state => state)
  const galaxyFileExists = selectedFilesExists.some(f => f.fileName === file.name)

  useEffect(() => {
    getRoughStatuses()
  }, [])

  useEffect(() => {
    setLocalRoughList(filterRoughList())
  }, [selectedRoughStones])

  function filterRoughList() {
    return roughStonesList.filter(rough => {
      // returns all roughs that were not selected
      // and the one selected by this adv file if so
      return rough.id === uploadObj?.rough?.id || !selectedRoughStones.some(r => r?.id === rough?.id)
    })
  }

  function handleOnChange(e) {
    if (onChange) {
      const { value } = e?.currentTarget
      uploadObj.lastSelected = uploadObj.rough
      uploadObj.rough = findRough(value)
      onChange(uploadObj)
    }
  }

  function handleOnRemove(uploadObj) {
    if (onRemove) {
      onRemove(uploadObj)
    }
  }

  function findRough(id) {
    return localRoughList.find(rough => rough.id === id) || null
  }

  function getRoughStatus(rough) {
    if (!rough) return ''
    return roughStatusesMap?.[rough.status] ?? ''
  }

  return (
    <div className='upload-adv-file-row'>
      <span className={ `upload-adv-file-row__file-name${galaxyFileExists ? '--file-exists' : ''}`}>{file?.name}</span>
      <span>{prettyBytes(file?.size)}</span>
      <div className="upload-adv-file-row__rough-status">
        <span>{getRoughStatus(uploadObj?.rough)}</span>
      </div>
      <div className="upload-adv-file-row__rough-stone">
        <Dropdown
          name='roughStoneList'
          value={uploadObj?.rough?.id}
          onChange={handleOnChange}
          options={localRoughList.map(roughStone => {
            return {
              value: roughStone.id,
              label: `${roughStone.id} - ${roughStone.sellerStoneName}`
            }
          })}
        />
      </div>
      <div className="upload-adv-file-row__icons">
        <Icon
          size='lg'
          name='remove'
          onClick={() => handleOnRemove(uploadObj)}
        />
        {
          rough
            ? (
              <div className='upload-adv-file-row__icons--success'>
                <Icon
                  size='md'
                  name='check'
                />
              </div>
            ) : null
        }
        {
          !rough
            ? (
              <InfoTip
                name='noRoughToolTip'
                icon={'error'}
                size='md'
                iconClass='upload-adv-file-row__icons--error'
              >
                <span>No rough stone selected for this ADV file.</span>
              </InfoTip>
            ) : null
        }
      </div>
    </div>
  )
}

UploadAdvFileRow.propTypes = {
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  uploadObj: PropTypes.object,
  roughStonesList: PropTypes.arrayOf(PropTypes.object),
  selectedRoughStones: PropTypes.arrayOf(PropTypes.object),
  selectedFilesExists: PropTypes.arrayOf(PropTypes.object)
}

UploadAdvFileRow.defaultProps = {
  roughStonesList: [],
  selectedRoughStones: [],
  selectedFilesExists: [],
  uploadObj: { rough: null, file: null }
}

export default UploadAdvFileRow
