const organizationSchema = {
  legalName: { type: 'string' },
  commonName: { type: 'string' },
  orgType: { type: 'string' },
  address: { type: 'array' },
  corporateNumber: { type: 'string' },
  taxNumber: { type: 'string' },
  purchaseLimit: { type: 'number' },
  purchaseMinimum: { type: 'number' },
  shippingMinimum: { type: 'number' },
  preferredShipping: { type: 'string' },
  riskLevel: { type: 'string' },
  sawPlane: {
    type: 'object',
    properties: {
      units: { type: 'string' },
      thickness: { type: 'number' }
    }
  },
  bcAnonymous: { type: 'boolean' },
  invoiceNotes: { type: 'string' },
  showCosts: { type: 'boolean' },
  showComputedBaselines: { type: 'boolean' },
  emailCcs: { type: 'array' },
  emailBccs: { type: 'array' },
  minMatchingMargin: { type: 'number' },
  minClaraMargin: { type: 'number' },
  profitSplit: { type: 'number' },
  highTensionMaxParts: { type: 'number' },
  rejectYellowFluor: { type: 'boolean' },
  comments: { type: 'string' },
  acceptWindowedStones: { type: 'boolean' },
  acceptBlockedStones: { type: 'boolean' }
}

export { organizationSchema }
