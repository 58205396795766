import React from 'react'
import { Button, Checkbox } from '@atoms'
import { Form, Fieldset } from '@organisms'
import {
  TextInput,
  TextArea,
  Dropdown,
  DisplayMessage
} from '@molecules'

function NameStep(props) {
  const {
    validationError,
    validateAndGoNextStep
  } = props.useMultiSteps(props)

  const requiredFields = [
    { name: 'legalName', label: 'Legal Name' },
    { name: 'commonName', label: 'Common Name' },
    { name: 'corporateNumber', label: 'Corporate Number' },
    { name: 'taxNumber', label: 'Tax Number' },
    { name: 'orgType', label: 'Organization Type' }
  ]

  function goNextStep() {
    // here it validates the required fields and go next step if all required fields are filled.
    validateAndGoNextStep(requiredFields)
  }

  return (
    <Form title={'Create Organization'}>
      <TextInput
        label="Legal Name"
        name="legalName"
        value={props.getState('legalName')}
        onChange={props.handleChange}
        required
      />
      <TextInput
        label="Common Name"
        name="commonName"
        value={props.getState('commonName')}
        onChange={props.handleChange}
      />
      <TextInput
        label="Corporate Number"
        name="corporateNumber"
        value={props.getState('corporateNumber')}
        onChange={props.handleChange}
      />
      <TextInput
        label="Tax Number"
        name="taxNumber"
        value={props.getState('taxNumber')}
        onChange={props.handleChange}
      />
      <TextArea
        rows={5}
        label="Email CCs"
        name="emailCcs"
        value={props.getState('emailCcs')}
        onChange={props.handleChange}
        required={false}
      />
      <TextArea
        rows={5}
        label="Email BCCs"
        name="emailBccs"
        value={props.getState('emailBccs')}
        onChange={props.handleChange}
        required={false}
      />
      <Dropdown
        size="lg"
        name="orgType"
        label="Organization Type"
        value={props.getState('orgType')}
        onChange={e => props.setState('orgType', e.currentTarget.value)}
        options={[
          { value: 'ADMIN', label: 'Admin' },
          { value: 'DEMO', label: 'Demo' },
          { value: 'GENERIC', label: 'Generic' },
          { value: 'DEFAULT_SELLER', label: 'Clara Seller' },
          { value: 'REGULAR', label: 'Regular' }
        ]}
      />
      <Fieldset legend={'Organization Role'} required>
        <ul className="vertical">
          <li>
            <Checkbox
              name="sellerRole"
              id="sellerRole"
              label="Seller"
              checked={props.getState('sellerRole')}
              onChange={props.handleChange}
            />
            <br />
            <Checkbox
              name="buyerRole"
              id="buyerRole"
              label="Buyer"
              checked={props.getState('buyerRole')}
              onChange={props.handleChange}
            />
          </li>
        </ul>
      </Fieldset>
      {validationError.error && (
        <DisplayMessage
          type={'error'}
          message={
            <>
              <div>Please enter all required fields:</div>
              {validationError.blankFields.map((blankField, i) => {
                return (
                  <span key={i}>
                    <strong>- {blankField}</strong>
                  </span>
                )
              })}
            </>
          }
        />
      )}
      <div className="create-organization-buttons">
        <Button
          disabled={!props.hasPrev()}
          typeVariant="action"
          onClick={() => props.prev()}
        >
          Previous
        </Button>
        <Button
          disabled={!props.hasNext()}
          typeVariant="primary"
          size="sm"
          onClick={() => goNextStep()}
        >
          Next
        </Button>
      </div>
    </Form>
  )
}

export default NameStep
