import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import FormInput from './formInput'
import { Icon, Button } from '@atoms'
import { RouterPrompt, ConfirmationModal } from '../templates'
import clone from 'just-clone'
import dottie from 'dottie'
import { validationUtils, objectUtils, textUtils } from '@utils'
import { useToast } from '@hooks'

function DetailsPage({
  title,
  fields,
  onSubmit,
  onCancel,
  onSanitize,
  onHasValuesChanged,
  onArchive,
  onUnarchive,
  onRemove,
  onRefresh,
  onEditToggled,
  isEdit,
  canEdit,
  canSubmit,
  modals,
  customConfirmationRenderer,
  customIconRenderer,
  extraIcons,
  extraButtons,
  extraSubmitButtons,
  propsByRef,
  validationProps: {
    validationSchema,
    schemaContext,
    validationToast
  },
  shortenId
}) {
  const [isEditToggled, setIsEditToggled] = useState(isEdit)
  const [values, setValues] = useState([])
  const [hasChanged, setHasChanged] = useState(false)
  const [hasValidated, setHasValidated] = useState(false)
  const [confirmPageExit, setConfirmPageExit] = useState(false)
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false)
  const [isUnarchiveModalOpen, setIsUnarchiveModalOpen] = useState(false)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [validationErrors, setValidationErrors] = useState()
  const { showErrorToast, showWarningToast, showInfoToast } = useToast()
  const originalFieldsValues = useMemo(() => clone(fields), [fields])
  const flatOriginalFields = useMemo(() => flatOriginalValues(originalFieldsValues), [originalFieldsValues])

  useEffect(() => {
    if (!fields || !fields.length) {
      setValues([])
      return
    }

    if (hasChanged) {
      // in case fields array has been updated and some value has changed
      // we need to update the new fields values with the changes made
      const updatedValues = updateTempValues(values, originalFieldsValues)
      setValues([...updatedValues])
    } else {
      setValues([...originalFieldsValues])
    }
  }, [fields])

  useEffect(() => {
    if (onEditToggled && typeof onEditToggled === 'function') {
      onEditToggled(isEditToggled)
    }
  }, [isEditToggled])

  function flatOriginalValues(originalValues) {
    const flatten = (arr, fn = (x) => x) => {
      if (!Array.isArray(arr) || !arr.length) return arr
      return arr.reduce((newArr, item) => newArr.concat(item).concat(fn(item) ? flatten(fn(item), fn) : []), [])
    }

    return flatten(originalValues, (x) => x.children)
  }

  function updateTempValues(currentValues, originalFieldsValues, editedValuesList = []) {
    currentValues.forEach(value => {
      if (value.children && Array.isArray(value.children)) {
        updateTempValues(value.children, originalFieldsValues, editedValuesList)
      }

      if (value.wasEdited) {
        editedValuesList.push({ ...value })
      }
    })

    for (const editedValue of editedValuesList) {
      // update values recursively because of children
      updateValue(originalFieldsValues, editedValue)
    }

    return originalFieldsValues
  }

  function updateValue(originalFieldsValues, editedValue) {
    for (const item of originalFieldsValues) {
      if (item?.children) {
        updateValue(item.children, editedValue)
      }

      if (item?.name === editedValue?.name) {
        item.value = editedValue.value
        item.wasEdited = true
      }
    }
  }

  function changeTargetValue(values, targetName, targetValue) {
    // loops through the array of values
    values.forEach(v => {
      // check to see if the values has children,
      if (v.children && Array.isArray(v.children)) {
        // if so, loops the array of children recursively
        changeTargetValue(v.children, targetName, targetValue)
      }

      // if it finds the value object to be changed
      // it updates its value with the target value (user input)
      if (v && v.name === targetName) {
        if ((v.type !== 'number' && v.type !== 'currency') || Number(v.value) !== Number(targetValue)) {
          v.wasEdited = true
        }
        v.value = targetValue
      }
    })
    // in the end returns the array of values
    return values
  }

  function setAllUnchanged(values) {
    for (const v of values) {
      if (v.children && Array.isArray(v.children)) {
        setAllUnchanged(v.children)
      }
      if (v && v.wasEdited) {
        v.wasEdited = false
      }
    }
  }

  function handleOnChange(changeHandler) {
    return async function(e) {
      if (!e.currentTarget && !e.target) return
      // get targetName
      const targetName = e?.currentTarget?.name || e?.target?.name
      if (!targetName) return
      // get value
      let targetValue = null
      if (e?.target?.type === 'checkbox') {
        targetValue = e?.target?.checked
      } else {
        targetValue = getValue(e)
      }

      function getValue(e) {
        if (e?.currentTarget?.value == null && e?.target?.value == null) return null
        if (e?.currentTarget?.value === '0' || e?.currentTarget?.value === 0) return e?.currentTarget?.value
        if (e?.target?.value === '0' || e?.target?.value === 0) return e?.target?.value
        return e?.currentTarget?.value ?? e?.target?.value
      }

      function doChange(name = targetName, value = targetValue) {
        setHasChanged(true)
        setHasValidated(false)
        setConfirmPageExit(true)
        // updates the state and re-render new values
        setValues(currValues => {
          // loop through values array
          const updatedValues = clone([...currValues])
          return changeTargetValue(updatedValues, name, value)
        })
      }

      if (changeHandler) {
        return await changeHandler(e, doChange)
      }

      return doChange()
    }
  }

  function handleOnCancel() {
    setValues(clone([...fields]))
    setHasChanged(false)
    setHasValidated(false)
    setConfirmPageExit(false)
    setIsEditToggled(false)
    setValidationErrors()
    onCancel && onCancel()
  }

  async function handleOnValidate(editedValues) {
    if (!validationSchema) return
    const newValidationErrors = await validationUtils.validateSchema(validationSchema, editedValues, { form: getValues(values, {}, false), updates: getValues(values, {}, true), ...schemaContext })
    if (newValidationErrors.getError()) {
      showErrorToast(validationToast?.error || newValidationErrors.defaultErrorToastMessage)
      setValidationErrors(newValidationErrors)
      throw new Error('Validation error.')
    } else if (newValidationErrors.getWarning() && !hasValidated) {
      showWarningToast(validationToast?.warning || newValidationErrors.defaultWarningToastMessage)
      setValidationErrors(newValidationErrors)
      throw new Error('Validation warning.')
    }
  }

  async function handleOnSubmit() {
    try {
      if (!onSubmit) throw new Error('Save was not implemented for this page')
      // This may create timing issues if the code attempts to push a new location in the callback of onSubmit below
      // Maybe might be good to pass in a redirect path prop into this component like
      // useMultiSteps.js
      setConfirmPageExit(false)
      const editedValues = getValues(values)
      const sanitizedValues = await handleOnSanitize(editedValues)
      await handleOnValidate(sanitizedValues)
      if (!hasValuesChanged(dottie.flatten(sanitizedValues), flatOriginalFields)) {
        showInfoToast('Item was not updated, no changes were recorded.')
      } else {
        await Promise.resolve(onSubmit(sanitizedValues, editedValues)) // Just in case onSubmit returns something that isn't a promise
      }
      setIsEditToggled(false)
      setAllUnchanged(values)
      setValidationErrors()
    } catch (err) {
      console.error(err)
      setConfirmPageExit(true)
    } finally {
      setHasValidated(true)
    }
  }

  async function handleOnSanitize(values) {
    const _values = trimStrings(values)
    const sanitizedValues = onSanitize ? await onSanitize(_values) : _values
    return sanitizedValues
  }

  function trimStrings(values) {
    if (values == null || values instanceof Map || values instanceof Set) return values
    if (typeof values === 'string') {
      return values.trim()
    }

    if (Array.isArray(values)) {
      return values.map(trimStrings)
    }

    if (typeof values === 'object') {
      const trimmedValues = {}
      for (const key in values) {
        trimmedValues[key] = trimStrings(values[key])
      }
      return trimmedValues
    }

    return values
  }

  function hasValuesChanged(editedValues, originalValues) {
    if (onHasValuesChanged) return onHasValuesChanged(editedValues, originalValues)
    const hasValuesChanged = []
    for (const fieldName in editedValues) {
      const originalFieldValue = originalValues.find(f => f.name === fieldName)
      if (originalFieldValue != null) {
        hasValuesChanged.push({ fieldName, hasChanged: validationUtils.diffValues(editedValues[fieldName], originalFieldValue.value) })
      } else {
        // fallback to true, so it won't prevent the user to submit the form
        // in case a field is not found in the original fields array
        hasValuesChanged.push({ fieldName, hasChanged: true })
        console.warn(`Field ${fieldName} not found in the original fields array.`)
      }
    }

    return hasValuesChanged.some(value => value.hasChanged)
  }

  async function handleOnRefresh() {
    if (!onRefresh) return
    const refreshedFields = await onRefresh()
    setValues(clone([...refreshedFields]))
  }

  function removeValidationErrors(key) {
    const newValidationErrors = validationErrors?.removeStep(key)
    setValidationErrors(newValidationErrors)
  }

  function getValues(values, valuesObj = {}, editedOnly = true) {
    // loop through values
    values.forEach(value => {
      // see if value has children
      if (value.children && Array.isArray(value.children)) {
        // if so, call function recursively
        getValues(value.children, valuesObj, editedOnly)
      }
      // check if value was edited
      if (value.wasEdited || !editedOnly) {
        // if so, add it to the editedObj
        valuesObj[value.name] = value.value
      }
    })

    return dottie.transform(valuesObj)
  }

  function getEditIcon() {
    if (!canEdit || isEditToggled) return null
    const editProps = {
      onClick: () => setIsEditToggled(!isEditToggled),
      name: 'edit',
      title: 'Edit',
      size: 'lg'
    }
    if (customIconRenderer?.edit) return customIconRenderer.edit(editProps)
    return <Icon {...editProps} />
  }

  function getArchiveIcon() {
    if (!onArchive) return null
    const archiveProps = {
      onClick: () => setIsArchiveModalOpen(true),
      name: 'archive',
      title: 'Archive',
      size: 'lg'
    }
    if (customIconRenderer?.archive) return customIconRenderer.archive(archiveProps)
    return <Icon {...archiveProps}/>
  }

  function getUnarchiveIcon() {
    if (!onUnarchive) return null
    const unarchiveProps = {
      onClick: () => setIsUnarchiveModalOpen(true),
      name: 'unarchive',
      title: 'Unarchive',
      size: 'lg'
    }
    if (customIconRenderer?.unarchive) return customIconRenderer.unarchive(unarchiveProps)
    return <Icon {...unarchiveProps}/>
  }

  function getRemoveIcon() {
    if (!onRemove) return null
    const removeProps = {
      onClick: () => setIsRemoveModalOpen(true),
      name: 'remove',
      title: 'Remove',
      size: 'lg'
    }
    if (customIconRenderer?.remove) return customIconRenderer.remove(removeProps)
    return <Icon {...removeProps} />
  }

  function getExtraIcons() {
    if (!Array.isArray(extraIcons)) {
      return []
    } else {
      return extraIcons.reduce((items, { icon: name, onClick, ...iconProps }, index) => {
        const overrides = typeof iconProps.renderOverrides === 'function' ? iconProps.renderOverrides(values) : null
        const renderProps = overrides ? { ...iconProps, ...overrides } : iconProps

        const hasProperty = Object.prototype.hasOwnProperty.call(renderProps, 'shouldDisplay')
        // shouldDisplay
        if (!hasProperty || renderProps.shouldDisplay) {
          if (renderProps.viewOnly ? !isEditToggled : renderProps.editOnly ? isEditToggled : true) {
            items.push(
              <Icon
                key={index}
                name={name}
                {...renderProps}
                size={'lg'}
                onClick={() => onClick(values)}
              />
            )
          }
        }
        return items
      }, [])
    }
  }

  function getExtraButtons() {
    if (!Array.isArray(extraButtons)) {
      return []
    } else {
      const localState = { isEditToggled, hasChanged, validationErrors, getValues, modals: { isRemoveModalOpen, isUnarchiveModalOpen, isArchiveModalOpen }, handleOnRefresh, handleOnChange }
      return extraButtons.reduce((items, { caption, onClick, ...buttonProps }, index) => {
        const overrides = typeof buttonProps.renderOverrides === 'function'
          ? buttonProps.renderOverrides(values, localState)
          : null
        const renderProps = overrides ? { ...buttonProps, ...overrides } : buttonProps
        const hasProperty = Object.prototype.hasOwnProperty.call(renderProps, 'shouldDisplay')

        // shouldDisplay
        if (!hasProperty || renderProps.shouldDisplay) {
          // view on edit
          if (renderProps.viewOnly ? !isEditToggled : renderProps.editOnly ? isEditToggled : true) {
            items.push(
              <Button
                key={index}
                typeVariant={buttonProps.typeVariant || 'secondary'}
                {...renderProps}
                size={buttonProps.size || 'sm'}
                onClick={() => onClick(values, localState)}
              >
                {caption}
              </Button>
            )
          }
        }
        return items
      }, [])
    }
  }

  function getExtraSubmitButtons() {
    if (!Array.isArray(extraSubmitButtons)) {
      return []
    } else {
      return extraSubmitButtons.map((extraSubmitButton, index) => {
        const show = (extraSubmitButton.show && typeof extraSubmitButton.show === 'function')
          ? extraSubmitButton.show(values)
          : (extraSubmitButton.show ?? true)
        if (!show) return null

        const isDisabled = (extraSubmitButton.isDisabled && typeof extraSubmitButton.isDisabled === 'function')
          ? extraSubmitButton.isDisabled(values)
          : (extraSubmitButton.isDisabled || false)

        return (
          <Button
            key={index}
            disabled={isDisabled || false}
            typeVariant={extraSubmitButton.typeVariant || 'secondary'}
            size={extraSubmitButton.size || 'sm'}
            onClick={() => {
              extraSubmitButton.onClick(values, getValues(values))
            }}
          >
            {extraSubmitButton.caption}
          </Button>
        )
      })
    }
  }

  function getCustomConfirmationMessage(key, item) {
    const defaultMessage = `Are you sure you want to ${key} ${item?.value && item?.label ? item.label.toLowerCase() + ' ' + item.value : 'this item'}?`
    return objectUtils.callProp(customConfirmationRenderer?.[key], [{ defaultMessage }], defaultMessage)
  }

  return (
    <div className='details-page'>
      <div className="details-page__container">
        <div className="details-page__header">
          <div className="details-page__header-title-container">
            <div className='details-page__header-title-row' >
              <span className="details-page__header-title">{title?.label}</span>
              <div className="details-page__header-icons">
                {getEditIcon()}
                {getArchiveIcon()}
                {getUnarchiveIcon()}
                {getRemoveIcon()}
                {getExtraIcons()}
              </div>
            </div>
            <span className="details-page__header-title-value">{shortenId ? textUtils.shortenUUID(title?.value) : title?.value}</span>
          </div>
          {
            <div className="details-page__header-buttons">
              {getExtraButtons()}
              {
                canEdit
                  ? isEditToggled
                    ? <div className='details-page__header-save-btns'>
                      <Button
                        typeVariant='action'
                        size='sm'
                        onClick={handleOnCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        size='sm'
                        disabled={!canSubmit || !hasChanged}
                        onClick={handleOnSubmit}
                      >
                        Save
                      </Button>
                      {getExtraSubmitButtons()}
                    </div>
                    : null
                  : null
              }
            </div>
          }
        </div>
        <div className="details-page__content">
          {
            values
              .reduce((items, item, index) => {
                const overrides = typeof item.renderOverrides === 'function' ? item.renderOverrides(values) : null
                const renderItem = overrides ? { ...item, ...overrides } : item
                // filters the array of values based on
                // 1 - if shouldDisplay is omitted return true
                // 2 - if shouldDisplay property exists but value is true return true
                // 3 - if shouldDisplay property exists but value is false return false
                const hasProperty = Object.prototype.hasOwnProperty.call(renderItem, 'shouldDisplay')
                if (!hasProperty || (renderItem.shouldDisplay === 'editOnly' ? isEditToggled : renderItem.shouldDisplay)) {
                  items.push(
                    <div key={index} className={['span', 'fixed'].reduce((accum, curr) => renderItem[curr] ? accum + `details-page__content-item-card--${curr} ` : accum, '').trim() || 'details-page__content-item-card'}>
                      <FormInput
                        item={renderItem}
                        values={values}
                        disabled={!renderItem.canEdit || !isEditToggled}
                        onChange={handleOnChange}
                        isEditToggled={isEditToggled}
                        validationErrors={validationErrors}
                        setValidationErrors={setValidationErrors}
                        removeValidationErrors={removeValidationErrors}
                        {...propsByRef}
                      />
                    </div>
                  )
                }
                return items
              }, [])
          }
        </div>
        {
          modals.length
            ? <div>
              {modals}
            </div>
            : null
        }
      </div>
      <RouterPrompt
        when={isEditToggled && confirmPageExit}
      />
      <ConfirmationModal
        title='Archive'
        message={getCustomConfirmationMessage('archive', title)}
        onSubmit={onArchive || (() => undefined)}
        open={isArchiveModalOpen}
        onClose={() => setIsArchiveModalOpen(false)}
      />
      <ConfirmationModal
        title='Unarchive'
        message={getCustomConfirmationMessage('unarchive', title)}
        onSubmit={onUnarchive || (() => undefined)}
        open={isUnarchiveModalOpen}
        onClose={() => setIsUnarchiveModalOpen(false)}
      />
      <ConfirmationModal
        title='Remove'
        message={getCustomConfirmationMessage('remove', title)}
        onSubmit={onRemove || (() => undefined)}
        open={isRemoveModalOpen}
        onClose={() => setIsRemoveModalOpen(false)}
      />
    </div>
  )
}

DetailsPage.propTypes = {
  isEdit: PropTypes.bool,
  canEdit: PropTypes.bool,
  canSubmit: PropTypes.bool,
  title: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onSanitize: PropTypes.func,
  onArchive: PropTypes.func,
  onUnarchive: PropTypes.func,
  onRemove: PropTypes.func,
  onEditToggled: PropTypes.func,
  onHasValuesChanged: PropTypes.func,
  onRefresh: PropTypes.func,
  customConfirmationRenderer: PropTypes.object,
  customIconRenderer: PropTypes.object,
  fields: PropTypes.arrayOf(PropTypes.object),
  modals: PropTypes.arrayOf(PropTypes.object),
  extraIcons: PropTypes.arrayOf(PropTypes.object),
  extraButtons: PropTypes.arrayOf(PropTypes.object),
  extraSubmitButtons: PropTypes.arrayOf(PropTypes.object),
  propsByRef: PropTypes.object,
  validationProps: PropTypes.object,
  shortenId: PropTypes.bool
}

DetailsPage.defaultProps = {
  fields: [],
  title: null,
  isEdit: false,
  canEdit: false,
  onSanitize: (vals) => vals,
  onUnarchive: null,
  onRemove: null,
  onArchive: null,
  customConfirmationRenderer: {},
  customIconRenderer: {},
  modals: [],
  canSubmit: true,
  extraIcons: [],
  extraButtons: [],
  extraSubmitButtons: [],
  validationProps: {
    validateSchema: undefined,
    schemaContext: undefined,
    validationToast: undefined
  },
  shortenId: true
}

export default DetailsPage
