import React, { useEffect, useState } from 'react'
import { organizationActions, provenanceActions } from '@actions'
import { FormComponents } from '@organisms'
import { Dropdown as VanillaDropdown } from '@molecules'
import { useAuthStore, useGlobalsStore } from '@stores'
import { useToast } from '@hooks'
import { useHistory } from 'react-router-dom'

function CreateBatch() {
  const {
    SimpleForm,
    TextInput,
    Dropdown,
    Button
  } = FormComponents

  const { showSuccessToast } = useToast()

  const { orgId: userOrgId, hasAdmin, permissionsAdminCache } = useAuthStore(state => state)

  const provParams = { condition: 'ACTIVE' }
  const mineParams = { ...provParams, ...(!hasAdmin(provenanceActions.getMines) && { sellerId: userOrgId }) }

  const {
    getOrgsList,
    orgsList: { [JSON.stringify({ condition: 'ACTIVE' })]: orgsList },
    getMinesList,
    getPipesList,
    resetBatchesList,
    minesList: { [JSON.stringify(mineParams)]: minesList = [] },
    pipesList: { [JSON.stringify(provParams)]: pipesList = [] }
  } = useGlobalsStore(store => store)

  useEffect(() => {
    if (!permissionsAdminCache) return
    getOrgsList({ condition: 'ACTIVE' })
    getMinesList(mineParams)
    getPipesList(provParams)
  }, [permissionsAdminCache])

  const [mineId, setMineId] = useState(null)

  const history = useHistory()
  function handleSubmit(values) {
    const { mineId, dateStart, dateEnd, ...batch } = values
    batch.dateStart = new Date(dateStart)
    batch.dateEnd = new Date(dateEnd)
    for (const key in batch) {
      if (batch[key] === '') delete batch[key]
    }

    provenanceActions.createBatch(batch)
      .then(async () => {
        showSuccessToast('Batch added.')
        resetBatchesList()
        history.push('/seller-settings')
      })
      .catch(err => console.error(err))
  }

  const renderPipeList = pipesList?.filter(pipe => pipe.mineId === mineId)

  return (
    <div className='create-batch'>
      <div className="col-xl-4 col-lg-7 col-md-10 col-sm-12">
        <SimpleForm
          title='New Batch'
          onSubmit={handleSubmit}
          name='createBatch'
          initialValues={{ sellerId: userOrgId }}
        >
          <TextInput
            name='name'
            label='Name'
          />
          <VanillaDropdown
            name='mineId'
            label='Mine'
            options={minesList.map(mine => ({
              label: mine.name,
              value: mine.id
            }))}
            required={true}
            value={mineId}
            onChange={e => setMineId(e?.currentTarget?.value)}
          />
          <Dropdown
            name='pipeId'
            label='Pipe'
            options={renderPipeList.map(pipe => ({
              label: pipe.name,
              value: pipe.id
            }))}
          />
          {hasAdmin(organizationActions.getOrganizationList)
            && <Dropdown
              name='sellerId'
              label='Seller'
              options={orgsList?.map(org => ({
                label: org.commonName,
                value: org.orgId
              }))}
            />
          }
          <TextInput
            name='dateStart'
            label='Start Date'
            type='date'
          />
          <TextInput
            name='dateEnd'
            label='End Date'
            type='date'
          />
          <Button typeVariant="action" onClick={() => history.goBack()}>
            Cancel
          </Button>
          <Button
            type='submit'
            size='sm'
          >
            Create Batch
          </Button>
        </SimpleForm>
      </div>
    </div>
  )
}

export default CreateBatch
