import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@atoms'
import PlanningCategoryModal from './planning_category_modal'
import { useGlobalsStore } from '@stores'

function UpdateInclusionDetails({
  handleSubmit,
  handleCancel
}) {
  const [inclusionDetails, setInclusionDetails] = useState({})
  const {
    inclusionTypes,
    getInclusionTypes,
    inclusionReductions,
    getInclusionReductions
  } = useGlobalsStore(store => store)

  useEffect(() => {
    getInclusionReductions()
    getInclusionTypes()
  }, [])

  function handleInclusionDetailsChange(e) {
    const { name, value } = e.currentTarget
    setInclusionDetails({ ...inclusionDetails, [name]: value })
  }

  return (
    <div>
      <PlanningCategoryModal
        onChange={handleInclusionDetailsChange}
        inclusionTypes={inclusionTypes}
        inclusionReductions={inclusionReductions}
        inclusionDetails={inclusionDetails}
      />
      <div className='modal__buttons'>
        <Button typeVariant='secondary' size='sm' onClick={handleCancel}>Cancel</Button>
        <Button typeVariant='primary' size='sm' onClick={() => handleSubmit(inclusionDetails)}>Save</Button>
      </div>
    </div>
  )
}

UpdateInclusionDetails.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func
}

UpdateInclusionDetails.defaultProps = {
}

export default UpdateInclusionDetails
