import React, { useState, useEffect, useMemo } from 'react'
import { Table } from '@organisms'
import { eventActions } from '@actions'
import { NavLink, useHistory } from 'react-router-dom'
import { useToast } from '@hooks'
import { arrayUtils } from '@utils'

function EventList() {
  const [eventList, setEventList] = useState([])
  const [eventTypes, setEventTypes] = useState([])
  const history = useHistory()
  const { showSuccessToast } = useToast()

  useEffect(() => {
    getEventsList()
    getEventTypes()
  }, [])

  const eventTypesMap = useMemo(() => arrayUtils.toMap(eventTypes ?? [], x => x.id, 'name'), [eventTypes])

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      dataType: 'string',
      Cell: cellInfo => {
        return (
          <NavLink
            className="link"
            to={`/events/${cellInfo.row.original.id}`}
            id={cellInfo.row.original.id}
          >
            {cellInfo.value}
          </NavLink>
        )
      }
    },
    {
      Header: 'Event Type',
      id: 'eventType',
      accessor: row => eventTypesMap[row.typeId] ?? '',
      filterType: 'checkbox',
      enums: Object.values(eventTypesMap)
    },
    {
      Header: 'Start Time',
      accessor: 'startTime',
      dataType: 'date',
      filterType: 'date'
    },
    {
      Header: 'End Time',
      accessor: 'endTime',
      dataType: 'date',
      filterType: 'date'
    },
    {
      Header: 'Description',
      accessor: 'description'
    },
    {
      Header: 'Group ID',
      accessor: 'groupId'
    },
    {
      Header: 'Visible',
      accessor: 'visible',
      dataType: 'boolean',
      filterType: 'checkbox'
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      dataType: 'date',
      filterType: 'date'
    },
    {
      Header: 'Last Updated',
      accessor: 'updatedAt',
      dataType: 'date',
      filterType: 'date'
    }
  ]

  const topBarActions = [{
    label: 'Create Event',
    callback: () => history.push('/events/create')
  }]

  const rowActions = [
    {
      actionName: 'remove',
      callback: (row) => handleRemove([row.id]),
      shouldConfirm: true
    }
  ]

  function getEventsList() {
    eventActions.getEvents()
    .then(response => setEventList(response.data.data))
  }

  function getEventTypes() {
    eventActions.getEventTypes()
    .then(response => setEventTypes(response.data.data))
  }

  function handleRemove(id) {
    eventActions.setEventCondition(id, 'DELETED')
    .then(() => {
      showSuccessToast('Event removed.')
      getEventsList()
    })
  }

  const initialSort = [{
    id: 'startTime',
    desc: true
  }]

  return (
    <Table
      title='Events'
      data={eventList}
      columns={columns}
      isMultiSelect={false}
      rowActions={rowActions}
      initialSort={initialSort}
      topBarActions={topBarActions}
    />
  )
}

export default EventList
