import React, { useState, useEffect } from 'react'
import { Table } from '@organisms'
import { notificationsActions } from '@actions'
import { NavLink, useHistory } from 'react-router-dom'

function EmailNotificationList() {
  const [emailNotificationList, setEmailNotificationList] = useState([])
  const history = useHistory()

  useEffect(() => {
    fetchData()
  }, [])
  function fetchData() {
    notificationsActions.getEmailNotifications()
    .then(response => setEmailNotificationList(response.data.data))
  }

  const columns = [
    {
      accessor: 'subject',
      Header: 'Subject',
      Cell: cellInfo => {
        return (
          <NavLink
            className="link"
            to={`/notifications/email/${cellInfo.row.original.emailId}`}
            id={cellInfo.row.original.emailId}
          >
            {cellInfo.value}
          </NavLink>
        )
      },
      filterType: 'checkbox'
    },
    {
      accessor: 'from',
      Header: 'From',
      filterType: 'checkbox'
    },
    {
      accessor: 'tos',
      Header: 'Recipients'
    },
    {
      accessor: row => !!row?.sentMessageId,
      Header: 'Sent',
      dataType: 'boolean',
      filterType: 'checkbox'
    },
    {
      accessor: 'sendTime',
      Header: 'Send Time',
      dataType: 'dateTime',
      filterType: 'date'
    },
    {
      accessor: row => row.statusUpdates ? renderStatusUpdates(row.statusUpdates) : '',
      Header: 'Status Updates'
    },
    {
      accessor: 'createdAt',
      Header: 'Created At',
      dataType: 'date',
      filterType: 'date'
    },
    {
      accessor: 'updatedAt',
      Header: 'Updated At',
      dataType: 'date',
      filterType: 'date'
    }
  ]

  function renderStatusUpdates(statusUpdates) {
    return Object.keys(statusUpdates).map(update => {
      return `${update}: ${JSON.stringify(statusUpdates[update])}`
    }).join(' | ')
  }

  const topBarActions = [
    {
      label: 'Create Notification',
      callback: () => history.push('/notifications/create')
    },
    {
      label: 'Notification Templates',
      callback: () => history.push('/notifications/templates')
    }
  ]

  return (
    <Table
      title='Notifications'
      data={emailNotificationList}
      columns={columns}
      getDataCallback={fetchData}
      topBarActions={topBarActions}
      isMultiSelect={false}
    />
  )
}

export default EmailNotificationList
