import React from 'react'
import { TextInput, Dropdown } from '@molecules'
import { Form } from '@organisms'
import CreateRoughStoneBtn from '../inc/createRoughStoneBtn'
import NumberFormat from 'react-number-format'

function PriceWeightStep({ assortment, weightCategories, pricePoints, ...props }) {
  const { validationSchemaError } = props.useMultiSteps(props)

  return (
    <Form title={`New rough stone for assortment ${assortment.name}`} name="New rough stone">
      <Dropdown
        size="lg"
        name="pricePoint"
        label="Price point"
        creatable={true}
        value={props.getState('pricePoint')}
        onChange={e => props.setState('pricePoint', e?.currentTarget?.value || '')}
        options={pricePoints.map(pricePoint => {
          return {
            label: pricePoint,
            value: pricePoint
          }
        })}
        validationText={validationSchemaError?.parseStep('pricePoint')}
      />
      <Dropdown
        size="lg"
        name="weightCategory"
        label="Weight category"
        creatable={true}
        value={props.getState('weightCategory')}
        onChange={e => props.setState('weightCategory', e?.currentTarget?.value || '')}
        options={weightCategories.map(weightCategory => {
          return {
            label: weightCategory,
            value: weightCategory
          }
        })}
        validationText={validationSchemaError?.parseStep('weightCategory')}

      />
      <TextInput
        size="lg"
        label='Weight'
        name="weight"
        type="number"
        decimalScale={3}
        value={props.getState('weight')}
        onChange={e => props.setState('weight', e.currentTarget.value)}
        validationText={validationSchemaError?.parseStep('weight')}
      />
      <TextInput
        size="lg"
        label='Reserve Price'
        name="reservePpcOriginal"
        type="number"
        value={props.getState('reservePpcOriginal')}
        onChange={e => props.setState('reservePpcOriginal', e.currentTarget.value)}
        validationText={validationSchemaError?.parseStep('reservePpcOriginal')}
      />
      <div className="create-rough-stone__total-price">
        <span className="name">Total price (USD): </span>
        <span className="value">
          <NumberFormat
            displayType='text'
            prefix=' $'
            thousandSeparator={true}
            decimalScale={2}
            value={props.getState('reservePpcOriginal') * props.getState('weight') || 0}
          />
        </span>
      </div>
      <CreateRoughStoneBtn {...props} />
    </Form>
  )
}

export default PriceWeightStep
