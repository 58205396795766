import React from 'react'
import { Icon } from '@atoms'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'

function NotificationModal({
  body,
  title,
  isOpen,
  setOpen,
  iconName,
  notifyTime,
  titleClassName
}) {
  return (
    <Popup
      open={isOpen}
      position="right center"
      onClose={() => setOpen(false)}
      nested={true}
    >
      <div className="notification__modal">
        <div className={titleClassName}>
          <Icon name={iconName} />
          {title}
          <div
            className="notification__modal-close-button"
            onClick={() => setOpen(false)}
          >
            <Icon name='close' />
          </div>
        </div>
        <div className="notification__modal-body">
          {body}
        </div>
        <div className="notification__modal-notify-time">
          {notifyTime}
        </div>
      </div>
    </Popup>
  )
}

NotificationModal.propTypes = {
  isOpen: PropTypes.bool,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  title: PropTypes.string,
  iconName: PropTypes.string,
  notifyTime: PropTypes.string,
  titleClassName: PropTypes.string,
  setOpen: PropTypes.func.isRequired
}

NotificationModal.defaultProps = {
  body: '',
  title: '',
  iconName: '',
  notifyTime: '',
  titleClassName: ''
}

export default NotificationModal
