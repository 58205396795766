import React, { useState, useEffect } from 'react'
import { Tabs } from '@templates'
import ShapeList from './list/shapeList'
import CutGradeList from './list/cutGradeList'
import BaselineList from './list/baselineList'
import TingeMapList from './list/tingeMapList'
import CostList from './list/costList'
import { useGlobalsStore, useAuthStore } from '@stores'
import { costActions } from '@actions'

function BuyerSettings(props) {
  const { getOrgById } = useGlobalsStore()
  const { currentAuthenticatedUser, hasAdmin, permissionsAdminCache } = useAuthStore(state => state)
  const isAdmin = hasAdmin(costActions.getCertCostList) && hasAdmin(costActions.getMfgCostList)
  const [showCosts, setShowCosts] = useState(false)
  useEffect(() => {
    if (!permissionsAdminCache?.size) return
    getOrgById(currentAuthenticatedUser?.attributes?.['custom:org_id'])
    .then(buyer => {
      setShowCosts(isAdmin || buyer?.showCosts)
    })
  }, [permissionsAdminCache])

  const [tabItems, setTabItems] = useState([])
  useEffect(() => {
    setTabItems(
      [
        {
          title: 'Shapes',
          Component: ShapeList
        },
        {
          title: 'Cut Grades',
          Component: CutGradeList
        },
        {
          title: 'Baselines',
          Component: BaselineList
        },
        {
          title: 'Tinge Maps',
          Component: TingeMapList
        },
        ...(showCosts
          ? [{
            title: 'Manufacturing Costs',
            Component: () => <CostList costType='mfg' costName='Manufacturing' />
          }, {
            title: 'Certificate Costs',
            Component: () => <CostList costType='cert' costName='Certificate' />
          }] : [])
      ]
    )
  }, [showCosts])

  return (
    <div className="buyer-settings">
      <h2>Buyer Settings</h2>
      <Tabs
        name='buyerSettings'
        items={tabItems}
      />
    </div>
  )
}

export default BuyerSettings
