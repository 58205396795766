import React, { useState, useEffect } from 'react'
import { baselineActions, organizationActions } from '@actions'
import { useToast } from '@hooks'
import { useAuthStore, useGlobalsStore } from '@stores'
import { FormComponents } from '@organisms'
import ColourMixGrid from '../colourMixGrid'
import { Field } from 'formik'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { InfoTip } from '@molecules'
import { textUtils } from '@utils'

function CreateComputedBaseline(props) {
  const {
    SimpleForm,
    Dropdown,
    TextInput,
    Button
  } = FormComponents

  const { orgId: userOrgId, hasAdmin } = useAuthStore(store => store)
  const isAdmin = hasAdmin(organizationActions.getOrganizationList)

  const [baselinesList, setBaselinesList] = useState([])
  useEffect(() => {
    baselineActions.getBaselines({ condition: 'ACTIVE' }).then(response => setBaselinesList(response.data.data?.sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt))))
  }, [])

  const [selectedVersion, setSelectedVersion] = useState(null)

  const {
    orgsList: { [JSON.stringify({ condition: 'ACTIVE' })]: orgsList },
    getOrgsList,
    polishedColours: colours,
    getPolishedColours,
    baselineShapeTypesMap,
    getBaselineShapeTypes
  } = useGlobalsStore()
  useEffect(() => {
    getOrgsList({ condition: 'ACTIVE' })
    getPolishedColours()
    getBaselineShapeTypes()
  }, [])

  const [selectedBuyer, setSelectedBuyer] = useState(userOrgId)
  const buyerBaselines = baselinesList.filter(b => b.buyerId === selectedBuyer && !b.parentBaselineId)

  function handleFormChange(previousValues, e) {
    const target = e.currentTarget || e.target
    if (target?.name === 'buyerId') setSelectedBuyer(target.value)
    if (target?.name === 'name' || target?.name === 'buyerId') {
      const formValues = { ...previousValues, [target.name]: target.value }
      setSelectedVersion(baselinesList.find(b => b.name === formValues.name && b.buyerId === formValues.buyerId))
    }
  }

  const { showSuccessToast } = useToast()
  const history = useHistory()
  function handleSubmit(values) {
    baselineActions.createBaseline(values)
    .then(() => {
      showSuccessToast('Baseline created')
      history.push('/buyer-settings')
    })
  }

  if (!colours?.length) return null // otherwise it would set the initialValues with incorrect colourMix

  return (
    <div className="center">
      <SimpleForm
        onSubmit={handleSubmit}
        title='Create Color Calc Baseline'
        name='createBaseline'
        initialValues={{ buyerId: userOrgId, colourMix: colours?.reduce((mix, c) => ({ ...mix, [c.value]: { [c.rapColour]: 1 } }), {}) }}
        extraOnChange={handleFormChange}
      >
        {isAdmin
        && <Dropdown
          name='buyerId'
          label='Owner'
          options={orgsList?.map(org => ({ value: org.orgId, label: org.commonName }))}
        />
        }
        <TextInput
          name='name'
          label='Name'
        />
        <Dropdown
          name='parentBaselineId'
          label={<>Parent Baseline <InfoTip name='createBaseline'/></>}
          options={buyerBaselines.map(b => ({ value: b.id, label: `${b.name} | ${baselineShapeTypesMap?.[b.shapeType] ?? ''}${b.date ? ' | ' + textUtils.formatDate(b.date) : ''}` }))}
        />
        <Field
          name='colourMix'
          validate={ColourMixGrid.validate}
        >
          {({ field, form }) => (
            <ColourMixGrid
              name={field.name}
              value={field.value}
              onChange={(...params) => {
                if (typeof props.extraOnChange === 'function') props.extraOnChange(form.values, ...params)
                return field.onChange(...params)
              }}
              required={props.required}
              colours={colours}
              // rapColours={Object.keys((colours || []).reduce((obj, c) => ({ ...obj, [c.rapColour]: true }), {}))}
              form={form}
              validationObject={form.errors[field.name] || null}
            />
          )}
        </Field>
        <Button
          typeVariant="action"
          onClick={() => history.push('/buyer-settings')}
        >
            Cancel
        </Button>
        <Button
          type='submit'
          disabled={!!selectedVersion}
        >
          Create Color Calc Baseline
        </Button>
      </SimpleForm>
    </div>
  )
}

export default CreateComputedBaseline
