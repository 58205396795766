import text from './text'
import { COMMON } from '@constants'

class ArrayError extends Error {
  constructor(message = [], type = 'error', ...params) {
    super(message, ...params)
    this.name = 'ArrayError'
    this.messagesArray = message
    this.type = type
  }

  getUniqueMessages() {
    return Array.from(new Set(this.messagesArray))
  }
}
class ModalError extends Error {
  constructor(message, type = 'error', ...params) {
    super(message, ...params)
    this.name = 'ModalError'
    this.type = type
  }
}

function getErrorSummary(err = {}) {
  const { base = COMMON.ERROR_SUMMARY, ...fields } = err
  return text.substituteConstantTemplateStr(base, { ...fields })
}

export default { ArrayError, ModalError, getErrorSummary }
