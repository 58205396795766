import React from 'react'
import PropTypes from 'prop-types'
import { FormComponents } from '@organisms'
const { SimpleForm, Checkbox, Button } = FormComponents

function SendForPlanningForm({ ids, handleSubmit }) {
  return (
    <SimpleForm
      initialValues={{ highPriority: false }}
      onSubmit={handleSubmit}
    >
      Are you sure you want to send the selected {ids?.length > 1 ? ` ${ids?.length}` : ''} stone{ids?.length > 1 ? 's' : ''} for generic planning?
      <br/><br/>
      <Checkbox
        name="highPriority"
        id="highPriority"
        label={<>Use High Priority Queue? <b>(Should only be used during sales)</b></>}
      />
      <div key='row-buttons' className='modal__buttons'>
        <Button type='submit' typeVariant='primary' size='sm'>Submit</Button>
      </div>
    </SimpleForm>
  )
}

SendForPlanningForm.propTypes = {
  ids: PropTypes.array,
  handleSubmit: PropTypes.func
}

SendForPlanningForm.defaultProps = {
  ids: []
}

export default SendForPlanningForm
