import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { InfoTip } from '@molecules'
import { objectUtils } from '@utils'
import { useGlobalsStore } from '@stores'

const { filterNullish, pick, isEmpty } = objectUtils

function PolishedStoneValueInfoTip({
  polishedStone
}) {
  const {
    roughColoursMap: coloursMap,
    claritiesMap,
    getRoughColours,
    getClarities
  } = useGlobalsStore(state => state)
  useEffect(() => {
    getRoughColours()
    getClarities()
  }, [])

  const priceBreakdown = Object.entries(polishedStone?.priceBreakdown).reduce((accum, [currKey, currVal]) => ({ ...accum, [currKey]: isNaN(Number(currVal)) ? currVal : Number(currVal) }), {})
  if (!priceBreakdown.basePpc) {
    return null
  }
  const baselineDiscount = (priceBreakdown.baseDiscount / 100 * priceBreakdown.basePpc * -1).toFixed(2)
  const fluorDiscount = ((priceBreakdown.basePpc - baselineDiscount) * priceBreakdown.fluorDiscount / 100 * -1).toFixed(2)
  const marginDiscount = ((priceBreakdown.basePpc - baselineDiscount - fluorDiscount) * priceBreakdown.margin / 100).toFixed(2)
  const afterDiscBasePpc = (priceBreakdown.basePpc - baselineDiscount - fluorDiscount - marginDiscount).toFixed(2)
  const polishedWeight = Number(polishedStone.polishedWeight).toFixed(2)
  const polishedPriceB4Costs = (afterDiscBasePpc * polishedWeight).toFixed(2)

  const baseDiscountWithFlu = (priceBreakdown.baseDiscount + priceBreakdown.fluorDiscount + (priceBreakdown.baseDiscount * priceBreakdown.fluorDiscount / 100)).toFixed(2)
  const baseDiscountWithMargin = (baseDiscountWithFlu - priceBreakdown.margin + (-1 * baseDiscountWithFlu * priceBreakdown.margin / 100)).toFixed(2)

  const totalCosts = (priceBreakdown.mfgCost + priceBreakdown.certCost).toFixed(2)

  const lineBreakdown = filterNullish(pick(priceBreakdown, ['rangeWeight', 'colour', 'clarity', 'pricedWithColour'], false))
  return (
    <InfoTip name={`transactionPolishedStone${polishedStone.id}`}>
      <>
        <table style={{ lineHeight: '15px', fontWeight: '300' }}>
          <tbody>
            <tr>
              <td style={{ width: '170px' }}></td>
              <td style={{ width: '220px' }}>PPC Price</td>
              <td style={{ width: '150px' }}>Total Discount<br/>below Baseline</td>
            </tr>
            <tr>
              <td>Base Price</td>
              <td>${priceBreakdown.basePpc}/ct</td>
              <td></td>
            </tr>
            {priceBreakdown.baseDiscount
              ? <tr>
                <td>- Baseline Discount</td>
                <td>-${baselineDiscount}/ct ({priceBreakdown.baseDiscount.toFixed(2)}%)</td>
                <td>{priceBreakdown.baseDiscount.toFixed(2)}</td>
              </tr>
              : null}
            {priceBreakdown.fluorDiscount
              ? <tr>
                <td>- Fluorescence Discount</td>
                <td>-${fluorDiscount}/ct ({priceBreakdown.fluorDiscount.toFixed(2)}%)</td>
                <td>{baseDiscountWithFlu}</td>
              </tr>
              : null}
            {priceBreakdown.margin
              ? <tr>
                <td>- Margin</td>
                <td>-${marginDiscount}/ct (-{priceBreakdown.margin.toFixed(2)}%)</td>
                <td>{baseDiscountWithMargin}</td>
              </tr>
              : null}
            {afterDiscBasePpc !== priceBreakdown.basePpc.toFixed(2) ? <tr>
              <td>After Discounts</td>
              <td>${afterDiscBasePpc}/ct</td>
              <td>{baseDiscountWithMargin}</td>
            </tr>
              : null}
            <tr style={{ fontWeight: '500' }}>
              <td>Polished Stone Price</td>
              <td>${polishedPriceB4Costs} ({polishedStone.polishedWeight} x {afterDiscBasePpc})</td>
              <td></td>
            </tr>
            {priceBreakdown.mfgCost || priceBreakdown.certCost
              ? <tr>
                <td>- Costs (Mfg & Cert)</td>
                <td>-${totalCosts} ({priceBreakdown.mfgCost} + {priceBreakdown.certCost})</td>
                <td></td>
              </tr>
              : null}
            {priceBreakdown.mfgCost || priceBreakdown.certCost
              ? <tr style={{ fontWeight: '500', borderTop: 'solid white 2px' }}>
                <td>Purchase Price</td>
                <td>${(polishedPriceB4Costs - totalCosts).toFixed(2)}</td>
                <td>{((polishedStone.buyerPrice / (priceBreakdown.basePpc * polishedWeight) - 1) * 100).toFixed(2)}</td>
              </tr>
              : null}
          </tbody>
        </table>
        {!isEmpty(lineBreakdown) && <div style={{ fontWeight: '400', textAlign: 'left', marginTop: '12px' }}>* Base price applied from weight: {lineBreakdown?.rangeWeight}, color: {coloursMap?.[lineBreakdown?.pricedWithColour ?? lineBreakdown?.colour]}, clarity: {claritiesMap?.[lineBreakdown?.clarity]}</div>}
      </>
    </InfoTip>
  )
}

PolishedStoneValueInfoTip.propTypes = {
  polishedStone: PropTypes.object.isRequired
}

PolishedStoneValueInfoTip.defaultProps = {
}

export default PolishedStoneValueInfoTip
