import React, { useState, useEffect, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  assortmentActions,
  pricePointActions,
  weightCategoryActions,
  roughStoneActions,
  organizationActions,
  constantsActions,
  provenanceActions,
  planningActions,
  settingsActions
} from '@actions'
import { Icon, Button } from '@atoms'
import { useGlobalsStore, useAuthStore, useRoughStoneStore, usePlanningStore } from '@stores'
import { numberUtils, errorUtils, arrayUtils } from '@utils'
import { FileUpload } from '@organisms'
import { useToast } from '@hooks'
import { SELLER } from '@constants'
import CreateMultipleRoughs from './createMultipleRoughs'
import { EditMultipleRoughs, QCEditMultipleRoughs } from './editMultipleRoughs'
import { parseExcelFile, uploadFieldFilters } from '../roughStoneUtils'
import RoughTemplateDownloadModal from './roughTemplateDownloadModal'

const { ROUGH_LOCATIONS } = SELLER
const columns = ({ roughColoursMap, roughTingesMap, polishedColoursMap, polishedFluorescencesMap, polishedTingesMap }, hasAdmin, hasPermission) => (keys = []) => {
  const hasKey = (key) => !keys?.length || keys.includes(key)
  return [
    ...(hasKey('id') ? [{
      Header: 'ID',
      accessor: 'id',
      dataType: 'string'
    }] : []),
    ...(hasKey('sellerStoneName') ? [{
      Header: 'Stone Name',
      accessor: 'sellerStoneName',
      dataType: 'string',
      Cell: ({ column: { id }, cell: { value, row: { original: { updates = {}, existing = {} } } } }) => {
        if (!(id in updates) || value === existing[id]) return value ?? existing[id] ?? null
        return <div className="save-multiple-roughs__table-cell-value--new">{value}</div>
      }
    }] : []),
    ...(hasKey('assortmentName') ? [{
      Header: 'Assortment',
      accessor: 'assortmentName',
      dataType: 'string'
    }] : []),
    ...(hasKey('statusName') ? [{
      Header: 'Status',
      accessor: 'statusName',
      dataType: 'string'
    }] : []),
    ...(hasKey('weight') ? [{
      Header: 'Weight',
      id: 'weight',
      accessor: row => numberUtils.numFmt(row.weight, 3),
      dataType: 'string',
      Cell: ({ column: { id }, cell: { value, row: { original: { updates = {}, existing = {} } } } }) => {
        if (!(id in updates) || Number(updates[id]) === Number(existing[id])) return value ?? existing[id] ?? null
        return <div className="save-multiple-roughs__table-cell-value--new">{value}</div>
      }
    }] : []),
    ...(hasKey('reservePpcOriginal') ? [{
      Header: 'Reserve Price',
      accessor: 'reservePpcOriginal',
      dataType: 'number',
      Cell: ({ column: { id }, cell: { value, row: { original: { updates = {}, existing = {} } } } }) => {
        if (!(id in updates) || Number(updates[id]) === Number(existing[id])) return value ?? existing[id] ?? null
        return <div className="save-multiple-roughs__table-cell-value--new">{value}</div>
      }
    }] : []),
    ...(hasKey('reservePpcOverride') ? [{
      Header: 'Override Reserve Price',
      accessor: 'reservePpcOverride',
      dataType: 'number',
      Cell: ({ column: { id }, cell: { value, row: { original: { updates = {}, existing = {} } } } }) => {
        if (!(id in updates) || Number(updates[id]) === Number(existing[id])) return value ?? existing[id] ?? null
        return <div className="save-multiple-roughs__table-cell-value--new">{value}</div>
      }
    }] : []),
    ...(hasKey('colourName') ? [{
      Header: 'Color',
      accessor: 'colourName',
      dataType: 'string'
    }] : []),
    ...(hasKey('fluorescenceName') ? [{
      Header: 'Fluorescence',
      accessor: 'fluorescenceName',
      dataType: 'string'
    }] : []),
    ...(hasKey('tingeName') ? [{
      Header: 'Tinge',
      accessor: 'tingeName',
      dataType: 'string'
    }] : []),

    ...(hasKey('measurements') ? [{
      Header: 'Measurements',
      accessor: 'measurements',
      dataType: 'string',
      Cell: ({ column: { id }, cell: { value, row: { original: { updates = {}, existing = {} } } } }) => {
        if (!value || !value.length) return ''
        return (
          <ul>
            {value.map((x, i) => {
              const update = updates?.[id]?.[i]
              const exist = existing?.[id]?.[i]
              return (
                <li key={i}>
                  <span className={(update?.colour != null && update?.colour !== exist?.colour) ? 'save-multiple-roughs__table-cell-value--new' : ''}>{roughColoursMap[x.colour] || x.colour}</span>,
                  <span className={(update?.fluorescence != null && update?.fluorescence !== exist?.fluorescence) ? 'save-multiple-roughs__table-cell-value--new' : ''}>{x.fluorescence}</span>,
                  <span className={(update?.tinge != null && update?.tinge !== exist?.tinge) ? 'save-multiple-roughs__table-cell-value--new' : ''}>{roughTingesMap[x.tinge] || x.tinge}</span>
                </li>
              )
            })}
          </ul>
        )
      }
    }] : []),
    ...(hasKey('eyeMeasurement') ? [{
      Header: 'Eye Measurement',
      accessor: 'eyeMeasurement',
      dataType: 'string',
      Cell: ({ column: { id }, cell: { value, row: { original: { updates = {}, existing = {} } } } }) => {
        if (!value) return ''
        return (
          <span>
            <span className={updates?.[id]?.colour != null && updates?.[id]?.colour !== existing?.[id]?.colour ? 'save-multiple-roughs__table-cell-value--new' : ''}>{polishedColoursMap?.[value.colour] || value.colour || ''}</span>,
            <span className={updates?.[id]?.fluorescence != null && updates?.[id]?.fluorescence !== existing?.[id]?.fluorescence ? 'save-multiple-roughs__table-cell-value--new' : ''}>{polishedFluorescencesMap?.[value.fluorescence] || value.fluorescence || ''}</span>,
            <span className={updates?.[id]?.tinge != null && updates?.[id]?.tinge !== existing?.[id]?.tinge ? 'save-multiple-roughs__table-cell-value--new' : ''}>{polishedTingesMap?.[value.tinge] || value.tinge || ''}</span>
          </span>
        )
      }
    }] : []),
    ...(hasKey('overrideMeasurement') && hasAdmin(roughStoneActions.editRoughStone) ? [{
      Header: 'Override Measurement',
      accessor: 'overrideMeasurement',
      dataType: 'string',
      Cell: ({ column: { id }, cell: { value, row: { original: { updates = {}, existing = {} } } } }) => {
        if (!value) return ''
        return (
          <span>
            <span className={updates?.[id]?.colour != null && updates?.[id]?.colour !== existing?.[id]?.colour ? 'save-multiple-roughs__table-cell-value--new' : ''}>{polishedColoursMap?.[value.colour] || value.colour || ''}</span>,
            <span className={updates?.[id]?.fluorescence != null && updates?.[id]?.fluorescence !== existing?.[id]?.fluorescence ? 'save-multiple-roughs__table-cell-value--new' : ''}>{polishedFluorescencesMap?.[value.fluorescence] || value.fluorescence || ''}</span>,
            <span className={updates?.[id]?.tinge != null && updates?.[id]?.tinge !== existing?.[id]?.tinge ? 'save-multiple-roughs__table-cell-value--new' : ''}>{polishedTingesMap?.[value.tinge] || value.tinge || ''}</span>
          </span>
        )
      }
    }] : []),
    ...(hasKey('countryName') ? [{
      Header: 'Country',
      accessor: 'countryName',
      dataType: 'string',
      Cell: ({ column: { id }, cell: { value, row: { original: { updates = {}, existing = {} } } } }) => {
        if (!(id in updates) || value === existing[id]) return value ?? existing[id] ?? null
        return <div className="save-multiple-roughs__table-cell-value--new">{value}</div>
      }
    }] : []),
    ...(hasKey('mineName') ? [{
      Header: 'Mine',
      accessor: 'mineName',
      dataType: 'string',
      Cell: ({ column: { id }, cell: { value, row: { original: { updates = {}, existing = {} } } } }) => {
        if (!(id in updates) || value === existing[id]) return value ?? existing[id] ?? null
        return <div className="save-multiple-roughs__table-cell-value--new">{value}</div>
      }
    }] : []),
    ...(hasKey('pipeName') ? [{
      Header: 'Pipe',
      accessor: 'pipeName',
      dataType: 'string',
      Cell: ({ column: { id }, cell: { value, row: { original: { updates = {}, existing = {} } } } }) => {
        if (!(id in updates) || value === existing[id]) return value ?? existing[id] ?? null
        return <div className="save-multiple-roughs__table-cell-value--new">{value}</div>
      }
    }] : []),
    ...(hasKey('batchName') ? [{
      Header: 'Batch',
      accessor: 'batchName',
      dataType: 'string',
      Cell: ({ column: { id }, cell: { value, row: { original: { updates = {}, existing = {} } } } }) => {
        if (!(id in updates) || value === existing[id]) return value ?? existing[id] ?? null
        return <div className="save-multiple-roughs__table-cell-value--new">{value}</div>
      }
    }] : []),
    ...(hasKey('scanTypeName') ? [{
    // TODO: transform data and build scan type property
      Header: 'Scan type',
      accessor: 'scanTypeName',
      dataType: 'string',
      Cell: ({ column: { id }, cell: { value, row: { original: { updates = {}, existing = {} } } } }) => {
        if (!(id in updates) || value === existing[id]) return value ?? existing[id] ?? null
        return <div className="save-multiple-roughs__table-cell-value--new">{value}</div>
      }
    }] : []),
    ...(hasKey('weightCategory') ? [{
      Header: 'Weight Category',
      accessor: 'weightCategory',
      dataType: 'string',
      Cell: ({ column: { id }, cell: { value, row: { original: { updates = {}, existing = {} } } } }) => {
        if (!(id in updates) || value === existing[id]) return value ?? existing[id] ?? null
        return <div className="save-multiple-roughs__table-cell-value--new">{value}</div>
      }
    }] : []),
    ...(hasKey('pricePoint') ? [{
      Header: 'Price Point',
      accessor: 'pricePoint',
      dataType: 'string',
      Cell: ({ column: { id }, cell: { value, row: { original: { updates = {}, existing = {} } } } }) => {
        if (!(id in updates) || value === existing[id]) return value ?? existing[id] ?? null
        return <div className="save-multiple-roughs__table-cell-value--new">{value}</div>
      }
    }] : []),
    ...(hasKey('inclusionsTypeName') ? [{
      Header: 'Inclusions Type',
      accessor: 'inclusionsTypeName',
      dataType: 'string',
      Cell: ({ column: { id }, cell: { value, row: { original: { updates = {}, existing = {} } } } }) => {
        if (!(id in updates) || value === existing[id]) return value ?? existing[id] ?? null
        return <div className="save-multiple-roughs__table-cell-value--new">{value}</div>
      }
    }] : []),
    ...(hasKey('inclusionReductionsName') && (hasAdmin(roughStoneActions.editRoughStone) || hasPermission(roughStoneActions.editQcRoughStone)) ? [{
      Header: 'Reduction Table',
      accessor: 'inclusionReductionsName',
      dataType: 'string',
      Cell: ({ column: { id }, cell: { value, row: { original: { updates = {}, existing = {} } } } }) => {
        if (!(id in updates) || value === existing[id]) return value ?? existing[id] ?? null
        return <div className="save-multiple-roughs__table-cell-value--new">{value}</div>
      }
    }] : []),
    ...(hasKey('tensionName') ? [{
      Header: 'Tension',
      accessor: 'tensionName',
      dataType: 'string',
      Cell: ({ column: { id }, cell: { value, row: { original: { updates = {}, existing = {} } } } }) => {
        if (!(id in updates) || value === existing[id]) return value ?? existing[id] ?? null
        return <div className="save-multiple-roughs__table-cell-value--new">{value}</div>
      }
    }] : []),
    ...(hasKey('otherAttributes.yellowFluorescence') ? [{
      Header: 'Yellow UV',
      accessor: 'otherAttributes.yellowFluorescence',
      dataType: 'boolean'
    }] : []),
    ...(hasKey('typeName') ? [{
      Header: 'Type',
      accessor: 'typeName',
      dataType: 'string',
      Cell: ({ column: { id }, cell: { value, row: { original: { updates = {}, existing = {} } } } }) => {
        if (!(id in updates) || value === existing[id]) return value ?? existing[id] ?? null
        return <div className="save-multiple-roughs__table-cell-value--new">{value}</div>
      }
    }] : []),
    ...(hasKey('locationName') && hasAdmin(roughStoneActions.editRoughStone) ? [{
      Header: 'Location',
      accessor: 'locationName',
      dataType: 'string',
      Cell: ({ column: { id }, cell: { value, row: { original: { updates = {}, existing = {} } } } }) => {
        if (!(id in updates) || value === existing[id]) return value ?? existing[id] ?? null
        return <div className="save-multiple-roughs__table-cell-value--new">{value}</div>
      }
    }] : []),
    ...(hasKey('qcStatusName') && hasPermission(roughStoneActions.editQcRoughStone) ? [{
      Header: 'QC Status',
      accessor: 'qcStatusName',
      dataType: 'string',
      Cell: ({ column: { id }, cell: { value, row: { original: { updates = {}, existing = {} } } } }) => {
        if (!(id in updates) || value === existing[id]) return value ?? existing[id] ?? null
        return <div className="save-multiple-roughs__table-cell-value--new">{value}</div>
      }
    }] : [])
  ]
}

const PAGE_KEYS = {
  ASSORTMENT: 'assortments',
  ROUGH: 'roughstones',
  QC: 'qc'
}

function SaveMultipleRoughs(props) {
  const assortmentId = props.match.params.assortmentId
  const pageKey = props.match.path.split('/')[1]
  const modes = [
    ...(assortmentId ? ['create'] : []),
    'edit',
    'qcEdit'
  ]
  const [assortment, setAssortment] = useState()
  const [roughList, setRoughList] = useState([])
  const [mode, setMode] = useState()
  const [fileId, setFileId] = useState()
  const [downloadTemplateModalOpen, setDownloadTemplateModalOpen] = useState(props.location.state)
  const { permissionsAdminCache, hasAdmin, hasPermission, orgId: userOrgId } = useAuthStore(store => store)
  const sellerActiveParam = { sellerId: assortment?.sellerId || userOrgId, condition: 'ACTIVE' }
  const appSettingsParams = { groupKey: 'ROUGH_CONSTANTS' }
  const activeOnlyParam = { condition: 'ACTIVE' }
  const {
    orgsList: { all: orgsList }, roughColours, roughColoursMap, roughFluorescences, roughFluorescencesMap,
    roughTinges: tinges, roughTingesMap, polishedColoursMap, polishedFluorescences,
    polishedFluorescencesMap, polishedTingesMap, roughScanTypes, roughScanTypesMap, inclusionTypes,
    inclusionReductions, inclusionReductionsMap, roughTensions, roughTensionsMap, roughQCStatuses,
    roughQCStatusesMap, provenanceTypesList: { all: provenanceTypes }, roughStatusesMap,
    roughTypes, roughTypesMap,
    getOrgsList, getRoughColours, getRoughFluorescences, getRoughTinges, getRoughScanTypes,
    getPolishedColours, getPolishedFluorescences, getPolishedTinges,
    getCountriesList, getMinesList, getPipesList,
    getBatchesList, getInclusionTypes, getInclusionReductions, getRoughTensions,
    getRoughQCStatuses, getProvenanceTypesList, getRoughStatuses, getRoughTypes,
    getAppSettings,
    countriesList: { [JSON.stringify(sellerActiveParam)]: countries },
    minesList: { [JSON.stringify(sellerActiveParam)]: mines },
    pipesList: { [JSON.stringify(activeOnlyParam)]: pipes },
    batchesList: { [JSON.stringify(sellerActiveParam)]: batches },
    appSettings: { [JSON.stringify(appSettingsParams)]: appSettings }
  } = useGlobalsStore(store => store)

  const { showErrorToast, showWarningToast } = useToast()
  const [pricePoints, setPricePoints] = useState([])
  const [weightCategories, setWeightCategories] = useState([])

  const tableColumns = useMemo(() => {
    if (!polishedFluorescencesMap) return undefined
    return columns({ roughColoursMap, roughTingesMap, roughFluorescencesMap, polishedColoursMap, polishedFluorescencesMap, polishedTingesMap }, hasAdmin, hasPermission)
  }, [[roughColoursMap, roughTingesMap, roughFluorescencesMap, polishedColoursMap, polishedFluorescencesMap, polishedTingesMap, permissionsAdminCache]])
  const eyeMeasurementColours = useMemo(() => (roughColours ?? []).filter(c => !c.eyeNotAllowed), [roughColours])
  const eyeMeasurementFluorescences = useMemo(() => (polishedFluorescences ?? []).filter(f => !f.eyeNotAllowed), [polishedFluorescences])
  const eyeMeasurementTinges = useMemo(() => (tinges ?? []).filter(t => !t.eyeNotAllowed), [tinges])
  const filteredInclusionTypes = useMemo(() => (inclusionTypes ?? []).filter(inc => hasAdmin(roughStoneActions.getInclusionTypes) ? true : inc.autoInclusions === true), [inclusionTypes, permissionsAdminCache])
  const { MEASUREMENTS_TO_SUBMIT, TENSION_REQUIRED_ABOVE_WEIGHT, EYE_COLOUR_ABOVE_PRIMARY_SOURCE_WEIGHT } = useMemo(() => arrayUtils.toMap(appSettings || [], (x) => x.key, 'value'), [appSettings])

  const tableConstants = useMemo(() => ({
    mines,
    pricePoints,
    weightCategories,
    eyeMeasurementColours,
    eyeMeasurementFluorescences,
    eyeMeasurementTinges,
    inclusionTypes: filteredInclusionTypes,
    inclusionReductions,
    roughScanTypes,
    roughTensions,
    roughTypes,
    roughColours,
    roughFluorescences,
    polishedFluorescences,
    tinges,
    roughQCStatuses,
    locations: ROUGH_LOCATIONS,
    minMeasurements: MEASUREMENTS_TO_SUBMIT,
    tensionRequiredAboveWeight: TENSION_REQUIRED_ABOVE_WEIGHT,
    eyeColourAbovePrimarySourceWeight: EYE_COLOUR_ABOVE_PRIMARY_SOURCE_WEIGHT
  }), [
    mines,
    pricePoints,
    weightCategories,
    eyeMeasurementColours,
    eyeMeasurementFluorescences,
    eyeMeasurementTinges,
    filteredInclusionTypes,
    inclusionReductions,
    roughScanTypes,
    roughTensions,
    roughTypes,
    roughColours,
    roughFluorescences,
    polishedFluorescences,
    tinges,
    roughQCStatusesMap,
    appSettings,
    MEASUREMENTS_TO_SUBMIT,
    TENSION_REQUIRED_ABOVE_WEIGHT,
    EYE_COLOUR_ABOVE_PRIMARY_SOURCE_WEIGHT
  ])

  useEffect(() => {
    hasPermission(organizationActions.getOrganizationList) && getOrgsList()
    hasPermission(constantsActions.getSellerConstants) && getRoughColours()
    hasPermission(constantsActions.getSellerConstants) && getRoughFluorescences()
    hasPermission(constantsActions.getSellerConstants) && getRoughTinges()
    hasPermission(constantsActions.getBuyerConstants) && getPolishedColours()
    hasPermission(constantsActions.getBuyerConstants) && getPolishedTinges()
    hasPermission(constantsActions.getBuyerConstants) && getPolishedFluorescences()
    hasPermission(constantsActions.getSellerConstants) && getRoughScanTypes()
    hasPermission(roughStoneActions.getInclusionTypes) && getInclusionTypes()
    hasPermission(roughStoneActions.getInclusionReductions) && getInclusionReductions()
    hasPermission(constantsActions.getSellerConstants) && getRoughTensions()
    hasPermission(constantsActions.getSellerConstants) && getRoughTypes()
    hasPermission(constantsActions.getSellerConstants) && getRoughQCStatuses()
    hasPermission(constantsActions.getSellerConstants) && getRoughStatuses()
    hasPermission(provenanceActions.getProvenance) && getProvenanceTypesList()
    hasPermission(assortmentActions.getAssortmentList) && assortmentActions.getAssortmentList({ columns: '[ProvenanceType]' }).then(response => setAssortment(response.data.data.find(({ id }) => id === assortmentId) ?? null))
    hasPermission(settingsActions.getSettings) && getAppSettings(appSettingsParams)
  }, [permissionsAdminCache])

  useEffect(() => {
    hasPermission(provenanceActions.getCountries) && getCountriesList(sellerActiveParam).catch(console.error)
    hasPermission(provenanceActions.getMines) && getMinesList(sellerActiveParam).catch(console.error)
    hasPermission(provenanceActions.getPipes) && getPipesList(activeOnlyParam).catch(console.error)
    hasPermission(provenanceActions.getBatches) && getBatchesList(sellerActiveParam).catch(console.error)
    const sellerId = assortment ? assortment.sellerId : !hasAdmin(pricePointActions.getPricePoints) && !hasAdmin(weightCategoryActions.getWeightCategories) ? userOrgId : undefined
    if (sellerId) {
      hasPermission(pricePointActions.getPricePoints) && pricePointActions.getPricePoints(sellerId).then(response => setPricePoints(response.data.data)).catch(console.error)
      hasPermission(weightCategoryActions.getWeightCategories) && weightCategoryActions.getWeightCategories(sellerId).then(response => setWeightCategories(response.data.data)).catch(console.error)
    }
  }, [sellerActiveParam?.sellerId, sellerActiveParam?.condition, activeOnlyParam?.condition, permissionsAdminCache])

  const {
    getRoughStonesList
  } = useRoughStoneStore(store => store)
  const {
    getQcPlannedStonesList
  } = usePlanningStore(store => store)
  async function getStones() {
    if (pageKey === PAGE_KEYS.QC) {
      const genericOrg = orgsList?.find(o => o.orgType === 'GENERIC')?.orgId
      if (!genericOrg) {
        showErrorToast('No Generic organization was found')
        return
      }
      return {
        data: (await getQcPlannedStonesList({
          condition: 'ACTIVE',
          submittedOnly: true,
          columns: hasAdmin(planningActions.getQcPlannedStones)
            ? '[Rough,Rough.Assortment,Rough.Mine,Rough.Batch,Rough.Pipe,Rough.Country,Rough.InclusionsType,Rough.InclusionReductionsFile,Rough.File]'
            : '[Rough,Rough.Assortment,Rough.InclusionsType,Rough.InclusionReductionsFile]'
        }))?.data?.reduce((roughStones, { Rough }) => roughStones.concat(Rough), [])
      }
    } else {
      return await getRoughStonesList({
        filters: { assortmentId, condition: 'ACTIVE', 'status.min': 'ROUGH_INCOMPLETE', 'status.max': 'ROUGH_SUBMITTED' },
        columns: ['Assortment', 'Mine', 'Batch', 'Pipe', 'Country', 'InclusionsType', 'InclusionReductionsFile', 'File', 'Photos']
      })
    }
  }

  async function readInFile(file) {
    if (!file) {
      setRoughList([])
      return
    }

    try {
      const constants = {
        countries,
        mines,
        pipes,
        batches,
        pricePoints,
        weightCategories,
        roughScanTypes,
        roughColours,
        roughColoursMap,
        roughFluorescences,
        roughFluorescencesMap,
        tinges,
        roughTingesMap,
        polishedFluorescences,
        inclusionTypes: filteredInclusionTypes,
        inclusionReductions,
        roughTensions,
        roughTypes,
        provenanceList: provenanceTypes,
        inclusionReductionsMap,
        roughScanTypesMap,
        roughTensionsMap,
        roughTypesMap,
        roughQCStatuses,
        roughQCStatusesMap,
        roughStatusesMap,
        minMeasurements: MEASUREMENTS_TO_SUBMIT,
        tensionRequiredAboveWeight: TENSION_REQUIRED_ABOVE_WEIGHT,
        eyeColourAbovePrimarySourceWeight: EYE_COLOUR_ABOVE_PRIMARY_SOURCE_WEIGHT
      }
      const { mode: roughsMode, roughs, validation: { errors, warnings } } = await parseExcelFile({
        pageKey,
        file,
        assortment,
        ...constants
      }, getStones)
      if (errors.length) throw new errorUtils.ArrayError(errors)
      const newRoughList = roughs.reduce((rL, r) => {
        uploadFieldFilters.forEach((fieldFilter) => {
          const modeFilter = fieldFilter?.[roughsMode]
          if (!modeFilter) delete r[fieldFilter.key]
          else {
            const { allow, hasAdmin: hasAdminFilter, default: defaultValue } = modeFilter
            const hasAccess = hasAdminFilter ? hasAdmin(hasAdminFilter) : true
            if (!allow || !hasAccess) {
              delete r[fieldFilter.key]
              if (SaveMultipleRoughs.parseNamedKey.reverse(fieldFilter.key)) delete r[SaveMultipleRoughs.parseNamedKey.reverse(fieldFilter.key)]
            }
            if (defaultValue && !(fieldFilter.key in r || SaveMultipleRoughs.parseNamedKey.reverse(fieldFilter.key) in r)) {
              if (typeof defaultValue === 'function') r[fieldFilter.key] = defaultValue(r, constants)
              else r[fieldFilter.key] = defaultValue
            }
          }
        })
        return rL.concat({ id: uuidv4(), ...r })
      }, [])
      setFileId(uuidv4())
      if (modes.includes(roughsMode)) {
        setMode(roughsMode)
        setRoughList(newRoughList)
      } else {
        setRoughList([])
        throw new Error('Invalid operation. Please try a different file.')
      }
      if (warnings.length) throw new errorUtils.ArrayError(warnings, 'warning')
    } catch (err) {
      console.error(err)
      if (err.name === 'ArrayError' && err.messagesArray) for (const msg of err.getUniqueMessages()) err.type === 'error' ? showErrorToast(msg) : showWarningToast(msg)
      else if (err.message) showErrorToast(err.message)
    }
  }

  const ModeMultipleRough = useMemo(() => {
    if (!roughList.length) return null
    if (mode === 'create') {
      return (
        <CreateMultipleRoughs
          roughList={roughList}
          columns={tableColumns}
          constants={tableConstants}
          assortment={assortment}
          fileId={fileId}
        />
      )
    }
    if (mode === 'edit') {
      return (
        <EditMultipleRoughs
          roughList={roughList}
          columns={tableColumns}
          constants={tableConstants}
          assortment={assortment}
          fileId={fileId}
        />
      )
    }
    if (mode === 'qcEdit') {
      return (
        <QCEditMultipleRoughs
          roughList={roughList}
          columns={tableColumns}
          constants={tableConstants}
          assortment={assortment}
          fileId={fileId}
        />
      )
    }
  }, [roughList, mode])

  return (
    <div className="save-multiple-roughs">
      <h2>Upload Rough Stones</h2>
      <div className="save-multiple-roughs__upload-box-container">
        <div className="save-multiple-roughs__upload-box">
          <FileUpload
            name={pageKey === PAGE_KEYS.QC ? 'roughQCExcel' : 'roughExcel'}
            label='Upload Rough Stones File'
            onChange={readInFile}
            acceptTypes={['.xlsx']}
            infoTip={true}
          />
          <div className="save-multiple-roughs__download-template-link">
            <Button
              typeVariant='action'
              size='sm'
              onClick={() => setDownloadTemplateModalOpen(true)}
            >
              Download blank template
            </Button>
            <div className="save-multiple-roughs__download-template-link-icon">
              <Icon
                name='download'
              />
            </div>
          </div>
        </div>
      </div>
      {ModeMultipleRough}
      <RoughTemplateDownloadModal
        open={downloadTemplateModalOpen}
        onClose={() => setDownloadTemplateModalOpen(false)}
        initialValues={props.location.state}
        roughColours={roughColours?.filter(c => !c.yehudaNotAllowed)}
        eyeMeasurementColours={eyeMeasurementColours}
        roughFluorescences={roughFluorescences}
        roughTinges={tinges?.filter(t => !t.yehudaNotAllowed)}
        eyeMeasurementTinges={tinges?.filter(t => !t.eyeNotAllowed)}
        roughTensions={roughTensions}
        roughTypes={roughTypes}
        inclusionTypes={filteredInclusionTypes}
        countriesList={countries}
        minesList={mines}
        pipesList={pipes}
        batchesList={batches}
        roughScanTypes={roughScanTypes}
        weightCategories={weightCategories}
        pricePoints={pricePoints}
        inclusionReductions={inclusionReductions}
        locations={tableConstants.locations}
        minMeasurements={MEASUREMENTS_TO_SUBMIT}
        tensionRequiredAboveWeight={TENSION_REQUIRED_ABOVE_WEIGHT}
        eyeColourAbovePrimarySourceWeight={EYE_COLOUR_ABOVE_PRIMARY_SOURCE_WEIGHT}
      />
    </div>
  )
}

SaveMultipleRoughs.parseNamedKey = {
  mineName: 'mineId',
  pipeName: 'pipeId',
  batchName: 'batchId',
  countryName: 'countryId',
  scanTypeName: 'scanType',
  inclusionsTypeName: 'inclusionsTypeId',
  inclusionReductionsName: 'inclusionReductionsId',
  tensionName: 'otherAttributes.tension',
  locationName: 'location',
  qcStatusName: 'qcStatus',
  typeName: 'type',
  // Get the key from the value
  reverse(key) {
    return Object.entries(this).find(([_, v]) => v === key)?.[0]
  }
}

export default SaveMultipleRoughs
