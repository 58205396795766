import React from 'react'
import PropTypes from 'prop-types'
import { FormComponents } from '@organisms'
const { SimpleForm, TextInput, Button } = FormComponents

function PriceAdjustOrderModal({ orders, handleSubmit, handleCancel }) {
  return (
    <SimpleForm onSubmit={(val) => handleSubmit(val?.priceAdjust)}>
      <div>
        {
          `Do you want to adjust the prices of the selected${orders?.length > 1 ? ` ${orders?.length}` : ''} order${orders?.length > 1 ? 's' : ''}? 
        Positive values will increase prices and negative values will decrease them by that percentage. 
        This updates the price by a percentage of the price (before costs), not simply by adding to the discount.`
        }
        <br/><br/>
        e.g. A -1% adjustment of price on:
        <ul>
          <li>- a ppc of $1500 would become $1485</li>
          <li>- a discount of -40% would become -40.6%</li>
        </ul>
      </div>
      <TextInput
        name='priceAdjust'
        type="number"
        label='Adjust Price (+/- %)'
        required={true}
        decimalScale={2}
      />
      <div key='row-buttons' className='modal__buttons'>
        <Button typeVariant='secondary' size='sm' onClick={handleCancel}>Cancel</Button>
        <Button type='submit' typeVariant='primary' size='sm'>Adjust</Button>
      </div>
    </SimpleForm>
  )
}

PriceAdjustOrderModal.propTypes = {
  orders: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired
}

PriceAdjustOrderModal.defaultProps = {
  orders: []
}

export default PriceAdjustOrderModal
