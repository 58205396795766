import { organizationActions } from '@actions'
import { COMMON } from '@constants'
import { useToast } from '@hooks'
import { useAuthStore } from '@stores'
import { fileUtils, numberUtils, textUtils } from '@utils'
import Excel from 'exceljs/dist/es5/exceljs.browser'

const useRoughStoneListReports = () => {
  const { hasAdmin } = useAuthStore(state => state)
  const { showErrorToast } = useToast()

  async function generateRoughBidsExcel(data, { orgsList, galaxyFees }) {
    function formatBidStatus(status) {
      return status === 'BID_ACCEPTED'
        ? 'Y'
        : status === 'BID_REJECTED'
          ? 'N'
          : ''
    }
    function findGalaxyFee(weight) {
      return Number(galaxyFees.find(f => f.minWeight <= weight && f.maxWeight >= weight)?.feePerCarat || 0)
    }
    function getRoundedWeight(weight) {
      return Math.floor((Number(weight) + 0.0001) * 100) / 100
    }
    try {
      const workbook = new Excel.Workbook()
      const bold = { font: { bold: true } }
      const weightCol = { style: { numFmt: '0.000', alignment: { horizontal: 'left' } } }
      const roundedWeightCol = { style: { numFmt: '0.00', alignment: { horizontal: 'left' } } }
      const priceCol = { width: 12, style: { numFmt: '$#,##0.00', alignment: { horizontal: 'left' } } }

      workbook.creator = 'Clara'
      workbook.created = new Date()
      const sheet = workbook.addWorksheet('Active Bids')
      const columns = [
        { header: 'Clara ID', key: 'id', width: 12, transform: val => Number(val) },
        { header: 'Name', key: 'sellerStoneName', width: 12 },
        { header: 'Assortment', key: 'assortmentId', width: 12, transform: (_, item) => item.Assortment ? item.Assortment.name : '' },
        ...(hasAdmin(organizationActions.getOrganizationList) ? [
          { header: 'Seller', key: 'sellerId', width: 12, transform: val => orgsList?.find(o => o.orgId === val)?.commonName }
        ] : []),
        { header: 'Weight', key: 'weight', width: 12, ...weightCol, transform: val => Number(val) },
        { header: 'Shipping Weight', key: 'roundedWeight', width: 12, ...roundedWeightCol, transform: (_, item) => getRoundedWeight(item.weight) },
        { header: 'Color', key: 'colour', width: 12 },
        { header: 'Fluorescence', key: 'fluorescence', width: 12 },
        { header: 'Tinge', key: 'tinge', width: 12 },
        { header: 'Sell? (Y/N)', key: 'sellBid', width: 12, transform: (_, item) => formatBidStatus(item.bid?.status) },
        { header: 'Bid Confirmed (Y/N)', key: 'bidConfirmed', width: 12, transform: (_, item) => item.bid?.confirmed ? 'Y' : 'N' },
        { header: 'Offer Price', key: 'offerPrice', width: 12, ...priceCol, transform: (_, item) => Number(numberUtils.numFmt(item.bid?.offerPrice, 2)) },
        { header: 'Reserve Price', key: 'reservePrice', width: 12, ...priceCol, transform: (_, item) => item.reservePpc != null ? Number(numberUtils.numFmt(item.weight * item.reservePpc, 2)) : 'N/A' },
        { header: 'Galaxy Fees', key: 'galaxyFees', width: 12, ...priceCol, transform: (_, item) => findGalaxyFee(item.weight) * Number(item.weight) }
      ]
      const rows = fileUtils.getExcelRows(data, columns)
      sheet.columns = columns
      sheet.getRow(1).style = bold
      sheet.addRows(rows)
      return workbook.xlsx.writeBuffer().then(buffer => fileUtils.saveBufferExcel(buffer, `Active Bids - ${textUtils.formatDate(new Date(), COMMON.DATE_FMT.REPORT)}.xlsx`))
    } catch (err) {
      console.error(err)
      showErrorToast(err?.message || 'There was an error writing the excel.')
    }
  }

  async function generatePrintingLabelExcel(data) {
    try {
      const workbook = new Excel.Workbook()
      workbook.creator = 'Clara'
      workbook.created = new Date()
      const sheet = workbook.addWorksheet('Clara Labels')
      const columns = [
        { header: 'Clara ID', key: 'id', width: 12, transform: val => Number(val) },
        { header: 'Stone Count', key: 'stoneCount', width: 12, transform: () => 1 },
        { header: 'Size', key: 'weightCategory' },
        { header: 'Carats', key: 'weight', width: 12, transform: val => Number(val) },
        { header: 'Color', key: 'colour', width: 12 },
        { header: 'Fluorescence', key: 'fluorescence', width: 12 },
        { header: 'Tinge', key: 'tinge', width: 12, transform: val => String(val).toLowerCase() === 'none' ? '' : val }
      ]
      const rows = fileUtils.getExcelRows(data, columns)
      sheet.columns = columns
      sheet.addRows(rows)
      return workbook.xlsx.writeBuffer().then(buffer => fileUtils.saveBufferExcel(buffer, `Clara Labels - ${textUtils.formatDate(new Date(), COMMON.DATE_FMT.REPORT)}.xlsx`))
    } catch (err) {
      console.error(err)
      showErrorToast(err?.message || 'There was an error writing the excel.')
    }
  }

  return { generateRoughBidsExcel, generatePrintingLabelExcel }
}

export default useRoughStoneListReports
