import React from 'react'
import Notification from './notification'
import { useOuterClick, useNotifications } from '@hooks'
import { useNotificationStore } from '@stores'
import NotificationEmptyState from './notification_empty_state'
import { Button } from '@atoms'

function NotificationList() {
  // starts an interval to check
  // for new notifications periodically.
  // the delay time is a property inside notification store .
  const { markAsRead, markMultipleAsRead } = useNotifications()
  const {
    setIsNotificationsOpen,
    isNotificationsOpen,
    notificationList
  } = useNotificationStore(state => state)

  const outerRef = useOuterClick(() => {
    setIsNotificationsOpen(false)
  })

  function markAllAsRead(notifications) {
    if (!notifications?.length) return
    markMultipleAsRead(notifications?.filter(n => !n.isRead)?.map(n => n.notificationId))
  }

  return (
    <>
      <div className={`overlay${!isNotificationsOpen ? '--hidden' : ''}`}></div>
      <div
        ref={outerRef}
        className={`notification-list${!isNotificationsOpen ? '--hidden' : ''}`}
      >
        {
          notificationList?.length
            ? (<Button
              typeVariant='action'
              size='sm'
              disabled={notificationList?.every(n => n.isRead)}
              onClick={() => markAllAsRead(notificationList)}
            >
              Mark all as read
            </Button>) : null
        }
        {
          !notificationList?.length
            ? <NotificationEmptyState />
            : null
        }
        {
          notificationList
          .sort((a, b) => {
            if (a.isRead && !b.isRead) return 1
            else if (!a.isRead && b.isRead) return -1
            else {
              if (a.notifyTime <= b.notifyTime) return 1
              else return -1
            }
          })
          .map((notification) => (
            <Notification
              key={notification.notificationId}
              notification={notification}
              markAsRead={markAsRead}
            />
          ))
        }
      </div>
    </>
  )
}

export default NotificationList
