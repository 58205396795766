import axios from 'axios'
const BASE_URL = process.env.REACT_APP_API_BASE_URL

function getWeightCategories(sellerId) {
  return axios.get(`${BASE_URL}/seller/weightcategories`, { params: (sellerId ? { sellerId } : {}) })
}
getWeightCategories.endpoint = 'GET|/seller/weightcategories'

export const weightCategoryActions = {
  getWeightCategories
}
