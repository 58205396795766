import axios from 'axios'
const BASE_URL = process.env.REACT_APP_API_BASE_URL

const getTingeMapList = function (params) {
  return axios.get(`${BASE_URL}/buyer/tingemaps`, { params })
}
getTingeMapList.endpoint = 'GET|/buyer/tingemaps'

const createTingeMap = function(params) {
  return axios.post(`${BASE_URL}/buyer/tingemaps`, params)
}
createTingeMap.endpoint = 'POST|/buyer/tingemaps'

const editTingeMap = function(tingeMapId, params) {
  return axios.patch(`${BASE_URL}/buyer/tingemaps/${tingeMapId}`, params)
}
editTingeMap.endpoint = 'PATCH|/buyer/tingemaps/:tingeMapId:'

const setTingeMapCondition = function(tingeMapIds, condition) {
  return axios.put(`${BASE_URL}/buyer/tingemaps/condition`, { tingeMapIds, condition })
}
setTingeMapCondition.endpoint = 'PUT|/buyer/tingemaps/condition'

export const tingeMapActions = {
  getTingeMapList,
  createTingeMap,
  editTingeMap,
  setTingeMapCondition
}
