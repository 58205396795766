import React, { useEffect, useRef } from 'react'
import { TextArea } from '@molecules'
import { useTableStore } from '@stores'

function TextAreaFilter({
  column,
  tableId,
  visible
}) {
  const {
    id,
    setFilter,
    filterValue = ''
  } = column

  const {
    setFilterValue,
    setIsFilteredFn,
    getTableConfig,
    getFilterValue
  } = useTableStore(state => state)

  const storeFilterValue = getFilterValue(tableId, id)
  const tableConfig = getTableConfig(tableId)

  useEffect(() => {
    setIsFilteredFn(
      tableId,
      id,
      (filter) => isFiltered(filter)
    )
  }, [tableConfig?.activeTab?.id, tableConfig?.activeTab?.label])

  useEffect(() => {
    setFilter(storeFilterValue?.value)
  }, [storeFilterValue?.value])

  function isFiltered(filterValue) {
    return !!filterValue && !!String(filterValue).trim()
  }

  const inputRef = useRef()
  useEffect(() => {
    if (visible) {
      inputRef.current.focus()
    }
  }, [visible])

  return (
    <TextArea
      value={filterValue}
      placeholder='Search...'
      inputRef={inputRef}
      onChange={e => {
        setFilter(e.target.value)
        setFilterValue(tableId, id, e.target.value)
      }}
      rows={filterValue?.includes('\n') ? 5 : 1}
    />
  )
}

const textAreaFilter = (rows, columnId, filters) => {
  if (filters === '') return rows
  const filtersList = getFilterValuesArr(filters)
  if (!filtersList?.length) return rows
  if (!filters.includes('\n') && !filters.includes(',')) {
    return rows.filter(row => String(row.values[columnId[0]]).toLowerCase().includes(filters.toLowerCase()))
  } else {
    const filtersSet = new Set(filtersList)
    if (!filtersSet?.size) return rows
    return rows.filter(row => filtersSet.has(String(row.values[columnId[0]]).toLowerCase()))
  }
}

function getFilterValuesArr(filters) {
  return filters.split(/\n|,\s|,+/g).filter(f => f !== '') || []
}

export {
  TextAreaFilter,
  textAreaFilter
}
