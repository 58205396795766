import React from 'react'
import {
  Signin,
  LogView,
  UserList,
  Dashboard,
  Shipments,
  EventList,
  Inventory,
  OrderList,
  CreateUser,
  CreatePipe,
  CreateMine,
  CreateCost,
  CreateEvent,
  UserDetails,
  CreateBatch,
  CreateOrder,
  MineDetails,
  PipeDetails,
  CostDetails,
  CreateShape,
  ReportsList,
  OrderDetails,
  BatchDetails,
  CreateCountry,
  ShapeDetails,
  BuyerSettings,
  CountryDetails,
  RoughStoneList,
  CreateShipment,
  AssortmentList,
  SellerSettings,
  CreateCutGrade,
  UploadBaseline,
  CreateTingeMap,
  ShipmentDetails,
  UploadCutGrades,
  CutGradeDetails,
  TingeMapDetails,
  BaselineDetails,
  OrganizationList,
  CreateAssortment,
  ProvenanceSearch,
  CreateRoughStone,
  RoughStoneDetails,
  AssortmentDetails,
  TransactionDetails,
  NotificationList,
  CreateOrganization,
  OrganizationDetails,
  SaveMultipleRoughs,
  CreateComputedBaseline,
  PlanningList,
  CreateSpsRequests,
  PlanningDetails,
  SalesList,
  CreateSale,
  SaleDetails,
  QCStonesList,
  EventDetails,
  AppNotificationDetails,
  EmailNotificationDetails,
  NotificationTemplateList,
  NotificationTemplateDetails,
  CreateNotificationTemplate,
  CreateNotification,
  RoleAndPermissionList,
  ShapeGroupList,
  ShapeGroupDetails,
  Settings,
  PaymentDetails,
  InvoiceDetails,
  Finance
} from '../components/pages/pages'
import { Icon } from '@atoms'
import * as actions from '@actions'

const routes = {
  // External routes are any route which is not
  // accessible via navigation. E.g. Signin
  external: [
    {
      path: '/signin',
      label: 'Sign In',
      icon: null,
      exact: false,
      component: Signin
    },
    {
      path: '/assortments/create',
      label: 'Create Assortment',
      icon: null,
      exact: false,
      component: CreateAssortment
    },
    {
      path: '/assortments/:assortmentId/roughstones/create',
      label: 'Create Rough Stone',
      icon: null,
      exact: false,
      component: CreateRoughStone
    },
    {
      path: '/assortments/:assortmentId/roughstones/upload',
      label: 'Upload Stones',
      icon: null,
      exact: false,
      component: SaveMultipleRoughs
    },
    {
      path: '/qc/roughstones/upload',
      label: 'Excel Update',
      icon: null,
      exact: false,
      component: SaveMultipleRoughs
    },
    {
      path: '/roughstones/upload',
      label: 'Excel Update',
      icon: null,
      exact: false,
      component: SaveMultipleRoughs
    },
    {
      path: '/roughstones/:roughStoneId/',
      label: 'Rough Stone Details',
      icon: null,
      exact: false,
      component: RoughStoneDetails
    },
    {
      path: '/assortments/:assortmentId',
      label: 'Assortment Details',
      icon: null,
      exact: false,
      component: AssortmentDetails
    },
    {
      path: '/countries/create',
      label: 'Create Country',
      icon: null,
      exact: false,
      component: CreateCountry
    },
    {
      path: '/mines/create',
      label: 'Create Mine',
      icon: null,
      exact: false,
      component: CreateMine
    },
    {
      path: '/pipes/create',
      label: 'Create Pipe',
      icon: null,
      exact: false,
      component: CreatePipe
    },
    {
      path: '/batches/create',
      label: 'Create Batch',
      icon: null,
      exact: false,
      component: CreateBatch
    },
    {
      path: '/events/create',
      label: 'Create Event',
      icon: null,
      exact: false,
      component: CreateEvent
    },
    {
      path: '/events/:eventId',
      label: 'Event Details',
      icon: null,
      exact: false,
      component: EventDetails
    },
    {
      path: '/countries/:countryId',
      label: 'Country Details',
      icon: null,
      exact: false,
      component: CountryDetails
    },
    {
      path: '/mines/:mineId',
      label: 'Mine Details',
      icon: null,
      exact: false,
      component: MineDetails
    },
    {
      path: '/pipes/:pipeId',
      label: 'Pipe Details',
      icon: null,
      exact: false,
      component: PipeDetails
    },
    {
      path: '/batches/:batchId',
      label: 'Batch Details',
      icon: null,
      exact: false,
      component: BatchDetails
    },
    {
      path: '/transactions/:transactionId',
      label: 'Transaction Details',
      icon: null,
      exact: false,
      component: TransactionDetails
    },

    // Users routes
    {
      path: '/users/create',
      label: 'Create User',
      icon: <Icon name="createUser" title="Create User" />,
      exact: false,
      component: CreateUser
    },
    {
      path: '/users/:userId',
      label: 'User Details',
      icon: null,
      exact: false,
      component: UserDetails
    },
    // Organization routes
    {
      path: '/organizations/create',
      label: 'Create Organization',
      icon: null,
      exact: false,
      component: CreateOrganization
    },
    {
      path: '/organizations/:orgId',
      label: 'Organization Details',
      icon: null,
      exact: false,
      component: OrganizationDetails
    },
    {
      path: '/orders/create',
      label: 'Create Order',
      icon: null,
      exact: true,
      component: CreateOrder
    },
    {
      path: '/orders/:orderId',
      label: 'Order Details',
      icon: null,
      exact: false,
      component: OrderDetails
    },

    // Buyer routes
    {
      path: '/shapes/create',
      label: 'Create Shape',
      icon: null,
      exact: true,
      component: CreateShape
    },
    {
      path: '/cutgrades/create',
      label: 'Create Cut Grade',
      icon: null,
      exact: true,
      component: CreateCutGrade
    },
    {
      path: '/cutgrades/upload',
      label: 'Upload Sarine File',
      icon: null,
      exact: true,
      component: UploadCutGrades
    },
    {
      path: '/baselines/upload',
      label: 'Upload Baseline',
      icon: null,
      exact: true,
      component: UploadBaseline
    },
    {
      path: '/baselines/create',
      label: 'Create Baseline',
      icon: null,
      exact: true,
      component: CreateComputedBaseline
    },
    {
      path: '/baselines/:baselineId',
      label: 'Baseline Details',
      icon: null,
      exact: true,
      component: BaselineDetails
    },
    {
      path: '/tingemaps/create',
      label: 'Create Tinge Map',
      icon: null,
      exact: true,
      component: CreateTingeMap
    },
    {
      path: '/mfgcosts/create',
      label: 'Create Manufacturing Cost',
      icon: null,
      exact: true,
      component: () => <CreateCost costType='mfg' />
    },
    {
      path: '/certcosts/create',
      label: 'Create Certificate Cost',
      icon: null,
      exact: true,
      component: () => <CreateCost costType='cert' />
    },
    {
      path: '/shapes/:shapeId/',
      label: 'Shape Details',
      icon: null,
      exact: true,
      component: ShapeDetails
    },
    {
      path: '/cutgrades/:cutGradeId/',
      label: 'Cut Grade Details',
      icon: null,
      exact: true,
      component: CutGradeDetails
    },
    {
      path: '/tingemaps/:tingeMapId/',
      label: 'Tinge Map Details',
      icon: null,
      exact: true,
      component: TingeMapDetails
    },
    {
      path: '/notifications/templates/',
      label: 'Notification Templates',
      icon: null,
      exact: true,
      component: NotificationTemplateList
    },
    {
      path: '/mfgcosts/:costId/',
      label: 'Manufacturing Cost Details',
      icon: null,
      exact: true,
      component: (props) => <CostDetails costType='mfg' {...props} />
    },
    {
      path: '/certcosts/:costId/',
      label: 'Certificate Cost Details',
      icon: null,
      exact: true,
      component: (props) => <CostDetails costType='cert' {...props} />
    },
    {
      path: '/notifications/create',
      label: 'Create Notification',
      icon: null,
      exact: true,
      component: CreateNotification
    },
    {
      path: '/notifications/templates/create',
      label: 'Create Notification Template',
      icon: null,
      exact: true,
      component: CreateNotificationTemplate
    },
    {
      path: '/notifications/app/:notificationId',
      label: 'App Notification Details',
      icon: null,
      exact: false,
      component: AppNotificationDetails
    },
    {
      path: '/notifications/email/:emailId',
      label: 'Email Notification Details',
      icon: null,
      exact: false,
      component: EmailNotificationDetails
    },
    {
      path: '/notifications/templates/:templateId',
      label: 'Notification Template Details',
      icon: null,
      exact: false,
      component: NotificationTemplateDetails
    },
    // SPS routes
    {
      path: '/planning/create',
      label: 'Create SPS Requests',
      icon: null,
      exact: true,
      component: CreateSpsRequests
    },
    {
      path: '/planning/:requestId/',
      label: 'Planning Details',
      icon: null,
      exact: true,
      component: PlanningDetails
    },
    {
      path: '/qc/planning/:requestId/',
      label: 'Planning Details',
      icon: null,
      exact: true,
      component: PlanningDetails
    },
    {
      path: '/sales/',
      label: 'Sales',
      icon: null,
      exact: true,
      component: SalesList
    },
    {
      path: '/sales/create',
      label: 'Create Sale',
      icon: null,
      exact: true,
      component: CreateSale
    },
    {
      path: '/sales/:saleId/',
      label: 'Sale Details',
      icon: null,
      exact: true,
      component: SaleDetails
    },
    {
      path: '/shipments/create',
      label: 'Create Shipment ',
      icon: null,
      exact: true,
      component: CreateShipment,
      displayFor: [actions.logisticsActions.getShipmentList]
    },
    {
      path: '/shipments/:shipmentId/',
      label: 'Shipment Details',
      icon: null,
      exact: true,
      component: ShipmentDetails
    },
    {
      path: '/finance/invoices/:invoiceId/',
      label: 'Invoice Details',
      icon: null,
      exact: true,
      component: InvoiceDetails,
      displayFor: [actions.logisticsActions.getInvoices]
    },
    {
      path: '/finance/payments/:paymentId/',
      label: 'Payment Details',
      icon: null,
      exact: true,
      component: PaymentDetails,
      displayFor: [actions.logisticsActions.getPayments]
    },
    {
      path: '/shapegroups/:shapeGroupId',
      label: 'Shape Groups Details',
      icon: null,
      exact: true,
      component: ShapeGroupDetails,
      displayForAdmin: [actions.shapeGroupActions.getShapeGroupsList]
    }
  ],
  navigation: {
    menu: [
      {
        path: '/dashboard',
        label: 'Dashboard',
        icon: <Icon name="dashboard" title="Dashboard" />,
        exact: false,
        component: Dashboard,
        displayFor: [actions.saleActions.getSales]
      },
      {
        path: '/seller-settings',
        label: 'Seller Settings',
        icon: <Icon name="buyerSettings" title="Buyer Settings" />,
        exact: false,
        component: SellerSettings,
        displayFor: [actions.provenanceActions.getPipes, actions.provenanceActions.getBatches]
      },
      {
        path: '/assortments',
        label: 'Assortments',
        icon: <Icon name="assortment" title="Assortment" />,
        exact: false,
        component: AssortmentList,
        displayFor: [actions.assortmentActions.getAssortmentList]
      },
      {
        path: '/roughstones',
        label: 'Rough Stones',
        icon: <Icon name="roughStone" title="Rough Stones" />,
        exact: false,
        component: RoughStoneList,
        displayFor: [actions.roughStoneActions.getRoughStoneList]
      },
      {
        path: '/buyer-settings',
        label: 'Buyer Settings',
        icon: <Icon name="buyerSettings" title="Buyer Settings" />,
        exact: false,
        component: BuyerSettings,
        displayFor: [actions.cutGradeActions.getShapeList, actions.cutGradeActions.getCutGradeList, actions.baselineActions.getBaselines, actions.costActions.getMfgCostList, actions.costActions.getCertCostList, actions.tingeMapActions.getTingeMapList]
      },
      {
        path: '/orders',
        label: 'Orders',
        icon: <Icon name="orders" title="Orders" />,
        exact: false,
        component: OrderList,
        displayFor: [actions.orderActions.getOrderList]
      },
      {
        path: '/inventory',
        label: 'Inventory',
        icon: <Icon name="inventory" title="Inventory" />,
        exact: false,
        component: Inventory,
        displayFor: [actions.roughStoneActions.getAvailability]
      },
      {
        path: '/provenance-search',
        label: 'Provenance',
        icon: <Icon name="provenance" title="Provenance" />,
        exact: false,
        component: ProvenanceSearch,
        displayFor: [actions.roughStoneActions.getRoughStoneForBlockchain]
      },
      {
        path: '/settings',
        label: 'Settings',
        icon: <Icon name="settings" title="Settings" />,
        exact: false,
        component: Settings,
        displayForAdmin: [actions.settingsActions.getSettings]
      },
      {
        path: '/organizations',
        label: 'Organizations',
        icon: <Icon name="organizations" title="Organizations" />,
        exact: false,
        component: OrganizationList,
        displayForAdmin: [actions.organizationActions.getOrganizationList]
      },
      {
        path: '/users',
        label: 'Users',
        icon: <Icon name="users" title="Users" />,
        exact: false,
        component: UserList,
        displayForAdmin: [actions.userActions.getUsersList]
      },
      {
        path: '/reports',
        label: 'Reports',
        icon: <Icon name='report' totle='Reports' />,
        exact: false,
        component: ReportsList,
        displayFor: [actions.reportActions.listReports, actions.reportActions.listUserReports]
      },
      {
        path: '/planning',
        label: 'Planning',
        icon: <Icon name="planning" title="Planning" />,
        exact: false,
        component: PlanningList,
        displayForAdmin: [actions.planningActions.getPlannedStones]
      },
      {
        path: '/qc',
        label: 'QC',
        icon: <Icon name="planning" title="QC Items" />,
        exact: false,
        component: QCStonesList,
        displayFor: [actions.planningActions.getQcPlannedStones]
      },
      {
        path: '/shapegroups',
        label: 'Shape Groups',
        icon: <Icon name='shapes' title='Shape Groups' />,
        exact: false,
        component: ShapeGroupList,
        displayForAdmin: [actions.shapeGroupActions.getShapeGroupsList]
      },
      {
        path: '/shipments',
        label: 'Shipments',
        icon: <Icon name="shipments" title="Shipments" />,
        exact: true,
        component: Shipments,
        displayFor: [actions.logisticsActions.getShipments]
      },
      {
        path: '/roles-and-permissions',
        label: 'Permissions',
        icon: <Icon name="shieldLock" title="Permissions" />,
        exact: false,
        component: RoleAndPermissionList,
        displayForAdmin: [actions.roleAndPermissionAction.getPermissions]
      },
      {
        path: '/events',
        label: 'Events',
        icon: <Icon name="event" title="Planning" />,
        exact: false,
        component: EventList,
        displayForAdmin: [actions.eventActions.getEvents]
      },
      {
        path: '/finance',
        label: 'Finance',
        icon: <Icon name="dollars" title="Finance" />,
        exact: true,
        component: Finance,
        displayFor: [actions.logisticsActions.getInvoices]
      },
      {
        path: '/notifications',
        label: 'Notifications',
        icon: <Icon name="bell" title="Notifications" />,
        exact: false,
        component: NotificationList,
        displayForAdmin: [actions.notificationsActions.getAppNotifications, actions.notificationsActions.getEmailNotifications]
      },
      {
        path: '/logs',
        label: 'Logs',
        icon: <Icon name='logList' title='Logs' />,
        exact: false,
        component: LogView,
        displayFor: [actions.auditActions.getLogs]
      }
    ],
    account: [
      {
        path: '/organizations/me',
        label: 'Organization Settings',
        icon: <Icon name="organizations" title="Organizations" />,
        exact: false,
        component: OrganizationDetails,
        displayFor: [actions.organizationActions.getOrganizationById]
      },
      {
        path: '/users/me',
        label: 'Profile',
        icon: <Icon name="profile" title="Profile" />,
        exact: false,
        component: UserDetails,
        displayFor: [actions.userActions.getUsersList]
      }
    ]
  }
}

export default routes
