import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from '@molecules'

function PlanningCategoryModal({
  onChange,
  inclusionTypes,
  inclusionDetails,
  inclusionReductions
}) {
  return (
    <div className='planning-category-modal'>
      <Dropdown
        size="lg"
        name="inclusionsTypeId"
        label="Inclusion type"
        options={inclusionTypes.map(inclusionType => ({
          value: inclusionType.id,
          label: inclusionType.description,
          default: inclusionType.default
        }))}
        value={inclusionDetails?.inclusionsTypeId}
        onChange={onChange}
        infoTip={true}
        required={false}
      />
      <Dropdown
        size="lg"
        name="inclusionReductionsId"
        label="Inclusion reductions"
        options={inclusionReductions?.map(inclusionReductions => ({
          value: inclusionReductions.id,
          label: inclusionReductions.description,
          default: inclusionReductions.default
        }))}
        value={inclusionDetails?.inclusionReductionsId}
        onChange={onChange}
        required={false}
      />
    </div>
  )
}

PlanningCategoryModal.propTypes = {
  onChange: PropTypes.func,
  inclusionDetails: PropTypes.object,
  inclusionReductions: PropTypes.arrayOf(PropTypes.object),
  inclusionTypes: PropTypes.arrayOf(PropTypes.object)
}

PlanningCategoryModal.defaultProps = {
  inclusionDetails: {}
}

export default PlanningCategoryModal
