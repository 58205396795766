import React, { useState, useEffect, forwardRef } from 'react'
import { TextInput, TextArea } from '@molecules'
import FileSaver from 'file-saver'

const LogDetails = forwardRef(({ logEvent, eventUser }, ref) => {
  const [attributes, setAttributes] = useState([])
  useEffect(() => {
    if (logEvent) setAttributes(Object.keys({ ...(logEvent.changed_fields ?? {}) }))
    else setAttributes([])
  }, [logEvent])

  function getElement(col, attr) {
    if (!logEvent[col]) return <TextArea value='' disabled />
    const textValue = (typeof logEvent[col][attr] === 'object') ? JSON.stringify(logEvent[col][attr]) : logEvent[col][attr]
    if (textValue?.length > 200000) {
      return <div className='log-details__download-anchor'>
        <span><a onClick={() => downloadText(textValue)}>Download</a> Large Value</span>
        <span><a onClick={() => copyText(textValue)}>Copy</a> to Clipboard</span>
      </div>
    } else {
      return <TextArea value={textValue} disabled />
    }
  }

  function downloadText(string) {
    FileSaver.saveAs(new Blob([string], { type: 'text/plain;charset=utf-8' }), 'log_field.json')
  }

  function copyText(string) {
    navigator.clipboard.writeText(string)
    .then(() => console.log('Copied'))
    .catch(err => console.error('Copy Failed', err))
  }

  return (
    <div ref={ref}>{
      logEvent ? (
        <div className="log-details__row" >
          <div className="log-details__container">
            <div className="log-details__header">
              <div className="log-details__label">{logEvent.event_id}</div>
            </div>
            <div className='log-details__column-container'>
              <TextInput label='Change ID' value={logEvent.event_id} disabled />
              <TextInput label='ID' value={logEvent.row_id} disabled />
              <TextInput label='Schema' value={logEvent.schema_name} disabled />
              <TextInput label='Table' value={logEvent.table_name} disabled />
              <TextInput label='User' value={logEvent.user_name + (eventUser ? ` - ${eventUser.name}` : '')} disabled />
              <TextInput label='Date' value={logEvent.action_tstamp} disabled />
              <TextInput label='Action' value={logEvent.action} disabled />
              <TextInput label='DB User' value={logEvent.session_user_name} disabled />
            </div>
            <br/>
            <div className='log-details__attribute-container'>
              <div className='log-details__value-row'>
                <div className='input__label'>Field Name</div>
                <div className='input__label'>Previous Value</div>
                <div className='input__label'>New Value</div>
              </div>
              {attributes.map(attr => {
                return <div key={attr} className='log-details__value-row'>
                  <div className='input__label'>{attr}</div>
                  {getElement('row_data', attr)}
                  {getElement('changed_fields', attr)}
                </div>
              })}
            </div>
          </div>
        </div>
      ) : null
    }
    </div>
  )
})

export default LogDetails
