import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { DetailsPage } from '@templates'
import { useGlobalsStore } from '@stores'
import PolishedStoneValueInfoTip from './infoTips/polishedStoneValueInfoTip'

function PolishedStoneDetails({ polishedStone, orders }) {
  const {
    polishedColoursMap: coloursMap,
    claritiesMap,
    polishedFluorescencesMap,
    polishedTingesMap: tingesMap,
    getPolishedColours,
    getClarities,
    getPolishedFluorescences,
    getPolishedTinges
  } = useGlobalsStore(state => state)

  useEffect(() => {
    getPolishedColours()
    getClarities()
    getPolishedFluorescences()
    getPolishedTinges()
  }, [])

  const fields = [
    {
      label: 'Value',
      type: 'currency',
      name: 'buyerPrice',
      value: polishedStone.buyerPrice,
      renderOverrides: () => ({
        label: <>Value <PolishedStoneValueInfoTip polishedStone={polishedStone} /></>
      })
    },
    {
      label: 'Stone Details',
      name: 'stoneDetails',
      span: true,
      rows: 4,
      componentName: 'textArea',
      value: `Weight: ${polishedStone.polishedWeight + 'ct'}
Color: ${tingesMap?.[polishedStone.tinge] !== 'None' ? tingesMap[polishedStone.tinge] : coloursMap?.[polishedStone.colour] ?? ''}
Clarity: ${claritiesMap?.[polishedStone.clarity] ?? ''}
Fluorescence: ${polishedFluorescencesMap?.[polishedStone.fluorescence] ?? ''}`
    },
    {
      label: 'Order',
      name: 'orders',
      span: true,
      ...(orders?.[polishedStone.OrderLine?.orderId] ? {
        componentName: 'navLink',
        text: orders?.[polishedStone.OrderLine?.orderId]?.name || '',
        url: `/orders/${orders?.[polishedStone.OrderLine?.orderId]?.id}`
      } : {
        componentName: 'readonly',
        value: 'NA',
        infoTip: 'Order was not found. Order may have been deleted.'
      })
    }
  ]

  return (
    <div>
      <DetailsPage
        title={{
          value: '',
          label: polishedStone.label || ''
        }}
        fields={fields}
      />
    </div>
  )
}

PolishedStoneDetails.propTypes = {
  polishedStone: PropTypes.object,
  orders: PropTypes.object
}

PolishedStoneDetails.defaultProps = {
  polishedStone: {},
  orders: {}
}

export default PolishedStoneDetails
