import moment from 'moment'
import React, { useEffect, useMemo } from 'react'
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Line, Legend } from 'recharts'
import { textUtils } from '@utils'

function SaleHistoryChart({ transactions, chartColours, dataKey, dateRange, setDateRange }) {
  const data = useMemo(() => {
    if (!transactions) return []
    return Object.values(transactions.reduce((txnsByDate, txn) => {
      const date = textUtils.formatDate(txn.transactionDate)
      if (!(date in txnsByDate)) txnsByDate[date] = { date, roughCount: 0, buyerPrice: 0, sellerPrice: 0 }
      txnsByDate[date].roughCount++
      txnsByDate[date].buyerPrice += Number(txn?.buyerPrice ?? 0)
      txnsByDate[date].sellerPrice += Number(txn?.sellerPrice ?? 0)
      return txnsByDate
    }, {})).sort((a, b) => moment(a.date) - moment(b.date))
  }, [transactions])
  const filteredData = useMemo(() => {
    if (!data || !data.length) return []
    return data.filter(({ date }) => moment(date).isSameOrAfter(dateRange.startDate) && moment(date).isSameOrBefore(dateRange.endDate))
  }, [data, dateRange])

  useEffect(() => {
    if (!data || !data.length) return
    setDateRange({ startDate: textUtils.formatDate(data[0].date), endDate: textUtils.formatDate(data[data.length - 1].date) })
  }, [data])

  if (!data) return null
  return (
    <div className="sale-history-chart">
      <ResponsiveContainer width='100%' height={400}>
        <LineChart data={filteredData}>
          <Line
            type="monotone"
            dataKey="roughCount"
            stroke={chartColours[0]}
            strokeWidth="2"
            name="Number of Stones"
            yAxisId="number"
          />
          <Line
            type="monotone"
            dataKey={dataKey}
            stroke={chartColours[1]}
            strokeWidth="2"
            name="Total Value"
            yAxisId="dollars"
          />
          <CartesianGrid />
          <XAxis dataKey='date' label={{ value: 'Sale Date', position: 'insideBottom', dy: 5 }} height={48}/>
          <YAxis yAxisId="number" label={{ value: 'Stones', position: 'insideLeft', angle: -90, dy: 40 }} allowDecimals={false}/>
          <YAxis yAxisId="dollars" orientation="right" tickFormatter={t => `$${t / 1000}k`} />
          <Legend align='center' verticalAlign='top' iconType='circle' height={24} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default SaleHistoryChart
