import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import OrderLine from './orderLine'
import OrderLinesList from './orderLinesList'
import { useGlobalsStore, useOrderStore } from '@stores'
import { tingeMapActions } from '@actions'
import dottie from 'dottie'

function OrderLines({ value: orderLines, onChange, priceScheme, disabled, tingeMapId, validationText, ...props }) {
  const [selectedLine, setSelectedLine] = useState(null)
  const selectedLineIdx = orderLines.findIndex(line => line.id === selectedLine)
  const selectedLineObject = orderLines[selectedLineIdx]

  // reset selected line when a line object is not found in order lines
  if (!selectedLineObject && selectedLine) {
    setSelectedLine(null)
  }

  const {
    polishedColours: colours,
    clarities,
    polishedFluorescences: fluors,
    polishedTinges: tinges
  } = useGlobalsStore(state => state)

  const { gridSize = {} } = useOrderStore(state => state)

  const [lineLimits, setLineLimits] = useState(gridSize)
  useEffect(() => {
    if (priceScheme?.length && colours && clarities && fluors) {
      setLineLimits(priceScheme.reduce((limits, range) => {
        if (!range) return limits
        if (range.minWeight != null) limits.minWeight = Math.min(limits.minWeight ?? range.minWeight, range.minWeight)
        if (range.maxWeight != null) limits.maxWeight = Math.max(limits.maxWeight ?? range.maxWeight, range.maxWeight)
        return limits
      }, { ...gridSize }))
    }
  }, [priceScheme, colours, clarities, fluors, gridSize])

  const [allowedTinges, setAllowedTinges] = useState(['None'])
  useEffect(() => {
    if (tingeMapId && tinges) {
      tingeMapActions.getTingeMapList({ id: tingeMapId })
      .then(response => setAllowedTinges(['None'].concat(Object.keys(response.data.data?.[0]?.mapping))))
      .catch(console.error)
    } else if (!tingeMapId) {
      handleOrderLineChange({ currentTarget: { name: '/tingesIncluded', value: { None: true } } })
      setAllowedTinges(['None'])
    }
  }, [tingeMapId, tinges])

  function addOrderLine() {
    const maxId = orderLines.reduce((max, line) => {
      if (isNaN(line.id)) return max
      return Math.max(max, Number(line.id))
    }, 0)
    onChange({
      currentTarget: {
        name: props.name,
        value: [...orderLines, {
          id: String(maxId + 1),
          tingesIncluded: allowedTinges.reduce((included, tinge) => ({ ...included, [tinge]: true }), {}),
          strictLimits: { clarity: false, weight: false, colour: false },
          quantityType: 'ONE_TIME',
          ...lineLimits
        }]
      }
    })
    setSelectedLine(String(maxId + 1))
  }

  function deleteOrderLine(id) {
    const filteredLines = orderLines.filter(line => line.id !== id)
    onChange({ currentTarget: { name: props.name, value: filteredLines } })
    if (selectedLine === id) {
      if (filteredLines.length) setSelectedLine(filteredLines[0].id)
      else setSelectedLine(null)
    }
  }

  // Event target name should be formatted using one of the options below
  // name => {orderLineId}/{field} => Update orderLine with id equal to orderLineId
  // name => /{field} => Update all orderLines
  function handleOrderLineChange(e) {
    const target = e.currentTarget || e.target
    const [id, name] = target.name.split('/')
    const newLines = orderLines.slice()
    if (id) {
      const lineIndex = orderLines.findIndex(line => line.id === id)
      if (lineIndex < 0) {
        console.error('order line to update was not found')
        return
      }
      dottie.set(newLines, `${lineIndex}.${name}`, target.value)
    } else {
      orderLines.forEach((_, idx) => dottie.set(newLines, `${idx}.${name}`, target.value))
    }
    onChange({ currentTarget: { name: props.name, value: newLines } })
  }

  return (
    <>
      <h5>Order Lines</h5>
      <div className='order-lines'>
        <OrderLinesList
          orderLines={orderLines}
          selectedOrderLine={selectedLine}
          selectOrderLine={setSelectedLine}
          addOrderLine={addOrderLine}
          deleteOrderLine={deleteOrderLine}
          disabled={disabled}
          validationText={validationText}
        />
        {
          orderLines.length
            ? <OrderLine
              orderLine={selectedLineObject}
              onChange={handleOrderLineChange}
              allowedTinges={allowedTinges}
              lineLimits={lineLimits}
              disabled={disabled}
              validationText={validationText?.parseStep([selectedLineIdx])}
            />
            : <div className="order-lines__item">
              <div className="order-lines__item-columns">
                <div className="order-lines__item-column">
                  No orderlines have been created yet.
                </div>
              </div>
            </div>
        }
      </div>
    </>
  )
}

OrderLines.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  priceScheme: PropTypes.array,
  disabled: PropTypes.bool,
  tingeMapId: PropTypes.string,
  validationText: PropTypes.object
}

OrderLines.defaultProps = {
  value: []
}

export default OrderLines
