import { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import Cookies from 'js-cookie'

import {
  useAuthStore,
  useSigninStore,
  useGlobalsStore,
  resetGlobalsStore,
  useOrderStore,
  usePlanningStore,
  useRoughStoneStore,
  useTransactionStore,
  useProgressListStore,
  useModalStore,
  useTableStore
} from '@stores'

import { userActions } from '@actions'

// TODO: Review and write comments
const useAuth = function () {
  const {
    currentUser,
    setCurrentUser,
    currentSession,
    isAuthenticated,
    setCurrentSession,
    currentAuthenticatedUser,
    setCurrentAuthenticatedUser,
    getMightRefreshSession,
    setMightRefreshSession,
    setPermissionsCache,
    resetAuthStore
  } = useAuthStore(state => state)

  const {
    resetProgressListStore
  } = useProgressListStore(state => state)

  const {
    setDisplayMode
  } = useSigninStore(state => state)

  const {
    setOrgsList
  } = useGlobalsStore(state => state)

  const { resetModalStore } = useModalStore(store => store)
  const { resetPlanningStore } = usePlanningStore(store => store)
  const { resetRoughStoneStore } = useRoughStoneStore(store => store)
  const { resetOrderStore } = useOrderStore(store => store)
  const { resetTransactionStore } = useTransactionStore(store => store)
  const resetTableFilters = useTableStore(store => store.resetTableFilters)

  useEffect(() => {
    Auth.currentSession()
      .then(currentSession => {
        setCurrentSession(currentSession)
      })
      .catch(() => setCurrentSession(null))
    if (!currentAuthenticatedUser && isAuthenticated) {
      Auth.currentAuthenticatedUser()
        .then(user => {
          setCurrentAuthenticatedUser(user)
        })
        .catch(() => setCurrentAuthenticatedUser(null))
    }
  }, [currentAuthenticatedUser])

  function signIn(username, password) {
    if (!username) {
      return Promise.reject(new Error('Please enter username.'))
    }

    if (!password) {
      return Promise.reject(new Error('Please enter password.'))
    }

    return Auth.signIn(username, password)
      .then(user => {
        if (
          user.challengeName === 'SMS_MFA'
          || user.challengeName === 'SOFTWARE_TOKEN_MFA'
        ) {
          setDisplayMode('confirmCode')
          setCurrentUser(user)
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setDisplayMode('changePassword')
          setCurrentUser(user)
        } else {
          // The user directly signs in
          // Resetting progress list in case the result of
          // a Promise added a task to the list
          // when the user signs out
          resetProgressListStore()
          authenticateUser(user)
        }
      })
  }

  function changePassword(newPassword, repeatNewPassword) {
    if (!newPassword) {
      return Promise.reject(new Error('Please enter New password'))
    }
    if (!repeatNewPassword) {
      return Promise.reject(new Error('Please enter Repeat New password'))
    }

    const {
      challengeParam,
      email,
      phoneNumber
    } = currentUser

    return Auth.completeNewPassword(
      currentUser, // the Cognito User Object
      newPassword, // the new password
      challengeParam.requiredAttributes, // OPTIONAL, the required attributes
      {
        email,
        phoneNumber
      }
    )
  }

  function forgotPassword(username) {
    if (!username) {
      return Promise.reject(new Error('Please enter username.'))
    }

    return Auth.forgotPassword(username)
  }

  function forgotPasswordCodeSubmit(username, verifyCode, newPassword, repeatNewPassword) {
    if (!username) {
      return Promise.reject(new Error('Please enter username.'))
    }

    if (newPassword !== repeatNewPassword) {
      return Promise.reject(new Error('New password and Repeat new password do not match.'))
    }

    return Auth.forgotPasswordSubmit(username, verifyCode, newPassword)
  }

  function authenticateUser(user) {
    // The user directly signs in
    setCurrentAuthenticatedUser(user)
    Cookies.set('jwt', user?.signInUserSession?.idToken?.jwtToken, {
      path: '/'
    })

    // On user login, get a list of all organizations and users for admin and org admins
    setOrgsList()
    setPermissionsCache()

    userActions.setUserLastLogin(user?.attributes?.sub)
  }

  function refreshSession() {
    Auth.currentSession()
    .then(session => {
      setCurrentSession(session)
      Cookies.set('jwt', session?.idToken?.jwtToken, {
        path: '/'
      })
    })
  }

  const signOut = function() {
    endSession()
    resetAuthStore()
    resetGlobalsStore()
    resetRoughStoneStore()
    resetPlanningStore()
    resetOrderStore()
    resetTransactionStore()
    resetProgressListStore()
    resetModalStore()
    resetTableFilters()
  }

  const endSession = function () {
    Auth.signOut()
  }

  return {
    signIn,
    signOut,
    endSession,
    refreshSession,
    forgotPassword,
    forgotPasswordCodeSubmit,
    changePassword,
    isAuthenticated,
    currentAuthenticatedUser,
    currentSession,
    getMightRefreshSession,
    setMightRefreshSession
  }
}

export { useAuth }
