import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_BASE_URL

const getRoles = function () {
  return axios.get(`${BASE_URL}/auth/roles`)
}
getRoles.endpoint = 'GET|/auth/roles'

const getPermissions = function (params = {}) {
  const { roles, ...restParams } = params
  return axios.get(`${BASE_URL}/auth/permissions`, {
    params: {
      ...(roles ? { roles: `[${roles.join(',')}]` } : {}),
      ...restParams
    }
  })
}
getPermissions.endpoint = 'GET|/auth/permissions'

const createPermission = function (permission) {
  return axios.post(`${BASE_URL}/auth/permissions`, permission)
}
createPermission.endpoint = 'POST|/auth/permissions'

const removePermission = function (endpoint, role) {
  return axios.delete(`${BASE_URL}/auth/permissions/${encodeURIComponent(endpoint)}/${role}`)
}
removePermission.endpoint = 'DELETE|/auth/permissions/:endpoint:/:role:'

const editPermission = function (endpoint, role, data) {
  return axios.patch(`${BASE_URL}/auth/permissions/${encodeURIComponent(endpoint)}/${role}`, data)
}
editPermission.endpoint = 'PATCH|/auth/permissions/:endpoint:/:role:'

export const roleAndPermissionAction = {
  getRoles,
  editPermission,
  getPermissions,
  createPermission,
  removePermission
}
