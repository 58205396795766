import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

function ProgressListBar({
  progressPercentage,
  inProgress,
  isSucceeded,
  isFailed
}) {
  const variant = useMemo(() => {
    if (inProgress) return '--in-progress'
    if (isSucceeded) return '--succeeded'
    if (isFailed) return '--failed'
    return ''
  }, [inProgress, isSucceeded, isFailed])

  return (
    <div className="progress-list__item-progress">
      {
        progressPercentage && !isFailed
          ? (
            <span className='progress-list__item-progress-percentage'>{progressPercentage}%</span>
          ) : null
      }
      <div className='progress-list__item-progress-bar-container'>
        <div className={`progress-list__item-progress-bar${variant}`} style={{ width: `${progressPercentage && !isFailed ? progressPercentage : 100}%` }}/>
      </div>
    </div>
  )
}

ProgressListBar.propTypes = {
  progressPercentage: PropTypes.number,
  inProgress: PropTypes.bool,
  isSucceeded: PropTypes.bool,
  isFailed: PropTypes.bool
}

ProgressListBar.defaultProps = {
  isFailed: false,
  inProgress: false,
  isSucceeded: false
}

export default ProgressListBar
