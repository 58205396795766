const COMMON = {
  // it looks like these enums should come from the backend.
  // maybe the same for action types.
  NOTIFICATION: {
    TYPE: {
      WARNING: 'Warning',
      ERROR: 'Error',
      SUCCESS: 'Success',
      CALENDAR: 'Calendar',
      INFO: 'Info'
    },
    ACTION: {
      TYPE: {
        REDIRECT: {
          key: 'redirect',
          label: 'Redirect'
        },
        GET_REPORT: {
          key: 'getReport',
          label: 'Get Report'
        }
      }
    }
  },
  ERROR_SUMMARY: 'Unable to update the selected {{field}} due to the following error(s).',
  HTML_ATTRIBUTES: {
    GLOBAL: ['accesskey', 'autocapitalize', 'autofocus', 'class', 'contenteditable', 'contextmenu', 'dir', 'draggable', 'enterkeyhint', 'exportparts', 'hidden', 'id', 'inputmode', 'is', 'itemid', 'itemprop', 'itemref', 'itemscope', 'itemtype', 'lang', 'nonce', 'part', 'slot', 'spellcheck', 'style', 'tabindex', 'title', 'translate', 'onabort', 'onautocomplete', 'onautocompleteerror', 'onblur', 'oncancel', 'oncanplay', 'oncanplaythrough', 'onchange', 'onclick', 'onclose', 'oncontextmenu', 'oncuechange', 'ondblclick', 'ondrag', 'ondragend', 'ondragenter', 'ondragleave', 'ondragover', 'ondragstart', 'ondrop', 'ondurationchange', 'onemptied', 'onended', 'onerror', 'onfocus', 'oninput', 'oninvalid', 'onkeydown', 'onkeypress', 'onkeyup', 'onload', 'onloadeddata', 'onloadedmetadata', 'onloadstart', 'onmousedown', 'onmouseenter', 'onmouseleave', 'onmousemove', 'onmouseout', 'onmouseover', 'onmouseup', 'onmousewheel', 'onpause', 'onplay', 'onplaying', 'onprogress', 'onratechange', 'onreset', 'onresize', 'onscroll', 'onseeked', 'onseeking', 'onselect', 'onshow', 'onsort', 'onstalled', 'onsubmit', 'onsuspend', 'ontimeupdate', 'ontoggle', 'onvolumechange', 'onwaiting'],
    INPUT: ['accept', 'alt', 'autocomplete', 'capture', 'checked', 'dirname', 'disabled', 'form', 'formaction', 'formenctype', 'formmethod', 'formnovalidate', 'formtarget', 'height', 'list', 'max', 'maxlength', 'min', 'minlength', 'multiple', 'name', 'pattern', 'placeholder', 'readonly', 'required', 'size', 'src', 'step', 'type', 'value', 'width'],
    BUTTON: ['autofocus', 'autocomplete', 'disabled', 'form', 'formaction', 'formenctype', 'formmethod', 'formnovalidate', 'formtarget', 'name', 'type', 'value'],
    TEXTAREA: ['autocomplete', 'autocorrect', 'autofocus', 'cols', 'disabled', 'form', 'maxlength', 'minlength', 'name', 'readonly', 'required', 'rows', 'spellcheck', 'wrap', 'placeholder'],
    FIELDSET: ['disabled', 'form', 'name']
  },
  DATE_FMT: {
    DATE: 'YYYY-MM-DD',
    DATETIME: 'YYYY-MM-DD HH:mm:ss',
    REPORT: 'YYYY-MM-DDTHH_MM'
  }
}

export default COMMON
