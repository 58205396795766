import React, { useState, useEffect } from 'react'
import { Button, Icon } from '@atoms'
import { TextInput } from '@molecules'
import { roughStoneActions } from '@actions'
import { textUtils, validationUtils } from '@utils'
import { useToast } from '@hooks'
import { useGlobalsStore } from '@stores'
import ProvenanceItem from './provenanceItem'
import { Form } from '@organisms'

function ProvenanceSearch() {
  const [roughId, setRoughId] = useState('')
  const [rough, setRough] = useState(null)
  const [roughIdValid, setRoughIdValid] = useState(true)
  const { showErrorToast } = useToast()
  const {
    roughScanTypesMap,
    roughColoursMap,
    roughFluorescencesMap,
    roughTingesMap,
    getRoughColours,
    getRoughFluorescences,
    getRoughTinges,
    getRoughScanTypes
  } = useGlobalsStore(state => state)

  useEffect(() => {
    getRoughColours()
    getRoughFluorescences()
    getRoughTinges()
    getRoughScanTypes()
  }, [])

  function searchProvenance () {
    if (!roughId) {
      setRoughIdValid(false)
      setRough(null)
    } else {
      setRoughIdValid(true)
      roughStoneActions.getRoughStoneForBlockchain(roughId)
      .then(roughResult => {
        const rough = roughResult.data.data
        setRough(rough)
      })
      .catch(e => setRough(null))
    }
  }

  return (
    <div className="provenance">
      <h2 className="provenance__title">Search Provenance Record</h2>
      <Form
        onSubmit={searchProvenance}
        hasOutline={false}
      >
        <div className="provenance__search-input">
          <TextInput
            label="Clara ID"
            name="clara-stone-id"
            value={roughId}
            size='md'
            onChange={e => setRoughId(e.target.value)}
            validationText={validationUtils.validation({ ...(!roughIdValid && { error: 'Please fill out the Clara ID' }) })}
            // needs enter listener
          />
          <div className="provenance__search-input-button">
            <Button size='sm' type='submit'>
              <Icon name="search" />
            </Button>
          </div>
        </div>
      </Form>
      <div className="provenance__container">
        <div className="provenance__container-row">
          <div className="provenance__container-item">
            <ProvenanceItem
              name='Clara ID'
              value={rough?.id}
            />
          </div>
          <div className="provenance__container-item">
            <ProvenanceItem
              name='Seller'
              value={rough?.seller}
            />
          </div>
          <div className="provenance__container-item">
            <ProvenanceItem
              name='Country'
              value={rough?.country}
            />
          </div>
        </div>
        <div className="provenance__container-row--expand">
          <div className="provenance__container-item">
            <ProvenanceItem
              name='Mine'
              value={rough?.mine}
            />
          </div>
          {/* <div className="provenance__container-item">
              {label(
                'Pipe',
                rough && rough.pipe ? rough.pipe : '-'
                )}
              </div> */}
          <div className="provenance__container-item">
            <ProvenanceItem
              name='Date Mined'
              value={`${textUtils.formatDate(rough?.batch?.start)} - ${textUtils.formatDate(rough?.batch?.end)}`}
            />
          </div>
        </div>
        <div className="provenance__container-row">
          <div className="provenance__container-item">
            <ProvenanceItem
              name='Color'
              value={rough?.colour && roughColoursMap ? roughColoursMap[rough?.colour] : undefined}
            />
          </div>
          <div className="provenance__container-item">
            <ProvenanceItem
              name='Fluorescence'
              value={rough?.fluorescence && roughFluorescencesMap ? `${rough?.fluorescence} (${roughFluorescencesMap[rough.fluorescence]})` : undefined}
            />
          </div>
          <div className="provenance__container-item">
            <ProvenanceItem
              name='Tinge'
              value={rough?.tinge && roughTingesMap ? roughTingesMap[rough?.tinge] : undefined}
            />
          </div>
        </div>
        <div className="provenance__container-row">
          <div className="provenance__container-item">
            <ProvenanceItem
              name='Weight'
              value={rough?.weight ? rough.weight + 'ct' : undefined}
            />
          </div>
          <div className="provenance__container-item">
            <ProvenanceItem
              name='Scan Type'
              value={rough?.scanType && roughScanTypesMap ? roughScanTypesMap[rough?.scanType] : undefined}
            />
          </div>
          <div className="provenance__container-item">
            <ProvenanceItem
              name='Blockchain status'
              value={
                rough?.bcValidation
                  ? rough?.bcValidation?.success
                    ? 'In Sync'
                    : 'Out of Sync'
                  : undefined
              }
              icon={
                rough?.bcValidation && rough?.bcValidation?.success
                  ? {
                    name: 'check',
                    className: '--success'
                  }
                  : null
              }
              // pass in null value to not display info tip
              infoTip={
                rough?.bcValidation && rough?.bcValidation?.message
                  ? {
                    name: 'bcStatus',
                    message: rough.bcValidation.message,
                    icon: 'error',
                    className: 'provenance-item__status-icon--error'
                  } : null}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProvenanceSearch
