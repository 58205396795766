import React, { useState } from 'react'
import { Modal } from '@templates'
import { Button } from '@atoms'
import { TextInput } from '@molecules'

function ReturnInvoiceModal({ open, onClose, onSubmit, shipment, ...props }) {
  const [sellerInvNo, setSellerInvNo] = useState([])

  const handleChange = e => {
    const target = e.currentTarget || e.target
    setSellerInvNo(target.value)
  }

  return <Modal
    title='Create Return Invoice'
    open={open}
    onClose={onClose}
  >
    <div className="shipment-docs-modal">
      <TextInput name='sellerInvNo' label='Invoice Number being returned' value={sellerInvNo} onChange={handleChange} />
      <Button
        size='sm'
        onClick={() => onSubmit(sellerInvNo || null)}
      >
      Create
      </Button>
    </div>
  </Modal>
}

export default ReturnInvoiceModal
