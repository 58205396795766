import React from 'react'
import PropTypes from 'prop-types'
import { InfoTip } from '@molecules'
import { Icon } from '@atoms'
function ProvenanceItem ({ name, value, icon, infoTip }) {
  return (
    <div className='provenance-item'>
      <div className="provenance-item__title">
        <span>{name}</span>

      </div>
      <div className="provenance-item__value">
        {
          icon ? (
            <div className={`provenance-item__status-icon${icon.className}`}>
              <Icon
                name={icon.name}
              />
            </div>
          ) : null
        }
        {
          infoTip ? (
            <InfoTip
              name={infoTip.name}
              icon={infoTip.icon}
              iconClass={infoTip.className}
            >{infoTip.message}</InfoTip>
          ) : null
        }
        <span>{value}</span>
      </div>
    </div>
  )
}

ProvenanceItem.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.object,
  infoTip: PropTypes.object
}

ProvenanceItem.defaultProps = {
  name: '',
  value: '-'
}

export default ProvenanceItem
