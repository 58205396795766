import { useEffect } from 'react'
import { useInterval } from './hooks'
import { useAuthStore, useNotificationStore } from '@stores'
import { notificationsActions } from '@actions'

function useNotifications() {
  const {
    fetchDelay,
    notificationList,
    setNotificationList
  } = useNotificationStore(state => state)

  const {
    currentAuthenticatedUser
  } = useAuthStore(state => state)

  const userId = currentAuthenticatedUser?.attributes?.sub

  // fetch user notification immediately after
  // user is authenticated
  useEffect(() => {
    getNotifications(userId)
  }, [userId])

  // create interval of X milliseconds
  // to periodically fetch user notifications
  useInterval(() => {
    if (userId) {
      getNotifications(userId)
    }
  }, fetchDelay)

  async function markAsRead(userId, notificationId) {
    // modify the notification item in the state notification list
    // in order to give the user an immediate visual feedback
    // if the PATCH request fails it fetches the list from back end
    // again and updates state notification list (updating notification UI back to 'not read')
    // and throws an error explaining what happened.
    setNotificationList(notificationList.map(notification => {
      if (notification.notificationId === notificationId) { notification.isRead = true }

      return notification
    }))

    notificationsActions.markAsRead(userId, notificationId)
    .then(() => getNotifications(userId))
    .catch(err => {
      console.error(err)
      getNotifications(userId)
    })
  }

  async function markMultipleAsRead(notificationIds) {
    setNotificationList(notificationList.map(notification => {
      if (notificationIds.includes(notification.notificationId)) { notification.isRead = true }
      return notification
    }))

    notificationsActions.markMultipleAsRead(userId, notificationIds)
    .then(() => getNotifications(userId))
    .catch(err => {
      console.error(err)
      getNotifications(userId)
    })
  }

  async function getNotifications(userId) {
    if (userId) {
      // fetch notifications list
      notificationsActions.getUserNotifications(userId)
        .then(res => {
          if (res) { setNotificationList(res.data.data) }
        })
        // TODO: Add error handling
        .catch(err => console.warn(`Error: ${err}`))
    }
  }

  return {
    markAsRead,
    markMultipleAsRead,
    getNotifications
  }
}

export {
  useNotifications
}
