import React, { useMemo, useEffect, useState } from 'react'
import { DetailsPage } from '@templates'
import { logisticsActions } from '@actions'
import { useGlobalsStore } from '@stores'
import moment from 'moment'
import { numberUtils, textUtils, validationUtils } from '@utils'
import { useToast } from '@hooks'
import SelectShipmentStonesModal from './selectShipmentStonesModal'
import ShipmentAddress from './shipmentAddress'
import ShipmentFiles from './shipmentFiles'
import { useHistory } from 'react-router-dom'
import dottie from 'dottie'
import clone from 'just-clone'

function ShipmentDetails({ match }) {
  const shipmentId = match?.params?.shipmentId
  const { showSuccessToast } = useToast()
  const [shipment, setShipment] = useState(null)
  const [constants, setConstants] = useState(null)
  const [fromAddresses, setFromAddresses] = useState([])
  const [toAddresses, setToAddresses] = useState([])
  const [localRoughStonesList, setLocalRoughStonesList] = useState([])
  const [openRoughStonesModal, setOpenRoughStonesModal] = useState({ open: false, roughIds: [] })
  const [isEditToggled, setIsEditToggled] = useState(false)
  const history = useHistory()
  const canArchive = shipment?.condition !== 'ARCHIVED' && shipment?.condition !== 'DELETED'
  const canUnarchive = shipment?.condition === 'ARCHIVED'
  const canRemove = shipment?.condition !== 'DELETED'

  const {
    orgsList: { all: orgsList },
    getOrgsList
  } = useGlobalsStore(store => store)

  useEffect(() => {
    getOrgsList()
  }, [])

  useEffect(() => {
    if (shipment) {
      setLocalRoughStonesList(shipment.Roughs)
    }
  }, [shipment])

  useEffect(() => {
    if (shipment && orgsList) {
      setFromAddresses(getAddressesOptions(shipment?.fromOrgId))
      setToAddresses(getAddressesOptions(shipment?.toOrgId))
    }
  }, [shipment, orgsList])

  useEffect(() => {
    if (shipmentId) {
      getShipment(shipmentId)
    }
  }, [shipmentId])

  useEffect(() => {
    logisticsActions.getShipmentConstants()
    .then(response => setConstants(response.data.data))
  }, [])

  const fields = useMemo(() => ([
    {
      label: 'Type',
      name: 'type',
      value: constants?.SHIPMENT_TYPES.find(c => c.value === shipment?.type)?.description,
      componentName: 'textInput',
      canEdit: false
    },
    {
      name: 'roughIds',
      customComponent: SelectShipmentStonesModal,
      value: shipment?.roughIds,
      roughStones: shipment?.Roughs,
      isEditToggled,
      canEdit: true
    },
    {
      label: 'Tracking Number',
      name: 'trackingNumber',
      value: shipment?.trackingNumber ?? '',
      componentName: 'textInput',
      canEdit: true
    },
    {
      label: 'Total Weight',
      name: 'totalWeight',
      value: numberUtils.numFmt(shipment?.Roughs?.reduce((totalWeight, rough) => {
        return Number(totalWeight) + Number(rough.weight)
      }, 0), 2, { suffix: 'ct', thousandSeperator: true }),
      componentName: 'readonly',
      canEdit: true
    },
    {
      label: 'From Org',
      name: 'fromOrg',
      value: shipment?.fromOrgId,
      componentName: 'dropDown',
      handleChange: (e, doChange) => {
        setFromAddresses(getAddressesOptions(e?.currentTarget?.value))
        return doChange()
      },
      options: orgsList?.map(org => ({ value: org?.orgId, label: org?.commonName })),
      canEdit: false
    },
    {
      label: 'To Org',
      name: 'toOrg',
      value: shipment?.toOrgId,
      componentName: 'dropDown',
      handleChange: (e, doChange) => {
        setToAddresses(getAddressesOptions(e?.currentTarget?.value))
        return doChange()
      },
      options: orgsList?.map(org => ({ value: org?.orgId, label: org?.commonName })),
      canEdit: false
    },
    {
      legend: 'From Address',
      componentName: 'fieldset',
      name: 'fromAddressFieldSet',
      value: { ...shipment?.fromAddress },
      canEdit: true,
      children: [
        {
          customComponent: ShipmentAddress,
          formData: shipment?.fromAddress,
          value: { ...shipment?.fromAddress },
          title: 'From Address',
          name: 'fromAddress',
          canEdit: true
        }
      ]
    },
    {
      legend: 'To Address',
      componentName: 'fieldset',
      name: 'toAddressFieldSet',
      value: { ...shipment?.toAddress },
      canEdit: true,
      children: [
        {
          customComponent: ShipmentAddress,
          formData: shipment?.toAddress,
          value: { ...shipment?.toAddress },
          title: 'To Address',
          name: 'toAddress',
          canEdit: true
        }
      ]
    },
    {
      label: 'Notes',
      name: 'notes',
      value: shipment?.notes ?? '',
      componentName: 'textarea',
      canEdit: true,
      rows: 3
    },
    {
      label: '',
      name: 'blank',
      componentName: 'blank'
    },
    {
      label: 'Status',
      name: 'status',
      isClearable: false,
      value: shipment?.status,
      componentName: 'dropdown',
      options: constants?.SHIPMENT_STATUSES?.map(c => ({ value: c.value, label: c.description })),
      canEdit: true
    },
    {
      label: 'Condition',
      name: 'condition',
      value: textUtils.formatDescription(shipment?.condition),
      componentName: 'textInput',
      canEdit: false
    },
    {
      label: 'Created At',
      name: 'createdAt',
      value: moment(shipment?.createdAt).toLocaleString(),
      componentName: 'textInput',
      canEdit: false
    },
    {
      label: 'Last Updated',
      name: 'updatedAt',
      value: moment(shipment?.updatedAt).toLocaleString(),
      componentName: 'textInput',
      canEdit: false
    }

  ]), [shipment, constants, orgsList, fromAddresses, toAddresses, localRoughStonesList])

  function getAddressesOptions(orgId) {
    const org = orgsList.find(o => o.orgId === orgId)

    if (org) {
      return org?.address?.map(address => ({
        value: address.name,
        label: `${address.name} | ${address.line1} | ${address.city} | ${address.country}`
      }))
    }

    return []
  }

  function getShipment(id) {
    logisticsActions.getShipments({ id, columns: '[Files,Rough]' })
    .then(response => setShipment(response.data.data[0]))
  }

  async function handleOnSubmit(values) {
    return logisticsActions.editShipment(shipmentId, { ...values })
    .then(() => {
      showSuccessToast('Shipment saved.')
      getShipment(shipmentId)
    })
  }

  function handleOnArchive() {
    logisticsActions.setShipmentCondition({ shipmentIds: [shipmentId], condition: 'ARCHIVED' })
    .then(() => {
      showSuccessToast('Shipment has been archived.')
      getShipment(shipmentId)
      history.push('/shipments')
    })
  }

  function handleOnRemove() {
    logisticsActions.setShipmentCondition({ shipmentIds: [shipmentId], condition: 'DELETED' })
    .then(() => {
      showSuccessToast('Shipment has been removed.')
      getShipment(shipmentId)
      history.push('/shipments')
    })
  }

  function handleOnUnarchive() {
    logisticsActions.setShipmentCondition({ shipmentIds: [shipmentId], condition: 'ACTIVE' })
    .then(() => {
      showSuccessToast('Shipment has been unarchived.')
      getShipment(shipmentId)
    })
  }

  function handleHasValuesChanged(editedValues, originalValues) {
    const hasChanged = {}
    const values = dottie.transform(clone(editedValues))
    if (values?.fromAddress?.fieldName) delete values.fromAddress.fieldName
    if (values?.toAddress?.fieldName) delete values.toAddress.fieldName

    Object.keys(values).forEach(fieldName => {
      const originalValue = originalValues.find(v => v.name === fieldName)
      if (originalValue) {
        hasChanged[fieldName] = validationUtils.diffValues(originalValue?.value, values[fieldName])
      }
    })

    return Object.values(hasChanged).some(v => v)
  }

  return (
    <div className='shipment-details'>
      <DetailsPage
        canEdit={true}
        fields={fields}
        onSubmit={handleOnSubmit}
        onArchive={canArchive ? handleOnArchive : null}
        onUnarchive={canUnarchive ? handleOnUnarchive : null}
        onHasValuesChanged={handleHasValuesChanged}
        onRemove={canRemove ? handleOnRemove : null}
        onEditToggled={(edit) => {
          setOpenRoughStonesModal({ ...openRoughStonesModal, disabled: !edit })
          setIsEditToggled(edit)
        }}
        title ={{
          label: 'Shipment ID',
          value: shipment?.id || ''
        }}
      />
      <ShipmentFiles
        shipmentId={shipmentId}
        shipment={shipment}
        refreshShipment={() => getShipment(shipmentId)}
        isEditToggled={isEditToggled}
      />
    </div>
  )
}

export default ShipmentDetails
