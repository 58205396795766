import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { baselineActions, organizationActions, reportActions } from '@actions'
import { useToast, usePageTitle } from '@hooks'
import { useGlobalsStore, useAuthStore, useModalStore } from '@stores'
import ColourMixGrid from '../colourMixGrid'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import clone from 'just-clone'
import BuyerSettingsDetailsPage from './buyerSettingsDetailsPage'
import BaselineSheet from '../baselineSheet'
import { fileUtils, textUtils } from '@utils'
import { COMMON } from '@constants'

function BaselineDetails(props) {
  const { baselineId } = props?.match?.params
  const { canEdit = true } = props
  const [fields, setFields] = useState([])
  const [baseline, setBaseline] = useState(null)
  const [baselineList, setBaselineList] = useState([])
  const parentBaseline = useMemo(() => baselineList?.find(({ id }) => id === baseline?.parentBaselineId), [baseline, baselineList])
  const history = useHistory()

  usePageTitle(props?.title, baselineId, baseline?.name)

  const { hasAdmin, permissionsAdminCache } = useAuthStore(state => state)
  const isAdmin = hasAdmin(organizationActions.getOrganizationList)

  const {
    getOrgsList,
    orgsList: { all: orgsList },
    getPolishedColours,
    polishedColours: colours,
    polishedColoursMap: coloursMap,
    getBaselineShapeTypes,
    baselineShapeTypesMap
  } = useGlobalsStore(state => state)
  const {
    setModal
  } = useModalStore(state => state)

  const {
    showSuccessToast,
    showErrorToast
  } = useToast()

  useEffect(() => {
    baselineActions.getBaselines({ condition: 'ACTIVE' })
    .then(response => setBaselineList(response?.data?.data?.sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt))))
  }, [])

  useEffect(() => {
    baselineActions.getBaselines({ id: baselineId, columns: '[Orders,ParentBaseline]' }).then(({ data }) => {
      setBaseline(data?.data[0])
    })
  }, [baselineId])

  useEffect(() => {
    getBaselineShapeTypes()
  }, [])

  useEffect(() => {
    getOrgsList()
    getPolishedColours()
  }, [baseline])

  useEffect(() => {
    setFields([
      ...(isAdmin ? [{
        label: 'Owner',
        value: baseline?.buyerId,
        name: 'buyerId',
        componentName: 'dropdown',
        options: orgsList?.map(org => ({ label: org.commonName, value: org.orgId })),
        canEdit: false
      }] : []),
      {
        label: 'Name',
        value: baseline?.name,
        name: 'name',
        componentName: 'textInput',
        canEdit: true
      },
      {
        label: 'Parent Baseline',
        value: baseline?.parentBaselineId,
        name: 'parentBaselineId',
        options: baselineList.filter((b => (b.buyerId == null || b.buyerId === baseline?.buyerId) && !b.parentBaselineId))
          .map(b => ({ label: `${b.name} | ${baselineShapeTypesMap?.[b.shapeType] ?? ''}${b.date ? ' | ' + textUtils.formatDate(b.date) : ''}`, value: b.id })),
        componentName: 'dropdown',
        canEdit: true,
        isClearable: false,
        shouldDisplay: !!baseline?.colourMix
      },
      {
        label: 'Shape Type',
        value: baselineShapeTypesMap?.[baseline?.shapeType] ?? '',
        name: 'shapeType',
        canEdit: false
      },
      {
        label: 'Type',
        value: baseline?.parentBaselineId ? 'Computed' : 'Uploaded',
        name: 'baselineType',
        canEdit: false
      },
      {
        label: 'Price Date',
        value: moment((baseline?.date || baseline?.ParentBaseline?.date)).toLocaleString(),
        name: 'priceDate',
        canEdit: false,
        shouldDisplay: (baseline?.date || baseline?.ParentBaseline?.date) != null
      },
      {
        label: 'Last Updated',
        value: moment(baseline?.updatedAt).toLocaleString(),
        name: 'updatedAt',
        canEdit: false
      },
      {
        label: '',
        customComponent: ColourMixGrid,
        value: baseline?.colourMix,
        name: 'colourMix',
        span: true,
        canEdit: true,
        colours,
        shouldDisplay: !!baseline?.colourMix
      }
    ])
  }, [baseline, orgsList, parentBaseline, baseline?.buyerId, baselineShapeTypesMap, permissionsAdminCache])

  async function handleOnValidate(baselineValues) {
    const { name = baseline.name, colourMix } = baselineValues
    const errors = []

    if (baselineValues.parentBaselineId && baselineValues.parentBaselineId !== baseline.parentBaselineId) {
      const newParentBaseline = baselineList.find(({ id }) => id === baselineValues.parentBaselineId)
      if (newParentBaseline && newParentBaseline.shapeType !== baseline.shapeType) {
        await new Promise((resolve, reject) => {
          setModal({
            id: 'baselineDetailsSaveParentBaselineUpdate',
            title: 'Confirm Parent Baseline Update',
            message: <>
            New Parent Baseline has a Shape Type that does not match current Parent Baseline Shape Type.
              <br/>
              <b>Your current Baseline Shape Type will be changed to match new Parent Baseline Shape Type.</b>
              <br/>
            This change will affect this Baseline&apos;s current Orders.
              <br/>
            Do you confirm this change?
            </>,
            onSubmit: resolve,
            onCancel: reject
          })
        })
      }
    }

    if (name === null) {
      errors.push('Name is required')
    }
    const mixGridErrors = colourMix && ColourMixGrid.validate(colourMix)
    if (mixGridErrors) {
      errors.push(...(mixGridErrors.length ? mixGridErrors : Object.keys(mixGridErrors).reduce((gridErrors, errColour) => gridErrors.concat(`Row ${coloursMap?.[errColour] ?? ''} must total 100%.`), [])))
    }
    if (errors.length) {
      errors.forEach(error => showErrorToast(error))
      throw new Error(errors)
    }
  }

  async function handleOnSubmit(baselineValues) {
    const baselineData = clone(baselineValues)
    // if (!editedValues.default) delete editedValues.default

    return baselineActions.editBaseline(baseline.id, { ...baselineData })
    .then(() => {
      showSuccessToast('Baseline saved.')
      history.push('/buyer-settings')
    })
  }

  function handleConfirmModal(baselineValues) {
    const ignoreKeys = ['name']
    const baselineKeys = Object.keys(baselineValues)
    return baselineKeys.some(key => !ignoreKeys.includes(key)) ? 'baselineDetailsSaveConfirmation' : null
  }

  function handlePreview() {
    const date = baseline?.date || baseline?.ParentBaseline?.date
    const title = baseline?.name + ' | ' + baselineShapeTypesMap?.[baseline.shapeType] + (date ? ' | ' + textUtils.formatDate(date) : '')
    setModal({
      id: 'baselineDetailsSheetPreview',
      title,
      message: <BaselineSheet baselineId={baselineId} />,
      customButtonsRenderer: () => null,
      className: 'baseline-details-preview-modal x-scroll sticky'
    })
  }

  function handleExport(format) {
    reportActions.exportBaseline(format, baseline?.id)
    .then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))
    .then(() => {
      showSuccessToast('Baseline has been exported.')
    })
  }

  function handleArchive() {
    baselineActions.setBaselineCondition([baseline?.id], 'ARCHIVED')
    .then(() => {
      showSuccessToast('Baseline has been archived.')
      history.push('/buyer-settings')
    })
  }

  function handleUnarchive() {
    baselineActions.setBaselineCondition([baseline?.id], 'ACTIVE')
    .then(() => {
      showSuccessToast('Baseline has been unarchived.')
      history.push('/buyer-settings')
    })
  }

  function handleRemove() {
    baselineActions.setBaselineCondition([baseline?.id], 'DELETED')
    .then(() => {
      showSuccessToast('Baseline has been removed.')
      history.push('/buyer-settings')
    })
  }

  const canArchive = baseline?.condition !== 'ARCHIVED' && baseline?.condition !== 'DELETED'
  const canUnarchive = baseline?.condition === 'ARCHIVED'
  const canRemove = baseline?.condition !== 'DELETED'

  return (
    <div className='center'>
      <BuyerSettingsDetailsPage
        fields={fields}
        title={{
          label: 'Baseline ID',
          value: baselineId
        }}
        onArchive={canArchive ? handleArchive : null}
        onUnarchive={canUnarchive ? handleUnarchive : null}
        onRemove={canRemove ? handleRemove : null}
        canEdit={canEdit && baseline?.condition === 'ACTIVE'}
        onValidate={handleOnValidate}
        onSubmit={handleOnSubmit}
        confirmModal={handleConfirmModal}
        value={baseline}
        extraIcons={[
          {
            icon: 'view',
            title: 'Preview',
            viewOnly: true,
            onClick: handlePreview
          },
          {
            icon: 'xlsxDownload',
            title: 'Download Excel',
            viewOnly: true,
            onClick: () => handleExport('xlsx')
          },
          {
            icon: 'csvDownload',
            title: 'Download CSV',
            viewOnly: true,
            onClick: () => handleExport('csv')
          }
        ]}
      />
    </div>
  )
}

BaselineDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string
}

export default BaselineDetails
