import React, { useState, useEffect } from 'react'
import { useGlobalsStore, useAuthStore, useRoughStoneStore } from '@stores'
import { useToast } from '@hooks'
import { assortmentActions } from '@actions'
import { DetailsPage } from '@templates'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import AssortmentDetailsProvenanceModal from './assortment_details_provenance_modal'
import ProgressiveProfitMatrix from '../inc/progressiveProfitMatrix'
import { SELLER } from '@constants'

function AssortmentDetailsPanel({ assortment, refreshAssortmentDetails, roughStoneParams, operation }) {
  const {
    roughStonesList: { [JSON.stringify({ ...roughStoneParams, condition: 'ACTIVE' })]: roughStonesList },
    getRoughStonesList
  } = useRoughStoneStore(store => store)

  const { hasAdmin } = useAuthStore(state => state)
  const isAdmin = hasAdmin(assortmentActions.editAssortment)

  useEffect(() => {
    refreshRoughStonesList()
  }, [roughStoneParams])

  function refreshRoughStonesList() {
    getRoughStonesList({ ...roughStoneParams, condition: 'ACTIVE' })
  }

  const [assortmentCategories, setAssortmentCategories] = useState()
  useEffect(() => {
    assortmentActions.getAssortmentCategories()
    .then(res => setAssortmentCategories(res.data.data))

    getReserveMethods()
  }, [])

  const sellerKey = { sellerId: assortment?.sellerId }

  const {
    getOrgById,
    getReserveMethods,
    getProvenanceTypesList,
    reserveMethods,
    provenanceTypesList: { [JSON.stringify(sellerKey)]: provenanceTypes }
  } = useGlobalsStore(store => store)

  const [sellerName, setSellerName] = useState('')

  useEffect(() => {
    if (assortment) {
      getProvenanceTypesList(sellerKey)

      getOrgById(assortment.sellerId)
      .then(org => {
        setSellerName(org?.commonName)
      })
    }
  }, [assortment])

  const { showSuccessToast } = useToast()
  const [fields, setFields] = useState([])

  useEffect(() => {
    if (assortment) {
      setFields([
        {
          label: 'Assortment ID',
          value: assortment?.id,
          name: 'assortmentId',
          componentName: 'textInput',
          canEdit: false
        },
        {
          label: 'Assortment Name',
          value: assortment.name,
          name: 'name',
          componentName: 'textInput',
          canEdit: true
        },
        {
          label: 'Assortment Category',
          value: assortment.assortmentCategory,
          name: 'assortmentCategory',
          componentName: 'dropdown',
          options: assortmentCategories?.map(cat => ({ value: cat, label: cat })),
          canEdit: true,
          creatable: true
        },
        {
          label: 'Provenance Type',
          value: assortment.provenanceTypeId,
          name: 'provenanceTypeId',
          componentName: 'dropdown',
          options: provenanceTypes?.map(type => ({
            value: type.id,
            label: type.description
          })),
          canEdit: true
        },
        {
          label: 'Original Reserve Price',
          value: assortment.reservePpcOriginal,
          name: 'reservePpcOriginal',
          componentName: 'textInput',
          type: 'number',
          canEdit: true
        },
        {
          label: 'Override Reserve Price',
          value: assortment.reservePpcOverride,
          name: 'reservePpcOverride',
          componentName: 'textInput',
          type: 'number',
          canEdit: true
        },
        {
          label: 'Reserve method',
          value: assortment.reserveMethod,
          name: 'reserveMethod',
          componentName: 'dropdown',
          options: reserveMethods?.map(method => ({
            value: method.value,
            label: method.description
          })),
          canEdit: true,
          infoTip: true
        },
        {
          label: 'Seller',
          value: sellerName,
          name: 'sellerName',
          componentName: 'textInput',
          canEdit: false
        },
        {
          label: 'Planning Priority',
          value: assortment.planningPriority,
          name: 'planningPriority',
          componentName: 'dropdown',
          options: SELLER.ASSORTMENT_PRIORITIES,
          shouldDisplay: isAdmin,
          canEdit: isAdmin,
          renderOverrides: values => {
            if (!isAdmin) return
            const bids = values.find(v => v.name === 'generateBids')?.value
            const overrides = { canEdit: !bids }
            if (!overrides.canEdit) overrides.value = SELLER.ASSORTMENT_PRIORITIES.at(-1).value
            return overrides
          }
        },
        {
          label: 'Matching Priority',
          value: assortment.matchingPriority,
          name: 'matchingPriority',
          componentName: 'dropdown',
          options: SELLER.ASSORTMENT_PRIORITIES,
          shouldDisplay: isAdmin,
          canEdit: isAdmin,
          renderOverrides: values => {
            if (!isAdmin) return
            const bids = values.find(v => v.name === 'generateBids')?.value
            const overrides = { canEdit: !bids }
            if (!overrides.canEdit) overrides.value = SELLER.ASSORTMENT_PRIORITIES.at(-1).value
            return overrides
          }
        },
        {
          label: 'Send Bids',
          value: assortment.generateBids,
          name: 'generateBids',
          componentName: 'checkbox',
          shouldDisplay: isAdmin,
          canEdit: isAdmin,
          span: false
        },
        {
          label: 'Use Progressive Profit',
          value: assortment.useProgressiveProfit,
          name: 'useProgressiveProfit',
          componentName: 'checkbox',
          shouldDisplay: isAdmin,
          canEdit: isAdmin,
          span: false
        },
        {
          label: 'Progressive Profits',
          value: assortment.progressiveProfitMatrix,
          name: 'progressiveProfitMatrix',
          customComponent: ProgressiveProfitMatrix,
          shouldDisplay: isAdmin,
          canEdit: isAdmin,
          span: true,
          renderOverrides: values => {
            const usePp = values.find(v => v.name === 'useProgressiveProfit')?.value
            return { canEdit: usePp }
          }
        },
        {
          label: 'Condition',
          value: assortment.condition,
          name: 'condition',
          componentName: 'dropdown',
          options: [
            { value: 'ACTIVE', label: 'Active' },
            { value: 'ARCHIVED', label: 'Archived' },
            { value: 'DELETED', label: 'Deleted' }
          ],
          canEdit: false
        },
        {
          label: 'Stone Count',
          value: assortment.available_roughs,
          name: 'available_roughs',
          componentName: 'textInput',
          canEdit: false
        },
        {
          label: 'Created At',
          value: moment(assortment?.createdAt).toLocaleString(),
          name: 'createdAt',
          componentName: 'textInput',
          canEdit: false
        },
        {
          label: 'Last Updated',
          value: moment(assortment?.updatedAt).toLocaleString(),
          name: 'updatedAt',
          componentName: 'textInput',
          canEdit: false
        }
      ])
    }
  }, [assortment, assortmentCategories, provenanceTypes, reserveMethods, sellerName, isAdmin])

  function handleOnSubmit(editedValues) {
    const { condition, ...assortmentData } = editedValues
    const conditionPromise = condition ? assortmentActions.setAssortmentCondition([assortment?.id], condition) : Promise.resolve()
    return conditionPromise
      .then(() => {
        if (Object.keys(assortmentData).length) {
          if ('generateBids' in assortmentData && assortmentData.generateBids) {
            assortmentData.planningPriority = SELLER.ASSORTMENT_PRIORITIES.at(-1).value
            assortmentData.matchingPriority = SELLER.ASSORTMENT_PRIORITIES.at(-1).value
          }
          return assortmentActions.editAssortment({ assortmentId: assortment?.id, ...assortmentData })
        }
      })
      .then(() => {
        showSuccessToast('Assortment saved.')
        refreshAssortmentDetails()
      })
  }

  const history = useHistory()
  function handleArchive() {
    assortmentActions.setAssortmentCondition([assortment?.id], 'ARCHIVED')
    .then(() => {
      showSuccessToast('Assortment has been archived.')
      history.push('/assortments')
    })
  }

  function handleUnarchive() {
    assortmentActions.setAssortmentCondition([assortment?.id], 'ACTIVE')
    .then(() => {
      showSuccessToast('Assortment has been unarchived.')
      history.push('/assortments')
    })
  }

  function handleRemove() {
    assortmentActions.setAssortmentCondition([assortment?.id], 'DELETED')
    .then(() => {
      showSuccessToast('Assortment has been removed.')
      history.push('/assortments')
    })
  }

  const canArchive = assortment?.condition !== 'ARCHIVED'
  const canUnarchive = assortment?.condition === 'ARCHIVED'
  const customConfirmationRenderer = {
    archive: ({ defaultMessage }) => (
      <>
        {defaultMessage}<br/><br/>
        All stones in this assortment will also be archived.
      </>
    )
  }
  const [changeProvenanceModal, setChangeProvenanceModal] = useState(false)
  const extraButtons = [
    {
      onClick: () => setChangeProvenanceModal(true),
      caption: 'Change Provenance Details',
      viewOnly: true
    }
  ]

  return (
    <>
      <DetailsPage
        canEdit={assortment?.condition === 'ACTIVE'}
        isEdit={operation === 'edit'}
        fields={fields}
        onArchive={canArchive ? handleArchive : null}
        onUnarchive={canUnarchive ? handleUnarchive : null}
        onRemove={handleRemove}
        onSubmit={handleOnSubmit}
        extraButtons={extraButtons}
        title={{
          label: 'Assortment ID',
          value: assortment?.id || ''
        }}
        customConfirmationRenderer={customConfirmationRenderer}
      />
      <AssortmentDetailsProvenanceModal
        roughStonesList={roughStonesList?.data}
        assortment={assortment}
        refreshAssortment={refreshAssortmentDetails}
        refreshRoughStones={refreshRoughStonesList}
        open={changeProvenanceModal}
        onClose={() => setChangeProvenanceModal(false)}
      />
    </>
  )
}

export default AssortmentDetailsPanel
