import React from 'react'
import { Tabs } from '@templates'
import MineList from './mineList'
import PipeList from './pipeList'
import BatchList from './batchList'
import CountryList from './countryList'

const items = [
  {
    title: 'Countries',
    Component: CountryList
  },
  {
    title: 'Mines',
    Component: MineList
  },
  {
    title: 'Pipes',
    Component: PipeList
  },
  {
    title: 'Batches',
    Component: BatchList
  }
]

function SellerSettings(props) {
  return (
    <div className="seller-settings">
      <h2>Seller Settings</h2>
      <Tabs
        name='sellerSettings'
        items={items}
      />
    </div>
  )
}

export default SellerSettings
