import React from 'react'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router-dom'
import { useAuthStore } from '@stores'

const DEFAULT_MESSAGE = 'Are you sure you want to leave this page? Any unsaved changes will be lost.'

function RouterPrompt({ when, message }) {
  const {
    isAuthenticated
  } = useAuthStore(state => state)

  if (!isAuthenticated) return null

  return (
    <Prompt
      when={when}
      message={message}
    />
  )
}

RouterPrompt.propTypes = {
  when: PropTypes.bool,
  message: PropTypes.string
}

RouterPrompt.defaultProps = {
  when: false,
  message: DEFAULT_MESSAGE
}
export default RouterPrompt
