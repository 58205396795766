import React from 'react'
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, Cell } from 'recharts'

function CurrentOrderChart({ lines, getLineDescription, getLineQuantityDescription, chartColours, onClick, onHover }) {
  function getLineLabel(order, line) {
    return (
      <>
        {order.id}: {order.name}<br/>
        {getLineDescription(line)}, {getLineQuantityDescription(line)}
      </>
    )
  }

  function orderClicked(lineId) {
    const clicked = lines.find(l => l.id === lineId)
    if (clicked && clicked.quantityUnfilled) {
      onClick(clicked.id)
    } else {
      onClick(null)
    }
  }

  if (!lines?.length) return null
  return (
    <ResponsiveContainer width='100%' height={400}>
      <BarChart data={lines}>
        <CartesianGrid />
        <XAxis hide dataKey="id" />
        <YAxis
          label={{ value: 'Quantity Purchased / Requested', position: 'insideLeft', angle: -90, dy: 115 }}
          domain={[0, 'linesMax+1']}
          allowDecimals={false}
        />
        <Tooltip labelFormatter={id => {
          const line = lines.find(line => line.id === id)
          return line ? getLineLabel({ name: line.orderName, id: line.orderId }, line) : id
        }} />
        <Bar
          dataKey="quantityFilled"
          stackId="qty"
          name="Filled"
          onMouseEnter={({ id }) => onHover(id)}
          onMouseLeave={() => onHover(null)}
        >
          {
            lines.map((item, i) => <Cell key={item.id} fill={chartColours[i % chartColours.length]} onClick={() => orderClicked(item.id)} />)
          }
        </Bar>
        <Bar
          dataKey="quantityUnfilled"
          stackId="qty"
          name="Unfilled"
          onMouseEnter={({ id }) => onHover(id)}
          onMouseLeave={() => onHover(null)}
        >
          {
            lines.map((item, i) => <Cell key={item.id} fill={chartColours[i % chartColours.length]} opacity="0.5" onClick={() => orderClicked(item.id)} />)
          }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default CurrentOrderChart
