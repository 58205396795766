import React, { useState } from 'react'
import { Icon, Button } from '@atoms'
import { useNotificationStore } from '@stores'
import { useAuth, useProgressList } from '@hooks'
import { AlertModal } from '@templates'

function getUnreadNotificationsCounter(notificationList) {
  return notificationList.filter(notification => !notification.isRead).length || 0
}

function TopBar() {
  const {
    setIsNotificationsOpen,
    notificationList
  } = useNotificationStore(state => state)

  const {
    isProgressListDone
  } = useProgressList()

  const {
    signOut,
    isAuthenticated
  } = useAuth()

  const [
    isProgressListModalOpen,
    setIsProgressListModalOpen
  ] = useState(false)

  if (!isAuthenticated) return null

  const unreadNotificationsCount = getUnreadNotificationsCounter(notificationList)

  function handleSignOut() {
    if (!isProgressListDone()) {
      setIsProgressListModalOpen(true)
      return
    }
    signOut()
  }

  return (
    <div className="topBar">
      <div
        className="topBar__notification-icon"
        onClick={() => setIsNotificationsOpen(true)}
      >
        <Icon
          name="bell"
          size='lg'
        />
        {
          unreadNotificationsCount
            ? <span className="topBar__notification-counter">
              {unreadNotificationsCount}
            </span> : null
        }
      </div>
      |
      <Button
        typeVariant="action"
        size="sm"
        onClick={() => handleSignOut()}
      > Sign out </Button>
      <AlertModal
        open={isProgressListModalOpen}
        closeable={true}
        onClose={() => setIsProgressListModalOpen(false)}
        onSubmit={() => signOut()}
        title='Alert!'
        onSubmitText='Sign Out'
        message='Files waiting to be uploaded will be lost. Would you like to sign out?'
      />
    </div>
  )
}

export default TopBar
