import React from 'react'
import PropTypes from 'prop-types'
import Modal from './modal'
import { Button } from '@atoms'

function AlertModal({
  open,
  title,
  position,
  message,
  onClose,
  onSubmit,
  closeable,
  children,
  onCloseText,
  onSubmitText,
  ...props
}) {
  return (
    <Modal
      open={open}
      title={title}
      position={position}
      onClose={onClose}
      closeable={closeable}
      {...props}
    >
      {message ? <div className='modal__message'>{message}</div> : null}
      {children}
      <div className='modal__buttons'>
        {closeable && onClose ? <Button typeVariant='action' size='sm' onClick={onClose}>{onCloseText}</Button> : null}
        <Button typeVariant='primary' size='sm' onClick={onSubmit}>{onSubmitText}</Button>
      </div>
    </Modal>
  )
}

AlertModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  position: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  closeable: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onSubmitText: PropTypes.string,
  onCloseText: PropTypes.string
}

AlertModal.defaultProps = {
  open: false,
  title: 'Title',
  position: 'center',
  onCloseText: 'Cancel',
  onSubmitText: 'OK',
  message: '',
  closeable: true,
  onClose: () => {},
  onSubmit: () => console.warn('AlertModal.js => onSubmit callback not implemented')
}

export default AlertModal
