import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import InfoTip from '../infoTip/infoTip'
import { arrayUtils, objectUtils } from '@utils'
import { COMMON } from '@constants'

function RadioGroup({
  name,
  value,
  label,
  visible,
  options,
  infoTip,
  ...props
}) {
  const validHTMLElementProps = useMemo(() => {
    const propKeys = Object.keys(props)
    const validKeys = arrayUtils.intersectBy(propKeys, COMMON.HTML_ATTRIBUTES.GLOBAL.concat(COMMON.HTML_ATTRIBUTES.INPUT), (x) => x.toLowerCase())
    return objectUtils.pick(props, validKeys)
  }, [props])
  return (
    visible
    && <div className='radio-group'>
      <span className='radio-group__label'>{label}</span>
      {infoTip ? <InfoTip name={name}>{infoTip}</InfoTip> : null}
      {
        options.map(option => {
          return (
            <div
              key={option.name}
              className='radio-group__option'>
              <input
                name={name}
                type='radio'
                value={option.value}
                className='radio-group__input'
                checked={option.value === value}
                disabled={option.disabled}
                {...validHTMLElementProps}
              />
              <label
                key={option.name}
                htmlFor={option.name}
              >
                <span className="subtext">
                  {option.label}
                </span>
              </label>
            </div>
          )
        })
      }
    </div>
  )
}

RadioGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  visible: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  infoTip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
}

RadioGroup.defaultProps = {
  label: '',
  options: [],
  visible: true
}

export default RadioGroup
