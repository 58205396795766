import React, { useEffect, useState } from 'react'
import { Table } from '@organisms'
import { useHistory, NavLink } from 'react-router-dom'
import { reportActions, userActions } from '@actions'
import { Checkbox } from '@atoms'
import { TextInput, InfoTip } from '@molecules'
import { ConfirmationModal } from '@templates'
import { useGlobalsStore, useAuthStore } from '@stores'
import { useToast } from '@hooks'
import { arrayUtils, fileUtils, textUtils } from '@utils'
import { COMMON } from '@constants'

const columns = [
  {
    Header: 'Username',
    accessor: 'username',
    dataType: 'string',
    Cell: cellInfo => {
      return (
        <NavLink
          className="link"
          to={`/users/${cellInfo.row.original.userId}`}
          id={cellInfo.row.original.userId}
        >
          {cellInfo.row.values.username}
        </NavLink>
      )
    }
  },
  {
    Header: 'Name',
    accessor: 'name',
    dataType: 'string'
  },
  {
    Header: 'Organization',
    accessor: 'orgName',
    dataType: 'string'
  },
  {
    Header: 'Roles',
    accessor: 'roles',
    Cell: ({ cell: { value } }) => (value + ' ')
  },
  {
    Header: 'Email',
    accessor: 'email',
    dataType: 'string'
  },
  {
    Header: 'Phone Number',
    accessor: 'phoneNumber',
    dataType: 'string'
  },
  {
    Header: 'Condition',
    id: 'enabled',
    accessor: row => row?.enabled ? 'Enabled' : 'Disabled',
    dataType: 'string',
    filterType: 'checkbox',
    enums: ['Enabled', 'Disabled']
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    dataType: 'date',
    filterType: 'date'
  }
]

function UserList() {
  const [localUsersList, setLocalUsersList] = useState({ enabled: [], disabled: [] })
  const [activeTab, setActiveTab] = useState('enabled')
  const { showSuccessToast, showErrorToast } = useToast()
  const { getUsersList, getOrgById, setUsersList, resetUsersList } = useGlobalsStore(store => store)
  const [openModal, setOpenModal] = useState({ name: false, selectedRows: [] })
  const [newPass, setNewPass] = useState()
  const [permanentPass, setPermanentPass] = useState(false)
  const { hasAdmin } = useAuthStore(state => state)
  const isAdmin = hasAdmin(userActions.getUsersList)

  const updateLocalUsers = async function() {
    getUsersList()
    .then(async usersList => {
      for (const u of usersList) {
        const org = await getOrgById(u.orgId)
        if (org) u.orgName = org.commonName
      }
      setLocalUsersList(arrayUtils.groupBy(usersList, x => x.enabled ? 'enabled' : 'disabled'))
    })
  }

  useEffect(() => {
    updateLocalUsers()
  }, [])

  const history = useHistory()
  function navigateTo(path) {
    history.push(path)
  }

  const resetUser = function (selectedRows, data) {
    if (data?.action === 'RESEND' || data?.action === 'RESET_PASS') {
      selectedRows.map(row => {
        const params = { type: data.action }
        if (newPass) params.newPassword = newPass
        userActions.resetUser(row.username, params)
        .then(result => {
          if (result.data.success) {
            showSuccessToast(result.data.message)
          }
        })
      })
    } else if (data?.action === 'SET_PASS') {
      selectedRows.map(row => {
        if (!newPass) showErrorToast('Missing new password')
        else {
          userActions.resetUser(row.username, { type: data.action, newPassword: newPass, permanent: permanentPass })
          .then(result => {
            if (result.data.success) {
              showSuccessToast(result.data.message)
            }
          })
        }
      })
    }
  }

  const changeUsersCondition = async function (selectedRows, action) {
    if (['enable', 'disable'].includes(action)) {
      Promise.all(selectedRows.map(row => {
        return userActions.setUserCondition({ username: row.username, enabled: action === 'enable' })
        .then(result => {
          if (result.data.success) {
            showSuccessToast('User status has been updated.')
          }
        })
      }))
      .then(async () => {
        await setUsersList()
        updateLocalUsers()
      })
    }
  }

  function handleArchive(row) {
    userActions.setUserCondition({ username: row.username, enabled: false })
    .then(() => {
      showSuccessToast('User has been archived.')
      resetUsersList()
      updateLocalUsers()
    })
  }

  const rowActions = [
    {
      actionName: 'archive',
      callback: handleArchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.enabled
    }
  ]

  const tableTabs = [{
    label: 'Enabled',
    params: 'enabled'
  },
  {
    label: 'Disabled',
    params: 'disabled'
  }]

  return (
    <div className="user-list">
      <Table
        title="Users"
        data={localUsersList?.[activeTab]}
        columns={columns}
        tableTabs={tableTabs}
        isMultiSelect={isAdmin}
        rowActions={rowActions}
        getDataCallback={(tab) => {
          setActiveTab(tab)
          setUsersList()
        }}
        topBarActions={isAdmin ? [
          {
            componentName: 'dropdown',
            shouldDisplayActions: isAdmin,
            enableOnSelect: true,
            options: [
              {
                label: 'Resend Password',
                isDisabled: rows => rows.some(row => row.original.status !== 'FORCE_CHANGE_PASSWORD'), //! isAdmin,
                callback: selectedRows => setOpenModal({ name: 'resend', selectedRows }),
                value: 'resend'
              },
              {
                label: 'Change Password',
                isDisabled: rows => rows.some(row => row.original.status === 'FORCE_CHANGE_PASSWORD'), //! isAdmin,
                callback: selectedRows => setOpenModal({ name: 'change', selectedRows }),
                value: 'change'
              },
              // Commented out for now as we need a new UI flow for this (after verification code is sent)
              // {
              //   label: 'Reset Password',
              //   isDisabled: !isAdmin,
              //   callback: selectedRows => setOpenModal({ name: 'reset', selectedRows }),
              //   value: 'reset'
              // },
              {
                label: 'Disable User(s)',
                isDisabled: rows => rows.some(row => !row.original.enabled),
                callback: selectedRows => setOpenModal({ name: 'disable', selectedRows }),
                value: 'disable'
              },
              {
                label: 'Enable User(s)',
                isDisabled: rows => rows.some(row => row.original.enabled),
                callback: selectedRows => setOpenModal({ name: 'enable', selectedRows }),
                value: 'enable'
              }
            ]
          },
          {
            label: 'Create User',
            callback: () => navigateTo('/users/create')
          },
          {
            label: 'Export Users',
            callback: () => reportActions.exportUsers().then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))
          }
        ] : []}
      />
      <ConfirmationModal
        open={openModal.name === 'resend'}
        title={`Resend Account Invitation to ${openModal.selectedRows?.[0]?.username}?`}
        message={''}
        onClose={() => {
          setOpenModal({ name: false, selectedRows: [] })
          setNewPass(null)
        }}
        onSubmit={async () => {
          resetUser(openModal.selectedRows, { action: 'RESEND', newPass })
        }}
      >
        <TextInput
          type='string'
          label='New Password? (Leave blank to use current password)'
          name='newPass'
          onChange={e => setNewPass(e?.currentTarget?.value)}
          value={newPass}
        />
      </ConfirmationModal>
      <ConfirmationModal
        open={openModal.name === 'change'}
        title={`Change Password for ${openModal.selectedRows?.[0]?.username}?`}
        message={''}
        onClose={() => {
          setOpenModal({ name: false, selectedRows: [] })
          setNewPass(null)
          setPermanentPass(null)
        }}
        onSubmit={async () => {
          resetUser(openModal.selectedRows, { action: 'SET_PASS', newPass, permanentPass })
        }}
      >
        <div className="user-list__change-password-modal">
          <TextInput
            type='string'
            label='New Password'
            name='newPass'
            onChange={e => setNewPass(e?.currentTarget?.value)}
            value={newPass}
          />
          <div className="user-list__change-password-modal-row">
            <Checkbox
              name="permanentPass"
              id="permanentPass"
              label="Permanent Password?"
              checked={permanentPass}
              onChange={e => setPermanentPass(e?.currentTarget?.checked)}
            />
            <InfoTip
              name='permanentPassInfoTip'
            >
            If checked, the user will not be forced to change their password (Not recommended).
            </InfoTip>
          </div>
        </div>
      </ConfirmationModal>
      <ConfirmationModal
        open={openModal.name === 'reset'}
        title={`Reset ${openModal.selectedRows?.[0]?.username}'s password?`}
        message={''}
        onClose={() => {
          setOpenModal({ name: false, selectedRows: [] })
          setNewPass(null)
        }}
        onSubmit={async () => {
          resetUser(openModal.selectedRows, { action: 'RESET_PASS', newPass })
        }}
      />
      <ConfirmationModal
        open={openModal.name === 'disable' || openModal.name === 'enable'}
        title={`Are you sure you want to ${openModal.name} the selected user(s)?`}
        message={''}
        onClose={() => setOpenModal({ name: false, selectedRows: [] })}
        onSubmit={async () => {
          changeUsersCondition(openModal.selectedRows, openModal.name)
        }}
      >
        <ul>
          {openModal.selectedRows.map(row => <li key={row.id}>{row.username}</li>)}
        </ul>
      </ConfirmationModal>
    </div>
  )
}

export default UserList
