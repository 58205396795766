import React, { useState, useEffect } from 'react'
import Excel from 'exceljs/dist/es5/exceljs.browser'
import PropTypes from 'prop-types'
import { Modal } from '@templates'
import { Button } from '@atoms'
import { FileUpload } from '@organisms'
import { fileUtils } from '@utils'
import { useGlobalsStore } from '@stores'
import { useToast } from '@hooks'

function ImportOrderModal({
  open,
  onClose,
  onSubmit,
  closeOnFail,
  ...props
}) {
  const [parsedPrices, setParsedPrices] = useState(null)
  const [fileName, setFileName] = useState(null)

  const {
    polishedColours: colours,
    clarities,
    polishedFluorescences,
    getPolishedColours,
    getClarities,
    getPolishedFluorescences
  } = useGlobalsStore()
  useEffect(() => {
    getPolishedColours()
    getClarities()
    getPolishedFluorescences()
  }, [])

  const { showErrorToast } = useToast()

  async function handleSubmit() {
    try {
      await onSubmit(parsedPrices)
      onClose()
    } catch (err) {
      console.error(err)
      if (closeOnFail) onClose()
    }
  }

  async function handleFileUpload(file) {
    if (!file) {
      setFileName(null)
      setParsedPrices(null)
      return
    }

    try {
      const buffer = await fileUtils.promiseReadBinaryFile(file)
      const book = new Excel.Workbook()
      await book.xlsx.load(buffer)
      const sheet = book.getWorksheet('Pricing')
      if (!sheet) throw new Error('"Pricing" sheet could not be found in workbook.')

      let headerRow = null
      sheet.eachRow((row, num) => {
        if (headerRow === null && (row.getCell('A').text.toLowerCase() === 'min weight')) headerRow = num
      })
      if (headerRow == null) throw new Error('No header row could be identified.')
      let fl = null
      sheet.getRow(headerRow).eachCell((cell, colNum) => {
        const cellVal = cell.text.trim().toLowerCase()
        const column = sheet.getColumn(colNum)
        const clr = clarities.find(c => c.description.toLowerCase() === cellVal || c.value.toLowerCase() === cellVal)
        const flr = polishedFluorescences.find(f => f.description.toLowerCase() === cellVal || (cellVal === 'light' && f.value === 'F'))
        if (clr) { // column header is a clarity name
          if (fl == null && !sheet.columns.some(col => col.key === clr.value)) column.key = clr.value
          else column.key = `${fl}|${clr.value}`
        } else if (flr) {
          fl = flr.value
          column.key = cellVal
        } else {
          column.key = cellVal
        }
      })
      if (!sheet.columns.some(col => col.key === 'min weight') || !sheet.columns.some(col => col.key === 'max weight') || !sheet.columns.some(col => col.key === 'color')) {
        throw new Error('Excel file does not contain some required columns. Check that your headers match the template.')
      }
      let priceList = []
      const coloursList = []
      sheet.eachRow((row, num) => {
        if (num <= headerRow) return
        if (!row.getCell('min weight').text || !row.getCell('max weight').text || !row.getCell('color').text) return
        const minWeight = Math.floor(fileUtils.parseExcelCell(row.getCell('min weight')) * 100 + 0.01) / 100
        const maxWeight = Math.floor(fileUtils.parseExcelCell(row.getCell('max weight')) * 100 + 0.01) / 100
        const cellColour = row.getCell('color').text.toLowerCase()
        const rowColour = colours.find(c => c.value.toLowerCase() === cellColour || c.description.toLowerCase() === cellColour)
        if (minWeight === 'NaN' || maxWeight === 'NaN') return // skip rows without a weight range
        if (!rowColour) return // skip rows without a colour
        if (!coloursList.includes(rowColour)) coloursList.push(rowColour)
        let wgtRange = priceList.find(w => w.minWeight === minWeight && w.maxWeight === maxWeight)
        if (wgtRange == null) {
          wgtRange = { minWeight, maxWeight, priceTable: [], discountTable: [] }
          priceList.push(wgtRange)
        }
        for (const clarity of clarities) {
          if (!sheet.getColumnKey(clarity.value)) continue
          // try {
          const value = fileUtils.parseExcelCell(row.getCell(clarity.value))

          if (!isNaN(value)) {
            wgtRange.priceTable.push({
              colour: rowColour.value,
              clarity: clarity.value,
              ppc: value
            })
            wgtRange.discountTable.push({
              colour: rowColour.value,
              clarity: clarity.value,
              percentage: value
            })
          }
          // } catch (e) {}
          const flrDiscounts = []
          for (const fluor of polishedFluorescences) {
            if (!sheet.getColumnKey(`${fluor.value}|${clarity.value}`)) continue
            // try {
            const fValue = fileUtils.parseExcelCell(row.getCell(`${fluor.value}|${clarity.value}`))

            if (!isNaN(value)) {
              flrDiscounts.push({
                flu: fluor.value,
                price: fValue
              })
            }
            // } catch (e) {}
          }
          if (flrDiscounts.length) {
            if (!wgtRange.additionalDiscounts) wgtRange.additionalDiscounts = { fluorescence: [] }
            wgtRange.additionalDiscounts.fluorescence.push({
              maxColour: rowColour.value,
              minColour: rowColour.value,
              maxClarity: clarity.value,
              minClarity: clarity.value,
              discounts: flrDiscounts.reduce((obj, disc) => ({ ...obj, [disc.flu]: disc.price }), {})
            })
          }
        }
      })
      priceList = addExtraColours(priceList, coloursList)

      let marginSheet = book.getWorksheet('Summary')
      if (!marginSheet) marginSheet = book.getWorksheet('Margin')
      if (marginSheet) {
        const weightRegex = /^((?:\d+(?:\.\d{0,3})?)|(?:\.\d{0,3}))\s*-\s*((?:\d+(?:\.\d{0,3})?)|(?:\.\d{0,3}))$/
        let marginHead = null
        marginSheet.eachRow((row, num) => {
          if (marginHead === null && (row.getCell('A').text.toLowerCase() === 'weight' && (row.getCell('B').text.toLowerCase() === 'margin (%)' || row.getCell('B').text.toLowerCase() === 'margin_(%)'))) {
            marginHead = num
          } else if (marginHead && !row.getCell('A').text) {
            marginHead = null
          } else if (marginHead) {
            const weightVal = row.getCell('A').text
            const marginVal = fileUtils.parseExcelCell(row.getCell('B'))
            const found = weightVal.match(weightRegex)
            if (!found || marginVal == null || marginVal < 0) return
            const minWeight = Math.floor(Number(found[1]) * 100 + 0.01) / 100
            const maxWeight = Math.floor(Number(found[2]) * 100 + 0.01) / 100
            const priceRow = priceList.find(wgt => wgt.minWeight === minWeight && wgt.maxWeight === maxWeight)
            if (priceRow) priceRow.margin = marginVal
          }
        })
      }

      if (Array.isArray(priceList)) {
        priceList = priceList.map(wgt => ({
          ...wgt,
          pricingMethod: wgt.priceTable.some(pt => pt.ppc < 0) ? 'discount' : 'ppc'
        }))
      }

      setFileName(file.name)
      setParsedPrices(priceList)
    } catch (err) {
      console.error(err)
      showErrorToast(err?.message || 'There was an error reading the excel.')
      setFileName(null)
      setParsedPrices(null)
    }
  }

  const addExtraColours = function(priceList, fileColours) {
    if (fileColours.some(fc => !fc.isBase)) {
      // File already includes +|- colours, keep customer's prices, no need to add extras
      return priceList
    }

    const extraColourMap = colours.reduce((map, col) => {
      const baseCol = fileColours.find(fc => fc.value === col.rapColour && !col.isBase)
      if (baseCol && baseCol.value !== col.value) {
        return { ...map, [baseCol.value]: [...(map[baseCol.value] || []), col.value] }
      } else {
        return map
      }
    }, {})

    for (const weightRange of priceList) {
      if (Array.isArray(weightRange.priceTable)) {
        for (const ppc of weightRange.priceTable) {
          if (extraColourMap[ppc.colour]) {
            for (const newColour of extraColourMap[ppc.colour]) {
              weightRange.priceTable.push({ ...ppc, colour: newColour })
            }
          }
        }
      }
      if (Array.isArray(weightRange.discountTable)) {
        for (const disc of weightRange.discountTable) {
          if (extraColourMap[disc.colour]) {
            for (const newColour of extraColourMap[disc.colour]) {
              weightRange.discountTable.push({ ...disc, colour: newColour })
            }
          }
        }
      }
      if (weightRange.additionalDiscounts && Array.isArray(weightRange.additionalDiscounts.fluorescence)) {
        for (const range of weightRange.additionalDiscounts.fluorescence) {
          if (extraColourMap[range.maxColour]) {
            const extraMax = colours.findIndex(c => c.value === extraColourMap[range.maxColour][0])
            const currentMax = colours.findIndex(c => c.value === range.maxColour)
            range.maxColour = extraMax < currentMax ? extraColourMap[range.maxColour][0] : range.maxColour
          }
          if (extraColourMap[range.minColour]) {
            const extraMin = colours.findIndex(c => c.value === extraColourMap[range.minColour][extraColourMap[range.minColour].length - 1])
            const currentMin = colours.findIndex(c => c.value === range.minColour)
            range.minColour = extraMin > currentMin ? extraColourMap[range.minColour][extraColourMap[range.minColour].length - 1] : range.minColour
          }
        }
      }
    }
    return priceList
  }

  return (
    <Modal
      open={open}
      title='Load Price File'
      position='center'
      onClose={onClose}
      className="import-order__modal"
      {...props}
    >
      <FileUpload
        name='priceFile'
        onChange={handleFileUpload}
        fileName={fileName}
        acceptTypes={['.xlsx']}
      />
      <div className='modal__buttons'>
        <Button typeVariant='secondary' size='sm' onClick={onClose}>Cancel</Button>
        <Button typeVariant='primary' size='sm' disabled={!Array.isArray(parsedPrices)} onClick={handleSubmit}>OK</Button>
      </div>
    </Modal>
  )
}

ImportOrderModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  closeOnFail: PropTypes.bool
}

ImportOrderModal.defaultProps = {
  open: false,
  onClose: () => console.warn('ConfirmationModal.js => onClose callback not implemented'),
  onSubmit: () => console.warn('ConfirmationModal.js => onSubmit callback not implemented'),
  closeOnFail: false
}

export default ImportOrderModal
