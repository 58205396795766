import axios from 'axios'
const BASE_URL = process.env.REACT_APP_API_BASE_URL

const exportOrder = function(format = 'xlsx', orderId, options = {}) {
  return axios.get(`${BASE_URL}/reports/order/${orderId}`, { params: { format, ...options }, report: true })
}
exportOrder.endpoint = 'GET|/reports/order/:orderId:'

const exportTempOrder = function(format = 'xlsx', orderId, options = {}) {
  return axios.post(`${BASE_URL}/reports/order/${orderId}`, { format, ...options }, { report: true })
}
exportTempOrder.endpoint = 'POST|/reports/order/:orderId:'

const exportBaseline = function(format = 'xlsx', baselineId, options = {}) {
  return axios.get(`${BASE_URL}/reports/baselines/${baselineId}`, { params: { format, ...options } }, { report: true })
}
exportBaseline.endpoint = 'GET|/reports/baselines/:baselineId:'

const exportOrganizations = function(format = 'xlsx', options = {}) {
  return axios.get(`${BASE_URL}/reports/organizations`, { params: { format, ...options }, report: true })
}
exportOrganizations.endpoint = 'GET|/reports/organizations'

const exportUsers = function(format = 'xlsx', options = {}) {
  return axios.get(`${BASE_URL}/reports/users`, { params: { format, ...options }, report: true })
}
exportUsers.endpoint = 'GET|/reports/users'

const getPackingList = function(format = 'xlsx', options = {}) {
  return axios.get(`${BASE_URL}/reports/packinglist`, { params: { format, ...options }, report: true })
}
getPackingList.endpoint = 'GET|/reports/packinglist'

const getSellerPackingList = function(format = 'xlsx', options = {}) {
  return axios.get(`${BASE_URL}/reports/sellerpackinglist`, { params: { format, ...options }, report: true })
}
getSellerPackingList.endpoint = 'GET|/reports/sellerpackinglist'

const getRoughsReport = function(format = 'xlsx', options = {}) {
  return axios.post(`${BASE_URL}/reports/roughs`, { format, ...options }, { report: true })
}
getRoughsReport.endpoint = 'POST|/reports/roughs'

const getPerformanceReport = function(format = 'xlsx', options = {}) {
  return axios.get(`${BASE_URL}/reports/assortmentperformance`, { params: { format, ...options }, report: format === 'xlsx' })
}
getPerformanceReport.endpoint = 'GET|/reports/assortmentperformance'

const getBuyerFeedback = function(format = 'xlsx', options = {}) {
  return axios.get(`${BASE_URL}/reports/feedback/orders`, { params: { format, ...options }, report: format === 'xlsx' })
}
getBuyerFeedback.endpoint = 'GET|/reports/feedback/orders'

const getInvoice = function(format = 'xlsx', options = {}) {
  return axios.get(`${BASE_URL}/reports/invoice`, { params: { format, ...options }, report: true })
}
getInvoice.endpoint = 'GET|/reports/invoice'

const getPlanningDetails = function(format = 'xlsx', options = {}) {
  return axios.get(`${BASE_URL}/reports/planningdetails`, { params: { format, ...options }, report: true })
}
getPlanningDetails.endpoint = 'GET|/reports/planningdetails'

const getQcPlanningDetails = function(format = 'xlsx', options = {}) {
  return axios.get(`${BASE_URL}/reports/qc/planningdetails`, { params: { format, ...options }, report: true })
}
getQcPlanningDetails.endpoint = 'GET|/reports/qc/planningdetails'

const getReplanningDetails = function(format = 'xlsx', options = {}) {
  return axios.get(`${BASE_URL}/reports/replanningdetails`, { params: { format, ...options }, report: true })
}
getReplanningDetails.endpoint = 'GET|/reports/replanningdetails'

const getBidGrid = function(format = 'xlsx', options = {}) {
  return axios.get(`${BASE_URL}/reports/bidgrid`, { params: { format, ...options }, report: true })
}
getBidGrid.endpoint = 'GET|/reports/bidgrid'

const getMatchDetails = function(format = 'xlsx', matchId) {
  return axios.get(`${BASE_URL}/reports/matchdetails/${matchId}`, { params: { format }, report: true })
}
getMatchDetails.endpoint = 'GET|/reports/matchdetails/:matchId:'

const getBidsReport = function(format = 'xlsx', options = {}) {
  return axios.get(`${BASE_URL}/reports/bids`, { params: { format, ...options }, report: true })
}
getBidsReport.endpoint = 'GET|/reports/bids'

const getPaymentsReport = function(format = 'xlsx', options = {}) {
  return axios.get(`${BASE_URL}/reports/payments`, { params: { format, ...options } })
}
getPaymentsReport.endpoint = 'GET|/reports/payments'

const listReports = function(params) {
  return axios.get(`${BASE_URL}/reports/saved`, { params })
}
listReports.endpoint = 'GET|/reports/saved'

const listUserReports = function(params) {
  return axios.get(`${BASE_URL}/reports/saved/user`, { params })
}
listUserReports.endpoint = 'GET|/reports/saved/user'

const createConsignmentMemo = function(params) {
  return axios.post(`${BASE_URL}/reports/consignmentmemo`, params)
}
createConsignmentMemo.endpoint = 'POST|/reports/consignmentmemo'

const createProForma = function(params) {
  return axios.post(`${BASE_URL}/reports/proforma`, params)
}
createProForma.endpoint = 'POST|/reports/proforma'

const createConsolidatedInvoice = function(params) {
  return axios.post(`${BASE_URL}/reports/consolidatedinvoice`, params)
}
createConsolidatedInvoice.endpoint = 'POST|/reports/consolidatedinvoice'

const createReturnInvoice = function(params) {
  return axios.post(`${BASE_URL}/reports/returninvoice`, params)
}
createReturnInvoice.endpoint = 'POST|/reports/returninvoice'

const getReleaseNotes = function(params) {
  return axios.get(`${BASE_URL}/reports/release`, { params })
}
getReleaseNotes.endpoint = 'GET|/reports/release'

const getDownloadUrl = function(fileIds, item) {
  return axios.post(`${BASE_URL}/reports/createdownloadlink`, { fileIds, ...item })
}
getDownloadUrl.endpoint = 'POST|/reports/createdownloadlink'

const getQcDownloadUrl = function(fileIds, item) {
  return axios.post(`${BASE_URL}/reports/qc/createdownloadlink`, { fileIds, ...item })
}
getQcDownloadUrl.endpoint = 'POST|/reports/qc/createdownloadlink'

const getBinaryFile = function(url) {
  const ax2 = axios.create()
  return ax2.get(url, { responseType: 'blob' })
}

export const reportActions = {
  exportOrder,
  exportTempOrder,
  exportBaseline,
  exportOrganizations,
  exportUsers,
  getPackingList,
  getSellerPackingList,
  getRoughsReport,
  getPerformanceReport,
  getBuyerFeedback,
  getInvoice,
  getPaymentsReport,
  getPlanningDetails,
  getQcPlanningDetails,
  getReplanningDetails,
  getBidGrid,
  getMatchDetails,
  getBidsReport,
  createConsignmentMemo,
  createProForma,
  createConsolidatedInvoice,
  createReturnInvoice,
  getReleaseNotes,
  getDownloadUrl,
  getQcDownloadUrl,
  listReports,
  listUserReports,
  getBinaryFile
}
