import React, { useState } from 'react'
import { Button } from '@atoms'
import { TextInput, DisplayMessage } from '@molecules'
import { useSigninStore } from '@stores'
import { Form } from '@organisms'
import { useAuth, useToast } from '@hooks'

function ConfirmCodeForm() {
  const [verifyCode, setVerifyCode] = useState('')
  const [username, setUsername] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [repeatNewPassword, setRepeatNewPassword] = useState('')
  const [resetPasswordError, setResetPasswordError] = useState(null)
  const { setDisplayMode } = useSigninStore(state => state)
  const { forgotPasswordCodeSubmit, signIn } = useAuth()

  const {
    showSuccessToast
  } = useToast()

  const handleOnSubmit = function () {
    setResetPasswordError(null)
    forgotPasswordCodeSubmit(username, verifyCode, newPassword, repeatNewPassword)
    .then(user => {
      showSuccessToast('Password was successfully updated.')
      setDisplayMode('signIn')
      signIn(username, newPassword)
    })
    .catch(err => setResetPasswordError(err))
  }

  const onCancel = () => {
    setDisplayMode('signIn')
  }

  return (
    <div className="signin__form">
      <Form
        onSubmit={handleOnSubmit}
        hasOutline={false}
      >
        <TextInput
          name="username"
          label="Username"
          labelStyle={{ color: 'white' }}
          onChange={e => setUsername(e.target.value)}
          value={username}
        />
        <TextInput
          name="verifyCode"
          label="Verification Code"
          labelStyle={{ color: 'white' }}
          onChange={e => setVerifyCode(e.target.value)}
          value={verifyCode}
        />
        <TextInput
          type="password"
          name="newPassword"
          label="New password"
          labelStyle={{ color: 'white' }}
          onChange={(e) => setNewPassword(e.target.value)}
          value={newPassword}
        />
        <TextInput
          type="password"
          name="repeatNewPassword"
          label="Repeat new password"
          labelStyle={{ color: 'white' }}
          onChange={(e) => setRepeatNewPassword(e.target.value)}
          value={repeatNewPassword}
        />
        <div className="signin__form-buttons">
          <Button
            size='sm'
            typeVariant="action"
            onClick={onCancel}
          >
            <span className='signin__form-action-button-text'>
              Cancel
            </span>
          </Button>
          <Button
            size='sm'
            type='submit'
          >
            Submit
          </Button>
        </div>
      </Form>
      {
        resetPasswordError
          ? <DisplayMessage
            type={'error'}
            message={
              <span>{resetPasswordError.message}</span>
            }
          />
          : null
      }
    </div>
  )
}

export default ConfirmCodeForm
