import React, { useEffect, useMemo, useState } from 'react'
import { FormComponents } from '@organisms'
import { userActions } from '@actions'
import { useGlobalsStore } from '@stores'
import { useHistory } from 'react-router-dom'
import { useToast } from '@hooks'

function CreateUser(props) {
  const [userRolesList, setUserRolesList] = useState([])
  const {
    setUsersList,
    getOrgsList,
    orgsList: { [JSON.stringify({ condition: 'ACTIVE' })]: orgsList }
  } = useGlobalsStore(store => store)
  const { SimpleForm, TextInput, Button, Dropdown } = FormComponents
  useEffect(() => {
    getOrgsList({ condition: 'ACTIVE' })
  }, [])
  useEffect(() => {
    userActions.getUserRoles().then(result => {
      setUserRolesList(
        result.data.data.map(r => {
          return {
            label: r.role,
            value: r.role
          }
        })
      )
    })
  }, [])

  const organizationList = useMemo(() => {
    if (!orgsList) return []
    return orgsList?.map(({ commonName: label, orgId: value }) => ({ label, value }))
  }, [orgsList])

  const history = useHistory()
  const { showSuccessToast } = useToast()

  const onSubmit = e => {
    return userActions
    .createUser({
      name: e.name,
      username: e.username,
      email: e.email,
      phoneNumber: e.phoneNumber,
      roles: e.role || [],
      orgId: e.organization
    })
    .then(async result => {
      if (result.data.success) {
        showSuccessToast(`User ${e.username} was successfully created`)
        await setUsersList()
        history.push('/users')
      }
    })
  }

  return (
    <div className="create-user__container">
      <div className="col-xl-4 col-lg-7 col-md-10 col-sm-12">

        <SimpleForm
          title="Create New User"
          name="Create New User"
          onSubmit={e => onSubmit(e)}
          initialValues={{ role: 'clara.basic' }}
        >
          <TextInput
            label="Username"
            name="username"
          />
          <TextInput
            label="Name"
            name="name"
            required
          />
          <TextInput
            label="Email"
            name="email"
            type="email"
          />
          <TextInput
            label="Phone Number"
            name="phoneNumber"
            type="phone"
          />
          <Dropdown
            name="organization"
            label="Organization"
            options={organizationList}
          />
          <Dropdown
            name="role"
            label="Role"
            options={userRolesList}
            isMulti
          />
          <Button
            onClick={() => history.goBack()}
            typeVariant="action"
          >
            Cancel
          </Button>
          <Button
            size="sm"
            type="submit"
          >
            Create User
          </Button>
        </SimpleForm>
      </div>
    </div>
  )
}

export default CreateUser
