import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import dottie from 'dottie'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import {
  Icon,
  Checkbox,
  Button
} from '@atoms'
import {
  TextInput,
  Dropdown,
  TextArea,
  Collapsible,
  DisplayMessage,
  FormRow
} from '@molecules'
import { Fieldset } from '@organisms'
import { organizationActions } from '@actions'
import { useGlobalsStore, useAuthStore } from '@stores'
import { arrayUtils, objectUtils, textUtils, validationUtils } from '@utils'
import { ORG } from '@constants'

const splitArrayLines = arr => {
  if (!Array.isArray(arr)) return arr
  if (!arr.length) return ''
  return arr.join('\n')
}

function EditAddressForm({
  formData,
  disabled,
  onChange,
  addressRef
}) {
  const [addresses, setAddresses] = useState()
  const [collapsibles, setCollapsibles] = useState({})
  const [displayMessage, setDisplayMessage] = useState({})

  const { hasAdmin } = useAuthStore()
  const isAdmin = hasAdmin(organizationActions.editOrganization)

  const appSettingsParams = { groupKey: 'INVOICE_PAYMENT_DETAILS' }

  useImperativeHandle(addressRef, () => ({
    validateForm
  }))

  const {
    complianceStatuses,
    licenceStatuses,
    licenceTypesList,
    appSettings: { [JSON.stringify(appSettingsParams)]: invoicePaymentDetails },
    getComplianceStatuses,
    getLicenceStatuses,
    getLicenceTypesList,
    getAppSettings
  } = useGlobalsStore()
  useEffect(() => {
    getComplianceStatuses()
    getLicenceStatuses()
    getLicenceTypesList({ condition: 'ACTIVE' })
    getAppSettings(appSettingsParams)
  }, [])

  const userDocumentsExpireValidation = useMemo(() => {
    if (!addresses?.length) return
    const now = moment()
    // Overload validation getValidationClass
    const getValidationClass = (expires) => () => {
      if (expires) {
        const diff = now.diff(expires, 'days')
        if (diff >= 0) return 'form-error'
        if (diff >= -31) return 'form-warning'
      }
      return ''
    }
    return addresses.reduce((val, address) => ({ ...val, ...arrayUtils.toMap(address.userDocuments, (doc) => doc._id, (doc) => ({ ...validationUtils.validation(), getValidationClass: getValidationClass(doc.expires) })) }), {})
  }, [addresses])

  const [defaultClaraInvoicePayment, defaultLucaraInvoicePayment] = useMemo(() => {
    const invoiceDefaults = []
    for (const paymentDetail of (invoicePaymentDetails || [])) {
      if (paymentDetail.claraDefault) invoiceDefaults[0] = paymentDetail.key
      if (paymentDetail.lucaraDefault) invoiceDefaults[1] = paymentDetail.key
    }

    return invoiceDefaults
  }, [invoicePaymentDetails])

  useEffect(() => {
    if (formData) {
      // Initialize addresses
      if (!addresses) setAddresses(getInitialAddresses(formData))
      if (!displayMessage || objectUtils.isEmpty(displayMessage)) setDisplayMessage(getInitialDisplayMessage(formData))
      // Reset collapsible and displayMessage
      setCollapsibles(getInitialCollapsibles(formData))
    }
  }, [disabled])
  useEffect(() => {
    // Only allow the component to be updated from parent while in disabled mode
    // This is to handle the case when the server-side adds extra properties to formData
    // (i.e. compliance & licences non-admin users)
    if (addresses && disabled && formData) {
      setAddresses(getInitialAddresses(formData))
      setDisplayMessage(getInitialDisplayMessage(formData))
    }
  }, [formData])

  function getInitialAddresses(formData) {
    return formData?.map((address, index) => {
      return {
        addressIndex: index,
        isCollapsibleOpen: false,
        isExisting: true,
        ...address,
        userDocuments: address.userDocuments?.map(doc => ({ ...doc, _id: uuidv4() }))
      }
    })
  }

  function getInitialCollapsibles(formData) {
    return formData?.reduce((collapsibles, _, index) => ({ ...collapsibles, [index]: false }), {})
  }

  function getInitialDisplayMessage(formData) {
    return formData?.reduce((displayMessage, { condition }, index) => ({ ...displayMessage, [index]: { type: condition === 'ARCHIVED' ? 'archived' : 'success', message: '' } }), {})
  }

  function validateForm(addressObj, addressIdx) {
    let blankFields = []
    const requiredFields = [
      { name: 'name', label: 'Entity Name' },
      { name: 'line1', label: 'Address line 1' },
      { name: 'city', label: 'City' },
      { name: 'country', label: 'Country' },
      {
        name: ['invoicePayment', 'primaryBank'],
        label: 'Primary Bank',
        requiredFn: (val, addr) => addr.billing && (!val || !String(val).trim())
      },
      {
        name: ['userDocuments', 'name'],
        label: (_, addr) => addr.userDocuments?.reduce((labels, { name }, idx) => labels.concat(!name ? `Compliance Documents ${idx + 1}: Name` : []), []),
        requiredFn: (_, addr) => addr.userDocuments?.find(({ name }) => !name)
      },
      {
        name: ['userDocuments', 'documentType'],
        label: (_, addr) => addr.userDocuments?.reduce((labels, { documentType }, idx) => labels.concat(!documentType ? `Compliance Documents ${idx + 1}: Document Type` : []), []),
        requiredFn: (_, addr) => addr.userDocuments?.find(({ documentType }) => !documentType)
      }
    ]
    let isValid = true

    requiredFields.forEach(fieldObj => {
      // check if the required fields is filled
      const field = dottie.get(addressObj, fieldObj.name)
      const invalidField = fieldObj.requiredFn ? fieldObj.requiredFn?.(field, addressObj) : (!field || !String(field).trim())
      if (invalidField && !['ARCHIVED', 'DELETED'].includes(addressObj.condition)) {
        isValid = false
        // if not, add to array of blank fields
        blankFields = blankFields.concat(objectUtils.callProp(fieldObj.label, [field, addressObj]))
      }
    })

    if (isValid) {
      if (addressObj.condition === 'ARCHIVED') setArchivedMessage(addressIdx)
      else setSuccessMessage(addressIdx)
    } else setErrorMessage(blankFields, addressIdx)

    return {
      isValid,
      errors: {
        blankFields
      }
    }
  }

  function handleOnChange(e, addressIndex) {
    const target = e.currentTarget ?? e.target
    const targetValue = target.type === 'checkbox' ? target.checked : target.value
    const addressObj = { ...addresses?.[addressIndex] }
    dottie.set(addressObj, target.name, targetValue)
    if (addressObj.emailCcs !== undefined && textUtils.nonEmptyString(addressObj.emailCcs)) addressObj.emailCcs = addressObj.emailCcs.split('\n')
    else if (!Array.isArray(addressObj.emailCcs) || !addressObj.emailCcs.length) addressObj.emailCcs = undefined
    if (addressObj.emailBccs !== undefined && textUtils.nonEmptyString(addressObj.emailBccs)) addressObj.emailBccs = addressObj.emailBccs.split('\n')
    else if (!Array.isArray(addressObj.emailBccs) || !addressObj.emailBccs.length) addressObj.emailBccs = undefined
    validateForm(addressObj, addressIndex)
    const newAddresses = addresses?.slice(0, addressIndex).concat(addressObj).concat(addresses?.slice(addressIndex + 1))
    callOnChange(newAddresses)
    setAddresses(newAddresses)
  }

  function resetDisplayMessage(addressIndex) {
    setDisplayMessage({
      ...displayMessage,
      [addressIndex]: {}
    })
  }

  function handleOnAddAddress(addressIndex) {
    addNewAddress(addressIndex)
    toggleCollapsible(addressIndex, false)
  }

  function addNewAddress(addressIndex) {
    const newAddresses = addresses.concat({
      addressIndex: addresses?.length,
      isCollapsibleOpen: true,
      invoicePayment: {
        clara: defaultClaraInvoicePayment,
        lucara: defaultLucaraInvoicePayment
      }
    })

    newAddresses.find(address => address.addressIndex === addressIndex).isCollapsibleOpen = false
    setAddresses(newAddresses)
    collapsibles[addresses?.length] = true
    setCollapsibles(collapsibles)
    resetDisplayMessage(addresses?.length)
  }

  function toggleCollapsible(addressIndex, toggle) {
    setCollapsibles({ ...collapsibles, [addressIndex]: toggle })
  }

  function handleOnRemove(addressIndex) {
    if (addresses?.[addressIndex]) {
      const newAddresses = addresses?.filter((_, i) => i !== addressIndex)
      resetDisplayMessage(addressIndex)
      setAddresses(newAddresses)
      callOnChange(newAddresses)
    }
  }

  function callOnChange(addrs) {
    return onChange({ target: { name: 'address', value: addrs.map(({ addressIndex, isCollapsibleOpen, isExisting, ...address }) => address) } })
  }

  function getValue(address, fieldName, defaultValue = '') {
    return dottie.get(address, fieldName, defaultValue)
  }

  function setSuccessMessage(index) {
    setDisplayMessage({
      ...displayMessage,
      [index]: {
        type: 'success',
        message: 'Address valid.'
      }
    })
  }

  function setErrorMessage(blankFields, index) {
    setDisplayMessage({
      ...displayMessage,
      [index]: {
        type: 'error',
        message: getErrorMessage(blankFields)
      }
    })
  }

  function setArchivedMessage(index) {
    setDisplayMessage({
      ...displayMessage,
      [index]: {
        type: 'archived',
        message: 'Address archived.'
      }
    })
  }

  function getErrorMessage(blankFields) {
    return (
      <>
        <div>Please, fill all required fields:</div>
        {blankFields.map((blankField, i) => {
          return (
            <span key={i}>
              <strong>- {blankField}</strong>
            </span>
          )
        })}
      </>
    )
  }

  function getAddressTitle(address) {
    const getIconName = (type) => {
      if (type === 'success') return 'check'
      if (type === 'error') return 'error'
      if (type === 'archived') return 'archive'
    }
    const getColour = (type) => {
      if (type === 'success') return 'green'
      if (type === 'error') return 'red'
      if (type === 'archived') return 'gray'
    }

    const messageType = displayMessage[address.addressIndex]?.type
    return (
      <div className='address-form__title'>
        <p>
          {getValue(address, 'name')
            ? `${getValue(address, 'name')}, ${getValue(address, 'line1')}, ${getValue(address, 'city')}, ${getValue(address, 'country')}`
            : 'New Address'
          }
        </p>
        {
          (messageType && !disabled) || (messageType === 'archived')
            ? (
              <Icon
                name={getIconName(messageType)}
                style={{ color: getColour(messageType) }}
              />
            )
            : null
        }
      </div>
    )
  }

  return (
    <div className='edit-organization-address-form'>
      <span className='input__label'>Addresses</span>
      {
        addresses?.map((address, index) => {
          const isDisabled = disabled || address.condition === 'ARCHIVED'
          const isExisting = address.isExisting
          const type = displayMessage[index]?.type
          const collapsibleType = (type !== 'archived' && disabled) ? '' : type
          return (
            <div className='edit-organization-address-form__address' key={index}>
              <Collapsible
                title={getAddressTitle(address)}
                type={collapsibleType}
                onClick={() => toggleCollapsible(index, !collapsibles[index])}
                isOpened={collapsibles[index]}
              >
                {displayMessage[index]?.message
                  ? (
                    <DisplayMessage
                      type={displayMessage[index]?.type}
                      message={displayMessage[index]?.message}
                    />
                  ) : null
                }
                <div className='edit-organization-address-form__address-grid'>
                  <TextInput
                    name='name'
                    label='Entity Name'
                    value={getValue(address, 'name')}
                    onChange={e => handleOnChange(e, index)}
                    disabled={isDisabled || !isAdmin}
                  />
                  <TextInput
                    name="line1"
                    label="Address line 1"
                    value={getValue(address, 'line1')}
                    onChange={e => handleOnChange(e, index)}
                    disabled={isDisabled || !isAdmin}
                  />
                  <TextInput
                    name="line2"
                    label="Address line 2"
                    required={false}
                    value={getValue(address, 'line2')}
                    onChange={e => handleOnChange(e, index)}
                    disabled={isDisabled || !isAdmin}
                  />
                  <TextInput
                    name="line3"
                    label="Address line 3"
                    required={false}
                    value={getValue(address, 'line3')}
                    onChange={e => handleOnChange(e, index)}
                    disabled={isDisabled || !isAdmin}
                  />
                  <TextInput
                    name="city"
                    label="City"
                    required
                    value={getValue(address, 'city')}
                    onChange={e => handleOnChange(e, index)}
                    disabled={isDisabled || !isAdmin}
                  />
                  <TextInput
                    name="province"
                    label="Province / State"
                    required={false}
                    value={getValue(address, 'province')}
                    onChange={e => handleOnChange(e, index)}
                    disabled={isDisabled || !isAdmin}
                  />
                  <TextInput
                    name="country"
                    label="Country"
                    required
                    value={getValue(address, 'country')}
                    onChange={e => handleOnChange(e, index)}
                    disabled={isDisabled || !isAdmin}
                  />
                  <TextInput
                    name="postalCode"
                    label="Postal code"
                    required={false}
                    value={getValue(address, 'postalCode')}
                    onChange={e => handleOnChange(e, index)}
                    disabled={isDisabled || !isAdmin}
                  />
                  <TextInput
                    name='corporateNumber'
                    label='Corporate Number'
                    required={false}
                    value={getValue(address, 'corporateNumber')}
                    onChange={e => handleOnChange(e, index)}
                    disabled={isDisabled || !isAdmin}
                  />
                  <TextInput
                    name='taxNumber'
                    label='Tax Number'
                    required={false}
                    value={getValue(address, 'taxNumber')}
                    onChange={e => handleOnChange(e, index)}
                    disabled={isDisabled || !isAdmin}
                  />
                  <Dropdown
                    name='preferredShipping'
                    label='Preferred Shipping'
                    required={false}
                    value={getValue(address, 'preferredShipping')}
                    onChange={e => handleOnChange(e, index)}
                    disabled={isDisabled || !isAdmin}
                    options={ORG.PREFERRED_SHIPPING}
                  />
                  <Fieldset legend={'Address type'} required>
                    <Checkbox
                      name="shipping"
                      id="shipping"
                      label="Shipping"
                      checked={ address?.shipping}
                      value={(address?.shipping) || false}
                      onChange={e => handleOnChange(e, index)}
                      disabled={isDisabled}
                    />
                    <Checkbox
                      name="billing"
                      id="billing"
                      label="Billing"
                      checked={ address?.billing}
                      value={(address?.billing) || false}
                      onChange={e => handleOnChange(e, index)}
                      disabled={isDisabled}
                    />
                  </Fieldset>
                  {isAdmin
                    ? <Fieldset legend='Compliance'>
                      <Dropdown
                        name='compliance.status'
                        label='Status'
                        required={false}
                        value={getValue(address, 'compliance.status')}
                        onChange={e => handleOnChange(e, index)}
                        disabled={isDisabled || !isAdmin}
                        options={complianceStatuses?.map(s => ({ value: s.value, label: s.description }))}
                        isClearable={false}
                      />
                      <TextInput
                        name='compliance.expires'
                        label='Expiry'
                        required={false}
                        value={getValue(address, 'compliance.expires')}
                        onChange={e => handleOnChange(e, index)}
                        disabled={isDisabled || !isAdmin}
                        type='date'
                      />
                    </Fieldset>
                    : null
                  }
                  {
                    isAdmin ? licenceTypesList?.map(licenceType => (
                      <Fieldset legend={licenceType} key={licenceType}>
                        <Dropdown
                          name={`licences.${licenceType}.status`}
                          label='Status'
                          required={false}
                          value={getValue(address, `licences.${licenceType}.status`)}
                          onChange={e => handleOnChange(e, index)}
                          disabled={isDisabled}
                          options={licenceStatuses?.map(s => ({ value: s.value, label: s.description }))}
                          isClearable={false}
                        />
                        <TextInput
                          name={`licences.${licenceType}.expires`}
                          label='Expiry'
                          required={false}
                          value={getValue(address, `licences.${licenceType}.expires`)}
                          onChange={e => handleOnChange(e, index)}
                          disabled={isDisabled}
                          type='date'
                        />
                      </Fieldset>
                    )) : null
                  }
                  <TextArea
                    name='emailCcs'
                    label='Email Ccs'
                    required={false}
                    value={splitArrayLines(getValue(address, 'emailCcs'))}
                    onChange={e => handleOnChange(e, index)}
                    disabled={isDisabled || !isAdmin}
                    rows={5}
                  />
                  <TextArea
                    name='emailBccs'
                    label='Email Bccs'
                    required={false}
                    value={splitArrayLines(getValue(address, 'emailBccs'))}
                    onChange={e => handleOnChange(e, index)}
                    disabled={isDisabled || !isAdmin}
                    rows={5}
                  />
                  {
                    isAdmin ? <TextArea
                      name='invoiceNotes'
                      label='Invoice Notes'
                      required={false}
                      value={getValue(address, 'invoiceNotes')}
                      onChange={e => handleOnChange(e, index)}
                      disabled={isDisabled}
                    /> : null
                  }
                  <Fieldset legend='Payment Details' key='invoicePayment' classNames={['justify-content-start']}>
                    <Fieldset
                      legend='Client Banking'
                      key='clientBanking'
                    >
                      <TextInput
                        name="invoicePayment.primaryBank"
                        label="Primary Bank"
                        value={getValue(address, 'invoicePayment.primaryBank')}
                        onChange={e => handleOnChange(e, index)}
                        disabled={isDisabled || !hasAdmin(organizationActions.editOrganization)}
                      />
                      <TextInput
                        name="invoicePayment.secondaryBank"
                        label="Secondary Bank"
                        value={getValue(address, 'invoicePayment.secondaryBank')}
                        onChange={e => handleOnChange(e, index)}
                        disabled={isDisabled || !hasAdmin(organizationActions.editOrganization)}
                      />
                    </Fieldset>
                    <Fieldset
                      legend='Clara Banking'
                      key='claraBanking'
                    >
                      <Dropdown
                        name='invoicePayment.clara'
                        label='Clara Invoice'
                        options={invoicePaymentDetails?.map(({ key }) => ({ label: key, value: key }))}
                        value={getValue(address, 'invoicePayment.clara')}
                        onChange={e => handleOnChange(e, index)}
                        disabled={isDisabled || !isAdmin}
                        isClearable={false}
                        required={true}
                      />
                      <Dropdown
                        name='invoicePayment.lucara'
                        label='Lucara Invoice'
                        options={invoicePaymentDetails?.map(({ key }) => ({ label: key, value: key }))}
                        value={getValue(address, 'invoicePayment.lucara') }
                        onChange={e => handleOnChange(e, index)}
                        disabled={isDisabled || !isAdmin}
                        isClearable={false}
                        required={true}
                      />
                    </Fieldset>
                  </Fieldset>
                  {isAdmin && (
                    <Fieldset legend='Compliance Documents' key='userDocuments' span={true}>
                      {getValue(address, 'userDocuments', []).length
                        ? getValue(address, 'userDocuments', []).map((doc, idx) => (
                          <FormRow key={`userDocument${idx}`}>
                            <TextInput
                              name={`userDocuments.${idx}.name`}
                              label="Name"
                              value={doc.name}
                              onChange={e => handleOnChange({
                                currentTarget: {
                                  name: 'userDocuments',
                                  value: getValue(address, 'userDocuments').map(({ _id, ...restDoc }) => ({
                                    _id,
                                    ...restDoc,
                                    ...(_id === doc._id && { name: (e?.currentTarget ?? e?.target)?.value })
                                  }))
                                }
                              }, index)}
                              disabled={isDisabled || !hasAdmin(organizationActions.editOrganization)}
                            />
                            <TextInput
                              name={`userDocuments.${idx}.documentType`}
                              label="Document Type"
                              value={doc.documentType}
                              onChange={e => handleOnChange({
                                currentTarget: {
                                  name: 'userDocuments',
                                  value: getValue(address, 'userDocuments').map(({ _id, ...restDoc }) => ({
                                    _id,
                                    ...restDoc,
                                    ...(_id === doc._id && { documentType: (e?.currentTarget ?? e?.target)?.value })
                                  }))
                                }
                              }, index)}
                              disabled={isDisabled || !hasAdmin(organizationActions.editOrganization)}
                            />
                            <TextInput
                              name={`userDocuments.${idx}.expires`}
                              label='Expiry'
                              type='date'
                              value={doc.expires}
                              onChange={e => handleOnChange({
                                currentTarget: {
                                  name: 'userDocuments',
                                  value: getValue(address, 'userDocuments').map(({ _id, ...restDoc }) => ({
                                    _id,
                                    ...restDoc,
                                    ...(_id === doc._id && { expires: (e?.currentTarget ?? e?.target)?.value })
                                  }))
                                }
                              }, index)}
                              disabled={isDisabled || !hasAdmin(organizationActions.editOrganization)}
                              validationText={userDocumentsExpireValidation[doc._id]}
                            />
                            <div className="edit-organization-address-form__userdoc-icons">
                              <Icon
                                name='plus'
                                title='Add Another'
                                size='lg'
                                onClick={() => handleOnChange({ currentTarget: { name: 'userDocuments', value: getValue(address, 'userDocuments').concat({ _id: uuidv4() }) } }, index)}
                                disabled={isDisabled || !hasAdmin(organizationActions.editOrganization)}
                              />
                              <Icon
                                name='remove'
                                title='Remove'
                                size='lg'
                                onClick={() => handleOnChange({ currentTarget: { name: 'userDocuments', value: getValue(address, 'userDocuments').filter(({ _id }) => _id !== doc._id) } }, index)}
                                disabled={isDisabled || !hasAdmin(organizationActions.editOrganization)}
                              />
                            </div>
                          </FormRow>
                        ))
                        : (
                          <Button
                            disabled={isDisabled || !hasAdmin(organizationActions.editOrganization)}
                            onClick={() => handleOnChange({ currentTarget: { name: 'userDocuments', value: [{ _id: uuidv4() }] } }, index)}
                          >
                            Add Document
                          </Button>
                        )}
                    </Fieldset>
                  )}
                </div>
              </Collapsible>
              {(!disabled && isAdmin)
                ? (
                  <div className="edit-organization-address-form__icons">
                    <Icon
                      name='plus'
                      title='Add Another'
                      size='lg'
                      onClick={() => handleOnAddAddress(index)}
                    />
                    {isExisting && (
                      <Icon
                        name={address.condition === 'ARCHIVED' ? 'unarchive' : 'archive'}
                        title={address.condition === 'ARCHIVED' ? 'Unarchive' : 'Archive'}
                        size='lg'
                        onClick={() => address.condition === 'ARCHIVED'
                          ? handleOnChange({ target: { name: 'condition', value: 'ACTIVE' } }, index)
                          : handleOnChange({ target: { name: 'condition', value: 'ARCHIVED' } }, index)
                        }
                      />
                    )}
                    <Icon
                      name='remove'
                      title='Remove'
                      disabled={addresses?.length < 2}
                      size='lg'
                      onClick={() => handleOnRemove(index)}
                    />
                  </div>
                ) : null
              }
            </div>
          )
        })
      }
    </div>
  )
}

EditAddressForm.propTypes = {
  formData: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  addressRef: PropTypes.object
}

EditAddressForm.defaultProps = {
  formData: [],
  disabled: false
}
export default EditAddressForm
