import React, { useState, useEffect } from 'react'
import { ToggleSwitch } from '@atoms'
import { useOrderStore } from '@stores'
import PricingPainter from './pricingPainter'
import FluorPainter from './fluorPainter'

function PricePainter({ disabled, ...restProps }) {
  const [isPricePainterEnabled, setIsPricePainterEnabled] = useState(false)
  const {
    activeRange,
    activePricingTabIndex,
    resetPainter
  } = useOrderStore(state => state)

  useEffect(() => {
    return () => {
      resetPainter()
    }
  }, [])

  useEffect(() => {
    if (!isPricePainterEnabled) resetPainter()
  }, [isPricePainterEnabled])

  const isDisabled = disabled || !activeRange.priceRange
  useEffect(() => {
    if (isDisabled) setIsPricePainterEnabled(false)
  }, [isDisabled])

  return (
    <div className={`price-painter${isPricePainterEnabled ? '' : '--disabled'}`}>
      <div className="price-painter__title">
        <span className="label">Price Painter</span>
        <div>
          <ToggleSwitch
            id="pricePainter"
            checked={isPricePainterEnabled && !disabled}
            onChange={() => setIsPricePainterEnabled(!isPricePainterEnabled)}
            disabled={isDisabled}
          />
        </div>
      </div>
      {
        isPricePainterEnabled && !disabled
          ? activePricingTabIndex === 0
            ? <PricingPainter {...restProps}/>
            : <FluorPainter {...restProps}/>
          : null
      }
    </div>
  )
}

export default PricePainter
