import React from 'react'
import PropTypes from 'prop-types'

// TODO: Add Icon component,
// introduced in this currently open PR:
// https://github.com/ClaraDiamonds/Clara2.0_Client/pull/15
function DisplayMessage(props) {
  function getMessageClass(type) {
    if (type === 'success') { return '--success' }
    if (type === 'error') { return '--error' }

    return '--info'
  }

  return (
    <div className={`display-message${getMessageClass(props.type)}`}>
      <div className="display-message__message">
        {props.message}
      </div>
    </div>
  )
}

DisplayMessage.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string
}

DisplayMessage.defaultProps = {
  type: 'info',
  message: 'Display message.'
}
export default DisplayMessage
