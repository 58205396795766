import React, { useEffect, useMemo, useState } from 'react'
import { Button } from '@atoms'
import OrderLines from '../line/orderLines'
import { Form } from '@organisms'
import clone from 'just-clone'
import dottie from 'dottie'
import { useGlobalsStore, useOrderStore } from '@stores'
import SubmitOrderConfirmationModal from '../submitOrderConfirmationModal'

function OrderLinesStep({ ...props }) {
  const { validateAndSubmit, renderValidationError } = props.useMultiSteps(props)
  const [submitOrderConfirmationModalIsOpen, setSubmitOrderConfirmationModalIsOpen] = useState(false)

  const {
    getPolishedColours,
    getClarities,
    getPolishedFluorescences,
    polishedColours: colours,
    clarities,
    polishedFluorescences
  } = useGlobalsStore()
  useEffect(() => {
    getPolishedColours()
    getClarities()
    getPolishedFluorescences()
  }, [])

  const { gridSize } = useOrderStore(state => state)

  const displayColours = useMemo(() => {
    if (!colours || !gridSize) return []
    return colours.reduce(({ colours, flag }, c) => {
      if (flag) return { colours: [...colours, c], flag: c.value !== gridSize?.minColour }
      else if (c.value === gridSize?.maxColour) return { colours: [...colours, c], flag: c.value !== gridSize?.minColour }
      else return { colours, flag }
    }, { colours: [], flag: false }).colours
  }, [colours, gridSize?.maxColour, gridSize?.minColour])
  const displayClarities = useMemo(() => {
    if (!clarities || !gridSize) return []
    return clarities.reduce(({ clarities, flag }, c) => {
      if (flag) return { clarities: [...clarities, c], flag: c.value !== gridSize?.minClarity }
      else if (c.value === gridSize?.maxClarity) return { clarities: [...clarities, c], flag: c.value !== gridSize?.minClarity }
      else return { clarities, flag }
    }, { clarities: [], flag: false }).clarities
  }, [clarities, gridSize?.maxClarity, gridSize?.minClarity])
  const displayFluors = useMemo(() => {
    if (!polishedFluorescences || !gridSize) return []
    return polishedFluorescences.reduce(({ fluors, flag }, f) => {
      if (flag) return { fluors: [...fluors, f], flag: f.value !== gridSize?.maxFluorescence }
      // else if (f.value === minFluorescence) return { fluors: [...fluors, f], flag: true }
      else return { fluors, flag }
    }, { fluors: [], flag: true }).fluors
  }, [polishedFluorescences, gridSize?.maxFluorescence])

  function handleSubmit(rawData, status = 'ORDER_NOT_SUBMITTED') {
    const order = clone(dottie.transform(rawData))
    const colourMap = displayColours.reduce((map, c, i) => ({ ...map, [c.value]: i + 1 }), {})
    const clarityMap = displayClarities.reduce((map, c, i) => ({ ...map, [c.value]: i + 1 }), {})
    const fluorMap = displayFluors.reduce((map, f, i) => ({ ...map, [f.value]: i + 1 }), {})
    for (const range of (order.priceScheme || [])) {
      delete range.id
      if (range.pricingMethod) {
        const table = range.pricingMethod === 'ppc' ? 'priceTable' : 'discountTable'
        const tableKey = range.pricingMethod === 'ppc' ? 'ppc' : 'percentage'
        table === 'priceTable' ? delete range.discountTable : delete range.priceTable
        delete range.pricingMethod
        range[table] = range[table].reduce((accum, { colour, clarity, ...restProps }) =>
          colourMap[colour] <= colourMap[gridSize.minColour]
            && colourMap[colour] >= colourMap[gridSize.maxColour]
            && clarityMap[clarity] <= clarityMap[gridSize.minClarity]
            && clarityMap[clarity] >= clarityMap[gridSize.maxClarity]
            && restProps[tableKey] != null
            ? accum.concat({ colour, clarity, ...restProps }) : accum, [])

        if (range.additionalDiscounts?.fluorescence) {
          range.additionalDiscounts.fluorescence = range.additionalDiscounts.fluorescence.reduce((accum, { discounts, ...restProps }) =>
            displayFluors.length
            && colourMap[restProps.minColour] <= colourMap[gridSize.minColour]
            && colourMap[restProps.maxColour] >= colourMap[gridSize.maxColour]
            && clarityMap[restProps.minClarity] <= clarityMap[gridSize.minClarity]
            && clarityMap[restProps.maxClarity] >= clarityMap[gridSize.maxClarity]
              ? accum.concat({
                ...restProps,
                discounts: Object.entries(discounts).reduce((discountAccum, [discountKey, discountVal]) => Object.assign(discountAccum, fluorMap[discountKey] <= fluorMap[gridSize.maxFluorescence] && fluorMap[discountKey] >= fluorMap[gridSize.minFluorescence] && { [discountKey]: discountVal }), {})
              }) : accum, [])
        }
      }
      if (range.additionalDiscounts?.fluorescence && !range.additionalDiscounts.fluorescence.length) {
        delete range.additionalDiscounts.fluorescence
      }
    }

    order.lines = Array.isArray(order.lines) ? order.lines : []
    order.lines.forEach(line => {
      delete line.id // this is for create, on edit we'll only delete the ones that are numeric
      if (line.tingesIncluded) line.tingesIncluded = Object.keys(line.tingesIncluded).filter(t => line.tingesIncluded[t])
    })
    order.status = status
    for (const key in order) {
      if (order[key] === '' || order[key] == null) delete order[key]
    }
    validateAndSubmit({
      requiredFields: [],
      data: order,
      redirectPath: '/orders/',
      successMessage: 'Order created',
      customValidationFunction: (state) => validateOrderLines(state.lines)
    })
  }

  function validateOrderLines(orderLines = []) {
    const lineErrors = []
    for (const line of orderLines) {
      if (line.minWeight == null || line.minWeight === '' || line.maxWeight == null || line.maxWeight === '') {
        lineErrors.push('Every order line must have a minimum and maximum weight.')
        continue
      }
      if (line.maxColour == null || line.maxColour === '' || line.minColour == null || line.minColour === '') {
        lineErrors.push(`Order line for ${line.minWeight}-${line.maxWeight} must have a colour range.`)
      }
      if (line.maxClarity == null || line.maxClarity === '' || line.minClarity == null || line.minClarity === '') {
        lineErrors.push(`Order line for ${line.minWeight}-${line.maxWeight} must have a clarity range.`)
      }
      if (line.minFluorescence == null || line.minFluorescence === '' || line.maxFluorescence == null || line.maxFluorescence === '') {
        lineErrors.push(`Order line for ${line.minWeight}-${line.maxWeight} must have a fluorescence range.`)
      }
      if (line.quantityType == null || line.quantityType === '') lineErrors.push(`Order line for  ${line.minWeight}-${line.maxWeight} must have a quantity type.`)
      if (line.quantityType !== 'TOPS' && (line.quantity == null || line.quantity === '')) {
        lineErrors.push(`Order line for ${line.minWeight}-${line.maxWeight} must have a quantity.`)
      }
      if (line.tingesIncluded == null || Object.keys(line.tingesIncluded).reduce((total, i) => line.tingesIncluded[i] ? total + 1 : total, 0) < 1) {
        lineErrors.push(`Order line for ${line.minWeight}-${line.maxWeight} must have at least one tinge selected.`)
      }
    }
    if (lineErrors.length) return lineErrors
  }

  return <>
    <Form>
      <OrderLines
        name='lines'
        onChange={x => props.handleChange(x)}
        value={props.getState('lines') || []}
        tingeMapId={props.getState('tingeMapId')}
        priceScheme={props.getState('priceScheme')}
      />
      {renderValidationError()}
      <div className="details-page__form-btns">
        <Button
          size='sm'
          typeVariant='action'
          onClick={() => props.prev()}
        >
          Previous
        </Button>
        <Button
          size='sm'
          onClick={() => handleSubmit(props.state)}
        >
          Save Order
        </Button>
        <Button
          size='sm'
          onClick={() => setSubmitOrderConfirmationModalIsOpen(true)}
        >
          Submit Order
        </Button>
      </div>
    </Form>
    <SubmitOrderConfirmationModal open={submitOrderConfirmationModalIsOpen} onSubmit={() => {
      setSubmitOrderConfirmationModalIsOpen(false)
      handleSubmit(props.state, 'ORDER_SUBMITTED')
    }} onClose={() => setSubmitOrderConfirmationModalIsOpen(false)} />
  </>
}

OrderLinesStep.defaultProps = {
}

export default OrderLinesStep
