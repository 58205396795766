import { useEffect, useRef } from 'react'

const useActivityMonitor = function(allActivity = false) {
  const lastActivityTime = useRef(0)

  useEffect(() => {
    handleActivity()
    // the cleanup happens when the page is not
    // active anymore
    return () => handleActivity()
  }, [])

  function handleActivity() {
    lastActivityTime.current = new Date()
  }

  // This function is intended for mousemove and keydown events
  // It throttles events so that the time is updated at most every 5 seconds, to improve performance
  // But I'm not convinced it actually speeds things up, so let's try using the regular handleActivity for now, and we can delete this if it's not needed
  // function slowlyHandleActivity() {
  //   const now = new Date()
  //   if (now - lastActivityTime.current > 5000) lastActivityTime.current = now
  // }

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') handleActivity()
    })
    window.addEventListener('focus', handleActivity)
    document.addEventListener('focus', handleActivity)

    if (allActivity) {
      document.addEventListener('mousemove', handleActivity)
      document.addEventListener('keydown', handleActivity)
    }

    return () => {
      window.removeEventListener('focus', handleActivity)
      document.removeEventListener('focus', handleActivity)
      document.removeEventListener('visibilitychange', handleActivity)

      if (allActivity) {
        document.removeEventListener('mousemove', handleActivity)
        document.removeEventListener('keydown', handleActivity)
      }
    }
  }, [allActivity])

  return lastActivityTime
}

export {
  useActivityMonitor
}
