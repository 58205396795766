import create from 'zustand'
import persist from './persistStore'
import { share, isSupported } from 'shared-zustand'
import { objectUtils } from '@utils'
import { roleAndPermissionAction } from '@actions'

let permissionsCachePromise

const initialValues = {
  orgId: null,
  currentUser: null,
  currentSession: null,
  isAuthenticated: false,
  isAuthenticating: false,
  currentAuthenticatedUser: null,
  userRoles: [],
  mightRefreshSession: false,
  permissionsAdminCache: new Map()
}

const useAuthStore = create(
  persist({
    key: 'auth',
    allowlist: [
      'orgId',
      'isAuthenticated',
      'currentAuthenticatedUser'
    ],
    denylist: [
      'currentUser',
      'isAuthenticating',
      'currentSession',
      'userRoles'
    ]
  },
  (set, get) => ({
    // initial values
    ...initialValues,
    // setters (reducers)
    setCurrentUser: (currentUser) => {
      set(() => ({ currentUser }))
    },
    setCurrentSession: (currentSession) => {
      set(() => ({ currentSession }))
    },
    setCurrentAuthenticatedUser: (user) => {
      const userRoles = user?.attributes?.['custom:roles']
        ? JSON.parse(user.attributes?.['custom:roles'])
        : []
      set(() => ({
        // persisting only relevant data due to localStorage limit
        ...objectUtils.pick(initialValues, ['currentAuthenticatedUser', 'userRoles']),
        ...(user && {
          currentAuthenticatedUser: {
            attributes: user?.attributes,
            username: user?.username,
            userId: user?.attributes?.sub
          },
          userRoles
        }),
        isAuthenticated: !!user,
        orgId: user?.attributes?.['custom:org_id']
      }))
    },
    getMightRefreshSession: () => {
      return get().mightRefreshSession
    },
    setMightRefreshSession: (mightRefreshSession) => {
      set(() => ({ mightRefreshSession }))
    },

    getPermissions: async () => {
      if (!get().permissionsAdminCache.size) await get().setPermissionsCache()
      return get().permissionsAdminCache.keys()
    },
    setPermissionsCache: async () => {
      if (!permissionsCachePromise) {
        const userRoles = JSON.parse(get().currentAuthenticatedUser?.attributes?.['custom:roles'] || null) || []
        permissionsCachePromise = roleAndPermissionAction.getPermissions({ roles: userRoles }).finally(() => {
          permissionsCachePromise = null
        })
      }
      const queryResults = await permissionsCachePromise
      if (!queryResults) return
      const permCache = new Map()
      for (const permission of queryResults.data.data) {
        if (!permCache.has(permission.endpoint)) permCache.set(permission.endpoint, Boolean(permission.ignoreOrg))
        else {
          const admin = permCache.get(permission.endpoint)
          if (!admin && permission.ignoreOrg) permCache.set(permission.endpoint, Boolean(permission.ignoreOrg))
        }
      }
      set({ permissionsAdminCache: permCache })
    },
    hasPermission: route => {
      if (route?.endpoint) return get().permissionsAdminCache.has(route.endpoint)
      else return get().permissionsAdminCache.has(route)
    },
    hasAdmin: route => {
      if (route?.endpoint) return get().permissionsAdminCache.get(route.endpoint) ?? false
      else return get().permissionsAdminCache.get(route) ?? false
    },

    resetAuthStore: () => {
      set({
        ...initialValues
      })
    }
  })
  )
)

if (isSupported()) {
  share('currentSession', useAuthStore, { initialize: true })
}

export {
  useAuthStore
}
