import React, { useState, useEffect } from 'react'
import { shapeGroupActions } from '@actions'
import { Table } from '@organisms'
import { useModalStore, useShapeGroupStore } from '@stores'
import CreateShapeGroup from './modals/createShapeGroup'
import { useToast } from '@hooks'
import { NavLink, useHistory } from 'react-router-dom'

function ShapeGroupList(props) {
  const history = useHistory()
  const {
    shapeGroupsList: { all: shapeGroupsList },
    getShapeGroupsList
  } = useShapeGroupStore()
  const { setModal } = useModalStore()

  const { showSuccessToast } = useToast()

  useEffect(() => {
    getShapeGroupsList()
  }, [])

  async function handleCreateShapeGroup(form) {
    const res = await shapeGroupActions.createShapeGroup(form)
    showSuccessToast('Shape Group created.')
    history.push(`/shapegroups/${res.data.data}`)
  }

  const [columns, setColumns] = useState([])
  useEffect(() => {
    setColumns([
      {
        Header: 'ID',
        accessor: 'id',
        dataType: 'string',
        Cell: cellInfo => {
          return (
            <NavLink
              className="link"
              to={`/shapegroups/${cellInfo.row.original.id}`}
              id={cellInfo.row.original.id}
            >
              {cellInfo.value}
            </NavLink>
          )
        }
      },
      {
        Header: 'Description',
        accessor: 'description',
        dataType: 'string'
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        dataType: 'date',
        filterType: 'date'
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
        dataType: 'date',
        filterType: 'date'
      }
    ])
  }, [])

  const topBarActions = [{
    label: 'Create Shape Group',
    callback: () => setModal({
      id: 'createShapeGroup',
      title: 'Create Shape Group',
      type: 'form',
      customMessageRenderer: (messageProps) => <CreateShapeGroup {...messageProps} />,
      onSubmit: async (res) => await handleCreateShapeGroup(res[0])
    })
  }]

  return (
    <Table
      title='Shape Groups'
      data={shapeGroupsList}
      columns={columns}
      getDataCallback={getShapeGroupsList}
      topBarActions={topBarActions}
    />
  )
}

export default ShapeGroupList
