import React, { useState, useEffect, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useAuthStore, useGlobalsStore, useModalStore, useOrderStore, useTabsStore } from '@stores'
import moment from 'moment'
import { DetailsPage } from '@templates'
import { usePageTitle, useToast } from '@hooks'
import { orderActions, cutGradeActions, baselineActions, costActions, tingeMapActions, reportActions, organizationActions } from '@actions'
import OrderPricing from './pricing/orderPricing'
import OrderLines from './line/orderLines'
import DuplicateOrder from './duplicateOrder/duplicateOrder'
import clone from 'just-clone'
import deepEqual from 'fast-deep-equal'
import OrderFieldModal from './orderFieldModal'
import SubmitOrderConfirmationModal from './submitOrderConfirmationModal'
import { getFormFieldLabel } from './orderUtils'
import { useHistory } from 'react-router-dom'
import { orderSchema } from '@schemas'
import dottie from 'dottie'
import { InfoTip } from '@molecules'
import { fileUtils, textUtils, arrayUtils, objectUtils } from '@utils'
import OrderTransactionTable from './orderTransactionsTable'
import PriceAdjustOrderModal from './priceAdjustOrderModal'
import { COMMON } from '@constants'

const EDIT_ORDER_STATUSES = ['ORDER_INCOMPLETE', 'ORDER_NOT_SUBMITTED']
const ARCHIVE_ORDER_STATUSES = ['ORDER_INCOMPLETE', 'ORDER_NOT_SUBMITTED', 'ORDER_FULFILLED', 'ORDER_EXPIRED']

function OrderDetails({ match, title }) {
  const history = useHistory()
  const { showSuccessToast, showErrorToast } = useToast()
  const { orderId } = match?.params
  const [order, setOrder] = useState({})
  usePageTitle(title, orderId, order?.name)
  useEffect(() => {
    if (orderId) getOrderDetails()
    else setOrder({})
  }, [orderId])
  async function getOrderDetails() {
    return orderActions.getOrderList({ id: orderId, columns: '[OrderLines]' })
    .then(result => {
      const ord = result.data.data[0]
      ord.totalFilledQty = 0
      ord.lines = ord.OrderLines.slice().sort((a, b) => a.minWeight - b.minWeight)
      delete ord.OrderLines
      for (const line of ord.lines) {
        ord.totalFilledQty += line.quantityFilled
        line.tingesIncluded = line.tingesIncluded.reduce((obj, t) => ({ ...obj, [t]: true }), {})
      }
      setOrder(ord)
      return ord
    })
    .catch(console.error)
  }

  const { permissionsAdminCache, hasAdmin } = useAuthStore(state => state)
  const { buyerSettingsModalOptions, setActiveOrderFieldModal, setActiveOrderFieldModalField, setModal } = useModalStore()

  const {
    getProvenanceTypesList,
    getCountriesList,
    getMinesList,
    getOrderStatuses,
    getOrgsList,
    getPolishedColours,
    getClarities,
    getPolishedFluorescences,
    getBaselineShapeTypes,
    getQuantityTypes,
    getPricingRangeWeightGap,
    provenanceTypesList: { all: provenanceList },
    countriesList: { all: countriesList },
    minesList: { all: minesList },
    orderStatuses,
    orgsList: { all: orgsList },
    polishedColours: colours,
    clarities,
    polishedFluorescences,
    baselineShapeTypesMap,
    quantityTypes
  } = useGlobalsStore()
  useEffect(() => {
    getProvenanceTypesList()
    getCountriesList()
    getMinesList()
    getOrderStatuses()
    getOrgsList()
    getPolishedColours()
    getClarities()
    getPolishedFluorescences()
    getBaselineShapeTypes()
    getQuantityTypes()
    getPricingRangeWeightGap()
  }, [])

  const { gridSize, resetPricingGrid, removeAllOrdersListItems, pmColours } = useOrderStore(state => state)
  const { resetOrderTabs } = useTabsStore(state => state)

  const [shapesList, setShapesList] = useState()
  const [cutGradesList, setCutGradesList] = useState()
  const [baselinesList, setBaselinesList] = useState()
  const [mfgCostsList, setMfgCostsList] = useState()
  const [certCostsList, setCertCostsList] = useState()
  const [tingeMapsList, setTingeMapsList] = useState()
  useEffect(() => {
    Promise.all([
      cutGradeActions.getShapeList({ condition: 'ACTIVE', columns: '[id,name,buyerId,shapeType]' }),
      cutGradeActions.getCutGradeList({ condition: 'ACTIVE', columns: '[id,institute,name,buyerId,shapeId]' }),
      baselineActions.getBaselines({ condition: 'ACTIVE', columns: '[ParentBaseline]' }),
      costActions.getMfgCostList({ condition: 'ACTIVE' }),
      costActions.getCertCostList({ condition: 'ACTIVE' }),
      tingeMapActions.getTingeMapList({ condition: 'ACTIVE' })
    ])
    .then(([shapeResult, cutGradeResult, baselineResult, mfgResult, certResult, tingeResult]) => {
      setShapesList(shapeResult.data.data)
      setCutGradesList(cutGradeResult.data.data)
      setBaselinesList(baselineResult.data.data.sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt)))
      setMfgCostsList(mfgResult.data.data)
      setCertCostsList(certResult.data.data)
      setTingeMapsList(tingeResult.data.data)
    })
    .catch(console.error)
  }, [])

  useEffect(() => {
    if (shapesList && order?.shapeId && !shapesList.some(s => s.id === order.shapeId)) {
      cutGradeActions.getShapeList({ id: order.shapeId, columns: '[id,name,buyerId,shapeType]' })
      .then(shapeResult => {
        const sList = shapeResult.data.data
        if (!sList?.length) return
        setShapesList(shapesList.concat(sList))
      })
    }
  }, [order, shapesList])
  useEffect(() => {
    if (cutGradesList && order?.cutGradeId && !cutGradesList.some(s => s.id === order.cutGradeId)) {
      cutGradeActions.getCutGradeList({ id: order.cutGradeId, columns: '[id,institute,name,buyerId,shapeId]' })
      .then(cutGradeResult => {
        const cList = cutGradeResult.data.data
        if (!cList?.length) return
        setCutGradesList(cutGradesList.concat(cList))
      })
    }
  }, [order, cutGradesList])
  useEffect(() => {
    if (baselinesList && order?.priceBaselineId && !baselinesList.some(b => b.id === order.priceBaselineId)) {
      baselineActions.getBaselines({ id: order.priceBaselineId })
      .then(baselineResult => {
        const bList = baselineResult.data.data
        if (!bList?.length) return
        setBaselinesList(baselinesList.concat(bList))
      })
    }
  }, [order, baselinesList])
  useEffect(() => {
    if (mfgCostsList && order?.mfgCostId && !mfgCostsList.some(c => c.id === order.mfgCostId)) {
      costActions.getMfgCostList({ id: order.mfgCostId })
      .then(costResult => {
        const cList = costResult.data.data
        if (!cList?.length) return
        setMfgCostsList(mfgCostsList.concat(cList))
      })
    }
  }, [order, mfgCostsList])
  useEffect(() => {
    if (certCostsList && order?.certCostId && !certCostsList.some(c => c.id === order.certCostId)) {
      costActions.getCertCostList({ id: order.certCostId })
      .then(costResult => {
        const cList = costResult.data.data
        if (!cList?.length) return
        setCertCostsList(certCostsList.concat(cList))
      })
    }
  }, [order, certCostsList])
  useEffect(() => {
    if (tingeMapsList && order?.tingeMapId && !tingeMapsList.some(t => t.id === order.tingeMapId)) {
      tingeMapActions.getTingeMapList({ id: order.tingeMapId })
      .then(tingeMapResult => {
        const tList = tingeMapResult.data.data
        if (!tList?.length) return
        setTingeMapsList(tingeMapsList.concat(tList))
      })
    }
  }, [order, tingeMapsList])

  useEffect(() => {
    return () => {
      resetPricingGrid()
      resetOrderTabs()
    }
  }, [])

  const [cutGradesByShapeId, setCutGradesByShapeId] = useState(new Map())
  useEffect(() => {
    if (shapesList?.length && cutGradesList?.length) {
      setCutGradesByShapeId(
        cutGradesList.reduce((accum, curr) =>
          accum.set(curr.shapeId, [...(accum.get(curr.shapeId) || []), curr]), new Map())
      )
    }
  }, [shapesList, cutGradesList])

  const displayColours = useMemo(() => {
    if (!colours || !gridSize) return []
    return colours.reduce(({ colours, flag }, c) => {
      if (flag) return { colours: [...colours, c], flag: c.value !== gridSize?.minColour }
      else if (c.value === gridSize?.maxColour) return { colours: [...colours, c], flag: c.value !== gridSize?.minColour }
      else return { colours, flag }
    }, { colours: [], flag: false }).colours
  }, [colours, gridSize?.maxColour, gridSize?.minColour])
  const displayClarities = useMemo(() => {
    if (!clarities || !gridSize) return []
    return clarities.reduce(({ clarities, flag }, c) => {
      if (flag) return { clarities: [...clarities, c], flag: c.value !== gridSize?.minClarity }
      else if (c.value === gridSize?.maxClarity) return { clarities: [...clarities, c], flag: c.value !== gridSize?.minClarity }
      else return { clarities, flag }
    }, { clarities: [], flag: false }).clarities
  }, [clarities, gridSize?.maxClarity, gridSize?.minClarity])
  const displayFluors = useMemo(() => {
    if (!polishedFluorescences || !gridSize) return []
    return polishedFluorescences.reduce(({ fluors, flag }, f) => {
      if (flag) return { fluors: [...fluors, f], flag: f.value !== gridSize?.maxFluorescence }
      // else if (f.value === minFluorescence) return { fluors: [...fluors, f], flag: true }
      else return { fluors, flag }
    }, { fluors: [], flag: true }).fluors
  }, [polishedFluorescences, gridSize?.maxFluorescence])
  const [submitOrderConfirmationModalIsOpen, setSubmitOrderConfirmationModalIsOpen] = useState(false)

  const orderHasCosts = order?.mfgCostId || order?.certCostId || order?.priceScheme?.some(range => range.margin)
  const currOrg = useMemo(() => orgsList?.find(org => org.orgId === order.buyerId), [order, orgsList])
  const countries = useMemo(() => {
    if (!countriesList || !order) return []
    const [activeCountries, inactiveCountries] = arrayUtils.partitionBy(countriesList, x => x.condition === 'ACTIVE')
    return [...activeCountries, ...arrayUtils.intersectBy(inactiveCountries, order?.additionalFilters?.countryIds || [], (x) => objectUtils.isObject(x) ? x.id : x)]
  }, [countriesList, order])
  const mines = useMemo(() => {
    if (!minesList || !order) return []
    const [activeMines, inactiveMines] = arrayUtils.partitionBy(minesList, x => x.condition === 'ACTIVE')
    return [...activeMines, ...arrayUtils.intersectBy(inactiveMines, order?.additionalFilters?.mineIds || [], (x) => objectUtils.isObject(x) ? x.id : x)]
  }, [minesList, order])

  const handleViewClick = (key, id) => (e) => {
    e.preventDefault()
    const modalFields = { id, label: '' }
    switch (key) {
      case buyerSettingsModalOptions.CUT_GRADE:
        modalFields.label = getFormFieldLabel(key, cutGradesList.find(({ id: cutGradeId }) => id === cutGradeId))
        break
      case buyerSettingsModalOptions.BASELINE: {
        const baseline = baselinesList.find(({ id: baselineId }) => id === baselineId)
        modalFields.label = getFormFieldLabel(key, { ...baseline, shapeType: baselineShapeTypesMap?.[baseline.shapeType] ?? '' })
        break
      }
      case buyerSettingsModalOptions.MFG_COST:
        modalFields.label = getFormFieldLabel(key, mfgCostsList.find(({ id: mfgCostId }) => id === mfgCostId))
        break
      case buyerSettingsModalOptions.CERT_COST:
        modalFields.label = getFormFieldLabel(key, certCostsList.find(({ id: certCostId }) => id === certCostId))
        break
      case buyerSettingsModalOptions.TINGE:
        modalFields.label = getFormFieldLabel(key, tingeMapsList.find(({ id: tingeMapId }) => id === tingeMapId))
        break
    }
    setActiveOrderFieldModalField(modalFields)
    setActiveOrderFieldModal(key)
  }
  const handleDownloadClick = (key, id) => (e) => {
    e.preventDefault()
    switch (key) {
      case buyerSettingsModalOptions.BASELINE: {
        reportActions.exportBaseline('xlsx', id)
      .then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))
        break
      }
    }
  }

  const fluorGridRef = useRef(null)

  const [fields, setFields] = useState([])
  useEffect(() => {
    setFields(getFields(order))
  }, [order, currOrg, orgsList, shapesList, cutGradesList, provenanceList, countriesList, minesList, baselinesList, mfgCostsList, certCostsList, tingeMapsList, permissionsAdminCache])
  function getFields(order) {
    if (!Object.keys(order).length) return []
    const {
      showCosts: orgShowCosts,
      showComputedBaselines: orgShowComputedBL,
      acceptWindowedStones: orgAcceptWindowedStones,
      acceptBlockedStones: orgAcceptBlockedStones
    } = currOrg || {}
    const orgShowWindowedStones = order.additionalFilters?.acceptWindowedStones || orgAcceptWindowedStones != null
    const orgShowBlockedStones = order.additionalFilters?.acceptBlockedStones || orgAcceptBlockedStones != null
    const orgsAdmin = hasAdmin(organizationActions.getOrganizationList)
    const orderAdmin = hasAdmin(orderActions.editOrder)
    return [
      {
        label: 'Name',
        value: order.name,
        name: 'name',
        componentName: 'textInput',
        canEdit: true
      },
      {
        label: 'Buyer',
        value: order.buyerId,
        name: 'buyerId',
        componentName: 'dropdown',
        options: orgsList?.map(org => ({ value: org.orgId, label: org.commonName })),
        canEdit: false,
        shouldDisplay: orgsAdmin
      },
      {
        label: 'Status',
        value: order.status,
        name: 'status',
        componentName: 'dropdown',
        options: orderStatuses?.map(status => ({ value: status.value, label: status.description })),
        canEdit: false
      },
      {
        label: 'Condition',
        value: textUtils.formatDescription(order.condition),
        name: 'condition',
        componentName: 'textInput',
        canEdit: false,
        shouldDisplay: orderAdmin
      },
      {
        label: 'Shape',
        value: order.shapeId,
        name: 'shapeId',
        componentName: 'dropdown',
        options:
            shapesList?.filter(shape => shape.buyerId ? shape.buyerId === order.buyerId : true)
            .map(shape => ({
              value: shape.id,
              label: (shape.shapeType === 'CUSTOM' ? 'Custom | ' : 'Preloaded | ') + shape.name,
              isDisabled: !cutGradesByShapeId.has(shape.id)
            }))
            .sort((a, b) => { return a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1 }),
        canEdit: true,
        handleChange: async (_, doChange) => {
          doChange() // update shapeId
          doChange('cutGradeId', null) // update cutGradeId
        }
      },
      {
        label: 'Cut Grade',
        value: order?.cutGradeId,
        name: 'cutGradeId',
        componentName: 'dropdown',
        canEdit: true,
        handleChange: async (e, doChange) => {
          if (e?.currentTarget?.value || e?.target?.value) {
            return setModal({
              id: 'editOrderCutGradeConfirmation',
              title: 'Update Cut Grade',
              message: 'Please make sure you have reviewed all proportions for this cut grade, especially max total depth. Incorrect proportions can cause an incorrect yield or other undesirable result.',
              onSubmit: () => doChange(),
              buttonOptions: {
                showCancel: false
              }
            })
          }
          return doChange()
        },
        renderOverrides: values => {
          const shapeVal = values && values.find(v => v.name === 'shapeId')
          const shapeId = shapeVal ? shapeVal.value : order.shapeId
          const overrideId = values.find(item => item.name === 'cutGradeId')?.value
          return {
            ...(overrideId ? { topActions: [{ label: 'View', onClick: (_, e) => handleViewClick(buyerSettingsModalOptions.CUT_GRADE, overrideId)(e) }] } : {}),
            options: cutGradesList?.filter(grade => grade.shapeId === shapeId && (grade.buyerId ? grade.buyerId === order.buyerId : true))
                                    .map(grade => ({ value: grade.id, label: (grade.buyerId !== null ? 'Custom | ' : 'Preloaded | ') + grade.institute + ' | ' + grade.name }))
                                    .sort((a, b) => { return a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1 })
          }
        }
      },
      {
        legend: 'Additional Filters',
        componentName: 'fieldset',
        name: 'additionalFilters',
        canEdit: true,
        children: [
          {
            label: <React.Fragment>Provenance Types <InfoTip key="provType" name="provenanceType"/></React.Fragment>,
            value: order.additionalFilters?.provenanceTypeIds,
            componentName: 'dropdown',
            name: 'additionalFilters.provenanceTypeIds',
            placeholder: 'Accept All',
            options: provenanceList?.map(pt => ({ value: pt.id, label: pt.description }) ?? []),
            isMulti: true,
            canEdit: true,
            canAddAll: true,
            renderOverrides: values => {
              const acceptAll = values.filter(v => v.name.includes('additionalFilters')).every(v => v.name === 'additionalFilters.provenanceTypeIds' || !v.value || !v.value?.length)
              return { placeholder: acceptAll ? 'Accept All' : 'Accept any provenance matching other filters' }
            }
          },
          {
            label: 'Countries',
            value: order.additionalFilters?.countryIds,
            componentName: 'dropdown',
            name: 'additionalFilters.countryIds',
            placeholder: 'Accept All',
            options: countries?.map(pt => {
              const isDisabled = ['ARCHIVED', 'DELETED'].includes(pt.condition)
              return { value: pt.id, label: `${pt.name}${isDisabled ? ' (Inactive)' : ''}`, isDisabled }
            }),
            isMulti: true,
            canEdit: true,
            canAddAll: true,
            renderOverrides: values => {
              const acceptAll = values.filter(v => v.name.includes('additionalFilters')).every(v => v.name === 'additionalFilters.countryIds' || !v.value || !v.value?.length)
              return { placeholder: acceptAll ? 'Accept All' : 'Accept any country matching other filters' }
            }
          },
          {
            label: 'Mines',
            value: order.additionalFilters?.mineIds ?? [],
            componentName: 'dropdown',
            name: 'additionalFilters.mineIds',
            placeholder: 'Accept All',
            options: mines?.map(pt => {
              const isDisabled = ['ARCHIVED', 'DELETED'].includes(pt.condition)
              return { value: pt.id, label: `${pt.name}${isDisabled ? ' (Inactive)' : ''}`, isDisabled }
            }),
            isMulti: true,
            canEdit: true,
            canAddAll: true,
            renderOverrides: values => {
              const acceptAll = values.filter(v => v.name.includes('additionalFilters')).every(v => v.name === 'additionalFilters.mineIds' || !v.value || !v.value?.length)
              return { placeholder: acceptAll ? 'Accept All' : 'Accept any mine matching other filters' }
            }
          },
          ...(orgShowWindowedStones ? [{
            label: 'Accept Windowed Stones',
            value: order.additionalFilters?.acceptWindowedStones ?? false,
            componentName: 'checkbox',
            name: 'additionalFilters.acceptWindowedStones',
            canEdit: true
          }] : []),
          ...(orgShowBlockedStones ? [{
            label: 'Accept Blocked Stones',
            value: order.additionalFilters?.acceptBlockedStones ?? false,
            componentName: 'checkbox',
            name: 'additionalFilters.acceptBlockedStones',
            canEdit: true
          }] : [])
        ]
      },
      {
        legend: 'Costs and Baselines',
        componentName: 'fieldset',
        name: 'costsAndBaselines',
        canEdit: true,
        children: [
          {
            label: 'Baseline',
            value: order?.priceBaselineId,
            componentName: 'dropdown',
            name: 'priceBaselineId',
            options: baselinesList?.filter(b => (!b.buyerId || b.buyerId === order.buyerId) && (!orgShowComputedBL ? order.priceBaselineId === b.id || b.colourMix === null : true))
                                    .map(b => ({ value: b.id, label: getFormFieldLabel('priceBaselineId', { ...b, shapeType: baselineShapeTypesMap?.[b.shapeType] ?? '' }) }) ?? []),
            canEdit: true,
            topActions: [],
            renderOverrides: (values) => {
              const overrideId = values.find(item => item.name === 'priceBaselineId')?.value
              if (!overrideId) return
              return {
                topActions: [
                  { label: 'View', onClick: (_, e) => handleViewClick(buyerSettingsModalOptions.BASELINE, overrideId)(e) },
                  { label: 'Download', onClick: (_, e) => handleDownloadClick(buyerSettingsModalOptions.BASELINE, overrideId)(e) }
                ]
              }
            }
          },
          {
            label: 'Manufacturing Costs',
            value: order?.mfgCostId,
            componentName: 'dropdown',
            name: 'mfgCostId',
            options: mfgCostsList?.filter(c => (!c.buyerId || c.buyerId === order.buyerId) && (!orgShowCosts ? c.id === order.mfgCostId : true))
                                   .map(c => ({ value: c.id, label: c.name }) ?? []),
            canEdit: true,
            shouldDisplay: orderHasCosts || orgShowCosts,
            renderOverrides: (values) => {
              const overrideId = values.find(item => item.name === 'mfgCostId')?.value
              if (!overrideId) return
              return {
                topActions: [{ label: 'View', onClick: (_, e) => handleViewClick(buyerSettingsModalOptions.MFG_COST, overrideId)(e) }]
              }
            }
          },
          {
            label: 'Certificate Costs',
            value: order?.certCostId,
            componentName: 'dropdown',
            name: 'certCostId',
            options: certCostsList?.filter(c => (!c.buyerId || c.buyerId === order.buyerId) && (!orgShowCosts ? c.id === order.certCostId : true))
                                    .map(c => ({ value: c.id, label: c.name }) ?? []),
            canEdit: true,
            shouldDisplay: orderHasCosts || orgShowCosts,
            renderOverrides: (values) => {
              const overrideId = values.find(item => item.name === 'certCostId')?.value
              if (!overrideId) return
              return {
                topActions: [{ label: 'View', onClick: (_, e) => handleViewClick(buyerSettingsModalOptions.CERT_COST, overrideId)(e) }]
              }
            }
          },
          {
            label: 'Tinge Mapping',
            value: order?.tingeMapId,
            componentName: 'dropdown',
            name: 'tingeMapId',
            options: tingeMapsList?.filter(m => !m.buyerId || m.buyerId === order.buyerId)
                                    .map(m => ({ value: m.id, label: m.name }) ?? []),
            canEdit: true,
            renderOverrides: (values) => {
              const overrideId = values.find(item => item.name === 'tingeMapId')?.value
              if (!overrideId) return
              return {
                topActions: [{ label: 'View', onClick: (_, e) => handleViewClick(buyerSettingsModalOptions.TINGE, overrideId)(e) }]
              }
            }
          }
        ]
      },
      {
        label: 'Pricing',
        value: order.priceScheme,
        name: 'priceScheme',
        customComponent: OrderPricing,
        span: true,
        fixed: true,
        showCosts: orgShowCosts,
        canEdit: true,
        recalcId: order.id
      },
      {
        label: 'Order Lines',
        value: order.lines ?? [],
        name: 'lines',
        customComponent: OrderLines,
        span: true,
        canEdit: true,
        tingeMapId: order.tingeMapId,
        priceScheme: order.priceScheme,
        renderOverrides: (values) => ({
          tingeMapId: values.find(item => item.legend === 'Costs and Baselines')?.children.find(item => item.name === 'tingeMapId')?.value,
          priceScheme: values.find(item => item.name === 'priceScheme')?.value
        })
      },
      {
        label: 'Created At',
        value: moment(order.createdAt).toLocaleString()
      },
      {
        label: 'Last Updated',
        value: moment(order.updatedAt).toLocaleString()
      }
    ]
  }

  function unsubmitOrder() {
    orderActions.editOrder({ id: orderId, status: 'ORDER_NOT_SUBMITTED' })
    .then(() => {
      showSuccessToast('Order has been suspended from matching.')
      getOrderDetails()
    })
  }

  function submitOrder() {
    orderActions.editOrder({ id: orderId, status: 'ORDER_SUBMITTED' })
    .then(() => {
      showSuccessToast('Order has been submitted for matching.')
      getOrderDetails()
    })
  }

  function sanitizeOrder(sanitizeOptions = {}) {
    return async function(formValues) {
      try {
        if (fluorGridRef.current) fluorGridRef.current.getCompressedDiscounts()
        const orderData = clone(formValues)

        if (orderData.priceScheme) {
          const colourMap = displayColours.reduce((map, c, i) => ({ ...map, [c.value]: i + 1 }), {})
          const clarityMap = displayClarities.reduce((map, c, i) => ({ ...map, [c.value]: i + 1 }), {})
          const fluorMap = displayFluors.reduce((map, f, i) => ({ ...map, [f.value]: i + 1 }), {})
          orderData.priceScheme.forEach(range => {
            if (range.pricingMethod) {
              const table = range.pricingMethod === 'ppc' ? 'priceTable' : 'discountTable'
              const tableKey = range.pricingMethod === 'ppc' ? 'ppc' : 'percentage'
              table === 'priceTable' ? delete range.discountTable : delete range.priceTable
              delete range.pricingMethod
              range[table] = (range[table] ?? []).reduce((accum, { colour, clarity, ...restProps }) =>
                colourMap[colour] <= colourMap[gridSize.minColour]
                  && colourMap[colour] >= colourMap[gridSize.maxColour]
                  && clarityMap[clarity] <= clarityMap[gridSize.minClarity]
                  && clarityMap[clarity] >= clarityMap[gridSize.maxClarity]
                  && restProps[tableKey] != null
                  ? accum.concat({ colour, clarity, ...restProps }) : accum
              , [])

              if (range.additionalDiscounts?.fluorescence) {
                range.additionalDiscounts.fluorescence = range.additionalDiscounts.fluorescence.reduce((accum, { discounts, ...restProps }) =>
                  displayFluors.length
                    && colourMap[restProps.minColour] <= colourMap[gridSize.minColour]
                    && colourMap[restProps.maxColour] >= colourMap[gridSize.maxColour]
                    && clarityMap[restProps.minClarity] <= clarityMap[gridSize.minClarity]
                    && clarityMap[restProps.maxClarity] >= clarityMap[gridSize.maxClarity]
                    ? accum.concat({
                      ...restProps,
                      discounts: Object.entries(discounts).reduce((discountAccum, [discountKey, discountVal]) => Object.assign(discountAccum, fluorMap[discountKey] <= fluorMap[gridSize.maxFluorescence] && fluorMap[discountKey] >= fluorMap[gridSize.minFluorescence] && { [discountKey]: discountVal }), {})
                    }) : accum, [])
              }
            }
            if (range.additionalDiscounts?.fluorescence && !range.additionalDiscounts.fluorescence.length) {
              delete range.additionalDiscounts.fluorescence
            }
          })
        }

        if (orderData.priceScheme) {
          for (const range of orderData.priceScheme) {
            delete range.pricingMethod
            delete range.id
          }
          if (deepEqual(orderData.priceScheme, order.priceScheme)) {
            delete orderData.priceScheme
          } else {
            // If we are validating priceScheme, we should also re-validate lines
            orderData.lines = orderData.lines || order.lines.map(line => ({ ...line }))
          }
        }

        if (orderData.lines) {
          for (const line of orderData.lines) {
            if (!isNaN(line.id)) delete line.id
            delete line.orderId
            delete line.quantityFilled
            delete line.createdAt
            delete line.updatedAt
            delete line.condition
            if (line.id) {
              const foundLine = order.lines.find(ol => ol.id === line.id)
              for (const key in line) {
                if (key !== 'id' && deepEqual(line[key], foundLine[key])) {
                  delete line[key]
                }
              }
            }
            if (line.tingesIncluded) line.tingesIncluded = Object.keys(line.tingesIncluded).filter(t => line.tingesIncluded[t])
            if (line.quantityType === 'TOPS') line.quantity = null
          }
          // If all lines are unchanged we do not need to include lines in the request
          if (orderData.lines.length) {
            const noLinesChanged = orderData.lines.every(line => line.id && Object.keys(line).length === 1)
            const noLinesRemoved = order.lines.every(line => orderData.lines.find(({ id }) => id === line.id))
            if (noLinesChanged && noLinesRemoved) delete orderData.lines
          }
        }

        if (orderData.additionalFilters) {
          const arrKeys = ['provenanceTypeIds', 'countryIds', 'mineIds']
          for (const key in orderData.additionalFilters) {
            if (!arrKeys.includes(key)) continue
            if (!orderData.additionalFilters[key] || !orderData.additionalFilters[key].length) orderData.additionalFilters[key] = null
          }
        }

        // Check if there are any archived elements in country or mines and add to validation if so
        const additionalFilters = orderData.additionalFilters || order.additionalFilters
        const archivedCountry = (additionalFilters?.countryIds || []).some(countryId => {
          const country = countries.find(c => c.id === countryId)
          return ['ARCHIVED', 'DELETED'].includes(country?.condition)
        })
        if (archivedCountry) dottie.set(orderData, ['additionalFilters', 'countryIds'], additionalFilters.countryIds)
        const archivedMine = (additionalFilters?.mineIds || []).some(mineId => {
          const mine = mines.find(m => m.id === mineId)
          return ['ARCHIVED', 'DELETED'].includes(mine?.condition)
        })
        if (archivedMine) orderData.additionalFilters.mineIds = dottie.set(orderData, ['additionalFilters', 'mineIds'], additionalFilters.mineIds)

        /// Sanitize Options
        const { skip = [], remove = [], duplicate = [] } = sanitizeOptions
        // Skip sanitization of properties from orderData
        for (const key of skip) {
          dottie.set(orderData, key, dottie.get(formValues, key))
        }
        // Remove properties from orderData
        for (const key of remove) {
          delete orderData[key]
        }
        // Duplicate properties from order
        for (const key of duplicate) {
          dottie.set(orderData, key, dottie.get(order, key))
        }

        return orderData
      } catch (err) {
        console.error(err)
        showErrorToast('Error occured while validating order data')
        return undefined
      }
    }
  }

  async function handleOnDuplicate(orderValues) {
    // clean and create order
    const orderData = await sanitizeOrder(
      {
        remove: [
          'id', 'replanDate', 'submittingIpAddress', 'condition', 'createdAt', 'updatedAt', 'totalFilledQty',
          ...(!order?.priceBaselineId ? ['priceBaselineId'] : []),
          ...(!order?.tingeMapId ? ['tingeMapId'] : []),
          ...(!order?.mfgCostId ? ['mfgCostId'] : []),
          ...(!order?.certCostId ? ['certCostId'] : []),
          ...(!order?.additionalFilters ? ['additionalFilters'] : []),
          ...(!order?.expiryDate ? ['expiryDate'] : [])
        ],
        duplicate: ['priceScheme'],
        skip: order.lines.reduce((skipArr, { quantityType }, idx) =>
          quantityType === 'TOPS' ? skipArr.concat(`lines.${idx}.quantity`) : skipArr,
        [])
      }
    )({
      ...order,
      ...{
        lines: order.lines.map(({ id, quantity, ...restData }) => (
          {
            ...restData,
            quantity: restData.quantityType === 'TOPS' ? undefined : quantity
          }
        ))
      },
      ...orderValues,
      status: 'ORDER_NOT_SUBMITTED'
    })
    if (orderData) {
      return orderActions.createOrder(orderData)
      .then((res) => {
        showSuccessToast('Order duplicated.')
        setOrder({})
        history.push(`/orders/${res.data.data}`)
      })
    }
  }

  async function handleOnUpdate(editedValues) {
    await handleConfirm(editedValues)

    return orderActions.editOrder({ id: orderId, ...editedValues })
      .then(() => {
        showSuccessToast('Order updated.')
        getOrderDetails()
      })
  }

  // Show confirmation modals for changes
  async function handleConfirm(orderData) {
    // check if we have conflicting shape type with baseline and shape
    if ('priceBaselineId' in orderData || 'shapeId' in orderData) {
      const { priceBaselineId = order?.priceBaselineId, shapeId = order?.shapeId } = orderData
      const isRoundShape = shapesList?.find(({ id }) => id === shapeId)?.name === 'ROUND'
      const baselineShapeType = baselinesList?.find(({ id }) => id === priceBaselineId)?.shapeType
      const baselineShapeTypeDescription = baselineShapeTypesMap?.[baselineShapeType] ?? ''
      if ((isRoundShape && baselineShapeType === 'FANCY') || (!isRoundShape && baselineShapeType === 'ROUND')) {
        await new Promise((resolve, reject) => setModal({
          id: 'orderDetailsBaselineConfirmation',
          title: 'Confirm Pricing',
          message: textUtils.substituteConstantTemplateStr('You selected a {{shapeType}} Shape. Are you sure you want to use a {{shapeTypeDescription}} Baseline for this order?', { shapeType: isRoundShape ? 'ROUND' : `NON-${'ROUND'}`, shapeTypeDescription: baselineShapeTypeDescription }),
          onSubmit: resolve, // resolve will continue execution of this function
          onCancel: reject // reject and subsequent error will stop further execution of this function be handled by detailsPage handleOnSubmit
        }))
      }
    }
  }

  function handleOnCancel() {
    // reset the orderPricing globals so the editted values do not persist
    resetPricingGrid()
    resetOrderTabs()
  }

  async function handleOnRefresh() {
    const order = await getOrderDetails()
    return getFields(order)
  }

  function handleArchive() {
    orderActions.setOrderCondition({ orderIds: [order.id], condition: 'ARCHIVED' })
    .then(() => removeAllOrdersListItems([order.id]))
    .then(() => {
      showSuccessToast('Order has been archived.')
      history.push('/orders')
    })
  }

  function handleUnarchive() {
    orderActions.setOrderCondition({ ordersIds: [order.id], condition: 'ACTIVE' })
    .then(() => removeAllOrdersListItems([order.id]))
    .then(() => {
      showSuccessToast('Order has been unarchived.')
      history.push('/orders')
    })
  }

  function handleRemove() {
    orderActions.setOrderCondition({ orderIds: [order.id], condition: 'DELETED' })
    .then(() => removeAllOrdersListItems([order.id]))
    .then(() => {
      showSuccessToast('Order has been removed.')
      history.push('/orders')
    })
  }

  function handlePriceAdjust(priceAdjust) {
    return orderActions.editOrder({
      id: order.id,
      priceAdjust
    })
    .then(() => removeAllOrdersListItems([order.id]))
    .then(() => {
      showSuccessToast('Order price\'s have been adjusted.')
    })
  }

  const validationConstants = useMemo(() => ({
    ORDER_STATUSES: arrayUtils.pickBy(orderStatuses || [], 'value'),
    COLOURS: arrayUtils.pickBy(colours || [], 'value'),
    CLARITIES: arrayUtils.pickBy(clarities || [], 'value'),
    POLISHED_FLUORESCENCES: arrayUtils.pickBy(polishedFluorescences || [], 'value'),
    QUANTITY_TYPES: arrayUtils.pickBy(quantityTypes || [], 'value'),
    WEIGHT_GAP_FN: getPricingRangeWeightGap,
    SHOW_COSTS: currOrg?.showCosts,
    PM_COLOURS: pmColours,
    MINES: arrayUtils.pickBy((mines || []).filter(m => m.condition === 'ACTIVE'), 'id'),
    COUNTRIES: arrayUtils.pickBy((countries || []).filter(c => c.condition === 'ACTIVE'), 'id')
  }), [orderStatuses, colours, clarities, polishedFluorescences, quantityTypes, mines, countries, currOrg])
  const warningValidationSchema = useMemo(() => orderSchema.orderDetailsWarningValidationSchema(validationConstants), [validationConstants])

  const canArchive = order.condition !== 'ARCHIVED' && order.condition !== 'DELETED' && ARCHIVE_ORDER_STATUSES.includes(order.status)
  const canUnarchive = order.condition === 'ARCHIVED'
  const canRemove = order.condition !== 'DELETED' && ARCHIVE_ORDER_STATUSES.includes(order.status) && order.totalFilledQty === 0
  const canEdit = EDIT_ORDER_STATUSES.includes(order.status) && order.condition === 'ACTIVE'

  const extraIcons = [
    {
      icon: 'copy',
      title: 'Duplicate',
      viewOnly: true,
      onClick: () =>
        setModal({
          id: 'orderDetailsDuplicateOrder',
          title: 'Duplicate Order',
          type: 'form',
          customMessageRenderer: (messageProps) => <DuplicateOrder {...messageProps} />,
          onSubmit: (res) => handleOnDuplicate(res[0])
        })
    }
  ]

  const extraButtons = [
    {
      onClick: () => setSubmitOrderConfirmationModalIsOpen(true),
      caption: 'Submit for Matching',
      viewOnly: true,
      shouldDisplay: order?.condition === 'ACTIVE' && EDIT_ORDER_STATUSES.includes(order?.status)
    },
    {
      onClick: unsubmitOrder,
      caption: 'Suspend Matching',
      viewOnly: true,
      shouldDisplay: (order?.status === 'ORDER_SUBMITTED' && order?.condition === 'ACTIVE')
    },
    {
      caption: 'Adjust Pricing',
      viewOnly: true,
      shouldDisplay: true,
      renderOverrides: (_, detailsProps) => ({
        shouldDisplay: !detailsProps.isEditToggled && EDIT_ORDER_STATUSES.includes(order?.status) && order?.condition === 'ACTIVE'
      }),
      onClick: (_, detailsProps) => setModal({
        id: 'orderDetailsPriceAdjust',
        title: 'Adjust Order Prices',
        type: 'form',
        customMessageRenderer: (messageProps) => <PriceAdjustOrderModal orders={[order.id]} {...messageProps}/>,
        onSubmit: (res) => handlePriceAdjust(res?.[0]).then(detailsProps.handleOnRefresh),
        closeOnFail: false
      })
    },
    {
      onClick: async (values, detailsProps = {}) => {
        const { isEditToggled = false, getValues } = detailsProps
        if (isEditToggled) {
          const formValues = getValues(values)
          const temp = await sanitizeOrder()(formValues)
          if (!temp.priceScheme) temp.priceScheme = clone(order.priceScheme)
          const getTable = (range) => {
            if ('priceTable' in range && 'discountTable' in range) return null
            else if ('priceTable' in range) return 'priceTable'
            else if ('discountTable' in range) return 'discountTable'
            else return null
          }
          const getTableObj = (table, colour, clarity) => ({
            [table === 'priceTable' ? 'ppc' : 'percentage']: null,
            colour,
            clarity
          })
          // Fill in filtered null cells within grid colours and grid clarities
          temp.priceScheme = temp.priceScheme.reduce((scheme, range) => {
            const table = getTable(range)
            if (table) {
              const rangeMap = arrayUtils.toMap(range[table], (x) => `${x.colour}_${x.clarity}`, null)
              for (const col of displayColours) {
                for (const cla of displayClarities) {
                  if (!rangeMap[`${col.value}_${cla.value}`]) range[table].push(getTableObj(table, col.value, cla.value))
                }
              }
              scheme.push(range)
            }
            return scheme
          }, [])

          reportActions.exportTempOrder('xlsx', order.id, { showWarnings: true, order: temp })
          .then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))
        } else {
          reportActions.exportOrder('xlsx', order.id, { showWarnings: true })
          .then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))
        }
      },
      caption: 'Export Order',
      // renderOverrides: (_, detailsProps) => ({
      //   shouldDisplay: !detailsProps.isEditToggled
      // })
      shouldDisplay: true
    }
  ]

  return (
    <div className="order-details__container">
      <DetailsPage
        canEdit={canEdit}
        fields={fields}
        onSanitize={sanitizeOrder()}
        onSubmit={handleOnUpdate}
        onCancel={handleOnCancel}
        onRemove={canRemove ? handleRemove : null}
        onArchive={canArchive ? handleArchive : null}
        onUnarchive={canUnarchive ? handleUnarchive : null}
        onRefresh={handleOnRefresh}
        title={{
          label: 'Order ID',
          value: order?.id || ''
        }}
        extraIcons={extraIcons}
        extraButtons={extraButtons}
        propsByRef={{ fluorGridRef }}
        validationProps={{
          validationSchema: { error: orderSchema.orderDetailsErrorValidationSchema, warning: warningValidationSchema }
        }}
      />
      <div className="order-details__transactions-table">
        <OrderTransactionTable orderId={orderId} />
      </div>
      <OrderFieldModal/>
      <SubmitOrderConfirmationModal
        open={submitOrderConfirmationModalIsOpen}
        onSubmit={() => {
          setSubmitOrderConfirmationModalIsOpen(false)
          submitOrder()
        }}
        onClose={() => setSubmitOrderConfirmationModalIsOpen(false)} />
    </div>
  )
}

OrderDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string
}

export default OrderDetails
