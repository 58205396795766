import axios from 'axios'
const BASE_URL = process.env.REACT_APP_API_BASE_URL

function getPricePoints(sellerId) {
  return axios.get(`${BASE_URL}/seller/pricepoints`, { params: (sellerId ? { sellerId } : {}) })
}
getPricePoints.endpoint = 'GET|/seller/pricepoints'

export const pricePointActions = {
  getPricePoints
}
