import axios from 'axios'
import { requestUtils } from '@utils'
const BASE_URL = process.env.REACT_APP_API_BASE_URL

const getBuyerTransactions = function (params, options) {
  return axios.get(`${BASE_URL}/sales/buyer/transactions`, { params, options })
}
getBuyerTransactions.endpoint = 'GET|/sales/buyer/transactions'
const getBuyerTransactionsBatch = requestUtils.batchRequest(getBuyerTransactions)

const getSellerTransactions = function (params, options) {
  return axios.get(`${BASE_URL}/sales/seller/transactions`, { params, options })
}
getSellerTransactions.endpoint = 'GET|/sales/seller/transactions'
const getSellerTransactionsBatch = requestUtils.batchRequest(getSellerTransactions)

const getAdminTransactions = function (params, options) {
  return axios.get(`${BASE_URL}/sales/transactions`, { params, options })
}
getAdminTransactions.endpoint = 'GET|/sales/transactions'
const getAdminTransactionsBatch = requestUtils.batchRequest(getAdminTransactions)

const getOrderTransactions = function(orderId) {
  return axios.get(`${BASE_URL}/sales/transactions/orders/${orderId}`)
}
getOrderTransactions.endpoint = 'GET|/sales/transactions/orders/:orderId:'

const editTransactions = function(params) {
  return axios.patch(`${BASE_URL}/sales/transactions`, params)
}
editTransactions.endpoint = 'PATCH|/sales/transactions'

const createTransaction = function(params) {
  return axios.post(`${BASE_URL}/sales/transactions`, params)
}
createTransaction.endpoint = 'POST|/sales/transactions'

export const transactionActions = {
  getBuyerTransactions,
  getBuyerTransactionsBatch,
  getSellerTransactions,
  createTransaction,
  getSellerTransactionsBatch,
  getAdminTransactions,
  getAdminTransactionsBatch,
  getOrderTransactions,
  editTransactions
}
