import axios from 'axios'
const BASE_URL = process.env.REACT_APP_API_BASE_URL

const createSales = function (data) {
  return axios.post(`${BASE_URL}/sales`, data)
}
createSales.endpoint = 'POST|/sales'

const editSale = function({ id, ...sale }) {
  return axios.patch(`${BASE_URL}/sales/${id}`, sale)
}
editSale.endpoint = 'PATCH|/sales/:saleId:'

const getSales = function (params) {
  return axios.get(`${BASE_URL}/sales`, { params })
}
getSales.endpoint = 'GET|/sales'

export const saleActions = {
  editSale,
  getSales,
  createSales
}
