import React, { useState, useEffect } from 'react'
import { Form, Fieldset } from '@organisms'
import { TextInput, Dropdown, InfoTip } from '@molecules'
import { Button, Checkbox } from '@atoms'
import { cutGradeActions, organizationActions } from '@actions'
import { useGlobalsStore, useAuthStore, useModalStore } from '@stores'
import { getFormFieldLabel } from '../orderUtils'
import { objectUtils } from '@utils'

function NameShapeCutGradeStep(props) {
  const { validateAndGoNextStep, renderValidationError, updateMultipleState, setFormLocalState, getFormLocalState, validationSchemaError } = props.useMultiSteps(props)
  const { hasAdmin } = useAuthStore()
  const { buyerSettingsModalOptions, setActiveOrderFieldModal, setActiveOrderFieldModalField, setModal } = useModalStore()

  const [shapeList, setShapeList] = useState([])
  useEffect(() => {
    cutGradeActions.getShapeList({ condition: 'ACTIVE' })
    .then(response => setShapeList(response.data.data))
    .catch(err => console.error(err))
  }, [])

  const [cutGradeList, setCutGradeList] = useState([])
  useEffect(() => {
    cutGradeActions.getCutGradeList({ condition: 'ACTIVE' })
    .then(response => setCutGradeList(response.data.data))
    .catch(err => console.error(err))
  }, [])

  const [cutGradesByShapeId, setCutGradesByShapeId] = useState(new Map())
  useEffect(() => {
    if (shapeList.length && cutGradeList.length) {
      setCutGradesByShapeId(
        cutGradeList.reduce((accum, curr) =>
          accum.set(curr.shapeId, [...(accum.get(curr.shapeId) || []), curr]), new Map())
      )
    }
  }, [shapeList, cutGradeList])

  const {
    getProvenanceTypesList,
    getCountriesList,
    getMinesList,
    getOrgsList,
    provenanceTypesList: { all: provenanceTypes },
    countriesList: { [JSON.stringify({ condition: 'ACTIVE' })]: countries },
    minesList: { [JSON.stringify({ condition: 'ACTIVE' })]: mines },
    orgsList: { [JSON.stringify({ condition: 'ACTIVE' })]: orgsList }
  } = useGlobalsStore()
  useEffect(() => {
    getProvenanceTypesList()
    getCountriesList({ condition: 'ACTIVE' })
    getMinesList({ condition: 'ACTIVE' })
    getOrgsList({ condition: 'ACTIVE' })
  }, [])

  const updateBuyer = (orgId) => {
    const currBuyer = orgsList.find(({ orgId: id }) => id === orgId)
    setFormLocalState({ buyer: currBuyer })
    updateMultipleState({
      'additionalFilters.acceptWindowedStones': currBuyer?.acceptWindowedStones ?? null,
      'additionalFilters.acceptBlockedStones': currBuyer?.acceptBlockedStones ?? null
    })
  }

  useEffect(() => {
    // Initialize buyer
    if (!orgsList || getFormLocalState('buyer')) return
    updateBuyer(props.formData.buyerId)
  }, [orgsList, props.formData.buyerId])

  const handleViewClick = (key, id) => (e) => {
    e.preventDefault()
    setActiveOrderFieldModalField({ id, label: getFormFieldLabel(key, cutGradeList.find(({ id: cutGradeId }) => id === cutGradeId)) })
    setActiveOrderFieldModal(key)
  }

  const handleBuyerChange = (e) => {
    if (!e.currentTarget && !e.target) return
    const { value } = e?.currentTarget || e?.target
    updateBuyer(value)
    props.handleChange(e)
  }

  const handleShapeChange = (e) => {
    if (!e.currentTarget && !e.target) return
    const { value } = e?.currentTarget || e?.target
    setFormLocalState({ isRoundShape: shapeList.find(({ id }) => id === value)?.name === 'ROUND' })
    props.handleChange(e)
  }
  const handleAdditionalFilterChange = (e) => {
    if (!e.currentTarget && !e.target) return
    const { name, value } = e?.currentTarget || e?.target
    const currentFilters = {
      ...objectUtils.pick(props.state, [
        'additionalFilters.provenanceTypeIds',
        'additionalFilters.countryIds',
        'additionalFilters.mineIds'
      ]),
      [name]: value
    }
    setFormLocalState({ addFltrsAcceptCurrent: Object.values(currentFilters).some(filter => filter) })
    props.handleChange(e)
  }

  return (
    <Form>
      {
        hasAdmin(organizationActions.getOrganizationList)
          ? <Dropdown
            name='buyerId'
            label='Buyer'
            value={props.getState('buyerId')}
            onChange={handleBuyerChange}
            options={orgsList?.map(org => ({
              value: org.orgId,
              label: org.commonName
            }))}
            isClearable={false}
          />
          : null
      }
      <TextInput
        name='name'
        label='Order Name'
        value={props.getState('name')}
        onChange={props.handleChange}
        validationText={validationSchemaError?.parseStep('name')}
      />
      <Dropdown
        name='shapeId'
        label='Shape'
        value={props.getState('shapeId')}
        onChange={handleShapeChange}
        options={shapeList
          .filter(shape => shape.buyerId ? shape.buyerId === props.getState('buyerId') : true)
          .sort((a, b) => {
            if (a.shapeType === b.shapeType) return a.name - b.name
            else if (a.shapeType === 'CUSTOM') return -1
            else if (b.shapeType === 'CUSTOM') return 1
            else return a.name - b.name
          })
          .map(shape => ({
            value: shape.id,
            label: getFormFieldLabel('shapeId', shape),
            isDisabled: !cutGradesByShapeId.has(shape.id)
          }))}
        validationText={validationSchemaError?.parseStep('shapeId')}
      />
      <Dropdown
        name='cutGradeId'
        label={props.getState('cutGradeId') ? <>Cut Grade <a onClick={handleViewClick(buyerSettingsModalOptions.CUT_GRADE, props.getState('cutGradeId'))}>View</a></> : 'Cut Grade'}
        value={props.getState('cutGradeId')}
        onChange={(e, ...restProps) => {
          if (e?.currentTarget?.value || e?.target?.value) {
            return setModal({
              id: 'createOrderCutGradeConfirmation',
              title: 'Select Cut Grade',
              message: 'Please make sure you have reviewed all proportions for this cut grade, especially max total depth. Incorrect proportions can cause an incorrect yield or other undesirable result.',
              onSubmit: () => props.handleChange(e, ...restProps),
              buttonOptions: { showCancel: false }
            })
          }
          props.handleChange(e, ...restProps)
        }}
        options={cutGradeList
          .filter(grade => grade.shapeId === props.getState('shapeId') && (grade.buyerId ? grade.buyerId === props.getState('buyerId') : true))
          .sort((a, b) => {
            if (a.buyerId === b.buyerId) return a.name - b.name
            else if (a.buyerId !== null) return -1
            else if (b.buyerId !== null) return 1
            else return a.name - b.name
          })
          .map(grade => ({
            value: grade.id,
            label: getFormFieldLabel('cutGradeId', grade)
          }))
        }
        validationText={validationSchemaError?.parseStep('cutGradeId')}
      />
      <Fieldset
        legend='Additional Filters'
      >
        <Dropdown
          name='additionalFilters.provenanceTypeIds'
          label={<>Provenance Type <InfoTip name="provenanceType"/></>}
          value={props.getState('additionalFilters.provenanceTypeIds')}
          placeholder={getFormLocalState('addFltrsAcceptCurrent') ? 'Accept any provenance matching other filters' : 'Accept All' }
          onChange={handleAdditionalFilterChange}
          isMulti={true}
          canAddAll={true}
          options={provenanceTypes?.map(provenance => ({
            value: provenance.id,
            label: getFormFieldLabel('additionalFilters.provenanceTypeIds', provenance)
          }))}
        />
        <Dropdown
          name='additionalFilters.countryIds'
          label='Country'
          value={props.getState('additionalFilters.countryIds')}
          placeholder={getFormLocalState('addFltrsAcceptCurrent') ? 'Accept any country matching other filters' : 'Accept All' }
          onChange={handleAdditionalFilterChange}
          isMulti={true}
          canAddAll={true}
          options={countries?.map(country => ({
            value: country.id,
            label: getFormFieldLabel('additionalFilters.countryIds', country)

          }))}
        />
        <Dropdown
          name='additionalFilters.mineIds'
          label='Mine'
          value={props.getState('additionalFilters.mineIds')}
          placeholder={getFormLocalState('addFltrsAcceptCurrent') ? 'Accept any mine matching other filters' : 'Accept All' }
          onChange={handleAdditionalFilterChange}
          isMulti={true}
          canAddAll={true}
          options={mines?.map(mine => ({
            value: mine.id,
            label: getFormFieldLabel('additionalFilters.mineIds', mine)

          }))}
        />
        {getFormLocalState('buyer')?.acceptWindowedStones != null && (
          <Checkbox
            name='additionalFilters.acceptWindowedStones'
            label='Accept Windowed Stones'
            value={props.getState('additionalFilters.acceptWindowedStones')}
            onChange={props.handleChange}
            isClearable={false}
          />
        )}
        {getFormLocalState('buyer')?.acceptBlockedStones != null && (
          <Checkbox
            name='additionalFilters.acceptBlockedStones'
            label='Accept Blocked Stones'
            value={props.getState('additionalFilters.acceptBlockedStones')}
            onChange={props.handleChange}
            isClearable={false}
          />
        )}
      </Fieldset>
      {renderValidationError()}
      <div className="right">
        <Button
          size='sm'
          typeVariant='action'
          disabled
        >
          Previous
        </Button>
        <Button
          size='sm'
          onClick={() => validateAndGoNextStep()}
        >
          Next
        </Button>
      </div>
    </Form>
  )
}

export default NameShapeCutGradeStep
