import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { notificationsActions } from '@actions'
import NotificationActionList from './notificationActionList'
import { DetailsPage } from '@templates'
import { usePageTitle, useToast } from '@hooks'
import { COMMON } from '@constants'
import { useGlobalsStore } from '@stores'
import moment from 'moment'
import { textUtils } from '@utils'

function AppNotificationDetails({ match, title }) {
  const { notificationId } = match?.params
  const [fields, setFields] = useState([])
  const [recipient, setRecipient] = useState('')
  const [actions, setActions] = useState([0])
  const [notification, setNotification] = useState(null)
  const { showSuccessToast } = useToast()
  const { getOrgById, getUserById } = useGlobalsStore()

  usePageTitle(title, notificationId, notification?.title)

  const typeOptions = useMemo(() => {
    return Object.keys(COMMON.NOTIFICATION.TYPE).map(type => {
      return {
        value: type,
        label: COMMON.NOTIFICATION.TYPE[type]
      }
    })
  }, [COMMON])

  useEffect(() => {
    refreshAppNotifications()
  }, [])

  useEffect(() => {
    if (notification) {
      getUserById(notification.userId)
      .then(user => {
        getOrgById(user.orgId)
        .then(org => {
          setRecipient(`${user?.username} | ${org?.commonName}`)
        })
      })
    }
  }, [notification])

  useEffect(() => {
    if (notification) {
      setFields([
        {
          label: 'Title',
          name: 'title',
          value: notification.title,
          canEdit: true
        },
        {
          label: 'Type',
          name: 'type',
          value: notification.type,
          componentName: 'dropDown',
          options: typeOptions,
          canEdit: true
        },
        {
          label: 'Body',
          name: 'body',
          value: notification.body,
          componentName: 'textArea',
          canEdit: true,
          span: true
        },
        {
          legend: 'Actions',
          componentName: 'fieldset',
          name: 'actions',
          span: true,
          canEdit: true,
          children: [
            {
              name: 'actions',
              value: notification.actions || [],
              actions,
              setActions,
              canEdit: true,
              customComponent: NotificationActionList,
              isDetailsPage: true,
              span: true
            }
          ]
        },
        {
          label: 'Read',
          name: 'isRead',
          value: notification.isRead,
          canEdit: false,
          componentName: 'checkbox'
        },
        {
          label: 'Condition',
          name: 'condition',
          value: textUtils.formatDescription(notification.condition),
          canEdit: false
        },
        {
          label: 'Recipient',
          name: 'recipient',
          value: recipient,
          canEdit: false
        },
        {
          label: 'Notify Time',
          name: 'notifyTime',
          type: 'datetime-local',
          value: textUtils.formatDate(notification.notifyTime, moment.HTML5_FMT.DATETIME_LOCAL),
          canEdit: true
        },
        {
          label: 'Created At',
          name: 'createdAt',
          value: textUtils.formatDate(notification.createdAt, true),
          canEdit: false
        },
        {
          label: 'Updated At',
          name: 'updateAt',
          value: textUtils.formatDate(notification.updatedAt, true),
          canEdit: false
        }
      ])
    }
  }, [notification, recipient])

  function handleOnSubmit(editedValues) {
    return notificationsActions.editAppNotification(notificationId, notification.userId, editedValues)
    .then(() => {
      showSuccessToast('Notification updated.')
      refreshAppNotifications()
    })
  }

  function isSent(notification) {
    return moment(notification?.notifyTime).diff(new Date()) <= 0
  }

  function refreshAppNotifications() {
    notificationsActions.getAppNotifications({ notificationId })
    .then(response => setNotification(response.data.data[0]))
  }

  return (
    <DetailsPage
      title={{
        label: 'Notification ID',
        value: notification?.notificationId || ''
      }}
      onSubmit={handleOnSubmit}
      canEdit={!isSent(notification)}
      fields={fields}
    />
  )
}

AppNotificationDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string
}

export default AppNotificationDetails
