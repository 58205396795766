import create from 'zustand'

const useTableStore = create((set, get) => ({
  tables: {},
  setTableConfig: (tableId, tableConfig) => {
    if (!tableId) return
    set({ tables: { ...get().tables, [tableId]: tableConfig } })
  },
  getTableConfig: (tableId) => {
    if (!tableId) return
    return get().tables[tableId] || null
  },
  removeTableConfig: (tableId) => {
    if (!tableId) return
    delete get().tables[tableId]
    set({ tables: { ...get().tables } })
  },
  editRowIndex: {},
  setEditRowIndex: (tableId, rowIndex) => {
    if (!tableId) return
    set({ editRowIndex: { ...get().editRowIndex, [tableId]: rowIndex } })
  },
  getEditRowIndex: (tableId) => {
    return get().editRowIndex[tableId] || null
  },
  removeEditRowIndex: (tableId) => {
    if (!tableId) return
    delete get().editRowIndex[tableId]
    set({ editRowIndex: { ...get().editRowIndex } })
  },
  editValues: {},
  setEditValue: (tableId, name, value, originalValue) => {
    if (!tableId) return
    set({
      editValues: {
        ...get().editValues,
        [tableId]: {
          ...get().editValues[tableId],
          [name]: { value, originalValue }
        }
      }
    })
  },
  getEditValues: (tableId) => {
    return get().editValues[tableId] || {}
  },
  removeEditValues: (tableId) => {
    if (!tableId) return
    delete get().editValues[tableId]
    set({ editValues: { ...get().editValues } })
  },
  editRow: {},
  setEditRow: (tableId, row) => {
    if (row && tableId) {
      set({ editRow: { ...get().editRow, [tableId]: row } })
      get().setEditRowIndex(tableId, row.index)
      get().setIsEditing(tableId, true)
    }
  },
  getEditRow: (tableId) => {
    if (!tableId) return
    return get().editRow[tableId] || null
  },
  removeEditRow: (tableId) => {
    if (!tableId) return
    delete get().editRow[tableId]
    set({ editRow: { ...get().editRow } })
  },
  isEditing: {},
  setIsEditing: (tableId, isEditing) => {
    if (!tableId) return
    set({ isEditing: { ...get().isEditing, [tableId]: isEditing } })
  },
  getIsEditing: (tableId) => {
    if (!tableId) return
    return get().isEditing[tableId] || null
  },
  removeIsEditing: (tableId) => {
    if (!tableId) return
    delete get().isEditing[tableId]
    set({ isEditing: { ...get().isEditing } })
  },
  filters: {},
  setFilterValue: (tableId, columnId, value) => {
    if (!tableId) return
    const id = get().getFilterId(tableId)
    set({
      filters: {
        ...get().filters,
        [id]: {
          ...get().filters[id],
          [columnId]: {
            ...get().filters[id]?.[columnId],
            value
          }
        }
      }
    })
    get().setIsFiltered(tableId, columnId, value)
  },
  getFilterValue: (tableId, columnId) => {
    const id = get().getFilterId(tableId)
    if (columnId) return get().filters[id]?.[columnId]
    return get().filters[id] || {}
  },
  setIsFiltered: (tableId, columnId, value) => {
    if (!tableId) return
    const id = get().getFilterId(tableId)
    if (!get().filters[id]?.[columnId]?.isFilteredFn) return
    set({
      filters: {
        ...get().filters,
        [id]: {
          ...get().filters[id],
          [columnId]: {
            ...get().filters[id]?.[columnId],
            isFiltered: get().filters[id][columnId]?.isFilteredFn(value) ?? false
          }
        }
      }
    })
  },
  getIsFiltered: (tableId, columnId) => {
    const id = get().getFilterId(tableId)
    return get().filters[id]?.[columnId]?.isFiltered
  },
  setIsFilteredFn: (tableId, columnId, fn) => {
    if (!tableId) return
    const id = get().getFilterId(tableId)
    set({
      filters: {
        ...get().filters,
        [id]: {
          ...get().filters[id],
          [columnId]: { ...get().filters[id]?.[columnId], isFilteredFn: fn }
        }
      }
    })
  },
  removeFilterValues: (tableId) => {
    if (!tableId) return
    if (!Object.keys(get().filters).length) return
    const id = get().getFilterId(tableId)
    set({
      filters: {
        ...Object.keys(get().filters[id]).reduce((filters, f) => {
          filters[id][f] = {
            ...filters[id][f],
            isFiltered: false,
            value: undefined
          }
          return filters
        }, { ...get().filters })
      }
    })
  },
  getFilterId: (tableId) => {
    const tableConfig = get().getTableConfig(tableId)
    return tableConfig?.tableTabs?.length ? tableId + (tableConfig?.activeTab.id ?? tableConfig?.activeTab.label) : tableId
  },
  getIsTableFiltered: (tableId) => {
    if (!tableId) return
    const id = get().getFilterId(tableId)
    if (!get().filters[id]) return
    return Object.keys(get().filters[id]).some(column => get().filters[id]?.[column]?.isFiltered)
  },
  resetTableFilters: () => {
    set({ filters: {} })
  },
  activeTab: {},
  setActiveTab: (tableId, tabId, active = false) => {
    if (!tableId) return
    set({
      filters: {
        ...get().filters,
        [tableId]: {
          ...get().filters[tableId],
          [tabId]: { active }
        }
      }
    })
  },
  resetTableStore: (tableId) => {
    get().removeTableConfig(tableId)
    get().removeEditValues(tableId)
    get().removeEditRow(tableId)
    get().removeEditRowIndex(tableId)
    get().removeIsEditing(tableId)
  }
}))

export {
  useTableStore
}
