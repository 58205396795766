import React from 'react'
import PropTypes from 'prop-types'

import { organizationActions } from '@actions'
import { MultiStepsForm } from '@organisms'
import MainStep from './mainStep'
import AddressStep from './addressStep'
import BuyerSettingsStep from './buyerSettingsStep'
import SellerSettingsStep from './sellerSettingsStep'
import { useGlobalsStore } from '@stores'

function OrganizationForm(props) {
  const steps = [
    { title: 'Main', component: MainStep },
    { title: 'Address', component: AddressStep },
    { title: 'Buyer Settings', component: BuyerSettingsStep },
    { title: 'Seller Settings', component: SellerSettingsStep }
  ]

  const { setOrgsList } = useGlobalsStore(store => store)

  return (
    <div className="col-sm-14 col-md-10 col-lg-8">
      <MultiStepsForm
        steps={steps}
        formData={props.formData}
        stateSchema={props.stateSchema}
        onSubmit={(orgData) => {
          return organizationActions.createOrganization(orgData)
          .then(result => {
            setOrgsList() // not awaited or returned so that it'll asynchronously load while redirecting
            return result
          })
        }}
      />
    </div>
  )
}

OrganizationForm.propTypes = {
  formData: PropTypes.object,
  stateSchema: PropTypes.array
}

OrganizationForm.defaultProps = {
  formData: null
}

export default OrganizationForm
