import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from '@molecules'

function DashboardInputCard({
  name,
  label,
  value,
  options,
  onChange,
  isVisible,
  componentName,
  isClearable
}) {
  if (!isVisible) return null
  return (
    <div className='dashboard-input-card'>
      <div className="dashboard-input-card__label">{label}</div>
      <div className="dashboard-input-card__input">
        <Dropdown
          size='sm'
          name={name}
          value={value}
          options={options}
          onChange={onChange}
          isClearable={isClearable}
        />
      </div>
    </div>
  )
}

DashboardInputCard.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  isVisible: PropTypes.bool,
  componentName: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isClearable: PropTypes.bool
}

DashboardInputCard.defaultProps = {
  label: '',
  value: null,
  options: [],
  isVisible: true,
  componentName: 'dropdown',
  isClearable: true
}

export default DashboardInputCard
