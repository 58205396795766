import React, { useState, useEffect } from 'react'
import { Table } from '@organisms'
import { transactionActions } from '@actions'
import { useGlobalsStore } from '@stores'
import { NavLink } from 'react-router-dom'
import { textUtils } from '@utils'

function OrderTransactionTable({ orderId }) {
  const [transactions, setTransactions] = useState([])
  const {
    polishedColoursMap: coloursMap,
    polishedColours,
    claritiesMap,
    clarities,
    polishedFluorescencesMap: fluorsMap,
    polishedFluorescences,
    polishedTingesMap: tingesMap,
    polishedTinges,
    getPolishedColours,
    getClarities,
    getPolishedFluorescences,
    getPolishedTinges
  } = useGlobalsStore(state => state)
  useEffect(() => {
    getPolishedColours()
    getClarities()
    getPolishedFluorescences()
    getPolishedTinges()
  }, [])

  function refreshList(id) {
    return transactionActions.getOrderTransactions(id)
      .then(result => setTransactions(result.data.data.map(txn => ({ ...txn, transactionDateFmted: textUtils.formatDate(txn.transactionDate, true) }))))
      .catch(console.error)
  }

  useEffect(() => {
    if (orderId) refreshList(orderId)
  }, [orderId])

  const [columns, setColumns] = useState([])
  useEffect(() => {
    setColumns([
      {
        Header: 'Clara ID',
        accessor: 'roughId',
        dataType: 'string',
        Cell: cellInfo => {
          return (
            <NavLink
              className="link"
              to={`/transactions/${cellInfo.row.original.transactionId}`}
              id={cellInfo.row.original.id}
            >
              {cellInfo.value}
            </NavLink>
          )
        }
      },
      {
        Header: 'Weight',
        accessor: 'polishedWeight',
        dataType: 'string',
        filterType: 'numberRange'
      },
      {
        Header: 'Color',
        accessor: row => coloursMap?.[row?.colour] ?? '',
        id: 'color',
        dataType: 'string',
        filterType: 'checkbox',
        enums: polishedColours?.map(pc => pc.description)
      },
      {
        Header: 'Clarity',
        accessor: row => claritiesMap?.[row?.clarity] ?? '',
        id: 'clarity',
        dataType: 'string',
        filterType: 'checkbox',
        enums: clarities?.map(c => c.description)
      },
      {
        Header: 'Fluor',
        accessor: row => fluorsMap?.[row?.fluorescence] ?? '',
        id: 'fluor',
        dataType: 'string',
        filterType: 'checkbox',
        enums: polishedFluorescences?.map(pf => pf.description)
      },
      {
        Header: 'Tinge',
        accessor: row => tingesMap?.[row?.tinge] ?? '',
        id: 'tinge',
        dataType: 'string',
        filterType: 'checkbox',
        enums: polishedTinges?.map(pt => pt.description)
      },
      {
        Header: 'Value',
        accessor: 'partPrice',
        dataType: 'currency',
        filterType: 'numberRange',
        decimalScale: 2,
        fixedDecimalScale: true
      },
      {
        Header: 'Date Transacted',
        accessor: 'transactionDateFmted',
        dataType: 'date',
        filterType: 'date'
      }
    ])
  }, [
    transactions,
    coloursMap,
    polishedColours,
    claritiesMap,
    clarities,
    fluorsMap,
    polishedFluorescences,
    tingesMap,
    polishedTinges
  ])

  if (!transactions.length) return null

  return <Table
    title='Transactions'
    data={transactions}
    columns={columns}
    isMultiSelect={false}
    initialSort={[
      { id: 'transactionDateFmted', desc: true },
      { id: 'roughId', desc: false }
    ]}
  />
}

export default OrderTransactionTable
