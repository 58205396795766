import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Collapsible, TextInput, Dropdown } from '@molecules'
import { FormComponents } from '@organisms'
import { COMMON } from '@constants'
import { Icon, Button } from '@atoms'
import { v4 as uuidv4 } from 'uuid'

function NotificationActions({
  isDetailsPage,
  name,
  disabled,
  ...props
}) {
  const initialActions = useMemo(() => updateInitialActions(props.value), [])
  const [actions, setActions] = useState(initialActions)
  const constActionTypes = COMMON.NOTIFICATION.ACTION.TYPE
  const actionTypes = Object.values(constActionTypes).map(({ key: value, label }) => ({
    value,
    label
  }))

  const {
    TextInput: SimpleFormTextInput,
    Dropdown: SimpleFormDropdown
  } = FormComponents

  useEffect(() => {
    if (!isDetailsPage) {
      setActions(updateInitialActions(props?.initialValues?.app?.actions))
    }
  }, [props?.initialValues?.app?.actions])

  function updateInitialActions(actions) {
    if (!actions?.length) return [{ id: uuidv4() }]

    return actions.map(action => {
      action.id = uuidv4()
      return action
    })
  }

  function addAction() {
    setActions([...actions, { id: uuidv4() }])
  }

  function removeAction(index, { id }) {
    const _actions = [...actions.filter(action => action.id !== id)]
    if (!isDetailsPage) {
      // remove action from formik values
      const formActions = props.getFieldMeta('app.actions')
      if (formActions.value) {
        props.setFieldValue('app.actions', [...formActions.value.filter((_, i) => i !== index)])
      }
    } else {
      updateDetailsPageValues(_actions)
    }
    setActions(_actions)
  }

  function handleOnChange(e, actionIndex) {
    const name = e?.target?.name || e?.currentTarget?.name
    const value = e?.target?.value || e?.currentTarget?.value
    const _actions = actions
    _actions[actionIndex] = { ..._actions[actionIndex], [name]: value }

    setActions([..._actions])
    updateDetailsPageValues(_actions)
  }

  function updateDetailsPageValues(actions) {
    props.onChange({
      target: {
        name,
        value: [...actions.map(action => ({
          name: action.name,
          args: action.args,
          type: action.type
        }))]
      }
    })
  }

  function renderSimpleFormVersion(actionIndex) {
    return (
      <>
        <SimpleFormTextInput
          label='Name'
          name={`app.actions.${actionIndex}.name`}
          required={false}
          {...props}
        />
        <SimpleFormDropdown
          label='Type'
          name={`app.actions.${actionIndex}.type`}
          options={actionTypes}
          required={false}
          {...props}
        />
        <SimpleFormTextInput
          label='Args'
          name={`app.actions.${actionIndex}.args`}
          required={false}
          {...props}
        />
      </>
    )
  }

  function renderDetailsPageVersion(actionIndex) {
    return (
      <>
        <TextInput
          label='Name'
          name='name'
          value={actions[actionIndex]?.name}
          required={false}
          disabled={!props?.props?.isEditToggled}
          onChange={e => handleOnChange(e, actionIndex)}
        />
        <Dropdown
          label='Type'
          name='type'
          value={actions[actionIndex]?.type}
          options={actionTypes}
          required={false}
          disabled={!props?.props?.isEditToggled}
          onChange={e => handleOnChange(e, actionIndex)}
        />
        <TextInput
          label='Args'
          name='args'
          value={actions[actionIndex]?.args}
          required={false}
          disabled={!props?.props?.isEditToggled}
          onChange={e => handleOnChange(e, actionIndex)}
        />
      </>
    )
  }

  return (
    <>
      {
        actions.map((action, index) => {
          return (
            <div
              key={'row-action-collapsible'}
              className="create-notification-template__action-collapsible-row"
            >
              <Collapsible
                key={`row-action-collapsible-${index}`}
                isOpened={actions.length - 1 === Number(index)}
                title={`Action #${Number(index) + 1}`}
              >
                {isDetailsPage
                  ? renderDetailsPageVersion(index)
                  : renderSimpleFormVersion(index)
                }
              </Collapsible>
              <div className="create-notification-template__action-collapsible-btns">
                <Button
                  typeVariant='action'
                  onClick={() => addAction()}
                  disabled={(isDetailsPage && !props?.props?.isEditToggled)
                    || disabled}
                  size='sm'
                >
                    Add Action
                </Button>
                <Icon
                  name='remove'
                  onClick={() => removeAction(index, action)}
                  disabled={actions?.length <= 1 || (isDetailsPage && !props?.props?.isEditToggled)}
                  size='md'
                />
              </div>
            </div>
          )
        })
      }
    </>
  )
}

NotificationActions.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  isDetailsPage: PropTypes.bool
}

NotificationActions.defaultProps = {
  name: 'app.actions',
  disabled: false,
  isDetailsPage: false
}

export default NotificationActions
