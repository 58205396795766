import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRangePicker } from 'react-date-range'
import { useTableStore } from '@stores'
import moment from 'moment'

const DateRangeFilter = function ({
  column: { filterValue, setFilter, id },
  tableId
}) {
  const initialDateRange = useMemo(() => ({
    startDate: filterValue ? filterValue[0] : new Date(),
    endDate: filterValue ? filterValue[1] : new Date(),
    key: 'selection'
  }))

  const [
    selectionRange,
    setSelectionRange
  ] = useState(initialDateRange)
  const {
    setFilterValue,
    setIsFilteredFn,
    getFilterValue,
    getTableConfig
  } = useTableStore(state => state)

  const storeFilterValue = getFilterValue(tableId, id)
  const tableConfig = getTableConfig(tableId)

  useEffect(() => {
    setIsFilteredFn(tableId, id, (filter) => !!filter)
  }, [tableConfig?.activeTab?.id, tableConfig?.activeTab?.label])

  useEffect(() => {
    setFilter(storeFilterValue?.value)
  }, [storeFilterValue?.value])

  useEffect(() => {
    if (!filterValue) {
      setSelectionRange(initialDateRange)
    }
  }, [filterValue])

  function handleChange ({ selection }) {
    // sets the value in the date range picker calendar
    setSelectionRange(selection)
    setFilterValue(tableId, id, [selection.startDate, selection.endDate])
  }

  return (
    <div className="table__date-range-select-container" onClick={e => e.stopPropagation()}>
      <DateRangePicker
        ranges={[selectionRange]}
        onChange={handleChange}
      />
    </div>
  )
}

const dateRangeFilter = function (rows, columnIds, filterValue) {
  if (!filterValue) return rows
  const startDate = moment(filterValue[0])
  const endDate = moment(filterValue[1])
  const rangeGranularity = 'day'
  const columnName = columnIds[0]

  if (!moment(startDate).isValid() || !moment(endDate).isValid()) { return rows }

  return rows.filter(row => {
    const rowDate = moment(new Date(row.values[columnName]))
    if (!moment(rowDate).isValid()) { return true }

    return rowDate.isSameOrAfter(startDate, rangeGranularity) && rowDate.isSameOrBefore(endDate, rangeGranularity)
  })
}

DateRangeFilter.propTypes = {
  column: PropTypes.object,
  tableId: PropTypes.string,
  setIsFiltered: PropTypes.func
}

export {
  DateRangeFilter,
  dateRangeFilter
}
