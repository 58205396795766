import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ConfirmationModal } from '@templates'
import { Dropdown } from '@molecules'

function CopyOrderModal({
  open,
  orders,
  onClose,
  onSubmit

}) {
  const [orderId, setOrderId] = useState('')

  return (
    <ConfirmationModal
      title='Copy Pricing From An Existing Order?'
      open={open}
      onClose={onClose}
      onSubmit={() => {
        onSubmit(orderId)
        .then(() => setOrderId(''))
      }}
    >
      <Dropdown
        className='dropdown dropdown-container--sm'
        name='copyOrder'
        onChange={e => setOrderId(e.currentTarget.value)}
        value={orderId}
        options={orders.map(order => ({ value: order.id, label: order.name }))}
      />
    </ConfirmationModal>
  )
}

CopyOrderModal.propTypes = {
  open: PropTypes.bool,
  onChange: PropTypes.func,
  setRecalcId: PropTypes.func,
  orders: PropTypes.arrayOf(PropTypes.object)
}

CopyOrderModal.defaultProps = {
  open: false,
  orders: [{ id: '', name: '' }]
}
export default CopyOrderModal
