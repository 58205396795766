import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import AssortmentDetailsPanel from './assortment_details_panel'
import AssortmentRoughList from './assortmentRoughList'
import { usePageTitle } from '@hooks'
import { assortmentActions } from '@actions'

function AssortmentDetails(props) {
  const assortmentId = props.match.params.assortmentId
  const [assortment, setAssortment] = useState(null)
  usePageTitle(props?.title, assortmentId, assortment?.name)

  function refreshAssortmentDetails() {
    assortmentActions.getAssortmentById(assortmentId)
    .then(async response => {
      const assort = response.data.data[0]
      setAssortment(assort)
      // return assort
    })
  }

  useEffect(() => {
    refreshAssortmentDetails()
  }, [])

  const roughStoneParams = useMemo(() => ({
    assortmentId,
    columns: [
      'id',
      'sellerStoneName',
      'assortmentId',
      'Assortment',
      'Country',
      'Mine',
      'Pipe',
      'File',
      'Batch',
      'status',
      'condition',
      'createdAt',
      'updatedAt',
      'sellerId',
      'weight',
      'weightCategory',
      'colour',
      'fluorescence',
      'tinge',
      'pricePoint',
      'reservePpc',
      'reservePpcOriginal',
      'reservePpcOverride',
      'genericPrice',
      'comments',
      'qcStatus',
      'location',
      'type'
    ]
  }), [assortmentId])

  return (
    <div className='assortment-details'>
      <div className="assortment-details__details-container">
        <AssortmentDetailsPanel
          assortment={assortment}
          refreshAssortmentDetails={refreshAssortmentDetails}
          assortmentId={assortmentId}
          roughStoneParams={roughStoneParams}
          operation='view'
        />
      </div>
      <AssortmentRoughList
        assortmentId={assortmentId}
        roughStoneParams={roughStoneParams}
        refreshAssortmentDetails={refreshAssortmentDetails}
      />
    </div>
  )
}

AssortmentDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string
}

export default AssortmentDetails
