import { useProgressListStore } from '@stores'
import PQueue from 'p-queue'
import { v4 as uuidv4 } from 'uuid'

const useProgressList = function () {
  const {
    addList,
    getList,
    addToQueue,
    setLists,
    lists,
    updateList,
    setIsRemoved
  } = useProgressListStore(state => state)

  const createProgressList = function ({
    name = '',
    title = '',
    concurrency = 1,
    autoStart = true,
    onAllTasksComplete = null
  }) {
    const listId = name
    addList({
      id: listId,
      title,
      queue: new PQueue({ concurrency, autoStart }),
      tasks: {},
      isOpen: false,
      isMinimized: false,
      onAllTasksComplete,
      count: () => {
        const list = getList(listId)
        if (!list) return
        const { tasks } = list
        return {
          total: Object.keys(tasks).filter(task => !tasks[task].status.isRemoved).length || 0,
          succeeded: Object.keys(tasks).filter(task => tasks[task].status.isSucceeded).length || 0,
          failed: Object.keys(tasks).filter(task => tasks[task].status.isFailed).length || 0,
          removed: Object.keys(tasks).filter(task => tasks[task].status.isRemoved).length || 0,
          waiting: Object.keys(tasks).filter(task => !tasks[task].status.isFailed && !tasks[task].status.isSucceeded && !tasks[task].status.isInProgress).length || 0,
          inProgress: Object.keys(tasks).filter(task => tasks[task].status.isInProgress).length || 0
        }
      },
      newTask: () => {
        return {
          id: uuidv4(),
          name: '',
          title: '',
          status: {
            isInProgress: false,
            isSucceeded: false,
            isFailed: false,
            isRemoved: false
          },
          size: '',
          error: '',
          callback: null,
          priority: 0,
          progressPercentage: null
        }
      },
      addTask: (task) => {
        _addTask(listId, task)
      },
      removeTask: (task) => {
        setIsRemoved(listId, task)
      },
      updateTask: (taskId, task) => {
        _updateTask(listId, taskId, task)
      },
      updateTaskProgressPercentage: (taskId, percentage) => {
        const list = getList(listId)
        const { tasks } = list
        const task = tasks[taskId]
        if (task) {
          task.progressPercentage = percentage
          _updateTask(listId, task.id, task)
        }
      },
      retryTask: (task) => {
        const _list = getList(listId)
        const { id, status, error, progressPercentage } = _list.newTask()
        const _task = {
          ...task,
          id,
          status,
          error,
          progressPercentage
        }
        setIsRemoved(listId, task)
        _addTask(listId, _task)
      },
      removeAllTasks: () => {
        const list = getList(listId)
        if (!list) return
        const { tasks } = list
        Object.keys(tasks).forEach((task) => {
          if (!tasks[task].status.isInProgress) {
            list.removeTask(tasks[task])
          }
        })
      },
      setIsOpen: (isOpen) => {
        const list = getList(listId)
        if (!list) return
        list.isOpen = isOpen
        updateList(list)
        // when the user closes the list we want
        // to reset it to it's initial value
        if (!isOpen) list.reset()
      },
      setIsMinimized: (isMinimized) => {
        const list = getList(listId)
        if (!list) return
        list.isMinimized = isMinimized
        updateList(list)
      },
      setConcurrency: (concurrency = 1) => {
        const list = getList(listId)
        if (!list) return
        list.queue.concurrency = concurrency
      },
      isAllTasksComplete: () => {
        const list = getList(listId)
        if (!list) return
        const { tasks } = list
        return Object.keys(tasks).every(task => list.isTaskComplete(tasks[task].id))
      },
      isTaskComplete: (taskId) => {
        const list = getList(listId)
        if (!list) return
        const { tasks } = list
        const task = tasks[taskId]
        return task.status.isSucceeded || task.status.isFailed || task.status.isRemoved
      },
      isTaskSucceeded: (taskId) => {
        const list = getList(listId)
        if (!list) return
        const { tasks } = list
        const task = tasks[taskId]
        return task.status.isSucceeded
      },
      start: () => {
        const list = getList(listId)
        if (!list) return
        list.queue.start()
      },
      reset: () => {
        const list = getList(listId)
        if (!list) return
        list.queue.pause()
        list.queue.clear()
        list.tasks = {}
        list.isOpen = false
        list.isMinimized = false
        updateList(list)
      }
    })

    return getProgressList(listId)
  }

  const getProgressList = function (listId) {
    return getList(listId)
  }

  const getLists = function () {
    return lists
  }

  const resetProgressLists = function () {
    for (const list of lists) {
      // resetting each list individually to make sure it pauses and clear
      // every queue before resetting the lists object in the store
      list.reset()
    }
    // resets the lists object in the store
    setLists({})
  }

  const isProgressListEmpty = function () {
    return !Object.keys(lists).some(list => {
      return !!Object.keys(lists[list].tasks).length
    })
  }

  const isProgressListDone = function () {
    let isDone = true
    for (const list of Object.values(lists)) {
      for (const task of Object.values(list.tasks)) {
        if (task?.status?.isInProgress) isDone = false
      }
    }

    return isDone
  }

  const getSucceededTasks = function(listId) {
    const progressList = getProgressList(listId)
    if (!progressList) return
    return Object.keys(progressList.tasks).filter((id => progressList.isTaskSucceeded(id)))
  }

  const _addTask = function(listId, task) {
    if (!Object.keys(task).length) return
    if (!task.id) task.id = uuidv4()
    const list = getList(listId)
    if (!list) return
    list.tasks = { ...list.tasks, [task.id]: { ...task } }
    list.isOpen = true
    addToQueue(list.id, task.id)
  }

  const _updateTask = function (listId, taskId, task) {
    if (!Object.keys(task).length) return
    const list = getList(listId)
    if (!list?.tasks[taskId]) return
    list.tasks[taskId] = task
    updateList(list)
  }

  return {
    getLists,
    getProgressList,
    createProgressList,
    isProgressListEmpty,
    isProgressListDone,
    resetProgressLists,
    getSucceededTasks
  }
}

export { useProgressList }
