import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_BASE_URL

const getUsersList = function (params) {
  return axios.get(`${BASE_URL}/auth/users`, { params })
}
getUsersList.endpoint = 'GET|/auth/users'

const getUserRoles = function () {
  return axios.get(`${BASE_URL}/auth/roles`)
}
getUserRoles.endpoint = 'GET|/auth/roles'

const createUser = function (user) {
  return axios.post(`${BASE_URL}/auth/users`, user)
}
createUser.endpoint = 'POST|/auth/users'

const resetUser = function (username, params) {
  return axios.put(`${BASE_URL}/auth/users/${username}/reset`, params)
}
resetUser.endpoint = 'PUT|/auth/users/:username:/reset'

const editUser = function (data) {
  const { username, ...editUserData } = data
  return axios.patch(
    `${BASE_URL}/auth/users/${username}`,
    editUserData
  )
}
editUser.endpoint = 'PATCH|/auth/users/:username:'

const setUserCondition = function (data) {
  const { username, orgId, enabled } = data
  if ((!username && !orgId) || enabled === null) return
  return axios.put(
    `${BASE_URL}/auth/users/condition`,
    data
  )
}
setUserCondition.endpoint = 'PUT|/auth/users/condition'

const setUserLastLogin = function (userId) {
  if (!userId) return
  return axios.patch(
    `${BASE_URL}/auth/users/authlog`
  )
}
setUserLastLogin.endpoint = 'PATCH|/users/authlog'

export const userActions = {
  getUsersList,
  getUserRoles,
  createUser,
  resetUser,
  editUser,
  setUserCondition,
  setUserLastLogin
}
