import create from 'zustand'

const modalInitialState = {
  id: null,
  title: null,
  message: null,
  checkbox: {
    name: null,
    message: null
  },
  onSubmit: undefined,
  onCancel: undefined,
  onClose: undefined,
  closeOnFail: false,
  shouldCloseOnOverlayClick: true,
  closeable: true,
  overlayClassName: null,
  className: null,
  buttonOptions: {
    showCancel: true,
    submitText: 'OK',
    cancelText: 'Cancel'
  },
  customMessageRenderer: undefined,
  customCheckboxRenderer: undefined,
  customButtonsRenderer: undefined
}

const useModalStore = create((set, get) => ({
  buyerSettingsModalOptions: {
    CUT_GRADE: 'cutGradeId',
    BASELINE: 'priceBaselineId',
    MFG_COST: 'mfgCostId',
    CERT_COST: 'certCostId',
    TINGE: 'tingeMapId'
  },
  activeOrderFieldModal: null,
  setActiveOrderFieldModal: (activeOrderFieldModal) => {
    set({ activeOrderFieldModal })
  },
  activeOrderFieldModalField: {
    id: null,
    label: ''
  },
  setActiveOrderFieldModalField: (activeOrderFieldModalField) => {
    set({ activeOrderFieldModalField })
  },
  resetActiveOrderFieldModal: () => {
    set({
      activeOrderFieldModal: null,
      activeOrderFieldModalField: {
        id: null,
        label: ''
      }
    })
  },
  termsAndConditionsModalIsOpen: false,
  setTermsAndConditionsModalIsOpen: (termsAndConditionsModalIsOpen) => {
    set({ termsAndConditionsModalIsOpen })
  },
  modalDefaults: { ...modalInitialState },
  modalQueue: [],
  setModal: (modal) => {
    set({
      modalQueue: get().modalQueue.concat({
        ...modalInitialState,
        ...modal
      })
    })
  },
  removeModal: () => {
    set({ modalQueue: get().modalQueue.slice(1) })
  },
  resetModalQueue: () => {
    set({ modalQueue: [] })
  },
  resetModalStore: () => {
    get().resetActiveOrderFieldModal()
    get().setTermsAndConditionsModalIsOpen(false)
    get().resetModalQueue()
  }
}))

export {
  useModalStore
}
