import React, { useState, useEffect } from 'react'
import { useGlobalsStore } from '@stores'
import { useToast } from '@hooks'
import { Table } from '@organisms'
import { cutGradeActions } from '@actions'
import { useHistory, NavLink } from 'react-router-dom'
import { fileUtils } from '@utils'

function CutGradeList() {
  const { showSuccessToast } = useToast()
  const [cutGradeList, setCutGradeList] = useState([])
  const { orgsList: { all: orgsList }, getOrgsList } = useGlobalsStore()
  const [shapeList, setShapeList] = useState([])
  const [columns, setColumns] = useState([])
  const history = useHistory()

  useEffect(() => {
    getCutGradeList('ACTIVE')
  }, [])

  useEffect(() => {
    getOrgsList()
  }, [])

  useEffect(() => {
    cutGradeActions.getShapeList()
    .then(response => setShapeList(response.data.data))
    .catch(err => console.error(err))
  }, [])

  useEffect(() => {
    setColumns([
      {
        Header: 'ID',
        accessor: 'id',
        dataType: 'string',
        Cell: cellInfo => {
          return (
            <NavLink
              className="link"
              to={`/cutgrades/${cellInfo.row.original.id}`}
              id={cellInfo.row.original.id}
            >
              {cellInfo.value.slice(0, 4)}
            </NavLink>
          )
        }
      },
      {
        Header: 'Name',
        accessor: 'name',
        dataType: 'string'
      },
      {
        Header: 'Shape',
        accessor: (row) => shapeList?.find(shape => shape.id === row.shapeId)?.name || ''
      },
      {
        Header: 'Owner',
        accessor: (row) => row.buyerId == null ? '* Preloaded *' : (orgsList?.find(org => org.orgId === row.buyerId)?.commonName || ''),
        dataType: 'string',
        id: 'owner'
      },
      {
        Header: 'Institute',
        accessor: 'institute',
        dataType: 'string'
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
        dataType: 'date',
        filterType: 'date'
      }
    ])
  }, [shapeList, orgsList])

  function getCutGradeList(condition) {
    cutGradeActions.getCutGradeList({ condition })
    .then(response => setCutGradeList(response.data.data))
    .catch(err => console.error(err))
  }

  function handleArchive(row) {
    return cutGradeActions.setCutGradeCondition(row.id, 'ARCHIVED')
    .then(() => {
      showSuccessToast('Cut Grade has been archived.')
    })
  }

  function handleUnarchive(row) {
    return cutGradeActions.setCutGradeCondition(row.id, 'ACTIVE')
    .then(() => {
      showSuccessToast('Cut Grade has been unarchived.')
    })
  }

  function handleRemove(row) {
    return cutGradeActions.setCutGradeCondition(row.id, 'DELETED')
    .then(() => {
      showSuccessToast('Cut Grade has been removed.')
    })
  }

  function exportCutGrades(cutGrades) {
    return cutGradeActions.getAllInstitutes(`[${cutGrades.map(grade => grade.id)}]`)
    .then(response => {
      const aiFile = response.data.data
      fileUtils.saveTextFile(aiFile, 'AllInstitutes.xml', { mimeType: 'text/xml' })
    })
  }

  const rowActions = [
    {
      actionName: 'archive',
      callback: handleArchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.buyerId && row.condition === 'ACTIVE'
    },
    {
      actionName: 'unarchive',
      callback: handleUnarchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.buyerId && row.condition === 'ARCHIVED'
    },
    {
      actionName: 'remove',
      callback: handleRemove,
      shouldConfirm: true,
      shouldDisplay: (row) => row.buyerId && row.condition !== 'DELETED'
    }
  ]

  const tableTabs = [{
    label: 'Active',
    params: 'ACTIVE'
  },
  {
    label: 'Archived',
    params: 'ARCHIVED'
  }]
  const topBarActions = [
    {
      componentName: 'dropdown',
      enableOnSelect: true,
      options: [
        {
          label: 'Export for Advisor',
          callback: exportCutGrades,
          value: 'exportXml'
        }
      ]
    },
    {
      label: 'Upload Sarine File',
      callback: () => history.push('/cutgrades/upload')
    },
    {
      label: 'Create Cut Grade',
      callback: () => history.push('/cutgrades/create')
    }
  ]

  const initialSort = [{
    id: 'updatedAt',
    desc: true
  },
  {
    id: 'owner',
    desc: true
  },
  {
    id: 'name',
    desc: false
  }
  ]

  return (
    <Table
      title='Cut Grades'
      data={cutGradeList}
      columns={columns}
      tableTabs={tableTabs}
      getDataCallback={getCutGradeList}
      topBarActions={topBarActions}
      rowActions={rowActions}
      initialSort={initialSort}
      isMultiSelect={true}
    />
  )
}

export default CutGradeList
