import axios from 'axios'
import { requestUtils } from '@utils'

const BASE_URL = process.env.REACT_APP_API_BASE_URL

const getPlannedStones = function (params, options) {
  return axios.get(`${BASE_URL}/sales/sps`, { params, options })
}
getPlannedStones.endpoint = 'GET|/sales/sps'

const getPlannedStonesBatch = requestUtils.batchRequest(getPlannedStones)

const getQcPlannedStones = function (params, options) {
  return axios.get(`${BASE_URL}/sales/qc/sps`, { params, options })
}
getQcPlannedStones.endpoint = 'GET|/sales/qc/sps'

const getQcPlannedStonesBatch = requestUtils.batchRequest(getQcPlannedStones)

const sendSpsRequests = function (params) {
  return axios.post(`${BASE_URL}/sales/sps`, params)
}
sendSpsRequests.endpoint = 'POST|/sales/sps'

const resendSpsRequests = function (params) {
  return axios.patch(`${BASE_URL}/sales/sps`, params)
}
resendSpsRequests.endpoint = 'PATCH|/sales/sps'

const processSpsResults = function (params) {
  return axios.patch(`${BASE_URL}/sales/sps/results`, params)
}
processSpsResults.endpoint = 'PATCH|/sales/sps/results'

const cancelSpsRequests = function(params) {
  return axios({
    method: 'delete',
    url: `${BASE_URL}/sales/sps`,
    data: params
  })
}
cancelSpsRequests.endpoint = 'DELETE|/sales/sps'

const getMatchesList = function(params) {
  return axios.get(`${BASE_URL}/sales/matches`, { params })
}
getMatchesList.endpoint = 'GET|/sales/matches'

const createMatch = function(params) {
  return axios.post(`${BASE_URL}/sales/matches`, params)
}
createMatch.endpoint = 'POST|/sales/matches'

const improveMatch = function({ matchId, ...params }) {
  return axios.patch(`${BASE_URL}/sales/matches/${matchId}`, params)
}
improveMatch.endpoint = 'PATCH|/sales/matches/:matchId:'

const confirmMatch = function({ matchId, ...params }) {
  return axios.post(`${BASE_URL}/sales/matches/${matchId}/confirm`, params)
}
confirmMatch.endpoint = 'POST|/sales/matches/:matchId:/confirm'

export const planningActions = {
  getPlannedStones,
  getPlannedStonesBatch,
  getQcPlannedStones,
  getQcPlannedStonesBatch,
  sendSpsRequests,
  resendSpsRequests,
  processSpsResults,
  cancelSpsRequests,
  getMatchesList,
  createMatch,
  improveMatch,
  confirmMatch
}
