import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@atoms'

function TableHeader({
  headerGroups,
  rowActions
}) {
  return (
    <thead className="table__header">
      {// Loop over the header rows
        headerGroups.map((headerGroup, i) => (
          // Apply the header row props
          <tr key={i} className="table__row--header" {...headerGroup.getHeaderGroupProps()}>
            {// Loop over the headers in each row
              headerGroup.headers.map((column, j) => (
                // Apply the header cell props
                <th key={j} className="table__cell--header" {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {/* // Render the header */}
                  <div className='table__header-cell-container'>
                    <div className="table__header-cell">
                      {column.render('Header') ? <div className="table__header-title">
                        <span>{column.render('Header')}</span>
                        {
                          column.canSort
                            ? <div className="table__sorting-arrows">
                              <span
                                className={`table__sorting-arrow${!column.isSorted
                                  || column.isSortedDesc ? '--not-sorted' : ''
                                }`}>
                                <Icon
                                  name='arrowDropUp'
                                />
                              </span>
                              <span
                                className={`table__sorting-arrow${!column.isSorted
                                  || !column.isSortedDesc ? '--not-sorted' : ''
                                }`}>
                                <Icon
                                  name='arrowDropDown'
                                />
                              </span>
                            </div> : null
                        }
                      </div> : null}
                    </div>
                    <div>{column.canFilter && column.render('Header') ? column.render('Filter') : null}</div>
                  </div>
                </th>
              ))}
            {
              rowActions.length
                ? (
                  <th className="table__cell--header"/>
                ) : null
            }
          </tr>
        ))}
    </thead>)
}

TableHeader.propTypes = {
  headerGroups: PropTypes.arrayOf(PropTypes.object),
  rowActions: PropTypes.arrayOf(PropTypes.object)
}

TableHeader.defaultProps = {
  headerGroups: [],
  rowActions: []
}

export default TableHeader
