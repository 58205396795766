import React from 'react'
import { Tabs } from '@templates'
import InvoiceList from './invoiceList'
import PaymentList from './paymentList'

function Finance() {
  const tabsItems = [
    {
      title: 'Invoices',
      Component: InvoiceList
    },
    {
      title: 'Payments',
      Component: PaymentList
    }
  ]

  return (
    <>
      <h2>Finance</h2>
      <Tabs
        name='finance'
        items={tabsItems}
      />
    </>
  )
}

export default Finance
