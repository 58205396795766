import React, { useState, useEffect, useMemo } from 'react'
import { DetailsPage } from '@templates'
import { useToast } from '@hooks'
import { logisticsActions, saleActions } from '@actions'
import { useGlobalsStore } from '@stores'
import { textUtils } from '@utils'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

function PaymentDetails({ match }) {
  const paymentId = match?.params?.paymentId
  const { showSuccessToast } = useToast()
  const [payment, setPayment] = useState(null)
  const [invoice, setInvoice] = useState(null)
  const [sale, setSale] = useState(null)
  const canArchive = payment?.condition !== 'ARCHIVED' && payment?.condition !== 'DELETED'
  const canUnarchive = payment?.condition === 'ARCHIVED'
  const history = useHistory()
  const appSettingsParams = { groupKey: 'INVOICE_PAYMENT_DETAILS' }

  const {
    orgsMap: { all: orgsMap },
    orgsList: { all: orgsList },
    getOrgsList,
    appSettings: { [JSON.stringify(appSettingsParams)]: invoicePaymentDetails },
    getAppSettings
  } = useGlobalsStore(store => store)

  const sendingBanks = useMemo(() => {
    if (!orgsList || !payment) return []
    const buyer = orgsList.find(o => o.orgId === payment.buyerId)
    if (!buyer) return []
    const banks = buyer.address.filter(a => a).filter(a => a.billing && (!a.condition || a.condition === 'ACTIVE'))
    .reduce((banks, addr) => {
      if (addr.invoicePayment.primaryBank) banks.push(addr.invoicePayment.primaryBank)
      if (addr.invoicePayment.secondaryBank) banks.push(addr.invoicePayment.secondaryBank)
      return banks
    }, [])
    // If user created a sending bank, we add it to the options list
    if (payment.sendingBank && !banks.includes(payment.sendingBank)) banks.push(payment.sendingBank)
    return banks
  }, [orgsList, payment])

  const fields = useMemo(() => ([
    {
      label: 'Invoice #',
      name: 'invoiceNumber',
      value: invoice?.invoiceNumber,
      componentName: 'textInput',
      canEdit: false
    },
    {
      label: 'Sale',
      name: 'sale',
      value: sale?.name,
      componentName: 'textInput',
      canEdit: false
    },
    {
      label: 'Buyer',
      name: 'buyer',
      value: orgsMap?.[payment?.buyerId],
      componentName: 'textInput',
      canEdit: false
    },
    {
      label: 'Seller',
      name: 'seller',
      value: orgsMap?.[payment?.sellerId],
      componentName: 'textInput',
      canEdit: false
    },
    {
      label: 'Sending Bank',
      name: 'sendingBank',
      value: payment?.sendingBank,
      options: sendingBanks?.map(bank => ({ label: bank, value: bank })),
      componentName: 'dropdown',
      canEdit: true,
      creatable: true
    },
    {
      label: 'Receiving Bank',
      name: 'receivingBank',
      value: payment?.receivingBank,
      componentName: 'dropdown',
      options: invoicePaymentDetails?.map(({ key }) => ({ label: key, value: key })),
      canEdit: true
    },
    {
      label: 'FX CAD',
      name: 'cadExchange',
      value: payment?.cadExchange,
      componentName: 'textInput',
      type: 'number',
      decimalScale: 6,
      canEdit: true
    },
    {
      label: 'FX BWP',
      name: 'bwpExchange',
      value: payment?.bwpExchange,
      componentName: 'textInput',
      type: 'number',
      decimalScale: 6,
      canEdit: true
    },
    {
      label: 'Payment Date',
      name: 'paymentDate',
      value: textUtils.formatDate(payment?.paymentDate, 'YYYY-MM-DD hh:mm:ss'),
      componentName: 'textInput',
      type: 'datetime-local',
      canEdit: true
    },
    {
      label: 'Stones Count',
      name: 'stoneCount',
      value: payment?.stoneCount,
      componentName: 'textInput',
      type: 'number',
      canEdit: true
    },
    {
      label: 'Weight',
      name: 'caratWeight',
      value: payment?.caratWeight,
      componentName: 'textInput',
      type: 'number',
      canEdit: true
    },
    {
      label: 'Stones Total',
      name: 'stonesTotal',
      value: payment?.stonesTotal,
      componentName: 'textInput',
      type: 'currency',
      canEdit: true
    },
    {
      label: 'Expenses',
      name: 'expenses',
      value: payment?.expenses,
      componentName: 'textInput',
      type: 'currency',
      canEdit: true
    },
    {
      label: 'Galaxy Fees',
      name: 'galaxyFees',
      value: payment?.galaxyFees,
      componentName: 'textInput',
      type: 'currency',
      canEdit: true
    },
    {
      label: 'Tax',
      name: 'tax',
      value: payment?.tax,
      componentName: 'textInput',
      type: 'currency',
      canEdit: true
    },
    {
      label: 'Condition',
      name: 'condition',
      value: textUtils.capitalize(payment?.condition),
      componentName: 'textInput',
      canEdit: false
    },
    {
      label: 'Notes',
      name: 'notes',
      span: true,
      value: payment?.notes,
      componentName: 'textArea',
      canEdit: true
    },
    {
      label: 'Created At',
      name: 'createdAt',
      type: 'datetime-local',
      value: textUtils.formatDate(invoice?.createdAt, true),
      componentName: 'textInput',
      canEdit: false
    },
    {
      label: 'Last Updated',
      name: 'updatedAt',
      type: 'datetime-local',
      value: textUtils.formatDate(invoice?.updatedAt, true),
      componentName: 'textInput',
      canEdit: false
    }

  ]), [payment, orgsMap, sale, invoice, invoicePaymentDetails])

  useEffect(() => {
    if (payment?.invoiceId) {
      getInvoice(payment.invoiceId)
    }
  }, [payment])

  useEffect(() => {
    getOrgsList()
    getAppSettings(appSettingsParams)
  }, [])

  useEffect(() => {
    if (payment?.saleId) {
      saleActions.getSales({ id: payment.saleId })
      .then(response => setSale(response.data.data[0]))
    }
  }, [payment])

  useEffect(() => {
    if (paymentId) {
      getPayment(paymentId)
    }
  }, [paymentId])

  function getInvoice(id) {
    logisticsActions.getInvoices({ id, columns: '[SavedReport,Files]' })
    .then(response => setInvoice(response.data.data[0]))
  }

  function getPayment(id) {
    logisticsActions.getPayments({ id })
    .then(response => setPayment(response.data.data[0]))
  }

  function handleOnSubmit(values) {
    if (values.paymentDate === '') values.paymentDate = null
    if (values.paymentDate) values.paymentDate = moment(values.paymentDate).toISOString()

    return logisticsActions.editPayment(paymentId, { ...values })
    .then(() => {
      showSuccessToast('Payment saved.')
      getPayment(paymentId)
    })
  }

  function handleOnArchive() {
    logisticsActions.setPaymentCondition({ paymentIds: [paymentId], condition: 'ARCHIVED' })
    .then(() => {
      showSuccessToast('Payment has been archived.')
      getPayment(paymentId)
      history.goBack()
    })
  }

  function handleOnUnarchive() {
    logisticsActions.setPaymentCondition({ paymentIds: [paymentId], condition: 'ACTIVE' })
    .then(() => {
      showSuccessToast('Payment has been archived.')
      getPayment(paymentId)
      history.goBack()
    })
  }

  return (
    <DetailsPage
      canEdit={true}
      fields={fields}
      onSubmit={handleOnSubmit}
      onArchive={canArchive ? handleOnArchive : null}
      onUnarchive={canUnarchive ? handleOnUnarchive : null}
      title ={{
        label: 'Payment ID',
        value: payment?.id || ''
      }}
    />

  )
}

export default PaymentDetails
