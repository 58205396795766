import React, { useEffect, useState } from 'react'
import { Table } from '@organisms'
import { useHistory, NavLink } from 'react-router-dom'
import Excel from 'exceljs/dist/es5/exceljs.browser'
import { assortmentActions, organizationActions, reportActions } from '@actions'
import { useToast } from '@hooks'
import { useGlobalsStore, useAuthStore, useRoughStoneStore } from '@stores'
import { COMMON, SELLER } from '@constants'
import ProgressiveProfitModal from './progressiveProfitModal'
import { fileUtils, textUtils } from '@utils'

function AssortmentList() {
  const { hasAdmin, hasPermission, permissionsAdminCache } = useAuthStore(state => state)
  const [assortmentsParams, setAssortmentsParams] = useState({ condition: 'ACTIVE' })
  const [openModal, setOpenModal] = useState({ name: false, selectedRows: [] })
  const {
    orgsList: { all: orgsList },
    provenanceTypesList: { all: provenanceTypes },
    getOrgsList,
    getProvenanceTypesList,
    reserveMethods,
    reserveMethodsMap,
    getReserveMethods
  } = useGlobalsStore(store => store)
  const { showSuccessToast, showErrorToast } = useToast()

  const {
    assortmentsList: { [assortmentsParams ? JSON.stringify(assortmentsParams) : 'all']: assortmentsList = [] },
    getAssortmentsList,
    resetAssortmentsList
  } = useRoughStoneStore(store => store)

  useEffect(() => {
    getOrgsList()
    getProvenanceTypesList()
    getReserveMethods()
    refreshAssortmentsList()
    return resetAssortmentsList
  }, [])

  const history = useHistory()
  function navigateTo(path) {
    history.push(path)
  }

  function refreshAssortmentsList(condition = 'ACTIVE') {
    setAssortmentsParams({ condition })
    getAssortmentsList({ condition })
  }

  function handleArchive(row) {
    return assortmentActions.setAssortmentCondition([row.id], 'ARCHIVED')
    .then(() => {
      showSuccessToast('Assortment has been archived.')
    })
  }

  function handleUnarchive(row) {
    return assortmentActions.setAssortmentCondition([row.id], 'ACTIVE')
    .then(() => {
      showSuccessToast('Assortment has been unarchived.')
    })
  }

  function handleRemove(row) {
    return assortmentActions.setAssortmentCondition([row.id], 'DELETED')
    .then(() => {
      showSuccessToast('Assortment has been removed.')
    })
  }

  async function generateExcel(data) {
    try {
      const workbook = new Excel.Workbook()
      const priceCol = { width: 12, style: { numFmt: '$#,##0.00', alignment: { horizontal: 'left' } } }

      workbook.creator = 'Clara'
      workbook.created = new Date()
      const sheet = workbook.addWorksheet('Assortments')
      const columns = [
        { header: 'ID', key: 'id', width: 12, transform: val => Number(val) },
        { header: 'Name', key: 'name', width: 12 },
        { header: 'Assortment Category', key: 'assortmentCategory', width: 12 },
        { header: 'Provenance Type', key: 'provType', width: 12, transform: (_, item) => provenanceTypes.find(pt => pt.id === item.provenanceTypeId)?.description },
        { header: 'Reserve Method', key: 'reserveMethod', width: 12 },
        { header: 'Original Reserve (/ct)', key: 'reservePpcOriginal', width: 12, ...priceCol, transform: (val) => Number(val) },
        { header: 'Override Reserve (/ct)', key: 'reservePpcOverride', width: 12, ...priceCol, transform: (val) => Number(val) },
        ...(hasAdmin(assortmentActions.getAssortmentList) ? [
          { header: 'Planning Priority', key: 'planningPriority', width: 12, transform: (val) => SELLER.ASSORTMENT_PRIORITIES.find(pri => pri.value === val)?.label },
          { header: 'Matching Priority', key: 'matchingPriority', width: 12, transform: (val) => SELLER.ASSORTMENT_PRIORITIES.find(pri => pri.value === val)?.label },
          { header: 'Send Bids', key: 'generateBids' },
          { header: 'Progressive Profits', key: 'useProgressiveProfit', width: 12 }
        ] : []),
        { header: 'Condition', key: 'condition', width: 12 },
        { header: 'Created At', key: 'createdAt', width: 12, transform: val => textUtils.formatDate(val) },
        { header: 'Last Updated', key: 'updatedAt', width: 12, transform: val => textUtils.formatDate(val) }
      ]
      const rows = fileUtils.getExcelRows(data, columns)
      sheet.columns = columns
      sheet.addRows(rows)
      return workbook.xlsx.writeBuffer().then(buffer => fileUtils.saveBufferExcel(buffer, `Assortment List - ${textUtils.formatDate(new Date(), COMMON.DATE_FMT.REPORT)}.xlsx`))
    } catch (err) {
      console.error(err)
      showErrorToast(err?.message || 'There was an error writing the excel.')
    }
  }

  const rowActions = [
    {
      actionName: 'archive',
      itemType: 'assortment',
      callback: handleArchive,
      shouldConfirm: ({ defaultMessage }) => (
        <>
          {defaultMessage}<br/><br/>
          All stones in this assortment will also be archived.
        </>
      ),
      shouldDisplay: (row) => row.condition === 'ACTIVE'
    },
    {
      actionName: 'unarchive',
      itemType: 'assortment',
      callback: handleUnarchive,
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition === 'ARCHIVED'
    },
    {
      actionName: 'remove',
      itemType: 'assortment',
      callback: handleRemove,
      shouldConfirm: true,
      shouldDisplay: (row) => row.condition !== 'DELETED'
    }
  ]

  const [columns, setColumns] = useState([])
  useEffect(() => {
    setColumns([
      {
        Header: 'Name',
        accessor: 'name',
        dataType: 'string',
        Cell: cellInfo => {
          return <NavLink
            className='link'
            to={`/assortments/${cellInfo.row.original.id}`}
            id={cellInfo.row.original.id}>
            {cellInfo.row.values.name}
          </NavLink>
        }
      },
      ...(hasAdmin(organizationActions.getOrganizationList)
        ? [{
          Header: 'Seller',
          id: 'sellerName',
          accessor: row => orgsList?.find(o => o.orgId === row.sellerId)?.commonName,
          dataType: 'string'
        }] : []),
      {
        Header: 'Assortment Category',
        accessor: 'assortmentCategory',
        dataType: 'string',
        filterType: 'checkbox'
      },
      {
        Header: 'Provenance Type',
        id: 'provenanceType',
        accessor: row => provenanceTypes?.find(pt => pt.id === row.provenanceTypeId)?.description,
        dataType: 'string',
        filterType: 'checkbox',
        enums: provenanceTypes?.map(pt => pt.description)
      },
      {
        Header: 'Total Roughs',
        accessor: 'available_roughs',
        dataType: 'number',
        filterType: 'numberRange'
      },
      {
        Header: 'Submitted Roughs',
        accessor: 'submitted_roughs',
        dataType: 'number',
        filterType: 'numberRange'
      },
      {
        Header: 'Reserve Method',
        id: 'reserveMethod',
        accessor: row => reserveMethodsMap[row.reserveMethod],
        dataType: 'string',
        filterType: 'checkbox',
        enums: reserveMethods?.map(rm => rm.description)
      },
      {
        Header: 'Reserve Price (/ct)',
        accessor: 'reservePpc',
        dataType: 'currency',
        filterType: 'numberRange'
      },
      ...(hasAdmin(assortmentActions.getAssortmentList)
        ? [
          {
            Header: 'Planning Priority',
            id: 'planningPriority',
            accessor: row => SELLER.ASSORTMENT_PRIORITIES.find(pri => pri.value === row.planningPriority)?.label,
            dataType: 'string',
            filterType: 'checkbox',
            enums: SELLER.ASSORTMENT_PRIORITIES.map(ap => ap.label).concat([undefined])
          },
          {
            Header: 'Matching Priority',
            id: 'matchingPriority',
            accessor: row => SELLER.ASSORTMENT_PRIORITIES.find(pri => pri.value === row.matchingPriority)?.label,
            dataType: 'string',
            filterType: 'checkbox',
            enums: SELLER.ASSORTMENT_PRIORITIES.map(ap => ap.label).concat([undefined])
          },
          {
            Header: 'Send Bids',
            accessor: 'generateBids',
            dataType: 'boolean',
            filterType: 'checkbox'
          },
          {
            Header: 'Progressive Profits',
            accessor: 'useProgressiveProfit',
            dataType: 'boolean',
            filterType: 'checkbox'
          }
        ] : []
      ),
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
        dataType: 'date',
        filterType: 'date'
      }
    ])
  }, [orgsList, provenanceTypes, permissionsAdminCache, reserveMethodsMap])

  const tableTabs = [{
    label: 'Active',
    params: 'ACTIVE'
  },
  {
    label: 'Archived',
    params: 'ARCHIVED'
  }]
  const topBarActions = [
    {
      componentName: 'dropdown',
      enableOnSelect: true,
      options: [
        {
          callback: async (selectedRows) => {
            await reportActions.getPerformanceReport('xlsx', { assortmentIds: `[${selectedRows?.map(row => (row.id))}]` }).then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))
          },
          label: 'Generate Performance Report',
          isDisabled: !hasPermission(reportActions.getPerformanceReport),
          value: 'performanceReport'
        },
        ...(hasAdmin(assortmentActions.editAssortment) ? [{
          label: 'Set Progressive Profit',
          value: 'progressiveProfitModal',
          callback: (selectedRows) => setOpenModal({ name: 'progressiveProfit', selectedRows }),
          isDisabled: rows => rows.some(row => row.original.condition !== 'ACTIVE')
        }] : [])
      ]
    },
    {
      label: 'Export',
      callback: () => generateExcel(assortmentsList),
      value: 'export'
    },
    {
      label: 'Create Assortment',
      callback: () => navigateTo('/assortments/create')
    }
  ]

  return (
    <>
      <Table
        title='Assortments'
        data={assortmentsList}
        columns={columns}
        getDataCallback={refreshAssortmentsList}
        rowActions={rowActions}
        tableTabs={tableTabs}
        topBarActions={topBarActions}
        resetDataCallback={resetAssortmentsList}
        isMultiSelect={hasPermission(reportActions.getPerformanceReport)}
      />
      <ProgressiveProfitModal open={openModal?.name === 'progressiveProfit'} onClose={() => setOpenModal({ name: false, selectedRows: [] })} dataSet={openModal?.selectedRows} />
    </>
  )
}

export default AssortmentList
