import * as Yup from 'yup'
import { objectUtils } from '@utils'
import { required, oneOf, invalid, expected } from './messages'
import { SaveMultipleRoughs } from '@pages'

const filterMeasurements = (meas) => meas?.filter(({ colour, fluorescence, tinge }) => !!colour && !!fluorescence && !!tinge)
const getRoughParams = (constants, context) => {
  const { form, cache: eyeRequiredCache } = context
  const { measurements, ...restForm } = form
  return {
    ...restForm,
    ...constants,
    measurements: filterMeasurements(measurements),
    eyeRequiredCache
  }
}

// Advanced tests that cannot be represented using Yup static types
const TESTS = {
  pipeId: (constants) => (_, { options: { context: { form } } }) => {
    return !(constants?.provenanceType?.requireBatch && form?.pipeId == null)
  },
  batchId: (constants) => (_, { options: { context: { form } } }) => {
    return !(constants?.provenanceType?.requireBatch && form?.batchId == null)
  },
  minMeasurements: (constants) => (val) => filterMeasurements(val)?.length >= constants?.minMeasurements,
  oneOfMeasurements: (constants) => (val, { path, createError }) => {
    const indices = filterMeasurements(val)?.map(meas => [
      constants?.roughColours.findIndex(c => c.value === meas.colour),
      constants?.roughFluorescences.findIndex(f => f.value == meas.fluorescence),
      constants?.roughTinges.findIndex(t => t.value === meas.tinge)
    ])
    if (indices?.some(i => i[0] < 0)) return createError({ path, message: 'Measurements contains an invalid colour.' })
    if (indices?.some(i => i[1] < 0)) return createError({ path, message: 'Measurements contains an invalid fluorescence.' })
    if (indices?.some(i => i[2] < 0)) return createError({ path, message: 'Measurements contains an invalid tinge.' })
    return true
  },
  eyeColour: (constants) => (_, { options: { context, context: { form: { measurements } } } }) => {
    // Dont throw error if measurements are not present
    if (!measurements || !filterMeasurements(measurements).length) return true
    const { eyeColourRequired, finalColour } = calculateRoughMeasurements(getRoughParams(constants, context))
    return !(eyeColourRequired && finalColour == null)
  },
  provTypeEyeColour: (constants) => (_, { options: { context: { form: { measurements, eyeMeasurement, weight } } } }) => {
    // Dont throw error if measurements are not present
    if (!measurements || !filterMeasurements(measurements).length) return true
    return !(
      (constants?.provenanceType?.id != 1 || weight >= constants?.eyeColourAbovePrimarySourceWeight)
      && (!eyeMeasurement || eyeMeasurement.colour == null)
    )
  },
  eyeFluorescence: (constants) => (_, { options: { context, context: { form: { measurements } } } }) => {
    // Dont throw error if measurements are not present
    if (!measurements || !filterMeasurements(measurements).length) return true
    const { eyeFluorescenceRequired, finalFluorescence } = calculateRoughMeasurements(getRoughParams(constants, context))
    return !(eyeFluorescenceRequired && finalFluorescence == null)
  },
  eyeTinge: (constants) => (_, { options: { context, context: { form: { measurements } } } }) => {
    // Dont throw error if measurements are not present
    if (!measurements || !filterMeasurements(measurements).length) return true
    const { eyeTingeRequired, finalTinge } = calculateRoughMeasurements(getRoughParams(constants, context))
    return !(eyeTingeRequired && finalTinge == null)
  },
  tensionForWeight: (constants) => (value, { options: { context: { form: { weight = 0 } } }, createError, path }) =>
    // Convert a possible empty (undefined) weight to 0
    !((weight ?? 0) > constants?.tensionRequiredAboveWeight && value == null) || createError({
      path,
      message: required('Tension')
    }),
  eyeMeasurement: (constants) => (val, { options: { context, context: { form, form: { measurements, eyeMeasurement, weight } } }, createError, path }) => {
    if (!measurements || !filterMeasurements(measurements).length) return true
    if (val?.colour != null && !constants?.eyeMeasurementColours?.includes(val.colour)) return createError({ path, message: invalid('Eye Color') })
    if (val?.tinge != null && !constants?.eyeMeasurementTinges?.includes(val.tinge)) return createError({ path, message: invalid('Eye Tinge') })
    const roughMeas = calculateRoughMeasurements(getRoughParams(constants, {
      ...context,
      form: {
        ...form,
        eyeColour: val?.colour,
        eyeFluorescence: val?.fluorescence,
        eyeTinge: val?.tinge
      }
    }))
    // If there was an error thrown by calculateRoughMeasurements return true for now
    if (!roughMeas) return true
    const { eyeColourRequired, finalColour, eyeFluorescenceRequired, finalFluorescence, eyeTingeRequired, finalTinge } = roughMeas
    if (eyeColourRequired && finalColour == null) return createError({ path, message: required('Eye Color') })
    if (eyeFluorescenceRequired && finalFluorescence == null) return createError({ path, message: required('Eye Fluorescence') })
    if (eyeTingeRequired && finalTinge == null) return createError({ path, message: required('Eye Tinge') })
    if (
      constants?.provenanceType
      && (constants?.provenanceType?.id != 1 || weight >= constants?.eyeColourAbovePrimarySourceWeight)
      && (!eyeMeasurement || eyeMeasurement.colour == null)
    ) return createError({ path, message: required('Eye Color') })

    return true
  },
  overrideMeasurement: (constants) => (val, { options: { context: { form: { measurements } } }, createError, path }) => {
    if (!measurements || !filterMeasurements(measurements).length) return true
    if (val?.colour != null && !constants?.eyeMeasurementColours?.includes(val.colour)) return createError({ path, message: invalid('Override Color') })
    if (val?.fluorescence != null && !constants?.eyeMeasurementFluorescences?.includes(val.fluorescence)) return createError({ path, message: invalid('Override Fluorescence') })
    if (val?.tinge != null && !constants?.eyeMeasurementTinges?.includes(val.tinge)) return createError({ path, message: invalid('Override Tinge') })
    return true
  },
  countrySecondaryMarket: (constants) => (val) => {
    const isAssortmentSecMarket = constants?.isAssortmentSecMarket
    const countryName = constants?.defaultSecondaryMarketSettings?.country
    // Ignore this test if the assortment is not secondary market
    if (!isAssortmentSecMarket || !countryName) return true
    return val === countryName
  },
  mineSecondaryMarket: (constants) => (val) => {
    const isAssortmentSecMarket = constants?.isAssortmentSecMarket
    const mineName = constants?.defaultSecondaryMarketSettings?.mine
    // Ignore this test if the assortment is not secondary market
    if (!isAssortmentSecMarket || !mineName) return true
    return val === mineName
  }
}

// Basic test assertions
const BASE_DICT = {
  sellerStoneName: Yup.string().nullable().required(required('Seller Stone Identifier')),
  pricePoint: Yup.string().nullable().required(required('Price point')),
  weightCategory: Yup.string().nullable().required(required('Weight category')),
  weight: Yup.number().nullable().required(required('Weight')),
  reservePpcOriginal: Yup.number().nullable().required(required('Reserve Price')),
  countryId: Yup.string().uuid().nullable().required(required('Country')),
  mineId: (constants) => Yup.string().uuid().oneOf((constants?.minesList ?? []).concat(['', null]), oneOf('Mine')).nullable().required(required('Mine')),
  pipeId: (constants) => Yup.string().uuid().nullable().test('pipeId', required('Pipe'), TESTS.pipeId(constants)),
  batchId: (constants) => Yup.string().uuid().nullable().test('batchId', required('Batch'), TESTS.batchId(constants)),
  measurements: (constants) => Yup.array().nullable()
    .test('minMeasurements', `At least ${constants?.minMeasurements} measurements are required`, TESTS.minMeasurements(constants))
    .test('oneOfMeasurements', TESTS.oneOfMeasurements(constants)),
  inclusionsTypeId: (constants) => Yup.number().oneOf((constants?.inclusionTypes ?? []).concat(['', null]), oneOf('Inclusion type')).nullable().required(required('Inclusion type')),
  inclusionReductionsId: (constants) => Yup.number().nullable().oneOf((constants?.inclusionReductions ?? []).concat(['', null]), oneOf('Inclusion reductions')).required(required('Inclusion reductions')),
  scanType: (constants) => Yup.string().oneOf((constants?.roughScanTypes ?? []).concat(['', null]), oneOf('Sarine scan type')).nullable().required(required('Sarine scan type')),
  eyeMeasurement: (constants) => Yup.object({
    colour: Yup.string().oneOf((constants?.eyeMeasurementColours ?? []).concat(['', null]), oneOf('Color')).nullable()
    .test('eyeColour', required('Color'), TESTS.eyeColour(constants))
    .test('provTypEyeColour', required('Color'), TESTS.provTypeEyeColour(constants)),
    fluorescence: Yup.string().oneOf((constants?.eyeMeasurementFluorescences ?? []).concat(['', null]), oneOf('Fluorescence')).nullable()
    .test('eyeFluorescence', required('Fluorescence'), TESTS.eyeFluorescence(constants)),
    tinge: Yup.string().oneOf((constants?.eyeMeasurementTinges ?? []).concat(['', null]), oneOf('Tinge')).nullable()
    .test('eyeTinge', required('Tinge'), TESTS.eyeTinge(constants))
  }),
  otherAttributes: (constants) => Yup.object({
    yellowFluorescence: Yup.bool().nullable(true),
    tension: Yup.string().oneOf((constants?.roughTensions ?? []).concat(['', null]), oneOf('Tension')).nullable()
        .test('tensionForWeight', required('Tension'), TESTS.tensionForWeight(constants))
  }),
  galaxyFile: Yup.mixed().nullable().required(required('Sarine galaxy file')),
  location: (constants) => Yup.string().oneOf((constants?.locations ?? []).concat(['', null]), oneOf('Location')).nullable(),
  qcStatus: (constants) => Yup.string().oneOf((constants?.roughQCStatuses ?? []).concat(['', null]), oneOf('QC Status')).nullable(),
  type: (constants) => Yup.string().oneOf((constants?.roughTypes ?? []).concat(['', null]), oneOf('Type')).nullable()
}
// This dictionary builds upon the BASE_DICT
// It is meant to handle create and edit forms
// For create mode this dictionary will effectively be equivalent to BASE_DICT
// For edit mode this dictionary will only try and run BASE_DICT if a field has been updated in the form
// TODO: think about how to handle otherAttibutes better
const VAL_DICT = {
  sellerStoneName: () => Yup.string().nullable().when(['id', '$updates'], {
    is: (id, updates = {}) => id && !('sellerStoneName' in updates),
    then: (schema) => schema.nullable(true),
    otherwise: () => BASE_DICT.sellerStoneName
  }),
  pricePoint: () => Yup.string().nullable().when(['id', '$updates'], {
    is: (id, updates = {}) => id && !('pricePoint' in updates),
    then: (schema) => schema.nullable(true),
    otherwise: () => BASE_DICT.pricePoint
  }),
  weightCategory: () => Yup.string().nullable().when(['id', '$updates'], {
    is: (id, updates = {}) => id && !('weightCategory' in updates),
    then: (schema) => schema.nullable(true),
    otherwise: () => BASE_DICT.weightCategory
  }),
  weight: () => Yup.number().nullable().when(['id', '$updates'], {
    is: (id, updates = {}) => id && !('weight' in updates),
    then: (schema) => schema.nullable(true),
    otherwise: () => BASE_DICT.weight
  }),
  reservePpcOriginal: () => Yup.number().nullable().when(['id', '$updates'], {
    is: (id, updates = {}) => id && !('reservePpcOriginal' in updates),
    then: (schema) => schema.nullable(true),
    otherwise: () => BASE_DICT.reservePpcOriginal
  }),
  countryId: () => Yup.string().nullable().when(['id', '$updates'], {
    is: (id, updates = {}) => id && !('countryId' in updates),
    then: (schema) => schema.nullable(true),
    otherwise: () => BASE_DICT.countryId
  }),
  // TODO: Determine the oneOf validation based on the currently selected properties
  mineId: (constants) => Yup.string().when(['id', '$updates'], {
    is: (id, updates = {}) => id && !('mineId' in updates),
    then: (schema) => schema.nullable(true),
    otherwise: () => BASE_DICT.mineId(constants)
  }),
  // TODO: Determine the oneOf validation based on the currently selected properties
  pipeId: (constants) => Yup.string().nullable().when(['id', '$updates'], {
    is: (id, updates = {}) => id && !('pipeId' in updates),
    then: (schema) => schema.nullable(true),
    otherwise: () => BASE_DICT.pipeId(constants)
  }),
  batchId: (constants) => Yup.string().nullable().when(['id', '$updates'], {
    is: (id, updates = {}) => id && !('batchId' in updates),
    then: (schema) => schema.nullable(true),
    otherwise: () => BASE_DICT.batchId(constants)
  }),
  // TODO: validate the validity of colour, tinge, fluor values
  measurements: (constants) => Yup.array().nullable().when(['id', '$updates'], {
    is: (id, updates = {}) => id && !('measurements' in updates),
    then: (schema) => schema,
    otherwise: () => BASE_DICT.measurements(constants)
  }),
  inclusionsTypeId: (constants) => Yup.number().nullable().when(['id', '$updates'], {
    is: (id, updates = {}) => id && !('inclusionsTypeId' in updates),
    then: (schema) => schema.nullable(true),
    otherwise: () => BASE_DICT.inclusionsTypeId(constants)
  }),
  inclusionReductionsId: (constants) => Yup.number().nullable().when(['id', '$updates'], {
    is: (id, updates = {}) => id && !('inclusionReductionsId' in updates),
    then: (schema) => schema.nullable(true),
    otherwise: () => BASE_DICT.inclusionReductionsId(constants)
  }),
  scanType: (constants) => Yup.string().nullable().when(['id', '$updates'], {
    is: (id, updates = {}) => id && !('scanType' in updates),
    then: (schema) => schema.nullable(true),
    otherwise: () => BASE_DICT.scanType(constants)
  }),
  eyeMeasurement: (constants) => Yup.object().nullable().when(['id', '$updates'], {
    is: (id, updates = {}) => id && !('eyeMeasurement' in updates),
    then: (schema) => schema.nullable(true),
    otherwise: () => BASE_DICT.eyeMeasurement(constants).nullable(true)
  }),
  otherAttributes: (constants) => Yup.mixed().nullable().when(['id', '$updates'], {
    is: (id, updates = {}) => id && !('otherAttributes' in updates),
    then: (schema) => schema.nullable(true),
    otherwise: () => BASE_DICT.otherAttributes(constants).nullable(true)
  }),
  galaxyFile: () => Yup.mixed().nullable().when(['id', '$updates'], {
    is: (id, updates = {}) => id && !('galaxyFile' in updates),
    then: (schema) => schema.nullable(true),
    otherwise: () => BASE_DICT.galaxyFile
  }),
  location: () => Yup.mixed().nullable().when(['id', '$updates'], {
    is: (id, updates = {}) => id && !('location' in updates),
    then: (schema) => schema.nullable(true),
    otherwise: () => BASE_DICT.location
  }),
  qcStatus: (constants) => Yup.mixed().nullable().when(['id', '$updates'], {
    is: (id, updates = {}) => id && !('qcStatus' in updates),
    then: (schema) => schema.nullable(true),
    otherwise: () => BASE_DICT.qcStatus(constants)
  }),
  type: (constants) => Yup.mixed().nullable().when(['id', '$updates'], {
    is: (id, updates = {}) => id && !('type' in updates),
    then: (schema) => schema.nullable(true),
    otherwise: () => BASE_DICT.type(constants)
  })
}
// Build a validation object from a set of keys that map to VAL_DICT object validation assertions
// This function is meant to reduce duplicated code for similar tests
const schemaPick = (keys, constants = {}) => keys.reduce((picks, key) => ({
  ...picks,
  [key]: objectUtils.callProp(picks[key], [constants])
}), objectUtils.pick(VAL_DICT, keys))

const editRoughWarningSchemaFields = [
  'weight',
  'countryId',
  'mineId',
  'pipeId',
  'batchId',
  'measurements',
  'eyeMeasurement',
  'otherAttributes',
  'reservePpcOriginal',
  'reservePpcOverride',
  'inclusionsTypeId',
  'scanType',
  'pricePoint',
  'type'
]
const editRoughErrorSchemaFields = ['sellerStoneName']
const editRoughWarningSchema = (constants, fields = editRoughWarningSchemaFields) => Yup.object({
  ...schemaPick(fields, constants),
  galaxyFileId: VAL_DICT.galaxyFile()
})
const editRoughErrorSchema = Yup.object(schemaPick(editRoughErrorSchemaFields))

const createStepProvWarningSchemaFields = ['countryId', 'mineId', 'pipeId', 'batchId']
const createStepProvErrorSchemaFields = ['sellerStoneName']
const createStepProvWarningSchema = (constants) => Yup.object(schemaPick(createStepProvWarningSchemaFields, constants))
const createStepProvErrorSchema = () => Yup.object(schemaPick(createStepProvErrorSchemaFields))

const createStepWgtWarningSchemaFields = ['pricePoint', 'weightCategory', 'weight', 'reservePpcOriginal']
const createStepWgtWarningSchema = (fields = createStepWgtWarningSchemaFields) => Yup.object(schemaPick(fields))

const createStepMeasWarningSchemaFields = [
  'eyeMeasurement',
  'inclusionsTypeId',
  'inclusionReductionsId',
  'scanType',
  'otherAttributes',
  'measurements',
  'type'
]
const createStepMeasWarningSchema = (constants) => Yup.object(
  schemaPick(createStepMeasWarningSchemaFields, constants))

const createStepAdvWarningSchemaFields = ['galaxyFile']
const createStepAdvWarningSchema = Yup.object(schemaPick(createStepAdvWarningSchemaFields))

const createMultipleRoughsWarningSchemaFields = [
  'sellerStoneName',
  'weight',
  'reservePpcOriginal',
  'reservePpcOverride',
  'weightCategory',
  'pricePoint'
]
// The createMultipleRoughs form will create name & id properties from user submitted fields (ex. user submits country and form attempts to add countryName and countryId properties)
// If a user submits valid data, both the name and id fields will be assigned.
// The approach below is to first check for the presence of an id field and pass validation if it exists
// otherwise we will determine what type of error to throw for this field
// If the name field is present without the id, we assume the data inputted is invalid and we will throw an error accordingly
// If the neither the name field or id field are present, we assume the data inputted is empty and we throw an error accordingly
const createMultipleRoughsWarningSchema = (constants) => Yup.object({
  ...schemaPick(createMultipleRoughsWarningSchemaFields, constants),
  eyeMeasurement: Yup.mixed().nullable().test('eyeMeasurement', required('Eye Measurement'), TESTS.eyeMeasurement(constants)),
  countryName: Yup.string().nullable().when(['countryId'], {
    is: (countryId) => countryId,
    then: (schema) => schema,
    otherwise: (schema) => schema.test('countryName', required('Country'), (value, { createError, path }) => value
    && createError({ path, message: invalid('Country') }))
  })
  .test('countrySecondaryMarket', expected('Country', constants?.defaultSecondaryMarketSettings?.country), TESTS.countrySecondaryMarket(constants)),
  mineName: Yup.string().nullable().when(['mineId'], {
    is: (mineId) => mineId,
    then: (schema) => schema,
    otherwise: (schema) => schema
      .test('mineName', required('Mine'), (value, { createError, path }) => value
        && createError({ path, message: invalid('Mine') }))
  })
  .test('mineSecondaryMarket', expected('Mine', constants?.defaultSecondaryMarketSettings?.mine), TESTS.mineSecondaryMarket(constants)),
  pipeName: Yup.string().nullable().when(['pipeId'], {
    is: (pipeId) => pipeId,
    then: (schema) => schema,
    otherwise: (schema) => (
      constants?.provenanceType?.requireBatch
        ? schema.test('pipeName', required('Pipe'), (value, { createError, path }) => value
          && createError({ path, message: invalid('Pipe') }))
        : schema.test('pipeName', invalid('Pipe'), (value) => !value)
    )
  }),
  batchName: Yup.string().nullable().when(['batchId'], {
    is: (batchId) => batchId,
    then: (schema) => schema,
    otherwise: (schema) => (
      constants?.provenanceType?.requireBatch
        ? schema.test('batchName', required('Batch'), (value, { createError, path }) => value
          && createError({ path, message: invalid('Batch') }))
        : schema.test('batchName', invalid('Batch'), (value) => !value)
    )
  }),
  scanTypeName: Yup.string().nullable().when(['scanType'], {
    is: (scanType) => scanType,
    then: (schema) => schema,
    otherwise: (schema) => schema.test('scanTypeName', required('Scan Type'), (value, { createError, path }) => value
    && createError({ path, message: invalid('Scan Type') }))
  }),
  inclusionsTypeName: Yup.string().nullable().when(['inclusionsTypeId'], {
    is: (inclusionsTypeId) => inclusionsTypeId,
    then: (schema) => schema,
    otherwise: (schema) => schema.test('inclusionsTypeName', required('Inclusions Type'), (value, { createError, path }) => value
    && createError({ path, message: invalid('Inclusions Type') }))
  }),
  inclusionReductionsName: Yup.string().nullable().when(['inclusionReductionsId'], {
    is: (inclusionReductionsId) => inclusionReductionsId,
    then: (schema) => schema,
    otherwise: (schema) => schema.test('inclusionReductionsName', invalid('Reduction Table'), (value) => !value)
  }),
  tensionName: Yup.string().nullable().test('tensionForWeight', required('Tension'), (_, options) => {
    const { options: { context: { form: { otherAttributes } } } } = options
    return TESTS.tensionForWeight(constants)(otherAttributes?.tension, options)
  }),
  typeName: Yup.string().nullable().when(['type'], {
    is: (type) => type,
    then: (schema) => schema,
    otherwise: (schema) => schema.test('typeName', required('Type'), (value, { createError, path }) => value
    && createError({ path, message: invalid('Type') }))
  }),
  // otherAttributes: Yup.object({
  //   yellowFluorescence: Yup.string().nullable().when(['otherAttributes'], {
  //     is: (otherAttributes) => otherAttributes?.yellowFluorescence,
  //     then: (schema) => schema,
  //     otherwise: (schema) => schema.test('yellowFlu', invalid('Yellow UV'), () => false)
  //   })
  // }),
  // Note this is a different validation than what we are doing for error measurements
  measurements: Yup.array().nullable().test('minMeasurements', `At least ${constants?.minMeasurements} measurements are required`, TESTS.minMeasurements(constants))
})

const createMultipleRoughsErrorSchema = (constants) => Yup.object({
  measurements: Yup.array().nullable().test('oneOfMeasurements', TESTS.oneOfMeasurements(constants))
})

const editMultipleRoughsWarningSchemaFields = [
  'sellerStoneName',
  'weight',
  'reservePpcOriginal',
  'weightCategory',
  'pricePoint'
]
const editMultipleRoughsWarningSchema = (constants, fields = editMultipleRoughsWarningSchemaFields) => Yup.object({
  ...schemaPick(fields, constants),
  eyeMeasurement: Yup.mixed().nullable().test('eyeMeasurement', required('Eye Measurement'), TESTS.eyeMeasurement(constants)),
  overrideMeasurement: Yup.mixed().nullable().test('overrideMeasurement', required('Override Measurement'), TESTS.overrideMeasurement(constants)),
  countryName: Yup.string().nullable().when(['countryId'], {
    is: (countryId) => countryId,
    then: (schema) => schema,
    otherwise: (schema) => schema.test('countryName', required('Country'), (value, { createError, path }) => value
    && createError({ path, message: invalid('Country') }))
  }),
  mineName: Yup.string().nullable().when(['mineId'], {
    is: (mineId) => mineId,
    then: (schema) => schema,
    otherwise: (schema) => schema.test('mineName', required('Mine'), (value, { createError, path }) => value
    && createError({ path, message: invalid('Mine') }))
  }),
  pipeName: Yup.string().nullable().when(['pipeId'], {
    is: (pipeId) => pipeId,
    then: (schema) => schema,
    otherwise: (schema) => schema.test('pipeName', required('Pipe'), (value, { createError, path }) => value
    && createError({ path, message: invalid('Pipe') }))
  }),
  batchName: Yup.string().nullable().when(['batchId'], {
    is: (batchId) => batchId,
    then: (schema) => schema,
    otherwise: (schema) => (
      constants?.provenanceType?.requireBatch
        ? schema.test('batchName', required('Batch'), (value, { createError, path }) => value
          && createError({ path, message: invalid('Batch') }))
        : schema.test('batchName', invalid('Batch'), (value) => !value)
    )
  }),
  scanTypeName: Yup.string().nullable().when(['scanType'], {
    is: (scanType) => scanType,
    then: (schema) => schema,
    otherwise: (schema) => schema.test('scanTypeName', required('Scan Type'), (value, { createError, path }) => value
    && createError({ path, message: invalid('Scan Type') }))
  }),
  inclusionsTypeName: Yup.string().nullable().when(['inclusionsTypeId'], {
    is: (inclusionsTypeId) => inclusionsTypeId,
    then: (schema) => schema,
    otherwise: (schema) => schema.test('inclusionsTypeName', required('Inclusions Type'), (value, { createError, path }) => value
    && createError({ path, message: invalid('Inclusions Type') }))
  }),
  inclusionReductionsName: Yup.string().nullable().when(['inclusionReductionsId'], {
    is: (inclusionReductionsId) => inclusionReductionsId,
    then: (schema) => schema,
    otherwise: (schema) => schema.test('inclusionReductionsName', invalid('Reduction Table'), (value) => !value)
  }),
  tensionName: Yup.string().nullable().test('tensionForWeight', required('Tension'), (_, options) => {
    const { options: { context: { form: { otherAttributes } } } } = options
    return TESTS.tensionForWeight(constants)(otherAttributes?.tension, options)
  }),
  locationName: Yup.string().nullable().when(['location'], {
    is: (location) => location,
    then: (schema) => schema,
    otherwise: (schema) => schema.test('locationName', invalid('Location'), (value) => !value)
  }),
  typeName: Yup.string().nullable().when(['type'], {
    is: (type) => type,
    then: (schema) => schema,
    otherwise: (schema) => schema.test('typeName', required('Type'), (value, { createError, path }) => value
    && createError({ path, message: invalid('Type') }))
  }),
  // otherAttributes: Yup.object({
  //   yellowFluorescence: Yup.string().nullable().when(['otherAttributes'], {
  //     is: (otherAttributes) => otherAttributes?.yellowFluorescence,
  //     then: (schema) => schema,
  //     otherwise: (schema) => schema.test('yellowFlu', invalid('Yellow UV'), () => false)
  //   })
  // }),
  // Note this is a different validation than what we are doing for error measurements
  measurements: Yup.array().nullable().test('minMeasurements', `At least ${constants?.minMeasurements} measurements are required`, TESTS.minMeasurements(constants))
})
const editMultipleRoughsErrorSchema = (constants) => Yup.object({
  measurements: Yup.array().nullable().test('oneOfMeasurements', TESTS.oneOfMeasurements(constants))
})

const qcEditMultipleRoughsWarningSchema = (constants) => Yup.object({
  inclusionsTypeName: Yup.string().nullable().when(['inclusionsTypeId'], {
    is: (inclusionsTypeId) => inclusionsTypeId,
    then: (schema) => schema,
    otherwise: (schema) => schema.test('inclusionsTypeName', required('Inclusions Type'), (value, { createError, path }) => value
    && createError({ path, message: invalid('Inclusions Type') }))
  }),
  inclusionReductionsName: Yup.string().nullable().when(['inclusionReductionsId'], {
    is: (inclusionReductionsId) => inclusionReductionsId,
    then: (schema) => schema,
    otherwise: (schema) => schema.test('inclusionReductionsName', invalid('Reduction Table'), (value) => !value)
  }),
  qcStatusName: Yup.string().nullable().when(['qcStatus'], {
    is: (qcStatus) => qcStatus,
    then: (schema) => schema,
    otherwise: (schema) => schema.test('qcStatusName', required('QC Status'), (value, { createError, path }) => value
    && createError({ path, message: invalid('QC Status') }))
  })
})
const qcEditMultipleRoughsErrorSchema = () => Yup.object({
  qcStatusName: Yup.string().nullable().when(['qcStatus'], {
    is: (qcStatus) => !qcStatus,
    then: (schema) => schema,
    otherwise: (schema) => schema.test('qcStatusOnly', 'QC Status cannot be edited with any other column', (value, { options: { context: { form: { existing, updates } } } }) => {
      const changes = Object.entries(updates).filter(([k, v]) => {
        if (SaveMultipleRoughs.parseNamedKey[k]) return false
        if (SaveMultipleRoughs.parseNamedKey.reverse(k)) return v !== existing[k]
        return false
      })
      return !changes.find(([k]) => k === 'qcStatus') || changes.length === 1
    })
  })
})

const calculateRoughMeasurements = function({
  measurements = [],
  otherAttributes,
  eyeMeasurement,
  overrideMeasurement,
  weight = null,
  roughColours: ROUGH_COLOURS,
  roughFluorescences: ROUGH_FLUORESCENCES,
  polishedFluorescences: POLISHED_FLUORESCENCES,
  roughTinges: TINGES,
  eyeRequiredCache
}) {
  try {
    const yellowFluorescence = otherAttributes ? otherAttributes.yellowFluorescence : null
    eyeMeasurement = eyeMeasurement || {}
    overrideMeasurement = overrideMeasurement || {}
    const stringForm = JSON.stringify({ measurements, yellowFluorescence, eyeMeasurement, overrideMeasurement, weight })
    if (eyeRequiredCache.has(stringForm)) {
      return eyeRequiredCache.get(stringForm)
    } else {
      const results = {
        eyeColourRequired: false,
        eyeFluorescenceRequired: false,
        eyeTingeRequired: false,
        measColour: null,
        measFluorescence: null,
        measTinge: null,
        finalColour: null,
        finalFluorescence: null,
        finalTinge: null
      }
      if (yellowFluorescence) {
        results.eyeColourRequired = true
        results.eyeFluorescenceRequired = true
        results.eyeTingeRequired = true
      }
      if (Array.isArray(measurements) && measurements.length) {
        const indices = measurements.map(meas => [
          ROUGH_COLOURS.findIndex(c => c.value === meas.colour),
          ROUGH_FLUORESCENCES.findIndex(f => f.value == meas.fluorescence),
          TINGES.findIndex(t => t.value === meas.tinge)
        ])
        if (indices.some(i => i[0] < 0)) throw new Error('Measurements contains an invalid colour.' + JSON.stringify(measurements))
        if (indices.some(i => i[1] < 0)) throw new Error('Measurements contains an invalid fluorescence.' + JSON.stringify(measurements))
        if (indices.some(i => i[2] < 0)) throw new Error('Measurements contains an invalid tinge.' + JSON.stringify(measurements))

        // get the average colour and flu from measurements
        const avgCol = Math.round(indices.reduce((sum, idx) => sum + idx[0], 0) / indices.length)
        const col = ROUGH_COLOURS[avgCol]
        if (col) results.measColour = col.value
        const avgFlu = Math.round(indices.reduce((sum, idx) => sum + idx[1], 0) / indices.length)
        const flu = ROUGH_FLUORESCENCES[avgFlu]
        if (flu) results.measFluorescence = flu.value
        let [tingeIndex, tingeCount, tng] = [0, 0, null]
        // for non-averageable tinges (TLB, LB, and IIa Mixed) the measured tinge is indeterminate if there is more than one non-None tinge
        if (indices.some(i => !TINGES[i[2]].averageable)) {
          for (const i of indices) {
            if (i[2] > 0) {
              if (tingeIndex !== 0 && tingeIndex !== i[2]) {
                tingeIndex = null
                results.eyeColourRequired = true
                results.eyeTingeRequired = true
              }
              tingeIndex = i[2]
              tingeCount++
            }
          }
        } else { // if all tinges are averageable then the resulting tinge is the average
          tingeIndex = Math.round(indices.reduce((sum, idx) => sum + idx[2], 0.0001) / indices.length) // 0.0001 is so ties are rounded conservatively
          tingeCount = indices.reduce((cnt, idx) => idx[2] > 0 ? cnt + 1 : cnt, 0)
        }
        // regardless of whether the measured tinges are averageable or not, we use the number of non-None tinges to determine the final tinge
        // if less than 1/3 are non-None then the tinge is None, if 3/5 or more are tinged then we use that tinge, and otherwise we require eye tinge and colour
        tingeCount /= indices.length
        if (tingeCount < 0.33) tng = TINGES[0]
        else if (tingeCount > 0.599) tng = TINGES[tingeIndex]
        else {
          results.eyeColourRequired = true
          results.eyeTingeRequired = true
          // if (TINGES[tingeIndex].requireEyeColour) results.eyeColourRequired = true
          if (TINGES[tingeIndex].requireEyeFluorescence) results.eyeFluorescenceRequired = true
        }
        if (tng) results.measTinge = tng.value

        // use the results of the calculations on the measurements to determine which eye measurements should override those
        if ((col && col.requireEyeColour) || (flu && flu.requireEyeColour) || (tng && tng.requireEyeColour)) results.eyeColourRequired = true
        if ((col && col.requireEyeFluorescence) || (flu && flu.requireEyeFluorescence) || (tng && tng.requireEyeFluorescence)) results.eyeFluorescenceRequired = true
        if ((col && col.requireEyeTinge) || (flu && flu.requireEyeTinge) || (tng && tng.requireEyeTinge)) results.eyeTingeRequired = true

        if (results.eyeColourRequired && eyeMeasurement.colour) results.finalColour = eyeMeasurement.colour
        else if (!results.eyeColourRequired) results.finalColour = results.measColour
        if (results.eyeTingeRequired && eyeMeasurement.tinge) results.finalTinge = eyeMeasurement.tinge
        else if (!results.eyeTingeRequired) results.finalTinge = results.measTinge

        if ( // an annoying special case for borderline fluorescences
          !results.eyeFluorescenceRequired
          && flu.borderline
          && weight && weight > 4
          && results.finalColour && results.finalTinge
          && ROUGH_COLOURS.find(c => c.value === results.finalColour).checkBorderlineFlu
          && TINGES.find(t => t.value === results.finalTinge).checkBorderlineFlu
        ) results.eyeFluorescenceRequired = true
        if (results.eyeFluorescenceRequired && eyeMeasurement.fluorescence) {
          const pFlu = POLISHED_FLUORESCENCES.find(f => f.value === eyeMeasurement.fluorescence)
          if (pFlu) results.finalFluorescence = Math.round((pFlu.min + pFlu.max) / 2)
        } else if (!results.eyeFluorescenceRequired) results.finalFluorescence = results.measFluorescence
      }
      // final chance for admin to override measurements (but eye required is unaffected)
      if (overrideMeasurement.colour) results.finalColour = overrideMeasurement.colour
      if (overrideMeasurement.tinge) results.finalTinge = overrideMeasurement.tinge
      if (overrideMeasurement.fluorescence) {
        const pFlu = POLISHED_FLUORESCENCES.find(f => f.value === overrideMeasurement.fluorescence)
        if (pFlu) results.finalFluorescence = Math.round((pFlu.min + pFlu.max) / 2)
      }

      // validate final measurements
      const indices = [
        results.finalColour != null ? ROUGH_COLOURS.findIndex(c => c.value === results.finalColour) : 0,
        results.finalFluorescence != null ? ROUGH_FLUORESCENCES.findIndex(f => f.value == results.finalFluorescence) : 0,
        results.finalTinge != null ? TINGES.findIndex(t => t.value === results.finalTinge) : 0
      ]
      if (indices[0] < 0) throw new Error('Final measurement contains an invalid colour.' + results.finalColour)
      if (indices[1] < 0) throw new Error('Final measurement contains an invalid fluorescence.' + results.finalFluorescence)
      if (indices[2] < 0) throw new Error('Final measurement contains an invalid tinge.' + results.finalTinge)
      eyeRequiredCache.set(stringForm, results)
      return results
    }
  } catch (err) {
    console.error(err)
  }
}

export const roughSchema = {
  editRoughWarningSchema,
  editRoughWarningSchemaFields,
  editRoughErrorSchema,
  editRoughErrorSchemaFields,
  createStepProvWarningSchema,
  createStepProvWarningSchemaFields,
  createStepProvErrorSchema,
  createStepProvErrorSchemaFields,
  createStepWgtWarningSchema,
  createStepWgtWarningSchemaFields,
  createStepMeasWarningSchema,
  createStepMeasWarningSchemaFields,
  createStepAdvWarningSchema,
  createStepAdvWarningSchemaFields,
  createMultipleRoughsWarningSchema,
  createMultipleRoughsWarningSchemaFields,
  createMultipleRoughsErrorSchema,
  editMultipleRoughsWarningSchema,
  editMultipleRoughsWarningSchemaFields,
  editMultipleRoughsErrorSchema,
  qcEditMultipleRoughsWarningSchema,
  qcEditMultipleRoughsErrorSchema
}
