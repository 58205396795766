import create from 'zustand'

const useNotificationStore = create(set => ({
  isNotificationsOpen: false,
  setIsNotificationsOpen: (isNotificationsOpen) => {
    set({ isNotificationsOpen })
  },
  notificationList: [],
  setNotificationList: (notificationList) => {
    set({ notificationList: [...notificationList] })
  },
  fetchDelay: 300000,
  setFetchDelay: (delay) => {
    set({ fetchDelay: delay })
  }
}))

export {
  useNotificationStore
}
