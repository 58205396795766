import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useShapeGroupStore } from '@stores'
import { DetailsPage, Tabs } from '@templates'
import { usePageTitle } from '@hooks'
import ShapeGroupShapesList from './shapeGroupShapesList'
import ShapeGroupOrdersList from './shapeGroupOrdersList'

function ShapeGroupDetails({ match, title }) {
  const { shapeGroupId } = match?.params

  const { getShapeGroupById } = useShapeGroupStore()

  const [shapeGroup, setShapeGroup] = useState({})
  usePageTitle(title, shapeGroupId, shapeGroup?.name)
  useEffect(() => {
    if (shapeGroupId) {
      getShapeGroupById(shapeGroupId)
      .then(shapeGroup => setShapeGroup(shapeGroup))
      .catch(err => console.error(err))
    }
  }, [shapeGroupId])

  const [fields, setFields] = useState([])
  useEffect(() => {
    if (shapeGroup) {
      setFields([
        {
          label: 'Description',
          value: shapeGroup.description,
          name: 'description',
          componentName: 'textInput',
          canEdit: false
        },
        {
          label: 'Created At',
          value: moment(shapeGroup.createdAt).toLocaleString()
        },
        {
          label: 'Last Updated',
          value: moment(shapeGroup.updatedAt).toLocaleString()
        }
      ])
    }
  }, [shapeGroup])

  const tabItems = useMemo(() => ([
    {
      title: 'Shapes',
      Component: ShapeGroupShapesList,
      props: { shapeGroupId }

    },
    {
      title: 'Orders',
      Component: ShapeGroupOrdersList,
      props: { shapeGroupId }
    }
  ]), [])

  return (
    <div className="shape-group-details__container">
      <div className="shape-group-details__content">
        <DetailsPage
          canEdit={false}
          fields={fields}
          title={{
            label: 'Shape Group ID',
            value: shapeGroup?.id || ''
          }}

        />
      </div>
      <div className="shape-group-details__tabs">
        <Tabs name="shapeGroupTabs" items={tabItems} />
      </div>
    </div>
  )
}

ShapeGroupDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string
}

export default ShapeGroupDetails
