import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { InfoTip } from '@molecules'
import { arrayUtils, objectUtils } from '@utils'
import { COMMON } from '@constants'

function Checkbox({
  id,
  name,
  label,
  value,
  canEdit,
  checked,
  infoTip,
  onChange,
  componentName,
  indeterminate,
  ...props
}) {
  const isChecked = checked == null ? !!value : checked
  const validHTMLElementProps = useMemo(() => {
    const propKeys = Object.keys(props)
    const validKeys = arrayUtils.intersectBy(propKeys, COMMON.HTML_ATTRIBUTES.GLOBAL.concat(COMMON.HTML_ATTRIBUTES.INPUT), (x) => x.toLowerCase())
    return objectUtils.pick(props, validKeys)
  }, [props])
  return (
    <div className="checkbox__container">
      <input
        {...validHTMLElementProps}
        id={id}
        name={name}
        type="checkbox"
        onChange={onChange}
        className={`checkbox${indeterminate ? '--indeterminate' : ''}`}
        checked={isChecked}
      />
      <label
        className={`checkbox-animation${indeterminate ? '--indeterminate' : ''}`}
        htmlFor={id}
      >
      </label>
      {label
        && <label htmlFor={name} >
          <span className="subtext">
            {label}
          </span>
          {infoTip ? <InfoTip name={name}>{infoTip}</InfoTip> : null}
        </label>
      }
    </div>
  )
}

Checkbox.propTypes = {
  id: PropTypes.string,
  value: PropTypes.bool,
  name: PropTypes.string,
  canEdit: PropTypes.bool,
  checked: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  infoTip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object]),
  indeterminate: PropTypes.bool,
  componentName: PropTypes.string
}

Checkbox.defaultProps = {
  name: '',
  label: '',
  indeterminate: false
}

export default Checkbox
