import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_BASE_URL

function getAppNotifications(params) {
  return axios.get(BASE_URL + '/notifications/app', { params })
}
getAppNotifications.endpoint = 'GET|/notifications/app'

function getEmailNotifications(params) {
  return axios.get(BASE_URL + '/notifications/email', { params })
}
getEmailNotifications.endpoint = 'GET|/notifications/email'

function getUserNotifications(userId) {
  return axios.get(BASE_URL + `/notifications/app/${userId}`, { options: { loadingSpinner: false } })
}
getUserNotifications.endpoint = 'GET|/notifications/app/:userId:'

function markAsRead(userId, notificationId) {
  return axios.patch(
    BASE_URL + `/notifications/app/${userId}/${notificationId}/status`,
    { isRead: true },
    { options: { loadingSpinner: false } }
  )
}
markAsRead.endpoint = 'PATCH|/notifications/app/:userId:/:notificationId:/status'

function markMultipleAsRead(userId, notificationIds) {
  return axios.patch(
    BASE_URL + `/notifications/app/${userId}/status`,
    { isRead: true, notificationIds },
    { options: { loadingSpinner: false } }
  )
}
markMultipleAsRead.endpoint = 'PATCH|/notifications/app/:userId:/status'

function createAppNotification(data) {
  return axios.post(BASE_URL + '/notifications/app', data)
}
createAppNotification.endpoint = 'POST|/notifications/app'

function createEmailNotification(data) {
  return axios.post(BASE_URL + '/notifications/email', data)
}
createEmailNotification.endpoint = 'POST|/notifications/email'

function createNotificationTemplate(data) {
  return axios.post(BASE_URL + '/notifications/template', data)
}
createNotificationTemplate.endpoint = 'POST|/notifications/template'

function getNotificationTemplates(params) {
  return axios.get(BASE_URL + '/notifications/template', { params })
}
getNotificationTemplates.endpoint = 'GET|/notifications/template'

function editAppNotification(notificationId, userId, data) {
  return axios.patch(BASE_URL + `/notifications/app/${userId}/${notificationId}`, data)
}
editAppNotification.endpoint = 'PATCH|/notifications/app/:userId:/:notificationId:'

function editEmailNotification(emailId, data) {
  return axios.patch(BASE_URL + `/notifications/email/${emailId}`, data)
}
editEmailNotification.endpoint = 'PATCH|/notifications/email/:emailId:'

function editNotificationTemplate(id, data) {
  return axios.patch(BASE_URL + `/notifications/template/${id}`, data)
}
editNotificationTemplate.endpoint = 'PATCH|/notifications/template/:templateId:'

export const notificationsActions = {
  getAppNotifications,
  editAppNotification,
  editEmailNotification,
  getEmailNotifications,
  getUserNotifications,
  createAppNotification,
  createEmailNotification,
  createNotificationTemplate,
  getNotificationTemplates,
  editNotificationTemplate,
  markAsRead,
  markMultipleAsRead
}
