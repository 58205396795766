import axios from 'axios'
const BASE_URL = process.env.REACT_APP_API_BASE_URL

const getShapeList = function(params) {
  return axios.get(`${BASE_URL}/buyer/shapes`, { params })
}
getShapeList.endpoint = 'GET|/buyer/shapes'

const createShape = function(shape) {
  return axios.post(`${BASE_URL}/buyer/shapes`, shape)
}
createShape.endpoint = 'POST|/buyer/shapes'

const editShape = function({ id, ...shape }) {
  return axios.patch(`${BASE_URL}/buyer/shapes/${id}`, shape)
}
editShape.endpoint = 'PATCH|/buyer/shapes/:shapeId:'

const setShapeCondition = function(shapeId, condition) {
  return axios.put(`${BASE_URL}/buyer/shapes/condition`, { shapeIds: [shapeId], condition })
}
setShapeCondition.endpoint = 'PUT|/buyer/shapes/condition'

const getCutGradeList = function(params) {
  return axios.get(`${BASE_URL}/buyer/cutgrades`, { params })
}
getCutGradeList.endpoint = 'GET|/buyer/cutgrades'

const createCutGrade = function(grade) {
  return axios.post(`${BASE_URL}/buyer/cutgrades`, grade)
}
createCutGrade.endpoint = 'POST|/buyer/cutgrades'

const uploadCutGrades = function(params) {
  return axios.post(`${BASE_URL}/buyer/cutgrades/multiple`, params)
}
uploadCutGrades.endpoint = 'POST|/buyer/cutgrades/multiple'

const getProportions = function(params) {
  return axios.get(`${BASE_URL}/buyer/proportions`, { params })
}
getProportions.endpoint = 'GET|/buyer/proportions'

const validateCutGrade = function(params) {
  return axios.post(`${BASE_URL}/buyer/cutgrades/validation`, params)
}
validateCutGrade.endpoint = 'POST|/buyer/cutgrades/validation'

const editCutGrade = function({ id, ...grade }) {
  return axios.patch(`${BASE_URL}/buyer/cutgrades/${id}`, grade)
}
editCutGrade.endpoint = 'PATCH|/buyer/cutgrades/:cutGradeId:'

const setCutGradeCondition = function(gradeId, condition) {
  return axios.put(`${BASE_URL}/buyer/cutgrades/condition`, { cutGradeIds: [gradeId], condition })
}
setCutGradeCondition.endpoint = 'PUT|/buyer/cutgrades/condition'

const getAllInstitutes = function(cutGradeIds) {
  return axios.get(`${BASE_URL}/sales/advisor/allinstitutes`, { params: { cutGradeIds } })
}
getAllInstitutes.endpoint = 'GET|/sales/advisor/allinstitutes'

export const cutGradeActions = {
  getShapeList,
  createShape,
  editShape,
  setShapeCondition,
  getCutGradeList,
  createCutGrade,
  uploadCutGrades,
  getProportions,
  validateCutGrade,
  editCutGrade,
  setCutGradeCondition,
  getAllInstitutes
}
