import dottie from 'dottie'
import clone from 'just-clone'
import objectUtils from './object'
import textUtils from './text'
import deepEqual from 'fast-deep-equal/es6/react'
import moment from 'moment/moment'

// type: ['error', 'warning', undefined]
// val: base object
// k: key value of base object
function extract(type, val, k) {
  const v = dottie.get(val, type)
  // If there is no key or validation of type was not found return val
  if (!v || !k) return v
  // If the validation is in non object form dottie cannot look for a key
  if (typeof v !== 'object') return undefined
  return dottie.get(v, k)
}

// type: ['error', 'warning', undefined]
// val: base object
// k: key value of base object
// item: value being mapped to key
// deep: [true -> directly mutate val object, false -> mutate a clone of val object]
function insert(type, val, k, item, deep = false) {
  const vType = type ? dottie.get(val, type) : val
  const v = deep ? vType : clone(vType)
  // If there is no key or validation of type was not found return val
  if (!v || !k) return v
  dottie.set(v, k, item)
  return v
}

function parseValidation(val, key) {
  return {
    warning: extract('warning', val, key),
    error: extract('error', val, key)
  }
}
function parseRemove(val, key) {
  // Assign undefined to key value and then remove all nullish properties from the val object
  // which will effectively act as a remove
  const warning = objectUtils.filterNullish(insert('warning', val, key, undefined, false), true)
  const error = objectUtils.filterNullish(insert('error', val, key, undefined, false), true)
  return {
    warning: objectUtils.isEmpty(warning) ? undefined : warning,
    error: objectUtils.isEmpty(error) ? undefined : error
  }
}

function validation(val = {}) {
  const { warning, error } = val
  const defaultErrorToastMessage = 'Please clear the highlighted errors before saving.'
  const defaultWarningToastMessage = 'Please ensure you have reviewed the highlighted values in orange. If they are your intended entries you can confirm them by clicking Save again. If they contain errors please correct them before saving.'
  const getError = (path) => extract('error', val, path)
  const getWarning = (path) => extract('warning', val, path)
  const parse = (path) => parseValidation(val, path)
  const parseStep = (path) => validation(parse(path))
  const remove = (path) => parseRemove(val, path)
  const removeStep = (path) => validation(remove(path))
  const groupWarningByKey = (map, key, path = '') => {
    const valWarnings = getWarning(path)
    if (typeof valWarnings === 'object' && !Array.isArray(valWarnings)) {
      return Object.entries(valWarnings).reduce((accum, curr) => {
        const [errIdx, errs] = curr
        accum[map[errIdx]?.[key]] = errs
        return accum
      }, {})
    }
  }
  const groupErrorByKey = (map, key, path = '') => {
    const valErrors = getError(path)
    if (typeof valErrors === 'object' && !Array.isArray(valErrors)) {
      return Object.entries(valErrors).reduce((accum, curr) => {
        const [errIdx, errs] = curr
        accum[map[errIdx]?.[key]] = errs
        return accum
      }, {})
    }
  }
  const getValidationClass = (key) => {
    if (getError(key)) return 'form-error'
    if (getWarning(key)) return 'form-warning'
    return ''
  }
  const hasAlert = (path) => !!(getError(path) || getWarning(path))
  return {
    error,
    warning,
    getError,
    getWarning,
    defaultErrorToastMessage,
    defaultWarningToastMessage,
    parse,
    parseStep,
    remove,
    removeStep,
    groupWarningByKey,
    groupErrorByKey,
    getValidationClass,
    hasAlert
  }
}

async function validateSchema(schema, vals, context) {
  if (!schema) return
  async function validate(schema, vals, context) {
    try {
      if (schema) {
        await objectUtils.callProp(schema, [vals]).validate(
          vals,
          {
            abortEarly: false,
            context
          }
        )
      }
      return undefined
    } catch (err) {
      const valErrors = {}
      if (err?.inner) {
        for (const { path: name, errors } of err?.inner) {
          insert(undefined, valErrors, textUtils.dotNotationSquareBracketToDot(name), errors, true)
        }
        return valErrors
      }
    }
  }
  const validationRes = {}
  for (const key of Object.keys(schema)) {
    const res = await validate(schema[key], vals, context, true)
    validationRes[key] = res
  }
  return validation(validationRes)
}

function validateSchemaSync(schema, vals, context) {
  if (!schema) return
  function validate(schema, vals, context) {
    try {
      if (schema) {
        objectUtils.callProp(schema, [vals]).validateSync(
          vals,
          {
            abortEarly: false,
            context
          }
        )
      }
      return undefined
    } catch (err) {
      const valErrors = {}
      if (err?.inner) {
        for (const { path: name, errors } of err?.inner) {
          insert(undefined, valErrors, textUtils.dotNotationSquareBracketToDot(name), errors, true)
        }
        return valErrors
      }
    }
  }
  const validationRes = Object.keys(schema).reduce((accum, curr) => {
    const res = validate(schema[curr], vals, context, false)
    return { ...accum, [curr]: res }
  }, {})
  return validation(validationRes)
}

function diffValues(val1, val2) {
  if (val1 == null || val2 == null) {
    return val1 != val2
  }
  // dates
  if ((val1 instanceof moment) && (val2 instanceof moment)) {
    return !moment(val1).isSame(val2)
  }

  return !deepEqual(val1, val2)
}

export default { validateSchema, validateSchemaSync, validation, diffValues }
