import React, { useEffect, useState, useRef } from 'react'
import { Button } from '@atoms'
import { InfoTip, TextInput } from '@molecules'

function ProgressiveProfitMatrix({ value: propVal, onChange, ...props }) {
  const getNextPriceRatio = (ratio, matrix) => {
    return (matrix ?? value).reduce((next, row) => row.minPriceRatio < next && row.minPriceRatio > ratio ? row.minPriceRatio : next, Number.POSITIVE_INFINITY)
  }
  const getPrevPriceRatio = (ratio, matrix) => {
    return (matrix ?? value).reduce((prev, row) => row.maxPriceRatio > prev && row.maxPriceRatio < ratio ? row.maxPriceRatio : prev, -100)
  }

  const blankElement = () => ({ maxPriceRatio: Number.POSITIVE_INFINITY, profitMargin: 0 })

  const propToState = (propValue) => {
    if (!Array.isArray(propVal) || !propVal.length) return [blankElement()]
    return propValue.map((row, i, pV) => {
      const npr = getNextPriceRatio(row.minPriceRatio, pV)
      return {
        maxPriceRatio: npr == null ? npr : (npr - 1) * 100,
        profitMargin: row.profitMargin * 100
      }
    })
  }

  const getFakeElement = (v) => ({ currentTarget: { name: props.name, value: v?.length ? v : null } })
  const stateToProp = (stateVal) => {
    if (!Array.isArray(stateVal)) return null
    return stateVal.map((row, i, aV) => ({
      minPriceRatio: getPrevPriceRatio(row.maxPriceRatio, aV) / 100 + 1,
      profitMargin: row.profitMargin / 100
    }))
  }

  const [value, setValue] = useState([])
  const [returnedValue, setReturnedValue] = useState()
  useEffect(() => {
    if (propVal !== returnedValue) setValue(propToState(propVal))
  }, [propVal])

  const handleChange = (e) => {
    const target = e.currentTarget || e.target
    const [fieldName, idx] = target.name.split('|')
    const newValue = value.slice()
    newValue[idx] = { ...newValue[idx], [fieldName]: target.value ?? Number.POSITIVE_INFINITY }
    if (!newValue.some(row => row.maxPriceRatio === Number.POSITIVE_INFINITY)) newValue.push(blankElement())
    const newPropValue = stateToProp(newValue)
    setValue(newValue)
    setReturnedValue(newPropValue)
    onChange(getFakeElement(newPropValue))
  }

  const handleRemoveRow = (i) => {
    const newValue = value.slice()
    newValue.splice(i, 1)
    const newPropValue = stateToProp(newValue)
    setValue(newValue)
    setReturnedValue(newPropValue)
    onChange(getFakeElement(newPropValue))
  }

  const [hasFocus, setHasFocus] = useState(false)
  const blurTimeoutId = useRef(false)
  const doBlur = () => {
    blurTimeoutId.current = setTimeout(() => {
      if (hasFocus) setHasFocus(false)
    }, 0)
  }
  const doFocus = () => {
    clearTimeout(blurTimeoutId.current)
    if (!hasFocus) setHasFocus(true)
  }
  useEffect(() => {
    if (!hasFocus && value && value.length > 1) {
      const newValue = value.slice()
      newValue.sort((a, b) => a.maxPriceRatio - b.maxPriceRatio)
      const newPropValue = stateToProp(newValue)
      setValue(newValue)
      setReturnedValue(newPropValue)
      onChange(getFakeElement(newPropValue))
    }
  }, [hasFocus])

  return (
    <div
      className='progressive-profit-matrix'
      onBlur={doBlur}
      onFocus={doFocus}
    >
      {!value || !value.length ? (
        <div>
          <div className='progressive-profit-row'>
            <span className='input__label'>Minimum Ratio &nbsp; <InfoTip name='assortmentProgressiveProfitRatio'/></span>
            <span className='input__label'>Maximum Ratio</span>
            <span className='input__label'>Profit Margin</span>
          </div>
          <div key='0' className='progressive-profit-row'>
            <div>
              <TextInput
                name='minPriceRatio|0'
                value={-100}
                type='number'
                decimalScale={0}
                formatSuffix='%'
                disabled={true}
                onChange={() => {}}
              />
            </div><div>
              <TextInput
                name={'maxPriceRatio|0'}
                type='number'
                onChange={handleChange}
                value={Number.POSITIVE_INFINITY}
                placeholder='∞'
                decimalScale={0}
                formatSuffix='%'
                disabled={props.disabled}
              />
            </div><div>
              <TextInput
                name={'profitMargin|0'}
                type='number'
                onChange={handleChange}
                value={0}
                decimalScale={2}
                formatSuffix='%'
                disabled={props.disabled}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className='progressive-profit-row'>
            <span className='input__label'>Minimum Ratio &nbsp; <InfoTip name='assortmentProgressiveProfitRatio'/></span>
            <span className='input__label'>Maximum Ratio</span>
            <span className='input__label'>Profit Margin</span>
          </div>
          {value.map((row, i) => (
            <div key={i} className='progressive-profit-row'>
              <div>
                <TextInput
                  name={`minPriceRatio|${i}`}
                  value={getPrevPriceRatio(row.maxPriceRatio ?? Number.POSITIVE_INFINITY)}
                  type='number'
                  decimalScale={0}
                  formatSuffix='%'
                  disabled={true}
                  onChange={() => {}}
                />
              </div><div>
                <TextInput
                  name={`maxPriceRatio|${i}`}
                  type='number'
                  onChange={handleChange}
                  value={row.maxPriceRatio === Number.POSITIVE_INFINITY ? '' : row.maxPriceRatio ?? Number.POSITIVE_INFINITY}
                  placeholder='∞'
                  decimalScale={0}
                  formatSuffix='%'
                  disabled={props.disabled}
                />
              </div><div>
                <TextInput
                  name={`profitMargin|${i}`}
                  type='number'
                  onChange={handleChange}
                  value={row.profitMargin ?? 0}
                  decimalScale={2}
                  formatSuffix='%'
                  disabled={props.disabled}
                />
              </div>
              {!props.disabled ? (
                <Button size='sm' typeVariant='icon' iconName='closeCircle' noOutline={true} disabled={props.disabled} onClick={() => handleRemoveRow(i)} />
              ) : null}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ProgressiveProfitMatrix
