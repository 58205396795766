import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Formik, Field } from 'formik'
import { Checkbox, Button } from '@atoms'
import { TextInput, Dropdown, TextArea } from '@molecules'
import { RouterPrompt } from '@templates'
import dottie from 'dottie'
import FormChildren from './formChildren'
import { validationUtils } from '@utils'

function SimpleForm({
  title,
  onSubmit,
  children,
  hasOutline,
  onReset,
  handleSubmit,
  initialValues,
  extraOnChange,
  enableReinitialize,
  customValidation,
  validationSchema,
  ...props
}) {
  const [hasChanged, setHasChanged] = useState(false)
  return (
    <div className={`form__container${!hasOutline ? '--no-outline' : ''}`}>
      {title ? <h2 className="form__title">{title}</h2> : null }
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        validate={customValidation}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={enableReinitialize}
        onSubmit={values => {
          setHasChanged(false)
          onSubmit(values)
        }}
      >
        {formProps => {
          const childrenElements = React.Children.toArray(children)
          return (
            <>
              {
                onReset
                  ? (
                    <div className='form__reset-button-container'>
                      <Button
                        typeVariant='action'
                        type='reset'
                        size='sm'
                        onClick={() => onReset(formProps.handleReset)} >
                          Clear All
                      </Button>
                    </div>
                  ) : null
              }
              <form
                className="form__fields"
                onReset={onReset}
                onSubmit={handleSubmit}
                {...props}
              >
                <FormChildren {...{ ...formProps, hasChanged, setHasChanged, extraOnChange }}>{childrenElements}</FormChildren>
              </form>
            </>
          )
        }}
      </Formik>
      <RouterPrompt
        when={hasChanged}
      />
    </div>
  )
}

SimpleForm.propTypes = {
  hasOutline: PropTypes.bool,
  onReset: PropTypes.func,
  handleSubmit: PropTypes.func,
  extraOnChange: PropTypes.func,
  initialValues: PropTypes.object,
  customValidation: PropTypes.func,
  enableReinitialize: PropTypes.bool,
  validationSchema: PropTypes.object,
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

SimpleForm.defaultProps = {
  title: '',
  hasOutline: true,
  initialValues: {},
  validationSchema: null,
  enableReinitialize: false
}

function FormTextInput(props) {
  return buildFormField(TextInput, props)
}

FormTextInput.propTypes = {
  required: PropTypes.bool
}

FormTextInput.defaultProps = {
  required: true
}

function FormTextArea(props) {
  return buildFormField(TextArea, props)
}

FormTextArea.propTypes = {
  required: PropTypes.bool
}

FormTextArea.defaultProps = {
  required: true
}

function FormDropdown(props) {
  return buildFormField(Dropdown, props)
}

FormDropdown.propTypes = {
  required: PropTypes.bool
}

FormDropdown.defaultProps = {
  required: true
}

function FormCheckbox(props) {
  return buildFormField(Checkbox, props)
}

FormCheckbox.propTypes = {
  required: PropTypes.bool
}

FormCheckbox.defaultProps = {
  required: true
}

function buildFormField(Component, props) {
  function handleValidate(value) {
    const errors = []
    const localError = validateField(value, props)
    if (localError) errors.push(localError)
    if (props.customValidation) {
      const customErrors = props.customValidation(value)
      if (customErrors) errors.push(...(Array.isArray(customErrors) ? customErrors : [customErrors]))
    }
    return errors.length ? Array.from(new Set(errors)) : undefined
  }
  return (
    <div className="form__field">
      <Field
        name={props.name}
        onChange={props.handleChange}
        validate={handleValidate}
        {...props}
      >
        {({ field, form }) => {
          return (
            <Component
              {...props}
              min={props.min}
              name={field.name}
              type={props.type}
              size={props.size}
              label={props.label}
              value={field.value}
              onBlur={field.onBlur}
              loading={props.loading}
              isMulti={props.isMulti}
              options={props.options}
              extraLabel={props.extraLabel}
              isClearable={props.isClearable}
              canAddAll={props.canAddAll}
              topActions={props.topActions}
              onChange={(...params) => {
                if (typeof props.extraOnChange === 'function') props.extraOnChange(form.values, ...params)
                if (!props.hasChanged) props.setHasChanged(true)
                return field.onChange(...params)
              }}
              disabled={props.disabled}
              onSelect={field.onSelect}
              rows={props.rows}
              required={props.required}
              infoTip={props.infoTip}
              creatable={props.creatable}
              labelStyle={props.labelStyle}
              validationText={validationUtils.validation({ error: dottie.get(form.errors, props.name) })}
            />
          )
        }}
      </Field>
    </div>
  )
}

function FormSingleCheckbox(props) {
  return (
    <div className="form__field">
      <Field
        name={props.name}
        onChange={props.handleChange}
        validate={value => validateField(value, props)}
        {...props}
      >
        {({ field, form }) => {
          return (
            <Checkbox
              name={field.name}
              type={props.type}
              label={props.label}
              checked={field.value}
              size={props.size}
              onBlur={field.onBlur}
              onChange={(e) => {
                if (typeof props.extraOnChange === 'function') props.extraOnChange(form.values, e)
                return props.setFieldValue(field.name, e.target.checked)
              }}
              required={props.required}
              labelStyle={props.labelStyle}
              validationText={form.errors[props.name] || ''}
              disabled={props.disabled}
            />
          )
        }}
      </Field>
    </div>
  )
}

FormSingleCheckbox.propTypes = {
  required: PropTypes.bool
}

FormSingleCheckbox.defaultProps = {
  required: true
}

function FormButton(props) {
  return (
    <Button
      size={props.size}
      type={props.type}
      loading={props.loading}
      onClick={e => {
        e.preventDefault()
        return props.type === 'submit'
          ? props.handleSubmit
            ? props.handleSubmit(e)
            : null
          : props.onClick
            ? props.onClick(e)
            : null
      }}
      typeVariant={props.typeVariant}
      disabled={props.disabled}
    >
      {props.children}
    </Button>
  )
}

function validateField(value = '', props) {
  if (props.required && (value == null || value === '')) return `${props.label} is required.`
  if (props.type === 'email') return validateEmailField(value, props)
  if (props.type === 'number') return validateNumber(value, props)
  if (props.type === 'phone') return validatePhoneNumber(value, props)
}

function validateEmailField(value, props, errorMessage = '') {
  if (!props.required && !value) return null
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) { return errorMessage || 'Please enter a valid email.' }
}

function validateNumber(value, props, errorMessage = '') {
  if (!props.required && !value) return null
  if (value == null || value === '' || isNaN(value)) { return errorMessage || 'Please enter a valid number.' }
  if (Number(value) < Number(props.min)) { return errorMessage || `Please enter a number greater than ${props.min}.` }
}

function validatePhoneNumber(value, props, errorMessage = '') {
  if (!/^\+[0-9]{8,15}$/i.test(value)) { return errorMessage || 'Please enter a valid phone number (+1234567890).' }
}

const FormComponents = {
  SimpleForm,
  Button: FormButton,
  TextArea: FormTextArea,
  Dropdown: FormDropdown,
  Checkbox: FormCheckbox,
  SingleCheckbox: FormSingleCheckbox,
  TextInput: FormTextInput
}

export default FormComponents
