import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@atoms'
import { Dropdown, DropdownButton } from '@molecules'
import { useToast } from '@hooks'
import { qcActions } from '@actions'
import { arrayUtils, objectUtils, textUtils } from '@utils'

function AssignStones({
  handleCancel,
  handleSubmit,
  usersList,
  selectedRows
}) {
  const [selectedUsers, setSelectedUsers] = useState(getInitSelectedUsers(selectedRows))
  const { showErrorToast } = useToast()
  const userOptions = useMemo(() => ([
    {
      label: 'Add All',
      value: '',
      onClick: ({ setVisible }) => {
        handleOnAddAll()
        setVisible(false)
      }
    },
    {
      label: 'Users',
      options: usersList
      .slice()
      .sort(({ name: a }, { name: b }) => textUtils.compareIgnoreCase(a, b))
      .map(user => ({
        label: `Add ${user.name}`,
        value: user.userId,
        onClick: ({ setVisible }) => {
          handleOnAddAll(user.userId)
          setVisible(false)
        }
      }))
    }
  ]), [usersList])

  function getInitSelectedUsers(roughs) {
    return arrayUtils.toMap(roughs, ({ id }) => id, (r) => r.QcPermissions)
  }

  function handleOnChange(e) {
    const { name, value } = e.currentTarget || e.target
    const users = selectedUsers[name]
    const newUsers = arrayUtils.differenceBy(value, users, (x) => objectUtils.isObject(x) ? x.userId : x).map(userId => ({ newUser: true, userId, roughId: name, condition: 'ACTIVE' }))
    const newValue = users.reduce((arr, user) => arr.concat({ ...user, condition: value.includes(user.userId) ? 'ACTIVE' : 'ARCHIVED' }), newUsers)
    setSelectedUsers(currSelectedUsers => ({ ...currSelectedUsers, [name]: newValue }))
  }
  function handleOnAddAll(userId) {
    setSelectedUsers(currSelectedUsers => Object.entries(currSelectedUsers).reduce((obj, [roughId, qcPermissions]) => {
      const filterList = userId ? [usersList.find(user => user.userId === userId)] : usersList
      const newUsers = arrayUtils.differenceBy(filterList, qcPermissions, (x) => x.userId).map(({ userId }) => ({ newUser: true, userId, roughId: roughId, condition: 'ACTIVE' }))
      obj[roughId] = qcPermissions.map(qcP => ({ ...qcP, condition: qcP.userId === userId ? 'ACTIVE' : qcP.condition })).concat(newUsers)
      return obj
    }, {}))
  }
  async function handleOnSubmit() {
    if (!selectedRows.length) {
      showErrorToast('No stones were assigned')
      return
    }
    const originalPermissions = getInitSelectedUsers(selectedRows)
    const usersObj = usersList.reduce((obj, { userId }) => ({ ...obj, [userId]: { userId, add: [], remove: [] } }), {})
    const qcPermissionsObj = selectedRows.reduce((obj, rough) => {
      for (const user of selectedUsers[rough.id]) {
        const { newUser, userId, roughId, condition } = user
        const previousCondition = originalPermissions[rough.id]?.find(perm => perm.userId === userId)?.condition
        if (previousCondition === condition) continue
        if (condition === 'ACTIVE') obj[userId].add.push(roughId)
        else if (!newUser) obj[userId].remove.push(roughId)
      }
      return obj
    }, usersObj)
    const qcPermissions = Object.values(qcPermissionsObj)
      .filter(({ add = [], remove = [] }) => add.length || remove.length)
      .map((qcP) => objectUtils.filterNullish(qcP, true))
    await qcActions.saveQcPermissions(qcPermissions)
    await handleSubmit(selectedRows.map(({ roughId }) => roughId))
  }

  return (
    <>
      <div className="assign-stones__grid">
        <h4 className='assign-stones__grid-header'>Clara ID</h4>
        <h4 className='assign-stones__grid-header'>Name</h4>
        <div className='assign-stones__grid-header'>
          <h4>Users</h4>
          <DropdownButton
            label="Add to All Stones"
            typeVariant="action"
            size='sm'
            options={userOptions}
          />
        </div>
        {selectedRows.map((row, idx) => (
          <React.Fragment key={`${row.id}_${idx}`}>
            <div className='assign-stones__grid-row'>{row.id}</div>
            <div className='assign-stones__grid-row'>{row.sellerStoneName}</div>
            <div className='assign-stones__grid-row'>
              <Dropdown
                name={row.id}
                value={selectedUsers[row.id].filter(({ condition }) => condition === 'ACTIVE').map(({ userId }) => userId)}
                onChange={handleOnChange}
                options={usersList.map(user => ({
                  value: user.userId,
                  label: user.name
                }))}
                isMulti={true}
              />
            </div>
          </React.Fragment>
        )
        )}
      </div>
      <div key='row-buttons' className='modal__buttons'>
        <Button typeVariant='action' size='sm' onClick={handleCancel}>Cancel</Button>
        <Button typeVariant='primary' size='sm' onClick={handleOnSubmit}>Assign</Button>
      </div>
    </>
  )
}

AssignStones.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  handleClose: PropTypes.func,
  usersList: PropTypes.arrayOf(PropTypes.object),
  selectedRows: PropTypes.arrayOf(PropTypes.object)
}

AssignStones.defaultProps = {
  usersList: [],
  selectedRows: []
}

export default AssignStones
