import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import dottie from 'dottie'
import { v4 as uuidv4 } from 'uuid'
import { Button, Checkbox, Icon } from '@atoms'
import { TextInput, TextArea, Dropdown, Collapsible, DisplayMessage, FormRow } from '@molecules'
import { Fieldset } from '@organisms'
import { useGlobalsStore } from '@stores'
import { ORG } from '@constants'
import { objectUtils, textUtils } from '@utils'

function AddressForm({ onChange, onAdd, onRemove, formData, isOpen, canRemove }) {
  const initialDisplayMessage = { type: '', message: '' }
  const [displayMessage, setDisplayMessage] = useState(initialDisplayMessage)
  const appSettingsParams = { groupKey: 'INVOICE_PAYMENT_DETAILS' }

  const {
    complianceStatuses,
    licenceStatuses,
    licenceTypesList,
    appSettings: { [JSON.stringify(appSettingsParams)]: invoicePaymentDetails },
    getComplianceStatuses,
    getLicenceStatuses,
    getLicenceTypesList,
    getAppSettings
  } = useGlobalsStore()
  useEffect(() => {
    getComplianceStatuses()
    getLicenceStatuses()
    getLicenceTypesList({ condition: 'ACTIVE' })
    getAppSettings(appSettingsParams)
  }, [])

  const formAddress = useMemo(() => getAddress(formData), [formData])
  function getAddress(address = {}) {
    return {
      _id: address._id,
      name: address.name || '',
      line1: address.line1 || '',
      line2: address.line2 || '',
      line3: address.line3 || '',
      city: address.city || '',
      province: address.province || '',
      country: address.country || '',
      postalCode: address.postalCode || '',
      billing: address.billing || false,
      shipping: address.shipping || false,
      compliance: address.compliance || '',
      licences: address.licences || '',
      corporateNumber: address.corporateNumber || '',
      taxNumber: address.taxNumber || '',
      preferredShipping: address.preferredShipping || '',
      invoiceNotes: address.invoiceNotes || '',
      invoicePayment: {
        clara: address.invoicePayment?.clara || '',
        lucara: address.invoicePayment?.lucara || '',
        primaryBank: address.invoicePayment?.primaryBank || '',
        secondaryBank: address.invoicePayment?.secondaryBank || ''
      },
      emailCcs: address.emailCcs || undefined,
      emailBccs: address.emailBccs || undefined,
      userDocuments: address.userDocuments || undefined
    }
  }

  function handleOnChange(e) {
    const target = e.currentTarget ?? e.target
    const targetValue = target.type === 'checkbox' ? target.checked : target.value
    if (target) {
      const addressObj = { ...formData }
      dottie.set(addressObj, target.name, targetValue)
      if (addressObj.emailCcs !== undefined && textUtils.nonEmptyString(addressObj.emailCcs)) addressObj.emailCcs = addressObj.emailCcs.split('\n')
      else if (!Array.isArray(addressObj.emailCcs) || !addressObj.emailCcs.length) addressObj.emailCcs = undefined
      if (addressObj.emailBccs !== undefined && textUtils.nonEmptyString(addressObj.emailBccs)) addressObj.emailBccs = addressObj.emailBccs.split('\n')
      else if (!Array.isArray(addressObj.emailBccs) || !addressObj.emailBccs.length) addressObj.emailBccs = undefined
      const isValid = validateAddressForm(addressObj)
      onChange(addressObj, isValid)
      if (isValid) {
        setSuccessMessage()
      }
    }
  }

  function validateAddressForm(addressObj) {
    // create array with required fields info
    const requiredFields = [
      { name: 'name', label: 'Entity Name' },
      { name: 'line1', label: 'Address line 1' },
      { name: 'city', label: 'City' },
      { name: 'country', label: 'Country' },
      { name: ['invoicePayment', 'primaryBank'], label: 'Primary Bank', requiredFn: (val, addr) => addr.billing && (!val || !String(val).trim()) },
      { name: ['userDocuments', 'name'], label: (_, addr) => addr.userDocuments?.reduce((labels, { name }, idx) => labels.concat(!name ? `Compliance Documents ${idx + 1}: Name` : []), []), requiredFn: (_, addr) => addr.userDocuments?.find(({ name }) => !name) },
      { name: ['userDocuments', 'documentType'], label: (_, addr) => addr.userDocuments?.reduce((labels, { documentType }, idx) => labels.concat(!documentType ? `Compliance Documents ${idx + 1}: Document Type` : []), []), requiredFn: (_, addr) => addr.userDocuments?.find(({ documentType }) => !documentType) }
    ]

    // validate form inputs
    const formInputsValidation = validateFormInputs(requiredFields, addressObj)
    const error = formInputsValidation.error
    setErrorMessage(formInputsValidation.blankFields)
    return !error
  }

  function setSuccessMessage() {
    setDisplayMessage({
      type: 'success',
      message: 'Address valid.'
    })
  }

  function setErrorMessage(blankFields) {
    setDisplayMessage({
      type: 'error',
      message: getErrorMessage(blankFields)
    })
  }

  function getErrorMessage(blankFields) {
    return (
      <>
        <div>Please, fill all required fields:</div>
        {blankFields.map((blankField, i) => {
          return (
            <span key={i}>
              <strong>- {blankField}</strong>
            </span>
          )
        })}
      </>
    )
  }

  function validateFormInputs(requiredFields = [], addressObj) {
    // create initial error object
    const validation = {
      error: false,
      blankFields: []
    }
    // loop through required fields
    requiredFields.forEach(fieldObj => {
      // check if the required fields is filled
      const field = dottie.get(addressObj, fieldObj.name)
      const invalidField = fieldObj.requiredFn ? fieldObj.requiredFn?.(field, addressObj) : (!field || !String(field).trim())
      if (invalidField) {
        validation.error = true
        // if not, add to array of blank fields
        validation.blankFields = validation.blankFields.concat(objectUtils.callProp(fieldObj.label, [field, addressObj]))
      }
    })

    return validation
  }

  function getAddressTitle() {
    return (
      <div className='address-form__title'>
        <p>
          {formAddress?.name
            ? `${formAddress?.name}, ${formAddress?.line1}, ${formAddress?.city}, ${formAddress?.country}`
            : 'New Address'
          }
        </p>
        {
          displayMessage.message
            ? (
              <Icon
                name={displayMessage.type === 'success' ? 'check' : 'error'}
                style={{ color: displayMessage.type === 'success' ? 'green' : 'red' }}
              />
            )
            : null
        }
      </div>
    )
  }

  return (
    <div className="address-form">
      <Collapsible
        title={getAddressTitle()}
        type={displayMessage.type}
        isOpened={isOpen}
      >
        {displayMessage.message && (
          <DisplayMessage
            type={displayMessage.type}
            message={displayMessage.message}
          />
        )}
        <div className="address-form__grid">
          <TextInput
            name='name'
            label='Entity Name'
            value={formAddress.name}
            onChange={handleOnChange}
          />
          <TextInput
            name="line1"
            label="Address line 1"
            value={formAddress.line1}
            onChange={handleOnChange}
          />
          <TextInput
            name="line2"
            label="Address line 2"
            required={false}
            value={formAddress.line2}
            onChange={handleOnChange}
          />
          <TextInput
            name="line3"
            label="Address line 3"
            required={false}
            value={formAddress.line3}
            onChange={handleOnChange}
          />
          <TextInput
            name="city"
            label="City"
            required
            value={formAddress.city}
            onChange={handleOnChange}
          />
          <TextInput
            name="province"
            label="Province / State"
            required={false}
            value={formAddress.province}
            onChange={handleOnChange}
          />
          <TextInput
            name="country"
            label="Country"
            required
            value={formAddress.country}
            onChange={handleOnChange}
          />
          <TextInput
            name="postalCode"
            label="Postal Code"
            required={false}
            value={formAddress.postalCode}
            onChange={handleOnChange}
          />
          <TextInput
            name='corporateNumber'
            label='Corporate Number'
            required={false}
            value={formAddress.corporateNumber}
            onChange={handleOnChange}
          />
          <TextInput
            name='taxNumber'
            label='Tax Number'
            required={false}
            value={formAddress.taxNumber}
            onChange={handleOnChange}
          />
          <Dropdown
            name='preferredShipping'
            label='Preferred Shipping'
            required={false}
            value={formAddress.preferredShipping}
            onChange={handleOnChange}
            options={ORG.PREFERRED_SHIPPING}
          />
          <Fieldset legend={'Address Type'} required>
            <ul className="vertical">
              <li>
                <Checkbox
                  name="shipping"
                  id="shipping"
                  label="Shipping"
                  checked={formAddress.shipping}
                  value={formAddress.shipping}
                  onChange={handleOnChange}
                />
                <br />
                <Checkbox
                  name="billing"
                  id="billing"
                  label="Billing"
                  checked={formAddress.billing}
                  value={formAddress.billing}
                  onChange={handleOnChange}
                />
              </li>
            </ul>
          </Fieldset>
          <Fieldset legend='Compliance'>
            <Dropdown
              name='compliance.status'
              label='Status'
              value={formAddress.compliance?.status}
              onChange={handleOnChange}
              options={complianceStatuses?.map(s => ({ value: s.value, label: s.description }))}
              isClearable={false}
            />
            <TextInput
              name='compliance.expires'
              label='Expiry'
              value={formAddress.compliance?.expires}
              onChange={handleOnChange}
              type='date'
            />
          </Fieldset>
          {
          licenceTypesList?.map(licenceType => (
            <Fieldset legend={licenceType} key={licenceType}>
              <Dropdown
                name={`licences.${licenceType}.status`}
                label='Status'
                value={formAddress.licences?.[licenceType]?.status}
                onChange={handleOnChange}
                options={licenceStatuses?.map(s => ({ value: s.value, label: s.description }))}
                isClearable={false}
              />
              <TextInput
                name={`licences.${licenceType}.expires`}
                label='Expiry'
                value={formAddress.licences?.[licenceType]?.expires}
                onChange={handleOnChange}
                type='date'
              />
            </Fieldset>
          ))
          }
          <TextArea
            name='emailCcs'
            label='Email Ccs'
            required={false}
            value={formAddress.emailCcs}
            onChange={handleOnChange}
            rows={5}
          />
          <TextArea
            name='emailBccs'
            label='Email Bccs'
            required={false}
            value={formAddress.emailBccs}
            onChange={handleOnChange}
            rows={5}
          />
          <TextArea
            name='invoiceNotes'
            label='Invoice Notes'
            required={false}
            value={formAddress.invoiceNotes}
            onChange={handleOnChange}
          />
          <Fieldset legend='Payment Details' key='invoicePayment'>
            <Fieldset
              legend='Client Banking'
              key='clientBanking'
            >
              <TextInput
                name="invoicePayment.primaryBank"
                label="Primary Bank"
                value={formAddress.invoicePayment.primaryBank}
                onChange={handleOnChange}
                required={true}
                // disabled={isDisabled || !isAdmin}
              />
              <TextInput
                name="invoicePayment.secondaryBank"
                label="Secondary Bank"
                value={formAddress.invoicePayment.secondaryBank}
                onChange={handleOnChange}
                // disabled={isDisabled || !isAdmin}
              />
            </Fieldset>
            <Fieldset
              legend='Clara Banking'
              key='claraBanking'
            >
              <Dropdown
                name='invoicePayment.clara'
                label='Clara Invoice'
                options={invoicePaymentDetails?.map(({ key }) => ({ label: key, value: key }))}
                value={formAddress.invoicePayment.clara}
                onChange={handleOnChange}
                isClearable={false}
                required={true}
              />
              <Dropdown
                name='invoicePayment.lucara'
                label='Lucara Invoice'
                options={invoicePaymentDetails?.map(({ key }) => ({ label: key, value: key }))}
                value={formAddress.invoicePayment.lucara}
                onChange={handleOnChange}
                isClearable={false}
                required={true}
              />
            </Fieldset>
          </Fieldset>
          <Fieldset legend='Compliance Documents' key='userDocuments' span={true}>
            {(formAddress.userDocuments || []).length
              ? formAddress.userDocuments.map((doc, idx) => (
                <FormRow key={`userDocument${idx}`}>
                  <TextInput
                    name={`userDocuments.${idx}.name`}
                    label="Name"
                    value={doc.name}
                    onChange={e => handleOnChange({
                      currentTarget: {
                        name: 'userDocuments',
                        value: formAddress.userDocuments.map(({ _id, ...restDoc }) => ({
                          _id,
                          ...restDoc,
                          ...(_id === doc._id && { name: (e?.currentTarget ?? e?.target)?.value })
                        }))
                      }
                    })}
                  />
                  <TextInput
                    name={`userDocuments.${idx}.documentType`}
                    label="Document Type"
                    value={doc.documentType}
                    onChange={e => handleOnChange({
                      currentTarget: {
                        name: 'userDocuments',
                        value: formAddress.userDocuments.map(({ _id, ...restDoc }) => ({
                          _id,
                          ...restDoc,
                          ...(_id === doc._id && { documentType: (e?.currentTarget ?? e?.target)?.value })
                        }))
                      }
                    })}
                  />
                  <TextInput
                    name={`userDocuments.${idx}.expires`}
                    label='Expiry'
                    type='date'
                    value={doc.expires}
                    onChange={e => handleOnChange({ // Bug Here withh eror on submit
                      currentTarget: {
                        name: 'userDocuments',
                        value: formAddress.userDocuments.map(({ _id, ...restDoc }) => ({
                          _id,
                          ...restDoc,
                          ...(_id === doc._id && { expires: (e?.currentTarget ?? e?.target)?.value })
                        }))
                      }
                    })}
                  />
                  <div className="edit-organization-address-form__userdoc-icons">
                    <Icon
                      name='plus'
                      title='Add Another'
                      size='lg'
                      onClick={() => handleOnChange({ currentTarget: { name: 'userDocuments', value: formAddress.userDocuments.concat({ _id: uuidv4() }) } })}
                    />
                    <Icon
                      name='remove'
                      title='Remove'
                      size='lg'
                      onClick={() => handleOnChange({ currentTarget: { name: 'userDocuments', value: formAddress.userDocuments.filter(({ _id }) => _id !== doc._id) } })}
                    />
                  </div>
                </FormRow>
              ))
              : (
                <Button
                  onClick={() => handleOnChange({ currentTarget: { name: 'userDocuments', value: [{ _id: uuidv4() }] } })}
                >
                  Add Document
                </Button>
              )}
          </Fieldset>
        </div>
      </Collapsible>
      <div className="address-form__icons">
        <Icon
          name='plus'
          title='Add Another'
          size='lg'
          onClick={() => onAdd()}
        />
        <Icon
          name='remove'
          title='Remove'
          disabled={!canRemove}
          size='lg'
          onClick={() => onRemove()}
        />
      </div>
    </div>
  )
}

AddressForm.propTypes = {
  onChange: PropTypes.func,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  canRemove: PropTypes.bool,
  isOpen: PropTypes.bool,
  formData: PropTypes.object
}

AddressForm.defaultProps = {
  canRemove: false,
  isOpen: false,
  formData: {}
}

export default AddressForm
