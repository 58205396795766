import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
import { Dropdown } from '@molecules'
import { Form } from '@organisms'
import { Button } from '@atoms'
import { costActions, baselineActions, tingeMapActions, reportActions } from '@actions'
import { useGlobalsStore, useModalStore } from '@stores'
import { getFormFieldLabel } from '../orderUtils'
import { ConfirmationModal } from '@templates'
import moment from 'moment'
import { fileUtils, textUtils } from '@utils'
import { COMMON } from '@constants'

function CostsBaselineStep(props) {
  const { validateAndGoNextStep, renderValidationError, getFormLocalState, validationSchemaError } = props.useMultiSteps(props)
  const {
    buyerSettingsModalOptions,
    setActiveOrderFieldModal,
    setActiveOrderFieldModalField
  } = useModalStore()

  const {
    getCertCostList,
    getMfgCostList
  } = costActions
  const { getBaselines } = baselineActions
  const { getTingeMapList } = tingeMapActions

  const [certificateCostsList, setCertificateCostsList] = useState([])
  const [manufacturingCostsList, setManufacturingCostsList] = useState([])
  const [baselinesList, setBaselinesList] = useState([])
  const [tingeMapsList, setTingeMapsList] = useState([])

  useEffect(() => {
    getCertCostList({ condition: 'ACTIVE' })
      .then(response => setCertificateCostsList(response.data.data))
      .catch(err => console.error(err))
  }, [])

  useEffect(() => {
    getMfgCostList({ condition: 'ACTIVE' })
      .then(response => setManufacturingCostsList(response.data.data))
      .catch(err => console.error(err))
  }, [])

  useEffect(() => {
    getBaselines({ condition: 'ACTIVE', columns: '[ParentBaseline]' })
      .then(response => setBaselinesList(response.data.data.sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt))))
      .catch(err => console.error(err))
  }, [])

  useEffect(() => {
    getTingeMapList({ condition: 'ACTIVE' })
    .then(response => setTingeMapsList(response.data.data))
    .catch(console.error)
  }, [])

  const handleViewClick = (key, id) => (e) => {
    e.preventDefault()
    const modalFields = { id, label: '' }
    switch (key) {
      case buyerSettingsModalOptions.BASELINE: {
        const baseline = baselinesList.find(({ id: baselineId }) => id === baselineId)
        modalFields.label = getFormFieldLabel(key, { ...baseline, shapeType: baselineShapeTypesMap?.[baseline.shapeType] ?? '' })
        break
      }
      case buyerSettingsModalOptions.MFG_COST:
        modalFields.label = getFormFieldLabel(key, manufacturingCostsList.find(({ id: mfgCostId }) => id === mfgCostId))
        break
      case buyerSettingsModalOptions.CERT_COST:
        modalFields.label = getFormFieldLabel(key, certificateCostsList.find(({ id: certCostId }) => id === certCostId))
        break
      case buyerSettingsModalOptions.TINGE:
        modalFields.label = getFormFieldLabel(key, tingeMapsList.find(({ id: tingeMapId }) => id === tingeMapId))
        break
    }
    setActiveOrderFieldModalField(modalFields)
    setActiveOrderFieldModal(key)
  }

  const handleDownloadClick = (key, id) => (e) => {
    e.preventDefault()
    switch (key) {
      case buyerSettingsModalOptions.BASELINE: {
        reportActions.exportBaseline('xlsx', id)
      .then(result => fileUtils.saveBase64Excel(result.data.data.report, fileUtils.getFileName(result.data.data)))
        break
      }
    }
  }

  const { getOrgById, baselineShapeTypesMap, getBaselineShapeTypes } = useGlobalsStore()
  const [showCosts, setShowCosts] = useState(false)
  useEffect(() => {
    getOrgById(props.getState('buyerId'))
    .then(buyer => {
      setShowCosts(buyer?.showCosts)
    })
  }, [props.state.buyerId])

  useEffect(() => { getBaselineShapeTypes() }, [])

  const [confirmModal, setConfirmModal] = useState(undefined)
  async function handleNext() {
    const priceBaselineId = props.getState('priceBaselineId')
    if (priceBaselineId) {
      const isRoundShape = getFormLocalState('isRoundShape')
      const baselineShapeType = baselinesList.find(({ id }) => id === priceBaselineId)?.shapeType
      const baselineShapeTypeDescription = baselineShapeTypesMap?.[baselineShapeType] ?? ''
      if ((isRoundShape && baselineShapeType === 'FANCY') || (!isRoundShape && baselineShapeType === 'ROUND')) {
        return new Promise((resolve, reject) => {
          setConfirmModal({
            message: `You selected a ${getFormLocalState('isRoundShape') ? 'ROUND' : 'NON-ROUND'} Shape. Are you sure you want to use a ${baselineShapeTypeDescription} Baseline for this order?`,
            promise: { resolve, reject }
          })
        })
          .finally(() => setConfirmModal(undefined))
          .then(() => validateAndGoNextStep())
      }
    }
    return validateAndGoNextStep()
  }

  const discountPricing = props.getState('priceScheme')?.some(wgt => wgt.pricingMethod === 'discount' || (!wgt.pricingMethod && wgt.discountTable?.length))

  return (
    <>
      <Form>
        {
          discountPricing || props.getState('priceBaselineId')
            ? (
              <Dropdown
                name='priceBaselineId'
                label='Baseline'
                topActions={props.getState('priceBaselineId') ? [
                  { label: 'View', onClick: (_, e) => handleViewClick(buyerSettingsModalOptions.BASELINE, props.getState('priceBaselineId'))(e) },
                  { label: 'Download', onClick: (_, e) => handleDownloadClick(buyerSettingsModalOptions.BASELINE, props.getState('priceBaselineId'))(e) }
                ] : []}
                value={props.getState('priceBaselineId')}
                onChange={props.handleChange}
                options={baselinesList
                  .filter(baseline => baseline.buyerId == null || baseline.buyerId === props.getState('buyerId'))
                  .map(baseline => ({
                    value: baseline.id,
                    label: getFormFieldLabel('priceBaselineId', { ...baseline, shapeType: baselineShapeTypesMap?.[baseline.shapeType] ?? '' })
                  }))
                }
                validationText={validationSchemaError?.parseStep('priceBaselineId')}
              />
            )
            : null
        }
        {
          showCosts || props.getState('mfgCostId')
            ? <Dropdown
              name='mfgCostId'
              label='Manufacturing Costs'
              topActions={props.getState('mfgCostId') ? [
                { label: 'View', onClick: (_, e) => handleViewClick(buyerSettingsModalOptions.MFG_COST, props.getState('mfgCostId'))(e) }
              ] : []}
              value={props.getState('mfgCostId')}
              onChange={props.handleChange}
              options={manufacturingCostsList
              .filter(cost => cost.buyerId == null || cost.buyerId === props.getState('buyerId'))
              .map(manufacturingCost => ({
                value: manufacturingCost.id,
                label: getFormFieldLabel('mfgCostId', manufacturingCost)
              }))
              }
            />
            : null
        }
        {
          showCosts || props.getState('certCostId')
            ? <Dropdown
              name='certCostId'
              label='Certificate Costs'
              topActions={props.getState('certCostId') ? [
                { label: 'View', onClick: (_, e) => handleViewClick(buyerSettingsModalOptions.CERT_COST, props.getState('certCostId'))(e) }
              ] : []}
              value={props.getState('certCostId')}
              onChange={props.handleChange}
              options={certificateCostsList
              .filter(cost => cost.buyerId == null || cost.buyerId === props.getState('buyerId'))
              .map(certificateCost => ({
                value: certificateCost.id,
                label: getFormFieldLabel('certCostId', certificateCost)
              }))
              }
            />
            : null
        }
        <Dropdown
          name='tingeMapId'
          label='Tinge Mapping'
          topActions={props.getState('tingeMapId') ? [
            { label: 'View', onClick: (_, e) => handleViewClick(buyerSettingsModalOptions.TINGE, props.getState('tingeMapId'))(e) }
          ] : []}
          value={props.getState('tingeMapId')}
          onChange={props.handleChange}
          options={tingeMapsList
          .filter(map => map.buyerId == null || map.buyerId === props.getState('buyerId'))
          .map(tinge => ({
            value: tinge.id,
            label: getFormFieldLabel('tingeMapId', tinge)
          }))
          }
        />
        {renderValidationError()}
        <div className="right">
          <Button
            size='sm'
            typeVariant='action'
            onClick={() => props.prev()}
          >
          Previous
          </Button>
          <Button
            size='sm'
            onClick={handleNext}
          >
          Next
          </Button>
        </div>
      </Form>

      <ConfirmationModal
        open={!!(confirmModal)}
        title={'Confirm Baseline'}
        message={confirmModal?.message}
        onClose={() => confirmModal?.promise?.reject()}
        onSubmit={() => confirmModal?.promise?.resolve()}
      />
    </>
  )
}

// CostsBaselineStep.propTypes = {
// }

// CostsBaselineStep.defaultProps = {
// }

export default CostsBaselineStep
