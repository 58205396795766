import React, { useRef, useState } from 'react'
import { useInterval, useAuth, useActivityMonitor, useProgressList } from '@hooks'
import PropTypes from 'prop-types'
import { AlertModal } from '@templates'
function CustomIdleTimer(props) {
  const warningTime = 60 * props.warningTime
  const inactivityTime = 60 * props.inactivityTime
  const delay = 5000
  const {
    signOut,
    currentSession,
    refreshSession,
    setMightRefreshSession
  } = useAuth()
  const windowActiveTime = useActivityMonitor(true) // this returns a ref
  const warningModalOpen = useRef(false)
  const expiredModalOpen = useRef(false)
  const [toggle, setToggle] = useState(false) // this is just some state to change when we want to rerender
  const { isProgressListEmpty } = useProgressList()

  function setModals({ warning, expired }) {
    const changed = warning !== warningModalOpen.current || expired !== expiredModalOpen.current
    warningModalOpen.current = warning
    expiredModalOpen.current = expired
    if (changed) setToggle(!toggle)
  }

  // create interval responsible for checking
  // the timers and show the alerts
  useInterval(() => {
    const now = new Date().getTime() / 1000
    const remainingTime = currentSession?.idToken?.payload?.exp ? currentSession.idToken.payload.exp - now : -1
    if (remainingTime <= -warningTime) {
      setMightRefreshSession(false)
      setModals({ warning: false, expired: true })
    } else if (remainingTime <= 0) {
      setMightRefreshSession(true)
      if ((!warningModalOpen.current
        && !expiredModalOpen.current
        && (now - windowActiveTime.current / 1000 < inactivityTime))
        || !isProgressListEmpty()) refreshSession()
      else setModals({ warning: true, expired: false })
    } else if (remainingTime <= delay / 1000) {
      setMightRefreshSession(true)
    } else if (warningModalOpen.current || expiredModalOpen.current) {
      setMightRefreshSession(false)
      setModals({ warning: false, expired: false })
    }
  }, delay)

  return <>
    <AlertModal
      open={warningModalOpen.current}
      closeable={false}
      onSubmit={() => refreshSession()}
      title='Alert!'
      message={<>
        Your session is about to expire.<br/>To remain logged in, please click OK. Otherwise you will be signed out.
      </>}
    />
    <AlertModal
      open={expiredModalOpen.current}
      closeable={false}
      onSubmit={() => signOut()}
      title='Alert!'
      message='Your session expired. Please, sign in again.'
    />
  </>
}

CustomIdleTimer.propTypes = {
  warningTime: PropTypes.number,
  inactivityTime: PropTypes.number
}

CustomIdleTimer.defaultProps = {
  warningTime: 5, // number of minutes after expiring when the user can click to stay logged in
  inactivityTime: 5 // number of minutes after the user acts when the session is still considered 'active'
}

export default CustomIdleTimer
