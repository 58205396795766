import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@atoms'
import { UploadAdvFilesModal } from '@pages'
import UpdateInclusionDetails from './updateInclusionDetails'
import { useProgressList } from '@hooks'
import { objectUtils } from '@utils'
import { roughStoneActions } from '@actions'

function UpdateAdvDetails({
  progressListName,
  allowedStatuses,
  handleAdvUploadSubmit,
  handleClose,
  handleCancel,
  stonesList
}) {
  const [currentStep, setCurrentStep] = useState(0)
  const [isAdvFileSelected, setIsAdvFileSelected] = useState(false)
  const [uploadStones, setUploadStones] = useState()

  const {
    getProgressList
  } = useProgressList()

  async function uploadAdv(uploadObj, inclusionDetails, list, task) {
    const uploadUrl = await roughStoneActions.getQcUploadUrl({ orgId: uploadObj.rough.sellerId, roughId: uploadObj.rough.id }, { loadingSpinner: false })
    const tempUpload = UploadAdvFilesModal.buildTempUpload(uploadUrl, uploadObj)
    const uploadedFile = await roughStoneActions.uploadFile(tempUpload.url, uploadObj.file, {
      updateProgress: list?.updateTaskProgressPercentage,
      taskId: task.id
    })
    return await roughStoneActions.editQcRoughStone(tempUpload.roughId, {
      galaxyFile: UploadAdvFilesModal.buildGalaxyFile(tempUpload, uploadedFile),
      ...inclusionDetails
    }, { loadingSpinner: false })
  }
  function handleAdvSubmit(_, selectedRoughStones) {
    setUploadStones(selectedRoughStones.map(({ rough }) => rough).filter(x => x))
    setCurrentStep(1)
  }

  function handleInclusionSubmit(form) {
    const progressList = getProgressList(progressListName)
    if (form) {
      const inclusionDetails = objectUtils.removeNulls(form, { replacement: undefined, deep: true })
      const changedRows = uploadStones.filter(r =>
        (inclusionDetails?.inclusionsTypeId != null && r.inclusionsTypeId !== inclusionDetails.inclusionsTypeId)
          || (inclusionDetails?.inclusionReductionsId != null && r.inclusionReductionsId !== inclusionDetails.inclusionReductionsId)
      )
      if (changedRows.length) {
        // Override each of the upload adv callbacks to include inclusionDetails
        for (const taskId in progressList.tasks) {
          const task = progressList.tasks[taskId]
          progressList.updateTask(taskId, {
            ...task,
            callback: () => uploadAdv(task.uploadObj, inclusionDetails, progressList, task).then(() => handleAdvUploadSubmit(task.uploadObj.rough))
          })
        }
      }
    }
    // Start the upload process
    progressList.start()
    handleClose()
  }

  if (currentStep === 0) {
    return (
      <>
        <UploadAdvFilesModal
          progressListName={progressListName}
          progressListTitle='Update Inclusion Details / Upload ADV Files'
          submitText='Next'
          roughStonesList={stonesList}
          allowedStatuses={allowedStatuses}
          onUpload={handleAdvSubmit}
          onUploadCallback={handleAdvUploadSubmit}
          onFileSelect={(isFileSelected) => setIsAdvFileSelected(isFileSelected)}
          autoUpload={false}
          handleCancel={handleCancel}
          required={true}
          editRoughFunc={roughStoneActions.editQcRoughStone}
          getUploadUrlFunc={roughStoneActions.getQcUploadUrl}
        />
        {!isAdvFileSelected && (
          <div key='row-buttons' className='modal__buttons'>
            <Button
              typeVariant='secondary'
              size='sm'
              onClick={handleCancel}>
                Cancel
            </Button>
          </div>
        )}
      </>
    )
  } else if (currentStep === 1) return <UpdateInclusionDetails handleSubmit={handleInclusionSubmit} handleCancel={handleCancel}/>
  else return null
}

UpdateAdvDetails.propTypes = {
  progressListName: PropTypes.string,
  onAllTasksComplete: PropTypes.func,
  handleAdvUploadSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  handleCancel: PropTypes.func,
  stonesList: PropTypes.arrayOf(PropTypes.object),
  allowedStatuses: PropTypes.arrayOf(PropTypes.string)
}

UpdateAdvDetails.defaultProps = {
  allowedStatuses: [],
  stonesList: []
}

export default UpdateAdvDetails
