import React, { useEffect, useState, useMemo } from 'react'
import { roughStoneActions } from '@actions'
import { arrayUtils, objectUtils } from '@utils'
import { useHistory } from 'react-router-dom'
import { useToast } from '@hooks'
import { roughSchema } from '@schemas'
import { Table } from '@organisms'

function CreateMultipleRoughs({ roughList, columns, constants, assortment, fileId }) {
  const { showSuccessToast, showErrorToast, showWarningToast } = useToast()
  const history = useHistory()
  const [validationText, setValidationText] = useState({})
  const schemaContext = useMemo(() => ({ cache: new Map() }), [])

  const tableColumns = useMemo(() => {
    if (!columns) return []
    return columns(['sellerStoneName', 'weight', 'reservePpcOriginal', 'reservePpcOverride', 'measurements', 'eyeMeasurement', 'countryName', 'mineName', 'pipeName', 'batchName', 'scanTypeName', 'pricePoint', 'weightCategory', 'inclusionsTypeName', 'inclusionReductionsName', 'tensionName', 'otherAttributes.yellowFluorescence', 'typeName'])
  }, [columns])
  const validationSchema = useMemo(() => {
    if (!constants) return
    const {
      mines,
      pricePoints,
      weightCategories,
      eyeMeasurementColours,
      eyeMeasurementFluorescences,
      eyeMeasurementTinges,
      inclusionTypes,
      inclusionReductions,
      roughScanTypes,
      roughTensions,
      roughTypes,
      roughColours,
      roughFluorescences,
      polishedFluorescences,
      tinges,
      minMeasurements,
      tensionRequiredAboveWeight,
      eyeColourAbovePrimarySourceWeight
    } = constants
    return {
      warning: roughSchema.createMultipleRoughsWarningSchema({
        provenanceType: assortment?.ProvenanceType,
        minesList: arrayUtils.pickBy(mines, 'id'),
        pricePoints,
        weightCategories,
        eyeMeasurementColours: arrayUtils.pickBy(eyeMeasurementColours ?? [], 'value'),
        eyeMeasurementFluorescences: arrayUtils.pickBy(eyeMeasurementFluorescences ?? [], 'value'),
        eyeMeasurementTinges: arrayUtils.pickBy(eyeMeasurementTinges ?? [], 'value'),
        inclusionTypes: arrayUtils.pickBy(inclusionTypes ?? [], 'id'),
        inclusionReductions: arrayUtils.pickBy(inclusionReductions ?? [], 'id'),
        roughScanTypes: arrayUtils.pickBy(roughScanTypes ?? [], 'value'),
        roughTensions: arrayUtils.pickBy(roughTensions ?? [], 'value'),
        roughTypes: arrayUtils.pickBy(roughTypes ?? [], 'value'),
        minMeasurements,
        tensionRequiredAboveWeight,
        eyeColourAbovePrimarySourceWeight,
        // For calculateRoughMeasurements
        roughColours: roughColours ?? [],
        roughFluorescences: roughFluorescences ?? [],
        polishedFluorescences: polishedFluorescences ?? [],
        roughTinges: tinges ?? []
      }),
      error: roughSchema.createMultipleRoughsErrorSchema({
        roughColours: roughColours ?? [],
        roughFluorescences: roughFluorescences ?? [],
        roughTinges: tinges ?? []
      })
    }
  }, [constants])

  const { hasErrors, hasWarnings } = useMemo(() => {
    const res = { hasErrors: false, hasWarnings: false }
    if (validationText?.[fileId]) {
      // validationErrors is a Map so we will have to loop through the entries
      for (const valErrors of validationText?.[fileId]?.values()) {
        if (valErrors.getError()) {
          res.hasErrors = true
          break
        } else if (valErrors.getWarning()) {
          res.hasWarnings = true
        }
      }
    }
    return res
  }, [validationText?.[fileId]])

  useEffect(() => {
    if (hasErrors) {
      showErrorToast('Please clear the highlighted errors before saving.')
    } else if (hasWarnings) {
      showWarningToast('Please clear the highlighted warnings or proceed to saving. Please note that the values of some fields with warning may be removed before saving.')
    }
  }, [hasErrors, hasWarnings])

  function handleSubmit(roughList) {
    if (!roughList.length) {
      showErrorToast('No rough stones were submitted')
      return
    }
    // TODO here, separate out the eyeRequired stuff, which can be added to the rough object for display in the grid
    // Clean up and maybe join non-scalar
    const roughsToSubmit = roughList.map(({ id, assortmentName, countryName, mineName, pipeName, batchName, inclusionsTypeName, inclusionReductionsName, scanTypeName, tensionName, qcStatusName, locationName, typeName, ...rough }) => objectUtils.filterNullish(rough, true))

    roughStoneActions.createRoughStones(roughsToSubmit)
    .then(async result => {
      if (result.data.success) {
        showSuccessToast(`${roughsToSubmit.length} rough stone${roughsToSubmit.length > 1 ? 's were' : ' was'} successfully created`)
        history.goBack()
      }
    })
    .catch(err => showErrorToast(err.message))
  }

  const topBarActions = [{
    label: 'Create Rough Stones',
    callback: () => handleSubmit(roughList),
    disabled: !roughList?.length || hasErrors
  }]

  return (
    <Table
      title='Upload Rough Stones Preview'
      columns={tableColumns}
      data={roughList}
      isMultiSelect={false}
      topBarActions={topBarActions}
      initialPageSize={25}
      initialSort={[]}
      validationProps={{
        validationSchema,
        schemaContext,
        onValidationChange: ({ validationText: valText }) => setValidationText(currValText => ({ ...currValText, [fileId]: valText }))
      }}
    />
  )
}
export default CreateMultipleRoughs
