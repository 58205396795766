import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useGlobalsStore, useAuthStore } from '@stores'
import { userActions } from '@actions'
import { useToast, usePageTitle } from '@hooks'
import moment from 'moment'
import { DetailsPage } from '@templates'
import { textUtils } from '@utils'

function UserDetails({ match, title }) {
  const {
    orgsList: { all: orgsList },
    getOrgById,
    getOrgsList,
    getUserRolesList,
    setUsersList
  } = useGlobalsStore(store => store)
  const [user, setUser] = useState()
  const [fields, setFields] = useState([])
  const { showSuccessToast } = useToast()
  const [userRoles, setUserRoles] = useState([])
  const { hasPermission, hasAdmin, currentAuthenticatedUser, permissionsAdminCache } = useAuthStore(state => state)
  let { userId, operation } = match?.params
  userId = userId === 'me'
    ? currentAuthenticatedUser?.attributes?.sub
    : userId

  usePageTitle(title, userId, user?.name)
  useEffect(() => {
    if (currentAuthenticatedUser) populateUserData()
  }, [userId, currentAuthenticatedUser])

  useEffect(() => {
    getOrgsList()
  }, [])

  useEffect(() => {
    populateUserRoles()
  }, [user, permissionsAdminCache])

  useEffect(() => {
    if (user) {
      setFields([
        {
          label: 'Username',
          value: user.username,
          name: 'username',
          componentName: 'textInput',
          canEdit: false
        },
        {
          label: 'Name',
          value: user.name,
          name: 'name',
          componentName: 'textInput',
          canEdit: true
        },
        {
          label: 'Email Address',
          value: user.email,
          name: 'email',
          componentName: 'textInput',
          canEdit: true
        },
        {
          label: 'Phone Number',
          value: user.phoneNumber,
          name: 'phoneNumber',
          componentName: 'textInput',
          canEdit: true
        },
        {
          label: 'Organization',
          value: user.orgId,
          name: 'orgId',
          componentName: 'dropdown',
          options: orgsList?.map(org => ({ value: org.orgId, label: org.commonName })),
          canEdit: hasAdmin(userActions.editUser)
        },
        {
          label: 'Roles',
          value: user.roles,
          name: 'roles',
          componentName: 'dropdown',
          options: userRoles,
          isMulti: true,
          canEdit: hasAdmin(userActions.editUser)
        },
        {
          label: 'Status',
          value: textUtils.formatDescription(user.status),
          shouldDisplay: hasAdmin(userActions.editUser),
          name: 'status',
          componentName: 'textInput',
          canEdit: false
        },
        {
          label: 'Condition',
          value: user.enabled ? 'true' : 'false',
          shouldDisplay: hasAdmin(userActions.editUser),
          name: 'enabled',
          componentName: 'dropdown',
          options: [
            { value: 'true', label: 'Enabled' },
            { value: 'false', label: 'Disabled' }
          ],
          canEdit: hasAdmin(userActions.editUser)
        },
        {
          label: 'Created At',
          value: moment(user.createdAt).toLocaleString()
        },
        {
          label: 'Last Updated',
          value: moment(user.updatedAt).toLocaleString()
        }
      ])
    }
  }, [user, userRoles, orgsList, permissionsAdminCache])

  async function handleOnSubmit(editedValues) {
    const { enabled, ...otherData } = editedValues

    if (enabled) {
      await userActions.setUserCondition({ username: user.username, enabled: editedValues.enabled })
    }

    if (Object.keys(otherData).length) {
      return userActions
        .editUser({ username: user.username, ...otherData })
        .then(() => {
          showSuccessToast('User updated.')
          populateUserData()
        })
    } else {
      setUsersList()
    }
  }

  function populateUserData() {
    userActions.getUsersList({ userId })
    .then(async response => {
      const u = response.data.data[0]
      const org = await getOrgById(u.orgId)
      if (org) u.orgName = org.commonName
      setUser(u)
    })
  }

  async function populateUserRoles() {
    if (hasPermission(userActions.getUserRoles)) {
      getUserRolesList().then(userRoles => setUserRoles(userRoles?.map(({ role }) => ({ label: role, value: role }))))
    } else if (user?.roles) {
      setUserRoles(user.roles.map(r => ({
        label: r,
        value: r
      })))
    }
  }

  return (
    <DetailsPage
      canEdit={true}
      isEdit={operation === 'edit'}
      fields={fields}
      onSubmit={handleOnSubmit}
      title={{
        label: 'User ID',
        value: user?.userId || ''
      }}
    />
  )
}

UserDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string
}

export default UserDetails
