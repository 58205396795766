import React, { useEffect, useState } from 'react'
import { Button } from '@atoms'
import Cookies from 'js-cookie'

function CookiesBanner() {
  const [isCookiesAccepted, setIsCookiesAccepted] = useState(false)

  useEffect(() => {
    setIsCookiesAccepted(Cookies.get('cookies-accepted') || false)
  }, [])

  function handleAcceptCookies() {
    Cookies.set('cookies-accepted', true, { expires: 365 })
    setIsCookiesAccepted(true)
  }

  return (
    !isCookiesAccepted
      ? (
        <div className='cookies-banner'>
          <div className='cookies-banner__content'>
            <h3>This Website Uses Cookies</h3>
            <span>This site uses cookies for the purposes of authentication and to allow access to the application.</span>
            <span>We do not track or analyze any user behavior nor do we provide that information to any third party.</span>
          </div>
          <Button onClick={handleAcceptCookies}>Ok</Button>
        </div>
      )
      : null
  )
}

export default CookiesBanner
