import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@atoms'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import { textUtils } from '@utils'

function DashboardInfoCard({
  title,
  value,
  secondaryValue,
  iconName,
  isVisible,
  type,
  style,
  secondaryType
}) {
  function getComponentByType(val, typ) {
    if (typ === 'currency' || typ === 'weight' || typ === 'number') {
      const numFormatProps = {
        decimalScale: typ === 'currency' || typ === 'weight' ? 2 : 0,
        ...(typ === 'currency' ? { prefix: '$' } : {}),
        ...(typ === 'weight' ? { suffix: 'ct' } : {})
      }
      return <NumberFormat
        value={val}
        displayType='text'
        thousandSeparator={true}
        {...numFormatProps}
      />
    } else if (typ === 'date') {
      const date = moment(val)
      return date.isValid() ? textUtils.formatDate(date) : '-'
    }
    return val
  }

  const component = getComponentByType(value, type)
  const secondaryComponent = getComponentByType(secondaryValue, secondaryType)
  return (
    <>
      {
        isVisible
          ? (
            <div className={`dashboard-card--${style}`}>
              <div className={`dashboard-card__icon--${style}`}>
                <Icon
                  name={iconName}
                  size='md'
                />
              </div>
              <span className='dashboard-card__title'>{title}</span>
              <span className='dashboard-card__value'>{component} {secondaryComponent && <>({secondaryComponent})</>}</span>
            </div>
          )
          : null
      }
    </>
  )
}

DashboardInfoCard.propTypes = {
  iconName: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  secondaryValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isVisible: PropTypes.bool,
  type: PropTypes.string,
  secondaryType: PropTypes.string,
  style: PropTypes.string
}

DashboardInfoCard.defaultProps = {
  iconName: 'error',
  title: '',
  value: '',
  secondaryValue: '',
  isVisible: true,
  style: 'admin'
}

export default DashboardInfoCard
