import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { FormComponents } from '@organisms'
import {
  provenanceActions,
  assortmentActions
} from '@actions'
import { useAuthStore, useGlobalsStore } from '@stores'
import { SELLER } from '@constants'
import { useToast } from '@hooks'

function CreateAssortment() {
  // -- Initial values and initial setup
  // form dropdown options
  const [provenanceList, setProvenanceList] = useState([])
  const [reserveMethods, setReserveMethods] = useState([])
  const [assortmentCategories, setAssortmentCategories] = useState([])
  const [isPriorityDisabled, setIsPriorityDisabled] = useState(false)
  const { showSuccessToast } = useToast()

  const { orgId: userOrgId, hasAdmin } = useAuthStore(state => state)
  const isAdmin = hasAdmin(assortmentActions.createAssortment)
  const {
    getReserveMethods,
    getOrgsList,
    orgsList: { [JSON.stringify({ condition: 'ACTIVE' })]: orgsList }
  } = useGlobalsStore(store => store)
  useEffect(() => {
    getOrgsList({ condition: 'ACTIVE' })
  }, [])

  function refreshProvenanceTypes(sellerId) {
    if (sellerId) {
      provenanceActions
      .getProvenance({ sellerId })
      .then(response => setProvenanceList(response.data.data))
      // TODO: add error handling
      .catch(err => console.warn(err))
    } else setProvenanceList([])
  }

  // get list of provenance
  useEffect(() => {
    refreshProvenanceTypes(userOrgId)
  }, [])

  // get list of reserve methods
  useEffect(() => {
    getReserveMethods()
    .then(methods => setReserveMethods(methods))
    // TODO: add error handling
    .catch(err => console.warn(err))
  }, [])

  // get list of assortment categories
  useEffect(() => {
    assortmentActions.getAssortmentCategories()
    .then(response => setAssortmentCategories(response.data.data))
    // TODO add error handling
    .catch(err => console.warn(err))
  }, [])

  const history = useHistory()

  function handleBidsOnChange(_, e) {
    const target = e.currentTarget ?? e.target
    setIsPriorityDisabled(target.checked)
  }

  function handleFormSubmit(values) {
    const formValues = { ...values }
    if (formValues.generateBids) {
      formValues.planningPriority = SELLER.ASSORTMENT_PRIORITIES.at(-1).value
      formValues.matchingPriority = SELLER.ASSORTMENT_PRIORITIES.at(-1).value
    }
    assortmentActions.createAssortment(formValues)
    .then(response => {
      history.push(`/assortments/${response.data.data}`)
    })
    .then(() => showSuccessToast(`Assortment ${values.name} has been created.`))
    .catch(err => console.warn(err))
  }

  const { SimpleForm, TextInput, Dropdown, SingleCheckbox, Button } = FormComponents

  return (
    <div className="create-assortment__container">
      <div className="col-xl-4 col-lg-7 col-md-10 col-sm-12">
        <SimpleForm
          onSubmit={handleFormSubmit}
          title="Create Assortment"
          name="createAssortment"
          initialValues={{ sellerId: userOrgId }}
        >
          <TextInput size="lg" name="name" label="Name" required={true} />
          {isAdmin
          && <Dropdown
            name='sellerId'
            label='Seller'
            options={orgsList?.map(org => ({
              label: org.commonName,
              value: org.orgId
            }))}
            extraOnChange={(_, e) => refreshProvenanceTypes(e.currentTarget.value)}
          />
          }
          <Dropdown
            size="lg"
            name="assortmentCategory"
            label="Assortment Category"
            creatable={true}
            options={assortmentCategories.map(cat => ({ value: cat, label: cat }))}
          />
          <Dropdown
            size="lg"
            label="Provenance"
            name="provenanceTypeId"
            required
            options={provenanceList.map(provenance => ({
              value: provenance.id,
              label: provenance.description
            }))}
          />
          <Dropdown
            size="lg"
            name="reserveMethod"
            label="Pricing Method"
            required
            options={reserveMethods.map(reserveMethod => ({
              value: reserveMethod.value,
              label: reserveMethod.description
            }))}
            infoTip={true}
          />
          <TextInput
            size="lg"
            type="number"
            label="Reserve Price per Carat"
            name="reservePpcOriginal"
            min={0}
            required={false}
          />
          {isAdmin && [
            <Dropdown
              key='planPri'
              size='lg'
              label='Planning Priority'
              name='planningPriority'
              options={SELLER.ASSORTMENT_PRIORITIES}
              required={false}
              disabled={isPriorityDisabled}
            />,
            <Dropdown
              key='matchPri'
              size='lg'
              label='Matching Priority'
              name='matchingPriority'
              options={SELLER.ASSORTMENT_PRIORITIES}
              required={false}
              disabled={isPriorityDisabled}
            />,
            <SingleCheckbox
              key='bids'
              label='Send Bids'
              name='generateBids'
              required={false}
              extraOnChange={handleBidsOnChange}
            />
          ]}
          <Button typeVariant="action" onClick={() => history.goBack()}>
            Cancel
          </Button>
          <Button size="sm" type="submit">
            Create Assortment
          </Button>
        </SimpleForm>
      </div>
    </div>
  )
}

export default CreateAssortment
