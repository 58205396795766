import create from 'zustand'

const useLoadingSpinner = create((set, get) => ({
  isLoading: {},
  addIsLoading: (key, groupKey = key, options = {}) => {
    const isLoading = get().isLoading
    const { weight = 1, progress = false } = options
    if (!isLoading[groupKey]) {
      isLoading[groupKey] = { activeKeys: new Set(), completedKeys: new Set(), weight: {} }
    }
    isLoading[groupKey].activeKeys.add(key)
    isLoading[groupKey].weight[key] = weight
    isLoading[groupKey].progress = progress
    set({ isLoading })
  },
  removeIsLoading: (key, groupKey = key) => {
    const isLoading = get().isLoading
    if (isLoading[groupKey]) {
      if (isLoading[groupKey].activeKeys.delete(key)) {
        isLoading[groupKey].completedKeys.add(key)
      }
      if (!isLoading[groupKey].activeKeys.size) {
        delete isLoading[groupKey]
      }
      set({ isLoading })
    }
  }
}))

export {
  useLoadingSpinner
}
