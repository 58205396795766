const SETTINGS = {
  CREATE: {
    GROUP: [{ name: 'groupKey', component: 'dropdown', label: 'Group Key', required: true }],
    ITEM: [
      { name: 'key', component: 'textinput', label: 'Key', required: true },
      { name: 'description', component: 'textinput', label: 'Description', required: false }
    ],
    TYPE: [
      { name: 'type', component: 'dropdown', options: ['string', 'number', 'boolean', 'textarea'].map((opt) => ({ value: opt, label: opt })), label: 'Type', required: true }
    ]
  },
  SAVE: {
    // Custom Setting Schemas
    /// Include any settings schemas that do not follow the standard DEFAULT below
    /// Note: component==='autodetect' will work best when the setting has a type defined
    ///       otherwise the value component will be rendered as 'textinput'. If a fixed
    ///       input type is expected for a given groupKey, include a custom setting
    ///       schema entry below with component!=='autodetect'
    INVOICE_PAYMENT_DETAILS: [
      { name: 'value', component: 'textarea', label: 'Value', required: true },
      { name: 'claraDefault', component: 'singlecheckbox', label: 'Clara Default', required: false },
      { name: 'lucaraDefault', component: 'singlecheckbox', label: 'Lucara Default', required: false }
    ],
    DEFAULT_ADDRESSES: [
      { name: 'value', component: 'textinput', label: 'Value', required: true },
      { name: 'name', component: 'textinput', label: 'Name', required: false },
      { name: 'line1', component: 'textinput', label: 'Line 1', required: false },
      { name: 'line2', component: 'textinput', label: 'Line 2', required: false },
      { name: 'line3', component: 'textinput', label: 'Line 3', required: false },
      { name: 'city', component: 'textinput', label: 'City', required: false },
      { name: 'province', component: 'textinput', label: 'Province', required: false },
      { name: 'country', component: 'textinput', label: 'Country', required: false },
      { name: 'postalCode', component: 'textinput', label: 'Postal Code', required: false }
    ],
    // Default Setting Schema
    DEFAULT: [
      { name: 'value', component: 'autodetect', label: 'Value', required: true }
    ]
  }
}
export default SETTINGS
