import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { InfoTip } from '@molecules'
import { COMMON } from '@constants'
import { arrayUtils, objectUtils } from '@utils'

function ToggleSwitch({
  name,
  id,
  label,
  value,
  checked,
  disabled,
  onChange,
  infoTip,
  validationText,
  ...props
}) {
  const isChecked = value || checked || false
  const validHTMLElementProps = useMemo(() => {
    const propKeys = Object.keys(props)
    const validKeys = arrayUtils.intersectBy(propKeys, COMMON.HTML_ATTRIBUTES.GLOBAL.concat(COMMON.HTML_ATTRIBUTES.INPUT), (x) => x.toLowerCase())
    return objectUtils.pick(props, validKeys)
  }, [props])
  return (
    <div className="toggle-switch">
      <label
        className={
          `toggle-switch__label${isChecked ? '--checked' : ''}
          toggle-switch__label${disabled ? '--disabled' : ''}`
        }
      >
        <input
          type='checkbox'
          className='toggle-switch__input'
          name={name}
          id={id}
          checked={isChecked}
          onChange={onChange}
          {...validHTMLElementProps}
        />
      </label>
      <label
        htmlFor={name}
      >
        <span className="subtext" >
          {label}
        </span>
        {infoTip ? <InfoTip name={name}>{infoTip}</InfoTip> : null}
      </label>
    </div>
  )
}

ToggleSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  infoTip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  validationText: PropTypes.object
}

ToggleSwitch.defaultProps = {
  label: '',
  name: ''
}
export default ToggleSwitch
