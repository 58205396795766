import axios from 'axios'
const BASE_URL = process.env.REACT_APP_API_BASE_URL

const getSettings = function(params) {
  return axios.get(`${BASE_URL}/auth/settings`, { params })
}
getSettings.endpoint = 'GET|/auth/settings'

const saveSettings = function(setting) {
  return axios.patch(`${BASE_URL}/auth/settings`, setting)
}
saveSettings.endpoint = 'PATCH|/auth/settings'

export const settingsActions = {
  getSettings,
  saveSettings
}
