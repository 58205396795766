import React from 'react'

function ShapesCutGrades() {
  return (
    <div>
      Shapes and cut grade
    </div>
  )
}

export default ShapesCutGrades
