import React, { useEffect, useState } from 'react'
import { Modal } from '@templates'
import { Button, Checkbox } from '@atoms'
import { TextInput } from '@molecules'

function ConsignmentPrices({ open, onClose, onSubmit, shipment, ...props }) {
  const [useCustomPrices, setUseCustomPrices] = useState(false)
  const [prices, setPrices] = useState([])

  useEffect(() => {
    if (shipment?.roughIds) {
      setPrices(shipment.roughIds.map(rId => ({ roughId: rId, ppc: '' })))
    }
  }, [shipment?.roughIds])

  const handleCheckbox = e => {
    const target = e.currentTarget || e.target
    setUseCustomPrices(target.checked)
  }
  const handleChange = e => {
    const target = e.currentTarget || e.target
    const idx = prices.findIndex(p => p.roughId == target.name)
    if (idx < 0) return
    const newPrices = prices.slice()
    newPrices[idx] = { roughId: target.name, ppc: target.value }
    setPrices(newPrices)
  }

  return <Modal
    title='Create Consignment Memo'
    open={open}
    onClose={onClose}
  >
    <div className="shipment-docs-modal">
      <Checkbox label='Override Reserve Prices' value={useCustomPrices} onChange={handleCheckbox} />
      {
        useCustomPrices ? prices.map(({ roughId, ppc }) => <TextInput key={roughId} name={roughId} label={roughId} value={ppc} type='number' onChange={handleChange} />) : null
      }
      <Button
        size='sm'
        disabled={useCustomPrices && prices.some(p => !p.ppc)}
        onClick={() => onSubmit(useCustomPrices ? prices : undefined)}
      >
      Create
      </Button>
    </div>
  </Modal>
}

export default ConsignmentPrices
