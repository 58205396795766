import React from 'react'
import PropTypes from 'prop-types'
import { Form } from '@organisms'
import { TextInput, Dropdown } from '@molecules'
import CreateRoughStoneBtn from '../inc/createRoughStoneBtn'

function CountryMineStep({ assortment, countryList, mineList, pipeList, batchList, ...props }) {
  const { validationSchemaError, updateMultipleState } = props.useMultiSteps(props)

  return (
    <Form title={`New Rough Stone ${assortment.name ? `for Assortment ${assortment.name}` : ''}`}>
      <TextInput
        size="lg"
        label="Seller Stone Name"
        name="sellerStoneName"
        value={props.getState('sellerStoneName')}
        onChange={props.handleChange}
        validationText={validationSchemaError?.parseStep('sellerStoneName')}
      />
      <Dropdown
        size="lg"
        label="Country"
        name="countryId"
        value={props.getState('countryId')}
        onChange={props.handleChange}
        options={countryList.map(country => {
          return { label: country.name, value: country.id }
        })}
        validationText={validationSchemaError?.parseStep('countryId')}
      />
      <Dropdown
        size="lg"
        label="Mine"
        name="mineId"
        value={props.getState('mineId')}
        onChange={e => updateMultipleState({ mineId: e.currentTarget.value, pipeId: null, batchId: null })}
        options={mineList.map(mine => {
          return { label: mine.name, value: mine.id }
        })}
        validationText={validationSchemaError?.parseStep('mineId')}
      />
      <Dropdown
        size="lg"
        name="pipeId"
        label='Mine Pipe'
        value={props.getState('pipeId')}
        onChange={e => updateMultipleState({ pipeId: e.currentTarget.value, batchId: null })}
        options={pipeList
          .filter(pipe => pipe.mineId === props.getState('mineId') && (pipe.sellerId === null || pipe.sellerId === assortment.sellerId))
          .map(pipe => ({ label: pipe.name, value: pipe.id }))
        }
        validationText={validationSchemaError?.parseStep('pipeId')}
      />
      <Dropdown
        size="lg"
        name="batchId"
        label='Mine Batch'
        value={props.getState('batchId')}
        onChange={props.handleChange}
        options={batchList
          .filter(batch => batch.pipeId === props.getState('pipeId'))
          .map(batch => ({ label: batch.name, value: batch.id }))
        }
        validationText={validationSchemaError?.parseStep('batchId')}
      />
      <CreateRoughStoneBtn {...props} />
    </Form>
  )
}

CountryMineStep.propTypes = {
  props: PropTypes.object,
  state: PropTypes.object,
  formData: PropTypes.object,
  assortment: PropTypes.object,
  countryList: PropTypes.arrayOf(PropTypes.object),
  pipeList: PropTypes.arrayOf(PropTypes.object),
  batchList: PropTypes.arrayOf(PropTypes.object),
  mineList: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func,
  setState: PropTypes.func,
  setStateAll: PropTypes.func,
  getState: PropTypes.func,
  useMultiSteps: PropTypes.func

}

export default CountryMineStep
