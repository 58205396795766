import React, { useEffect } from 'react'
import { organizationActions, tingeMapActions } from '@actions'
import { FormComponents } from '@organisms'

import { useAuthStore, useGlobalsStore } from '@stores'
import { useToast } from '@hooks'
import { useHistory } from 'react-router-dom'
import { Field } from 'formik'

function CreateTingeMap() {
  const {
    SimpleForm,
    TextInput,
    Dropdown,
    Button
  } = FormComponents

  const { showSuccessToast } = useToast()

  const { orgId: userOrgId, hasAdmin } = useAuthStore(state => state)
  const isAdmin = hasAdmin(organizationActions.getOrganizationList)

  const {
    getOrgsList,
    orgsList: { [JSON.stringify({ condition: 'ACTIVE' })]: orgsList },
    polishedTinges: tinges,
    polishedColours: colours,
    getPolishedColours
  } = useGlobalsStore(store => store)
  useEffect(() => {
    getOrgsList({ condition: 'ACTIVE' })
    getPolishedColours()
  }, [])
  const tingeList = tinges?.filter(t => t.value !== 'None')

  const history = useHistory()
  async function handleSubmit({ name, buyerId, ...mapping }) {
    const values = { name, buyerId, mapping }
    tingeMapActions.createTingeMap(values)
    .then(() => {
      showSuccessToast('Tinge Map added.')
      history.push('/buyer-settings')
    })
    .catch(err => console.error(err))
  }

  return (
    <div className='create-tinge-map center'>
      <SimpleForm
        title='New Tinge Map'
        onSubmit={handleSubmit}
        name='createTingeMap'
        initialValues={{ buyerId: userOrgId }}
        customValidation={(values) => {
          if (tingeList.every(t => !values[t.value])) return { mapping: 'At least one tinge is required.' }
        }}
      >
        <TextInput
          name='name'
          label='Name'
        />
        {isAdmin
        && <Dropdown
          name='buyerId'
          label='Buyer'
          options={orgsList?.map(org => ({
            label: org.commonName,
            value: org.orgId
          }))}
        />
        }
        {tingeList && colours
          ? tingeList.map(tinge => (
            <Dropdown
              key={tinge.value}
              name={tinge.value}
              label={tinge.description}
              options={colours.map(col => ({ value: col.value, label: col.description }))}
              required={false}
            />
          ))
          : null
        }
        <Field
          name='mapping'
        >
          {({ form }) => form?.errors?.mapping ? (
            <div className='input__helper-text--error'>{form.errors.mapping}</div>
          ) : null}</Field>
        <Button typeVariant="action" onClick={() => history.push('/buyer-settings')}>
            Cancel
        </Button>
        <Button type='submit' size='sm'>
            Create Tinge Map
        </Button>
      </SimpleForm>
    </div>
  )
}

export default CreateTingeMap
