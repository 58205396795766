import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { DetailsPage } from '@templates'
import { useGlobalsStore, useOrderStore, useAuthStore } from '@stores'
import { saleActions, transactionActions, reportActions } from '@actions'
import PolishedStoneList from './polishedStoneList'
import { textUtils, fileUtils } from '@utils'
import { usePageTitle } from '@hooks'

function TransactionDetails({ match, title }) {
  const { transactionId } = match?.params
  const { hasAdmin, permissionsAdminCache, orgId } = useAuthStore(state => state)

  const {
    getOrgById,
    roughStatuses,
    getRoughStatuses,
    countriesList: { all: countriesList },
    getCountriesList,
    minesList: { all: minesList },
    getMinesList,
    pipesList: { all: pipesList },
    getPipesList,
    batchesList: { all: batchesList },
    getBatchesList,
    roughColours,
    roughColoursMap,
    getRoughColours,
    roughFluorescences,
    roughFluorescencesMap,
    getRoughFluorescences,
    roughTinges: tinges,
    roughTingesMap,
    getRoughTinges,
    roughScanTypes,
    getRoughScanTypes,
    inclusionTypes,
    getInclusionTypes
  } = useGlobalsStore(state => state)

  const [transaction, setTransaction] = useState(null)
  const [sellerName, setSellerName] = useState('')
  const [buyerName, setBuyerName] = useState('')
  const [saleName, setSaleName] = useState('')

  usePageTitle(title, transactionId, saleName)
  useEffect(() => {
    if (!permissionsAdminCache.size) return
    Promise.all(hasAdmin(transactionActions.getAdminTransactions)
      ? [
        transactionActions.getAdminTransactions({ id: transactionId, columns: '[AdvFile, AxpFile, Rough, PolishedStones]' })
      ]
      : [
        transactionActions.getBuyerTransactions({ id: transactionId, columns: '[AdvFile, AxpFile, Rough, PolishedStones]' }),
        transactionActions.getSellerTransactions({ id: transactionId, columns: '[AdvFile, Rough]' })
      ]
    )
    .then(async response => {
      const transactionData = hasAdmin(transactionActions.getAdminTransactions) ? response[0] : response.find(txns => txns.data.recordCount)
      if (!transactionData) throw new Error('Transaction not found')
      const dataWithLinks = transactionData.data.data[0]
      if (dataWithLinks?.Rough?.File?.id && !dataWithLinks?.Rough?.File?.url) {
        dataWithLinks.Rough.File.url = (await reportActions.getDownloadUrl(
          [dataWithLinks.Rough.File.id],
          { roughId: dataWithLinks.Rough.id }
        )).data.data[0].url
      }
      if (dataWithLinks?.SPSRequest?.AdvFile?.id && !dataWithLinks?.SPSRequest?.AdvFile?.url) {
        const advLinks = (await reportActions.getDownloadUrl(
          [dataWithLinks.SPSRequest.AdvFile.id, dataWithLinks.SPSRequest.AxpFile.id],
          { spsRequestId: dataWithLinks.SPSRequest.id }
        )).data.data
        dataWithLinks.SPSRequest.AdvFile.url = advLinks.find(file => file.id == dataWithLinks.SPSRequest.AdvFile.id)?.url
        dataWithLinks.SPSRequest.AxpFile.url = advLinks.find(file => file.id == dataWithLinks.SPSRequest.AxpFile.id)?.url
      }

      setTransaction(dataWithLinks)
    })
    .catch(console.error)

    getRoughStatuses()
    getRoughColours()
    getRoughFluorescences()
    getRoughTinges()
    getRoughScanTypes()
  }, [permissionsAdminCache])

  const ordersParams = {
    buyerId: transaction?.buyerId,
    columns: '[id, name, OrderLines]'
  }
  const {
    ordersList: { [ordersParams ? JSON.stringify(ordersParams) : 'all']: ordersList },
    getOrdersList
  } = useOrderStore(store => store)
  const orders = useMemo(() => (ordersList?.data || []).reduce((obj, order) => ({
    ...obj,
    [order.id]: { id: order.id, name: order.name }
  }), {})
  , [ordersList])

  function getRoughMeasurementInfoTip(roughStone, key, keyLabel) {
    const roughKeyMapping = {
      colour: roughColoursMap,
      // fluorescence: roughFluorescencesMap,
      tinge: roughTingesMap
    }[key]

    const body = []
    const measurements = roughStone?.measurements?.map((meas) => roughKeyMapping ? roughKeyMapping[meas?.[key]] : meas?.[key])
    if (!measurements || !measurements.length) return body
    // Yehuda Measurements
    body.push(<div key="yehudaMeas"><b key="yehudaMeasLabel">Yehuda {keyLabel}s:</b> {measurements.join(', ')}</div>)
    // Eye Measurement
    if (roughStone?.eyeMeasurement?.[key] != null) body.push(<div key="eyeMeas"><b key="eyeMeasLabel">Eye {keyLabel}:</b>{' '}{roughKeyMapping ? roughKeyMapping[roughStone?.eyeMeasurement?.[key]] : roughStone?.eyeMeasurement?.[key]}</div>)
    // Override Measurement
    if (roughStone?.overrideMeasurement?.[key] != null) body.push(<div key="overrideMeas"><b key="overrideMeasLabel">Override {keyLabel}:</b>{' '}{roughKeyMapping ? roughKeyMapping[roughStone?.overrideMeasurement?.[key]] : roughStone?.overrideMeasurement?.[key]}</div>)
    return body
  }

  useEffect(() => {
    if (transaction) {
      // Get all orders for this buyer
      if (transaction.buyerId) {
        getOrdersList(ordersParams)
      }

      // get seller name
      getOrgById(transaction.sellerId)
      .then(response => { setSellerName(response?.commonName || '') })

      // get buyer name
      getOrgById(transaction.buyerId)
      .then(response => { setBuyerName(response?.commonName || '') })

      // get sale name
      saleActions.getSales({ id: transaction.saleId })
      .then(response => setSaleName(response?.data?.data[0]?.name || ''))

      if (transaction.Rough?.countryId) getCountriesList()
      if (transaction.Rough?.mineId) getMinesList()
      if (transaction.Rough?.pipeId) getPipesList()
      if (transaction.Rough?.batchId) getBatchesList()
      if (transaction.Rough?.inclusionsTypeId) getInclusionTypes()
    }
  }, [transaction])

  const [fields, setFields] = useState([])
  useEffect(() => {
    if (transaction) {
      setFields([
        {
          legend: 'Transaction',
          componentName: 'fieldset',
          name: 'transactionDetails',
          span: false,
          children: [{
            name: 'sale',
            label: 'Sale',
            value: saleName
          },
          {
            span: true,
            name: 'transactionDate',
            label: 'Date Sold',
            value: textUtils.formatDate(transaction?.transactionDate)
          },
          {
            name: 'seller',
            label: 'Seller',
            value: sellerName,
            shouldDisplay: sellerName
          },
          {
            name: 'buyer',
            label: 'Buyer',
            value: buyerName,
            shouldDisplay: buyerName
          },
          {
            name: 'soldPrice',
            label: 'Sold Price ',
            value: transaction?.sellerPrice,
            type: 'currency',
            shouldDisplay: transaction?.sellerPrice
          },
          {
            name: 'purchasePrice',
            label: 'Purchase Price ',
            value: transaction?.buyerPrice,
            type: 'currency',
            shouldDisplay: transaction?.buyerPrice
          },
          {
            name: 'paid',
            label: 'Paid ',
            value: transaction?.paidDate ? 'Yes' : 'No',
            shouldDisplay: transaction && 'paidDate' in transaction
          },
          {
            name: 'paidDate',
            label: 'Paid Date',
            value: transaction?.paidDate ? textUtils.formatDate(transaction?.paidDate) : '-',
            shouldDisplay: transaction && 'paidDate' in transaction
          },
          {
            name: 'shipped',
            label: 'Shipped ',
            value: transaction?.shippedDate ? 'Yes' : 'No',
            shouldDisplay: transaction && 'shippedDate' in transaction
          },
          {
            name: 'shippedDate',
            label: 'Shipped Date',
            value: transaction?.shippedDate ? textUtils.formatDate(transaction?.shippedDate) : '-',
            shouldDisplay: transaction && 'shippedDate' in transaction
          },
          {
            name: 'cancelled',
            label: 'Cancelled',
            value: transaction?.cancelledDate ? 'Yes' : 'No',
            shouldDisplay: transaction && transaction?.cancelledDate
          },
          {
            name: 'cancelledDate',
            label: 'Cancelled Date',
            value: transaction?.cancelledDate ? textUtils.formatDate(transaction?.cancelledDate) : '-',
            shouldDisplay: transaction && transaction?.cancelledDate
          },
          {
            span: true,
            name: 'cancelledReason',
            label: 'Cancelled Reason',
            componentName: 'textarea',
            value: transaction?.cancelledReason,
            shouldDisplay: transaction?.cancelledDate
          },
          {
            span: true,
            name: 'polishedStones',
            label: 'Polished Stones',
            customComponent: PolishedStoneList,
            value: transaction?.PolishedStones,
            polishedStones: transaction?.PolishedStones,
            orders,
            shouldDisplay: hasAdmin(transactionActions.getAdminTransactions) || transaction?.buyerId === orgId
          }]
        },
        {
          legend: 'Rough Stone',
          componentName: 'fieldset',
          name: 'transactionDetails',
          span: false,
          children: [
            (
              transaction.sellerId
                ? {
                  name: 'roughStoneId',
                  componentName: 'navLink',
                  label: 'Clara ID',
                  text: transaction?.roughId,
                  url: `/roughstones/${transaction?.roughId}`
                }
                : {
                  name: 'roughStoneId',
                  label: 'Clara ID',
                  value: transaction?.roughId
                }
            ),
            {
              label: 'Stone Name',
              name: 'sellerStoneName',
              value: transaction?.Rough?.sellerStoneName,
              componentName: 'textInput',
              shouldDisplay: transaction?.Rough?.sellerStoneName
            },
            {
              label: 'Status',
              name: 'status',
              value: transaction?.Rough?.status,
              componentName: 'dropdown',
              options: roughStatuses?.map(
                status => ({
                  value: status.value,
                  label: status.description
                })),
              shouldDisplay: transaction?.Rough?.status
            },
            {
              label: 'Assortment',
              name: 'assortment',
              value: transaction?.Rough?.Assortment?.name,
              componentName: 'textInput',
              shouldDisplay: transaction?.Rough?.Assortment?.name
            },
            {
              label: 'Weight',
              name: 'weight',
              value: transaction?.Rough?.weight,
              componentName: 'textinput',
              shouldDisplay: transaction?.Rough?.weight
            },
            {
              label: 'Weight Category',
              name: 'weightCategory',
              value: transaction?.Rough?.weightCategory,
              componentName: 'textInput',
              shouldDisplay: transaction?.Rough?.weightCategory
            },
            {
              label: 'Price point',
              name: 'pricePoint',
              value: transaction?.Rough?.pricePoint,
              componentName: 'textInput',
              shouldDisplay: transaction?.Rough?.pricePoint
            },
            {
              label: 'Country',
              name: 'countryId',
              componentName: 'dropdown',
              options: countriesList?.map(i => ({ label: i.name, value: i.id })),
              value: transaction?.Rough?.countryId,
              shouldDisplay: transaction?.Rough?.countryId
            },
            {
              label: 'Mine',
              value: transaction?.Rough?.mineId,
              componentName: 'dropdown',
              options: minesList?.map(i => ({ label: i.name, value: i.id })),
              name: 'mineId',
              shouldDisplay: transaction?.Rough?.mineId
            },
            {
              label: 'Mine Pipe',
              value: transaction?.Rough?.pipeId,
              componentName: 'dropdown',
              options: pipesList?.map(pipe => ({ label: pipe.name, value: pipe.id })),
              name: 'pipeId',
              shouldDisplay: transaction?.Rough?.pipeId
            },
            {
              label: 'Mine Batch',
              value: transaction?.Rough?.batchId,
              componentName: 'dropdown',
              options: batchesList?.map(i => ({ label: i.name, value: i.id })),
              name: 'batchId',
              shouldDisplay: transaction?.Rough?.batchId
            },
            {
              label: 'Override Price ($/ct)',
              name: 'reservePpc',
              value: transaction?.Rough?.reservePpc,
              type: 'currency',
              componentName: 'textInput',
              shouldDisplay: transaction?.Rough?.reservePpc
            },
            {
              label: 'Total Price',
              componentName: 'textInput',
              type: 'currency',
              value: Number(transaction?.Rough?.reservePpc) * Number(transaction?.Rough?.weight),
              shouldDisplay: transaction?.Rough?.reservePpc
            },
            {
              legend: 'Measurements',
              componentName: 'fieldset',
              name: 'meas',
              span: false,
              shouldDisplay: hasAdmin(transactionActions.getAdminTransactions) || transaction?.sellerId === orgId,
              children: [
                {
                  label: 'Color',
                  componentName: 'dropdown',
                  name: 'transactionDetailsMeasurementColour',
                  value: transaction?.Rough?.colour,
                  options: roughColours?.map(i => ({ label: i.description, value: i.value })),
                  shouldDisplay: transaction?.Rough?.colour != null,
                  infoTip: getRoughMeasurementInfoTip(transaction?.Rough, 'colour', 'Color')
                },
                {
                  label: 'Fluorescence',
                  componentName: 'dropdown',
                  name: 'transactionDetailsMeasurementFluor',
                  value: transaction?.Rough?.fluorescence,
                  options: roughFluorescences?.map(i => ({ label: i.value, value: i.value })),
                  shouldDisplay: transaction?.Rough?.fluorescence != null,
                  infoTip: getRoughMeasurementInfoTip(transaction?.Rough, 'fluorescence', 'Fluorescence')
                },
                {
                  label: 'Tinge',
                  componentName: 'dropdown',
                  name: 'transactionDetailsMeasurementTinge',
                  value: transaction?.Rough?.tinge,
                  options: tinges?.map(i => ({ label: i.description, value: i.value })),
                  shouldDisplay: transaction?.Rough?.tinge != null,
                  infoTip: getRoughMeasurementInfoTip(transaction?.Rough, 'tinge', 'Tinge')
                }
              ]
            },
            {
              label: 'Inclusion Type',
              componentName: 'dropdown',
              name: 'inclusionsTypeId',
              value: transaction?.Rough?.inclusionsTypeId,
              options: inclusionTypes?.map(i => ({ label: i.description, value: i.id })),
              infoTip: true,
              shouldDisplay: transaction?.Rough?.inclusionsTypeId
            },
            {
              label: 'Scan Type',
              name: 'scanType',
              componentName: 'dropdown',
              value: transaction?.Rough?.scanType,
              options: roughScanTypes?.map(i => ({ label: i.description, value: i.value })),
              shouldDisplay: transaction?.Rough?.scanType
            },
            {
              label: 'Rough Adv',
              text: fileUtils.getFileName(transaction?.Rough?.File, { shorten: true }),
              componentName: 'downloadLink',
              name: 'fileUrl',
              url: transaction?.Rough?.File?.url,
              shouldDisplay: transaction?.Rough?.File?.url
            },
            {
              label: 'Polished Adv',
              text: fileUtils.getFileName(transaction?.SPSRequest?.AdvFile, { shorten: true }),
              componentName: 'downloadLink',
              name: 'advFileUrl',
              url: transaction?.SPSRequest?.AdvFile?.url,
              shouldDisplay: transaction?.SPSRequest?.AdvFile?.url
            },
            {
              label: 'Polished Axp',
              text: fileUtils.getFileName(transaction?.SPSRequest?.AxpFile, { shorten: true }),
              componentName: 'downloadLink',
              name: 'axpFileUrl',
              url: transaction?.SPSRequest?.AxpFile?.url,
              shouldDisplay: transaction?.SPSRequest?.AxpFile?.url
            },
            {
              label: 'Created At',
              value: textUtils.formatDate(transaction?.Rough?.createdAt),
              shouldDisplay: transaction?.Rough?.sellerStoneName
            },
            {
              label: 'Last Updated',
              value: textUtils.formatDate(transaction?.Rough?.updatedAt),
              shouldDisplay: transaction?.Rough?.sellerStoneName
            }
          ]
        }
      ])
    }
  }, [transaction, countriesList, minesList, pipesList, batchesList, roughColours, tinges, roughStatuses, roughScanTypes, inclusionTypes, buyerName, sellerName, orders, saleName, roughColoursMap, roughTingesMap, roughFluorescencesMap, permissionsAdminCache])
  return (
    <div className='transaction-details center'>
      <DetailsPage
        fields={fields}
        canEdit={false}
        title={{
          label: 'Transaction ID',
          value: transaction?.id || ''
        }}
        shortenId={false}
      />
    </div>
  )
}

TransactionDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string
}

export default TransactionDetails
