import React, { useState } from 'react'
import { Button } from '@atoms'
import { TextInput, Dropdown } from '@molecules'
import { useOrderStore } from '@stores'

function PpcPainter() {
  const [newPrice, setNewPrice] = useState('')
  const [adjustValue, setAdjustValue] = useState('')
  const [adjustMethod, setAdjustMethod] = useState(null)

  const {
    activeRange,
    setActiveRange,
    painterValue,
    setPainterValue,
    isPainterEnabled,
    setIsPainterEnabled
  } = useOrderStore(state => state)

  const {
    priceRange,
    colours,
    clarities
  } = activeRange

  if (!priceRange) return null
  const { pricingMethod } = priceRange

  const discountAdjustmentOptions = [
    {
      value: 'multiply',
      label: 'Multiply (-45 * 0.9 = -40.5)'
    },
    {
      value: 'add',
      label: 'Add (-45 + 5 = -40)'
    },
    {
      value: 'percentage',
      label: 'Percent (-45 + 5% = -42.25)'
    }
  ]

  const ppcAdjustmentOptions = [
    {
      value: 'multiply',
      label: 'Multiply (1250 * 1.1 = 1375)'
    },
    {
      value: 'add',
      label: 'Add (1250 + 75 = 1325)'
    },
    {
      value: 'percentage',
      label: 'Percent (1250 + 5% = 1312.5)'
    }
  ]

  const adjustmentOptions = pricingMethod === 'ppc' ? ppcAdjustmentOptions : discountAdjustmentOptions

  function onSetAllPrices() {
    colours.forEach(colour => {
      clarities.forEach(clarity => {
        const priceField = findPriceField(colour, clarity)

        if (pricingMethod === 'ppc') {
          if (priceField) {
            priceField.ppc = newPrice
          } else {
            if (!priceRange.priceTable) priceRange.priceTable = []
            priceRange.priceTable.push({
              colour: colour.value,
              clarity: clarity.value,
              ppc: newPrice
            })
          }
        } else if (pricingMethod === 'discount') {
          if (priceField) {
            priceField.percentage = newPrice
          } else {
            if (!priceRange.discountTable) priceRange.discountTable = []
            priceRange.discountTable.push({
              clarity: clarity.value,
              colour: colour.value,
              percentage: newPrice
            })
          }
        }
      })
    })

    setActiveRange({ ...activeRange, priceRange })
  }

  function onSetBlanksPrices() {
    colours.forEach(colour => {
      clarities.forEach(clarity => {
        const priceField = findPriceField(colour, clarity)

        if (pricingMethod === 'ppc') {
          if (!priceField) {
            if (!priceRange.priceTable) priceRange.priceTable = []
            priceRange.priceTable.push({
              colour: colour.value,
              clarity: clarity.value,
              ppc: newPrice
            })
          } else {
            if (priceField.ppc == null || priceField.ppc === '') {
              priceField.ppc = newPrice
            }
          }
        } else if (pricingMethod === 'discount') {
          if (!priceField) {
            if (!priceRange.discountTable) priceRange.discountTable = []
            priceRange.discountTable.push({
              clarity: clarity.value,
              colour: colour.value,
              percentage: newPrice
            })
          } else {
            if (priceField.percentage == null || priceField.percentage === '') {
              priceField.percentage = newPrice
            }
          }
        }
      })
    })

    setActiveRange({ ...activeRange, priceRange })
  }

  function onAdjustAllPrices () {
    if (!adjustMethod) return

    function getAdjustedPrice(currentPrice) {
      let adjustedPrice = null
      if (adjustMethod === 'multiply') {
        adjustedPrice = Number(currentPrice) * Number(adjustValue)
      } else if (adjustMethod === 'add') {
        adjustedPrice = Number(currentPrice) + Number(adjustValue)
      } else if (adjustMethod === 'percentage') {
        if (pricingMethod === 'ppc') {
          adjustedPrice = Number(currentPrice) * (1 + Number(adjustValue) / 100)
        } else {
          adjustedPrice = Number(currentPrice) + Number(adjustValue) + Number(currentPrice) * Number(adjustValue) / 100
        }
      }

      return adjustedPrice
    }

    colours.forEach(colour => {
      clarities.forEach(clarity => {
        const priceField = findPriceField(colour, clarity)
        if (priceField == null) return
        if (pricingMethod === 'ppc') {
          priceField.ppc = getAdjustedPrice(priceField.ppc)
        } else if (pricingMethod === 'discount') {
          priceField.percentage = getAdjustedPrice(priceField.percentage)
        }
      })
    })

    setActiveRange({ ...activeRange, priceRange })
  }

  function findPriceField(colour, clarity) {
    if (pricingMethod === 'ppc') {
      return activeRange.priceRange.priceTable?.find(pt => pt.colour === colour.value && pt.clarity === clarity.value) || null
    } else {
      return activeRange.priceRange.discountTable?.find(pt => pt.colour === colour.value && pt.clarity === clarity.value) || null
    }
  }

  return (
    <div >
      <span className="p2">
                Set a new price to all prices or blanks.
      </span>
      <div className='price-painter__new-price'>
        <div>
          <TextInput
            type="number"
            label='New Price'
            name='newPrice'
            value={newPrice}
            onChange={e => setNewPrice(e.currentTarget.value)}
          />
        </div>
        <div className="price-painter__new-price-buttons--no-margin">
          <Button
            typeVariant='secondary'
            size='sm'
            onClick={() => onSetBlanksPrices()}
          >
                  Fill Blanks
          </Button>
          <Button
            typeVariant='secondary'
            size='sm'
            onClick={() => onSetAllPrices()}
          >
                  Set All
          </Button>
        </div>
      </div>
      <span className="p2">
                Enable painter to manually set a new price.
      </span>
      <div className='price-painter__paint-value'>
        <div>
          <TextInput
            type="number"
            label='Paint Value'
            name='paintvalue'
            value={painterValue}
            disabled={!isPainterEnabled}
            onChange={e => setPainterValue(e.currentTarget.value)}
          />
        </div>
        <Button
          typeVariant='secondary'
          size='sm'
          onClick={() => {
            if (isPainterEnabled) {
              setPainterValue(null)
            }
            setIsPainterEnabled(!isPainterEnabled)
          }}
        >
          {`${isPainterEnabled ? 'Disable Painter' : 'Enable Painter'}`}
        </Button>
      </div>
      <span className="p2">
                Adjust all prices by percentage, adding or multiplying a new value.
      </span>
      <div className='price-painter__adjust-value'>
        <div>
          <TextInput
            type="number"
            label='Adjust Value'
            name='adjustValue'
            value={adjustValue}
            onChange = {e => setAdjustValue(e.currentTarget.value)}
          />
        </div>
        <div className='price-painter__adjust-all-prices'>
          <Dropdown
            name='adjustAllPrices'
            value={adjustMethod}
            onChange={e => setAdjustMethod(e.currentTarget.value)}
            options ={adjustmentOptions}
          />
        </div>
        <Button
          size='sm'
          typeVariant='secondary'
          onClick={() => onAdjustAllPrices()}
        >
                  Adjust All Prices
        </Button>
      </div>
    </div>
  )
}

export default PpcPainter
