import React, { useState } from 'react'
import { FileUpload, Table } from '@organisms'
import { Button } from '@atoms'
import { Modal } from '@templates'
import { fileUtils } from '@utils'
import Excel from 'exceljs/dist/es5/exceljs.browser'

function PriceUploadModal({
  open,
  onSubmit,
  onClose,
  ...props
}) {
  const [fileName, setFileName] = useState('')
  const [parsedStones, setParsedStones] = useState({})
  const [parseError, setParseError] = useState(null)

  async function handleFile(file) {
    setParseError(null)
    if (!file) {
      setFileName('')
      setParsedStones([])
    }
    setFileName(file.name)
    try {
      const fileBody = await fileUtils.promiseReadBinaryFile(file)
      const book = new Excel.Workbook()
      await book.xlsx.load(fileBody)
      const sheet = book.worksheets[0]
      let headerRow = null
      sheet.eachRow((row, num) => {
        if (headerRow === null && row.getCell('A').text.toLowerCase() === 'clara id') headerRow = num
      })
      sheet.getRow(headerRow).eachCell((cell, colNum) => {
        const cellVal = cell.text.toLowerCase().trim()
        const col = sheet.getColumn(colNum)
        if (cellVal === 'clara id') col.key = 'rough_id'
        if (cellVal === 'override price ($/ct)' || cellVal === 'override price') col.key = 'reserve_price_override'
      })
      if (!sheet.columns.find(col => col.key === 'rough_id') || !sheet.columns.find(col => col.key === 'reserve_price_override')) {
        throw new Error('Excel file does not contain the required columns. Check that your headers include "clara id" and "override price".')
      }
      const toUpdate = {}
      sheet.eachRow((row, num) => {
        if (num <= headerRow) return
        if (!row.getCell('rough_id').text || !row.getCell('reserve_price_override').text) return
        toUpdate[row.getCell('rough_id').text] = row.getCell('reserve_price_override').text
      })
      setParsedStones(toUpdate)
    } catch (err) {
      console.error(err)
      setParseError(err?.message || err)
      setFileName('')
    }
  }

  function handleSubmit() {
    onSubmit(parsedStones)
    .then(() => onClose())
    .catch(() => { })
  }

  const saveable = Object.keys(parsedStones).length

  return <Modal
    open={open}
    title='Upload New Reserve Prices'
    onClose={onClose}
  >
    <div className='upload-prices-modal__container'>
      <FileUpload
        name='priceExcel'
        label='The uploaded file must include two columns: "clara id" and "override price".'
        onChange={handleFile}
        acceptTypes={['.xlsx']}
        fileName={fileName}
      />

      {parseError && <div className='error'>{parseError}</div>}

      {saveable ? <Table
        title='Preview'
        data={Object.entries(parsedStones)}
        columns={[
          { Header: 'Clara ID', accessor: '0' },
          { Header: 'New Price', accessor: '1' }
        ]}
        isMultiSelect={false}
      /> : null}

      <div className='modal__buttons'>
        <Button typeVariant='secondary' size='sm' onClick={onClose}>Cancel</Button>
        <Button typeVariant='primary' size='sm' disabled={!saveable} onClick={handleSubmit}>Save</Button>
      </div>
    </div>
  </Modal>
}

export default PriceUploadModal
