import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useAuthStore, useGlobalsStore } from '@stores'
import { provenanceActions } from '@actions'
import moment from 'moment'
import { DetailsPage } from '@templates'
import { usePageTitle, useToast } from '@hooks'
import { useHistory } from 'react-router-dom'
import { textUtils } from '@utils'

function BatchDetails({ match, title }) {
  const { batchId, operation } = match?.params

  const { orgId: userOrgId, hasAdmin, permissionsAdminCache } = useAuthStore(state => state)
  const provParams = hasAdmin(provenanceActions.getPipes) ? null : { sellerId: userOrgId }

  const {
    orgsList: { all: orgsList },
    getOrgsList,
    getPipesList,
    resetBatchesList,
    pipesList: { [provParams ? JSON.stringify(provParams) : 'all']: pipes = [] }
  } = useGlobalsStore()

  const { showSuccessToast } = useToast()
  const history = useHistory()

  const [batch, setBatch] = useState({})
  usePageTitle(title, batchId, batch?.name)
  useEffect(() => {
    if (batchId) {
      provenanceActions.getBatchById(batchId)
      .then(setBatch)
      .catch(err => console.error(err))
    } else setBatch({})
  }, [batchId])

  useEffect(() => {
    if (!permissionsAdminCache.size) return
    getPipesList(provParams)
    .catch(err => console.error(err))

    getOrgsList()
  }, [permissionsAdminCache])

  const [fields, setFields] = useState([])
  useEffect(() => {
    if (batch) {
      setFields([
        {
          label: 'Name',
          value: batch.name,
          name: 'name',
          componentName: 'textInput',
          canEdit: true
        },
        {
          label: 'Pipe',
          value: batch.pipeId,
          name: 'pipeId',
          componentName: 'dropdown',
          options: pipes.map(pipe => ({ value: pipe.id, label: pipe.name })),
          canEdit: false
        },
        {
          label: 'Condition',
          value: batch.condition,
          name: 'condition',
          componentName: 'dropdown',
          options: [
            { value: 'ACTIVE', label: 'Active' },
            { value: 'ARCHIVED', label: 'Archived' }
          ],
          canEdit: true
        },
        {
          label: 'Seller',
          value: batch.sellerId,
          name: 'sellerId',
          componentName: 'dropdown',
          options: orgsList?.map(org => ({ value: org.orgId, label: org.commonName })),
          canEdit: false
        },
        {
          label: 'Start Date',
          value: textUtils.formatDate(batch.dateStart),
          name: 'dateStart',
          componentName: 'textInput',
          type: 'datetime-local',
          canEdit: true
        },
        {
          label: 'End Date',
          value: textUtils.formatDate(batch.dateEnd),
          name: 'dateEnd',
          componentName: 'textInput',
          type: 'datetime-local',
          canEdit: true
        },
        {
          label: 'Created At',
          value: moment(batch.createdAt).toLocaleString()
        },
        {
          label: 'Last Updated',
          value: moment(batch.updatedAt).toLocaleString()
        }
      ])
    }
  }, [batch, pipes, orgsList])

  async function handleOnSubmit(editedValues) {
    const { condition, ...batchData } = editedValues

    const conditionPromise = condition ? provenanceActions.setBatchCondition(batchId, condition) : Promise.resolve()
    return conditionPromise.then(() => {
      if (Object.keys(batchData).length) {
        if ('dateStart' in batchData) batchData.dateStart = moment(batchData.dateStart).toISOString(true)
        if ('dateEnd' in batchData) batchData.dateEnd = moment(batchData.dateEnd).toISOString(true)
        return provenanceActions.editBatch({ id: batchId, ...batchData })
      }
    })
    .then(() => {
      showSuccessToast('Batch updated.')
      resetBatchesList()
      history.push('/seller-settings')
    })
  }

  function handleArchive() {
    provenanceActions.setBatchCondition(batch?.id, 'ARCHIVED')
    .then(() => {
      showSuccessToast('Batch has been archived.')
      resetBatchesList()
      history.push('/seller-settings')
    })
  }

  function handleUnarchive() {
    provenanceActions.setBatchCondition(batch?.id, 'ACTIVE')
    .then(() => {
      showSuccessToast('Batch has been unarchived.')
      resetBatchesList()
      history.push('/seller-settings')
    })
  }

  function handleRemove() {
    provenanceActions.setBatchCondition(batch?.id, 'DELETED')
    .then(() => {
      showSuccessToast('Batch has been removed.')
      resetBatchesList()
      history.push('/seller-settings')
    })
  }

  const canArchive = batch?.condition !== 'ARCHIVED' && batch?.condition !== 'DELETED'
  const canUnarchive = batch?.condition === 'ARCHIVED'
  const canRemove = batch?.condition !== 'DELETED'
  const canEdit = batch?.condition === 'ACTIVE'

  return (
    <DetailsPage
      canEdit={canEdit}
      isEdit={operation === 'edit'}
      fields={fields}
      onArchive={canArchive ? handleArchive : null}
      onUnarchive={canUnarchive ? handleUnarchive : null}
      onRemove={canRemove ? handleRemove : null}
      onSubmit={handleOnSubmit}
      title={{
        label: 'Batch ID',
        value: batch?.id || ''
      }}
    />
  )
}

BatchDetails.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string
}

export default BatchDetails
