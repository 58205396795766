import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Table } from '@organisms'
import { NavLink } from 'react-router-dom'
import { logisticsActions, saleActions } from '@actions'
import { useGlobalsStore } from '@stores'
import { textUtils, arrayUtils } from '@utils'
import moment from 'moment'
import EditPaymentsModal from './editPaymentsModal'
import InvoicePaymentConditionModal from './invoicePaymentConditionModal'
import PaymentReportModal from './paymentsReportModal'

const CONDITIONS = {
  activeInvoice: { id: 'active', label: 'Active Invoices', value: 'ACTIVE' },
  archivedInvoice: { id: 'archived', label: 'Archived Invoices', value: 'ARCHIVED' },
  allInvoice: { id: 'any', label: 'All Invoices', value: '' }
}

function PaymentList({ invoice, conditions, canEditPayments }) {
  const invoiceId = invoice?.id

  const {
    orgsList: { all: orgsList },
    orgsMap: { all: orgsMap },
    getOrgsList
  } = useGlobalsStore(store => store)
  const showTabs = conditions.filter(conKey => CONDITIONS[conKey]).length > 1
  const tableTabs = conditions.map(conKey => ({
    id: CONDITIONS[conKey].id,
    label: CONDITIONS[conKey].label,
    params: CONDITIONS[conKey].value
  }))

  const [invoiceList, setInvoiceList] = useState([])
  const [openModal, setOpenModal] = useState(null)
  const [activeTab, setActiveTab] = useState(tableTabs[0])
  const [salesMap, setSalesMap] = useState({})

  const fullOrgsMap = useMemo(() => arrayUtils.toMap(orgsList || [], x => x.orgId, org => ({
    ...org,
    billingEntity: org.address?.find(entity => entity.billing && (!entity.condition || entity.condition === 'ACTIVE'))
  })), [orgsList])

  const paymentList = useMemo(() => invoiceList.reduce((payments, invoice) => payments.concat(invoice.Payments.map(payment => {
    const billingEntity = fullOrgsMap[payment.buyerId]?.billingEntity
    return ({
      ...payment,
      kycBanks: [billingEntity?.invoicePayment?.primaryBank, billingEntity?.invoicePayment?.secondaryBank].filter(x => x).join(' / '),
      Invoice: invoice
    })
  })), []), [invoiceList, fullOrgsMap])

  useEffect(() => {
    getOrgsList()
    getInvoiceList(activeTab.params)
    saleActions.getSales({ status: 'COMPLETED', columns: '[id, name, eventGroupId, status, updatedAt, buyers, sellers, events]' })
    .then(result => {
      setSalesMap(arrayUtils.toMap(
        result.data.data.sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt)),
        sale => sale.id,
        sale => `${sale.name}${sale.Events?.length && sale.Events[0].endTime ? ` | ${textUtils.formatDate(sale.Events[0].endTime, 'MMM DD, YYYY')}` : ''}`
      ))
    })
    .catch(console.error)
  }, [invoice])

  async function getInvoiceList (condition) {
    const params = {}
    if (condition) params.condition = condition
    if (invoiceId) params.id = invoiceId
    return logisticsActions.getInvoices({ ...params, columns: '[Payments]' })
    .then(results => setInvoiceList(results.data.data))
  }

  const columns = useMemo(() => (
    [
      {
        Header: 'Payment ID',
        accessor: 'id',
        dataType: 'string',
        Cell: cellInfo => {
          return <NavLink
            className='link'
            to={`/finance/payments/${cellInfo.row.original.id}`}
            id={cellInfo.row.original.id}>
            {textUtils.shortenUUID(cellInfo.value)}
          </NavLink>
        }
      },
      {
        Header: 'Invoice Number',
        id: 'invoiceNumber',
        accessor: row => row?.Invoice?.invoiceNumber || '',
        dataType: 'string'
      },
      {
        Header: 'Sale',
        id: 'saleName',
        accessor: row => salesMap?.[row.saleId],
        dataType: 'string'
      },
      {
        Header: 'Buyer',
        id: 'buyerId',
        accessor: row => orgsMap?.[row.buyerId],
        dataType: 'string'
      },
      {
        Header: 'Seller',
        id: 'sellerId',
        accessor: row => orgsMap?.[row.sellerId],
        dataType: 'string'
      },
      {
        Header: 'Stones',
        accessor: 'stoneCount',
        dataType: 'number',
        filterType: 'numberRange'
      },
      {
        Header: 'Weight',
        accessor: 'caratWeight',
        dataType: 'number',
        filterType: 'numberRange'
      },
      {
        Header: 'Stone Value',
        accessor: 'stonesTotal',
        dataType: 'currency',
        filterType: 'numberRange'
      },
      {
        Header: 'Galaxy Fees',
        accessor: 'galaxyFees',
        dataType: 'currency',
        filterType: 'numberRange'
      },
      {
        Header: 'Expenses',
        accessor: 'expenses',
        dataType: 'currency',
        filterType: 'numberRange'
      },
      {
        Header: 'Taxes',
        accessor: 'tax',
        dataType: 'currency',
        filterType: 'numberRange'
      },
      {
        Header: 'Total',
        id: 'totalValue',
        dataType: 'currency',
        accessor: row => Math.round((Number(row.stonesTotal ?? 0) + Number(row.galaxyFees ?? 0) + Number(row.expenses ?? 0) + Number(row.tax ?? 0)) * 100) / 100,
        filterType: 'numberRange'
      },
      {
        Header: 'Notes',
        accessor: 'notes',
        dataType: 'string'
      },
      {
        Header: 'Payment Date',
        accessor: 'paymentDate',
        dataType: 'date',
        filterType: 'date'
      },
      {
        Header: 'Sending Bank',
        accessor: 'sendingBank',
        dataType: 'string'
      },
      {
        Header: 'Banks on KYC',
        accessor: 'kycBanks',
        dataType: 'string'
      },
      {
        Header: 'Receiving Bank',
        accessor: 'receivingBank',
        dataType: 'string',
        filterType: 'checkbox'
      },
      {
        Header: 'FX (CAD)',
        accessor: 'cadExchange',
        dataType: 'number',
        filterType: 'numberRange'
      },
      {
        Header: 'FX (BWP)',
        accessor: 'bwpExchange',
        dataType: 'number',
        filterType: 'numberRange'
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        dataType: 'date',
        filterType: 'date'
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
        dataType: 'date',
        filterType: 'date'
      }
    ]
  ), [orgsMap, salesMap])

  const topBarActions = [
    {
      componentName: 'dropdown',
      enableOnSelect: true,
      options: [
        {
          label: 'Edit payments',
          callback: selectedRows => setOpenModal({
            name: 'editPayments',
            selectedRows,
            onClose: () => getInvoiceList(activeTab.params).then(() => setOpenModal(null))
          }),
          value: 'editPayments',
          isDisabled: !(canEditPayments && ['active', 'any'].includes(activeTab.id))
        }
      ]
    },
    {
      label: 'Payments Report',
      callback: () => setOpenModal(prev => ({ ...prev, name: 'generatePaymentReport' }))
    }
  ]

  return (
    <>
      <Table
        title="Payments"
        data={paymentList}
        columns={columns}
        tableTabs={showTabs ? tableTabs : []}
        onTabChange={setActiveTab}
        getDataCallback={getInvoiceList}
        topBarActions={topBarActions}
      />
      <EditPaymentsModal
        open={openModal?.name === 'editPayments'}
        payments={openModal?.selectedRows}
        orgsMap={orgsMap}
        salesMap={salesMap}
        onClose={async (shouldFetch) => {
          if (shouldFetch) await openModal.onClose()
          setOpenModal(prev => ({ ...prev, name: '' }))
        }}
      />
      <InvoicePaymentConditionModal
        open={openModal?.name === 'setPaymentCondition'}
        payments={openModal?.selectedRows?.map(row => row?.Payments)}
        operation={openModal?.operation}
        action={openModal?.action}
        salesMap={salesMap}
        orgsMap={orgsMap}
        handleOnClose={async () => {
          await openModal.onClose()
          setOpenModal(null)
        }}
      />
      <PaymentReportModal
        open={openModal?.name === 'generatePaymentReport'}
        onClose={() => setOpenModal(prev => ({ ...prev, name: '' }))}
      />
    </>
  )
}

PaymentList.propTypes = {
  invoice: PropTypes.object,
  conditions: PropTypes.array,
  canEditPayments: PropTypes.bool
}

PaymentList.defaultProps = {
  conditions: ['activeInvoice', 'archivedInvoice'],
  canEditPayments: true
}

export default PaymentList
