import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import OrderPricingRange from './orderPricingRange'
import OrderPricingRangesSettings from './orderPricingRangesSettings'
import PricePainter from './pricePainter/pricePainter'
import { Button, Icon } from '@atoms'
import { useGlobalsStore, useModalStore, useOrderStore } from '@stores'
import { useToast } from '@hooks'
import { validationUtils, numberUtils, objectUtils } from '@utils'
import { v4 as uuidv4 } from 'uuid'
import { ErrorIcon } from '@molecules'

const gridSizeTemplate = { maxColour: null, minColour: null, maxClarity: null, minClarity: null, maxFluorescence: null, minFluorescence: null }

function OrderPricingRanges({ priceScheme, onChange, recalcId, showCosts, disabled, fluorGridRef, validationText, ...props }) {
  const [selectedPricingRange, setSelectedPricingRange] = useState(null)
  priceScheme.sort((a, b) => a.minWeight - b.minWeight).forEach(range => {
    range.id = range.id ?? uuidv4()
  })
  if (!selectedPricingRange && priceScheme[0]?.id) setSelectedPricingRange(priceScheme[0].id)
  const priceSchemeObj = priceScheme?.find(({ id }) => id === selectedPricingRange)
  if (selectedPricingRange && !priceSchemeObj) setSelectedPricingRange(priceScheme[0]?.id)

  const warning = useMemo(() => validationText?.groupWarningByKey(priceScheme, 'id'), [priceScheme, validationText])
  const error = useMemo(() => validationText?.groupErrorByKey(priceScheme, 'id'), [priceScheme, validationText])

  const {
    polishedColours: colours,
    clarities,
    polishedFluorescences,
    getPolishedColours,
    getClarities,
    getPolishedFluorescences,
    getPricingRangeWeightIncrement
  } = useGlobalsStore(state => state)
  const { setModal } = useModalStore(state => state)
  const {
    gridSize,
    gridSize: safeGridSize = gridSizeTemplate,
    setGridSize,
    disablePainter,
    setActivePricingTabIndex
  } = useOrderStore(state => state)
  const {
    maxColour,
    minColour,
    maxClarity,
    minClarity,
    maxFluorescence,
    minFluorescence
  } = safeGridSize

  const isOrderGridSizeInitialized = !!gridSize
  const {
    showInfoToast
  } = useToast()

  useEffect(() => {
    getPolishedColours()
    getClarities()
    getPolishedFluorescences()
    return () => setActivePricingTabIndex(0)
  }, [])

  useEffect(() => {
    if (!colours || !clarities || !polishedFluorescences) return
    if (maxColour && !recalcId) return
    let maxColIdx = 0
    let minColIdx = colours.length - 1
    let maxClrIdx = 0
    let minClrIdx = clarities.length - 1
    const minFluIdx = 0
    let maxFluIdx = polishedFluorescences.length - 1
    if (priceScheme) {
      if (priceScheme.some(range => range.priceTable?.length || range.discountTable?.length)) {
        [maxColIdx, minColIdx, maxClrIdx, minClrIdx] = [minColIdx, maxColIdx, minClrIdx, maxClrIdx]
        maxFluIdx = 0
        for (const range of priceScheme) {
          const table = range.priceTable || range.discountTable || []
          for (const ppt of table) {
            const colIdx = colours.findIndex(c => c.value === ppt.colour)
            const clrIdx = clarities.findIndex(c => c.value === ppt.clarity)
            if (colIdx >= 0) {
              maxColIdx = Math.min(maxColIdx, colIdx)
              minColIdx = Math.max(minColIdx, colIdx)
            }
            if (clrIdx >= 0) {
              maxClrIdx = Math.min(maxClrIdx, clrIdx)
              minClrIdx = Math.max(minClrIdx, clrIdx)
            }
          }
        }
      }
      if (priceScheme.some(range => range.additionalDiscounts?.fluorescence?.length)) {
        for (const range of priceScheme) {
          if (range.additionalDiscounts && !objectUtils.isEmpty(range.additionalDiscounts)) {
            for (const region of range.additionalDiscounts?.fluorescence) {
              if (!region.discounts) continue
              for (const fluKey in region.discounts) {
                const fluIdx = polishedFluorescences.findIndex(f => f.value === fluKey)
                if (fluIdx >= 0) maxFluIdx = Math.max(maxFluIdx, fluIdx)
              }
            }
          }
        }
      }
    }
    setGridSize({
      maxColour: colours[maxColIdx].value,
      minColour: colours[minColIdx].value,
      maxClarity: clarities[maxClrIdx].value,
      minClarity: clarities[minClrIdx].value,
      minFluorescence: polishedFluorescences[minFluIdx].value,
      maxFluorescence: polishedFluorescences[maxFluIdx].value
    })
  }, [colours, clarities, polishedFluorescences, recalcId, isOrderGridSizeInitialized])
  function handleOrderGridSizeChange(e) {
    const target = e.currentTarget || e.target
    setGridSize({ ...gridSize, [target.name]: target.value })
  }
  const displayColours = useMemo(() => {
    if (!colours || !gridSize) return []
    return colours.reduce(({ colours, flag }, c) => {
      if (flag) return { colours: [...colours, c], flag: c.value !== minColour }
      else if (c.value === maxColour) return { colours: [...colours, c], flag: c.value !== minColour }
      else return { colours, flag }
    }, { colours: [], flag: false }).colours
  }, [colours, maxColour, minColour])
  const displayClarities = useMemo(() => {
    if (!clarities || !gridSize) return []
    return clarities.reduce(({ clarities, flag }, c) => {
      if (flag) return { clarities: [...clarities, c], flag: c.value !== minClarity }
      else if (c.value === maxClarity) return { clarities: [...clarities, c], flag: c.value !== minClarity }
      else return { clarities, flag }
    }, { clarities: [], flag: false }).clarities
  }, [clarities, maxClarity, minClarity])
  const displayFluors = useMemo(() => {
    if (!polishedFluorescences || !gridSize) return []
    return polishedFluorescences.reduce(({ fluors, flag }, f) => {
      if (flag) return { fluors: [...fluors, f], flag: f.value !== maxFluorescence }
      // else if (f.value === minFluorescence) return { fluors: [...fluors, f], flag: true }
      else return { fluors, flag }
    }, { fluors: [], flag: true }).fluors
  }, [polishedFluorescences, maxFluorescence])

  function handleRangeChange(rangeId, changes) {
    const range = priceScheme.find(({ id }) => id === rangeId)
    onChange([...priceScheme.filter(({ id }) => id !== rangeId), { ...range, ...changes }])
  }

  function handleApplyAllMargin(value) {
    if (value != null) {
      onChange([...priceScheme.map(ps => ({ ...ps, margin: value }))])
      showInfoToast(`A margin of ${value}% was applied to all weight ranges.`)
    }
  }

  function addRange() {
    const maxWeight = priceScheme.reduce((max, range) => range && !isNaN(range.maxWeight) ? Math.max(max, range.maxWeight) : max, getPricingRangeWeightIncrement(0, -1))
    const id = uuidv4()
    onChange([...priceScheme, { minWeight: getPricingRangeWeightIncrement(maxWeight), maxWeight: getPricingRangeWeightIncrement(maxWeight), priceTable: [], discountTable: [], id }])
    setSelectedPricingRange(id)
    disablePainter()
  }

  function deletePriceRange(id) {
    const newPriceScheme = priceScheme.filter(({ id: schemaId }) => schemaId !== id)
    onChange(newPriceScheme)
  }

  return (
    <div className='order-pricing-ranges'>
      <div className="order-pricing-ranges__list">
        {
          priceScheme.map((priceSchema, index) => {
            return <div key={index} className='order-pricing-ranges__list-row'>
              <Button
                size='sm'
                typeVariant={selectedPricingRange === priceSchema?.id ? 'primary' : 'secondary'}
                onClick={() => {
                  setSelectedPricingRange(priceSchema?.id)
                  disablePainter()
                }}
              >
                {`${numberUtils.numFmt(priceSchema.minWeight, 2, { numbersOnly: true, fallbackValue: '' })} - ${numberUtils.numFmt(priceSchema.maxWeight, 2, { numbersOnly: true, fallbackValue: '' })}`}
              </Button>
              {!disabled
                && <>
                  <div
                    className="cursor-pointer"
                    onClick={() => new Promise((resolve) => {
                      setModal({
                        id: 'orderPricingRangesDeleteRange',
                        title: 'Confirm Delete',
                        message: <>
                          Are you sure you want to delete weight range?
                        </>,
                        onSubmit: resolve
                      })
                    }).then(() => deletePriceRange(priceSchema?.id))}
                  >
                    <Icon
                      size='md'
                      name='close'
                    />
                  </div>
                  <ErrorIcon error={error?.[priceSchema?.id]} warning={warning?.[priceSchema?.id]} />
                </>
              }
            </div>
          })
        }
        {!disabled
          && <div className="order-lines__list-row">
            <Button
              size='sm'
              onClick={addRange}
            >
            Add Weight Range
            </Button>
            <ErrorIcon warning={validationText?.getWarning()?.length} error={validationText?.getError()?.length}/>
          </div>
        }
      </div>
      <div className="order-pricing-ranges__range">
        <div className="order-pricing-ranges__row">
          <OrderPricingRangesSettings
            priceSchema={priceScheme}
            onChange={handleOrderGridSizeChange}
            disabled={disabled}
            {...gridSize}
          />
          <PricePainter disabled={disabled} priceSchema={priceScheme} selectedPricingRange={selectedPricingRange} onChange={onChange} onRangeChange={(...params) => handleRangeChange(selectedPricingRange, ...params)}/>
        </div>
        <hr />
        <OrderPricingRange
          key={selectedPricingRange}
          priceRange={priceSchemeObj}
          colours={displayColours}
          clarities={displayClarities}
          fluorescences={displayFluors}
          showCosts={showCosts}
          handleApplyAllMargin={handleApplyAllMargin}
          onChange={(...params) => handleRangeChange(selectedPricingRange, ...params)}
          disabled={disabled}
          fluorGridRef={fluorGridRef}
          validationText={validationUtils.validation({ warning: warning?.[selectedPricingRange], error: error?.[selectedPricingRange] })}
        />
      </div>
    </div >
  )
}

OrderPricingRanges.propTypes = {
  priceScheme: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  recalcId: PropTypes.any,
  showCosts: PropTypes.bool,
  disabled: PropTypes.bool,
  validationText: PropTypes.object
}

OrderPricingRanges.defaultProps = {
  priceScheme: []
}
export default OrderPricingRanges
