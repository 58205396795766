import create from 'zustand'
import { transactionActions } from '@actions'
import { cacheList } from './storeUtils'

const buyerTransactionsListPromise = {}
const sellerTransactionsListPromise = {}
const adminTransactionsListPromise = {}

const initialValues = {
  buyerTransactionsList: {},
  sellerTransactionsList: {},
  adminTransactionsList: {}
}
const useTransactionStore = create((set, get) => ({
  /*
  transactionsList structure
    transactionsList: {
      [paramString]: {
        data: [/transaction objects/],
        lastUpdated: /date-time of last request/
        cacheInvalid: /bool flag to indicate if data has been manipulated/
      }
      ...
    }
  */
  ...initialValues,
  /*
  cacheList:
    getBuyerTransactionsList,
    setBuyerTransactionsList,
    removeBuyerTransactionsListItems,
    removeBuyerTransactionsList,
    removeAllBuyerTransactionsListItems,
    removeAllBuyerTransactionsList,
  */
  ...cacheList({
    state: 'buyerTransactionsList',
    zustandMethods: { get, set },
    promise: buyerTransactionsListPromise,
    batchAction: transactionActions.getBuyerTransactionsBatch
  }),
  /*
  cacheList:
    getSellerTransactionsList,
    setSellerTransactionsList,
    removeSellerTransactionsListItems,
    removeSellerTransactionsList,
    removeAllSellerTransactionsListItems,
    removeAllSellerTransactionsList,
  */
  ...cacheList({
    state: 'sellerTransactionsList',
    zustandMethods: { get, set },
    promise: sellerTransactionsListPromise,
    batchAction: transactionActions.getSellerTransactionsBatch
  }),
  /*
  cacheList:
    getAdminTransactionsList,
    setAdminTransactionsList,
    removeAdminTransactionsListItems,
    removeAdminTransactionsList,
    removeAllAdminTransactionsListItems,
    removeAllAdminTransactionsList,
  */
  ...cacheList({
    state: 'adminTransactionsList',
    zustandMethods: { get, set },
    promise: adminTransactionsListPromise,
    batchAction: transactionActions.getAdminTransactionsBatch
  }),
  resetTransactionStore: () => {
    set({
      ...initialValues
    })
  }
}))

export {
  useTransactionStore
}
