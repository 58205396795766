import axios from 'axios'
const BASE_URL = process.env.REACT_APP_API_BASE_URL

const getSellerConstants = function () {
  return axios.get(`${BASE_URL}/seller/constants`)
}
getSellerConstants.endpoint = 'GET|/seller/constants'

const getBuyerConstants = function() {
  return axios.get(`${BASE_URL}/buyer/constants`)
}
getBuyerConstants.endpoint = 'GET|/buyer/constants'

const getCutGradeConstants = function () {
  return axios.get(`${BASE_URL}/buyer/cutgrades/constants`)
}
getCutGradeConstants.endpoint = 'GET|/buyer/cutgrades/constants'

const getComplianceConstants = function() {
  return axios.get(`${BASE_URL}/auth/compliance/constants`)
}
getComplianceConstants.endpoint = 'GET|/auth/compliance/constants'

const getReportConstants = function() {
  return axios.get(`${BASE_URL}/reports/constants`)
}
getReportConstants.endpoint = 'GET|/reports/constants'

const getShipmentConstants = function () {
  return axios.get(`${BASE_URL}/logistics/shipments/constants`)
}
getShipmentConstants.endpoint = 'GET|/logistics/shipments/constants'

const getSpsConstants = function() {
  return axios.get(`${BASE_URL}/sales/constants`)
}
getSpsConstants.endpoint = 'GET|/sales/constants'

export const constantsActions = {
  getSellerConstants,
  getBuyerConstants,
  getReportConstants,
  getShipmentConstants,
  getCutGradeConstants,
  getComplianceConstants,
  getSpsConstants
}
