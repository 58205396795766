import React, { useMemo, useState } from 'react'
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Line, Legend } from 'recharts'
import { InfoTip } from '@molecules'

function OrderBreakdownChart({ lines, activeLine: activeLineId, chartColours, orderDetailType, coloursMap, claritiesMap }) {
  const [chartHoveredBin, setChartHoveredBin] = useState(null)
  const activeLine = useMemo(() => lines.find(({ id }) => id === activeLineId), [activeLineId])
  const { bins, data } = useMemo(() => {
    const res = { bins: [], data: [] }
    if (activeLine && orderDetailType) {
      const activeBreakdown = activeLine.breakdown[orderDetailType]
      res.bins = Object.keys(activeBreakdown[`${orderDetailType}0filled`])
      res.data = [
        { id: '0', pct: 0, name: 'Current' },
        { id: '1', pct: 5, name: '+5%' },
        { id: '2', pct: 10, name: '+10%' },
        { id: '3', pct: 15, name: '+15%' },
        { id: '4', pct: 20, name: '+20%' }
      ].map((pct) => ({
        ...pct,
        ...(
          pct.pct
            ? res.bins.reduce((pctFilled, pctKey) => ({
              ...pctFilled,
              [pctKey]: activeBreakdown[`${orderDetailType}${pct.pct}filled`][pctKey] + activeBreakdown[`${orderDetailType}0filled`][pctKey]
            }), {})
            : activeBreakdown[`${orderDetailType}0filled`]
        )
      }))
    }
    return res
  }, [activeLine, orderDetailType])

  function compactLegend(props) {
    return <div>
      {props.payload.map(line => (
        <div
          key={line.dataKey}
          style={{ color: line.color }}
          onMouseEnter={() => setChartHoveredBin(line.dataKey)}
          onMouseLeave={() => setChartHoveredBin(null)}
        >
          <InfoTip
            name={`line_${line.dataKey}_legend`}
            place='right'
            delayHide={100}
            icon="circle"
          >
            {getValueDescription(line.value)}
          </InfoTip>
          {getValueDescription(line.value)}
        </div>
      ))}
    </div>
  }

  function getValueDescription(value) {
    if (orderDetailType === 'colour') {
      return coloursMap?.[value] ?? ''
    } else if (orderDetailType === 'clarity') {
      return claritiesMap?.[value] ?? ''
    } else {
      return value
    }
  }

  if (!lines?.length || !activeLine || !orderDetailType) return null
  return (
    <div className="order-breakdown-chart">
      <ResponsiveContainer width='100%' height={400}>
        <LineChart data={data}>
          {bins.map((bin, i) => (
            <Line key={bin}
              type="monotone"
              dataKey={bin}
              stroke={chartColours[i % chartColours.length]}
              strokeWidth={bin === chartHoveredBin ? 4 : 2}
              name={bin}
            />
          ))}
          <CartesianGrid />
          <XAxis dataKey='name' label={{ value: 'Price', position: 'insideBottom', dy: 10 }}/>
          <YAxis
            label={{ value: 'Estimated Quantity', position: 'insideLeft', angle: -90, dy: 70 }}
            domain={[0, 'dataMax+1']}
            allowDecimals={false}
          />
          <Legend wrapperStyle={{ width: 120, height: '90%', right: 0, top: 5, overflowY: 'auto', overflowX: 'clip' }} layout='vertical' verticalAlign='middle' align='right' content={compactLegend} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default OrderBreakdownChart
