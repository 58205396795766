import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import images from '../../../assets/images'
import { Icon } from '@atoms'
import { NavLink } from 'react-router-dom'
import routes from '../../../routes/routes'
import { useAuthStore } from '@stores'
import { useWindowSize } from '@hooks'

function Sidebar({
  isCollapsed,
  setIsCollapsed
}) {
  const [width] = useWindowSize()
  const [showScrollArrows, setShowScrollArrows] = useState(false)
  let scrollInterval = null

  const menuDiv = document.getElementById('sidebar-menu')
  useEffect(() => {
    // if the scrollbar height is bigger than the div height
    // it shows the scroll arrows (up and down arrows).
    if (menuDiv) setShowScrollArrows(menuDiv.scrollHeight > menuDiv.clientHeight)
  }, [menuDiv?.scrollHeight, menuDiv?.clientHeight])

  useEffect(() => {
    if (width < 768 && !isCollapsed) setIsCollapsed(true)
    if (width >= 768 && isCollapsed) setIsCollapsed(false)
  }, [width])

  const {
    isAuthenticated,
    hasPermission,
    hasAdmin,
    getPermissions
  } = useAuthStore(state => state)

  useEffect(() => {
    if (isAuthenticated) getPermissions()
  }, [isAuthenticated])
  if (!isAuthenticated) return null

  const startScroll = function (direction) {
    const element = document.getElementById('sidebar-menu')
    scroll(element, direction, 35, element.clientHeight)
  }

  const stopScroll = function () {
    if (scrollInterval) {
      window.clearInterval(scrollInterval)
    }
  }

  const scroll = function (element, direction, speed = 45, distance = 500, step = 10) {
    let scrollAmount = 0
    const slideTimer = setInterval(function () {
      if (direction === 'up') { element.scrollTop -= step } else { element.scrollTop += step }

      scrollAmount += step
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer)
        scrollInterval = null
      }
    }, speed)

    scrollInterval = slideTimer
  }

  const collapsedClassName = isCollapsed ? '--collapsed' : ''
  const scrollArrowsClassName = showScrollArrows ? '--up' : '--hidden'

  return (
    <div className={`sidebar${collapsedClassName}`}>
      <div
        className={`sidebar__collapse-button${collapsedClassName}`}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <Icon
          name='chevronRight'
        />
      </div>
      {
        !isCollapsed ? (
          <div className={'sidebar__logo'}>
            <img className="sidebar__img"
              src={images.claraLogo}
              alt=""
            />
          </div>
        ) : null
      }
      {
        isCollapsed ? (
          <div className={`sidebar__logo${collapsedClassName}`}>
            <img className="sidebar__img"
              src={ images.claraLogoSmall }
              alt=""
            />
          </div>
        ) : null
      }
      <hr className="sidebar__divider" />
      <div className={`sidebar__menu-label${collapsedClassName}`}>
        <p className="overline">
          MENU
        </p>
      </div>
      <div
        className={`sidebar__scroll-arrow${scrollArrowsClassName}`}
        onMouseOver={() => startScroll('up')}
        onMouseOut={() => stopScroll()}
      >
        <Icon name='chevronUp' />
      </div>
      <div
        className="sidebar__menu"
        id="sidebar-menu"
      >
        <div className="sidebar__menu-items">
          {
            routes.navigation.menu
            .filter(r => r.displayFor?.some(endpoint => hasPermission(endpoint)) || r.displayForAdmin?.some(endpoint => hasAdmin(endpoint)))
            .map((route, index) => {
              return <NavLink
                key={index}
                to={route.path}
                activeClassName="sidebar__item--active"
                className="sidebar__item">
                <div className="sidebar__icon">
                  {route.icon ? route.icon : ''}
                </div>
                <div className={`sidebar__label${collapsedClassName}`}>
                  <span>
                    {route.label}
                  </span>
                </div>
              </NavLink>
            })}
        </div>
      </div>
      <div
        className={`sidebar__scroll-arrow${scrollArrowsClassName}`}
        onMouseOver={() => startScroll('down')}
        onMouseOut={() => stopScroll()}
      >
        <Icon name='chevronDown' />
      </div>
      <div className="sidebar__footer">
        <hr className="sidebar__divider" />
        <div className={`sidebar__menu-label${collapsedClassName}`}>
          <p className="overline">
            ACCOUNT
          </p>
        </div>
        {
          // Note: This code behaves differently than the code above for navigation menu.
          //       For Admins, we don't show the Organization Settings or Profile buttons
          routes.navigation.account
          .filter(r => r.displayFor?.some(endpoint => hasPermission(endpoint)) && !r.displayFor?.some(endpoint => hasAdmin(endpoint)))
          .map((route, index) => {
            return <NavLink
              key={index}
              to={route.path}
              activeClassName="sidebar__item--active"
              className="sidebar__item"
            >
              <div className="sidebar__icon">
                {route.icon}
              </div>
              <div className={`sidebar__menu-label${collapsedClassName}`}>
                <span>
                  {route.label}
                </span>
              </div>
            </NavLink>
          })
        }
      </div>
    </div>
  )
}

Sidebar.propTypes = {
  isCollapsed: PropTypes.bool,
  setIsCollapsed: PropTypes.func
}

Sidebar.defaultProps = {
  isCollapsed: false
}

export default Sidebar
