import { arrayUtils } from '@utils'

const ROUGH_TEMPLATE_OPTIONS = [
  { key: 'sellerStoneName', header: 'Seller Stone Name', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 18 },
  {
    key: 'weight',
    header: 'Weight',
    permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }],
    width: 12,
    dataValidation: {
      key: 'weight',
      allowBlank: true,
      showInputMessage: true,
      type: 'decimal',
      operator: 'greaterThanOrEqual',
      promptTitle: 'Allowed values',
      prompt: 'Positive numbers'
    }
  },
  { key: 'color1', header: 'Color 1', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 12, dataValidation: { key: 'color', allowBlank: true } },
  {
    key: 'fluorescence1',
    header: 'Fluorescence 1',
    permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }],
    width: 18,
    dataValidation: {
      key: 'fluorescence',
      allowBlank: true,
      showInputMessage: true,
      type: 'whole',
      operator: 'between',
      promptTitle: 'Allowed values',
      prompt: '0 to 200'
    }
  },
  { key: 'tinge1', header: 'Tinge 1', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 12, dataValidation: { key: 'tinge', allowBlank: true } },
  { key: 'color2', header: 'Color 2', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 12, dataValidation: { key: 'color', allowBlank: true } },
  {
    key: 'fluorescence2',
    header: 'Fluorescence 2',
    permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }],
    width: 18,
    dataValidation: {
      key: 'fluorescence',
      allowBlank: true,
      showInputMessage: true,
      type: 'whole',
      operator: 'between',
      promptTitle: 'Allowed values',
      prompt: '0 to 200'
    }
  },
  { key: 'tinge2', header: 'Tinge 2', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 12, dataValidation: { key: 'tinge', allowBlank: true } },
  { key: 'color3', header: 'Color 3', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 12, dataValidation: { key: 'color', allowBlank: true } },
  {
    key: 'fluorescence3',
    header: 'Fluorescence 3',
    permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }],
    width: 18,
    dataValidation: {
      key: 'fluorescence',
      allowBlank: true,
      showInputMessage: true,
      type: 'whole',
      operator: 'between',
      promptTitle: 'Allowed values',
      prompt: '0 to 200'
    }
  },
  { key: 'tinge3', header: 'Tinge 3', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 12, dataValidation: { key: 'tinge', allowBlank: true } },
  { key: 'color4', header: 'Color 4', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 12, dataValidation: { key: 'color', allowBlank: true } },
  {
    key: 'fluorescence4',
    header: 'Fluorescence 4',
    permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }],
    width: 18,
    dataValidation: {
      key: 'fluorescence',
      allowBlank: true,
      showInputMessage: true,
      type: 'whole',
      operator: 'between',
      promptTitle: 'Allowed values',
      prompt: '0 to 200'
    }
  },
  { key: 'tinge4', header: 'Tinge 4', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 12, dataValidation: { key: 'tinge', allowBlank: true } },
  { key: 'color5', header: 'Color 5', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 12, dataValidation: { key: 'color', allowBlank: true } },
  {
    key: 'fluorescence5',
    header: 'Fluorescence 5',
    permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }],
    width: 18,
    dataValidation: {
      key: 'fluorescence',
      allowBlank: true,
      showInputMessage: true,
      type: 'whole',
      operator: 'between',
      promptTitle: 'Allowed values',
      prompt: '0 to 200'
    }
  },
  { key: 'tinge5', header: 'Tinge 5', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 12, dataValidation: { key: 'tinge', allowBlank: true } },
  { key: 'eyeColor', header: 'Eye Color', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 16, dataValidation: { key: 'eyeColor', allowBlank: true } },
  { key: 'eyeFluorescence', header: 'Eye Fluorescence', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 18, dataValidation: { key: 'eyeFluorescence', allowBlank: true } },
  { key: 'eyeTinge', header: 'Eye Tinge', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 16, dataValidation: { key: 'eyeTinge', allowBlank: true } },
  { key: 'tension', header: 'Tension', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 12, dataValidation: { key: 'tension', allowBlank: true } },
  { key: 'yellowUv', header: 'Yellow UV', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 16, dataValidation: { key: 'yellowUV', allowBlank: true } },
  { key: 'type', header: 'Type', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 12, dataValidation: { key: 'type', allowBlank: true } },
  { key: 'scanType', header: 'Scan Type', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 16, dataValidation: { key: 'scanType', allowBlank: true } },
  { key: 'inclusionType', header: 'Inclusion Type', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }, { action: 'editQcRoughStone', admin: true }, { action: 'editQcRoughStone', admin: false }], width: 18, dataValidation: { key: 'inclusionType', allowBlank: true } },
  { key: 'country', header: 'Country', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 12, dataValidation: { key: 'country', allowBlank: true } },
  { key: 'mine', header: 'Mine', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 12, dataValidation: { key: 'mine', allowBlank: true } },
  { key: 'pipe', header: 'Pipe', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 12, dataValidation: { key: 'pipe', allowBlank: true } },
  { key: 'batch', header: 'Batch', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 12, dataValidation: { key: 'batch', allowBlank: true } },
  { key: 'weightCategory', header: 'Weight Category', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 18, dataValidation: { key: 'weightCategory', allowBlank: true, showErrorMessage: false } },
  { key: 'pricePointName', header: 'Price Point', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }], width: 18, dataValidation: { key: 'pricePoint', allowBlank: true, showErrorMessage: false } },
  {
    key: 'pricePerCarat',
    header: 'Price Per Carat',
    permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }],
    dataValidation: {
      key: 'pricePerCarat',
      allowBlank: true,
      showInputMessage: true,
      type: 'decimal',
      operator: 'greaterThanOrEqual',
      promptTitle: 'Allowed values',
      prompt: 'Positive numbers'
    },
    width: 18
  },
  {
    key: 'overridePricePerCarat',
    header: 'Override Price per Carat',
    permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editRoughStone', admin: false }],
    dataValidation: {
      key: 'pricePerCarat',
      allowBlank: true,
      showInputMessage: true,
      type: 'decimal',
      operator: 'greaterThanOrEqual',
      promptTitle: 'Allowed values',
      prompt: 'Positive numbers'
    },
    width: 22
  },
  { key: 'overrideColor', header: 'Override Color', permissions: [{ action: 'editRoughStone', admin: true }], width: 16, dataValidation: { key: 'eyeColor', allowBlank: true } },
  { key: 'overrideFluorescence', header: 'Override Fluorescence', permissions: [{ action: 'editRoughStone', admin: true }], width: 20, dataValidation: { key: 'eyeFluorescence', allowBlank: true } },
  { key: 'overrideTinge', header: 'Override Tinge', permissions: [{ action: 'editRoughStone', admin: true }], width: 18, dataValidation: { key: 'eyeTinge', allowBlank: true } },
  { key: 'inclusionReductions', header: 'Inclusion Reductions', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editQcRoughStone', admin: true }, { action: 'editQcRoughStone', admin: false }], width: 18, dataValidation: { key: 'inclusionReduction', allowBlank: true } },
  { key: 'qcStatus', header: 'QC Approved', permissions: [{ action: 'editRoughStone', admin: true }, { action: 'editQcRoughStone', admin: true }], width: 16, dataValidation: { key: 'qcStatus', allowBlank: true } },
  { key: 'location', header: 'Location', permissions: [{ action: 'editRoughStone', admin: true }], width: 14, dataValidation: { key: 'location', allowBlank: true } }
]

const SELLER = {
  ROUGH_LOCATIONS: [
    'Seller',
    'Clara Diamond Solutions BV',
    'Sarine Polishing Technologies LTD.',
    'Worldwide Diamond Manufacturers Pvt Ltd',
    'Buyer',
    'In Transit'
  ],
  ASSORTMENT_PRIORITIES: [
    { value: 0, label: 'Normal' },
    { value: 40, label: 'High' },
    // { value: 60, label: 'Higher (Bids)' }
    { value: 100, label: 'Highest' }
  ],
  ROUGH_TEMPLATE_OPTIONS,
  MULTI_ROUGHS_UPDATE_FIELDS: arrayUtils.pickBy(ROUGH_TEMPLATE_OPTIONS, ['key', 'header', 'permissions']).filter(({ key }) => ['weightCategory', 'pricePointName', 'scanType', 'tension', 'yellowUv', 'inclusionType', 'inclusionReductions', 'location', 'type'].includes(key))
}

export default SELLER
