import React, { useState } from 'react'
import SelectShipmentRoughStones from './selectShipmentRoughStones'
import CreateShipmentForm from './createShipmentForm'

function CreateShipment() {
  const [step, setStep] = useState(0)
  const [selectedRoughStones, setSelectedRoughStones] = useState([])

  function onRoughStonesSelect(rows) {
    setSelectedRoughStones(rows)
    setStep(1)
  }

  return (
    <>
      {
        step === 0
          ? (
            <SelectShipmentRoughStones
              onRoughStonesSelect={onRoughStonesSelect}
            />
          )
          : step === 1
            ? (
              <CreateShipmentForm
                selectedRoughStones={selectedRoughStones}
                onCancel={() => {
                  setSelectedRoughStones([])
                  setStep(0)
                }}
              />
            ) : null
      }
    </>
  )
}

export default CreateShipment
