import React, { useState, useEffect } from 'react'
import { Button } from '@atoms'
import { TextInput } from '@molecules'
import { useGlobalsStore } from '@stores'

function MeasurementsModal({
  isEdit,
  onCancel,
  onSaveMeasurements,
  measurementBoxList,
  ...props
}) {
  const [
    measurements,
    setMeasurements
  ] = useState(null)

  const [
    measurementsError,
    setMeasurementsError
  ] = useState(false)

  const minStrongFluor = 70
  const maxStrongFluor = 200

  const {
    getRoughColours,
    getRoughFluorescences,
    getRoughTinges,
    roughColours: colours,
    roughColoursMap,
    roughFluorescences: fluors,
    roughTinges: tinges,
    roughTingesMap
  } = useGlobalsStore(store => store)
  useEffect(() => {
    getRoughColours()
    getRoughFluorescences()
    getRoughTinges()
  }, [])

  useEffect(() => {
    if (colours && colours.length && fluors && fluors.length && tinges && tinges.length) {
      const numberOfItems = 5
      buildMeasurementsListArray(numberOfItems)
    }
  }, [colours, fluors, tinges])

  function buildMeasurementsListArray(numberOfItems) {
    const measurementsSchema = {
      colours: colours.filter(c => !c.yehudaNotAllowed),
      fluorescence: { quantity: fluors.length, rangeLimit: 10, startsWith: 0 },
      tinge: tinges.filter(t => !t.yehudaNotAllowed).map(tinge => {
        return {
          description: tinge.description,
          value: tinge.value
        }
      })
    }

    setMeasurements([...new Array(numberOfItems)].map((_, index) => {
      const fluorItems = buildFluorItems(measurementsSchema.fluorescence, measurementBoxList[index]?.fluorescence)
      return {
        colours: { items: [...measurementsSchema.colours] },
        fluor: { ...fluorItems },
        tinge: { items: [...measurementsSchema.tinge] },
        selected: {
          colour: measurementBoxList ? measurementBoxList[index]?.colour : null,
          fluor: measurementBoxList ? measurementBoxList[index]?.fluorescence : null,
          fluorRange: measurementBoxList ? measurementBoxList[index]?.fluorRange : null,
          tinge: measurementBoxList ? measurementBoxList[index]?.tinge : null
        },
        get selectedCount() {
          return Number(!!this.selected.colour)
            + Number(!!this.selected.fluor)
            + Number(!!this.selected.tinge)
        }
      }
    }))
  }

  function buildFluorItems(fluorObj, selectedFluor, maxItems = minStrongFluor) {
    const upperLimit = fluorObj.quantity > maxItems ? maxItems : fluorObj.quantity
    return {
      items: [...new Array(upperLimit)].map((_, index) => Number(index) + Number(fluorObj.startsWith)),
      ranges: [...new Array(Math.ceil(upperLimit / fluorObj.rangeLimit))].map((_, index) => {
        const minRange = Number(index * fluorObj.rangeLimit)
        let maxRange = Number(index + 1) * Number(fluorObj.rangeLimit) - 1
        if (maxRange > upperLimit) maxRange = upperLimit - 1
        return {
          index,
          minRange,
          maxRange,
          selected: Number(selectedFluor) <= maxRange && Number(selectedFluor) >= minRange,
          lastRange: false,
          label: String(Number(index * fluorObj.rangeLimit)
            + ' - '
            + maxRange),
          items: [...new Array(maxRange - minRange + 1)
            .fill()
            .map((_, index) => index + minRange)]
        }
      })
      .concat([{
        index: Math.ceil(upperLimit / fluorObj.rangeLimit),
        minRange: null,
        maxRange: null,
        selected: selectedFluor >= upperLimit,
        lastRange: true,
        label: `${upperLimit}+`,
        items: null
      }])
    }
  }

  function selectColour(colour, measurementIndex) {
    const copiedMeasurements = [...measurements]

    copiedMeasurements[measurementIndex].selected.colour
      = copiedMeasurements[measurementIndex].selected.colour === colour.value
        ? null
        : colour.value

    setMeasurements(copiedMeasurements)
  }

  function selectTinge(tinge, measurementIndex) {
    const copiedMeasurements = [...measurements]

    copiedMeasurements[measurementIndex].selected.tinge
      = copiedMeasurements[measurementIndex].selected.tinge === tinge.value
        ? null
        : tinge.value

    setMeasurements(copiedMeasurements)
  }

  function renderFluorRanges(fluorRange, measurementIndex) {
    if (fluorRange.selected || measurements[measurementIndex].selected.fluorRange === fluorRange.index) {
      if (fluorRange.lastRange) {
        const classNames = measurements[measurementIndex].selected.fluor >= minStrongFluor
          ? ['create-rough-stone__strong-fluor--active'] : []

        return (
          <div className='create-rough-stone__strong-fluor'>
            <TextInput
              name='veryStrongFluor'
              type='number'
              placeholder={`${minStrongFluor}-${maxStrongFluor}`}
              classNames={classNames}
              value={measurements[measurementIndex].selected.fluor}
              size="sm"
              min={0}
              max={maxStrongFluor}
              decimalScale={0}
              onChange={e => setFluor(e.currentTarget.value, measurementIndex)}
            />
          </div>
        )
      }

      return fluorRange.items.map(fluNum => {
        const fluor = String(fluNum)
        return (
          <span
            key={fluor + measurementIndex}
            onClick={() => selectFluor(fluor, measurementIndex)}
            className={`create-rough-stone__measurements-item${measurements[measurementIndex].selected.fluor
              && measurements[measurementIndex].selected.fluor === fluor
              ? '--selected'
              : ''}
            `}
          >
            <strong>{fluor}</strong>
          </span>
        )
      })
    } else {
      return (
        <span
          key={fluorRange.label + fluorRange.index}
          onClick={() => selectFluorRange(fluorRange.index, measurementIndex)}
          className='create-rough-stone__measurements-item'
        >
          {fluorRange.label}
        </span>)
    }
  }

  function selectFluorRange(fluorRangeIndex, measurementIndex) {
    const copiedMeasurements = [...measurements]
    copiedMeasurements[measurementIndex].fluor.ranges.forEach(range => { range.selected = false })
    copiedMeasurements[measurementIndex].fluor.ranges[fluorRangeIndex].selected = true
    copiedMeasurements[measurementIndex].selected.fluorRange = fluorRangeIndex
    setMeasurements(copiedMeasurements)
  }

  function setFluor(value, measurementIndex) {
    const copiedMeasurements = [...measurements]
    copiedMeasurements[measurementIndex].selected.fluor = String(value)
    setMeasurements(copiedMeasurements)
  }

  function selectFluor(name, measurementIndex) {
    const copiedMeasurements = [...measurements]

    copiedMeasurements[measurementIndex].selected.fluor
      = copiedMeasurements[measurementIndex].selected.fluor === name
        ? null
        : name

    setMeasurements(copiedMeasurements)
  }

  function clearMeasurement(measurementIndex) {
    setMeasurements(currMeasurements => currMeasurements.toSpliced(
      measurementIndex,
      1,
      { ...currMeasurements[measurementIndex], selected: { colour: null, fluor: null, fluorRange: null, tinge: null } }
    ))
  }

  function handleSaveMeasurements() {
    if (measurements) {
      const isMeasurementError = []
      measurements.forEach((measurement, i) => {
        if (measurement.selectedCount > 0 && measurement.selectedCount < 3) { isMeasurementError.push(i) }
      })

      if (!isMeasurementError.length) {
        const selectedMeasurements = measurements.map(measurement => {
          return {
            colour: measurement.selected.colour,
            fluorescence: measurement.selected.fluor,
            fluorRange: measurement.selected.fluorRange,
            tinge: measurement.selected.tinge
          }
        })

        setMeasurementsError(false)
        onSaveMeasurements(selectedMeasurements)
        if (props.isEditToggled && props.onChange) {
          props.onChange({ target: { name: 'measurements', value: selectedMeasurements } })
        }
      } else {
        setMeasurementsError(isMeasurementError)
      }
    }
  }

  return (
    <div className='create-rough-stone__measurements-list'>
      <div className="create-rough-stone__measurement-step-measurements">
        <div className='create-rough-stone__measurement-step-measurements-list'>
          {
            measurements ? measurements.map((measurement, measurementIndex) => {
              return (
                <div
                  key={measurementIndex}
                  className='create-rough-stone__measurements-list-item'
                >
                  <div className='create-rough-stone__measurements-title-row'>
                    <div className="create-rough-stone__measurements-title">
                      <h3>
                        {`Measurement ${measurementIndex + 1} `}
                      </h3>
                      <span>
                        {`(${roughColoursMap?.[measurement?.selected?.colour] ?? ''} / ${measurement?.selected?.fluor ?? ''} / ${roughTingesMap?.[measurement?.selected?.tinge] ?? ''})`}
                      </span>
                    </div>
                    <div className='ml-auto'><Button typeVariant="action" onClick={() => clearMeasurement(measurementIndex)}>Clear</Button></div>
                  </div>
                  <div className='create-rough-stone__measurements-row'>
                    <span className='create-rough-stone__measurements-row-label'>
                      Color
                    </span>
                    <div>
                      {measurement.colours.items.map((colourItem, index) => {
                        return <span
                          key={index}
                          className={`create-rough-stone__measurements-item${measurement.selected.colour && measurement.selected.colour === colourItem.value ? '--selected' : ''}`}
                          onClick={() => selectColour(colourItem, measurementIndex)}
                        >
                          {colourItem.description}
                        </span>
                      })}
                    </div>
                  </div>
                  <div className='create-rough-stone__measurements-row'>
                    <span className='create-rough-stone__measurements-row-label'>
                      Fluorescence
                    </span>
                    <div className='create-rough-stone__measurements-items-row'>
                      { measurement.fluor.ranges.map(range => {
                        return renderFluorRanges(range, measurementIndex)
                      })}
                    </div>
                  </div>
                  <div className='create-rough-stone__measurements-row'>
                    <span className='create-rough-stone__measurements-row-label'>
                      Tinge
                    </span>
                    <div>
                      {measurement.tinge.items.map((tingeItem, index) => {
                        return <span
                          key={index}
                          onClick={() => selectTinge(tingeItem, measurementIndex)}
                          className={`create-rough-stone__measurements-item${measurement.selected.tinge && measurement.selected.tinge === tingeItem.value ? '--selected' : ''}`}
                        >
                          {tingeItem.description}
                        </span>
                      })}
                    </div>
                  </div>
                </div>
              )
            }) : null
          }
          <div className="create-rough-stone__measurements-footer">
            {
              measurementsError
                ? <span className='p3 error'>Sorry, {`measurement${measurementsError.length > 1 ? 's' : ''} ${measurementsError.map(i => i + 1).join(',')} ${measurementsError.length > 1 ? 'are' : 'is'} incomplete.`}</span>
                : null
            }

            <div className="right">
              <Button
                typeVariant="action"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                // disabled={!canhandleSaveMeasurements()}
                onClick={() => handleSaveMeasurements()}
              >
                Save measurements
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

MeasurementsModal.defaultProps = {
  isEdit: false,
  isEditToggled: false
}

export default MeasurementsModal
