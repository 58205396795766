import React, { useEffect, useState } from 'react'
import { costActions, organizationActions } from '@actions'
import { FormComponents } from '@organisms'
import { Button as PlainButton, Icon } from '@atoms'
import { useAuthStore, useGlobalsStore } from '@stores'
import { useToast } from '@hooks'
import { useHistory } from 'react-router-dom'
import { Field } from 'formik'
import { BUYER } from '@constants'
import { textUtils } from '@utils'

const COST_DICT = BUYER.COST

function CreateCost({ costType, ...props }) {
  const costDict = COST_DICT[costType.toUpperCase()]
  const appSettingsParams = { groupKey: 'BUYER_SETTINGS_CONSTANTS', key: `${costDict.KEY}_COST_LIMIT` }

  const {
    SimpleForm,
    TextInput,
    Dropdown,
    Button
  } = FormComponents

  const { showSuccessToast } = useToast()

  const { orgId: userOrgId, hasAdmin } = useAuthStore(state => state)
  const isAdmin = hasAdmin(organizationActions.getOrganizationList)
  const {
    getOrgsList,
    getAppSettings,
    orgsList: { [JSON.stringify({ condition: 'ACTIVE' })]: orgsList },
    appSettings: { [JSON.stringify(appSettingsParams)]: appSettings }
  } = useGlobalsStore(store => store)
  useEffect(() => {
    getOrgsList({ condition: 'ACTIVE' })
    getAppSettings(appSettingsParams)
  }, [])

  const [itemIndices, setItemIndices] = useState([0])
  function addCostItem() {
    if (!itemIndices.length) setItemIndices([1])
    else setItemIndices(itemIndices.concat([itemIndices[itemIndices.length - 1] + 1]))
  }
  function removeCostItem(idx) {
    setItemIndices(itemIndices.filter(i => i !== idx))
  }

  const history = useHistory()
  async function handleSubmit({ name, buyerId, items }) {
    const values = { name, buyerId, items: items.filter((_, i) => itemIndices.some(idx => idx === i)) }
    costActions[costDict.ACTION.POST](values)
    .then(() => {
      showSuccessToast('Cost created')
      history.push('/buyer-settings')
    })
    .catch(err => console.error(err))
  }

  function validateForm({ items = [] }) {
    const errors = {}
    if (!itemIndices.length) {
      errors.itemCount = COST_DICT.ERROR.REQUIRED
    }
    if (!items.filter((item, i) => itemIndices.some(idx => idx === i) && item.maxWeight >= appSettings?.[0]?.value).length) {
      errors.weightCoverage = textUtils.substituteConstantTemplateStr(COST_DICT.ERROR.WEIGHT_COVERAGE, { name: costDict.NAME, maxWeight: appSettings?.[0]?.value })
    }
    return errors
  }

  function renderCustomErrors(form) {
    const errors = []
    const { errors: formErrors, touched } = form
    if (formErrors) {
      if (formErrors.itemCount) {
        errors.push(<div className='input__helper-text--error'>{formErrors.itemCount}</div>)
      }
      if (touched.items && !formErrors.itemCount && formErrors.weightCoverage) {
        errors.push(<div className='input__helper-text--error'>{formErrors.weightCoverage}</div>)
      }
    }
    return errors
  }

  return (
    <div className='create-cost center'>
      <SimpleForm
        title={`New ${costDict.NAME} Cost`}
        onSubmit={handleSubmit}
        name='createTingeMap'
        initialValues={{ buyerId: userOrgId }}
        customValidation={validateForm}
      >
        <TextInput
          name='name'
          label='Name'
        />
        {isAdmin
        && <Dropdown
          name='buyerId'
          label='Buyer'
          options={orgsList?.map(org => ({
            label: org.commonName,
            value: org.orgId
          }))}
        />
        }
        {itemIndices.map(idx => (
          <div key={`row-${idx}`} className='cost-item__row'>
            <TextInput
              name={`items.${idx}.minWeight`}
              label='Min Weight'
              required={true}
            />
            <TextInput
              name={`items.${idx}.maxWeight`}
              label='Max Weight'
              required={true}
            />
            <TextInput
              name={`items.${idx}.${costDict.FIELD}`}
              label={costDict.LABEL}
              required={true}
            />
            <div className='file-upload__remove-icon' onClick={() => removeCostItem(idx)}>
              <Icon
                name='closeCircle'
                size='md'
              />
            </div>
          </div>
        ))}
        <div className='w-100'>
          <PlainButton size='sm' onClick={() => addCostItem()}>Add a Cost Row</PlainButton>
        </div>
        <Field
          name='mapping'
        >
          {({ form }) => renderCustomErrors(form)}
        </Field>
        <Button typeVariant="action" onClick={() => history.push('/buyer-settings')}>
            Cancel
        </Button>
        <Button type='submit' size='sm'>
            Create Cost Map
        </Button>
      </SimpleForm>
    </div>
  )
}

export default CreateCost
