import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useGlobalsStore } from '@stores'
import { Table } from '@organisms'

const OrderFilterList = function({ orders, selectedOrders, isAdmin, orgsList, tableInstanceRef, handleSubmit }) {
  const { orderStatusesMap, getOrderStatuses } = useGlobalsStore(store => store)
  useEffect(() => {
    getOrderStatuses()
  }, [])

  useEffect(() => {
    return () => {
      handleSubmit()
    }
  }, [])

  const initialSelected = useMemo(() => selectedOrders.reduce((selected, id) => ({ ...selected, [orders.findIndex(({ id: orderId }) => id === orderId)]: true }), {}), [orders, selectedOrders])
  const columns = useMemo(() => ([
    {
      Header: 'Order ID',
      accessor: 'id',
      dataType: 'number'
    },
    ...(isAdmin ? [{
      Header: 'Owner',
      accessor: row => orgsList?.find(org => org.orgId === row.buyerId)?.commonName, // Should cases like these also be moved to the store?
      dataType: 'string'
    }] : []),
    {
      Header: 'Order Name',
      accessor: 'name',
      dataType: 'string'
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: row => orderStatusesMap?.[row.status] ?? '',
      dataType: 'string'
    },
    {
      Header: 'Last Updated',
      accessor: 'updatedAt',
      dataType: 'date',
      filterType: 'date'
    }
  ]), [orgsList, isAdmin, orderStatusesMap])

  return <Table
    title="Orders"
    data={orders}
    columns={columns}
    initialSelected={initialSelected}
    initialSort={[]}
    ref={tableInstanceRef}
  />
}

OrderFilterList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  selectedOrders: PropTypes.arrayOf(PropTypes.string),
  isAdmin: PropTypes.bool,
  orgsList: PropTypes.arrayOf(PropTypes.object),
  tableInstanceRef: PropTypes.object,
  handleSubmit: PropTypes.func
}

OrderFilterList.defaultProps = {
  orders: [],
  selectedOrders: [],
  orgsList: []
}

export default OrderFilterList
