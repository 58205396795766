import React, { useEffect, useState } from 'react'
import { Modal } from '@templates'
import { useModalStore } from '@stores'
import { Button, Checkbox } from '@atoms'
import { useToast } from '@hooks'

function ActionConfirmationModal() {
  const {
    modalDefaults,
    modalQueue,
    removeModal
  } = useModalStore(state => state)
  const {
    showErrorToast,
    showWarningToast,
    showInfoToast
  } = useToast()

  const activeModal = modalQueue[0] || {}

  const {
    id,
    type,
    title,
    message,
    customMessageRenderer,
    checkbox,
    customCheckboxRenderer,
    onSubmit,
    onCancel,
    onClose,
    customButtonsRenderer,
    buttonOptions,
    closeOnFail,
    closeable,
    shouldCloseOnOverlayClick = true,
    className,
    overlayClassName
  } = activeModal

  const [confirmationChecked, setConfirmationChecked] = useState(true)

  useEffect(() => {
    setConfirmationChecked(!checkbox?.name)
  }, [id])

  const handleClose = () => {
    Promise.resolve(onClose?.())
      .then(() => {
        removeModal()
      })
      .catch(console.err)
  }
  const handleCancel = (...props) => {
    Promise.resolve(onCancel?.(props))
    .then(() => handleClose())
    .catch(() => {
      if (closeOnFail) handleClose()
    })
  }

  const handleSubmit = (...props) => {
    Promise.resolve(onSubmit?.(props))
    .then(() => handleClose())
    .catch((err) => {
      if (err.name === 'ModalError') {
        if (err.type === 'error') showErrorToast(err.message)
        if (err.type === 'warning') showWarningToast(err.message)
        if (err.type === 'info') showInfoToast(err.message)
      }
      if (closeOnFail) handleClose()
    })
  }

  const bodyRenderer = () => {
    if (type) {
      return ActionConfirmationModal?.[type]({ ...activeModal, messageRenderer, checkboxRenderer, buttonsRenderer })
    }
    return [
      <React.Fragment key="messageRenderer">{messageRenderer()}</React.Fragment>,
      <React.Fragment key="checkboxRenderer">{checkboxRenderer()}</React.Fragment>,
      <React.Fragment key="buttonRenderer">{buttonsRenderer()}</React.Fragment>
    ]
  }

  const messageRenderer = () => {
    const messageProps = { handleSubmit, handleCancel, handleClose, message }
    if (customMessageRenderer) {
      return customMessageRenderer(messageProps, modalDefaults)
    }
    return ActionConfirmationModal.message(messageProps, modalDefaults)
  }

  const checkboxRenderer = () => {
    const checkboxProps = { checkbox, confirmationChecked, setConfirmationChecked }
    if (customCheckboxRenderer) {
      return customCheckboxRenderer(checkboxProps, modalDefaults)
    }
    return ActionConfirmationModal.checkbox(checkboxProps, modalDefaults)
  }

  const buttonsRenderer = () => {
    const buttonProps = {
      handleSubmit,
      handleCancel,
      handleClose,
      confirmationChecked,
      buttonOptions
    }
    if (customButtonsRenderer !== undefined) {
      return customButtonsRenderer(buttonProps, modalDefaults)
    }
    return ActionConfirmationModal.buttons(buttonProps, modalDefaults)
  }

  return (
    <Modal
      open={!!modalQueue.length}
      title={title}
      onClose={modalQueue.length ? handleClose : () => null}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      closeable={closeable}
      className={`action-confirmation-modal${className ? ` ${className}` : ''}`}
      overlayClassName={overlayClassName}
    >
      {bodyRenderer()}
    </Modal>
  )
}

// Modal Builders
ActionConfirmationModal.message = function(props, defaults = {}) {
  const { message } = props
  if (!message) {
    return null
  }
  return <div className="action-confirmation-modal__message">{message}</div>
}

ActionConfirmationModal.checkbox = function(props, defaults = {}) {
  const { checkbox, confirmationChecked, setConfirmationChecked } = props
  if (!checkbox?.name) {
    return null
  }
  return <div className="action-confirmation-modal__input-container">
    <Checkbox
      name={checkbox.name}
      value={confirmationChecked}
      onChange={() => setConfirmationChecked(!confirmationChecked)}
    />
    <div className="action-confirmation-modal__input-label">
      {checkbox.message}
    </div>
  </div>
}

ActionConfirmationModal.buttons = function(props, defaults = {}) {
  const { handleCancel, handleSubmit, confirmationChecked, buttonOptions = {} } = props
  const { buttonOptions: defaultButtonOptions } = defaults
  const {
    showCancel = defaultButtonOptions.showCancel,
    submitText = defaultButtonOptions.submitText,
    submitTypeVariant = defaultButtonOptions.submitTypeVariant,
    cancelText = defaultButtonOptions.cancelText,
    cancelTypeVariant = defaultButtonOptions.cancelTypeVariant
  } = buttonOptions
  return (
    <div key="action-buttons" className='modal__buttons'>
      {showCancel && <Button typeVariant={cancelTypeVariant || 'secondary'} size='sm' onClick={handleCancel}>{cancelText}</Button>}
      <Button typeVariant={submitTypeVariant || 'primary'} size='sm' onClick={handleSubmit} disabled={!confirmationChecked}>{submitText}</Button>
    </div>
  )
}

// Modal Type Presets
ActionConfirmationModal.downloadFile = function(props) {
  const { messageRenderer } = props
  return (
    <div
      className="action-confirmation-modal__download-file-container"
    >
      <div className="action-confirmation-modal__download-file-content">
        <h2 className="action-confirmation-modal__download-file-heading"> Your file is ready for download</h2>
        {messageRenderer()}
      </div>
    </div>
  )
}

ActionConfirmationModal.form = function(props) {
  const { messageRenderer, addDefaultButtons, buttonsRenderer } = props
  return (
    <div
      className='action-confirmation-modal__form-container'
    >
      {messageRenderer()}
      {addDefaultButtons ? buttonsRenderer() : null}
    </div>
  )
}

export default ActionConfirmationModal
