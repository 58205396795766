function numFmt(num, decimals, { prefix, suffix, numbersOnly, thousandSeperator, fallbackValue = ' - ' } = { fallbackValue: ' - ' }) {
  if (num == null) return fallbackValue

  if (typeof num !== 'number') {
    if (numbersOnly && !isNumber(num)) return num
    else num = Number(num)
  }
  let str = decimals != null ? num.toFixed(decimals) : String(num)
  if (thousandSeperator) str = Number(str).toLocaleString('en-US', { minimumFractionDigits: decimals, maximumFractionDigits: decimals })
  if (prefix) str = prefix + ' ' + str
  if (suffix) str += ' ' + suffix
  return str
}

function isNumber(num) {
  const numType = typeof num
  if (numType !== 'string' && numType !== 'number') return false
  return Number.isFinite(Number(num))
}

function floatAddition(a, b, prec) {
  return parseFloat((a + b).toFixed(prec))
}

export default { numFmt, isNumber, floatAddition }
