import * as Yup from 'yup'
import { positive, range, required, moreThanInclusive } from '../messages'

// schema models
const priceTable = () => Yup.array(Yup.object({})
  .test('ppcValue', (val, { createError, path }) => val.ppc == null || val.ppc >= 0 || createError({
    // consider checking +/-
    // Remove the array index from the path and replace with colour/clarity
    path: path.replace(/\[\d+\]$/g, `.${val.colour}|${val.clarity}.ppc`),
    message: positive()
  }))
)
const discountTable = () => Yup.array(Yup.object({})
  .test('percentageValueRange', (val, { createError, path }) => val.percentage == null || val.percentage >= -100 || createError({
    // consider checking +/-
    // Remove the array index from the path and replace with colour/clarity
    path: path.replace(/\[\d+\]$/g, `.${val.colour}|${val.clarity}.percentage`),
    message: range(null, '-100%', '0%')
  }))
  .test('negPercentageValue', (val, { createError, path }) => val.percentage == null || val.percentage < 0 || createError({
    // Remove the array index from the path and replace with colour/clarity
    path: path.replace(/\[\d+\]$/g, `.${val.colour}|${val.clarity}.percentage`),
    message: 'Negative % expected in most cases'
  }))
)
const additionalDiscounts = (constants) => Yup.object({
  fluorescence: Yup.array(Yup.object({
    maxColour: Yup.string().oneOf(constants?.COLOURS),
    minColour: Yup.string().oneOf(constants?.COLOURS),
    maxClarity: Yup.string().oneOf(constants?.CLARITIES),
    minClarity: Yup.string().oneOf(constants?.CLARITIES),
    // consider checking +/-
    discounts: Yup.object({
      ...(constants?.POLISHED_FLUORESCENCES || []).reduce((obj, flu) => ({
        ...obj,
        [flu]: Yup.number().min(-100, range(null, '-100%', '0%')).max(0, range(null, '-100%', '0%'))
      }), {})
    })
  }))
})

const priceScheme = (constants, customSchema = {}) => Yup.array(Yup.object({
  minWeight: Yup.number().min(0).nullable(true).test('minWeightRequired', required('Min Weight'),
    (minWeight) => (minWeight ?? -1) >= 0
  ),
  maxWeight: Yup.number().min(Yup.ref('minWeight'), moreThanInclusive('Max Weight', 'Min Weight')).nullable(true).test('maxWeightRequired', required('Max Weight'),
    (maxWeight) => (maxWeight ?? -1) >= 0
  ),
  margin: Yup.number().nullable(true).test('advancedPricingMarginRequired', (val, { createError, path }) =>
    (!constants.SHOW_COSTS || (val ?? -1) >= 0) || createError({
      path,
      message: required('Margin')
    })
  ),
  priceTable: priceTable(),
  discountTable: discountTable(),
  additionalDiscounts: additionalDiscounts(constants),
  ...customSchema
})
.test('pricingMethodRequired', (val, { createError, path }) => {
  const key = val.priceTable ? 'priceTable' : 'discountTable'
  return (val.priceTable?.length || val.discountTable?.length) || createError({
    path: `${path}.${key}`,
    message: required('Pricing')
  })
})
.test('pricingWeightRangeOverlap', ({ minWeight, maxWeight }, { options: { context: { form } }, path, createError }) =>
    form?.priceScheme?.filter(({ minWeight: locMinWeight, maxWeight: locMaxWeight }) => (
      (minWeight >= locMinWeight && minWeight <= locMaxWeight)
      || (maxWeight >= locMinWeight && maxWeight <= locMaxWeight)
    )).length <= 1
    || createError({
      path: `${path}.weight`,
      message: 'Weight range overlaps with another weight range.'
    })
))

const orderLine = (constants) => Yup.array(Yup.object({
  id: Yup.string(),
  minWeight: Yup.number().min(0).when('id', (id, schema) =>
    schema.nullable(true).test('minWeightRequired', required('Min Weight'), (minWeight) => {
      if ((minWeight ?? -1) >= 0) return true
      if (id) return minWeight !== null
      return false
    })
  ),
  maxWeight: Yup.number().when(['id', 'minWeight'], (id, minWeight, schema) => {
    if (minWeight === null) return schema

    return schema.nullable(true).test('maxWeightRequired', (maxWeight, { options: { context: { form } }, path, createError }) => {
      const line = form?.lines?.find(({ id: lineId }) => id === lineId)
      if (line || (minWeight != null && maxWeight != null)) {
        const currMinWeight = minWeight ?? line.minWeight
        const currMaxWeight = maxWeight ?? line.maxWeight
        if (currMinWeight > currMaxWeight) {
          return createError({
            path,
            message: moreThanInclusive('Max Weight', 'Min Weight')
          })
        }
      }
      if ((maxWeight ?? -1) >= 0) return true
      if (id && maxWeight !== null) return true
      return createError({
        path,
        message: required('Max Weight')
      })
    })
  }
  ),
  maxColour: Yup.string().when('id', {
    is: (id) => id,
    then: (schema) => schema.nullable(true),
    otherwise: (schema) => schema.required(required('Max Color'))
  }),
  minColour: Yup.string().when('id', {
    is: (id) => id,
    then: (schema) => schema.nullable(true),
    otherwise: (schema) => schema.required(required('Min Color'))
  }),
  maxClarity: Yup.string().when('id', {
    is: (id) => id,
    then: (schema) => schema.nullable(true),
    otherwise: (schema) => schema.required(required('Max Clarity'))
  }),
  minClarity: Yup.string().when('id', {
    is: (id) => id,
    then: (schema) => schema.nullable(true),
    otherwise: (schema) => schema.required(required('Min Clarity'))
  }),
  minFluorescence: Yup.string().when('id', {
    is: (id) => id,
    then: (schema) => schema.nullable(true),
    otherwise: (schema) => schema.required(required('Min Fluor'))
  }),
  maxFluorescence: Yup.string().when('id', {
    is: (id) => id,
    then: (schema) => schema.nullable(true),
    otherwise: (schema) => schema.required(required('Max Fluor'))
  }),
  strictLimits: Yup.object({
    weight: Yup.bool(),
    clarity: Yup.bool(),
    colour: Yup.bool()
  }),
  tingesIncluded: Yup.array(Yup.string()), // fill in the options here
  quantity: Yup.number().nullable(true).when(['id', 'quantityType'], (id, quantityType, schema) => {
    if (quantityType === 'TOPS') return schema
    return Yup.number().nullable(true).test('quantityRequired', required('Quantity'), (quantity, { options: { context: { form } } }) => {
      // Check quantity if we are changing from type tops
      if (
        id
        && quantity === undefined
        && (quantityType === 'ONE_TIME' || quantityType === 'PER_SALE')
      ) {
        const line = form?.lines?.find(({ id: lineId }) => lineId === id)
        quantity = line.quantity
      }
      if ((quantity ?? -1) >= 0) return true
      if (id) return quantity !== null
      return false
    })
  }),
  quantityType: Yup.string().oneOf(constants?.QUANTITY_TYPES)
}))

export default { priceTable, discountTable, additionalDiscounts, priceScheme, orderLine }
