import React from 'react'
import PropTypes from 'prop-types'

function Form({
  title,
  children,
  onSubmit,
  hasOutline
}) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit(e)
      }}
      className={`form__container${!hasOutline ? '--no-outline' : ''}`}>
      {
        title
        && <>
          <h2 className="form__title">
            {title}
          </h2>
        </>
      }
      {children}
    </form>
  )
}

Form.propTypes = {
  title: PropTypes.string,
  hasOutline: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
}

Form.defaultProps = {
  title: '',
  hasOutline: true,
  onSubmit: () => console.warn('Form.js => onSubmit callback not implemented')
}

export default Form
